import React from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'throttle-debounce';
import { StudyManagerController } from '../../../networking/controllers/study-manager-controller';
import { EmailHelper } from '../../../helpers/email-helper';

import styles from './manage-study-manager-search.module.scss';

const IPropTypes = {
  callback: PropTypes.func.isRequired,
  input: PropTypes.string.isRequired,
  inputCallback: PropTypes.func.isRequired,
  internalUsersSsoEnabled: PropTypes.bool.isRequired,
};

class AdminManageStudyManagerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.debounceSearch = debounce(300, this.handleChange.bind(this));
    this.triggerChange = this.triggerChange.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
  }

  isEmailValid() {
    const { input, internalUsersSsoEnabled } = this.props;
    if (internalUsersSsoEnabled && input.match(/@guardanthealth.com$/)) {
      return false;
    }
    return (input.length > 1) && EmailHelper.validateEmail(input);
  }

  triggerChange(e) {
    this.props.inputCallback(e.target.value);
    this.debounceSearch();
  }

  async handleChange() {
    const email = this.props.input.trim().toLowerCase();
    if (email && this.isEmailValid()) {
      window.showSpinner();
      const response = await StudyManagerController.getEmailValidation(email);
      this.props.callback(response, true);
    }
  }

  render() {
    return (
      <div className={styles.searchContainer}>
        <div className={[styles.searchIcon, 'fa fa-search'].join(' ')} />
        <input className={styles.searchInput} type="text" value={this.props.input} onChange={this.triggerChange} placeholder="Search email address" />
      </div>
    );
  }
}

AdminManageStudyManagerSearch.propTypes = IPropTypes;

export { AdminManageStudyManagerSearch };
