import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Toolbar, InputAdornment, useTheme, useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Search } from '@mui/icons-material';
import _ from 'loadsh';
import Immutable from 'immutable';
import ApplicationMessage from '../common/components/elements/ApplicationMessage';
import ConfirmModalWindow from '../common/components/elements/ConfirmModalWindow';
import Controls from '../common/components/forms/controls';
import Pagination from '../common/components/elements/Table/Pagination';
import Table from '../common/Table';
import TableBody from '../common/TableBody';
import TableHeader from '../common/TableHeader';

const useStyles = makeStyles(theme => createStyles({
  root: {
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#ffffff',
  },
  searchInput: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#1A5EA7',
    boxShadow: 'none',
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
}));

const PatientsView = (props) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false, title: '', subTitle: '', onConfirm: () => {},
  });
  const classes = useStyles();
  const {
    patients = Immutable.fromJS({}),
    actions:
      {
        getPatientsData,
        changePagination,
        onChange,
      },
    roles,
  } = props;
  const patientsData = patients.toJS().patientsList || [];
  const patientsList = patientsData.map((patient) => {
    const { releaseResponseDetails, unReleaseResponseDetails } = patient;
    const releaseResponse = releaseResponseDetails ? releaseResponseDetails.map(report => `${report.requestId} (${report.bloodCollectionDate} ${report.reportStatus})`) : [];
    const unReleaseResponse = unReleaseResponseDetails ? unReleaseResponseDetails.map(report => `${report.requestId} (${report.bloodCollectionDate} ${report.reportStatus})`) : [];
    const status = [...releaseResponse, ...unReleaseResponse];
    return Object.assign(patient, { name: `${patient.firstName} ${patient.lastName}`, status });
  });
  const notify = patients.toJS().notify || {};
  const pagination = patients.toJS().pagination || 5;
  const patientManagement = patients.toJS() || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (patientsList.length === 0 || patientManagement.reload) {
      setConfirmDialog({
        isOpen: false, title: '', subTitle: '', onConfirm: () => {},
      });
      getPatientsData(pagination);
      onChange({ key: 'reload', value: false });
    }
  }, [patientManagement.reload]);
  useEffect(() => {
    if (patientManagement.isOpen) {
      setConfirmDialog({
        isOpen: false, title: '', subTitle: '', onConfirm: () => {},
      });
      onChange({ key: 'isOpen', value: false });
    }
  }, [patientManagement.isOpen]);

  const setNotify = (data) => {
    onChange({ key: 'notify', value: { ...data } });
  };
  useEffect(() => {
    onChange({ key: 'reload', value: true });
  }, []);

  const delayedQuery = _.debounce(q => getPatientsData(q), 500);
  const handleSearch = (e) => {
    delayedQuery({ sortBy: e.target.value });
  };
  const userPermission = {
    Edit: roles && roles.includes('UPDATE'),
  };
  const actions = [];
  const handlePagination = (e, pageNum) => {
    e.preventDefault();
    getPatientsData(pageNum);
    changePagination(pageNum);
  };
  const headers = [
    { name: 'name', type: 'text' },
    { name: 'email', type: 'text' },
    { name: 'dob', type: 'text' },
    { name: 'bloodCollectionDate', type: 'text' },
    { name: 'physicianLastName', type: 'text' },
    { name: 'status', type: 'array', format: 'html' },
  ];
  return (
    <div id="roleAssignmentContainer" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Controls.Input
          label="search Patients assignment"
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Toolbar>
      <Grid container>
        <Grid item xs={12}>
          <Table>
            {!isMobile
            && <TableHeader headers={[...headers]} {...{ rows: patientsList || [], actions }} />}
            <TableBody
              headers={headers}
              {...{ rows: patientsList || [], actions }}
              userPermission={userPermission}
              link={{ title: 'Reset Password', link: '/password_resets/new' }}
            />
          </Table>
          <Pagination
            count={patientManagement.totalRecords}
            rowsPerPage={pagination.pageSize}
            page={pagination.pageNo}
            handleChangePage={(e, val) => handlePagination(e, { ...pagination, pageNo: val })}
            handleChangeRowsPerPage={e => handlePagination(e,
              { ...pagination, pageSize: e.target.value, pageNo: 0 })}
          />
        </Grid>
      </Grid>
      <ConfirmModalWindow confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <ApplicationMessage notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default PatientsView;
PatientsView.propTypes = {
  patients: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};
