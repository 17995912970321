import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  info: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  confirm_email_text: PropTypes.string.isRequired,
  showModalWindow: PropTypes.func.isRequired,
};

const AccountSettings = (props) => {
  const {
    info: { email_verified: emailVerified },
    confirm_email_text: confirmEmailText,
    user: { email },
    showModalWindow,
  } = props;

  return (
    <>
      <div className="white-tab clickable__edit__email" onClick={() => showModalWindow('email')}>
        <div className="left">EMAIL ADDRESS</div>
        {
            emailVerified
              ? (
                <>
                  <div className="mid-italic refresh__email">{email}</div>
                  <div className="right">
                    <span className="text-please-confirm">{confirmEmailText}</span>
                    <span className="verified-status green fa fa-check" />
                  </div>
                </>
              )
              : (
                <>
                  <div className="mid-italic refresh__email">{email}</div>
                  <div className="right">
                    <span className="text-please-confirm">{confirmEmailText}</span>
                    <span className="fa fa-chevron-right" />
                  </div>
                </>
              )
          }
      </div>
      <div className="white-tab clickable__edit__password" onClick={() => showModalWindow('passwordReset')}>
        <div className="left">RESET YOUR PASSWORD</div>
        <div className="mid placeholder">Click here to reset your password</div>
        <div className="right">
          <span className="fa fa-chevron-right" />
        </div>
      </div>
    </>
  );
};
export default AccountSettings;
AccountSettings.propTypes = IPropTypes;
