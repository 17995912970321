/* eslint-disable no-prototype-builtins, consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import RevokeConfirmation from './RevokeConfirmation';
import { shareRevoke, shareRecreate } from '../../../../util/ajax';

const ToggleRevoke = (props) => {
  const {
    share,
    accessClass,
    sharedWithYou,
    sharees,
  } = props;
  const { revoked_at: revokedAt, sharee_id: shareeId } = share;
  let $confirm;
  let $yes;
  let $no;
  let $form;
  let $status;

  const patientToggleClick = async (event) => {
    const target = window.$(event.target).closest('.simple-toggle__share-index');
    if (window.$(target)
      .hasClass('disable_click')) {
      return false;
    }
    if (window.$(target)
      .hasClass('on')) {
      $confirm = window.$(target)
        .parent()
        .parent()
        .parent()
        .prev();
      $form = window.$(target)
        .closest('form');
      $status = $form.parent()
        .parent()
        .find('.share-created__status');
      $yes = $confirm.find('.share-created__confirmation-btn-green');
      $no = $confirm.find('.share-created__confirmation-btn-red');
      $confirm.fadeIn();
      $form.hide();
      $status.hide();

      $yes.click(async (e) => {
        e.preventDefault();
        $confirm.hide();
        $form.fadeIn();
        $status.fadeIn();

        window.$(target).toggleClass('on');
        window.showSpinner();
        const formdata = $form.serializeArray().reduce((obj, item) => {
          // eslint-disable-next-line no-param-reassign
          obj[item.name] = item.value;
          return obj;
        }, {});
        await shareRevoke(formdata);
        window.hideSpinner();
        window.location.reload();
      });

      $no.click(() => {
        $confirm.hide();
        $form.fadeIn();
        $status.fadeIn();
      });
    } else {
      window.$(target)
        .find('.simple-toggle-circle__share-index')
        .animate({
          left: '+=22px',
        }, 200);
      window.$(target)
        .toggleClass('on');
      $form = window.$(target)
        .closest('form');
      window.showSpinner();
      const formdata = $form.serializeArray().reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item.name] = item.value;
        return obj;
      }, {});
      await shareRecreate(formdata);
      window.hideSpinner();
      window.location.reload();
    }
  };
  const email = sharees.hasOwnProperty(shareeId) ? sharees[shareeId].sharee_email : sharees.email;
  return (
    <React.Fragment>
      <RevokeConfirmation />
      <div className="share-created__toggle-revoke">
        { revokedAt
          ? (
            <form method="POST" action="/shares_recreate" className="share-created__actions">
              <input type="hidden" name="no_email" value="true" />
              <input type="hidden" name="accept_terms" value="true" />
              <input type="hidden" name="email_addresses" value={email} />
              <input type="hidden" name="patient_id" value={share.patient_id || ''} />

              {sharedWithYou === false
              && (
              <div className="toggle share-patient-toggle" data-js-toggle="false">
                <div className={`simple-toggle__share-index ${accessClass}`} onClick={patientToggleClick}>
                  <div className="simple-toggle-circle__share-index" />
                </div>
              </div>
              )
            }
            </form>
          ) : (
            <form method="DELETE" action="/shares_destroy" className="share-created__actions">
              <input type="hidden" name="id" value={share.id || ''} />
              {sharedWithYou === false
            && (
            <div className="toggle share-patient-toggle" data-js-toggle="true">
              <div className={`simple-toggle__share-index on ${accessClass}`} onClick={patientToggleClick}>
                <div className="simple-toggle-circle__share-index" />
              </div>
            </div>
            )
            }
            </form>
          )

     }
      </div>
    </React.Fragment>
  );
};
export default ToggleRevoke;
ToggleRevoke.propTypes = {
  share: PropTypes.object.isRequired,
  accessClass: PropTypes.string,
  sharedWithYou: PropTypes.bool,
  sharees: PropTypes.any,
};
ToggleRevoke.defaultProps = {
  sharedWithYou: false,
  accessClass: '',
  sharees: null,
};
