class LocalStorageApi {
  static storage = window.localStorage;

  static get = key => JSON.parse(LocalStorageApi.storage.getItem(key));

  static set = (key, item) => {
    if (item === undefined) {
      return;
    }

    LocalStorageApi.storage.setItem(key, JSON.stringify(item));
  }

  static remove = (key) => {
    LocalStorageApi.storage.removeItem(key);
  }
}

export { LocalStorageApi };
