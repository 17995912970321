const clinicalHistoryActionTypes = {
  ADD_CLINICAL_HISTORY_DATA: 'ADD_CLINICAL_HISTORY_DATA',
};

const AddClinicalHistoryInfo = (info, hideBanner) => ({
  type: clinicalHistoryActionTypes.ADD_CLINICAL_HISTORY_DATA,
  info,
  hideBanner,
});

export { AddClinicalHistoryInfo, clinicalHistoryActionTypes };
