/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Controls from '../common/components/forms/controls';
import { useForm, Form } from '../common/components/forms/useForm';
import Validation from '../common/utils/Validation';

const initialFValues = {
  id: 0,
  name: '',
  phone: '',
  country: '',
  accountNumber: '',
};

const useStyles = makeStyles(theme => createStyles({
  actionButtons: {
    textAlign: 'right',
    margin: theme.spacing(2),
  },
  submitButton: {
    boxShadow: 'none',
    backgroundColor: '#1A5EA7',
    '&:hover': {
      backgroundColor: '#4572A7',
      boxShadow: 'none',
    },
  },
  resetButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  inputText: {
    width: '90%',
  },
}));
const SitesForm = (props) => {
  const { submitHandler, formData, edit } = props;
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    ['name', 'phone', 'country', 'accountNumber'].forEach((val) => {
      if (val in fieldValues) {
        if (
          (val === 'phone'
            && fieldValues[val]
            && fieldValues[val].length > 0)
          || val !== 'phone'
        ) {
          const error = Validation(val, fieldValues[val], fieldsValidation) || '';
          temp[val] = error;
        } else {
          temp[val] = '';
        }
      }
    });
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
  };

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      submitHandler(values, resetForm, edit);
    }
  };
  const inputChange = (e) => {
    handleInputChange({
      ...e,
      target: { ...e.target, value: (e.target.value || {}).accountNumber },
    });
  };
  useEffect(() => {
    if (formData !== null) {
      setValues({
        ...formData,
      });
    } else {
      setValues({
        name: '',
        phone: '',
        country: '',
        accountNumber: '',
      });
    }
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            required
            label="Name"
            name="name"
            id="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Phone"
            name="phone"
            id="phone"
            value={values.phone}
            onChange={handleInputChange}
            inputProps={{ pattern: '(([0-9]{0,3})-[0-9]{0,3}-[0-9]{0,4})' }}
            error={errors.phone}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controls.Input
            required
            label="Country"
            name="country"
            id="country"
            value={values.country}
            onChange={handleInputChange}
            error={errors.country}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.AutoCompleteInput
            required
            value={values.accountNumber}
            label="Account Number"
            name="accountNumber"
            property="uuid"
            displaylabel="accountNumber"
            options={props.accountList}
            onChange={inputChange}
            error={errors.accountNumber && errors.accountNumber.length > 0}
            helperText={errors.accountNumber}
            disabled={edit}
          />
        </Grid>
        <Grid item xs={12} className={classes.actionButtons}>
          <Controls.Button
            className={classes.submitButton}
            type="submit"
            text="Save"
          />
          <Controls.Button
            text="Reset"
            color="default"
            className={classes.resetButton}
            onClick={() => {
              resetForm(formData);
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
SitesForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  formData: PropTypes.object,
  edit: PropTypes.bool,
  accountList: PropTypes.array,
};
SitesForm.defaultProps = {
  formData: null,
  edit: false,
  accountList: [],
};
export default SitesForm;

const fieldsValidation = {
  name: {
    error: '',
    validate: 'text',
    minLength: 2,
    maxLength: 256,
  },
  phone: {
    error: '',
    validate: 'phone',
    minLength: 7,
    maxLength: 15,
  },
  country: {
    error: '',
    validate: 'text',
    minLength: 2,
    maxLength: 256,
  },
  accountNumber: {
    error: '',
    validate: 'dropDown',
    required: true,
    maxLength: 256,
  },
};
