import { connect } from 'react-redux';

import { PatientTestsDropdownContainer } from './patient-tests-dropdown-container';

const mapStateToProps = store => ({
  canDownloadReport: store.patientPage.multiProduct.info.loggedUser.canDownloadReport,
  data: store.patientPage.multiProduct.info.testsDropdown,
});

const ConnectedPatientTestsDropdownContainer = connect(
  mapStateToProps,
)(PatientTestsDropdownContainer);

export { ConnectedPatientTestsDropdownContainer as PatientTestsDropdownContainer };
