class TissueSpecimenRetrieval {
  constructor(params) {
    this.pathologyLabName = params.pathologyLabName;
    this.pathologyLabPhone = params.pathologyLabPhone;
    this.pathologyLabFax = params.pathologyLabFax;
    this.tissueCollectionDate = params.tissueCollectionDate;
  }
}

export { TissueSpecimenRetrieval };
