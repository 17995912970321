/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import {
  Grid, TableHead, TableRow, TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import Controls from '../common/components/forms/controls';
import { useForm, Form } from '../common/components/forms/useForm';
import Table from '../common/Table';
import ClaimsTableBody from './ClaimsTableBody';
import Validation from '../common/utils/Validation';

const initialFValues = {
  name: '',
};

const useStyles = makeStyles(() => createStyles({
  root: {
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#ffffff',
  },
  searchInput: {
    width: '50%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
  borderRight: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
}));
const fieldsValidation = {
  name: {
    error: '',
    required: true,
    minLength: 1,
    maxLength: 50,
  },
};
const IPropTypes = {
  submithandler: PropTypes.func.isRequired,
  formData: PropTypes.object,
  claimsList: PropTypes.object,
  edit: PropTypes.bool,
};
const defaultProps = {
  formData: null,
  claimsList: {},
  edit: false,
};
const RolesForm = (props) => {
  const {
    submithandler, formData, claimsList, edit,
  } = props;
  const [resetToggel, setResetToggel] = useState(false);
  const [tableCheckedValues, setTableValues] = useState({});
  const [alert, setAlert] = useState(false);
  const claimData = claimsList;
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    ['name'].forEach((val) => {
      if (val in fieldValues) {
        const error = Validation(val, fieldValues[val], fieldsValidation) || '';
        temp[val] = error;
      }
    });

    setErrors({
      ...temp,
    });
    if (fieldValues === values) return Object.values(temp).every(x => x === '');
  };

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const formValues = { ...values, featureClaims: { ...tableCheckedValues } };
    if (validate()) {
      if (Object.keys(tableCheckedValues).length !== 0) {
        submithandler(formValues, resetForm, edit);
      } else {
        setAlert(true);
      }
    }
  };
  const fetchTableValue = (tableValues) => {
    setTableValues({ ...tableValues });
  };

  useEffect(() => {
    if (formData !== null) {
      setValues({
        ...formData,
      });
    }
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      {alert && (
        <Alert
          onClose={() => {
            setAlert(false);
          }}
          severity="error"
        >
          Atleast one feature&apos;s permission is required!
        </Alert>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            name="name"
            label="Name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name && errors.name}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.borderRight}>module</TableCell>
                <TableCell className={classes.borderRight}>feature</TableCell>
                <TableCell className={classes.borderRight}>permissions</TableCell>
              </TableRow>
            </TableHead>
            <ClaimsTableBody
              formData={formData}
              claimsList={claimData}
              resetToggel={resetToggel}
              setResetToggel={setResetToggel}
              fetchTableValue={fetchTableValue}
            />
          </Table>
          <div style={{ textAlign: 'right' }}>
            <Controls.Button type="submit" text="save" />
            <Controls.Button
              text="reset"
              color="default"
              onClick={() => {
                setResetToggel(true);
                resetForm(formData);
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};
export default RolesForm;
RolesForm.propTypes = IPropTypes;
RolesForm.defaultProps = defaultProps;
