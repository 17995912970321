import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { DashboardListContainer } from '../dashboard-list-container';
import { Order } from '../../../../models/order/order';

const IPropTypes = {
  dashboardData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.instanceOf(Order),
    ),
  ),
  initialDashboardListItems: PropTypes.number,
  showAllDashboardListItems: PropTypes.bool,
};

const defaultProps = {
  dashboardData: null,
  initialDashboardListItems: 2,
  showAllDashboardListItems: false,
};

const DashboardContainer = ({
  dashboardData,
  initialDashboardListItems,
  showAllDashboardListItems,
}) => {
  const [showAll, setShowAll] = useState(showAllDashboardListItems);

  if (!dashboardData) {
    return null;
  }

  const userPermissions = useSelector(state => state.global.initialProps.userPermissions);

  return dashboardData.map(data => (
    <DashboardListContainer
      key={data[0].id}
      list={data}
      initialDashboardListItems={initialDashboardListItems}
      showAllDashboardListItems={showAll}
      loadAdditionalDashboardListItems={() => setShowAll(true)}
      handleCollapseClick={() => setShowAll(false)}
      userPermissions={userPermissions}
    />
  ));
};

DashboardContainer.propTypes = IPropTypes;
DashboardContainer.defaultProps = defaultProps;

export { DashboardContainer };
