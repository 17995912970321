import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AlterationsTable } from '../alterations-table';
import styles from './alterations-container.module.scss';
import approvedIcon from '../../../assets/images/icons/patient-page/approved.svg';
import inOtherIcon from '../../../assets/images/icons/patient-page/in-other.svg';
import lackOfResponseIcon from '../../../assets/images/icons/patient-page/lack-of-response.svg';
import { AlterationInfoModal } from '../alteration-info-modal';
import { InfoButton } from '../info-button';

const IPropTypes = {
  tableHeader: PropTypes.string,
  reportDisclaimer: PropTypes.string,
  showFootnote: PropTypes.bool.isRequired,
  hasInfoModal: PropTypes.bool.isRequired,
  hasTherapyLegend: PropTypes.bool.isRequired,
  showContent: PropTypes.bool.isRequired,

};

const defaultProps = {
  reportDisclaimer: null,
  tableHeader: '',
};

const AlterationsContainer = (props) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  return (
    <div className={styles.container}>
      {
        showInfoModal
        && (
          <AlterationInfoModal
            onClose={() => setShowInfoModal(false)}
          >
            The table below annotates the variant allele fraction of
            altered circulating cell-free DNA (% cfDNA) detected in this sample.
            By default, alterations are listed in descending order by associated treatment options.
          </AlterationInfoModal>
        )
      }
      {
        props.showContent
        && (
          <>
            <h3 className={styles.smallHeader}>
              {props.tableHeader}
              {
                props.hasInfoModal && (
                  <InfoButton onClick={() => setShowInfoModal(true)} />
                )
              }
            </h3>
            {
              props.hasTherapyLegend && (
                <div className={styles.legendContainer}>
                  <span>
                    <img alt="" className={styles.legendIcon} src={approvedIcon} />
                    Approved in indication
                  </span>
                  <span>
                    <img alt="" className={styles.legendIcon} src={inOtherIcon} />
                    Approved in other indication
                  </span>
                  <span>
                    <img alt="" className={styles.legendIcon} src={lackOfResponseIcon} />
                    Lack of response
                  </span>
                </div>
              )
            }
            {
              props.reportDisclaimer && (
                <div className={styles.disclaimerContainer}>
                  {props.reportDisclaimer}
                </div>
              )
            }
            <AlterationsTable />
            <div className={styles.footnote}>
              {
                props.showFootnote && (
                  <p>
                    § See definitions section for more details.
                  </p>
                )
              }
            </div>
          </>
        )
      }
    </div>
  );
};

AlterationsContainer.propTypes = IPropTypes;
AlterationsContainer.defaultProps = defaultProps;

export { AlterationsContainer };
