import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import baseEtrfStyles from '../../../../assets/stylesheets/etrf/base.module.scss';
import styles from './diagnosis-condition-early-stage-expanded.module.scss';
import { PrimaryCode } from '../../diagnosis-condition-late-stage/primary-code';
import { SecondaryCodeContainer } from '../../diagnosis-condition-late-stage/secondary-code-container';
import { FormatHelper } from '../../helpers/format_helper';
import { DateInput } from '../../../generic/DateInput';

const IIcdCode = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

const IPropTypes = {
  saveEarlyStageDiagnosisInfo: PropTypes.func.isRequired,
  diagnosisEarlyStore: PropTypes.shape({
    diagnosisOriginalDiagnosis: PropTypes.string,
    diagnosisAdjuvantChemotherapy: PropTypes.string,
    diagnosisStage: PropTypes.string,
    diagnosisPrimaryCode: IIcdCode,
    diagnosisSecondaryCode: IIcdCode,
    diagnosisTertiaryCode: IIcdCode,
    diagnosisQuarternaryCode: IIcdCode,
    diagnosisQuinaryCode: IIcdCode,
    diagnosisSenaryCode: IIcdCode,
    disease: PropTypes.string,
  }).isRequired,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  highlightRequiredFields: false,
};

const DiagnosisConditionEarlyStageExpanded = ({
  diagnosisEarlyStore,
  diagnosisEarlyStore: {
    diagnosisOriginalDiagnosis, diagnosisStage,
    diagnosisPrimaryCode, diagnosisSecondaryCode, diagnosisTertiaryCode,
    diagnosisQuarternaryCode, diagnosisQuinaryCode, diagnosisSenaryCode,
    diagnosisAdjuvantChemotherapy, disease,
  },
  saveEarlyStageDiagnosisInfo,
  highlightRequiredFields,
}) => {
  const checkForHighlightingField = useCallback((fieldName) => {
    if (fieldName === 'diagnosisPrimaryCode') {
      return highlightRequiredFields && !diagnosisPrimaryCode.code;
    }
    return highlightRequiredFields && !diagnosisEarlyStore[fieldName];
  }, [highlightRequiredFields]);

  const storeSelectedItem = useCallback(id => (info) => {
    saveEarlyStageDiagnosisInfo({ [id]: info }, true);
  }, []);

  const updateDiagnosisStage = useCallback((event) => {
    const { target: { checked, name } } = event;
    const newDiagnosisStage = checked ? name : null;
    saveEarlyStageDiagnosisInfo({ diagnosisStage: newDiagnosisStage }, true);
  }, []);

  const codes = {
    selectedSecondaryCode: diagnosisSecondaryCode,
    selectedTertiaryCode: diagnosisTertiaryCode,
    selectedQuarternaryCode: diagnosisQuarternaryCode,
    selectedQuinaryCode: diagnosisQuinaryCode,
    selectedSenaryCode: diagnosisSenaryCode,
  };

  return (
    <div className={baseEtrfStyles.accordionSection}>
      <div className={baseEtrfStyles.sectionRow}>
        <span className={baseEtrfStyles.requiredFieldsText}>* Required fields</span>
      </div>
      <div className={baseEtrfStyles.sectionRow}>
        <div className={`${baseEtrfStyles.sectionColumn} ${styles.primaryCodeColumn}`}>
          <p className={`${baseEtrfStyles.fieldLabel} ${checkForHighlightingField('diagnosisPrimaryCode') ? baseEtrfStyles.highlighted : ''}`}>
            Primary Code
            <span className={baseEtrfStyles.highlightedAsterix}>*</span>
          </p>
          <PrimaryCode
            primaryCode={diagnosisPrimaryCode}
            storeSelectedItem={storeSelectedItem('diagnosisPrimaryCode')}
            highlightedBorder={checkForHighlightingField('diagnosisPrimaryCode')}
          />
        </div>
        <div className={baseEtrfStyles.sectionColumn}>
          <p className={baseEtrfStyles.fieldLabel}>
            Secondary Code(s):
          </p>
          <SecondaryCodeContainer
            codes={codes}
            storeSelectedItem={storeSelectedItem}
          />
        </div>
      </div>
      <div className={baseEtrfStyles.sectionRow}>
        <div className={`${baseEtrfStyles.sectionColumn} ${styles.diseaseOriginalDiagnosisColumn}`}>
          <div className={styles.cancerType}>
            <p>
              <span className={baseEtrfStyles.fieldLabel}>Cancer Type</span>
            </p>
            <div className={[styles.contentSpaceBreathable, styles.smallText, styles.cancerTypeContent].join(' ')}>
              {disease}
            </div>
          </div>
          <div className={[styles.column40, styles.grow].join(' ')}>
            <p>
              <span className={baseEtrfStyles.fieldLabel}>Original Diagnosis</span>
            </p>
            <DateInput
              className={styles.contentSpaceBreathable}
              value={diagnosisOriginalDiagnosis || ''}
              maxDate={FormatHelper.getTodaysDate()}
              onInputChange={storeSelectedItem('diagnosisOriginalDiagnosis')}
            />
          </div>
        </div>
        <div className={baseEtrfStyles.sectionColumn}>
          <p>
            <span className={baseEtrfStyles.fieldLabel}>Completion of adjuvant chemotherapy</span>
            <span className={[styles.smallText, styles.leftSpace].join(' ')}>(if applicable)</span>
          </p>
          <div className={styles.width40}>
            <DateInput
              className={styles.contentSpaceBreathable}
              value={diagnosisAdjuvantChemotherapy || ''}
              maxDate={FormatHelper.getTodaysDate()}
              onInputChange={storeSelectedItem('diagnosisAdjuvantChemotherapy')}
            />
          </div>
        </div>
      </div>
      <div className={baseEtrfStyles.sectionRow}>
        <div className={baseEtrfStyles.sectionColumn}>
          <p>
            <span className={`${baseEtrfStyles.fieldLabel} ${checkForHighlightingField('diagnosisStage') ? baseEtrfStyles.highlighted : ''}`}>Stage</span>
            <span className={baseEtrfStyles.highlightedAsterix}>*</span>
            <span className={[styles.smallText, styles.leftSpace].join(' ')}>Cancer types other than COLORECTAL are not currently accepted</span>
          </p>
          <div className={styles.radioButtonArea}>
            <div className={styles.stageColumn}>
              <input
                type="radio"
                name="Stage II Colorectal Cancer"
                className="stageCheckbox"
                checked={diagnosisStage === 'Stage II Colorectal Cancer'}
                onChange={updateDiagnosisStage}
              />
              <span className={`${checkForHighlightingField('diagnosisStage') ? styles.highlighted : ''} ${styles.smallText}`}>
                Stage II Colorectal Cancer
              </span>
            </div>
            <div className={styles.stageColumn}>
              <input
                type="radio"
                name="Stage III Colorectal Cancer"
                className="stageCheckbox"
                checked={diagnosisStage === 'Stage III Colorectal Cancer'}
                onChange={updateDiagnosisStage}
              />
              <span className={`${checkForHighlightingField('diagnosisStage') ? styles.highlighted : ''} ${styles.smallText}`}>
                Stage III Colorectal Cancer
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DiagnosisConditionEarlyStageExpanded.propTypes = IPropTypes;
DiagnosisConditionEarlyStageExpanded.defaultProps = defaultProps;

export { DiagnosisConditionEarlyStageExpanded };
