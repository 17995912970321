import { connect } from 'react-redux';

import { ClinicalHistoryEarlyStageExpanded } from './clinical-history-early-stage-expanded';
import { AddEarlyStageClinicalHistoryInfo } from '../../../../store/actions/orders/active-order/early-stage-clinical-history-info';

const mapStateToProps = ({
  orders: { activeOrder: { earlyStageClinicalHistoryInfo }, staticData },
}) => ({
  clinicalHistoryEarlyStore: earlyStageClinicalHistoryInfo,
  clinicalHistoryQuestions: staticData.relevantClinicalHistory,
});

const mapDispatchToProps = dispatch => ({
  saveEarlyStageClinicalHistoryInfo: (info, hideBanner) => dispatch(
    AddEarlyStageClinicalHistoryInfo(info, hideBanner),
  ),
});

const ConnectedClinicalHistoryEarlyStageExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(ClinicalHistoryEarlyStageExpanded);

export { ConnectedClinicalHistoryEarlyStageExpanded as ClinicalHistoryEarlyStageExpanded };
