import React from 'react';
import PropTypes from 'prop-types';

import { logger } from '../../../util/logger';

const IPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  onError: PropTypes.func.isRequired,
};

class EtrfErrorHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorEncountered: false,
    };
  }

  componentDidCatch(error, info) {
    logger.error(error);
    logger.warn(info);

    this.setState({
      errorEncountered: true,
    });

    window.showSpinner();
    this.props.onError();
  }

  render() {
    if (this.state.errorEncountered) {
      return null;
    }
    return this.props.children;
  }
}

EtrfErrorHandler.propTypes = IPropTypes;

export { EtrfErrorHandler };
