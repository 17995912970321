import { combineReducers } from 'redux';

import { shareModalReducer } from './share_modal';
import { releaseModalReducer } from './release_modal';

const lunarReducer = combineReducers({
  share: shareModalReducer,
  release: releaseModalReducer,
});

export { lunarReducer };
