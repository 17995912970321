import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';
import { StoreHelper } from '../../helpers/store_helper';
import { formatFirstLastName } from '../../util/order_util';

const IPropTypes = {
  physicianStore: PropTypes.object.isRequired,
  onToggle: PropTypes.func,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  onToggle: () => { },
  highlightRequiredFields: false,
};

const PhysicianInformationCollapsed = ({
  physicianStore: {
    client: {
      client, clientAccount, clientFax,
    },
    physician: {
      phone, lastname, firstname, email, fullname,
    },
    secondaryRecipient: {
      secondaryRecipientFirstname, secondaryRecipientLastname,
      secondaryRecipientPhone, secondaryRecipientFax,
    },
  },
  onToggle,
  highlightRequiredFields,
}) => (
  <div className="requests-list requests-list--collapsed--reactified">
    <div
      className="accordion-section collapsed flex-container__inner-container"
      type="button"
      onClick={onToggle}
    >
      <div className="flex-container__etrf-section">
        <div className="flex-row">
          <div className="patient-information-column-1-3">
            <CollapsedTab
              title="Account"
              value={client}
              highlightTitle={highlightRequiredFields && !client}
            />
            <CollapsedTab
              title="Physician"
              value={fullname || StoreHelper.formatPhysician(firstname, lastname)}
              highlightTitle={highlightRequiredFields && !lastname}
            />
            <CollapsedTab
              className="no-padding"
              title="Collaborator"
              value={formatFirstLastName(
                secondaryRecipientFirstname, secondaryRecipientLastname,
              )}
            />
          </div>
          <div className="patient-information-column-1-3">
            <CollapsedTab title="Account ID" value={clientAccount} />
            <CollapsedTab sectionClassname="lowercase" title="Email" value={email} />
            <CollapsedTab className="no-padding" sectionClassname="lowercase" title="Phone" value={secondaryRecipientPhone} />
          </div>
          <div className="patient-information-column-1-3">
            <CollapsedTab title="Fax" value={clientFax} />
            <CollapsedTab title="Phone" value={phone} />
            <CollapsedTab className="no-padding" title="Fax" value={secondaryRecipientFax} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

PhysicianInformationCollapsed.propTypes = IPropTypes;
PhysicianInformationCollapsed.defaultProps = defaultProps;

export { PhysicianInformationCollapsed };
