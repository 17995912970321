import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionTypes';

export const DEFAULT_SITE_ASSIGNMENT_STATE = fromJS({
  siteAssignmentList: null,
  sitesList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },
  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
  isOpen: false,
});

const SiteAssignmentReducer = handleActions(
  {
    [actionTypes.GET_SITES]: (state, action) => state
      .set('reload', false)
      .set('siteAssignmentList', action.payload.data.userSites)
      .set('totalRecords', action.payload.data.totalRecords),
    [actionTypes.GET_ALL_SITES]: (state, action) => state.set('sitesList', action.payload.data.sites || action.payload.data),
    [actionTypes.SAVE_SITE_ASSIGNMENT]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Site Added Successfully', type: 'success' }),

    [actionTypes.UPDATE_SITE_ASSIGNMENT]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Site Added Successfully', type: 'success' }),

    [actionTypes.DELETE_SITE_ASSIGNMENT]: state => state
      .set('reload', true)
      .setIn(['notify'], fromJS({ isOpen: true, message: 'Site Deleted Successfully', type: 'success' })),

    [actionTypes.SITE_ASSIGNMENT_ERROR]: (state, action) => state.setIn(['notify'], fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.SITE_ASSIGN_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_SITE_ASSIGNMENT]: (state, action) => state
      .set(action.payload.key, action.payload.value),
  },
  DEFAULT_SITE_ASSIGNMENT_STATE,
);
export default SiteAssignmentReducer;
