import { constants } from '../../config/constants';

const { INCOMPLETE } = constants.printModal.infoCompleteness;

class CurrentTherapyInfo {
  constructor(params) {
    this.targetedTherapy = params.targetedTherapy;
    this.immunotherapy = params.immunotherapy;
    this.chemotherapy = params.chemotherapy;
    this.combinationTherapy = params.combinationTherapy;
    this.specificTherapy = params.specificTherapy;
    this.dateOfTherapyInitiation = params.dateOfTherapyInitiation;
  }

  isComplete = () => (
    !!this.targetedTherapy
    || !!this.immunotherapy || !!this.chemotherapy
    || !!this.combinationTherapy || !!this.specificTherapy
  );

  formatForPrintModal = () => {
    const isCompleted = this.isComplete();

    const info = [
      this.targetedTherapy && 'Targeted Therapy',
      this.chemotherapy && 'Chemotherapy',
      this.immunotherapy && 'Immunotherapy',
      this.combinationTherapy && 'Combination Therapy',
    ].filter(Boolean).join(', ');

    const specificTherapyInfo = (!this.specificTherapy && !!this.dateOfTherapyInitiation) ? 'None' : this.specificTherapy;

    const fullTherapyInfo = (!!this.specificTherapy || !!this.dateOfTherapyInitiation)
      ? [info, specificTherapyInfo, this.dateOfTherapyInitiation].join(' - ') : info;

    return {
      title: 'CURRENT THERAPY',
      info: isCompleted ? fullTherapyInfo : INCOMPLETE,
      isCompleted,
    };
  };
}

export { CurrentTherapyInfo };
