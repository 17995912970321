import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  ghiList: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
  allGhiNumbers: PropTypes.object,
};

const defaultProps = {
  ghiList: null,
  allGhiNumbers: null,
};

class GhiList extends React.Component {
  static handleGhiNumberClick(e) {
    e.stopPropagation();
    const result = window.$(e.target).data('ghi-number');
    const currentValue = window.$('#practice-manager__ghi').val();
    const index = currentValue.lastIndexOf('; ');
    const trimmedCurrentValue = currentValue.substring(0, index !== -1 ? index + 2 : 0);

    window.$('#practice-manager__ghi').val(trimmedCurrentValue.concat(result, '; '));
    window.$('#practice-manager__ghi').focus();
    window.$('.ghi-list__dropdown').hide();
  }

  static handleInputClick() {
    const currentVal = window.$('#practice-manager__ghi').val();

    window.$('#practice-manager__ghi').val(currentVal.concat('; '));
    window.$('#practice-manager__ghi').focus();
    window.$('.ghi-list__dropdown').hide();
  }

  static handleInputChange(e) {
    const $ghiNumber = window.$('.ghi_number');
    const numGhis = window.$.trim(window.$(e.target).val()).split('; ').length;
    const currentGhi = window.$.trim(window.$(e.target).val()).split('; ')[numGhis - 1];
    const val = window.$.trim(currentGhi).replace(/ +/g, ' ').toLowerCase();

    if (currentGhi === '') {
      window.$('.ghi-list__dropdown').hide();
      return;
    }
    if (val === '') {
      window.$('.ghi-list__dropdown').hide();
      return;
    }

    window.$('.ghi-list__dropdown').show();
    window.$('.ghi-list__input-value').text(val);
    $ghiNumber.show().filter((_, elem) => {
      const text = window.$(elem).text().replace(/\s+/g, ' ').toLowerCase();
      return text.indexOf(val) === -1;
    }).hide();
  }

  componentDidMount() {
    window.$('#practice-manager__ghi').val(this.props.ghiList);
  }

  generateGhiList() {
    const ghiList = this.props.allGhiNumbers;

    return Object.entries(ghiList)
      .filter(([, value]) => value.length)
      .map(([key, value]) => (
        <div
          key={key}
          onClick={GhiList.handleGhiNumberClick}
          className="ghi_number"
          data-ghi-number={key}
          id={key}
        >
          {key}
          {' '}
          -
          {value[0].PRACTICENAME}
        </div>
      ));
  }

  render() {
    const ghiList = this.generateGhiList();

    return (
      <div className="grey-box__form__item-inner grey-box__form__item site-manager-ghi-list">
        <div className="ghi-errors" />
        <label
          className="grey-box__form__item-label ghi__list"
        >
          Site GHI Number
          <input
            onChange={GhiList.handleInputChange}
            type="text"
            id="practice-manager__ghi"
            className="input__ghi-number"
            placeholder="e.g. GHI-000000"
            autoComplete="off"
          />
        </label>
        <div className="ghi-list__dropdown">
          <div onClick={GhiList.handleInputClick} className="ghi-list__input-value" />
          <div className="ghi-list__dropdown-header">GHI List</div>
          <div className="divider-line" />
          {ghiList}
        </div>
      </div>
    );
  }
}

GhiList.propTypes = IPropTypes;
GhiList.defaultProps = defaultProps;

export default GhiList;
