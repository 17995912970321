import { OrdersEndpoints } from './endpoints';
import { ParamsHelper } from '../components/orders/helpers/params-helper';

export const printPDFURL = (envelopeId, orderId, productFamily, alreadyPrinted = false) => (
  `${OrdersEndpoints.PRINT_PDF}${ParamsHelper.createQueryParams({
    envelope_id: envelopeId,
    order_id: orderId,
    product_family: productFamily,
    print_again: alreadyPrinted,
  })}`
);
