import React, { useState, useEffect } from 'react';

import { SessionController } from '../networking/controllers/session';
import { LogoutWarningModal } from './session/LogoutWarningModal';
import { logger } from '../util/logger';

// 15min = 900000 ms
const initialTimeDuration = 900000;

const SessionEnforcer = () => {
  const [showModal, setShowModal] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(' 15 minutes');

  let timeDuration = initialTimeDuration; // default time before session times out
  const intervalDuration = 60000; // default interval time to check in with server 1min
  let redirectURL = '/';
  let sessionValidityCheckInterval;

  const timers = {
    sessionTimeout: null,
    oneMinuteTimeout: null,
    twoMinutesTimeout: null,
    lessThanAMinuteTimeout: null,
  };

  const timerTimes = {
    twoMinutesLeft: time => time - 2 * 60 * 1000, // 2 minutes
    oneMinuteLeft: time => time - 1 * 60 * 1000, // 1 minute
    lessThanAMinuteLeft: time => time - 30 * 1000, // 30 seconds (less than a minute)
  };


  const hideModal = () => setShowModal(false);
  const changeToTwoMinutes = () => {
    setShowModal(true);
    setTimeRemaining(' 2 minutes');
  };
  const changeToOneMinute = () => setTimeRemaining(' 1 minute');
  const changeToLessThanAMinute = () => setTimeRemaining(' less than a minute');

  const killSessionTimeoutTimers = () => {
    if (timers) {
      clearTimeout(timers.twoMinutesTimeout);
      clearTimeout(timers.oneMinuteTimeout);
      clearTimeout(timers.lessThanAMinuteTimeout);
      clearTimeout(timers.sessionTimeout);
    }
  };

  const endSession = (redirectTo) => {
    const redirectLink = redirectTo || redirectURL;
    const redirect = () => {
      window.location.assign(redirectLink);
    };
    killSessionTimeoutTimers();
    logger.log('session has expired');
    setTimeout(redirect, 3000);
  };

  const startModalTimer = (time) => {
    timers.twoMinutesTimeout = setTimeout(changeToTwoMinutes, timerTimes.twoMinutesLeft(time));
    timers.oneMinuteTimeout = setTimeout(changeToOneMinute, timerTimes.oneMinuteLeft(time));
    timers.lessThanAMinuteTimeout = setTimeout(
      changeToLessThanAMinute, timerTimes.lessThanAMinuteLeft(time),
    );
  };

  const startSessionTimeoutTimers = (t) => {
    const time = t || timeDuration;
    timers.sessionTimeout = setTimeout(endSession, time);
    startModalTimer(time);
  };

  const updateSessionTimeoutTimer = (updatedTime) => {
    if (updatedTime) {
      logger.log('time updated');
      timeDuration = updatedTime;
    }
    killSessionTimeoutTimers();
    startSessionTimeoutTimers(updatedTime);
  };

  const updateSessionFromServer = (timeLeft) => {
    if (timeLeft) {
      // ternary for backwards compatability with current version
      const timeLeftMiliseconds = typeof timeLeft === 'string' ? timeLeft.split(' ')[0] * 1000 : timeLeft * 1000;
      updateSessionTimeoutTimer(timeLeftMiliseconds);
    }
  };

  const getSessionValidityFromServer = () => {
    const getValidateSession = async () => {
      try {
        const response = await SessionController.checkSession();
        const { data } = response;
        if (data.redirect_to) {
          redirectURL = data.redirect_to;
        }
        if (data.valid_session === false) {
          endSession(redirectURL);
        }
        updateSessionFromServer(data.time_left);
      } catch (error) {
        logger.error(error);
      }
    };
    getValidateSession();
  };

  const refreshSession = async () => {
    try {
      const response = await SessionController.refreshSession();
      const { data: { time_left: timeLeft } } = response;
      updateSessionFromServer(timeLeft);
    } catch (error) {
      logger.error(error);
    }
  };

  const startServerSessionValidityInterval = () => {
    sessionValidityCheckInterval = setInterval(getSessionValidityFromServer, intervalDuration);
  };

  const onClose = () => {
    hideModal();
    refreshSession();
  };

  useEffect(() => {
    logger.log('session management running');
    startSessionTimeoutTimers();
    getSessionValidityFromServer();
    startServerSessionValidityInterval();
    return () => {
      killSessionTimeoutTimers();
      clearInterval(sessionValidityCheckInterval);
    };
  }, []);

  return (
    <React.Fragment>
      {
        showModal
          && (
            <LogoutWarningModal
              onSignIn={onClose}
              onClose={onClose}
              timeRemaining={timeRemaining}
            />
          )
        }
    </React.Fragment>
  );
};

export default SessionEnforcer;
