import HttpService from '../services/HttpService';
import { SessionEndpoints } from '../endpoints';
import { getToken, isInternalUser } from './helper';

const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

export class SessionController {
  static checkSession = () => (
    HttpService.get(SessionEndpoints.CHECK_SESSION,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      })
  );

  static refreshSession = () => (
    HttpService.get(SessionEndpoints.REFRESH_SESSION, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    })
  );
}
