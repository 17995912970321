import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { EtrfOrders } from '../etrf-orders/etrf-orders';
import { store } from '../../../store';
import { EtrfErrorHandler } from '../etrf_error_handler';
import { useAuthInterceptor } from '../../../hooks/use-auth-interceptor';
import { EMRDataSerializer } from '../../../networking/serializers/order/emr-data';

const IPropTypes = {
  viewAsUser: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string,
  userPermissions: PropTypes.object.isRequired,
  emrData: PropTypes.object,
};

const defaultProps = {
  authenticityToken: '',
  emrData: null,
};

const EtrfOrdersContainer = ({
  viewAsUser,
  authenticityToken,
  userPermissions,
  emrData,
}) => {
  useAuthInterceptor(authenticityToken);

  return (
    <Provider store={store}>
      <EtrfErrorHandler>
        <EtrfOrders
          viewAsUser={viewAsUser}
          authenticityToken={authenticityToken}
          userPermissions={userPermissions}
          emrData={emrData && EMRDataSerializer.deserialize(emrData)}
        />
      </EtrfErrorHandler>
    </Provider>
  );
};

EtrfOrdersContainer.propTypes = IPropTypes;
EtrfOrdersContainer.defaultProps = defaultProps;

export { EtrfOrdersContainer };
