import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialogBox from './commons/components/ModalDialogBox';
import PhysicianAdminAccessList from './commons/components/PhysicianAdminAccessList';

const PhysicianView = (props) => {
  const {
    physicinAdmins,
    downloadContractReport,
    eSignContractReport,
  } = props;
  const [showPhysicians, setShowPhysicians] = useState(false);

  const showPhysicianWindow = () => {
    setShowPhysicians(!showPhysicians);
  };
  const closePhysicianWindow = () => {
    setShowPhysicians(!showPhysicians);
  };
  const adminAccessOfPhysiciansToSign = physicinAdmins?.userList?.map((accessedPhysician) => {
    const lastName = accessedPhysician?.lastName;
    const firstName = accessedPhysician?.firstName;
    const lastAndFirstName = [firstName, lastName].join(', ');
    return (
      <PhysicianAdminAccessList
        key={accessedPhysician?.lastName}
        accessedPhysician={accessedPhysician}
        leftColName={`${lastAndFirstName}`}
        rightColName={accessedPhysician?.status}
        downloadContractReport={downloadContractReport}
        eSignContractReport={eSignContractReport}
        viewAsUser={physicinAdmins?.viewAsUser}
    />
    )
  });
  return (
    <React.Fragment>
      {physicinAdmins?.userList?.length > 0 && (<div className="share-index__body">
        <div className="share-index__body__header">
          <div className="share-index__body__header-text">
            <div className="share-index__body__header-title">
              USERS WHO CAN SIGN ON YOUR BEHALF
              <i className="fa fa-info-circle shares_info_circle modal-info" onClick={showPhysicianWindow} />
            </div>
          </div>
        </div>
        <div className="share-index__collaborators-container">
          {adminAccessOfPhysiciansToSign}
        </div>
      </div>)}
      <ModalDialogBox
        show={showPhysicians}
        showModal={closePhysicianWindow}
        ModalContent="Authorizing providers are sent an email to provide digital signature and validate signature delegation authority. Once the signature is complete, physician administrators can sign orders on behalf of physicians. Please email portal-support@guardanthealth.com for any questions."
      />
    </React.Fragment>
  );
};
export default PhysicianView;
PhysicianView.propTypes = {
  downloadContractReport: PropTypes.func.isRequired,
  eSignContractReport: PropTypes.func.isRequired,
};
