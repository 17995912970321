import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import RoleAssignmentContainer from './RoleAssignmentContainer';
import { getUserInfo } from '../helpers';
import Spinner from '../common/utils/spinner/Spinner';

const userRoles = getUserInfo('Role Assignment');
const RoleAssignmentRootContainer = () => (
  <Provider store={store}>
    <RoleAssignmentContainer roles={userRoles} />
    <Spinner />
  </Provider>
);
export default RoleAssignmentRootContainer;
