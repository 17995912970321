import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PendingSettings from './PendingSettings';

const IPropTypes = {
  info: PropTypes.object.isRequired,
  verified: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  isAutoCreated: PropTypes.bool.isRequired,
  showModalWindow: PropTypes.func.isRequired,
};
const IdentitySettings = (props) => {
  const {
    info,
    info: {
      firstname,
      lastname,
      dob: dobDisplay,
      welcome_code: welcomeCode,
      bloodcolldate,
      blood_collection_date_display: bloodCollectionDateDisplay,
      physician_last_name: physicianLastName,
    },
    verified,
    editable,
    isAutoCreated,
    showModalWindow,
  } = props;
  const empty = !(!welcomeCode || !bloodcolldate || !physicianLastName);
  return (
    <>
      {
        verified ? (
          <React.Fragment>
            <div className={`white-tab ${editable ? 'clickable__edit__name' : ''}`} onClick={() => (editable ? showModalWindow('name') : undefined)}>
              <div className="left">PATIENT&apos;S FULL NAME</div>
              <div className="mid-italic refresh__name">
                {`${firstname} ${lastname}`}
              </div>
              <div className="right">
                <span className="text-please-confirm" />
                <span className="verified-status green fa fa-check green" />
              </div>
            </div>
            <div className={`white-tab ${editable ? 'clickable__edit__dob' : ''}`} onClick={() => (editable ? showModalWindow('dob') : undefined)}>
              <div className="left">PATIENT&apos;S DATE OF BIRTH</div>
              <div className="mid-italic refresh__name">
                {moment(dobDisplay).format('MMM-DD-YYYY')}
              </div>
              <div className="right">
                <span className="text-please-confirm" />
                <span className="verified-status green fa fa-check green" />
              </div>
            </div>
            <div className={`white-tab ${editable ? 'clickable__edit__additional-info' : ''}`} onClick={() => (editable ? showModalWindow('additionalInfo') : undefined)}>
              <div className="left">ADDITIONAL IDENTIFICATION</div>
              {
                !isAutoCreated && (
                  <div className="mid-italic refresh__additional-info">
                    {
                      welcomeCode && (
                        <>
                          <span className="mid-italic">
                            {welcomeCode}
                          </span>
                          <span className="mid-italic">
                             &nbsp; (activation code)
                          </span>
                        </>
                      )
                    }
                    {
                      bloodcolldate && (
                        <>
                          <span className="mid-italic">
                            {bloodCollectionDateDisplay}
                          </span>
                          <span className="mid-italic">
                             &nbsp;(blood collection date)
                          </span>
                        </>
                      )
                    }
                    {
                      physicianLastName && (
                        <>
                          <span className="mid-italic">
                            {physicianLastName}
                          </span>
                          <span className="mid-italic">
                             &nbsp;(physician&apos;s last name)
                          </span>
                        </>
                      )
                    }
                    {empty === true && <div className="mid-italic">empty</div>}
                  </div>
                )
              }
              <div className="right">
                <span className="text-please-confirm" />
                <span className="verified-status green fa fa-check green" />
              </div>
            </div>
          </React.Fragment>
        )
          : <PendingSettings info={info} editable={editable} showModalWindow={showModalWindow} />
      }
    </>
  );
};
export default IdentitySettings;
IdentitySettings.propTypes = IPropTypes;
