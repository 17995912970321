import React from 'react';

class ClosestTrialSiteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matchKey: null,
      name: null,
    };
  }

  componentDidMount() {
    window.$(document).on('click touch', '.closest-trial-site__link', (e) => {
      const matchKey = e.target.getAttribute('data-match-key');

      const address = e.target.getAttribute('data-trial-address');

      const name = e.target.getAttribute('data-trial-name');

      const siteType = e.target.getAttribute('data-site-type');

      if (this.state.matchKey !== matchKey) {
        this.setState({
          address, matchKey, name, siteType,
        });
      }
    });
  }

  render() {
    const address = (
      <div className="modal-single-block">
        <div className="modal-single-line">{ this.state.siteType }</div>
        <div className="modal-single-line">{ this.state.name }</div>
        <div className="modal-single-line">{ this.state.address }</div>
      </div>
    );

    return (
      <div className="modal__container-outer closest-trial-site__modal">
        <div className="modal__container-inner">
          <div className="modal__content">
            <div className="closest-trial-site__details">
              <i className="fa fa-close modal-close" />
              <div className="closest-trial-site__container">
                <div className="header">
                  Closest Trial Site -
                  {this.state.matchKey}
                </div>
                { address }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClosestTrialSiteModal;
