import { TissueSpecimenRetrievalSerializer } from './tissue-specimen-retrieval';

class TestSelectionSerializer {
  static deserialize = (testSelection, specimens) => {
    if (!testSelection) {
      return {};
    }

    const {
      name, text, followOnTestOrdered, followOnTestText,
    } = testSelection;

    const tissueSpecimen = ((specimens || {}).tissueSpecimen || {});

    return {
      testSelectionType: {
        code: name,
        text: text && text[0],
      },
      treatmentResponse: {
        code: followOnTestOrdered && [followOnTestOrdered],
        text: followOnTestText && followOnTestText[0],
      },
      tissueSpecimenRetrieval: TissueSpecimenRetrievalSerializer.deserialize(
        tissueSpecimen,
      ),
    };
  }

  static serialize = ({ testSelectionType, treatmentResponse }) => ({
    name: testSelectionType.code,
    followOnTestOrdered: (treatmentResponse.code || [])[0],
  })
}

export { TestSelectionSerializer };
