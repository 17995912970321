import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  tableHeader: PropTypes.string.isRequired,
  tableHeaderCaption: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  cssStyle: PropTypes.func.isRequired,
};

const Table = ({
  tableHeader, tableHeaderCaption, tableData, cssStyle,
}) => (
  <div className="genes-analyzed">
    <div className="genes-analyzed__container">
      <h3 className="genes-analyzed__title reactify genes_analyzed__table-header">{tableHeader}</h3>
      <div className="genes_analyzed__table-header genes-analyzed__footnote">{tableHeaderCaption}</div>
    </div>
    <div>
      <div className="gene-panel genes-analyzed__panel">
        <div className="gene-panel__lists">
          <ul>
            {
              tableData.map(data => (
                <li key={data.name} className={cssStyle(data)}>
                  {data.name}
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  </div>
);

Table.propTypes = IPropTypes;

export default Table;
