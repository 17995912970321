export const ALTERATION_COLUMNS = {
  COLOR: 'color',
  ALTERATIONS: 'alterations',
  FREQUENCY: 'frequency',
  THERAPIES: 'therapies',
  CLINICAL: 'clinical',
};

export const DEFAULT_COLUMN_ORDER = [
  ALTERATION_COLUMNS.COLOR,
  ALTERATION_COLUMNS.ALTERATIONS,
  ALTERATION_COLUMNS.THERAPIES,
  ALTERATION_COLUMNS.CLINICAL,
  ALTERATION_COLUMNS.FREQUENCY,
];

export const ROW_ORDERS = {
  ALTERATION_ASC: 'alterationAsc',
  ALTERATION_DESC: 'alterationDesc',
  FREQUENCY_ASC: 'frequencyAsc',
  FREQUENCY_DESC: 'frequency',
  THERAPY_ASC: 'therapyAsc',
  THERAPY_DESC: 'therapyDesc',
  CLINICAL_ASC: 'clinicalAsc',
  CLINICAL_DESC: 'clinicalDesc',
};

export const DEFAULT_ROW_ORDER = ROW_ORDERS.THERAPY_ASC;

const sortAlterationColumnAsc = alterations => alterations.sort((a, b) => (
  a.alterationSortKey.localeCompare(b.alterationSortKey)
));

const sortAlterationColumnDesc = alterations => alterations.sort((a, b) => (
  b.alterationSortKey.localeCompare(a.alterationSortKey)
));

const sortFrequencyColumnAsc = alterations => alterations.sort((a, b) => (
  a.frequencySortKey - b.frequencySortKey
));

const sortFrequencyColumnDesc = alterations => alterations.sort((a, b) => (
  b.frequencySortKey - a.frequencySortKey
));

const sortTherapyColumnAsc = alterations => alterations.sort((a, b) => (
  a.therapySortKey - b.therapySortKey
));

const sortTherapyColumnDesc = alterations => alterations.sort((a, b) => (
  b.therapySortKey - a.therapySortKey
));

const sortClinicalColumnAsc = alterations => alterations.sort((a, b) => (
  a.clinicalSortKey.localeCompare(b.clinicalSortKey)
));

const sortClinicalColumnDesc = alterations => alterations.sort((a, b) => (
  b.clinicalSortKey.localeCompare(a.clinicalSortKey)
));

export const sortAlterations = (alterations, order) => {
  switch (order) {
    case ROW_ORDERS.ALTERATION_ASC:
      return sortAlterationColumnAsc(alterations);
    case ROW_ORDERS.ALTERATION_DESC:
      return sortAlterationColumnDesc(alterations);
    case ROW_ORDERS.FREQUENCY_ASC:
      return sortFrequencyColumnAsc(alterations);
    case ROW_ORDERS.FREQUENCY_DESC:
      return sortFrequencyColumnDesc(alterations);
    case ROW_ORDERS.THERAPY_ASC:
      return sortTherapyColumnAsc(alterations);
    case ROW_ORDERS.THERAPY_DESC:
      return sortTherapyColumnDesc(alterations);
    case ROW_ORDERS.CLINICAL_ASC:
      return sortClinicalColumnAsc(alterations);
    case ROW_ORDERS.CLINICAL_DESC:
      return sortClinicalColumnDesc(alterations);
    default:
      return sortTherapyColumnAsc(alterations);
  }
};
