import React from 'react';
import PropTypes from 'prop-types';

import styles from './comment.module.scss';

const IPropTypes = {
  comment: PropTypes.string,
};

const defaultProps = {
  comment: null,
};

const Comment = props => !!props.comment && (
  <div>
    <h3 className={styles.title}>
      Comments from our laboratory director
    </h3>
    <div className={styles.container}>
      <p className={styles.comment}>
        {props.comment}
      </p>
    </div>
  </div>
);

Comment.propTypes = IPropTypes;
Comment.defaultProps = defaultProps;

export { Comment };
