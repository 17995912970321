import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';
import { BillingInformationExpanded } from '../billing-information-expanded';
import { BillingInformationCollapsed } from '../billing-information-collapsed';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
};

const BillingInformationContainer = ({ enabled, onToggle }) => (
  <div>
    <GenericHeader
      enabled={enabled}
      handleContainerArrowClick={onToggle}
      label="Billing Information"
    />
    {
      enabled
        ? <BillingInformationExpanded />
        : <BillingInformationCollapsed onToggle={onToggle} />
    }
  </div>
);

BillingInformationContainer.propTypes = IPropTypes;
BillingInformationContainer.defaultProps = defaultProps;

export { BillingInformationContainer };
