import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { OrdersContainer } from '../../components/orders/orders-container';
import { logger } from '../../util/logger';
import { OrdersController } from '../../networking/controllers/orders';
import { ParamsHelper } from '../../helpers/params-helper';

const IPropTypes = {
  productFamily: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  saveGenericInfo: PropTypes.func.isRequired,
  clearActiveOrder: PropTypes.func.isRequired,
  saveOrder: PropTypes.func.isRequired,
  receiveError: PropTypes.func.isRequired,
  fetchStaticData: PropTypes.func.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
};

const defaultProps = {
  productFamily: null,
};

const EditOrderContainer = ({
  fetchStaticData, clearActiveOrder,
  receiveError, saveOrder, saveGenericInfo, productFamily,
  match: { params: { orderId } },
  location: { search },
  updateLoadingCount,
}) => {
  const [loading, setLoading] = useState(true);
  const [showOrderErrorFlag, setShowOrderErrorFlag] = useState(false);

  useEffect(() => {
    (async () => {
      updateLoadingCount(1);
      try {
        const { productFamily: productFamilyFromParams } = ParamsHelper.parseQueryParams(search);
        const order = await OrdersController.getOrder(orderId, productFamilyFromParams);

        await fetchStaticData(order.genericInfo.productFamily);

        if (!order) {
          clearActiveOrder();
        } else if (order.printDate) {
          receiveError(['This order has already been printed']);
          setShowOrderErrorFlag(true);
        } else {
          saveOrder(order);
          saveGenericInfo({ activeOrderId: order.orderId });
        }
      } catch (error) {
        logger.warn(error);
        if (error.response && error.response.status === 404) {
          window.location = '/404';
        }
      } finally {
        setLoading(false);
        updateLoadingCount(-1);
      }
    })();
  }, []);

  if (showOrderErrorFlag) {
    return <Redirect to="/orders" />;
  }

  return !loading && <OrdersContainer productFamily={productFamily} editingOrder />;
};

EditOrderContainer.propTypes = IPropTypes;
EditOrderContainer.defaultProps = defaultProps;

export { EditOrderContainer };
