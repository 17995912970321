import { createAction } from 'redux-actions';

export const NEWORDER_TAB_CHANGE = '@usermanagment-workflow/NEWORDER_TAB_CHANGE';
export const changeTabChange = createAction(NEWORDER_TAB_CHANGE);

export const NEWORDER_ON_CHANGE = '@usermanagment-workflow/NEWORDER_ON_CHANGE';
export const onChangeNewOrder = createAction(NEWORDER_ON_CHANGE);

export const ICR_CODES = '@usermanagment-workflow/ICR_CODES';
export const getICRCODES = createAction(ICR_CODES);

export const ICR_CODES21 = '@usermanagment-workflow/ICR_CODES21';
export const getICRCODES21 = createAction(ICR_CODES21);

export const GUARDANT_REVEAL = '@usermanagment-workflow/GUARDANT_REVEAL';
export const setGuardantReveal = createAction(GUARDANT_REVEAL);

export const ON_ERROR = '@usermanagment-workflow/ON_ERROR';
export const onError = createAction(ON_ERROR);

export const NEXT_SAVE_DATA = '@usermanagment-workflow/NEXT_SAVE_DATA';
export const saveData = createAction(NEXT_SAVE_DATA);

export const ORDER_ERROR = '@usermanagment-workflow/ORDER_ERROR';
export const showError = createAction(ORDER_ERROR);

export const REVIEW_ORDER_DATA = '@usermanagment-workflow/REVIEW_ORDER_DATA';
export const reviewOrderData = createAction(REVIEW_ORDER_DATA);

export const CLINICAL_HISTORY_DATA_360 = '@usermanagment-workflow/CLINICAL_HISTORY_DATA_360';
export const clinicalHistoryData360 = createAction(CLINICAL_HISTORY_DATA_360);

export const CLINICAL_HISTORY_DATA_REVEAL = '@usermanagment-workflow/CLINICAL_HISTORY_DATA_REVEAL';
export const clinicalHistoryDataReveal = createAction(CLINICAL_HISTORY_DATA_REVEAL);

export const RESET_STATE = '@usermanagment-workflow/RESET_STATE';
export const resetState = createAction(RESET_STATE);

export const defaultNewOrder = createAction('');

export const DELEGEE_FLAG = '@usermanagment-workflow/DELEGEE_FLAG';
export const updateDelegeeFlag = createAction(DELEGEE_FLAG);
