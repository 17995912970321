import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ParamsHelper } from '../helpers/params-helper';
import { logger } from '../util/logger';
import { OrdersController } from '../networking/controllers/orders';
import { updateLoadingCount } from '../store/actions/global';

const eSignatureCompleted = event => event === 'signing_complete';

const withSignatureChecking = (Component) => {
  const SignatureChecker = (props) => {
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
      (async () => {
        const {
          event, envelopeId, productFamilyId,
        } = ParamsHelper.parseQueryParams(props.location.search);

        if (!eSignatureCompleted(event) || !envelopeId || !productFamilyId) {
          setLoading(false);
          return;
        }

        try {
          updateLoadingCount(1);

          const { signed } = await OrdersController.checkSignatureCompletion(
            envelopeId, productFamilyId,
          );

          if (signed) {
            setSuccessMessage('Your order has been submitted electronically');
          }
        } catch (error) {
          logger.error(error);
        } finally {
          setLoading(false);
          updateLoadingCount(-1);
        }
      })();
    }, []);

    return !loading && <Component {...props} success={successMessage} />;
  };

  SignatureChecker.propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  return connect(
    null,
    dispatch => bindActionCreators({ updateLoadingCount }, dispatch),
  )(SignatureChecker);
};

export { withSignatureChecking };
