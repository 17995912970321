import { ClinicalTrial } from './clinical-trial';
import { Therapy } from './therapy';

class Alteration {
  constructor(params) {
    this.id = params.id;
    this.name = params.name;
    this.gene = params.gene;
    this.description = params.description;
    this.synonym = params.synonym;
    this.showPlasmaCopyNumber = params.showPlasmaCopyNumber;
    this.plasmaCopyNumber = params.plasmaCopyNumber;
    this.synonymous = params.synonymous;
    this.richDescription = params.richDescription;
    this.noTherapiesDetected = params.noTherapiesDetected;
    this.additionalTherapyInformation = params.additionalTherapyInformation;
    this.therapies = params.therapies;
    this.unknownSignificance = params.unknownSignificance;
    this.percentage = params.percentage;
    this.clinicalTrialsAvailable = params.clinicalTrialsAvailable;
    this.clinicalTrialsText = params.clinicalTrialsText;
    this.therapies = {
      approvedForThisDisease: params.therapies.approvedForThisDisease.map(t => new Therapy(t)),
      approvedForOtherDiseases: params.therapies.approvedForOtherDiseases.map(t => new Therapy(t)),
      lackOfResponse: params.therapies.lackOfResponse.map(t => new Therapy(t)),
    };
    this.clinicalTrials = params.clinicalTrials.map(ct => new ClinicalTrial(ct));
    this.shaded = params.shaded;
    this.alterationSortKey = params.alterationSortKey;
    this.therapySortKey = params.therapySortKey;
    this.frequencySortKey = params.frequencySortKey;
    this.clinicalSortKey = params.clinicalSortKey;
  }
}

export { Alteration };
