export const OrderingWarnings = {
  clinicalHistoryWarning: {
    name: 'clinicalHistoryWarning',
    description: 'All questions in “Relevant Clinical History” must be answered for insurance coverage determination.',
  },
  icdCodeMatchWarning: {
    name: 'icdCodeMatchWarning',
    description: ' None of the ICD-10 codes you’ve selected match the cancer type you’ve selected. At least one of the ICD-10 codes must match the cancer type in order for you to get the results of your testing.',
  },
  revealOrderingSurgicalResectionWarning: {
    name: 'revealOrderingSurgicalResectionWarning',
    description: 'Date of surgical resection is needed for recurring blood draws for the “Post-surgery” and “Surveillance” programs.',
  },
  insuredDOBMatchWarning: {
    name: 'insuredDOBMatchWarning',
    description: 'The patient’s date of birth in the ‘Patient Information’ section and ‘Billing information’ section do not match. Please update as appropriate.',
  },
};
