const currentTherapyActionTypes = {
  ADD_CURRENT_THERAPY_INFO: 'ADD_CURRENT_THERAPY_INFO',
};

const AddCurrentTherapyInfo = (info, hideBanner) => ({
  type: currentTherapyActionTypes.ADD_CURRENT_THERAPY_INFO,
  info,
  hideBanner,
});

export { currentTherapyActionTypes, AddCurrentTherapyInfo };
