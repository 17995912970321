import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './banner-message.module.scss';

const IPropTypes = {
  status: PropTypes.oneOf(['success', 'error']),
  messages: PropTypes.array,
  className: PropTypes.string,
  resetBanner: PropTypes.func.isRequired,
};

const defaultProps = {
  className: '',
  status: null,
  messages: [],
};

const BannerMessage = ({
  status,
  resetBanner,
  messages,
  className,
}) => {
  useEffect(() => {
    if (messages.length) {
      setTimeout(() => {
        resetBanner();
      }, 10000);
    }
  }, [status, messages, resetBanner]);

  if (!status && !messages.length) {
    return null;
  }

  return (
    <div className={`${styles.alertContainer} ${className}`}>
      <div className={[styles.banner, status === 'success' ? styles.success : styles.error].join(' ')}>
        <ul className={styles.message}>
          {
            status === 'success'
              ? <i className={`fa fa-check-circle ${styles.bannerLogo}`} />
              : <i className={`fa fa-exclamation-circle ${styles.bannerLogo}`} />
          }
          {messages.map(message => (
            /* eslint-disable react/no-danger */
            <li
              key={message}
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
            /* eslint-enable react/no-danger */
          ))}
        </ul>
        <button type="button" className={styles.closeBanner} onClick={resetBanner}>
          <i className="fa fa-close" />
        </button>
      </div>
    </div>
  );
};

BannerMessage.propTypes = IPropTypes;
BannerMessage.defaultProps = defaultProps;

export { BannerMessage };
