import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  TableBody as MuiTableBody,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import ActionsMenu from './ActionsMenu';

const useStyles = makeStyles(() => createStyles({
  card: {
    width: '100%',
    fontWeight: 'normal',
  },
  cardContent: {
    marginTop: '-3.5rem',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  tableCell: {
    borderBottom: 'none !important',
  },
  gridItem: {
    padding: '5px',
  },
  typography: {
    textTransform: 'capitalize',
  },
}));

export const ResponsiveTableBody = (props) => {
  const {
    rows, headers: columns, actions = null, userPermission,
  } = props;
  const classes = useStyles();
  return (
    <>
      {rows.length > 0 ? (
        rows.map((row) => {
          const columnContent = columns.map((column) => {
            switch (column.type) {
              case 'text':
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2" className={classes.typography}>
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">{row[column.name]}</Typography>
                    </Grid>
                  </>
                );
              case 'date':
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2" className={classes.typography}>
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">{moment(row[column.name]).format('DD-MMM-YYYY')}</Typography>
                    </Grid>
                  </>
                );
              case 'object':
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2" className={classes.typography}>
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">{(row[column.name].name || '').toString()}</Typography>
                    </Grid>
                  </>
                );
              case 'array':
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2" className={classes.typography}>
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">{row[column.name].map(x => x.name).join(',')}</Typography>
                    </Grid>
                  </>
                );
              default:
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2" className={classes.typography}>
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">{row[column.name]}</Typography>
                    </Grid>
                  </>
                );
            }
          });
          return (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Card className={classes.card}>
                  <CardHeader
                    title=""
                    action={(
                      <ActionsMenu
                        actions={actions}
                        row={row}
                        userPermission={userPermission}
                      />
                    )}
                  />
                  <CardContent className={classes.cardContent}>
                    <Grid container>{columnContent}</Grid>
                  </CardContent>
                </Card>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell>
            <Typography>No data to display</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
ResponsiveTableBody.propTypes = {
  rows: PropTypes.array,
  actions: PropTypes.array,
  headers: PropTypes.array.isRequired,
  userPermission: PropTypes.array,
};
ResponsiveTableBody.defaultProps = {
  rows: [],
  actions: null,
  userPermission: {},
};
const IPropTypes = {
  rows: PropTypes.array,
  actions: PropTypes.array,
  headers: PropTypes.array.isRequired,
  userPermission: PropTypes.array,
};
const defaultProps = {
  rows: [],
  actions: null,
  userPermission: {},
};

const TableBody = (props) => {
  const {
    rows = [], actions = null, headers, userPermission = {},
  } = props;
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MuiTableBody>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isMobile ? (
        <ResponsiveTableBody {...props} />
      ) : rows.length > 0 ? (
        rows.map((row) => {
          const cloumnsKeys = headers;
          const columns = cloumnsKeys.map((item) => {
            switch (item.type) {
              case 'text':
                return <TableCell key={Math.random()}>{row[item.name]}</TableCell>;
              case 'date':
                return <TableCell key={Math.random()}>{moment(row[item.name]).format('DD-MMM-YYYY')}</TableCell>;
              case 'object':
                return <TableCell key={Math.random()}>{(row[item.name].name || '').toString()}</TableCell>;
              case 'array':
                return <TableCell key={Math.random()}>{row[item.name].map(x => x.name).join(', ')}</TableCell>;
              default:
                return <TableCell key={Math.random()}>{row[item.name]}</TableCell>;
            }
          });
          const actionItems = isTab ? (
            <ActionsMenu actions={actions} row={row} userPermission={userPermission} />
          ) : (
            actions.map(item => (
              <IconButton
                aria-label={item.action}
                key={item.action}
                disabled={false}
                onClick={() => item.handleAction(row)}
                size="large"
              >
                {item.action === 'Edit' ? <EditIcon /> : <AddIcon />}
              </IconButton>
            ))
          );
          return (
            <TableRow key={row.uuid}>
              {columns}
              {actions && <TableCell>{actionItems}</TableCell>}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell variant="footer" colSpan={(headers.length || 0) + 1}>
            <Typography style={{ textAlign: 'center', lineHeight: '15' }}>No data to display</Typography>
          </TableCell>
        </TableRow>
      )}
    </MuiTableBody>
  );
};
export default TableBody;
TableBody.propTypes = IPropTypes;
TableBody.defaultProps = defaultProps;
