import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionTypes';

export const DEFAULT_PATIENT_MANAGEMENT_STATE = fromJS({
  patientsList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },
  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
  isOpen: false,
});

const PatientManagementReducer = handleActions(
  {
    [actionTypes.GET_PATIENTS]: (state, action) => state
      .set('reload', false)
      .set('patientsList', action.payload.data.patients)
      .set('totalRecords', action.payload.data.totalRecords),
    [actionTypes.DELETE_PATIENT]: state => state
      .set('reload', true)
      .setIn(['notify'], fromJS({ isOpen: true, message: 'Patient Deleted Successfully', type: 'success' })),

    [actionTypes.PATIENTS_ERROR]: (state, action) => state.setIn(['notify'], fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.PATIENT_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_PATIENTS]: (state, action) => state
      .set(action.payload.key, action.payload.value),
  },
  DEFAULT_PATIENT_MANAGEMENT_STATE,
);
export default PatientManagementReducer;
