import React from 'react';
import { Table as MuiTable } from '@mui/material';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TableContainer from '@mui/material/TableContainer';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => createStyles({
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '500',
      fontSize: '0.9rem',
    },
    '& tbody td': {
      fontWeight: '300',
      padding: '6px 16px',
    },
    '& tbody tr:hover': {
      backgroundColor: '#dee2e6',
      cursor: 'pointer',
    },
  },
}));
const Table = ({ children }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <MuiTable aria-label="table view" className={classes.table}>
        {children}
      </MuiTable>
    </TableContainer>
  );
};
export default Table;
Table.propTypes = {
  children: PropTypes.any.isRequired,
};
