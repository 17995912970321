export const OnlineOrderingFieldsLimits = {
  genericInputMaxLength: 22,
  emailAddressMaxLength: 24,
  additionalRecipientFirstNameMaxLength: 11,
  additionalRecipientLastNameMaxLength: 11,
  phoneNumberMaxLength: 15,
  streetAddress1MaxLength: 32,
  streetAddress2MaxLength: 12,
  cityMaxLength: 18,
  provinceMaxLength: 3,
  zipcodeMaxLength: 5,
  diagnosisMaxLength: 21,
  billingInsuredFirstNameMaxLength: 11,
  billingInsuredLastNameMaxLength: 11,
  specialTherapyMaxLength: 36,
  pathologyLabNameMaxLength: 36,
  pathologyLabPhoneMaxLength: 14,
};
