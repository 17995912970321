import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShareIndexCollaborator from './commons/components/ShareIndexCollaborator';
import ModalDialogBox from './commons/components/ModalDialogBox';
import AllPracticeShare from './commons/components/AllPracticeShare';

const SiteManagerView = (props) => {
  const {
    orderingPhysiciansOfSiteManager,
    allPracticeShares,

  } = props;
  const [showModal, setShowModal] = useState(false);
  const showModalWindow = () => {
    setShowModal(!showModal);
  };
  const closeWindow = () => {
    setShowModal(!showModal);
  };
  const orderingPhysicians = orderingPhysiciansOfSiteManager.map((info) => {
    const lastAndFirstName = (info[2].first_name && info[2].last_name) ? [info[2].first_name, info[2].last_name].join(', ') : info[2].user_name;

    return (
      <ShareIndexCollaborator
        key={info[0].GHPHYCLIENTID}
        leftColName={`${lastAndFirstName}`}
        leftColEmail={info[2].user_name || ''}
        rightColName="YOU CAN VIEW AND GRANT ACCESS"
        sharingTitle="YOU HAVE SITE MANAGER ACCESS TO"
        shareDescription={`All ${info[1]} of their patient reports from ${info[2].practice_name}`}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="share-index__body">
        <div className="share-index__body__header">
          <div className="share-index__body__header-text">
            <div className="share-index__body__header-title">
              Ordering Physicians
              <i className="fa fa-info-circle shares_info_circle modal-info" onClick={showModalWindow} />
            </div>
          </div>
        </div>
        <div className="share-index__collaborators-container">
          {orderingPhysicians}
          <AllPracticeShare
            allPracticeShares={allPracticeShares}
            sharedWithYou={false}
            display="theirs"
          />
        </div>
      </div>
      <ModalDialogBox show={showModal} showModal={closeWindow} ModalContent="See whose reports you have access to" />
    </React.Fragment>
  );
};
export default SiteManagerView;
SiteManagerView.propTypes = {
  orderingPhysiciansOfSiteManager: PropTypes.array.isRequired,
  allPracticeShares: PropTypes.array.isRequired,
};
