import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import UserViewContainer from './UserViewContainer';
import { getUserInfo, getUserAccessInfo } from '../helpers';
import Spinner from '../common/utils/spinner/Spinner';

const userRoles = getUserInfo('User');
const UserAccessInfo = getUserAccessInfo('User');
const UsersRootContainer = () => (
  <Provider store={store}>
    <UserViewContainer roles={userRoles} accessInfo={UserAccessInfo} />
    <Spinner />
  </Provider>
);
export default UsersRootContainer;
