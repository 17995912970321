import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../generic/button';

const IPropTypes = {
  toggleConfirmationModal: PropTypes.func.isRequired,
  onConfirmCallback: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  contentLineOne: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  contentLineTwo: PropTypes.string,
  leftButtonText: PropTypes.string.isRequired,
  rightButtonText: PropTypes.string.isRequired,
  leftButtonType: PropTypes.string,
  rightButtonType: PropTypes.string,
  withWarningIcon: PropTypes.bool,
};

const defaultProps = {
  contentLineTwo: '',
  withWarningIcon: true,
  leftButtonType: 'primary',
  rightButtonType: 'secondary',
};

const ConfirmationOverlay = ({
  toggleConfirmationModal, onConfirmCallback,
  header, contentLineOne, contentLineTwo,
  leftButtonText, rightButtonText, withWarningIcon,
  leftButtonType, rightButtonType,
}) => (
  <div className="modal__container-outer sign_up__site-manager billing-overlay-modal">
    <div className="modal__container-inner">
      <div className="modal__content">
        <div className="sign_up__site-manager billing-overlay discard-overlay">
          <div onClick={toggleConfirmationModal}><i className="fa fa-close modal-close" /></div>
          <div className="sign_up__site-manager__container">
            <div className="header">
              {header}
            </div>
            <div className="content billing-overlay-content">
              {
                withWarningIcon && (
                  <div className="discard-warning-container">
                    <div className="discard-warning-container__icon-container vertical-align">
                      <i className="discard-warning-container__icon fa fa-exclamation-triangle warning-message icon" />
                    </div>
                  </div>
                )
              }
              <div className="discard-warning-content">
                {contentLineOne}
              </div>
              <div className="discard-warning-content">{contentLineTwo}</div>
              <div className="billing-overlay-action-section">
                <Button type={leftButtonType} onClick={toggleConfirmationModal}>
                  <i className="fa fa-chevron-left" />
                  {leftButtonText}
                </Button>
                <Button type={rightButtonType} onClick={onConfirmCallback}>
                  {rightButtonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ConfirmationOverlay.propTypes = IPropTypes;
ConfirmationOverlay.defaultProps = defaultProps;

export { ConfirmationOverlay };
