import { connect } from 'react-redux';

import { toggleShareModalAction, toggleShareMessageAction } from '../../../../store/actions/patient-page/lunar/share_modal';
import { toggleReleaseModalAction, toggleReleaseMessageAction } from '../../../../store/actions/patient-page/lunar/release_modal';
import { LunarActionBar } from './LunarActionBar';

const mapStateToProps = store => ({
  showShareModal: store.patientPage.lunar.share.showShareModal,
  showReleaseModal: store.patientPage.lunar.release.showReleaseModal,
});

const mapDispatchToProps = dispatch => ({
  toggleShareModal: () => dispatch(toggleShareModalAction()),
  toggleShareMessage: () => dispatch(toggleShareMessageAction()),
  toggleReleaseModal: () => dispatch(toggleReleaseModalAction()),
  toggleReleaseMessage: () => dispatch(toggleReleaseMessageAction()),
});

const LunarActionBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LunarActionBar);

export { LunarActionBarContainer as LunarActionBar };
