import React from 'react';
import PropTypes from 'prop-types';

import { BiomarkersTable } from '../biomarkers-table';
import { BiomarkersTable as BiomarkersTableModel } from '../../../models/biomarkers-table';
import styles from './biomarkers-container.module.scss';

const IPropTypes = {
  data: PropTypes.instanceOf(BiomarkersTableModel),
  dictionary: PropTypes.object,
};

const defaultProps = {
  data: null,
  dictionary: null,
};

const BiomarkersContainer = ({ data, dictionary }) => (
  !!data && (
    <div className={styles.container}>
      <BiomarkersTable
        data={data.biomarkers}
        dictionary={dictionary}
      />
      <div className={styles.footnote}>
        {
          data.footnote
            && <p>{data.footnote}</p>
        }
      </div>
    </div>
  )
);

BiomarkersContainer.propTypes = IPropTypes;
BiomarkersContainer.defaultProps = defaultProps;

export { BiomarkersContainer };
