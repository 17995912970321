import { deserializeDate, serializeDate } from './helpers';

const MRN_TYPE = 'MRN';

class PatientSerializer {
  static deserialize = (patient) => {
    if (!patient) {
      return {};
    }

    const {
      hospitalizationStatus, type, demographic = {}, identifiers = [],
    } = patient;

    const { address = {} } = demographic;

    // idType could be either MRN or mrn
    const mrnObject = identifiers.find(e => new RegExp(MRN_TYPE, 'i').test(e.idType)) || {};

    const patientInfo = {
      patientStatus: hospitalizationStatus,
      faceSheet: demographic.faceSheet,
      patientLastName: demographic.lastName,
      patientFirstName: demographic.firstName,
      patientSex: demographic.sex,
      patientBirthDate: deserializeDate(demographic.dob),
      patientMedicalRecordNumber: mrnObject.id,
      patientStreet: address.address1,
      patientStreet2: address.address2,
      patientCity: address.city,
      patientCountry: address.country,
      patientType: type,
      patientEmail: demographic.email,
      patientPhone: demographic.phone,
    };

    if (address.country === 'United States') {
      patientInfo.patientState = address.state;
      patientInfo.patientProvince = null;
      patientInfo.patientPostalCode = null;
      patientInfo.patientZipCode = address.zipCode;
    } else {
      patientInfo.patientState = null;
      patientInfo.patientProvince = address.state;
      patientInfo.patientPostalCode = address.zipCode;
      patientInfo.patientZipCode = null;
    }

    return patientInfo;
  }

  static serialize = (patientInfo) => {
    const patient = {
      type: patientInfo.patientType,
      hospitalizationStatus: patientInfo.patientStatus,
      demographic: {
        firstName: patientInfo.patientFirstName,
        lastName: patientInfo.patientLastName,
        sex: patientInfo.patientSex,
        dob: serializeDate(patientInfo.patientBirthDate),
        email: patientInfo.patientEmail,
        phone: patientInfo.patientPhone,
        faceSheet: !!patientInfo.faceSheet,
        address: {
          address1: patientInfo.patientStreet,
          address2: patientInfo.patientStreet2,
          city: patientInfo.patientCity,
          country: patientInfo.patientCountry,
        },
      },
    };

    if (patientInfo.patientCountry === 'United States') {
      patient.demographic.address.state = patientInfo.patientState;
      patient.demographic.address.zipCode = patientInfo.patientZipCode;
    } else {
      patient.demographic.address.state = patientInfo.patientProvince;
      patient.demographic.address.zipCode = patientInfo.patientPostalCode;
    }

    if (patientInfo.patientMedicalRecordNumber) {
      patient.identifiers = [
        {
          idType: MRN_TYPE,
          id: patientInfo.patientMedicalRecordNumber,
        },
      ];
    }

    return patient;
  }
}

export { PatientSerializer };
