import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateCompletedOrderModalInfo,
  toggleModalAction,
} from '../../../../store/actions/orders/active-order/print-modal';
import { showErrorBanner } from '../../../../store/actions/orders/active-order/form_errors';
import { updateLoadingCount } from '../../../../store/actions/global';

import { CompletedOrderItem } from './completed-order-item';

const mapStateToProps = ({ global: { initialProps: { viewAsUser } } }) => ({
  loggedUserEmail: viewAsUser.email,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleModal: toggleModalAction,
  updateModal: updateCompletedOrderModalInfo,
  onError: showErrorBanner,
  updateLoadingCount,
}, dispatch);

const ConnectedCompletedOrderItem = connect(
  mapStateToProps, mapDispatchToProps,
)(CompletedOrderItem);

export { ConnectedCompletedOrderItem as CompletedOrderItem };
