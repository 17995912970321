import { AccountDropdown } from './account-dropdown';
import { deserializeDate } from './helpers';
import { PhysicianDropdown } from './physician-dropdown';
import { constants } from '../../../config/constants';
import { ConfigurationSerializer } from './configuration';

const MRN_TYPE = 'MRN';

const deserializeCoverage = (coverage) => {
  if (!coverage) {
    return {};
  }

  const billingInfo = {
    company: coverage.company,
    groupNumber: coverage.group,
    policyNumber: coverage.policy,
  };

  if (coverage.relationToInsured) {
    billingInfo.insuredRelationship = coverage.relationToInsured;
  }

  if (coverage.insured) {
    billingInfo.subscriberFirstName = coverage.insured.firstName;
    billingInfo.subscriberLastName = coverage.insured.lastName;
    billingInfo.dob = deserializeDate(coverage.insured.dob);
  }

  return billingInfo;
};

const deserializePatient = (patient) => {
  if (!patient) {
    return {};
  }

  const { address = {} } = patient;

  const mrnObject = patient.identifiers.find(i => i.type === MRN_TYPE) || {};

  const patientInfo = {
    patientLastName: patient.lastName,
    patientFirstName: patient.firstName,
    patientBirthDate: deserializeDate(patient.dob),
    patientMedicalRecordNumber: mrnObject.value,
    patientType: patient.type,
    patientEmail: patient.email,
    patientPhone: patient.phoneNumber,
    patientStreet: address.street,
    patientCity: address.city,
    patientState: address.state,
    patientZipCode: address.zipCode,
  };

  if (['Male', 'Female'].includes(patient.gender)) {
    patientInfo.patientSex = patient.gender;
  }

  return patientInfo;
};

const deserializeIcdCodes = (icdcodes) => {
  const deserializeIcdCode = icdCode => ({
    code: icdCode || '',
    description: '',
  });

  const codes = icdcodes || [];

  return {
    diagnosisPrimaryCode: deserializeIcdCode(codes[0]),
    diagnosisSecondaryCode: deserializeIcdCode(codes[1]),
    diagnosisTertiaryCode: deserializeIcdCode(codes[2]),
    diagnosisQuarternaryCode: deserializeIcdCode(codes[3]),
    diagnosisQuinaryCode: deserializeIcdCode(codes[4]),
    diagnosisSenaryCode: deserializeIcdCode(codes[5]),
  };
};

const mappingStages = (config, stageGroup) => (
  config.formConfiguration.stageMapping[stageGroup] || null
);

class EMRDataSerializer {
  static deserialize = ({
    account,
    patient,
    coverage,
    icdCodes,
    order = {},
    productFamilies,
    stageGroup,
  }) => {
    const diagnosisInfo = deserializeIcdCodes(icdCodes);
    const serializedConfiguration = ConfigurationSerializer.deserialize({ productFamilies });
    return {
      data: {
        patientInfo: deserializePatient(patient),
        billingInfo: deserializeCoverage(coverage),
        diagnosisInfo: {
          ...diagnosisInfo,
          diagnosisStage: mappingStages(
            serializedConfiguration[constants.etrfOrdering.productFamilies.g360],
            stageGroup,
          ),
        },
        earlyStageDiagnosisInfo: {
          ...diagnosisInfo,
          diagnosisStage: mappingStages(
            serializedConfiguration[constants.etrfOrdering.productFamilies.reveal],
            stageGroup,
          ),
        },
      },
      accounts: [AccountDropdown.deserialize(account)],
      provider: PhysicianDropdown.deserialize(order.authorizeProvider),
    };
  }
}

export { EMRDataSerializer };
