import { AlterationSerializer } from './alteration';

class AlterationsTableSerializer {
  static deserialize(data) {
    return data && {
      hasInfoModal: data['hasInfoModal?'],
      hasTherapyLegend: data['hasTherapyLegend?'],
      showInProgressMessage: data.showInProgressMessage,
      showCancelledMessage: data.showCancelledMessage,
      showContent: data.showContent,
      showFootnote: data.showFootnote,
      hideTherapies: data['hideTherapies?'],
      requestId: data.requestId,
      colorTable: data.colorTable,
      showSuperscript: data.showSuperscript,
      alterations: (data.alterations || []).map(AlterationSerializer.deserialize),
      therapyUrl: data.therapyUrl,
      showFrequencyColumn: data.showFrequencyColumn,
    };
  }
}

export { AlterationsTableSerializer };
