import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Controls from '../../admin/common/components/forms/controls';
import ValidationJson from '../validate.json';
import Validation from '../../admin/common/utils/Validation';
import { Countries } from '../../../constants/countries';
import { schema } from '../schema';

const useStyles = makeStyles(() => ({
  root: {
    height: '105px',
  },
  helperText: {
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    'overflow-y': 'clip'
  }
}));

const NumberFormatCustom = (props) => {
  const { inputRef, ...other } = props;
  const format = other.name === 'phone' ? '(###)-###-####' : '#####';
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      format={format}
    />
  );
};
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const PatientInfo = (props) => {
  const styles = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { newOrder } = props;
  const orderManagement = newOrder && newOrder.toJS();
  useEffect(() => {
    if (!orderManagement.stateList) {
      props.actions.getAllStates();
    }
  }, [orderManagement.stateList]);

  useEffect(() => {
    props.actions.onChange({ name: schema.patient.country, value: 'United States' });
  }, []);
  const onInputChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    if (name === 'phone') {
      value = value.replace(/[\D]/g, '', ' ');
    }
    if (name === 'identifiers') {
      value = [{
        id: value,
        idType: 'MRN',
      }];
    }
    const { patient } = ValidationJson;
    if (ValidationJson.patient[name]) {
      let error = Validation(name, value, patient) || '';
      error = ValidationJson.patient[name].required && error.trim().length > 0 && (value || '').length === 0 ? 'Required' : error;
      props.actions.setError({ name, value: error });
    }
    props.actions.onChange({ name: schema.patient[name], value });
    const isSelf = orderManagement.schema?.order?.billing?.primaryInsurance?.insuredRelationship;

    if (isSelf === 'Patient (self)') {
      if (name === 'firstName') {
        props.actions.onChange({
          name: schema.billing.subscriberFirstName,
          value: value?.substring(0, 11),
        });
      } else if (name === 'lastName') {
        props.actions.onChange({
          name: schema.billing.subscriberLastName,
          value: value?.substring(0, 11),
        });
      } else if (name === 'dob') {
        props.actions.onChange({
          name: schema.billing.dob,
          value: value !== 'Invalid date' && moment(value).format('YYYY-MM-DD').toString() !== 'Invalid date' ? moment(value).format('YYYY-MM-DD').toString() : undefined,
        });
      }
    }
  };
  return (
    <Grid container justifyContent="center" alignItems="flex-start">
      <Grid item lg={8} justifyContent="center">
        <Typography sx={{ my: '30px' }} component="h5" variant="h5">1. Patient Information</Typography>
        <Grid container className={styles.root} direction="row" spacing={2}>
          <Grid item lg={6} xs={12}>
            <Controls.Input
              label="First Name"
              name="firstName"
              fullWidth
              value={newOrder.getIn(schema.patient.firstName)}
              onChange={onInputChange}
              error={orderManagement.errors.firstName}
              inputProps={{ maxLength: 22 }}
              showHelperText="Required"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Controls.Input
              label="Last Name"
              name="lastName"
              fullWidth
              value={newOrder.getIn(schema.patient.lastName)}
              onChange={onInputChange}
              error={orderManagement.errors.lastName}
              inputProps={{ maxLength: 22 }}
              showHelperText="Required"
            />
          </Grid>
        </Grid>
        <Grid container className={styles.root} direction="row" spacing={2}>
          <Grid item lg={4.5} xs={12}>
            <Controls.DatePicker
              mask="__-__-____"
              label="DOB"
              name="dob"
              type="date"
              fullWidth
              inputFormat="MM-DD-YYYY"
              maxDate={moment()}
              minDate={moment().year(1900).month(0).date(1)}
              value={newOrder.getIn(schema.patient.dob) || null}
              error={orderManagement.errors.dob}
              FormHelperTextProps={{ classes: { root: styles.helperText } }}
              // eslint-disable-next-line consistent-return
              onChange={e => {
                props.actions.setError({ name: 'dob', value: '' });
                props.actions.onChange({ name: schema.patient.dob, value: undefined });
                if (!e) {
                  props.actions.setError({ name: 'dob', value: 'Required' });
                  return;
                }
                const val = moment(e);
                //invalid.isBefore(another), invalid.isAfter(another) return false
                const isFutureDate = val.isAfter(new Date(), 'day');
                const isPastDate = val.isBefore(moment().year(1900).month(0).date(1), 'day');
                if (!(isFutureDate || isPastDate)) {
                  onInputChange({
                    target: {
                      name: 'dob',
                      value: val.format('YYYY-MM-DD').toString()
                    },
                  });
                } else {
                  props.actions.setError({ name: 'dob', value: 'Please select a date between 01-01-1900 to today' });
                }
              }}
              showHelperText="Required"
            />
          </Grid>
          <Grid item lg={1.5} xs={12}>
            <Controls.Select
              label="Sex"
              name="sex"
              options={['Male', 'Female']}
              fullWidth
              MenuProps={MenuProps}
              value={newOrder.getIn(schema.patient.sex)}
              onChange={onInputChange}
              error={orderManagement.errors.sex}
              showHelperText="Required"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Controls.Select
              label="Patient Type"
              name="type"
              options={['New Patient', 'Existing Patient']}
              fullWidth
              value={newOrder.getIn(schema.patient.type)}
              onChange={onInputChange}
              error={orderManagement.errors.type}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.root} direction="row" spacing={2}>
          <Grid item lg={6} xs={12}>
            <Controls.Select
              label="Status"
              name="hospitalizationStatus"
              value={newOrder.getIn(schema.patient.hospitalizationStatus)}
              onChange={onInputChange}
              options={[
                'Hospital Inpatient',
                'Hospital Outpatient',
                'Non-hospital Patient',
              ]}
              error={orderManagement.errors.hospitalizationStatus}
              showHelperText="Required"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Controls.Input
              label="MRN"
              name="identifiers"
              fullWidth
              value={newOrder.getIn(schema.patient.identifiers, [{}])[0] ? newOrder.getIn(schema.patient.identifiers, [{}])[0].id : ''}
              onChange={onInputChange}
              error={orderManagement.errors.identifiers}
              inputProps={{ maxLength: 22 }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.root} direction="row" spacing={2}>
          <Grid item lg={7} xs={12}>
            <Controls.Input
              label="Patient Email Address"
              name="email"
              fullWidth
              inputProps={{ maxLength: 24 }}
              value={newOrder.getIn(schema.patient.email)}
              onChange={onInputChange}
              error={orderManagement.errors.email}
            />
          </Grid>
          <Grid item lg={5} xs={12}>
            <Controls.Input
              label="Patient Phone Number"
              name="phone"
              fullWidth
              id="input"
              value={newOrder.getIn(schema.patient.phone)}
              onChange={onInputChange}
              error={orderManagement.errors.phone}
              format="###-###-####"
              InputProps={{
                maxLength: 15,
                inputComponent: NumberFormatCustom,
              }}
              // eslint-disable-next-line no-unneeded-ternary
              InputLabelProps={{ shrink: newOrder.getIn(schema.patient.phone) ? true : false }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.root} direction="row" spacing={2}>
          <Grid item xs={12} lg={8}>
            <Controls.Input
              label="Street Address"
              name="address1"
              fullWidth
              value={newOrder.getIn(schema.patient.address1)}
              onChange={onInputChange}
              error={orderManagement.errors.address1}
              inputProps={{ maxLength: 32 }}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <Controls.Input
              label="Apt., Suite, etc."
              name="address2"
              fullWidth
              value={newOrder.getIn(schema.patient.address2)}
              onChange={onInputChange}
              error={orderManagement.errors.address2}
              inputProps={{ maxLength: 12 }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.root} direction="row" spacing={2}>
          <Grid item lg={4} xs={12}>
            <Controls.Input
              label="City"
              name="city"
              fullWidth
              value={newOrder.getIn(schema.patient.city)}
              onChange={onInputChange}
              error={orderManagement.errors.city}
              inputProps={{ maxLength: 18 }}
            />
          </Grid>
          <Grid item lg={2} xs={6}>
            <Controls.Select
              label="State"
              name="state"
              key="value"
              options={(orderManagement.stateList || []).map(x => x.code)}
              fullWidth
              MenuProps={MenuProps}
              value={newOrder.getIn(schema.patient.state)}
              onChange={onInputChange}
              error={orderManagement.errors.state}
            />
          </Grid>
          <Grid item lg={3} xs={6}>
            <Controls.Input
              label="Zip Code"
              name="zipCode"
              fullWidth
              value={newOrder.getIn(schema.patient.zipCode)}
              onChange={onInputChange}
              error={orderManagement.errors.zipCode}
              format="#####"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              // eslint-disable-next-line no-unneeded-ternary
              InputLabelProps={{ shrink: newOrder.getIn(schema.patient.zipCode) ? true : false }}
            />
          </Grid>
          <Grid item lg={3} xs={6}>
            <Controls.Select
              label="Country"
              name="country"
              key="value"
              options={Countries.filter(x => x.label === 'United States').map(x => x.label)}
              fullWidth
              MenuProps={MenuProps}
              // value={[...Countries.filter(x => x.label === 'United States').map(x => x.label)]}
              value={newOrder.getIn(schema.patient.country)}
              onChange={onInputChange}
              error={orderManagement.errors.country}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientInfo;

PatientInfo.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.objectOf({
    action: PropTypes.objectOf({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};
