import axios from 'axios';
import { restApiConfig } from './restApiConfig';
import { isInternalUser } from '../../../networking/controllers/helper';

const HttpService = axios.create();
const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
HttpService.defaults.baseURL = restApiConfig.apiBasePath;
HttpService.interceptors.request.use(
  async (config) => {
    HttpService.state = 'sent';
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      ...extHeader,
      Accept: '*',
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept-Language': restApiConfig.lang,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
HttpService.interceptors.response.use(
  response => response,
  // eslint-disable-next-line no-use-before-define
  err => Promise.reject(convertToErrorString(err.response.data || err)),
);

export default HttpService;

function convertToErrorString(payload) {
  let payloadData;
  if (Array.isArray(payload.errors)) {
    payloadData = payload.errors.map(x => `${x.code}-${x.message}`);
    return { message: payloadData };
  } return payloadData;
}
