class InterpretationAndMethodology {
  constructor(params) {
    this.interpretationAndUse = params.interpretationAndUse;
    this.methodology = params.methodology;
    this.limitations = params.limitations;
    this.references = params.references;
  }
}

export { InterpretationAndMethodology };
