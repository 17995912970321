import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { PatientInformationExpanded } from './patient-information-expanded';
import { savePatientInfo } from '../../../../store/actions/orders/active-order';

const mapStateToProps = (
  { orders: { activeOrder: { patientInfo, formErrors } } },
) => ({
  patientInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  savePatientInfo,
}, dispatch);

const ConnectedPatientInformationExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(PatientInformationExpanded);

export { ConnectedPatientInformationExpanded as PatientInformationExpanded };
