import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    top: '122px',
    paddingLeft: '1.5rem',
    right: '10%',
  },
  content: {
    width: '100%',
  },
}));
const IPropTypes = {
  notify: PropTypes.object,
  setNotify: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
const defaultProps = {
  notify: {},
  isLoading: false,
};
const ApplicationMessage = (props) => {
  const { notify, setNotify, isLoading } = props;
  const classes = useStyles();
  const handleClose = () => {
    setNotify({
      ...notify,
      isOpen: false,
    });
  };
  return !isLoading ? (
    <Snackbar
      className={classes.root}
      open={notify.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert className={classes.content} severity={notify.type} onClose={() => handleClose()}>
        {notify.message}
      </Alert>
    </Snackbar>
  ) : null;
};
const mapStateToProps = state => ({
  isLoading: state.spinner.get('isLoading'),
});
export default connect(mapStateToProps, {})(ApplicationMessage);
ApplicationMessage.propTypes = IPropTypes;
ApplicationMessage.defaultProps = defaultProps;
