import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import AccountViewContainer from './AccountsViewContainer';
import { getUserInfo } from '../helpers';
import Spinner from '../common/utils/spinner/Spinner';

const userRoles = getUserInfo('Account');
const AccountsRootContainer = () => (
  <Provider store={store}>
    <AccountViewContainer roles={userRoles} />
    <Spinner />
  </Provider>
);
export default AccountsRootContainer;
