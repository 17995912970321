/* eslint-disable no-nested-ternary */
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  FormControlLabel, Paper, Radio, RadioGroup, Typography, Grid,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import Controls from '../../admin/common/components/forms/controls';
import ValidationJson from '../validate.json';
import Validation from '../../admin/common/utils/Validation';
import { schema } from '../schema';

const useStyles = makeStyles(() => (
  {
    root: {
    },
    radioButton: {
      textAlign: 'center',
      color: 'rgba(0 ,0, 0 , 0.6)',
    },
    paper1: {
      backgroundColor: '#0058a5 !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      maxHeight: '150px',
    },
    paper2: {
      backgroundColor: '#0085f92b !important',
      padding: '5%',
      boxSizing: 'border-box',
      textAlign: 'left',
      width: '100%',
      height: '100%',
      maxHeight: '150px',
      border: '1px solid #0058a5',
      fontWeight: 'bold',
    },
    helperText: {
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
      'overflow-y': 'clip'
    }
  }));

const BillingInfo = (props) => {
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const { newOrder } = props;
  const orderManagement = newOrder && newOrder.toJS();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  useEffect(() => {
    if (!orderManagement.infoType && orderManagement.roOpenFromDashboard) {
      const value = newOrder.getIn(schema.billing.insuredRelationship) ? 'first' : newOrder.getIn(schema.billing.cardIncluded) ? 'second' : '';
      props.actions.onChange({
        name: ['infoType'],
        value,
      });
    }
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'insuredRelationship') {
      const isSelf = value === 'Patient (self)';
      props.actions.onChange({
        name: schema.billing.subscriberFirstName,
        value: isSelf ? newOrder.getIn(schema.patient.firstName)?.substring(0, 11) : '',
      });
      props.actions.onChange({
        name: schema.billing.subscriberLastName,
        value: isSelf ? newOrder.getIn(schema.patient.lastName)?.substring(0, 11) : '',
      });
      props.actions.onChange({
        name: schema.billing.dob,
        value: isSelf && moment(newOrder.getIn(schema.patient.dob)).format('YYYY-MM-DD').toString() !== 'Invalid date' ? newOrder.getIn(schema.patient.dob) : undefined,
      });
      props.actions.onChange({ name: schema.billing[name] || [name], value });
    } else {
      const billing = ValidationJson.Billing;
      if (ValidationJson.Billing[name]) {
        let error = Validation(name, value, billing) || '';
        if (name !== 'infoType') {
          error = ValidationJson.Billing[name].required && error.trim().length > 0 ? 'Required' : error;
        }
        props.actions.setError({ name: name === 'dob' ? 'billingdob' : name, value: error });
      }
      if (name === 'infoType') {
        props.actions.onChange({ name: schema.billing.cardIncluded, value: value === 'second' });
      }
      if (name === 'billingIndicator' && value === 'Institution (e.g. hospital)') {
        props.actions.onChange({ name: schema.billing[name] || [name], value: 'Institution' });
      } else {
        props.actions.onChange({ name: schema.billing[name] || [name], value });
      }
    }
  };


  return (
    <Grid container justifyContent="center" alignItems="flex-start">
      <Grid item lg={6} xs={12}>
        <Typography sx={{ my: '30px' }} component="h5" variant="h5">6. Billing Information</Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <RadioGroup
              name="infoType"
              value={orderManagement.infoType}
              row
              onChange={onInputChange}
            >
              <Grid container spacing={2} direction="row">
                <Grid item xs={6} direction="column" spacing={2} className={classes.radioButton}>
                  <Item>
                    <Typography component="div" variant="body1">Enter information</Typography>
                    <FormControlLabel
                      value="first"
                      label=""
                      labelPlacement="top"
                      control={<Radio checked={orderManagement.infoType === 'first'} />}
                    />
                  </Item>
                </Grid>

                <Grid item xs={6} direction="column" spacing={2} className={classes.radioButton}>
                  <Item>
                    <Typography component="div" variant="body1">Include Paper Copy with Sample</Typography>
                    <FormControlLabel
                      value="second"
                      label=""
                      labelPlacement="top"
                      control={<Radio checked={orderManagement.infoType === 'second'} />}
                    />
                  </Item>
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            {orderManagement.infoType === 'first' && (
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <Controls.Select
                    label="Insured"
                    name="insuredRelationship"
                    id="input"
                    onChange={onInputChange}
                    value={newOrder.getIn(schema.billing.insuredRelationship)}
                    options={['Patient (self)', 'Spouse', 'Child', 'Other']}
                    error={orderManagement.errors.insuredRelationship}
                    fullWidth
                    MenuProps={MenuProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controls.Select
                    label="Insurance Type"
                    name="billingIndicator"
                    id="input"
                    onChange={onInputChange}
                    value={newOrder.getIn(schema.billing.billingIndicator) === 'Institution' ? 'Institution (e.g. hospital)' : newOrder.getIn(schema.billing.billingIndicator)}
                    options={[
                      'Private Insurance',
                      'Medicare Part B',
                      'Medicaid',
                      'Institution (e.g. hospital)',
                      'Self Pay',
                    ]}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controls.Input
                    label="Insured First Name"
                    name="subscriberFirstName"
                    onChange={onInputChange}
                    value={newOrder.getIn(schema.billing.subscriberFirstName)}
                    id="input"
                    error={orderManagement.errors.subscriberFirstName}
                    fullWidth
                    inputProps={{ maxLength: 11 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controls.Input
                    label="Insured Last Name"
                    name="subscriberLastName"
                    id="input"
                    onChange={onInputChange}
                    value={newOrder.getIn(schema.billing.subscriberLastName)}
                    error={orderManagement.errors.subscriberLastName}
                    fullWidth
                    inputProps={{ maxLength: 11 }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controls.Input
                    label="Primary Insurance"
                    name="company"
                    id="input"
                    onChange={onInputChange}
                    value={newOrder.getIn(schema.billing.company)}
                    error={orderManagement.errors.company}
                    fullWidth
                    inputProps={{ maxLength: 22 }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controls.Input
                    label="Policy Number"
                    name="policyNumber"
                    id="input"
                    onChange={onInputChange}
                    value={newOrder.getIn(schema.billing.policyNumber)}
                    error={orderManagement.errors.policyNumber}
                    fullWidth
                    inputProps={{ maxLength: 22 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controls.Input
                    label="Group Number"
                    name="groupNumber"
                    id="input"
                    onChange={onInputChange}
                    value={newOrder.getIn(schema.billing.groupNumber)}
                    error={orderManagement.errors.groupNumber}
                    fullWidth
                    inputProps={{ maxLength: 22 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controls.DatePicker
                    mask="__-__-____"
                    label="Insured DOB"
                    name="dob"
                    type="date"
                    fullWidth
                    maxDate={moment()}
                    minDate={moment().year(1900).month(0).date(1)}
                    inputFormat="MM-DD-YYYY"
                    value={newOrder.getIn(schema.billing.dob) || null}
                    error={orderManagement.errors.billingdob}
                    FormHelperTextProps={{ classes: { root: classes.helperText } }}
                    InputLabelProps={{ shrink: newOrder.getIn(schema.billing?.dob) }}
                    // eslint-disable-next-line consistent-return
                    onChange={e => {
                      props.actions.setError({ name: 'billingdob', value: '' });
                      props.actions.onChange({ name: schema.billing.dob, value: undefined });
                      if (!e) return;
                      const val = moment(e);
                      //invalid.isBefore(another), invalid.isAfter(another) return false
                      const isFutureDate = val.isAfter(new Date(), 'day');
                      const isPastDate = val.isBefore(moment().year(1900).month(0).date(1), 'day');
                      if (!(isFutureDate || isPastDate) && newOrder?.schema?.billing?.name !== 'infoType') {
                        onInputChange({
                          target: {
                            name: 'dob',
                            value: val.format('YYYY-MM-DD').toString()
                          },
                        });
                      } else {
                        props.actions.setError({ name: 'billingdob', value: 'Please select a date between 01-01-1900 to today' });
                      }
                    }}

                  />
                </Grid>
              </Grid>
            )}
            {orderManagement.infoType === 'second' && (
              <div>
                <Grid container>
                  <Grid item xs={1}>
                    <Paper elevation={0} className={classes.paper1}>
                      <LockIcon fontSize="large" sx={{ color: 'white' }} />
                    </Paper>
                  </Grid>
                  <Grid item xs={10}>
                    <Paper elevation={0} className={classes.paper2}>
                      Please include a paper copy of the patient’s insurance information
                      with the blood sample
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default BillingInfo;

BillingInfo.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.objectOf({
    action: PropTypes.objectOf({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};
