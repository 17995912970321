export const formatFirstLastName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }

  if (firstName || lastName) {
    return lastName ? `${lastName},-` : `-,${firstName}`;
  }
  return '';
};
