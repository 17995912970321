import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ConfirmationOverlay } from '../confirmation-overlay';

const IPropTypes = {
  toggleElectronicSignatureModal: PropTypes.func.isRequired,
  togglePrintModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  physicianName: PropTypes.string.isRequired,
};

const ElectronicSignatureConfirmationOverlay = ({
  toggleElectronicSignatureModal,
  togglePrintModal,
  onConfirm,
  physicianName,
}) => {
  const closeModal = useCallback(() => {
    toggleElectronicSignatureModal();
    togglePrintModal();
  }, [toggleElectronicSignatureModal, togglePrintModal]);

  const handleConfirm = useCallback(async () => {
    toggleElectronicSignatureModal();
    onConfirm();
  }, [toggleElectronicSignatureModal, onConfirm]);

  const content = `An email notification will be sent to ${physicianName} for their electronic signature. This order will not be submitted until the electronic signature is received.`;

  return (
    <ConfirmationOverlay
      toggleConfirmationModal={closeModal}
      onConfirmCallback={handleConfirm}
      header="Guardant - Request for e-Signature"
      contentLineOne={content}
      leftButtonText="Back"
      rightButtonText="Confirm"
      withWarningIcon={false}
      leftButtonType="secondary"
      rightButtonType="primary"
    />
  );
};


ElectronicSignatureConfirmationOverlay.propTypes = IPropTypes;

export { ElectronicSignatureConfirmationOverlay };
