import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';
import { CurrentTherapyInformationExpanded } from '../current-therapy-information-expanded';
import { CurrentTherapyInformationCollapsed } from '../current-therapy-information-collapsed';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
};

const CurrentTherapyContainer = ({ enabled, onToggle }) => (
  <>
    <GenericHeader
      enabled={enabled}
      handleContainerArrowClick={onToggle}
      label="Current Therapy"
    />

    {
      enabled
        ? <CurrentTherapyInformationExpanded onToggle={onToggle} />
        : <CurrentTherapyInformationCollapsed onToggle={onToggle} />
    }
  </>
);

CurrentTherapyContainer.propTypes = IPropTypes;
CurrentTherapyContainer.defaultProps = defaultProps;

export { CurrentTherapyContainer };
