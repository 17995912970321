import { isEqual } from 'lodash';

import { constants } from '../../../../config/constants';

const {
  liquidToTissueProfiling, liquidProfiling, treatmentResponse,
} = constants.etrfOrdering.testSelectionTypes;

const testSelectionActionTypes = {
  ADD_TEST_SELECTION_INFO: 'ADD_TEST_SELECTION_INFO',
};

const AddTestSelectionInfo = (info, hideBanner) => (dispatch, getState) => {
  const finalInfo = { ...info };
  const { orders: { staticData: { testSelections: { testTypes } } } } = getState();

  if (info.testSelectionType !== undefined) {
    // info.testSelectionType may be null or an actual object
    finalInfo.isTissueOption = testTypes[liquidToTissueProfiling]
      .map(e => e.text)
      .includes(info.testSelectionType);

    finalInfo.testSelectionType = { code: null, text: null };

    if (info.testSelectionType) {
      // if info.testSelectionType is not null
      const superType = finalInfo.isTissueOption ? liquidToTissueProfiling : liquidProfiling;

      finalInfo.testSelectionType = testTypes[superType].find(
        e => e.text === info.testSelectionType,
      );
    }
  }

  if (info.treatmentResponse || info.treatmentResponse === false) {
    finalInfo.treatmentResponse = (
      info.treatmentResponse ? testTypes[treatmentResponse][0] : { code: null, text: null }
    );
  }

  dispatch({
    type: testSelectionActionTypes.ADD_TEST_SELECTION_INFO,
    info: finalInfo,
    hideBanner,
  });
};


const receiveTestSelectionInfo = (order, testTypes) => {
  const parsedOrder = { ...order };
  const { testSelectionInfo } = parsedOrder;

  if (testSelectionInfo) {
    if ((testSelectionInfo.testSelectionType || {}).code) {
      const testSelectionOptions = [
        ...testTypes[liquidProfiling],
        ...testTypes[liquidToTissueProfiling],
      ];

      testSelectionInfo.testSelectionType = testSelectionOptions.find(
        e => isEqual(e.code, testSelectionInfo.testSelectionType.code),
      );

      testSelectionInfo.isTissueOption = testTypes[liquidToTissueProfiling]
        .map(e => e.text)
        .includes(testSelectionInfo.testSelectionType.text);
    }

    if ((testSelectionInfo.treatmentResponse || {}).code) {
      testSelectionInfo.treatmentResponse = testTypes[treatmentResponse].find(
        e => isEqual(e.code, testSelectionInfo.treatmentResponse.code),
      );
    }
  }

  return parsedOrder;
};

export { testSelectionActionTypes, AddTestSelectionInfo, receiveTestSelectionInfo };
