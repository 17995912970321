class PhysicianSearch {
  static deserialize = data => ({
    id: String(data.id),
    label: `${data.lastname}, ${data.firstname}`,
    value: {
      id: String(data.id),
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.physician_phone,
      npi: data.npi,
    },
  })
}

export { PhysicianSearch };
