import React from 'react';
import ManagePermissionsSearch from './ManagePermissionsSearch';
import { getAllAdminPermissions } from '../util/ajax';

class ManagePermissions extends React.Component {
  static handlePermissionClick(event) {
    event.stopPropagation();
    const target = window.$(event.target).closest('.share-index__collaborator__top');
    window.$(target).find('.share-index__collaborator__left-col__email').toggle();
    window.$(target).find('.share-index__count-of-shares').toggle();
    window.$(target).next().slideToggle();
  }

  static sharedOrRevoked(physician) {
    if (physician.share_revoked_at !== null) {
      return (
        <div className="share-created__status manage-permissions__status">
          <span id={`share-status-${physician.id}`} className="share-status">REVOKED </span>
          <span id={`share-time-${physician.id}`}>
            {physician.share_updated_at}
            {' '}
          </span>
          <span id={`share-name-${physician.id}`}>
            BY
            {physician.share_editor_fullname}
          </span>
        </div>
      );
    }
    return (
      <div className="share-created__status manage-permissions__status">
        <span id={`share-status-${physician.id}`} className="share-status">SHARED </span>
        <span id={`share-time-${physician.id}`}>
          {physician.share_updated_at}
          {' '}
        </span>
        <span id={`share-name-${physician.id}`}>
          BY
          {physician.share_editor_fullname}
        </span>
      </div>
    );
  }

  static createSiteManagerBottom(user) {
    return (
      <div className="share-created" key={`${user.ghi_number + user.email}bottom`}>
        <div className="share-created__patient">
          <div className="share-created__text__wide">
            <div className="share-created__text-icon-report" />
            <div className="share-created__text-description">
              <div className=".share-created__text-description">
                All of your patient reports from
                {' '}
                {user.account_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      allUsers: null,
      users: null,
      loaded: false,
    };

    this.updateAllPermissions = this.updateAllPermissions.bind(this);
    this.filterList = this.filterList.bind(this);
  }

  componentDidMount() {
    if (window.gon.whitelistedFeatures.adminMenuLoad) {
      if (!this.state.loaded) {
        getAllAdminPermissions(this.updateAllPermissions);
      }
    } else {
      getAllAdminPermissions(this.updateAllPermissions);
    }
  }

  updateAllPermissions(data) {
    this.setState({ allUsers: data, loaded: true });
    if (this.state.users === null) this.setState({ users: data });
  }

  filterList(users) {
    this.setState({ users });
  }

  generatePermissionsList() {
    if (this.state.users === null) return (<div className="modal-loading-spinner" />);
    if (this.state.users === undefined) return (<div className="no-permissions"> No Permissions Found</div>);

    const list = [];
    this.state.users.forEach((user) => {
      list.push(this.createPermission(user[Object.keys(user)[0]]));
    });

    return (
      <div className="manage-bulk-shares__container">
        {list}
      </div>
    );
  }

  createPermission(user) {
    if (user.type === 'share') {
      return this.createPracticeManagerEntry(user);
    }
    return this.createSiteManagerEntry(user);
  }

  createPracticeManagerEntry(user) {
    let leftColEmail;

    if (user.nameOrEmail.indexOf('@') === -1) {
      leftColEmail = (
        <div className="share-index__collaborator__left-col__email">
          {user.email}
        </div>
      );
    }

    return (
      <div className="share-index__collaborator" key={user.email + user.createTime}>
        <div className="share-index__collaborator__top" onClick={ManagePermissions.handlePermissionClick.bind(this)}>
          <div className="share-index__collaborator__top-inner">
            <div className="share-index__collaborator__left-col">
              <div className="share-index__collaborator__left-col__name">
                <div className="share-index__collaborator__left-col__name-only">{user.nameOrEmail}</div>
                {leftColEmail}
              </div>
            </div>
            <div className="share-index__collaborator__right-col">
              <div className="share-index__count-of-shares__manage">
                SHARED
                {' '}
                {user.shares[0].share_recent_activity}
              </div>
            </div>
          </div>
        </div>
        <div className="share-index__collaborator__bottom">
          {this.generatePhysicianList(user.shares)}
        </div>
      </div>
    );
  }

  createSiteManagerEntry(user) {
    let leftColEmail;
    const bottom = ManagePermissions.createSiteManagerBottom(user);

    if (user.nameOrEmail.indexOf('@') === -1) {
      leftColEmail = (
        <div className="share-index__collaborator__left-col__email">
          {user.email}
        </div>
      );
    }

    return (
      <div className="share-index__collaborator" key={`${user.ghi_number + user.email}top`}>
        <div className="share-index__collaborator__top" onClick={ManagePermissions.handlePermissionClick.bind(this)}>
          <div className="share-index__collaborator__top-inner">
            <div className="share-index__collaborator__left-col">
              <div className="share-index__collaborator__left-col__name">
                <div className="share-index__collaborator__left-col__name-only">{user.nameOrEmail}</div>
                {leftColEmail}
              </div>
            </div>
            <div className="share-index__collaborator__right-col">
              <div className="share-index__count-of-shares__manage">
                SITE MANAGER
              </div>
            </div>
          </div>
        </div>
        <div className="share-index__collaborator__bottom">
          {bottom}
        </div>
      </div>
    );
  }

  generatePhysicianList(physicians) {
    const physicianList = [];

    physicians.forEach((physician) => {
      physicianList.push(
        <div className="share-created" key={physician.share_id}>
          <div className="share-created__patient">
            <div className="share-created__text__wide">
              <div className="share-created__text-icon-report" />
              <div className="share-created__text-description">
                {physician.physician_last_name}
                {' '}
                {physician.physician_first_name}
                {' '}
                (
                {physician.physician_account_number}
                )
              </div>
              { ManagePermissions.sharedOrRevoked(physician) }
              { this.createShareButton(physician) }
            </div>
          </div>
        </div>,
      );
    });
    return physicianList;
  }

  createShareButton(physician) {
    if (physician.share_revoked_at !== null) {
      return (
        <div className="share-created__toggle-revoke manage-permissions-toggle">
          <div className="toggle share-patient-toggle">
            <div
              className="simple-toggle__share-index"
              id={physician.id}
              data-name={physician.current_user_fullname}
              onClick={this.toggleShare.bind(this)}
            >
              <div className="simple-toggle-circle__share-index" />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="share-created__toggle-revoke manage-permissions-toggle">
        <div className="toggle share-patient-toggle">
          <div
            className="simple-toggle__share-index on"
            id={physician.id}
            onClick={this.toggleShare.bind(this)}
          >
            <div className="simple-toggle-circle__share-index" />
          </div>
        </div>
      </div>
    );
  }

  toggleShare(e) {
    const target = window.$(e.target).closest('.simple-toggle__share-index');
    e.stopPropagation();
    const key = Object.keys(this.state.allUsers[0])[0];

    const name = this.state.allUsers[0][key].current_user_fullname;

    if (window.$(target).hasClass('on')) {
      window.updateToggleShare(window.$(target).attr('id'), name);
    } else {
      window.updateToggleShare(window.$(target).attr('id'), name);
    }
  }

  render() {
    return (
      <div className="modal__container-outer manage_permissions">
        <div className="modal__container-inner">
          <div className="modal__content manage-permissions-content">
            <div className="manage_permissions-modal">
              <i className="fa fa-close modal-close" />
              <div className="manage_permissions__container">
                <div className="header">Manage Permissions</div>
                <div className="grey-box__form__item-container">
                  <div className="grey-box__form__item manage-permissions-container">
                    <div className="content manage-permissions-content">
                      <div className="manage-permissions-top-block">
                        <ManagePermissionsSearch
                          users={this.state.allUsers}
                          filterList={this.filterList}
                        />
                      </div>
                      { this.generatePermissionsList() }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManagePermissions;
