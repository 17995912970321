// TODO: Think about moving this component out of
// orders folder, since it's being used by others functionalities
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { KeyCodes } from '../../constants/key_codes';

const IPropTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
    selected: PropTypes.bool,
  })),
  resetThenSet: PropTypes.func,
  title: PropTypes.string,
  storeSelectedItem: PropTypes.func,
  defaultValue: PropTypes.string,
  defaultId: PropTypes.string,
  highlightedBorder: PropTypes.bool,
  arrowClass: PropTypes.string,
  itemLabelClass: PropTypes.string,
};

const defaultProps = {
  list: [],
  title: '',
  resetThenSet: () => {},
  storeSelectedItem: () => {},
  defaultValue: '',
  defaultId: '',
  highlightedBorder: false,
  arrowClass: '',
  itemLabelClass: '',
};

const tooltipShouldDisplay = word => (
  word.split(' ').length > 2
);

const DROPDOWN_PLACEHOLDERS = ['Select', 'Select Account'];

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      headerTitle: props.title,
      selectedItem: 0,
      currentSelection: null,
    };
    this.ul = React.createRef();
    this.close = this.close.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.references = props.list.map(() => React.createRef());
    this.getRefByIndex = this.getRefByIndex.bind(this);
  }

  componentDidMount() {
    const { defaultId, defaultValue, list } = this.props;
    if (defaultValue && list) {
      const matchingValue = defaultValue.split(', ').slice(-1)[0];
      const selectedItem = list.find(i => i.title.includes(matchingValue));
      this.selectItem(selectedItem.title, selectedItem.id, selectedItem.key);
    }

    if (defaultId && list) {
      const selectedItem = list.find(i => i.id === defaultId);
      this.selectItem(selectedItem.title, selectedItem.id, selectedItem.key);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.title !== newProps.title) {
      this.setState({ headerTitle: newProps.title });
    }
  }


  componentDidUpdate() {
    const { listOpen } = this.state;
    setTimeout(() => {
      if (listOpen) {
        window.addEventListener('click', this.close);
        document.addEventListener('keydown', this.handleKeyPress);
      } else {
        window.removeEventListener('click', this.close);
        document.removeEventListener('keydown', this.handleKeyPress);
      }
    }, 0);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
    window.removeEventListener('click', this.close);
  }

  getRefByIndex(index) {
    if (this.references.length < index + 1) {
      for (let i = 0; i <= index; i += 1) {
        this.references[i] = this.references[i] || React.createRef();
      }
    }
    return this.references[index];
  }

  scrollTo(itemId) {
    const { list } = this.props;
    const currentItem = list.find(i => i.id === itemId);
    const index = list.indexOf(currentItem);
    const currentReference = this.references[index] ? this.references[index].current : null;
    if (currentReference) {
      const offset = currentReference.offsetTop;
      const realParent = this.ul.current;
      realParent.scrollTop = offset;
    }
  }

  close() {
    this.setState({
      listOpen: false,
    });
  }

  selectItem(title, id, stateKey) {
    const { currentSelection } = this.state;
    if (currentSelection !== id) {
      const position = this.props.list.findIndex(i => i.id === id) + 1;

      this.setState({
        headerTitle: title,
        listOpen: false,
        currentSelection: id,
        selectedItem: position,
      });
      if (this.props.resetThenSet) {
        this.props.resetThenSet(id, stateKey);
      }
      this.props.storeSelectedItem(stateKey, title);
    }
  }

  toggleList() {
    const { currentSelection } = this.state;
    this.setState(prevState => ({
      listOpen: !prevState.listOpen,
    }), () => {
      if (currentSelection) {
        this.scrollTo(currentSelection);
      }
    });
  }

  handleKeyPress(event) {
    document.removeEventListener('keydown', this.handleKeyPress);
    if (event.keyCode === KeyCodes.ESC) {
      this.toggleList();
    } else if (event.keyCode === KeyCodes.ARROW_DOWN) {
      let selectedItem = 0;
      if (this.state.selectedItem < this.props.list.length) {
        this.setState(prevState => ({
          selectedItem: prevState.selectedItem + 1,
        }));
      } else {
        selectedItem = 1;
        this.setState({ selectedItem });
      }
    } else if (event.keyCode === KeyCodes.ARROW_UP) {
      let selectedItem = 0;
      if (this.state.selectedItem > 1) {
        this.setState(prevState => ({
          selectedItem: prevState.selectedItem - 1,
        }));
      } else {
        selectedItem = this.props.list.length;
        this.setState({ selectedItem });
      }
      // Note: This case ("enter" key) is for selecting the current element in the dropdown
    } else if (event.keyCode === KeyCodes.ENTER) {
      if (this.state.selectedItem > 0) {
        let currentItem = this.props.list.find(i => i.id === this.state.selectedItem);
        if (!currentItem) {
          currentItem = this.props.list[this.state.selectedItem - 1];
        }
        if (currentItem) {
          this.selectItem(currentItem.title, currentItem.id, currentItem.key);
        }
      } else {
        this.setState(prevState => ({
          listOpen: !prevState.listOpen,
        }));
      }
    } else if (event.keyCode === KeyCodes.TAB) {
      this.setState({
        listOpen: false,
      });
    }
    event.preventDefault();
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  render() {
    const { list, highlightedBorder } = this.props;
    const { listOpen, headerTitle } = this.state;
    const disabled = DROPDOWN_PLACEHOLDERS.includes(headerTitle);
    const headerTitleClass = ['dd-header-title', 'truncate', disabled ? 'inactive-section' : '', this.props.itemLabelClass].join(' ');
    return (
      <div className={['dd-wrapper state', 'dd-tooltip-container'].join(' ')}>
        <button type="button" className={['dd-header', highlightedBorder ? 'red-box' : ''].join(' ')} onClick={this.toggleList}>
          <div className={headerTitleClass}>{headerTitle}</div>
          {listOpen
            ? <i className={`${this.props.arrowClass} fa fa-chevron-up`} aria-hidden="true" />
            : <i className={`${this.props.arrowClass} fa fa-chevron-down`} aria-hidden="true" />
          }
        </button>
        {
          tooltipShouldDisplay(headerTitle)
          && (
          <span className="dd-tooltiptext">
            {headerTitle}
          </span>
          )
        }
        <ul id="dropdown-list" className={[listOpen ? 'dd-list' : 'dd-list__invisible']} ref={this.ul}>
          {listOpen
              && (
                list.map((item, index) => (
                  <li
                    ref={this.getRefByIndex(index)}
                    key={item.id}
                    id={item.id}
                  >
                    <button
                      type="button"
                      onClick={() => this.selectItem(item.title, item.id, item.key)}
                      className={['dd-list-item', this.state.selectedItem === item.id || list.indexOf(item) === (this.state.selectedItem - 1) ? 'dd-list-item__hover' : ''].join(' ')}
                    >
                      {item.title}
                      {' '}
                      {item.selected}
                    </button>
                  </li>
                ))
              )
          }
        </ul>
      </div>
    );
  }
}

Dropdown.propTypes = IPropTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
