import React, { useState } from 'react';
import FeedbackForm from './FeedbackForm';
import bubble from '../../../assets/images/icons/contact-bubble.svg';


function NeedHelpFooter(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button type="button" className="feedback-form__btn" style={{ display: ' block' }} onClick={() => setOpen(true)}>
        <div className="feedback-form__btn-desktop">
          CONTACT US
          <i className="fa fa-chevron-up" />
        </div>
        <div className="feedback-form__btn-mobile">
          <img
            alt=""
            src={bubble}
          />
        </div>
      </button>
      {open && <FeedbackForm setOpen={setOpen} {...props} />}
    </>
  );
}

export default NeedHelpFooter;
