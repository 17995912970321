class TestResultSerializer {
  static deserialize(data) {
    return data && {
      testName: data.test_name,
      testResult: data.test_result,
    };
  }
}

export { TestResultSerializer };
