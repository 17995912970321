import { combineReducers } from 'redux';

import { lunarReducer } from './lunar';
import { patientPageInfo } from './multi-product';


const patientPageReducer = combineReducers({
  lunar: lunarReducer,
  multiProduct: patientPageInfo,
});

export { patientPageReducer };
