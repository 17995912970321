import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getUsersData,
  addUsersData,
  deleteUsersData,
  editUsersData,
  changePagination,
  onChange,
  getUsersSearchData,
} from './actions';
import UsersView from './UsersView';
import {
  getAccountsData,
  onChange as accountOnChange,
} from '../accounts/actions';

const mapStateToProps = state => ({
  users: state.users,
  account: state.accounts,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUsersData,
      addUsersData,
      deleteUsersData,
      editUsersData,
      changePagination,
      getAccountsData,
      onChange,
      accountOnChange,
      getUsersSearchData,
    },
    dispatch,
  ),
});
export const ConnectedUsersView = connect(mapStateToProps, mapDispatchToProps)(UsersView);
export default ConnectedUsersView;
