/* eslint-disable consistent-return, no-unused-expressions */
import React, { useState } from 'react';
import {
  Chip, TextField, Autocomplete, createFilterOptions, Box, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const IPropTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.array,
  options: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  updateList: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  displaylabel: PropTypes.any.isRequired,
  onTextChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  manualFilter: PropTypes.any,
  InputProps: PropTypes.object.isRequired,
  showPropertyValue: PropTypes.bool.isRequired,
};
const defaultProps = {
  multiple: false,
  value: [],
  options: null,
  error: undefined,
  updateList: undefined,
  helperText: undefined,
  manualFilter: '',
};
function AutoCompleteInput(props) {
  const isName = RegExp(/^[A-Z0-9 ]+$/i);

  const {
    value,
    options = [],
    name,
    label,
    error,
    updateList,
    onChange,
    property,
    displaylabel,
    onTextChange,
    multiple,
    InputProps,
    showPropertyValue,
  } = props;
  const [localerr, updateerror] = useState(false);
  const getOptions = (e) => {
    if (isName.test(e.target.value)) {
      updateerror(false);
      onTextChange(e);
      updateList && updateList(e.target.value);
    } else {
      onTextChange(e);
    }
  };
  const handleChange = (e, newValue) => {
    if (multiple) {
      const values = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          values.push(options[i][property]);
        }
      }
      onChange({ ...e, target: { name, value: values } });
    } else {
      onChange({ ...e, target: { name, value: newValue } });
    }
  };
  const val = (!multiple && (options || []).find(x => x[property] === value)) || value;
  const filterOption = createFilterOptions({ matchFrom: 'any' });
  return (
    <Autocomplete
      {...props}
      value={(val && val[property]) || val}
      label={label}
      error={error}
      name={name}
      fullWidth
      onChange={handleChange}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options || []}
      defaultValue={value}
      onInputChange={(e) => {
        e && e.target.value === undefined && onTextChange && onTextChange(e);
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option[property] && showPropertyValue) {
          return option[property];
        }
        if (Array.isArray(displaylabel)) {
          return displaylabel
            .map(x => option[x])
            .join(' ')
            .replaceAll('  ', ' ');
        }
        if (option[displaylabel]) {
          return option[displaylabel];
        }
      }}
      renderOption={(propss, option) => (
        <li {...propss}>
          <Box key={option.icdCode} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component="div" variant="body1" color="primary">{`${option.icdCode} ${option.description}`}</Typography>
          </Box>
          )
        </li>
      )
      }
      freeSolo={!multiple}
      renderValue={selected => (multiple ? (
        <div>
          {selected.map(item => (
            <Chip key={item} label={item} />
          ))}
        </div>
      ) : (
        selected
      ))
      }
      filterOptions={props.manualFilter ? o => o : filterOption}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, ...InputProps }}
          variant="outlined"
          error={error || localerr}
          helperText={props.helperText || (localerr && 'Please enter the valid text')}
          onKeyDown={getOptions}
          label={label}
          name={name}
        />
      )}
    />
  );
}

export default AutoCompleteInput;
AutoCompleteInput.propTypes = IPropTypes;
AutoCompleteInput.defaultProps = defaultProps;
