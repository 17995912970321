import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceItem } from './reference-item';
import styles from './reference-menu.module.scss';

const IPropTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
};

const ReferenceMenu = ({ data, header }) => (
  <div className={styles.container}>
    <div className={styles.header}>{header}</div>
    <div className={styles.menuContainer}>
      {data.map(d => <ReferenceItem key={`refItem-${d.name}`} data={d} />)}
    </div>
  </div>
);

ReferenceMenu.propTypes = IPropTypes;

export { ReferenceMenu };
