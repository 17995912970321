import { connect } from 'react-redux';

import { AddRequests } from '../../../store/actions/requests';
import { RequestsContainer } from './requests-container';

const mapStateToProps = store => ({
  requests: store.requests.requests,
  rootUrl: store.requests.rootUrl,
});

const mapDispatchToProps = dispatch => ({
  saveRequestsPageData: requests => dispatch(AddRequests(requests)),
});

const ConnectedRequestsContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(RequestsContainer);


export { ConnectedRequestsContainer as RequestsContainer };
