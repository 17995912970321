import React from 'react';
import PropTypes from 'prop-types';
import SignOut from '../login/SignOut';

function MyGuardantSiteHeaderSignedIn(props) {
  const {
    email,
    userName,
    lastLogin,
    myGuardantSettingsPath,
    myGuardantTermsOfUsePath,
    formAuthenticityToken,
    className,
  } = props;
  return (
    <nav className={`navbar ${className}`}>
      <div className="row">
        <div className="container navbar-container">
          <div className="navbar-header">
            <button className="navbar-toggle nav-icon__hamburger" type="button">
              <div className="fa fa-bars" id="nav-icon" />
            </button>
            <a className="navbar-brand" href="/myguardant">
              <img
                alt="Brand"
                className="myguardant-brand-img hidden-xs"
                src="/assets/patients/my-guardant-logo-with-text-564a398b2f545f7fb2a1c9e82542da7704e6d255991469e9806fb7147aa34ce6.svg"
              />
            </a>
            <div className="navbar-mobile-headline navbar-mobile-headline__patient-portal visible-xs">
              <img
                alt="Brand"
                className="myguardant-brand-img visible-xs"
                src="/assets/patients/my-guardant-logo-with-text-564a398b2f545f7fb2a1c9e82542da7704e6d255991469e9806fb7147aa34ce6.svg"
              />
            </div>
            <div className="navbar-mobile-fullname visible-xs">
              {userName === ' ' ? email : userName}
            </div>
          </div>
          <div className="hidden-xs">
            <ul className="nav navbar-nav myguardant">
              <li>
                <a className="active main-link_" href="/myguardant/dashboard">
                  YOUR REPORT
                </a>
              </li>
            </ul>
            <div className="menu-user-container hidden-xs">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                aria-expanded="false"
                aria-haspopup="true"
                className="dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                role="button"
              >
                <p className="menu-user-name">{userName === ' ' ? email : userName}</p>
                <i className="fa fa-chevron-up" />
                <i className="fa fa-chevron-down" />
              </a>
              <ul className="dropdown-menu">
                <li className="no-hover">
                  <div className="text bold">{email}</div>
                </li>
                <li className="no-hover">
                  <div className="text">
                    {lastLogin}
                  </div>
                </li>
                <li className="li-divider no-hover" />
                <li>
                  <a href={myGuardantSettingsPath}>Settings</a>
                </li>
                <li>
                  <a target="_blank" href={myGuardantTermsOfUsePath} rel="noreferrer">
                    Terms and Conditions of Use
                  </a>
                </li>
                <SignOut authenticityToken={formAuthenticityToken} />
                <li className="li-divider no-hover" />
                <li className="no-hover">
                  <div className="text">Call us at 1-855-698-8887</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MyGuardantSiteHeaderSignedIn;
MyGuardantSiteHeaderSignedIn.propTypes = {
  email: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  lastLogin: PropTypes.string.isRequired,
  myGuardantSettingsPath: PropTypes.string.isRequired,
  myGuardantTermsOfUsePath: PropTypes.string.isRequired,
  formAuthenticityToken: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
