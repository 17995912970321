import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleRevoke from './commons/components/ToggleRevoke';
import { getStatusMessage } from './helpers';

const Share = (props) => {
  const {
    share,
    accessClass,
    sharedWithYou,
    sharees,
    sharers,
    physicians,
  } = props;
  const {
    physician_id: physicianId,
    revoked_at: revokedAt,
    sharer_id: sharerId,
  } = share;
  const [showStatus, setShowStatus] = useState(true);
  const showShareStatus = (status) => {
    if (status === true) {
      setShowStatus(true);
    } else {
      setShowStatus(false);
    }
  };
  const shareClass = revokedAt && 'share-created--revoked';
  return (
    <div className={`share-created ${shareClass}`}>
      <div className={`share-created__patient ${shareClass}`}>
        {
        sharedWithYou ? (
          <div className="share-created__text">
            <div className="share-created__text-icon-report" />
            <div className="share-created__text-description">
              {
                physicianId ? `All ${physicians[physicianId].report_count_for_practice} of your patient reports`
                  : `All ${sharers[sharerId].number_shareable_reports} of your patient reports`
              }
            </div>
          </div>
        ) : (
          <div className="share-created__text">
            <div className="share-created__text-icon-report" />
            <div className="share-created__text-description">
              {
                physicianId ? `All ${physicians[physicianId].report_count_for_practice} of their patient reports`
                  : `All ${sharers[sharerId].number_shareable_reports} of their patient reports`
              }
            </div>
          </div>
        )
        }
        {showStatus && (
        <div className="share-created__status">
          {getStatusMessage(share)}
        </div>
        )}
        <ToggleRevoke
          share={share}
          accessClass={accessClass}
          sharedWithYou={sharedWithYou}
          showShareStatus={showShareStatus}
          sharees={sharees}
          sharers={sharers}
        />
      </div>
    </div>
  );
};

export default Share;
Share.propTypes = {
  share: PropTypes.object.isRequired,
  sharedWithYou: PropTypes.bool,
  accessClass: PropTypes.string.isRequired,
  physicians: PropTypes.object,
  sharees: PropTypes.object,
  sharers: PropTypes.object,
};
Share.defaultProps = {
  sharedWithYou: false,
  physicians: null,
  sharees: null,
  sharers: null,
};
