import { connect } from 'react-redux';

import { NewTestRequisition } from './new-test-requisition';

const mapStateToProps = store => ({
  userPermissions: store.global.initialProps.userPermissions,
});

const ConnectedNewTestRequisition = connect(mapStateToProps)(NewTestRequisition);

export { ConnectedNewTestRequisition as NewTestRequisition };
