import { connect } from 'react-redux';

import { ReferencesContainer } from './references-container';

const mapStateToProps = store => ({
  data: store.patientPage.multiProduct.info.references,
});

const ConnectedReferencesContainer = connect(mapStateToProps)(ReferencesContainer);

export { ConnectedReferencesContainer as ReferencesContainer };
