import { connect } from 'react-redux';

import { PatientPageAlert } from './patient-page-alert';

const mapStateToProps = store => ({
  loggedUser: store.patientPage.multiProduct.info.loggedUser,
  data: store.patientPage.multiProduct.info.alert,
});

const ConnectedPatientPageAlert = connect(
  mapStateToProps,
)(PatientPageAlert);

export { ConnectedPatientPageAlert as PatientPageAlert };
