import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import PatientManagementContainer from './PatientsContainer';
import { getUserInfo } from '../helpers';
import Spinner from '../common/utils/spinner/Spinner';

const userRoles = getUserInfo('Patient Management');
const PatientsRootContainer = () => (
  <Provider store={store}>
    <PatientManagementContainer roles={userRoles} />
    <Spinner />
  </Provider>
);
export default PatientsRootContainer;
