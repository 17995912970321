import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { getAuthClient } from '../login/AuthClient';

const PatientSignIn = (props) => {
  const {
    authenticityToken,
  } = props;
  const inputEl = useRef(null);
  useEffect(() => {
    inputEl.current.focus();
  }, []);

  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const patientFormSubmit = (e) => {
    e.preventDefault();
    window.showSpinner();
    Cookies.set('isAdmin', false);
    Cookies.set('isInternal', false);
    const authClient = getAuthClient();
    const url = window.location.origin;
    sessionStorage.setItem('url', url);
    const formData = {
      'session[email]': username,
      'session[password]': password,
      authenticity_token: authenticityToken,
      portal: 'patient',
      has_touch_id: '',
      has_secret: '',
    };
    sessionStorage.setItem('login-form-data', JSON.stringify(formData));
    authClient.signInWithCredentials({ username, password })
      .then((transaction) => {
        if (transaction.status === 'SUCCESS') {
          authClient.token.getWithRedirect({
            sessionToken: transaction.sessionToken,
            responseType: 'token',
          });
        }
      })
      .catch(async () => {
        window.hideSpinner();
        window.location.href = `${url}/sign-in`;
      });
  };
  return (
    <div className="row content_ registry">
      <div className="registry-content col-xs-12">
        <h1>Sign in to myGuardant</h1>
        <form onSubmit={e => patientFormSubmit(e)}>
          <div className="form-group">
            <label htmlFor="session_email">Email Address</label>
            <p className="help-block">Please enter the email address you used to create your account.</p>
            <div className="row">
              <div className="col-xs-12 col-md-7">
                <input
                  type="email"
                  placeholder="e.g. name@example.com"
                  required="required"
                  ref={inputEl}
                  className="form-control"
                  name="session[email]"
                  id="session_email"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="session_password">Password</label>
            <p className="help-block">Please enter the password you used to create your account.</p>
            <div className="row">
              <div className="col-xs-12 col-md-7">
                <input
                  placeholder="e.g. Password123"
                  className="form-control"
                  type="password"
                  name="session[password]"
                  id="session_password"
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="col-xs-12 col-md-5">
                <a
                  className="registry-links align-bottom_"
                  href="/myguardant/password_resets/patient_new"
                >
                  FORGOT YOUR PASSWORD?
                </a>
              </div>
            </div>
          </div>
          <button className="registry-btn" type="submit">SIGN IN</button>
        </form>
      </div>
      <div className="col-xs-12">
        <div className="registry-headline">
          <div className="registry-text">Don’t have an account?</div>
          <div className="registry-links">
            <a className="registry-links" href="/myguardant/sign_up" title="">
              Create an account here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientSignIn;

PatientSignIn.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  actionPath: PropTypes.string.isRequired,
};
