import { constants } from '../../../config/constants';

const { productFamilies } = constants.etrfOrdering;

const transformTestTypeForProduct = (productFamily) => {
  if (productFamily === productFamilies.g360) {
    return e => ({ text: e.text[0], code: e.code });
  }

  return e => ({
    text: e.text,
    code: e.code,
    requiresSurgicalResectionDate: e.requires_surgical_resection_date,
  });
};

const deserializeTestTypes = (testTypes, productFamily) => {
  const res = {};

  const testTypeTransform = transformTestTypeForProduct(productFamily);
  Object.keys(testTypes).forEach((key) => {
    res[key] = testTypes[key].map(testTypeTransform);
  });

  return res;
};

class TestSelectionOptionsSerializer {
  static deserialize = (testSelectionOptions, productFamily) => {
    const deserializedOptions = testSelectionOptions;
    deserializedOptions.testTypes = deserializeTestTypes(
      testSelectionOptions.testTypes, productFamily,
    );
    return deserializedOptions;
  };
}

export { TestSelectionOptionsSerializer };
