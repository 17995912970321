import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleElectronicSignatureModal } from '../../../store/actions/orders/electronic-signature-confirmation';
import { toggleModalAction } from '../../../store/actions/orders/active-order/print-modal';

import { ElectronicSignatureConfirmationOverlay } from './electronic-signature-confirmation-overlay';

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleElectronicSignatureModal,
  togglePrintModal: toggleModalAction,
}, dispatch);

const ConnectedElectronicSignatureConfirmationOverlay = connect(
  null,
  mapDispatchToProps,
)(ElectronicSignatureConfirmationOverlay);

export {
  ConnectedElectronicSignatureConfirmationOverlay as ElectronicSignatureConfirmationOverlay,
};
