/* eslint-disable no-unused-vars, no-undef, jsx-a11y/anchor-is-valid, func-names */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SignOut from '../login/SignOut';

const IPropTypes = {
  salesAdmin: PropTypes.bool.isRequired,
  createGhAdmin: PropTypes.any,
  fullName: PropTypes.string.isRequired,
  physicianAdmin: PropTypes.bool.isRequired,
  studyManagers: PropTypes.object.isRequired,
  showDivider: PropTypes.any,
  setActive: PropTypes.any,
  authenticityToken: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  email: PropTypes.string,
  modify: PropTypes.any,
};
const defaultProps = {
  createGhAdmin: null,
  showDivider: null,
  setActive: null,
  email: '',
  modify: null,
};
function Admin(props) {
  useEffect(() => {
    $('.view-as__current_user__link').click(function () {
      $(this).parent().submit();
    });
  }, []);
  const {
    fullName,
    salesAdmin,
    createGhAdmin,
    physicianAdmin,
    studyManagers,
    showDivider,
    setActive,
    authenticityToken,
    userId,
    email,
    modify,
    currentUserId,
  } = props;
  return (
    <>
      <div className="menu-user-container admin-menu-user-container hidden-xs">
        <a
          aria-expanded={false}
          aria-haspopup
          className="dropdown-toggle no-spinner"
          data-toggle="dropdown"
          href="#"
          role="button"
        >
          <span className="menu-user-name admin-menu-user-name">
            {fullName}
          </span>
          <i className="fa fa-chevron-up admin-menu-arrow" />
          <i className="fa fa-chevron-down admin-menu-arrow" />
        </a>
        {salesAdmin ? (
          <ul className="dropdown-menu admin-dropdown-menu">
            {createGhAdmin && (
              <li>
                <a
                  className="no-spinner"
                  href="#"
                  modal-toggle="create-gh-admin"
                >
                  Create GH Admin
                </a>
              </li>
            )}
            {physicianAdmin && (
              <li>
                <a
                  className="no-spinner"
                  href="#"
                  modal-toggle="physician-admin-signup"
                >
                  physician admin sign up
                </a>
              </li>
            )}
            <li>
              <a
                className="no-spinner"
                href="#"
                modal-toggle="sign_up__site-manager"
              >
                site manager sign up
              </a>
            </li>
            {studyManagers && (
              <li>
                <a
                  className="no-spinner"
                  href="#"
                  modal-toggle={studyManagers.modalLink}
                >
                  {studyManagers.label}
                </a>
              </li>
            )}
            <li>
              <a
                className="no-spinner sign_up__practice-manager"
                href="#"
                modal-toggle="sign_up__practice-manager"
              >
                Practice manager sign up
              </a>
            </li>
            <li>
              <a
                className="manage-shares__link clickable no-spinner"
                href="#"
                modal-toggle="manage_permissions"
              >
                Manage SITE/PRACTICE MANAGERS
              </a>
            </li>
            <li>
              <a
                className="no-spinner manage-patients__link"
                href="#"
                modal-toggle="manage__portal-patients"
              >
                manage patients
              </a>
            </li>
            {showDivider && (
              <li className="li-divider-no-spacing no-hover" />
            )}
            <li>
              {setActive === true && (
                <form
                  action="/user/toggle_active_status"
                  acceptCharset="UTF-8"
                  method="POST"
                >
                  <input type="hidden" name="_method" value="put" />
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={authenticityToken}
                  />
                  <input
                    type="hidden"
                    name="auth_token"
                    value={JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken}
                  />

                  <input
                    type="hidden"
                    name="user_id"
                    id="user_id"
                    value={userId}
                  />
                  <input
                    type="hidden"
                    name="set_active"
                    id="set_active"
                    value="false"
                  />
                  <a className="view-as__current_user__link">
                    Deactivate
                    {' '}
                    {email}
                  </a>
                </form>
              )}
            </li>
            <li>
              {setActive === false && (
                <form
                  action="/user/toggle_active_status"
                  acceptCharset="UTF-8"
                  method="POST"
                >
                  <input type="hidden" name="_method" value="put" />
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={authenticityToken}
                  />
                  <input
                    type="hidden"
                    name="user_id"
                    id="user_id"
                    value={userId}
                  />
                  <input
                    type="hidden"
                    name="set_active"
                    id="set_active"
                    value="true"
                  />
                  <a className="view-as__current_user__link">
                    Activate
                    {' '}
                    {email}
                  </a>
                </form>
              )}
            </li>
            <li>
              {modify && (
                <a
                  className="no-spinner manage-patients__link"
                  href="#"
                  modal-toggle="change-username"
                >
                  Modify
                  {' '}
                  {email}
                </a>
              )}
            </li>
            <li className="li-divider-no-spacing no-hover" />
            <li>
              <form
                action="/admin_view_as"
                acceptCharset="UTF-8"
                method="POST"
              >
                <input type="hidden" name="_method" value="PUT" />
                <input type="hidden" name="auth_token" value={JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken} />
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={authenticityToken}
                />
                <input
                  type="hidden"
                  name="user_id"
                  id="user_id"
                  value={currentUserId}
                />
                <input
                  type="hidden"
                  name="user_name_and_email"
                  id="user_name_and_email"
                  value="yourself"
                />
                <a className="view-as__current_user__link">GO TO DASHBOARD</a>
              </form>
            </li>
            <SignOut authenticityToken={authenticityToken} />
          </ul>
        ) : (
          <ul className="dropdown-menu admin-dropdown-menu">
            <SignOut authenticityToken={authenticityToken} />
          </ul>
        )}
      </div>
    </>
  );
}

export default Admin;
Admin.propTypes = IPropTypes;
Admin.defaultProps = defaultProps;
