class DiagnosisSearchSerializer {
  static deserialize = diagnosis => ({
    id: diagnosis.id,
    label: diagnosis.trfDisplayName,
    value: diagnosis.diagnosis,
    group: diagnosis.category,
    order: diagnosis.cancerTypeOrder || 0,
  })
}

export { DiagnosisSearchSerializer };
