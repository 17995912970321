import React from 'react';
import PropTypes from 'prop-types';
import { getAuthClient } from './AuthClient';

const SignOut = (props) => {
  const {
    authenticityToken,
  } = props;
  const authClient = getAuthClient();
  const signOutHandler = async () => {
    const { accessToken } = JSON.parse(
      localStorage.getItem('okta-token-storage'),
    ).accessToken;
    sessionStorage.setItem('userLoggedOut', '1');
    window.showSpinner();
    const formData = new FormData();
    formData.append('authenticity_token', authenticityToken);
    const signOutResponse = await fetch('/sign_out', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      },
    });
    const res = await signOutResponse.json();
    authClient.tokenManager.clear();
    localStorage.clear();
    const { origin } = window.location;
    const pathname = res.post_signout_url;
    window.location.href = origin + pathname;
  };
  return (
    <li className="menu-sign-out">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" rel="nofollow" onClick={signOutHandler}>Sign Out</a>
    </li>
  );
};
export default SignOut;

SignOut.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
};
