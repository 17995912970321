import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  status: PropTypes.string.isRequired,
  toggleBanner: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const BannerMessage = ({
  status,
  toggleBanner,
  messages,
  className,
}) => (
  <div className={`flash-alert-container ${className}`}>
    <div className="close-flash-alert fa fa-close" onClick={toggleBanner} />
    <div className={['alert', `alert--${status}`].join(' ')}>
      <ul className="message">
        {messages.map(message => (
          <li key={message}>
            {message}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

BannerMessage.propTypes = IPropTypes;
BannerMessage.defaultProps = defaultProps;

export default BannerMessage;
