import axios from 'axios';
import { getToken, isInternalUser } from '../../../networking/controllers/helper';
import { apiOrdersPath } from '../endpoints';

const HttpService = axios.create();
HttpService.defaults.baseURL = apiOrdersPath;
const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

const requestHandler = (request) => {
  request.headers = {
    Authorization: `Bearer ${getToken()}`,
    ...extHeader,
    Accept: '*',
    'Content-Type': 'application/json; charset=UTF-8',
  };
  return request;
};
const errorHandler = error => Promise.reject(error);

HttpService.interceptors.request.use(request => requestHandler(request),
  error => errorHandler(error));

export default HttpService;
