import React, { memo, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

const IPropTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
};
const defaultProps = {
  title: '',
  className: '',
  options: [],
};

const MenuDropDown = (props) => {
  const { title, options, className } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div onClick={handleClick}>{title}</div>
      <Menu
        classes={{ paper: className }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem key={Math.random()} onClick={handleClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default memo(MenuDropDown);
MenuDropDown.propTypes = IPropTypes;
MenuDropDown.defaultProps = defaultProps;
