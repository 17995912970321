import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getRolesData,
  addRolesData,
  editRolesData,
  deleteRolesData,
  onChange,
  changePagination,
  getClaims,
} from './actions';
import RolesView from './RolesView';

const mapStateToProps = state => ({
  rolesData: state.roles,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getRolesData,
    addRolesData,
    editRolesData,
    deleteRolesData,
    onChange,
    changePagination,
    getClaims,
  }, dispatch),
});
export const ConnectedRolesView = connect(mapStateToProps, mapDispatchToProps)(RolesView);
export default ConnectedRolesView;
