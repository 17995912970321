import React from 'react';
import PropTypes from 'prop-types';
import pendingOrdersColumns from './pendingColumnsMetadata.json';
import completedOrdersColumns from './completedColumnsMetadata.json';
import pendingSignOrdersColumns from './pendingSignColumnsMetadata.json';
import OrdersTable from '../common/components/OrdersTable';

const OrdersList = (props) => {
  const {
    orders,
    pageChange,
    ordersType,
    totalRecords,
    pageLimit,
    downloadPdf,
    editOrder,
    history,
    onSortRequest,
    roleBasedAuthorisation,
  } = props;
  return (
    <React.Fragment>
      {
        ordersType === 'pending' && (
        <OrdersTable
          ordersType={ordersType}
          roleBasedAuthorisation={roleBasedAuthorisation}
          columns={pendingOrdersColumns}
          {...{
            orders, pageChange, totalRecords, pageLimit, editOrder, history, onSortRequest,
          }}
        />
        )
      }
      {
        ordersType === 'pendingSignature' && (
          <OrdersTable
            ordersType={ordersType}
            roleBasedAuthorisation={roleBasedAuthorisation}
            columns={pendingSignOrdersColumns}
            {...{
              orders,
              pageChange,
              totalRecords,
              pageLimit,
              editOrder,
              downloadPdf,
              history,
              onSortRequest,
            }}
          />
        )
      }
      {
        ordersType === 'completed' && (
          <OrdersTable
            ordersType={ordersType}
            roleBasedAuthorisation={roleBasedAuthorisation}
            columns={completedOrdersColumns}
            {...{
              orders,
              pageChange,
              totalRecords,
              pageLimit,
              editOrder,
              downloadPdf,
              history,
              onSortRequest,
            }}
          />
        )
      }
    </React.Fragment>
  );
};
export default OrdersList;
OrdersList.propTypes = {
  orders: PropTypes.array,
  pageChange: PropTypes.func.isRequired,
  ordersType: PropTypes.string.isRequired,
  totalRecords: PropTypes.number,
  pageLimit: PropTypes.number.isRequired,
  downloadPdf: PropTypes.func,
  editOrder: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
  onSortRequest: PropTypes.func.isRequired,
  roleBasedAuthorisation: PropTypes.object.isRequired,
};
OrdersList.defaultProps = {
  orders: [],
  totalRecords: 0,
  downloadPdf: undefined,
};
