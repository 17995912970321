import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';

import baseEtrfStyles from '../../../../assets/stylesheets/etrf/base.module.scss';
import styles from './clinical-history-early-stage-collapsed.module.scss';

const IPropTypes = {
  clinicalHistoryEarlyStore: PropTypes.shape({
    chemotherapy: PropTypes.string,
    evidenceOfRecurrence: PropTypes.string,
    mrdSurveillance: PropTypes.string,
  }).isRequired,
  onToggle: PropTypes.func,
};

const defaultProps = {
  onToggle: () => {},
};

const ClinicalHistoryEarlyStageCollapsed = ({
  clinicalHistoryEarlyStore: {
    chemotherapy,
    evidenceOfRecurrence,
    mrdSurveillance,
  },
  onToggle,
}) => (
  <div className={[baseEtrfStyles.accordionSection, baseEtrfStyles.collapsed, styles.shadow].join(' ')} onClick={onToggle}>
    <div className={styles.row}>
      <div className={styles.column}>
        <CollapsedTab title="Currently on chemotherapy or targeted therapy" value={chemotherapy} />
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.column}>
        <CollapsedTab className={styles.noMargin} title="Evidence of recurrence or progression" value={evidenceOfRecurrence} />
      </div>
      <div className={styles.column}>
        <CollapsedTab className={styles.noMargin} title="Surveillance or monitoring tests other than Guardant" value={mrdSurveillance} />
      </div>
    </div>
  </div>
);

ClinicalHistoryEarlyStageCollapsed.propTypes = IPropTypes;
ClinicalHistoryEarlyStageCollapsed.defaultProps = defaultProps;

export { ClinicalHistoryEarlyStageCollapsed };
