import React from 'react';
import PropTypes from 'prop-types';

import styles from './checkbox.module.scss';

const IPropTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  highlight: PropTypes.bool,
};

const defaultProps = {
  checked: false,
  highlight: false,
};

const Checkbox = ({
  id,
  checked,
  highlight,
  onChange,
}) => (
  <div className={styles.checkboxContainer}>
    <input
      id={id}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    { highlight && <div className={styles.checkboxHighlight} /> }
  </div>
);

Checkbox.propTypes = IPropTypes;
Checkbox.defaultProps = defaultProps;

export { Checkbox };
