class BiologicalGeneDetail {
  constructor(params) {
    this.alterationId = params.alterationId;
    this.content = params.content;
    this.molecularFunction = params.molecularFunction;
    this.incidence = params.incidence;
  }
}

export { BiologicalGeneDetail };
