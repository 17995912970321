import React from 'react';
import PropTypes from 'prop-types';

import { isUsername } from '../../../util/validator';
import styles from './study-manager-modal.module.scss';
import { ProjectListPicker } from './projects-list-picker';

const IPropTypes = {
  studyManagerHasMultipleProjects: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  firstList: PropTypes.arrayOf(PropTypes.string).isRequired,
  secondList: PropTypes.arrayOf(PropTypes.string).isRequired,
  submitCallback: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

const defaultProps = {
  firstName: '',
  lastName: '',
};

class StudyManagerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.firstName || '',
      lastName: this.props.lastName || '',
      email: this.props.email,
      sendInvitationEmail: true,
      sendCourtesyEmail: true,
      errorText: '',
      submitEnabled: true,
      availableProjectsList: this.props.firstList,
      selectedProjectsList: this.props.secondList,
      filteredFirstList: this.props.firstList,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.submit = this.submit.bind(this);
    this.handleCheckmarkChange = this.handleCheckmarkChange.bind(this);
    this.addProjectsItem = this.addProjectsItem.bind(this);
    this.removeProjectsItem = this.removeProjectsItem.bind(this);
    this.filterResults = this.filterResults.bind(this);
  }

  update(field) {
    return (e) => {
      this.setState({ [field]: e.target.value });
    };
  }

  handleCheckmarkChange(field) {
    return (e) => {
      this.setState({ [field]: e.target.checked });
    };
  }

  submit() {
    const {
      firstName,
      lastName,
      email,
      sendInvitationEmail,
      sendCourtesyEmail,
      selectedProjectsList,
    } = this.state;

    if (!firstName) {
      this.setState({ errorText: 'First name is required.' });
      return;
    }
    if (!lastName) {
      this.setState({ errorText: 'Last name is required.' });
      return;
    }
    if (!email) {
      this.setState({ errorText: 'Email address is required.' });
      return;
    }
    if (!isUsername(email)) {
      this.setState({ errorText: 'Please enter a valid email address.' });
      return;
    }
    this.setState({ errorText: null });

    this.props.submitCallback(
      firstName,
      lastName,
      selectedProjectsList,
      sendInvitationEmail,
      sendCourtesyEmail,
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.submit();
  }

  handleCloseModal() {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      errorText: '',
    });
    this.props.callback(1);
  }

  addProjectsItem(selectedItem) {
    const { availableProjectsList, selectedProjectsList } = this.state;
    if (!this.props.studyManagerHasMultipleProjects && selectedProjectsList.length >= 1) {
      return;
    }
    if (selectedItem && availableProjectsList.includes(selectedItem)) {
      const index = availableProjectsList.indexOf(selectedItem);
      const list1 = [...availableProjectsList];
      const list2 = [...selectedProjectsList];
      list1.splice(index, 1);
      list2.splice(0, 0, selectedItem);
      this.setState({
        availableProjectsList: list1,
        selectedProjectsList: list2,
        filteredFirstList: list1,
      });
    }
  }

  removeProjectsItem(selectedItem) {
    const { availableProjectsList, selectedProjectsList } = this.state;
    if (selectedItem && selectedProjectsList.includes(selectedItem)) {
      const index = selectedProjectsList.indexOf(selectedItem);
      const list1 = [...availableProjectsList];
      const list2 = [...selectedProjectsList];
      list2.splice(index, 1);
      list1.splice(0, 0, selectedItem);
      this.setState({
        availableProjectsList: list1,
        selectedProjectsList: list2,
        filteredFirstList: list1,
      });
    }
  }

  filterResults(value) {
    const { availableProjectsList } = this.state;
    if (value) {
      const regExp = new RegExp(`^${value}.*`, 'i');
      const listResult = availableProjectsList.filter(project => regExp.test(project));
      this.setState({
        filteredFirstList: listResult,
      });
    } else {
      this.setState({
        filteredFirstList: availableProjectsList,
      });
    }
  }

  content() {
    const {
      firstName,
      lastName,
      email,
      errorText,
      sendInvitationEmail,
      submitEnabled,
      availableProjectsList,
      selectedProjectsList,
      filteredFirstList,
    } = this.state;

    return (
      <div className={styles.content}>
        <div className={styles.errors}>{errorText}</div>
        <form>
          <div className={styles.formItemContainer}>
            <div className={styles.formItem}>
              <div className={[styles.formItemInner, styles.formItemInnerLeft].join(' ')}>
                <label className={styles.shareFirstName} htmlFor="firstName">First Name</label>
                <input type="text" id="firstName" className={styles.formItem} placeholder="e.g. Joe" required value={firstName} onChange={this.update('firstName')} />
              </div>

              <div className={[styles.formItemInner, styles.formItemInnerRight].join(' ')}>
                <label className={styles.shareLastName} htmlFor="lastName">Last Name</label>
                <input type="text" id="lastName" className={styles.formItem} placeholder="e.g. Smith" required value={lastName} onChange={this.update('lastName')} />
              </div>
            </div>

            <div className={styles.formItem}>
              <label className={styles.shareEmailAddress} htmlFor="email">Email Address</label>
              <input type="text" id="email" disabled className={styles.formItem} placeholder="e.g. studymanager@example.com" required value={email} />
            </div>

            <ProjectListPicker
              addItem={this.addProjectsItem}
              removeItem={this.removeProjectsItem}
              availableItemsList={availableProjectsList}
              suscribedItemsList={selectedProjectsList}
              filteredAvailableList={filteredFirstList}
              filterCallback={this.filterResults}
              buttonEnable={
                !this.props.studyManagerHasMultipleProjects
                && selectedProjectsList.length >= 1
              }
            />

            <div className={[styles.formItemInner, styles.formItem].join(' ')}>
              <button type="button" className={styles.button} disabled={!submitEnabled} onClick={this.handleSubmit}>{this.props.buttonText}</button>

              <div className={styles.checkboxContainer}>
                <input type="checkbox" className={styles.checkbox} defaultChecked="true" value={sendInvitationEmail} onChange={this.handleCheckmarkChange('sendInvitationEmail')} />
                Send invitation email
                <br />

                <input type="checkbox" className={styles.checkbox} defaultChecked="true" value={sendInvitationEmail} onChange={this.handleCheckmarkChange('sendCourtesyEmail')} />
                Send courtesy emails to ordering physicians
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const content = this.content();

    return (
      <div className={styles.modalContainerOuter} style={{ display: 'block' }}>
        <div className={styles.modalContainerInner}>
          <div className={styles.modalContent}>
            <div>
              <i className={[styles.closeIcon, 'modal-close-study-manager', 'fa fa-close'].join(' ')} onClick={this.handleCloseModal} />
              <div className={styles.header}>
                Manage Study Managers
              </div>
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StudyManagerModal.propTypes = IPropTypes;
StudyManagerModal.defaultProps = defaultProps;
export { StudyManagerModal };
