const requestsActionTypes = {
  ADD_REQUESTS: 'ADD_REQUESTS',
};

const AddRequests = data => ({
  type: requestsActionTypes.ADD_REQUESTS,
  data,
});

export { requestsActionTypes, AddRequests };
