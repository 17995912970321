import React from 'react';
import Administration from './Administration';

const AdminContainer = () => (
  <div>
    <Administration />
  </div>
);

export default AdminContainer;
