import React from 'react';
import PropTypes from 'prop-types';

import styles from './release-activity.module.scss';

const IPropTypes = {
  reportDate: PropTypes.string.isRequired,
  releaseDateHour: PropTypes.string.isRequired,
  releaser: PropTypes.string.isRequired,
};

const ReleaseActivity = ({ reportDate, releaseDateHour, releaser }) => (
  <div>
    <p className={styles.activityLogEvent}>
      {`${releaser} `}
      <b className={styles.reportDate}>
        {reportDate}
      </b>
      &nbsp;to patient
    </p>
    <p className={styles.timestamp}>{releaseDateHour}</p>
  </div>
);

ReleaseActivity.propTypes = IPropTypes;

export { ReleaseActivity };
