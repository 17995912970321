import React from 'react';

import guardantLogo from '../../../assets/images/logos/guardant-logo-with-text.svg';
import styles from './header.module.scss';

const Header = () => (
  <nav className={styles.header}>
    <img alt="Guardant" src={guardantLogo} />
  </nav>
);

export { Header };
