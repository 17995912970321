import { createAction } from 'redux-actions';

export const GET_PATIENTS = '@usermanagment-workflow/GET_PATIENTS';
export const getPatients = createAction(GET_PATIENTS);

export const GET_PATIENTS_DONE = '@usermanagment-workflow/GET_PATIENTS_DONE';
export const getPatientsDone = createAction(GET_PATIENTS_DONE);

export const PATIENTS_ERROR = '@usermanagment-workflow/PATIENTS_ERROR';
export const patientsError = createAction(PATIENTS_ERROR);


export const DELETE_PATIENT = '@usermanagment-workflow/DELETE_PATIENT';
export const deletePatient = createAction(DELETE_PATIENT);

export const DELETE_PATIENT_DONE = '@usermanagment-workflow/DELETE_PATIENT_DONE';
export const deletePatientDone = createAction(DELETE_PATIENT_DONE);

export const DELETE_PATIENT_ERROR = '@usermanagment-workflow/DELETE_PATIENT_ERROR';
export const deletePatientError = createAction(DELETE_PATIENT_ERROR);

export const PATIENT_PAGINATION_CHANGE = '@usermanagment-workflow/PATIENT_PAGINATION_CHANGE';
export const changePagePatient = createAction(PATIENT_PAGINATION_CHANGE);

export const ON_CHANGE_PATIENTS = '@usermanagment-workflow/ON_CHANGE_PATIENTS';
export const onChangePatients = createAction(ON_CHANGE_PATIENTS);

export const defaultSite = createAction('');
