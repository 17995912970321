import React from 'react';
import PropTypes from 'prop-types';

import { StatesDropdown } from '../../../generic/StatesDropdown';
import { validEmail } from '../../../../util/validator';
import { Countries } from '../../../../constants/countries';
import Dropdown from '../../Dropdown';
import { OnlineOrderingFieldsLimits } from '../../../../config/input-character-limit';

const IPropTypes = {
  toggleSelected: PropTypes.func.isRequired,
  patientInfo: PropTypes.object.isRequired,
  savePatientInfo: PropTypes.func.isRequired,
};

const adaptedCountryList = Countries.map((country, index) => ({
  id: String(index + 1), // Id's for dropdowns start in 1.
  title: country.label,
  key: 'patientCountry',
}));

const defaultCountry = 'United States';

class PatientInformationShowAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validEmail: true,
      streetAddressToggled: false,
      countryDropdownLoaded: false,
    };
  }

  onCountryDropdownChange = (id, info) => {
    if (this.state.countryDropdownLoaded) {
      const newInfo = {
        [id]: info,
        patientStreet: null,
        patientStreet2: null,
        patientProvince: null,
        patientState: null,
        patientPostalCode: null,
        patientZipCode: null,
        patientCity: null,
      };

      this.props.savePatientInfo(newInfo, true);
    } else {
      this.setState({ countryDropdownLoaded: true });
    }
  }

  onDropdownChange = (id, info) => {
    this.props.savePatientInfo({ [id]: info }, true);
  }

  onInputChange = name => (event) => {
    const { value } = event.target;

    this.props.savePatientInfo({ [name]: value }, true);
  }

  addressToggled = () => {
    const { patientInfo } = this.props;
    const { streetAddressToggled } = this.state;

    return patientInfo.patientStreet2 != null || streetAddressToggled;
  }

  toggleStreetAddress = () => {
    this.setState(prevState => (
      {
        streetAddressToggled: !prevState.streetAddressToggled,
      }
    ));
  }

  showStandardFields = () => {
    const { patientInfo } = this.props;
    return patientInfo.patientCountry === defaultCountry;
  }

  validateEmail = (event) => {
    const { value } = event.target;
    const valid = !value.trim() || validEmail(value);
    this.setState({ validEmail: valid });
    this.props.savePatientInfo({ patientEmail: value }, true);
  }

  render() {
    const { toggleSelected, patientInfo } = this.props;

    const patientCountry = patientInfo.patientCountry || defaultCountry;

    return (
      <div>
        <div className="flex-container__etrf-section">
          <div className="flex-row">
            <div className="patient-information-column-1-3">
              <div className="action-label bold">Email Address</div>
              <input
                className="content-space-breathable content-space-breathable__with-message"
                placeholder="name@domain.com"
                type="text"
                value={patientInfo.patientEmail || ''}
                onChange={this.validateEmail}
                maxLength={OnlineOrderingFieldsLimits.emailAddressMaxLength}
              />
              <p className="email-error">
                {
                  !this.state.validEmail
                      && '*Please enter a valid email'
                }
              </p>
            </div>
            <div className="patient-information-column-1-3">
              <div className="action-label bold">Preferred Phone</div>
              <input
                className="content-space-breathable"
                placeholder="(###) ###-####"
                type="text"
                value={patientInfo.patientPhone || ''}
                onChange={this.onInputChange('patientPhone')}
                maxLength={OnlineOrderingFieldsLimits.phoneNumberMaxLength}
              />
            </div>
            <div className="action-label-container country">
              <div className="action-label bold">Country</div>
              <div className="content-space-breathable">
                <Dropdown
                  title={patientCountry}
                  toggleSelected={toggleSelected}
                  resetThenSet={this.onCountryDropdownChange}
                  storeSelectedItem={this.onDropdownChange}
                  defaultValue={patientCountry}
                  list={adaptedCountryList}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container__etrf-section">
          <div className="flex-container__etrf-row">
            <div className="patient-information-column-1-3">
              <div className="flex-row">
                <div className="action-label-container street">
                  <div className="action-label bold">Street Address</div>
                  <input
                    className="content-space-breathable"
                    placeholder={this.showStandardFields() ? 'e.g., 123 Main Street' : 'Street name, number, unit, etc.'}
                    type="text"
                    value={patientInfo.patientStreet || ''}
                    onChange={this.onInputChange('patientStreet')}
                    maxLength={OnlineOrderingFieldsLimits.streetAddress1MaxLength}
                  />
                </div>
                {' '}
              </div>
            </div>
            <div className="flex-container__etrf-column">
              <div className="flex-row">
                {
                  this.showStandardFields() ? (
                    <div className="action-label-container city">
                      <div className="action-label bold">City</div>
                      <input
                        className="content-space-breathable"
                        placeholder="e.g., Los Angeles"
                        type="text"
                        value={patientInfo.patientCity || ''}
                        onChange={this.onInputChange('patientCity')}
                        maxLength={OnlineOrderingFieldsLimits.cityMaxLength}
                      />
                    </div>
                  )
                    : (
                      <div className="action-label-container municipality">
                        <div className="action-label bold">City</div>
                        <input
                          className="content-space-breathable"
                          placeholder="City name"
                          type="text"
                          value={patientInfo.patientCity || ''}
                          onChange={this.onInputChange('patientCity')}
                          maxLength={OnlineOrderingFieldsLimits.genericInputMaxLength}
                        />
                      </div>
                    )
                }
                {
                  this.showStandardFields() ? (
                    <div className="action-label-container state">
                      <div className="action-label bold">State</div>
                      <div className="content-space-breathable">
                        <StatesDropdown
                          country={patientInfo.patientCountry}
                          toggleSelected={toggleSelected}
                          storeSelectedItem={this.onDropdownChange}
                          defaultValue={patientInfo.patientState}
                        />
                      </div>
                    </div>
                  )
                    : (
                      <div className="action-label-container city">
                        <div className="action-label bold">Province/Region</div>
                        <input
                          className="content-space-breathable"
                          placeholder="(Optional)"
                          type="text"
                          value={patientInfo.patientProvince || ''}
                          onChange={this.onInputChange('patientProvince')}
                          maxLength={OnlineOrderingFieldsLimits.provinceMaxLength}
                        />
                      </div>
                    )
                }
              </div>
            </div>
            <div className="flex-container__etrf-column">
              <div className="flex-row">
                {
                  this.showStandardFields() ? (
                    <div className="action-label-container zipcode">
                      <div className="action-label bold">Zip Code</div>
                      <input
                        className="content-space-breathable"
                        placeholder="5 digits"
                        type="text"
                        value={patientInfo.patientZipCode || ''}
                        onChange={this.onInputChange('patientZipCode')}
                        maxLength={OnlineOrderingFieldsLimits.zipcodeMaxLength}
                      />
                    </div>
                  )
                    : (
                      <div className="action-label-container postalcode">
                        <div className="action-label bold">Postal Code</div>
                        <input
                          className="content-space-breathable"
                          placeholder="Code"
                          type="text"
                          value={patientInfo.patientPostalCode || ''}
                          onChange={this.onInputChange('patientPostalCode')}
                          maxLength={OnlineOrderingFieldsLimits.zipcodeMaxLength}
                        />
                      </div>
                    )
                }
              </div>
            </div>
          </div>
          <div className="flex-container__etrf-row">
            <div className="flex-container__etrf-column">
              {
                  this.addressToggled() ? (
                    <div className="flex-container street2 content-space-breathable">
                      <input
                        className="street2"
                        placeholder="Apt., suite, unit, etc."
                        type="text"
                        value={patientInfo.patientStreet2 || ''}
                        onChange={this.onInputChange('patientStreet2')}
                        maxLength={OnlineOrderingFieldsLimits.streetAddress2MaxLength}
                      />
                    </div>
                  )
                    : (
                      <div className="flex-container street2 content-space-breathable">
                        <i className="fa fa-plus-circle medical-collaborator-minus" onClick={this.toggleStreetAddress} />
                        <div className="action-label bold click-btn" onClick={this.toggleStreetAddress}>STREET ADDRESS 2</div>
                      </div>
                    )
                }
            </div>
          </div>

        </div>
      </div>
    );
  }
}

PatientInformationShowAll.propTypes = IPropTypes;

export { PatientInformationShowAll };
