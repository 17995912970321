import HttpService from '../services/HttpService';
import { ReportEndpoints } from '../endpoints';
import { isInternalUser } from './helper';

export class ReportController {
 static toggleReadRequest = (revisionId, revisionIds) => {
   const params = {
     id: revisionId,
     revisions: revisionIds,
   };
   const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
   const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

   return HttpService.post(ReportEndpoints.MOVE_REPORT, params,
     {
       headers: {
         Authorization: `Bearer ${accessToken}`,
         ...extHeader,
       },
     });
 };
}
