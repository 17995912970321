/* eslint-disable no-undef, jsx-a11y/anchor-is-valid, func-names */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const IPorpTypes = {
  isPhysicianLoggedIn: PropTypes.bool.isRequired,
  myGuardantLoggedIn: PropTypes.bool.isRequired,
  isEmailVerified: PropTypes.bool.isRequired,
  isTwoDataSetsVerified: PropTypes.bool.isRequired,
  isDaysValid: PropTypes.bool.isRequired,
  isVerificationErrors: PropTypes.bool.isRequired,
  email: PropTypes.string,
  sendEmailVerificationEmailPath: PropTypes.string.isRequired,
  myGuardantSettingsPath: PropTypes.string.isRequired,
  signOutPath: PropTypes.string.isRequired,
  duplicateUserEmail: PropTypes.string,
  days: PropTypes.any,
};
const defaultProps = {
  email: '',
  days: null,
  duplicateUserEmail: '',
};
const GlobalAlert = (props) => {
  const {
    isPhysicianLoggedIn,
    myGuardantLoggedIn,
    isEmailVerified,
    isTwoDataSetsVerified,
    isDaysValid,
    isVerificationErrors,
    email,
    sendEmailVerificationEmailPath,
    myGuardantSettingsPath,
    duplicateUserEmail,
    signOutPath,
    days,
  } = props;
  useEffect(() => {
    $('.close-alert').click(function () {
      $(this).parent('.content').parent('.alert-box').hide('400');
      $.post('/set_session_key', { key: $(this).attr('data') });
    });

    $('.dismiss__global_alert_email_verification_sent').click(function () {
      $('.alert-box.success.global_alert_email_verification_sent').hide('400');
      $.post('/set_session_key', { key: $(this).attr('data') });
    });

    $('.dismiss__global_alert_missing_information').click(function () {
      $('.alert-box.warning.global_alert_missing_information').hide('400');
      $.post('/set_session_key', { key: $(this).attr('data') });
    });

    $('.dismiss__global_alert_days_since_account_creation').click(function () {
      $('.alert-box.warning.global_alert_days_since_account_creation').hide(
        '400',
      );
      $.post('/set_session_key', { key: $(this).attr('data') });
    });
  });
  return (
    <>
      {isPhysicianLoggedIn && (
        <div className="physician_portal" id="global-alert">
          <div className="alert-box success global_alert_progression_testing_available">
            <div className="global-alert-color-bar" />
            <div className="content container">
              <div className="content-inner">
                <div className="alert-info-circle-container">
                  <div className="fa fa-bullhorn" />
                </div>
                <div className="alert-text-container">
                  <div className="text">
                    Patients tested with Guardant360 are eligible for another
                    test upon progression. The portal now allows you to print
                    out a pre-filled TRF for those patients. Click on the ‘Start
                    an order’ button from the patient’s report view to finish
                    completing the pre-filled TRF for that patient
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {myGuardantLoggedIn && (
        <div className="ptp_global_alert" id="global-alert">
          { isEmailVerified
            && (
            <div className="alert-box pending global_alert_email_verification_sent">
              <div className="global-alert-color-bar" />
              <div className="content container">
                <div className="content-inner">
                  <div className="alert-info-circle-container">
                    <div className="fa fa-info-circle" />
                  </div>
                  <div className="alert-text-container">
                    <div className="text">
                      We have
                      {' '}
                      {email}
                      {' '}
                      saved to your account. Is this
                      correct?
                    </div>
                    <div className="links">
                      <a
                        className="send-confirmation-email__link"
                        href={sendEmailVerificationEmailPath}
                      >
                        Yes, that&apos;s correct
                      </a>
                      <a
                        className="dismiss__global_alert_email_verification_sent"
                        data="global_alert_email_verification_sent"
                        href={myGuardantSettingsPath}
                      >
                        No, change my email
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="close-alert"
                  data="global_alert_email_verification_sent"
                >
                  <i className="fa fa-close" />
                </div>
              </div>
            </div>
            )
          }
          { isTwoDataSetsVerified
            && (
            <div className="alert-box warning global_alert_missing_information">
              <div className="global-alert-color-bar" />
              <div className="content container">
                <div className="content-inner">
                  <div className="alert-info-circle-container">
                    <div className="fa fa-info-circle" />
                  </div>
                  <div className="alert-text-container">
                    <div className="text">
                      Unfortunately, we cannot verify your patient account.
                    </div>
                    <div className="links">
                      <a
                        className="dismiss__global_alert_missing_information"
                        data="global_alert_missing_information"
                        href={myGuardantSettingsPath}
                      >
                        PLEASE COMPLETE YOUR PROFILE
                      </a>
                    </div>
                  </div>
                  <div
                    className="close-alert"
                    data="global_alert_missing_information"
                  >
                    <i className="fa fa-close" />
                  </div>
                </div>
              </div>
            </div>
            )
          }
          {
            isDaysValid && (
            <div className="alert-box pending warning global_alert_days_since_account_creation">
              <div className="global-alert-color-bar" />
              <div className="content container">
                <div className="content-inner">
                  <div className="alert-info-circle-container">
                    <div className="fa fa-info-circle" />
                  </div>
                  <div className="alert-text-container">
                    <div className="text">
                      It has been
                      {' '}
                      {days}
                      {' '}
                      days since you created a myGuardant
                      account, but we have not yet received a blood sample that
                      matches the patient information you&apos;ve entered.
                    </div>
                    <div className="links">
                      <a
                        className="dismiss__global_alert_days_since_account_creation"
                        data="global_alert_days_since_account_creation"
                      >
                        PLEASE REVIEW YOUR PROFILE
                      </a>
                      <a
                        className="underline"
                        href="mailto:portal@guardanthealth.com?body=%0D%0DSent%20via%20myGuardant"
                        target="_blank"
                        rel="noreferrer"
                      >
                        contact Client Services
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="close-alert"
                  data="global_alert_days_since_account_creation"
                >
                  <i className="fa fa-close" />
                </div>
              </div>
            </div>
            )
          }
          {
            isVerificationErrors && (
            <div className="alert-box warning global_alert_duplicate_account_patient_verification">
              <div className="global-alert-color-bar" />
              <div className="content container">
                <div className="content-inner">
                  <div className="alert-info-circle-container">
                    <div className="fa fa-info-circle" />
                  </div>
                  <div className="alert-text-container">
                    <div className="text">
                      The patient information you entered is already linked to
                      another myGuardant account using
                      {' '}
                      {duplicateUserEmail}
                      .
                    </div>
                    <div className="links">
                      <a
                        rel="nofollow"
                        data-method="post"
                        href={signOutPath}
                      >
                        SIGN IN TO THE OTHER ACCOUNT
                      </a>
                      <a
                        className="underline"
                        href="mailto:portal@guardanthealth.com?body=%0D%0DSent%20via%20myGuardant"
                        target="_blank"
                        rel="noreferrer"
                      >
                        contact Client Services
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="close-alert"
                  data="global_alert_duplicate_account_patient_verification"
                >
                  <i className="fa fa-close" />
                </div>
              </div>
            </div>
            )

          }
        </div>
      )}
    </>
  );
};

export default GlobalAlert;
GlobalAlert.propTypes = IPorpTypes;
GlobalAlert.defaultProps = defaultProps;
