import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionsTypes';

export const DEFAULT_ROLES_LIST_STATE = fromJS({
  rolesList: null,
  claimsList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },
  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
  isOpen: false,
});

const RolesReducer = handleActions(
  {
    [actionTypes.GET_ROLES]: (state, action) => state
      .set('reload', false)
      .set('rolesList', action.payload.data.roles)
      .set('totalRecords', action.payload.data.totalRecords === 0 ? 0 : action.payload.data.totalRecords)
      .setIn(
        ['pagination', 'pageNo'],
        action.payload.data.roles.length === 0 && state.getIn(['pagination', 'pageNo']) > 0
          ? state.getIn(['pagination', 'pageNo']) - 1
          : state.getIn(['pagination', 'pageNo']),
      ),
    [actionTypes.SAVE_ROLES]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Role Added Successfully', type: 'success' }),
    [actionTypes.UPDATE_ROLES]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Role Saved Successfully', type: 'success' }),
    [actionTypes.DELETE_ROLES]: state => state
      .set('reload', true)
      .setIn(['notify'], fromJS({ isOpen: true, message: 'Role Deleted Successfully', type: 'success' })),

    [actionTypes.ROLES_ERROR]: (state, action) => state
      .setIn(['notify'], fromJS({ isOpen: true, message: action.payload.message, type: 'error' }))
      .set('isOpen', true),
    [actionTypes.ROLES_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_ROLES]: (state, action) => state
      .set(action.payload.key, action.payload.value),
    [actionTypes.GET_CLAIMS]: (state, action) => state.set('claimsList', action.payload.data),
  },
  DEFAULT_ROLES_LIST_STATE,
);
export default RolesReducer;
