import React from 'react';
import { Button as MuiButton, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    margin: useTheme().spacing(0.5),
  },
  label: {
    textTransform: 'none',
  },
}));

const IPropTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
const defaultProps = {
  variant: '',
  color: '',
  size: '',
};

const Button = (props) => {
  const {
    text, size, color, variant, onClick, ...other
  } = props;
  const classes = useStyles();

  return (
    <MuiButton
      variant={variant || 'contained'}
      size={size || 'large'}
      color={color || 'primary'}
      onClick={onClick}
      {...other}
      classes={{ root: classes.root, label: classes.label }}
    >
      {text}
    </MuiButton>
  );
};

export default Button;
Button.propTypes = IPropTypes;
Button.defaultProps = defaultProps;
