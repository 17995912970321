import React from 'react';

const TermsOfUse = () => (
  <div className="terms-content__container">
    <div className="terms-content__wrapper">
      <div className="terms">
        <h1 className="terms__header">Patient Portal Terms and Conditions of Use</h1>
        <p className="terms__updated-on">Last Updated: March 30, 2016</p>
        <h4 className="font-weight__bold underline margin-top__25-px">Introduction and Acceptance</h4>
        <p>
          Unless otherwise agreed to in writing by Guardant Health, Inc. (&quot;Guardant&quot;),
          by using the Guardant Health Patient Portal and Services (&quot;Patient Portal&quot;),
          you agree to the following Terms and Conditions of Use Policy without
          limitation or qualification. Please read these terms and conditions
          carefully before using the Patient Portal. If you do not agree with the
          Terms and Conditions of Use Policy, you are not granted
          permission to use the Patient Portal.
        </p>
        <p>
          All references in this Terms and Conditions of Use Policy to
          &quot;we,&quot; &quot;us,&quot; or &quot;our&quot; shall refer to Guardant.
          All references in this Terms and Conditions of Use Policy to &quot;you&quot; or
          &quot;your&quot; shall refer
          to the users of the Guardant Health Patient Portal.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">
          Exclusion of Warranties and Limitations of Liability
        </h4>
        <p>
          The Guardant Health Patient Portal is provided by Guardant on an &quot;as is&quot; and
          &quot;as available&quot; basis. Guardant makes no representations or warranties of any
          kind, express or implied, as to the operation of the Patient Portal or the information,
          content, materials, or services included thereon, including, but not limited to,
          implied warranties of merchantability and fitness for a particular purpose.
          In particular, Guardant does not warrant that the Patient Portal, its servers,
          or email sent from Guardant are free of viruses or other
          harmful components. Guardant will not be liable for any damages of any kind arising
          from the use of, or inability to use, the Patient Portal, including, but not limited to,
          direct, indirect, incidental, punitive, and consequential damages, whether in contract,
          tort, or otherwise. Certain state laws do not allow
          limitations on implied warranties or the exclusion or limitation of certain damages;
          accordingly, the above limitations or exclusions may not apply to you.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">General Use of the Patient Portal</h4>
        <div className="overflow__auto">
          <div className="margin-top__10-px margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">1. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              In order to access certain features of the Guardant Health Patient
              Portal, you may be required to provide information about yourself
              (such as identification or contact details) as part of the registration process,
              including to obtain a Guardant account to access certain
              sections of the Patient Portal, or as part of your continued use of such
              website or portals. You agree that any registration information you give to Guardant
              will always be accurate, correct, and up to date.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">2. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              You agree to use the Guardant Health Patient Portal only for purposes
              that are permitted by (a) this Terms and Conditions of Use Policy, and (b) any
              applicable law, regulation, or generally accepted practices or guidelines in the
              relevant jurisdictions (including any laws regarding the export of data or software
              to and from the United States or other relevant countries).
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">3. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              You agree not to access (or attempt to access) the Patient Portal by
              any means other than through the interface that is provided by Guardant.
              You specifically agree not to access (or attempt to access) any part of the
              Patient Portal through any automated means (including use
              of scripts or web crawlers) and shall ensure that you comply with the instructions
              set out in any robots.txt file present on the Patient Portal.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">4. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              You agree that you will not engage in any activity that interferes
              with or disrupts the Patient Portal (or the servers and networks which are
              connected to such website or portals).
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">5. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              You agree that you will not reproduce, duplicate, copy, republish,
              upload, post, transmit, distribute, modify, sell, trade, or resell the
              Patient Portal, or any portion, feature, or content thereof, for any purpose.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">6. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              You agree that you are solely responsible for (and that Guardant has
              no responsibility to you or to any third-party for) any breach of your
              obligations under the Terms and Conditions of Use Policy and for the
              consequences (including any loss or damage which Guardant may
              suffer) of any such breach.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">7. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              You must be 18 years or older to use the Patient Portal unsupervised.
              If you are under the age of 18, you may only use such Patient Portal in
              conjunction with, and under the supervision of, your parents or guardians.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">8. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              You must be a human. Guardant accounts registered by “bots” or other
              automated methods are not permitted.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">9. &nbsp;&nbsp;</div>
            <div className="margin-left__40-px">
              Your Guardant account may only be used by you – a single Guardant
              account shared by you and other people is not permitted.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">10. &nbsp;</div>
            <div className="margin-left__40-px">
              You agree and understand that you are responsible for maintaining the
              confidentiality and security of your Guardant account and password. Guardant
              cannot and will not be liable for any loss or damage from your failure to comply
              with this security obligation.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">11. &nbsp;</div>
            <div className="margin-left__40-px">
              You agree that you will be solely responsible to Guardant for all
              activities that occur under your Guardant account.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">12. &nbsp;</div>
            <div className="margin-left__40-px">
              One person or legal entity may not maintain more than one Guardant
              account.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">13. &nbsp;</div>
            <div className="margin-left__40-px">
              If you become aware of any unauthorized use of your Guardant account
              or password, you agree to notify Guardant of this immediately.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">14. &nbsp;</div>
            <div className="margin-left__40-px">
              We reserve the right to modify or discontinue, temporarily or
              permanently, your access to the Patient Portal, or your Guardant account
              with or without notice.
            </div>
          </div>
          <div className="margin-bottom__20-px overflow__auto">
            <div className="float-left margin-left__10-px">15. &nbsp;</div>
            <div className="margin-left__40-px">
              You agree that the official CLIA laboratory report of record is the
              pdf version of the laboratory report.&nbsp; The portal may have interactive
              features that simulate the laboratory report, but there are some differences
              in the information presented on the portal (example:&nbsp; therapies listed,
              language in the methods section, color of the TRM,&nbsp;etc.) compared
              to pdf version of the report.
            </div>
          </div>
        </div>
        <h4 className="font-weight__bold underline margin-top__25-px">
          Comments, Communications, and Other Content
        </h4>
        <p>
          Certain features within the Patient Portal may allow you to post comments,
          send emails and other communications, and submit suggestions, ideas, comments,
          questions, or other information (together &quot;Commenting&quot;). You agree, understand
          and represent that all of your Commenting: (i) is accurate, (ii) is
          owned or otherwise controlled by you, (iii) is not illegal, obscene, threatening,
          defamatory, invasive of privacy, infringing of intellectual property rights, or
          otherwise injurious to third-parties, and (iv) does not consist of or contain
          software viruses, political campaigning, commercial solicitation, chain letters,
          mass mailings, or any form of &quot;spam.&quot; You may not use a false e-mail address,
          impersonate any person or entity, or otherwise mislead as to the origin of Commenting.
          Guardant reserves the right (but not the obligation) to monitor and edit or remove any
          Commenting activity or content. If you do post Commenting content or submit Commenting
          material, you grant Guardant a nonexclusive, royalty-free, perpetual,
          irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish,
          translate, create derivative works from, distribute, and display such Commenting content
          throughout the world in any media, including the right to use the name that you submit
          in connection with such Commenting. Guardant takes no responsibility and assumes no
          liability for any Commenting content posted by you or any third-party.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">Links</h4>
        <p>
          The Guardant Health Patient Portal may be linked to other websites not maintained by us.
          We do not control and are not responsible for the content of and policies governing use
          of those websites not maintained by us. The inclusion of any link to such websites does
          not imply approval of or endorsement by us of the websites or the content thereof.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">Privacy</h4>
        <p>
          Please read our Privacy Policy (
          <a href="https://guardanthealth.com/contact/privacy-policy/">https://guardanthealth.com/contact/privacy-policy/</a>
          )
          which also governs your use of the Guardant Health Patient Portal to understand our
          policies about what information we collect, how we will treat this information, and
          what options you have to direct our activities.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">Ownership, Copyright and Trademark</h4>
        <p>
          All content included on the Guardant Health Patient Portal, such as trademarks,
          service marks, trade names, text, graphics, logos, icons, button icons, images,
          data compilations, domain names, and software, is the property of Guardant or its
          content suppliers or used with permission. The compilation of all content on this site
          is the exclusive property of Guardant and may be protected by U.S. and international
          copyright laws. All software used on this site is the property of Guardant or their
          software suppliers and may be protected by United States and international copyright
          laws. Nothing on this site should be construed as
          granting any license or right to such content or software.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">Copyright and Trademark Complaints</h4>
        <p>
          Guardant respects the intellectual property of others. If you believe that your work
          has been copied in a way that constitutes copyright infringement on the Guardant Health
          Patient Portal, please notify us immediately by phone at (855) 698-8887 or by mail at
          Guardant Health, Inc., 505 Penobscot Avenue, Redwood City, CA 94063 USA.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">Applicable Law</h4>
        <p>
          By visiting the Patient Portal, you agree that the laws of the State of California,
          without regard to principles of conflict of laws, will govern this Terms and Conditions
          of Use Policy and any dispute of any sort that might arise between you and Guardant
          regarding such Policy. You agree to submit to the personal jurisdiction and venue of
          the courts of the State of California for any legal proceeding involving the Patient
          Portal, regardless of who initiated the proceeding. The English-language version of
          this Terms and Conditions of Use Policy is Guardant’s official agreement with users
          of the Patient Portal. In case of any inconsistency between this English-language
          version of this Terms and Conditions of Use Policy and its
          translation into another language, this English-language document controls.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">Changes to the Terms</h4>
        <p>
          We reserve the right, at any time, to modify, alter, or update the terms and conditions
          of this Terms and Conditions of Use Policy without prior notice. Modifications shall
          become effective immediately upon being posted on our Patient Portal. Your continued
          use of the Patient Portal after modifications are posted constitutes an acknowledgement
          and acceptance of the Terms and Conditions of Use Policy and its modifications.
        </p>
        <h4 className="font-weight__bold underline margin-top__25-px">How To Contact Us</h4>
        <p>
          Any questions about this Terms and Conditions of Use Policy should be addressed to
          clientservices@guardanthealth.com or by mail at: Guardant Health, Inc., 505 Penobscot
          Avenue, Redwood City, CA 94063 USA.
        </p>
      </div>
    </div>
  </div>
);
export default TermsOfUse;
