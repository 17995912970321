import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';

import { ClinicalHistoryEarlyStageCollapsed } from '../clinical-history-early-stage-collapsed';
import { ClinicalHistoryEarlyStageExpanded } from '../clinical-history-early-stage-expanded';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
};

const ClinicalHistoryEarlyStageContainer = ({ enabled, onToggle }) => (
  <div>
    <GenericHeader
      enabled={enabled}
      handleContainerArrowClick={onToggle}
      label="Relevant Clinical History"
    />

    {
      enabled
        ? <ClinicalHistoryEarlyStageExpanded />
        : <ClinicalHistoryEarlyStageCollapsed onToggle={onToggle} />
    }
  </div>
);

ClinicalHistoryEarlyStageContainer.propTypes = IPropTypes;
ClinicalHistoryEarlyStageContainer.defaultProps = defaultProps;

export { ClinicalHistoryEarlyStageContainer };
