export const deserializeDate = (date) => {
  let deserialized = date;
  if (date) {
    const splittedDate = date.split('-');
    deserialized = `${splittedDate[1]}/${splittedDate[2]}/${splittedDate[0]}`;
  }
  return deserialized;
};

export const serializeDate = (date) => {
  let serializedDate = date;
  if (date) {
    const splittedDate = date.split('/');
    serializedDate = `${splittedDate[2]}-${splittedDate[0]}-${splittedDate[1]}`;
  }
  return serializedDate;
};

export const deserializeFullName = (fullname) => {
  const name = fullname || '';

  let firstname;
  let lastname;

  const commaSeparatedNames = name.split(',');
  if (commaSeparatedNames.length === 1) {
    const spaceSeparatedNames = commaSeparatedNames[0].split(' ');

    if (spaceSeparatedNames.length > 1) {
      [firstname] = spaceSeparatedNames;
    }
    [lastname] = spaceSeparatedNames.slice(-1);
  } else {
    [lastname, firstname] = commaSeparatedNames;
  }

  return {
    firstname: (firstname || '').trim(),
    lastname: (lastname || '').trim(),
  };
};
