import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'throttle-debounce';

import { LoadingDots } from '../loading-dots';
import OutsideAlerter from '../../orders/OutsideAlerter';

import styles from './inline-search.module.scss';
import { SearchResults } from './search-results/search-results';

const IPropTypes = {
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelPlaceholder: PropTypes.string,
  onOutsideClick: PropTypes.func,
  onOptionsFetch: PropTypes.func,
  minLengthSearch: PropTypes.number,
  inputPlaceholder: PropTypes.string,
  highlightedBorder: PropTypes.bool,
};

const defaultProps = {
  className: '',
  labelPlaceholder: '',
  onOutsideClick: () => {},
  onOptionsFetch: () => {},
  minLengthSearch: 0,
  inputPlaceholder: 'Search...',
  highlightedBorder: false,
};

const InlineSearch = (props) => {
  const {
    className, selectedValue, onOutsideClick, onChange,
    inputPlaceholder, labelPlaceholder,
    minLengthSearch, onOptionsFetch,
    highlightedBorder,
  } = props;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [lastFetchCriteria, setLastFetchCriteria] = useState('');
  const [inputVal, setInputVal] = useState('');

  const searchCallId = useRef(0);
  const inputRef = useRef(null);

  const performSearch = async () => {
    const nextShowDropdown = inputVal.length >= minLengthSearch;

    const fetchCriteria = inputVal.trim();
    if (nextShowDropdown && fetchCriteria !== lastFetchCriteria) {
      const nextSearchCallId = searchCallId.current + 1;
      searchCallId.current = nextSearchCallId;

      const data = await onOptionsFetch(fetchCriteria);

      setLastFetchCriteria(fetchCriteria);
      if (searchCallId.current === nextSearchCallId) {
        setList(data);
      }
    }
    setLoading(false);
    setShowDropdown(nextShowDropdown);
  };

  const debouncedSearch = useRef(debounce(600, func => func()));

  const handleInputChange = (e) => {
    setInputVal(e.target.value || '');
  };

  useEffect(() => {
    if (inputVal) {
      setLoading(true);
      debouncedSearch.current(performSearch);
    }
  }, [inputVal]);

  const clearInput = (event) => {
    setInputVal('');
    setShowDropdown(false);
    onChange();

    event.stopPropagation();
  };

  const selectItem = (item) => {
    setShowDropdown(false);
    setShowSearch(false);
    onChange(item);
  };

  const handleOutsideClick = () => {
    if (showSearch) {
      onOutsideClick();
      setShowSearch(false);
      setShowDropdown(false);
    }
  };

  const handleLabelClick = () => {
    setShowSearch(true);
  };

  useEffect(() => {
    if (showSearch) {
      inputRef.current.focus();
    }
  }, [showSearch]);

  const innerContainerClassNames = [
    styles.innerContainer,
    (showSearch && styles.active) || (selectedValue && styles.selected),
  ].filter(Boolean).join(' ');

  return (
    <OutsideAlerter onOutsideClick={handleOutsideClick}>
      <div className={styles.container}>
        <div className={[styles.inputContainer, highlightedBorder ? styles.highlight : '', className].join(' ')}>
          <div className={innerContainerClassNames} onClick={handleLabelClick}>
            {
              showSearch
                ? (
                  <input
                    type="text"
                    ref={inputRef}
                    className={styles.input}
                    value={inputVal}
                    onChange={handleInputChange}
                    placeholder={inputPlaceholder}
                    autoComplete="off"
                    onFocus={performSearch}
                  />
                ) : (
                  <span className={styles.value}>
                    {selectedValue || labelPlaceholder}
                  </span>
                )
            }
            { loading && <LoadingDots className={styles.spinner} />}
            {
              !loading && (
                selectedValue || inputVal
                  ? <i className={`fa fa-close ${styles.deleteIcon} ${showSearch ? styles.active : ''}`} aria-hidden="true" onClick={clearInput} />
                  : <i className={`fa fa-search ${styles.icon} ${showSearch ? styles.active : ''}`} aria-hidden="true" />
              )
            }
          </div>
        </div>
        {
          showDropdown && (
            <SearchResults
              results={list}
              selectItem={selectItem}
              closeSearch={handleOutsideClick}
            />
          )
        }
      </div>
    </OutsideAlerter>
  );
};

InlineSearch.propTypes = IPropTypes;
InlineSearch.defaultProps = defaultProps;

export { InlineSearch };
