import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import HttpService from '../../service/interceptor';
import * as actionTypes from './actionTypes';

export const getProjectsAssignmentList = params => (dispatch) => {
  const query = `?${new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());

  return HttpService.get(`/api/v1/userprojects${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getProjectAssignmentList(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.projectAssignmentListError(err));
      dispatch(closeSpinner());
    });
};

export const addProjectAssignment = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.post('/api/v1/userroles', params)
    .then(({ data }) => {
      dispatch(actionTypes.saveProjectAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.saveProjectAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const getProjectsList = (params = {}) => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.get(`/api/v1/projects?name:contains=${params}`)
    .then(({ data }) => {
      dispatch(actionTypes.getAlProjects(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.projectAssignmentListError(err));
      dispatch(closeSpinner());
    });
};
export const editProjectAssignment = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.put(`/api/v1/users/${params.uuid}/projects`, params.projects)
    .then(({ data }) => {
      dispatch(actionTypes.updateProjectAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.updateProjectAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const deleteProjectAssignment = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.delete('/api/v1/userroles', params)
    .then(({ data }) => {
      dispatch(actionTypes.deleteProjectAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.deleteProjectAssignmentError(err));
      dispatch(closeSpinner());
    });
};

export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePageProjectAssignment(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeProjectAssignment(params));
};
