import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { connect } from 'react-redux';

const useStyles = makeStyles(() => createStyles({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
      '-ms-transform': 'rotate(0deg)',
      '-moz-transform': 'rotate(0deg)',
      '-webkit-transform': 'rotate(0deg)',
      '-o-transform': 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
      '-ms-transform': 'rotate(360deg)',
      '-moz-transform': 'rotate(360deg)',
      '-webkit-transform': 'rotate(360deg)',
      '-o-transform': 'rotate(360deg)',
    },
  },
  SpinnerContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'center',
    height: '100%',
    width: '100%',
    'z-index': 9998,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    'background-color': '#f2f2f2',
    opacity: '.7',
  },
  Spinner: {
    height: '35px',
    width: '35px',
    'border-radius': '100%',
    border: '3px solid #4096fd',
    'border-bottom-color': 'transparent',
    animationName: '$rotate',
    animationDuration: '0.8s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  message: {
    'margin-top': '20px',
    'font-size': '16px',
    'font-color': '#373a3c',
    'font-family': 'Roboto, Arial',
    'z-index': 1,
  },
}));
function Spinner(props) {
  // eslint-disable-next-line react/prop-types
  const { showSpinner } = props;
  const classes = useStyles();

  return showSpinner ? (
    <div className={classes.SpinnerContainer}>
      <div className={classes.overlay} />
      <div className={classes.Spinner} />
      <div className={classes.message}>LOADING</div>
    </div>
  ) : null;
}
const mapStateToProps = state => ({
  showSpinner: state.spinner.get('isLoading', false),
});

export default connect(mapStateToProps, {})(Spinner);
