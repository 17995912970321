export class ReferenceItemSerializer {
  static deserialize = referenceItem => (
    {
      name: this.parseName(referenceItem.name),
      path: referenceItem.report_path,
    }
  );

  static parseName = (name) => {
    switch (name.toUpperCase()) {
      case 'DEFINITIONS':
        return 'Definitions';
      case 'INTERPRETATIONS':
        return 'Interpretations';
      case 'THERAPIES':
        return 'Detailed Therapy Results';
      case 'CLINICAL_RELEVANCE':
        return 'Clinical Relevance';
      case 'BIOLOGICAL_SIGNIFICANCE':
        return 'Biological Significance';
      case 'METHOD_AND_LIMITATIONS':
        return 'Method and Limitations';
      case 'REFERENCES':
        return 'References';
      case 'ABOUT':
        return 'About the Test';
      default:
        return '';
    }
  }
}
