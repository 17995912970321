import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import {
  REQUEST_ADD,
  REQUEST_POP,
} from './actions';

export const defaultState = fromJS({
  isLoading: false,
  requests: {},
  alerts: null,
  show: false,
});

const spinnerReducer = handleActions(
  {
    [REQUEST_ADD]: state => state.set('isLoading', true),
    [REQUEST_POP]: state => state.set('isLoading', false),
  },
  defaultState,
);
export default spinnerReducer;
