import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changePagination,
  onChange,
  getRoleAssignmentsData,
  getAdminRoles,
  editRoleAssignmentsData,
} from './actions/RoleAssignmentAction';
import RoleAssignmentView from './RoleAssignmentView';

const mapStateToProps = state => ({
  roleAssignment: state.roleAssignment,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePagination,
      onChange,
      getRoleAssignmentsData,
      getAdminRoles,
      editRoleAssignmentsData,
    },
    dispatch,
  ),
});
// eslint-disable-next-line max-len
export const ConnectedRoleAssignmentView = connect(mapStateToProps, mapDispatchToProps)(RoleAssignmentView);
export default ConnectedRoleAssignmentView;
