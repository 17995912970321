import { deserializeFullName } from './helpers';

const NPI_IDENTIFIER_TYPE = 'NPI';
const RECORD_IDENTIFIER_TYPE = 'RECORD';

class PhysicianSerializer {
  static deserialize = (physician) => {
    if (!physician) {
      return {};
    }

    const { identifiers } = physician;
    let npi = null;
    let recordId = null;

    if (identifiers) {
      npi = identifiers.find(identifier => identifier.idType === NPI_IDENTIFIER_TYPE);
      npi = (npi || {}).id;
      recordId = identifiers.find(identifier => identifier.idType === RECORD_IDENTIFIER_TYPE);
      recordId = (recordId || {}).id;
    }

    return {
      id: recordId,
      lastname: physician.lastName,
      firstname: physician.firstName,
      email: physician.email,
      npi,
      phone: physician.phone,
    };
  }

  static serialize = (physicianInfo) => {
    const res = {
      lastName: physicianInfo.lastname,
      firstName: physicianInfo.firstname,
      email: physicianInfo.email,
      phone: physicianInfo.phone,
    };

    if (physicianInfo.fullname) {
      const deserializedName = deserializeFullName(physicianInfo.fullname);
      res.firstName = deserializedName.firstname;
      res.lastName = deserializedName.lastname;
    }

    const identifiers = [];
    if (physicianInfo.npi) {
      identifiers.push({
        id: physicianInfo.npi,
        idType: NPI_IDENTIFIER_TYPE,
      });
    }
    if (physicianInfo.id) {
      identifiers.push({
        id: String(physicianInfo.id),
        idType: RECORD_IDENTIFIER_TYPE,
      });
    }

    if (identifiers.length) {
      res.identifiers = identifiers;
    }

    return res;
  };
}

export { PhysicianSerializer };
