import React from 'react';
import PropTypes from 'prop-types';
import RequestTile from './RequestTile';

const RequestCounts = (props) => {
  const { requestCategories, testAgain, selectedCategory } = props;
  return (
    <div className="request-counts-tiles">
      {
        // eslint-disable-next-line max-len
        Object.entries(requestCategories).map(item => <RequestTile key={item[0]} testAgain={testAgain} selectedCategory={selectedCategory} category={item[0]} count={item[1]} />)
      }
    </div>
  );
};
export default RequestCounts;
RequestCounts.propTypes = {
  requestCategories: PropTypes.object.isRequired,
  testAgain: PropTypes.bool.isRequired,
  selectedCategory: PropTypes.string.isRequired,
};
