import {
  useCallback, useEffect, useRef, useState,
} from 'react';


import { logger } from '../util/logger';
import { updateLoadingCount } from '../store/actions/global';
import { AnnouncementsController } from '../networking/controllers/announcements';

const useSignatureIncompleteAnnouncement = (withIncompleteSignatureMessage) => {
  const [loading, setLoading] = useState(true);
  const [bannerMessage, setBannerMessage] = useState(null);
  const [autoDismiss, setAutoDismiss] = useState(false);
  const alreadyInactivated = useRef(false);

  const inactivateAnnouncements = useCallback(() => {
    if (!alreadyInactivated.current) {
      AnnouncementsController.inactivateAnnouncements();
      alreadyInactivated.current = true;
    }
  }, [alreadyInactivated.current]);

  const closeBanner = useCallback(() => {
    if (bannerMessage) {
      setBannerMessage(null);
      inactivateAnnouncements();
    }
  }, [bannerMessage]);

  const startMessageAutoDismiss = useCallback(() => {
    setAutoDismiss(true);
  }, []);

  useEffect(() => {
    if (!withIncompleteSignatureMessage) {
      return;
    }

    (async () => {
      try {
        updateLoadingCount(1);

        const show = await AnnouncementsController.checkSignatureNotFinalized();

        if (show) {
          setBannerMessage("Your signature was not completed. Please provide digital signature by opening your order in 'Pending e-signature'");
        }
      } catch (error) {
        logger.error(error);
      } finally {
        setLoading(false);
        updateLoadingCount(-1);
      }
    })();
  }, []);

  useEffect(() => {
    if (autoDismiss && bannerMessage) {
      setTimeout(inactivateAnnouncements, 4000);
    }
  }, [autoDismiss, bannerMessage, inactivateAnnouncements]);


  if (withIncompleteSignatureMessage) {
    return [loading, bannerMessage, closeBanner, startMessageAutoDismiss];
  }

  return [false, null, () => {}, () => {}];
};

export { useSignatureIncompleteAnnouncement };
