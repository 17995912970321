import { deserializeDate, serializeDate } from './helpers';

class BillingSerializer {
  static deserialize = (billing) => {
    if (!billing) {
      return {};
    }

    const primaryInsurance = billing.primaryInsurance || {};

    return {
      cardIncluded: billing.cardIncluded,
      type: primaryInsurance.billingIndicator,
      company: primaryInsurance.company,
      dob: deserializeDate(primaryInsurance.dob),
      groupNumber: primaryInsurance.groupNumber,
      insuredRelationship: primaryInsurance.insuredRelationship,
      subscriberFirstName: primaryInsurance.subscriberFirstName,
      subscriberLastName: primaryInsurance.subscriberLastName,
      policyNumber: primaryInsurance.policyNumber,
    };
  }

  static serialize = billingInfo => ({
    cardIncluded: billingInfo.cardIncluded,
    primaryInsurance: {
      billingIndicator: billingInfo.type,
      company: billingInfo.company,
      dob: serializeDate(billingInfo.dob),
      groupNumber: billingInfo.groupNumber,
      insuredRelationship: billingInfo.insuredRelationship,
      subscriberFirstName: billingInfo.subscriberFirstName,
      subscriberLastName: billingInfo.subscriberLastName,
      policyNumber: billingInfo.policyNumber,
    },
  })
}

export { BillingSerializer };
