import React from 'react';
import PropTypes from 'prop-types';

import { constants } from '../../../config/constants';
import ldtLogo from '../../../../assets/images/logos/guardant360-logo.svg';
import cdxLogo from '../../../../assets/images/logos/guardant360cdx-logo.svg';
import lunarLogo from '../../../../assets/images/logos/lunar-logo.svg';
import mrLogo from '../../../../assets/images/logos/mr-logo.svg';
import tissueLogo from '../../../../assets/images/logos/tissue-logo.svg';

const LOGOS = {
  LDT: ldtLogo,
  CDX: cdxLogo,
  CDX_EU: cdxLogo,
  MR: mrLogo,
  LUNAR: lunarLogo,
  TISSUE: tissueLogo,
};

const IPropTypes = {
  testType: PropTypes.string,
  productId: PropTypes.string,
};

const defaultProps = {
  testType: null,
  productId: null,
};

const logoFromTestType = (testType) => {
  // NOTE: LUNAR does not have one specific test type
  // that's why we need a special case.
  if (testType.startsWith(constants.testTypes.LUNAR_PREFIX)) {
    return LOGOS.LUNAR;
  }

  // NOTE: we don't use Array.prototype.find because it's not supported on IE11.
  const product = Object.entries(constants.testTypes)
    .filter(([, tType]) => testType === tType)[0][0];
  return LOGOS[product];
};

const logoFromProductId = (productId) => {
  // NOTE: LUNAR does not have one specific test type
  // that's why we need a special case.
  if (productId && productId.startsWith(constants.productIds.LUNAR_PREFIX)) {
    return LOGOS.LUNAR;
  }

  // NOTE: we don't use Array.prototype.find because it's not supported on IE11.
  const entries = Object.entries(constants.productIds)
    .filter(([, id]) => productId === id);

  if (entries.length) {
    const [[product]] = entries;

    return LOGOS[product];
  }

  return null;
};

const ProductLogo = ({ testType, productId, ...nativeProps }) => {
  const src = testType ? logoFromTestType(testType) : logoFromProductId(productId);
  if (src) {
    return (
      <img alt="" {...nativeProps} src={src} />
    );
  }

  return null;
};

ProductLogo.propTypes = IPropTypes;
ProductLogo.defaultProps = defaultProps;

export { ProductLogo };
