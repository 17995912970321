import { RevealOrderingSerializer } from './reveal-ordering';
import { EarlyStageDiagnosisSerializer } from './early-stage-diagnosis';
import { EarlyStageClinicalHistorySerializer } from './early-stage-clinical-history';

class RevealSerializer {
  static deserialize = order => ({
    guardantRevealOrderingInfo: RevealOrderingSerializer.deserialize(order.testOrdered),
    earlyStageDiagnosisInfo: EarlyStageDiagnosisSerializer.deserialize(order.diagnosis),
    earlyStageClinicalHistoryInfo: EarlyStageClinicalHistorySerializer.deserialize(
      order.relevantClinicalHistory,
    ),
  });

  static serialize = ({
    guardantRevealOrderingInfo, earlyStageDiagnosisInfo, earlyStageClinicalHistoryInfo,
  }) => ({
    testOrdered: RevealOrderingSerializer.serialize(guardantRevealOrderingInfo),
    diagnosis: EarlyStageDiagnosisSerializer.serialize(earlyStageDiagnosisInfo),
    relevantClinicalHistory: EarlyStageClinicalHistorySerializer.serialize(
      earlyStageClinicalHistoryInfo,
    ),
  });
}

export { RevealSerializer };
