import { ExpressionValuesSerializer } from './expression-values';

class DiseaseSerializer {
  static deserialize(data) {
    return data && {
      name: data.name,
      values: (data.expressionValues || []).map(ExpressionValuesSerializer.deserialize),
    };
  }
}

export { DiseaseSerializer };
