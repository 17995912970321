import { Box, Grid, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'loadsh';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import BackspaceIcon from '@mui/icons-material/Backspace';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(() => createStyles({
  borderRight: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '16px',
    backgroundColor: '#f8f8f9',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    textOverflow: 'ellipsis',
    backgroundColor: '#f8f8f9',
  },
  borderPadding: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '2px 0px 3px 0',
    backgroundColor: '#f8f8f9',
  },
  container: {
    borderBottom: '18px solid white',
    textAlign: 'start',
    placeContent: 'flex-start',
    backgroundColor: '#f8f8f9',
  },
}));
const RolesTableBody = (props) => {
  const { rolesList } = props;
  return (
    <>
      {rolesList
        && rolesList.map(item => (
          <ExpandableRow
            key={Math.random()}
            item={item}
            {...props}
            det={item}
          />
        ))}
    </>
  );
};
export default RolesTableBody;
RolesTableBody.propTypes = {
  rolesList: PropTypes.array,
};
RolesTableBody.defaultProps = {
  rolesList: [],
};

const permissionIcons = (key) => {
  switch (key) {
    case 'CREATE':
      return <AddIcon />;

    case 'VIEW':
      return <VisibilityIcon />;

    case 'DELETE':
      return <BackspaceIcon />;

    case 'UPDATE':
      return <UpdateIcon />;

    default:
      return '';
  }
};

const IPropTypes = {
  item: PropTypes.object.isRequired,
  actionButtons: PropTypes.array,
  det: PropTypes.any.isRequired,
  userPermission: PropTypes.object,
};
const defaultProps = {
  actionButtons: null,
  userPermission: {},
};
const ExpandableRow = (props) => {
  const {
    item, actionButtons, det, userPermission,
  } = props;
  const featureClaims = Object.keys(item.featureClaims || []);
  const featureClaimstrans = featureClaims.map(ele => ele);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const gg = featureClaims.reduce(
    (acc, cur) => {
      const currTranslated = Object.keys(item.featureClaims[cur]).map(it => it);
      // eslint-disable-next-line no-param-reassign
      acc = {
        feature: `${acc.feature + currTranslated}, `,
        permission: _.uniq([...acc.permission, ...Object.values(item.featureClaims[cur]).flat()]),
      };
      return acc;
    },
    { feature: '', permission: [] },
  );
  return open ? (
    <Grid container xs={11} direction="row" className={classes.container}>
      <Grid item xs={2} className={classes.borderBottom}>
        <Tooltip title={item.name}>
          <Box className={classes.borderRight}>{item.name}</Box>
        </Tooltip>
      </Grid>

      <Grid item xs={7}>
        <Grid container xs={12}>
          {featureClaims.map((module) => {
            const features = Object.keys(item.featureClaims[module]);
            const dd = _.groupBy(
              features.map(x => ({ key: x, value: item.featureClaims[module][x].join(',') })),
              n => n.value,
            );
            const ff = Object.keys(dd).map(x => dd[x].map(y => y.key));
            return (
              <>
                <Grid item xs={4} className={classes.borderBottom}>
                  <Box className={classes.borderRight}>{module}</Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ textAlign: 'start', placeContent: 'flex-start' }}
                  className={classes.borderBottom}
                >
                  {ff.map(z => z.map(x => (
                    <Box
                      key={x}
                      style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', marginLeft: '1%' }}
                      className={classes.borderRight}
                    >
                      {x}
                    </Box>
                  )))}
                </Grid>
                <Grid item xs={5} className={classes.borderBottom}>
                  <Grid>
                    {ff.map(x => x.map(y => (
                      <Box className={classes.borderPadding} style={{ display: 'flex' }} key={Math.random()}>
                        {item.featureClaims[module][y].map(n => (
                          <Tooltip title={n} key={n}>
                            <div style={{ padding: '10px', color: 'rgba(0, 0, 0, 0.54)' }}>
                              {permissionIcons(n)}
                            </div>
                          </Tooltip>
                        ))}
                      </Box>
                    )))}
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={2} style={{ placeSelf: 'center' }}>
        {actionButtons.map(items => (
          <Tooltip title={items.action.toUpperCase()}>
            <IconButton
              aria-label={items.action}
              key={items.action}
              onClick={() => items.handleAction(det)}
              disabled={!userPermission[items.action]}
              size="large"
            >
              {items.action === 'Edit' ? <EditIcon /> : <DeleteIcon />}
            </IconButton>
          </Tooltip>
        ))}
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Collapse">
          <IconButton
            aria-label={item.action}
            key={item.action}
            onClick={() => setOpen(!open)}
            size="large"
          >
            <ExpandLessIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  ) : (
    <Grid container xs={11} direction="row" className={classes.container} spacing={1}>
      <Grid item xs={2}>
        <Tooltip title={item.name}>
          <Box className={classes.borderRight}>{item.name}</Box>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title={featureClaimstrans.join(', ')}>
          <Box className={classes.borderRight}>{featureClaimstrans.join(', ')}</Box>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title={gg.feature || ''}>
          <Box className={classes.borderRight}>{gg.feature || ''}</Box>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Box style={{ display: 'flex' }}>
          {gg.permission.map(n => (
            <Tooltip title={n} key={n}>
              <div style={{ padding: '16px', color: 'rgba(0, 0, 0, 0.54)' }}>{permissionIcons(n)}</div>
            </Tooltip>
          ))}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box>
          {actionButtons.map(actionButton => (
            <Tooltip title={actionButton.action.toUpperCase()} key={Math.random()}>
              <IconButton
                aria-label={actionButton.action}
                key={actionButton.action}
                onClick={() => actionButton.handleAction(det)}
                disabled={!userPermission[actionButton.action]}
                size="large"
              >
                {actionButton.action === 'Edit' ? <EditIcon /> : <DeleteIcon />}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="Expand">
          <IconButton
            aria-label={item.action}
            key={item.action}
            onClick={() => setOpen(!open)}
            size="large"
          >
            <ExpandMoreIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
ExpandableRow.propTypes = IPropTypes;
ExpandableRow.defaultProps = defaultProps;
