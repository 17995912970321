import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './dashboard-list-item.module.scss';

const IPropTypes = {
  firstBlock: PropTypes.string,
  secondBlock: PropTypes.string,
  thirdBlock: PropTypes.string,
  fourthBlock: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.element])),
  fifthBlock: PropTypes.arrayOf(PropTypes.string),
  callback: PropTypes.func,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  displayBtn: PropTypes.bool,
  onBtnClick: PropTypes.func,
};

const defaultProps = {
  firstBlock: '',
  secondBlock: '',
  thirdBlock: '',
  fourthBlock: '',
  fifthBlock: [],
  callback: () => {},
  link: '#',
  disabled: false,
  displayBtn: false,
  onBtnClick: () => {},
};

const DashboardListItem = ({
  firstBlock, secondBlock, thirdBlock, fourthBlock,
  fifthBlock, callback, link, disabled, displayBtn, onBtnClick,
}) => {
  const linkStyles = disabled ? styles.disabled : '';
  const btnStyles = [
    `fa fa-download ${styles.downloadButton}`,
    disabled ? styles.disabledDownloadButton : '',
  ].join(' ');
  const actionButton = displayBtn && (
    <div className={styles.buttonColumn}>
      <button
        type="button"
        className={btnStyles}
        onClick={onBtnClick}
        disabled={disabled}
      />
    </div>
  );

  return (
    <Link className={linkStyles} to={link} onClick={callback} disabled={disabled}>
      <div className={styles.row}>
        <div className={styles.column}>
          {firstBlock}
          <span className={styles.tooltipText}>{firstBlock}</span>
        </div>
        <div className={styles.column}>
          {secondBlock}
        </div>
        <div className={styles.column}>
          {thirdBlock}
        </div>
        <div className={[styles.column, styles.images].join(' ')}>
          {fourthBlock}
        </div>
        <div className={[styles.column, styles.status].join(' ')}>
          {fifthBlock.map(el => <span key={el}>{el}</span>)}
        </div>
        {actionButton}
      </div>
    </Link>
  );
};

DashboardListItem.propTypes = IPropTypes;
DashboardListItem.defaultProps = defaultProps;

export { DashboardListItem };
