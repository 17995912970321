import { useEffect } from 'react';
import { OrdersController } from '../networking/controllers/orders';

const icdCodesStoreKeys = [
  'diagnosisPrimaryCode',
  'diagnosisSecondaryCode',
  'diagnosisTertiaryCode',
  'diagnosisQuarternaryCode',
  'diagnosisQuinaryCode',
  'diagnosisSenaryCode',
];

const useIcdCodesFiltering = (icdCodes, productFamily, saveDiagnosisInfo) => {
  useEffect(() => {
    (async () => {
      const matchedIcdCodes = await Promise.all(icdCodes.map(
        code => OrdersController.icdCodesSearch(
          code,
          productFamily,
        ),
      ));


      matchedIcdCodes.forEach((icdCodesResult, index) => {
        if (icdCodesResult.length) {
          const { icdCode, description } = icdCodesResult.find(e => e.icdCode === icdCodes[index]);
          saveDiagnosisInfo({ [icdCodesStoreKeys[index]]: { code: icdCode, description } });
        } else {
          saveDiagnosisInfo({ [icdCodesStoreKeys[index]]: { code: '', description: '' } });
        }
      });
    })();
  }, []);
};

export { useIcdCodesFiltering };
