import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import JwtDecode from 'jwt-decode';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { config } from '../config/config';
import { API_GATEWAY_URL, USER_MGMT_V2_PATH } from '../config';


const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
};
const Callback = (props) => {
  const {
    authenticityToken,
  } = props;
  const [error, setError] = useState(null);
  const formData = new FormData();
  const fetchUserPermission = () => {
    window.showSpinner();
    const tokenData = JSON.parse(
      localStorage.getItem('okta-token-storage'),
    ).accessToken;
    const oktaAccessToken = tokenData.accessToken;
    formData.append('session[email]', tokenData.claims.sub);
    formData.append('authenticity_token', authenticityToken);
    formData.append('portal', 'physician');
    formData.append('has_touch_id', '');
    formData.append('has_secret', '');
    async function fetchUserInfoAndSession() {
      const [userResponse, sessionResponse] = await Promise.all([
        fetch(`${config()[API_GATEWAY_URL]}${config()[USER_MGMT_V2_PATH]}/users/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${oktaAccessToken}`,
          },
        }),
        fetch('/session', {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${oktaAccessToken}`,
          },
        }),
      ]);
      const userData = await userResponse.json();
      return [userData, sessionResponse];
    }

    fetchUserInfoAndSession().then(([userData, sessionResponse]) => {
      localStorage.setItem(
        'userInfo',
        JSON.stringify({ isLoggedIn: true, accessInfo: userData.data }),
      );
      if (sessionResponse.ok) {
        // window.location.reload();
        const url = window.location.origin;
        window.location.assign(url);
      } else {
        // setStartSignIn(false);
        window.hideSpinner();
      }
    }).catch((err) => {
      setError(err);
    });
  };
  useEffect(() => {
    const params = queryString.parse(window.location.hash);
    if (params) {
      const decodedOktaAccessToken = JwtDecode(params.access_token);
      const accessToken = {
        accessToken: {
          accessToken: params.access_token,
          claims: decodedOktaAccessToken,
          expiresAt: decodedOktaAccessToken.exp,
          tokenType: params.token_type,
          scopes: decodedOktaAccessToken.scp,
        },
      };
      localStorage.setItem('okta-token-storage', JSON.stringify(accessToken));
      Cookies.set('login', decodedOktaAccessToken.sub);
      Cookies.set('isInternal', 'true');
      if (decodedOktaAccessToken) {
        Cookies.set('portal_version', decodedOktaAccessToken.portal_version);
        const url = window.location.origin;
        if (decodedOktaAccessToken.portal_version === 'v1') {
          fetchUserPermission();
        } else { // Default or V2
          window.location.assign(`${url}/v2/sso/sign-in`);
        }
      }
    }
  }, []);
  return error ? <div>{error}</div> : null;
};
export default Callback;
Callback.propTypes = IPropTypes;
