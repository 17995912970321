import { TrialSite } from './trial-site';

class ClinicalTrial {
  constructor(params) {
    this.id = params.id;
    this.contact = params.contact;
    this.phase = params.phase;
    this.title = params.title;
    this.url = params.url;
    this.sites = params.sites.map(s => new TrialSite(s));
  }
}

export { ClinicalTrial };
