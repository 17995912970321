import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './DownloadReportsDropdown.module.scss';

const IPropTypes = {
  requestLinks: PropTypes.shape(PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  )),
  requestsBloodCollDate: PropTypes.objectOf(
    PropTypes.string,
  ).isRequired,
  canDownloadReport: PropTypes.bool.isRequired,
};

const defaultProps = {
  requestLinks: {},
};


const InProgressMessage = () => <p className={styles.inProgressMessage}>Test is in progress</p>;

const DownloadReportsContainer = (props) => {
  const {
    requestLinks,
    requestsBloodCollDate,
    canDownloadReport,
  } = props;
  return (
    <div className={styles.container}>
      {Object.keys(Object.values(requestLinks)).length > 0
        ? (
          <DownloadReportsDropdown
            requestLinks={requestLinks}
            requestsBloodCollDate={requestsBloodCollDate}
            canDownloadReport={canDownloadReport}
          />
        )
        : <InProgressMessage />}
    </div>
  );
};
DownloadReportsContainer.propTypes = IPropTypes;
DownloadReportsContainer.defaultProps = {
  requestLinks: {},
};
const DownloadReportsButton = ({ onClick, canDownloadReport }) => {
  const dropdownClass = canDownloadReport
    ? styles.dropdownButton
    : styles.disabledDropdownButton;

  return (
    <button className={dropdownClass} type="button" onClick={onClick} disabled={!canDownloadReport}>
      DOWNLOAD REPORT
      {' '}
      <i className="fa fa-download" />
    </button>
  );
};

const IReportLinkPropTypes = {
  link: PropTypes.string,
  linkText: PropTypes.string.isRequired,
};

const ReportLink = ({ link, linkText }) => (
  <li className={styles.reportLink}>
    <a href={link} target="_blank">{linkText}</a>
  </li>
);

ReportLink.defaultProps = { link: null };
ReportLink.propTypes = IReportLinkPropTypes;

const DownloadReportsDropdown = ({ requestLinks, requestsBloodCollDate, canDownloadReport }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(prevState => !prevState);
  return (
    <div className={styles.dropdownContainer}>
      <DownloadReportsButton onClick={toggleDropdown} canDownloadReport={canDownloadReport} />
      {showDropdown && (
        <ul className={styles.dropdownMenu}>
          {Object.entries(requestLinks).map(([requestId, links]) => links
      && (
        <Fragment key={requestId}>
          <li className={styles.dropdownSubtitle}>
            {requestsBloodCollDate[requestId]}
          </li>
          { !links.length
            ? <ReportLink linkText="In progress report" />
            : links.map(link => (
              <ReportLink
                key={requestId + link.display_name}
                link={link.link}
                linkText={link.display_name}
              />
            )) }
        </Fragment>
      ))}
        </ul>
      )}
    </div>
  );
};


DownloadReportsDropdown.propTypes = IPropTypes;
DownloadReportsDropdown.defaultProps = defaultProps;

DownloadReportsButton.propTypes = {
  canDownloadReport: PropTypes.bool,
  onClick: PropTypes.func,
};

DownloadReportsButton.defaultProps = {
  canDownloadReport: false,
  onClick: null,
};

export default DownloadReportsContainer;
