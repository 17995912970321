class PhysicianInfo {
  constructor(params) {
    this.id = params.id;
    this.lastname = params.lastname;
    this.firstname = params.firstname;
    this.email = params.email;
    this.npi = params.npi;
    this.phone = params.phone;

    // For EMR flow when user types physician name in
    this.fullname = params.fullname;
  }

  isComplete = () => !!this.firstname && !!this.lastname;

  formatFullName = () => this.firstname && this.lastname && `Dr. ${this.firstname} ${this.lastname}`;

  checkRequiredFields = () => !!this.id || !!this.fullname;
}

export { PhysicianInfo };
