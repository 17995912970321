const SHOW_ERROR_BANNER = 'SHOW_ERROR_BANNER';
const HIDE_ERROR_BANNER = 'HIDE_ERROR_BANNER';
const SHOW_MISSING_REQUIRED_FIELDS = 'SHOW_MISSING_REQUIRED_FIELDS';
const HIDE_MISSING_REQUIRED_FIELDS = 'HIDE_MISSING_REQUIRED_FIELDS';
const SAVE_ERROR_BANNER_MSG = 'SAVE_ERROR_BANNER_MSG';

const showErrorBanner = () => ({
  type: SHOW_ERROR_BANNER,
});

const saveErrorBannerMsg = info => ({
  type: SAVE_ERROR_BANNER_MSG,
  info,
});

const hideErrorBanner = () => ({
  type: HIDE_ERROR_BANNER,
});

const showMissingRequiredFields = () => ({
  type: SHOW_MISSING_REQUIRED_FIELDS,
});

const hideMissingRequiredFields = () => ({
  type: HIDE_MISSING_REQUIRED_FIELDS,
});


export {
  SHOW_ERROR_BANNER,
  SAVE_ERROR_BANNER_MSG,
  HIDE_ERROR_BANNER,
  showErrorBanner,
  saveErrorBannerMsg,
  hideErrorBanner,
  SHOW_MISSING_REQUIRED_FIELDS,
  showMissingRequiredFields,
  HIDE_MISSING_REQUIRED_FIELDS,
  hideMissingRequiredFields,
};
