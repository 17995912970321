import { connect } from 'react-redux';

import { PatientSummaryContainer } from './patient-summary-container';

const mapStateToProps = store => ({
  data: store.patientPage.multiProduct.info.patientSummary,
  isAdminLogged: store.patientPage.multiProduct.info.loggedUser.admin,
});

const ConnectedPatientSummaryContainer = connect(
  mapStateToProps,
)(PatientSummaryContainer);

export { ConnectedPatientSummaryContainer as PatientSummaryContainer };
