const electronicSignatureConfirmationActionTypes = {
  TOGGLE_ELECTRONIC_SIGNATURE_MODAL: 'TOGGLE_ELECTRONIC_SIGNATURE_MODAL',
};

const toggleElectronicSignatureModal = () => ({
  type: electronicSignatureConfirmationActionTypes.TOGGLE_ELECTRONIC_SIGNATURE_MODAL,
});

export {
  electronicSignatureConfirmationActionTypes,
  toggleElectronicSignatureModal,
};
