import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const IPropTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  showHelperText: PropTypes.string,
};

const defaultProps = {
  label: '',
  value: '',
  error: '',
  name: '',
  type: 'text',
  showHelperText: undefined,
  onChange: () => {},
};
const Input = (props) => {
  const {
    name, label, value, error = null, onChange, type, showHelperText, ...other
  } = props;
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      {...other}
      {...(error && { error: true, helperText: error })}
      {...(!error && showHelperText && { error: false, helperText: showHelperText })}
    />
  );
};
export default Input;
Input.propTypes = IPropTypes;
Input.defaultProps = defaultProps;
