import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isInternalUser } from '../../networking/controllers/helper';
import { logger } from '../../util/logger';

const SignUp = (props) => {
  const {
    authenticityToken,
    rootPath,
    accountDec,
    accountType,
  } = props;
  const inputEmail = useRef(null);

  useEffect(() => {
    inputEmail.current.focus();
  }, []);
  const headers = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
  const fetchUsers = async () => {
    const email = inputEmail.current.value;
    if (email !== '') {
      const response = await axios.post('/user/usernames', { current_email: email }, { headers });
      logger.log(response);
    }
  };

  return (
    <div className="php_landing_container">
      <a href="/"><div className="logo__gh-logo" /></a>
      <div className="php_landing__trm-img" />
      <div className="header">{accountDec}</div>
      <div className="php_landing__grey-box_container">
        <div className="grey-box__text">
          Please enter the email address that we have on file for you.
        </div>
        <form
          className="fancy-form"
          id="new_password_reset"
          action="/password_resets"
          acceptCharset="UTF-8"
          method="post"
          aria-label="email"
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          <div className="input-group">
            <div className="label hide">
              <label className="label" htmlFor="password_reset_email">Username</label>
            </div>
            <input
              placeholder="Email Address"
              required="required"
              ref={inputEmail}
              className="field margin-bottom__30-px"
              type="email"
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              name="password_reset[email]"
              id="password_reset_email"
              onBlur={fetchUsers}
            />
            <input type="hidden" name="activate_account_flow" id="activate_account_flow" value="true" />
            <input value={accountType} type="hidden" name="password_reset[account_type]" id="password_reset_account_type" />
          </div>
          <div className="input-group">
            <div className="buttons-row">
              <button type="submit" className="btn__size-large btn__color-blue">SEND ACTIVATION EMAIL</button>
            </div>
          </div>
        </form>
      </div>
      <div className="php_landing__page__text">
        This requires that you have already set up an account with Client Services.
        To get set up, please call 1-855-698-8887 or
        <span className="email-link clickable">&nbsp;email us.</span>
      </div>
      <div className="php_landing__page__text">
        <div className="php_landing__page__text-link"><a href={rootPath}>Back to sign in page</a></div>
      </div>
    </div>
  );
};
export default SignUp;
SignUp.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  rootPath: PropTypes.string.isRequired,
  accountDec: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
};
