import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './StudiesDropdownTab.module.scss';
import { logger } from '../../../util/logger';

const IPropTypes = {
  tabIsActive: PropTypes.bool,
};

const defaultProps = {
  tabIsActive: false,
};

const StudiesDropdownTab = ({ tabIsActive }) => {
  const dropdownOptions = [
    { id: 1, title: 'Active Studies Only' },
    { id: 2, title: 'Closed Studies Only' },
    { id: 3, title: 'All Studies' },
    { id: 4, title: 'Custom Studies Selection...' },
  ];
  const [isOpened, setIsOpened] = useState(false);
  const toggleDropdown = () => setIsOpened(prevState => !prevState);
  // TODO: Function was done for testing purposes.
  //       Should have proper behaviour (e.g. opening modal).
  //       The idea is to send the filter options through query params
  //       from the modal (that logic will be in the modal).
  const selectItem = (title, id) => logger(title, id);

  const buttonClassName = [
    isOpened ? 'fa fa-chevron-up' : 'fa fa-chevron-down',
    tabIsActive && styles.tabIsActive,
    styles.dropdownButton].join(' ');
  return (
    <>
      <button type="button" onClick={toggleDropdown} className={buttonClassName} />
      {isOpened
        && (
          <ul className={styles.dropdownList}>
            {
              dropdownOptions.map(item => (
                <li key={item.id} id={item.id}>
                  <button type="button" onClick={() => selectItem(item.title, item.id)}>
                    {item.title}
                  </button>
                </li>
              ))
            }
          </ul>
        )
      }
    </>
  );
};

StudiesDropdownTab.propTypes = IPropTypes;
StudiesDropdownTab.defaultProps = defaultProps;

export { StudiesDropdownTab };
