import React from 'react';

import PropTypes from 'prop-types';
import { isUsername } from '../util/validator';
import GhiList from './GhiList';
import GhiListAndPhysicians from './GhiListAndPhysicians';
import { getAllGhiNumbers } from '../util/ajax';

const IPropTypes = {
  internalUsersSsoEnabled: PropTypes.bool.isRequired,
};

class PracticeManagerSignUp extends React.Component {
  static deserializeAndShare(params) {
    const { data: { sharees, physician_ids: physicianIds } } = params;
    window.createBulkShare(sharees, physicianIds);
  }

  static parseEmails(emails) {
    let newEmails = emails;
    if (emails[emails.length - 1] === ';') {
      newEmails = emails.substring(0, emails.length - 1);
    }
    const split = newEmails.split('; ');
    return split.filter(str => str.trim().length !== 0);
  }

  static invalidEmailAddresses(emails) {
    return (emails || []).filter(email => !isUsername(email)).join('\n');
  }

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      showSendEmail: false,
      sendEmailBoxChecked: true,
      ghiList: null,
      all_ghi_numbers: null,
      loaded: false,
    };
    this.selectedPhysicians = null;

    this.handleClick = this.handleClick.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.patientId = this.patientId.bind(this);
  }

  componentDidMount() {
    if (window.gon.whitelistedFeatures.adminMenuLoad) {
      if (!this.state.loaded) {
        getAllGhiNumbers(this.updateAllGhiNumbers.bind(this));
      }
    } else {
      getAllGhiNumbers(this.updateAllGhiNumbers.bind(this));
    }
  }

  updateEmail(e) {
    const currentEmail = e.target.value;
    if (!this.props.internalUsersSsoEnabled) {
      return;
    }
    if (currentEmail.match(/@guardanthealth.com$/)) {
      window.$('.email-errors').text('Please enter an email address with different domain');
      window.$('.practice-manager__button').prop('disabled', true);
    } else {
      window.$('.email-errors').text('');
      window.$('.practice-manager__button').prop('disabled', false);
    }
  }

  patientId() {
    return (
      <input
        type="text"
        id="practice_manager__email__address"
        className="grey-box__form__item grey-box__form__item-email practice-manager__input"
        placeholder="e.g. practicemanager@example.com"
        autoCorrect="off"
        autoCapitalize="none"
        onChange={this.updateEmail}
        required
      />
    );
  }

  updateAllGhiNumbers(data) {
    this.setState({ all_ghi_numbers: data, loaded: true });
  }

  updateGhiList() {
    this.setState({ ghiList: window.$('.input__ghi-number').val() });
  }

  handleClick(e) {
    this.updateGhiList();

    if (e.target.innerHTML === 'Next') {
      this.setState({ page: 2, showSendEmail: true });
    } else if (e.target.innerHTML === 'Back') {
      window.$('.input__ghi-number').val(this.state.ghiList);
      this.setState({ page: 1, showSendEmail: false });
    } else {
      this.createPracticeManager(e);
    }
  }

  windowOne() {
    return (
      <GhiList
        allGhiNumbers={this.state.all_ghi_numbers}
        ghiList={this.state.ghiList}
        {...this.props}
      />
    );
  }

  windowTwo() {
    return (
      <GhiListAndPhysicians
        ref={(element) => { this.selectedPhysicians = element; }}
        backButton={this.handleClick}
        currentGhiList={this.state.ghiList}
        allGhiNumbers={this.state.all_ghi_numbers}
        {...this.props}
      />
    );
  }

  showSendEmailCheckbox() {
    let show;

    if (this.state.showSendEmail) {
      show = (
        <div className="send-email">
          <input
            type="checkbox"
            className="practice-manager__checkbox"
            onChange={this.toggleSendEmailCheckbox.bind(this)}
            id="sendEmailCheckbox"
            checked={this.state.sendEmailBoxChecked}
          />
          Send invitation email
        </div>
      );
    }
    return show;
  }

  toggleSendEmailCheckbox() {
    const currentState = this.state.sendEmailBoxChecked;
    this.setState({ sendEmailBoxChecked: !currentState });
  }

  createPracticeManager(event) {
    event.stopPropagation();

    const emails = window.$('.grey-box__form__item-email').val();
    const parsedEmails = PracticeManagerSignUp.parseEmails(emails);

    const selectedPhysicians = this.selectedPhysicians.state.physicianCheckboxes;

    const noSelectedPhysicians = Object
      .values(selectedPhysicians)
      .some(physician => physician === true);

    const invalidEmails = PracticeManagerSignUp.invalidEmailAddresses(parsedEmails);

    if (invalidEmails !== '') {
      window.$('.email-errors').html(`Invalid emails: ${invalidEmails}`);
      return;
    }
    window.$('.email-errors').html('');

    if (!noSelectedPhysicians) {
      window.$('.physician-list-errors').html('Please select a physician to share.');
      return;
    }
    window.$('.physician-list-errors').html();
    window.showSpinner();
    window.signupPracticeManager(
      this.state.sendEmailBoxChecked,
      selectedPhysicians,
      parsedEmails,
      PracticeManagerSignUp.deserializeAndShare.bind(this),
    );
  }

  generateInnerContent() {
    const patientId = this.patientId();

    const page = this.state.page === 1 ? this.windowOne() : this.windowTwo();

    const showCheckbox = this.showSendEmailCheckbox();

    const nextButton = this.state.page === 1
      ? (
        <button
          type="button"
          className="btn__color-blue btn__size-dynamic practice-manager__button"
          onClick={this.handleClick}
        >
          Next
        </button>
      )
      : null;
    const backButton = this.state.page === 2
      ? (
        <button
          type="button"
          className="btn__color-blue btn__size-dynamic practice-manager__button"
          onClick={this.handleClick}
        >
          Back
        </button>
      )
      : null;
    const finishButton = this.state.page === 2
      ? (
        <button
          className="btn__color-blue btn__size-dynamic practice-manager__button"
          onClick={this.handleClick}
          type="button"
        >
          Finish
        </button>
      )
      : null;
    if (this.state.all_ghi_numbers !== null) {
      return (
        <div className="practice-manager-content">
          <label
            className="grey-box__form__item-label share__email__address"
            htmlFor="practice_manager__email__address"
          >
            Enter practice manager email:
            <div className="email-errors" />
            {patientId}
          </label>
          {page}
          {nextButton}
          {backButton}
          {finishButton}
          {showCheckbox}
        </div>
      );
    }
    return (
      <div className="modal-loading-spinner" />
    );
  }

  render() {
    const content = this.generateInnerContent();

    return (
      <div className="modal__container-outer sign_up__practice-manager">
        <div className="modal__container-inner">
          <div className="modal__content">
            <div className="sign_up__site-manager">
              <i className="fa fa-close modal-close" />
              <div className="sign_up__practice-manager__container">
                <div className="header">Practice Manager Sign Up</div>
                <div className="content practice-manager-page-one">
                  <div className="grey-box__form__item-container practice-manager-container">
                    <div className="grey-box__form__item">
                      { content }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PracticeManagerSignUp.propTypes = IPropTypes;
export default PracticeManagerSignUp;
