import React from 'react';
import PropTypes from 'prop-types';

import { TestsDropdown } from '../../../models/tests-dropdown';
import { TestsDropdownSerializer } from '../../../networking/serializers/tests-dropdown';
import { PatientTestsDropdownContainer } from '../patient-tests-dropdown-container/patient-tests-dropdown-container';

const IPropTypes = {
  canDownloadReport: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    download_button_name: PropTypes.string,
    selected_dropdown_name: PropTypes.string.isRequired,
    error_handling_support: PropTypes.bool.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
      display_name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })),
    dropdown_options: PropTypes.arrayOf(PropTypes.shape({
      dropdown_display_name: PropTypes.string.isRequired,
      redirect_to: PropTypes.string.isRequired,
    })).isRequired,
  }),
};

const defaultProps = {
  data: null,
};

const PatientTestsDropdownRootContainer = ({ canDownloadReport, data }) => {
  const deserializedData = data && new TestsDropdown(
    TestsDropdownSerializer.deserialize(data),
  );

  return (
    <PatientTestsDropdownContainer
      data={deserializedData}
      canDownloadReport={canDownloadReport}
    />
  );
};

PatientTestsDropdownRootContainer.propTypes = IPropTypes;
PatientTestsDropdownRootContainer.defaultProps = defaultProps;

export { PatientTestsDropdownRootContainer };
