import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './expandable-container.module.scss';

const IRenderable = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.node,
]);

const IPropTypes = {
  children: IRenderable.isRequired,
  expandedButtonContent: IRenderable.isRequired,
  collapsedButtonContent: IRenderable.isRequired,
  buttonClassName: PropTypes.string,
  transitionSeconds: PropTypes.number,
};

const defaultProps = {
  buttonClassName: '',
  transitionSeconds: 0.5,
};

const ExpandableContainer = (props) => {
  const [contentExpanded, setContentExpanded] = useState(false);
  const contentRef = useRef(null);
  return (
    <div>
      <button
        type="button"
        onClick={() => setContentExpanded(!contentExpanded)}
        className={[styles.button, props.buttonClassName].join(' ')}
      >
        {
          contentExpanded
          && (
            <>
              <i className={['fa fa-caret-up', styles.icon].join(' ')} />
              {props.expandedButtonContent}
            </>
          )
        }
        {
          !contentExpanded
          && (
            <>
              <i className={['fa fa-caret-right', styles.icon].join(' ')} />
              {props.collapsedButtonContent}
            </>
          )
        }
      </button>
      <div
        className={styles.content}
        style={{
          height: contentExpanded ? contentRef.current.clientHeight : 0,
          transition: `height ${props.transitionSeconds}s`,
        }}
      >
        <div ref={contentRef}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

ExpandableContainer.propTypes = IPropTypes;
ExpandableContainer.defaultProps = defaultProps;

export { ExpandableContainer };
