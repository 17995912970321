import React from 'react';
import PropTypes from 'prop-types';

import { InlineSearch } from '../../../generic/inline-search';
import Search from '../../Search';
import { OrdersController } from '../../../../networking/controllers/orders';

const IIcdCode = PropTypes.shape({
  code: PropTypes.string.isRequired,
});

const IPropTypes = {
  showErrorBanner: PropTypes.func.isRequired,
  productFamily: PropTypes.string.isRequired,
  selectedCodes: PropTypes.shape({
    selectedSecondaryCode: IIcdCode.isRequired,
    selectedTertiaryCode: IIcdCode.isRequired,
    selectedQuarternaryCode: IIcdCode.isRequired,
    selectedQuinaryCode: IIcdCode.isRequired,
    selectedSenaryCode: IIcdCode.isRequired,
  }),
  inline: PropTypes.bool,
};

const defaultProps = {
  selectedCodes: {
    selectedSecondaryCode: { code: '' },
    selectedTertiaryCode: { code: '' },
    selectedQuarternaryCode: { code: '' },
    selectedQuinaryCode: { code: '' },
    selectedSenaryCode: { code: '' },
  },
  inline: false,
};

const ICDSearch = (props) => {
  const fetchICDCodes = async (keywords) => {
    const codes = Object.values(props.selectedCodes).map(i => i.code);

    try {
      const availableCodes = await OrdersController.icdCodesSearch(keywords, props.productFamily);
      return availableCodes.filter(option => !codes.includes(option.icdCode));
    } catch (error) {
      props.showErrorBanner();
      return [];
    }
  };

  if (props.inline) {
    return (
      <InlineSearch
        {...props}
        onOptionsFetch={fetchICDCodes}
        minLengthSearch={1}
        labelPlaceholder="ICD.10"
      />
    );
  }

  return (
    <Search
      {...props}
      avoidCheckingInputMatches
      onOptionsFetch={fetchICDCodes}
      minLengthSearch={1}
    />
  );
};

ICDSearch.propTypes = IPropTypes;
ICDSearch.defaultProps = defaultProps;

export { ICDSearch };
