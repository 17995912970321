import {
  FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import PropTypes from 'prop-types';
import { schema } from '../schema';
import ValidationJson from '../validate.json';
import Validation from '../../admin/common/utils/Validation';
import { Form } from '../../admin/common/components/forms/useForm';
import Controls from '../../admin/common/components/forms/controls';
import ClinicalHistoryReveal from './ClinicalHistoryReveal';

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'row !important',
  },
  helperText: {
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    'overflow-y': 'clip'
  }
}));
function ClinicalHistory360(props) {
  const classes = useStyles();

  const { newOrder } = props;
  const orderManagement = newOrder && newOrder.toJS();

  const onInputChange = (e, questionObj) => {
    const {
      name, type, checked,
    } = e.target;
    let { value } = e.target;
    if (type === 'checkbox' && name !== 'relevantClinicalHistory') {
      value = checked;
    }
    const { clinicalHistory } = ValidationJson;
    if (ValidationJson.clinicalHistory[name]) {
      const error = Validation(name, value, clinicalHistory) || '';
      props.actions.setError({ name, value: error });
    }
    if (questionObj && name === 'relevantClinicalHistory') {
      let relCliHistory = newOrder.getIn(schema.clinicalHistory.relevantClinicalHistory) || [];
      relCliHistory = [...relCliHistory.filter(x => x.questionNumber !== questionObj.order),
        { questionNumber: questionObj.order, answerValue: value }];
      props.actions.onChange({ name: schema.clinicalHistory[name], value: relCliHistory });
    } else if (questionObj && questionObj.target && questionObj.target.name === 'dateOfTherapyInitiation') {
      if (ValidationJson.clinicalHistory.dateOfTherapyInitiation) {
        const error = Validation('dateOfTherapyInitiation', questionObj.target.value, clinicalHistory) || '';
        props.actions.setError({ name: 'dateOfTherapyInitiation', value: error });
      }
      props.actions.onChange({ name: schema.clinicalHistory.dateOfTherapyInitiation, value: value !== 'Invalid date' && questionObj.target.value !== 'Invalid date' && !(parseInt(questionObj.target.value?.slice(0, 4), 10) < 1900) ? questionObj.target.value : undefined });
    } else {
      props.actions.onChange({ name: schema.clinicalHistory[name] || [''], value });
    }
  };
  useEffect(() => {
    if (!orderManagement.clinicalHistory360) {
      props.actions.getClinicalHistory360();
    }
  }, [orderManagement.clinicalHistory360]);
  return (
    <>
      {newOrder.getIn(schema.testSelection.productFamily)?.id !== 'REVEAL' ? (
        <Form className={classes.root}>
          <Grid container justifyContent="center" alignItems="flex-start" xs={12}>
            <Grid item lg={7}>
              <Typography sx={{ my: '30px' }} component="h5" variant="h5">4. Clinical History</Typography>
              <Typography component="div" variant="h6" sx={{ mb: 1 }}>Current Therapy (if applicable)</Typography>
              <Grid container direction="row" sx={{ mb: 3 }} spacing={2} xs={12}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={newOrder.getIn(schema.clinicalHistory.targetedTherapy) || false}
                          onChange={onInputChange}
                          inputProps={{ 'aria-label': 'controlled', name: 'targetedTherapy' }}
                        />
                  )}
                      label="Targeted Therapy"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={newOrder.getIn(schema.clinicalHistory.immunotherapy) || false}
                          onChange={onInputChange}
                          inputProps={{ 'aria-label': 'controlled', name: 'immunotherapy' }}
                        />
                  )}
                      label="Immunotherapy"
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mb: 3 }} item xs={12}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={newOrder.getIn(schema.clinicalHistory.chemotherapy) || false}
                          onChange={onInputChange}
                          inputProps={{ 'aria-label': 'controlled', name: 'chemotherapy' }}
                        />
                  )}
                      label="Chemotherapy"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={newOrder.getIn(schema.clinicalHistory.combinationTherapy)
                          || false}
                          onChange={onInputChange}
                          inputProps={{ 'aria-label': 'controlled', name: 'combinationTherapy' }}
                        />
                  )}
                      label="Combination Therapy"
                    />
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={6}>
                    <Controls.Input
                      label="Specific Therapy"
                      name="specificTherapy"
                      id="input"
                      fullWidth
                      value={newOrder.getIn(schema.clinicalHistory.specificTherapy)}
                      onChange={onInputChange}
                      inputProps={{ maxLength: 36 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controls.DatePicker
                      mask="__-__-____"
                      label="Therapy Initiation Date"
                      name="dateOfTherapyInitiation"
                      type="date"
                      fullWidth
                      inputFormat="MM-DD-YYYY"
                      maxDate={moment()}
                      minDate={moment().year(1900).month(0).date(1)}
                      value={newOrder.getIn(schema.clinicalHistory.dateOfTherapyInitiation) || ''}
                      error={orderManagement.errors.dateOfTherapyInitiation}
                      FormHelperTextProps={{ classes: { root: classes.helperText } }}
                      // eslint-disable-next-line consistent-return
                      onChange={e => {
                        props.actions.setError({ name: 'dateOfTherapyInitiation', value: '' });
                        props.actions.onChange({ name: schema.clinicalHistory.dateOfTherapyInitiation, value: undefined });
                        if (!e) return;
                        const val = moment(e);
                        const isFutureDate = val.isAfter(new Date(),'day');
                        const isPastDate = val.isBefore(moment().year(1900).month(0).date(1),'day');
                        if (!(isFutureDate || isPastDate)) {
                          onInputChange({
                            target: {
                              name: 'dateOfTherapyInitiation',
                              value: val.format('YYYY-MM-DD').toString()
                            },
                          });
                        } else {
                          props.actions.setError({ name: 'dateOfTherapyInitiation', value: 'Please select a date between 01-01-1900 to today' });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Typography component="div" variant="h6" sx={{ mb: '15px' }}>Relevant Clinical History</Typography>
              <Grid container direction="row" spacing={2} xs={12}>
                <Grid item xs={12}>
                  {orderManagement?.clinicalHistory360?.map(val => (
                    <Grid item xs={12}>
                      <Typography component="div" variant="body1">
                        {val.description}
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          name="relevantClinicalHistory"
                          className={classes.root}
                          onChange={e => onInputChange(e, val)}
                          value={newOrder.getIn(schema.clinicalHistory.relevantClinicalHistory)?.find(x => x.questionNumber === val.order)?.answerValue || ''}
                        >

                          <FormControlLabel
                            value={val.answers[0]}
                            control={<Radio color="primary" />}
                            label={val.answers[0]}
                          />
                          <FormControlLabel
                            value={val.answers[1]}
                            control={<Radio color="primary" />}
                            label={val.answers[1]}
                          />

                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  ))}

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      ) : (
        <ClinicalHistoryReveal {...props} />
      )}
    </>
  );
}

export default ClinicalHistory360;

ClinicalHistory360.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        '-',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
    />
  );
};
TextMaskCustom.propTypes = {
  inputRef: PropTypes.any.isRequired,
};
