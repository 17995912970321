import React from 'react';

import PropTypes from 'prop-types';
import { isUsername } from '../util/validator';
import MultiSearch from './MultiSearch';
import GhiPracticeList from './GhiPracticeList';
import { getGhiPracticeList } from '../util/ajax';
import { mapArrayForReusableSearch } from '../util/helpers';

const IPropTypes = {
  internalUsersSsoEnabled: PropTypes.bool.isRequired,
};

class PhysicianAdminSignup extends React.Component {
  static deserializeAndShare(params) {
    const { data } = params;
    const { sharees } = data;
    const physicianIds = data.physician_ids;
    const users = data.phy_client_users;
    const physicianAdmin = true;
    window.createBulkShare(sharees, physicianIds, users, physicianAdmin);
  }

  static handleCloseModal() {
    window.$(':text').val('');
    window.$('.email-errors').html('');
    window.$('.ghi-list__dropdown').hide();
  }

  constructor(props) {
    super(props);
    this.state = {
      all_ghi_numbers: null,
      page: 1,
      email: null,
      showSendEmail: false,
      sendInvitationEmail: true,
      errorText: null,
      selectedIds: [],
      nextEnabled: true,
    };

    this.selectedPhysicians = {};

    this.handleCheckmarkChange = this.handleCheckmarkChange.bind(this);
    this.updateAllGhiNumbers = this.updateAllGhiNumbers.bind(this);
    this.selectDropdownItem = this.selectDropdownItem.bind(this);
    this._updateSelectedPhysicians = this._updateSelectedPhysicians.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    getGhiPracticeList(this.updateAllGhiNumbers);
  }

  _updateSelectedPhysicians(checkboxId) {
    this.selectedPhysicians[checkboxId] = !this.selectedPhysicians[checkboxId];
  }

  updateAllGhiNumbers(data) {
    const list = mapArrayForReusableSearch(data);
    this.setState({ all_ghi_numbers: list });
  }

  emailInput() {
    return (
      <input
        type="text"
        id="physician_admin__email__address"
        className="grey-box__form__item grey-box__form__item-email physician-admin-signup__input"
        placeholder="e.g. physicianadmin@example.com"
        onChange={this.update('email')}
        required
      />
    );
  }

  update(field) {
    return (e) => {
      if (this.props.internalUsersSsoEnabled && field === 'email') {
        const currentEmail = e.target.value;
        if (currentEmail.match(/@guardanthealth.com$/)) {
          this.setState({ nextEnabled: false, errorText: 'Please enter an email address with different domain' });
        } else {
          this.setState({ nextEnabled: true, errorText: null });
        }
      }
      this.setState({ [field]: e.target.value });
    };
  }

  selectDropdownItem(selectedIds) {
    this.setState({ selectedIds });
  }

  handleClick(e) {
    if (e.target.innerHTML === 'Next') {
      this.setState({ page: 2, showSendEmail: true });
    } else if (e.target.innerHTML === 'Back') {
      this.setState({ page: 1, showSendEmail: false, errorText: null });
    } else {
      this.createPhysicianAdmin(e);
    }
  }

  windowOne() {
    if (!this.state.all_ghi_numbers) return null;
    return (
      <div className="grey-box__form__item-inner grey-box__form__item site-manager-ghi-list">
        <div className="ghi-errors" />
        <label
          className="grey-box__form__item-label ghi__list"
          htmlFor="project_id"
        >
          Site GHI Number
          <MultiSearch
            id="project_id"
            list={this.state.all_ghi_numbers}
            placeholder="e.g. GHI-000000"
            selectDropdownItem={this.selectDropdownItem}
          />
        </label>

      </div>
    );
  }

  windowTwo() {
    return (
      <GhiPracticeList
        onCheckboxClick={this._updateSelectedPhysicians}
        selectedIds={this.state.selectedIds}
      />
    );
  }

  handleCheckmarkChange(field) {
    return (e) => {
      this.setState({ [field]: e.target.checked });
    };
  }

  showSendEmailCheckbox() {
    let show;

    if (this.state.showSendEmail) {
      show = (
        <div className="site-manager-checkbox__container">
          <div className="site-manager-checkbox">
            <input
              type="checkbox"
              className="practice-manager__checkbox"
              defaultChecked="true"
              value={this.state.sendInvitationEmail}
              onChange={this.handleCheckmarkChange('sendInvitationEmail')}
              id="sendEmailCheckbox"
            />
            Send invitation email
          </div>
          <div className="site-manager-checkbox">
            Physicians selected above will get an email
            to provide signature delegation to this user.
          </div>
        </div>
      );
    }
    return show;
  }

  createPhysicianAdmin(event) {
    event.stopPropagation();

    const {
      sendInvitationEmail,
      email,
    } = this.state;
    const physicianAdmin = true;

    const noSelectedPhysicians = Object
      .values(this.selectedPhysicians)
      .some(Boolean);

    if (!noSelectedPhysicians) {
      this.setState({ errorText: 'Please select a physician to share.' });
      return;
    } if (!email) {
      this.setState({ errorText: 'Email address is required.' });
      return;
    } if (!isUsername(email)) {
      this.setState({ errorText: 'Please enter a valid email address.' });
      return;
    }
    this.setState({ errorText: null });


    window.showSpinner();
    window.createPhysicianAdmin(this.selectedPhysicians, sendInvitationEmail,
      email, physicianAdmin, PhysicianAdminSignup.deserializeAndShare);
  }

  generateInnerContent() {
    const emailInput = this.emailInput();

    const page = this.state.page === 1 ? this.windowOne() : this.windowTwo();

    const showCheckbox = this.showSendEmailCheckbox();

    const nextButton = this.state.page === 1
      ? (
        <button
          type="button"
          className="btn__color-blue btn__size-dynamic practice-manager__button"
          onClick={this.handleClick.bind(this)}
          disabled={!this.state.nextEnabled}
        >
          Next
        </button>
      )
      : null;
    const backButton = this.state.page === 2
      ? (
        <button
          type="button"
          className="btn__color-blue btn__size-dynamic practice-manager__button"
          onClick={this.handleClick.bind(this)}
        >
          Back
        </button>
      )
      : null;
    const finishButton = this.state.page === 2
      ? (
        <button
          type="button"
          className="btn__color-blue btn__size-dynamic practice-manager__button"
          onClick={this.handleClick.bind(this)}
        >
          Finish
        </button>
      )
      : null;
    if (this.state.all_ghi_numbers !== null) {
      return (
        <div className="practice-manager-content">
          <label
            className="grey-box__form__item-label share__email__address"
            htmlFor="physician_admin__email__address"
          >
            Enter physician admin email:
            {emailInput}
          </label>
          <div className="email-errors" />
          {page}
          {nextButton}
          {backButton}
          {finishButton}
          {showCheckbox}
        </div>
      );
    }
    return (
      <div className="modal-loading-spinner" />
    );
  }

  render() {
    const content = this.generateInnerContent();

    return (
      <div className="modal__container-outer physician-admin-signup">
        <div className="modal__container-inner">
          <div className="modal__content">
            <div className="sign_up__site-manager">
              <button
                type="button"
                className="fa fa-close modal-close"
                onClick={PhysicianAdminSignup.handleCloseModal}
              />
              <div className="sign_up__practice-manager__container">
                <div className="header">Physician Admin Sign Up</div>
                <div className="content practice-manager-page-one">
                  <div className="grey-box__form__item-container practice-manager-container">
                    <div className="email-errors" id="email-errors">{this.state.errorText}</div>
                    <div className="grey-box__form__item">
                      { content }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
PhysicianAdminSignup.propTypes = IPropTypes;
export default PhysicianAdminSignup;
