import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import {
  LOAD_SHARES_DATA,
  LOAD_SHARES_DONE,
  LOAD_PHYSICIANS_DONE,
  LOAD_PHYSICIAN_ADMIN_DONE,
} from '../actions';
import {
  IS_SHARES_LOADING,
  PHYSICIAN_ADMIN_LIST,
  PHYSICIAN_LIST,
  SHARES_LIST,
} from '../commons';

export const DEFAULT_SHARES_STATE = Immutable.fromJS({
  [SHARES_LIST]: Immutable.fromJS({}),
  [IS_SHARES_LOADING]: false,
  [PHYSICIAN_ADMIN_LIST]: {},
  [PHYSICIAN_LIST]: {},
});

const SharesReducers = handleActions({
  [LOAD_SHARES_DATA]: (state, { payload: { data } }) => state.set(SHARES_LIST, data),
  [LOAD_SHARES_DONE]: state => state.set(IS_SHARES_LOADING, true),
  [LOAD_PHYSICIANS_DONE]: (state, { payload: data }) => state.set(PHYSICIAN_LIST, data),
  [LOAD_PHYSICIAN_ADMIN_DONE]: (state, { payload: data }) => state.set(PHYSICIAN_ADMIN_LIST, data),
}, DEFAULT_SHARES_STATE);

export default SharesReducers;
