import HttpService from '../services/HttpService';
import { RequestsEndpoints } from '../endpoints';
import { RequestSerializer } from '../serializers/request';
import { Request } from '../../models/request';
import { isInternalUser } from './helper';

export class RequestsController {
  static getRequests = async () => {
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
    const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
    const response = await HttpService.get(`${RequestsEndpoints.GET_REQUESTS}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      ...extHeader,
    });

    return {
      rootUrl: response.data.root_url,
      requests: response.data.requests.map(
        req => new Request(RequestSerializer.deserialize(req)),
      ),
    };
  };
}
