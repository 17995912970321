import { connect } from 'react-redux';

import { PhysicianInformationExpanded } from './physician-information-expanded';

const mapStateToProps = ({ orders: { activeOrder: { physicianInfo, formErrors } } }) => ({
  client: physicianInfo.client,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const ConnectedPhysicianInformationExpanded = connect(
  mapStateToProps,
)(PhysicianInformationExpanded);

export { ConnectedPhysicianInformationExpanded as PhysicianInformationExpanded };
