import {
  IS_SHARES_LOADING,
  PHYSICIAN_ADMIN_LIST,
  PHYSICIAN_LIST,
  SHARES_LIST,
} from './commons';

export const getSharesList = state => state.shares.get(SHARES_LIST);
export const isSharesLoading = state => state.shares.get(IS_SHARES_LOADING);
export const getPhysiciansList = state => state.shares.get(PHYSICIAN_LIST);
export const getPhysicianAdminList = state => state.shares.get(PHYSICIAN_ADMIN_LIST);
