import React from 'react';
import PropTypes from 'prop-types';

import { ReleaseActivityLog } from './release_activity_log';

const IPropTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};

const activityReleases = window.gon.activityLogs;

const LunarReleaseModal = ({ onClose, content }) => (
  <div className="modal__container-outer release-to-patient__modal show_table">
    <div className="modal__container-inner release-to-patient__modal">
      <div className="modal__content">
        <div className="release-to-patient__modal__content">
          <i className="fa fa-close modal-close" type="button" onClick={onClose} />
          <div className="modal-body">
            <div className="left-side">
              {content}
            </div>
            <div className="right-side">
              <ReleaseActivityLog releases={activityReleases} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

LunarReleaseModal.propTypes = IPropTypes;

export { LunarReleaseModal };
