const CLEAR_ORDER_ERROR = 'CLEAR_ORDER_ERROR';
const CLEAR_REDIRECT_ROUTE = 'CLEAR_REDIRECT_ROUTE';
const RECEIVE_ORDER_ERROR = 'RECEIVE_ORDER_ERROR';
const REDIRECT_AFTER_ERROR = 'REDIRECT_AFTER_ERROR';

const receiveError = errors => ({
  type: RECEIVE_ORDER_ERROR,
  errors,
});

const redirectAfterErrorTo = route => ({
  type: REDIRECT_AFTER_ERROR,
  route,
});

const clearError = () => ({
  type: CLEAR_ORDER_ERROR,
});

const clearRedirectAfterErrorRoute = () => ({
  type: CLEAR_REDIRECT_ROUTE,
});

export {
  CLEAR_ORDER_ERROR,
  CLEAR_REDIRECT_ROUTE,
  RECEIVE_ORDER_ERROR,
  REDIRECT_AFTER_ERROR,
  receiveError,
  redirectAfterErrorTo,
  clearError,
  clearRedirectAfterErrorRoute,
};
