import { ActionBar } from './action-bar';
import { AlterationsTable } from './alterations-table';
import { AssociatedTest } from './associated-test';
import { BiomarkersTable } from './biomarkers-table';
import { Dictionary } from './dictionary';
import { ImmunohistochemistryTable } from './immunohistochemistry-table';
import { LoggedUser } from './logged-user';
import { PatientSummary } from './patient-summary';
import { ReferenceMenu } from './reference-menu';
import { Alert } from './alert';
import { InterpretationAndMethodology } from './interpretation-and-methodology';
import { TestsDropdown } from './tests-dropdown';
import { ReferenceRange } from './reference-range';

class PatientPageMultiProduct {
  constructor(params) {
    this.alterationsTable = params.alterationsTable
      && new AlterationsTable(params.alterationsTable);
    this.biomarkersTable = params.biomarkersTable && new BiomarkersTable(params.biomarkersTable);
    this.dictionary = params.dictionary && new Dictionary(params.dictionary);
    this.patientSummary = params.patientSummary && new PatientSummary(params.patientSummary);
    this.references = params.references && new ReferenceMenu(params.references);
    this.associatedTest = params.associatedTest && new AssociatedTest(params.associatedTest);
    this.loggedUser = params.loggedUser && new LoggedUser(params.loggedUser);
    this.viewedAsUser = params.viewedAsUser && new LoggedUser(params.viewedAsUser);
    this.actionBar = params.actionBar && new ActionBar(params.actionBar);
    this.immunohistochemistryTable = params.immunohistochemistryTable
      && new ImmunohistochemistryTable(params.immunohistochemistryTable);
    this.interpretationAndMethodology = params.interpretationAndMethodology
      && new InterpretationAndMethodology(params.interpretationAndMethodology);
    this.comment = params.comment;
    this.alert = params.alert && new Alert(params.alert);
    this.primaryStatusMessages = params.primaryStatusMessages;
    this.testsDropdown = params.testsDropdown && new TestsDropdown(params.testsDropdown);
    this.referenceRange = params.referenceRange && new ReferenceRange(params.referenceRange);
    this.generalMessage = params.generalMessage;
  }
}

export { PatientPageMultiProduct };
