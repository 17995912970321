import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  tableCell: {
    textTransform: 'uppercase',
    backgroundColor: '#C0C0C0',
    border: '1px solid #808080',
  },
});
const TableHeader = ({ actions, headers }) => {
  const classes = useStyles();
  const headerKeys = headers || [];
  return (
    <TableHead>
      <TableRow>
        {headerKeys.map(item => (
          <TableCell className={classes.tableCell} key={item}>
            {`${item.name}`}
          </TableCell>
        ))}
        {actions && <TableCell className={classes.tableCell}>Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
};
export default TableHeader;
TableHeader.propTypes = {
  actions: PropTypes.array,
  headers: PropTypes.array,
};
TableHeader.defaultProps = {
  actions: null,
  headers: [],
};
