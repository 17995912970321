import { ClinicalTrialSerializer } from './clinical-trial';
import { TherapySerializer } from './therapy';

class AlterationSerializer {
  static deserialize(data) {
    return {
      id: data.key,
      name: data.name,
      gene: data.gene,
      description: data.alterationDescription,
      synonym: data.synonym,
      showPlasmaCopyNumber: data['showPlasmaCopyNumber?'],
      plasmaCopyNumber: data.plasmaCopyNumber,
      synonymous: data['synonymous?'],
      richDescription: data['richDescription?'],
      noTherapiesDetected: data['noTherapiesDetected?'],
      additionalTherapyInformation: data['additionalTherapyInformation?'],
      therapies: {
        approvedForThisDisease: (data.therapies.approvedForThisDisease || []).map(
          TherapySerializer.deserialize,
        ),
        approvedForOtherDiseases: (data.therapies.approvedForOtherDiseases || []).map(
          TherapySerializer.deserialize,
        ),
        lackOfResponse: (data.therapies.lackOfResponse || []).map(TherapySerializer.deserialize),
      },
      unknownSignificance: data['unknownSignificance?'],
      percentage: data.value,
      clinicalTrialsAvailable: data['clinicalTrialsAvailable?'],
      clinicalTrialsText: data.clinicalTrialsText,
      clinicalTrials: (data.clinicalTrials || []).map(ClinicalTrialSerializer.deserialize),
      shaded: data['shaded?'],
      frequencySortKey: data.frequencySortKey,
      alterationSortKey: data.alterationSortKey,
      therapySortKey: data.therapySortKey,
      clinicalSortKey: data.clinicalSortKey,
    };
  }
}

export { AlterationSerializer };
