import { connect } from 'react-redux';

import { MedicalCollaborator } from './medical-collaborator';

const mapStateToProps = store => ({
  secondaryRecipient: store.orders.activeOrder.physicianInfo.secondaryRecipient,
});

const ConnectedMedicalCollaborator = connect(mapStateToProps)(MedicalCollaborator);

export { ConnectedMedicalCollaborator as MedicalCollaborator };
