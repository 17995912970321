export const mockEtrfIsComplete = jest.fn();
export const mockGeneratePrintData = jest.fn();

export class StoreHelper {
  static etrfIsComplete(store) {
    return mockEtrfIsComplete(store);
  }

  static generatePrintData(activeOrderStore) {
    return mockGeneratePrintData(activeOrderStore);
  }
}
