import { connect } from 'react-redux';

import { BillingInformationCollapsed } from './billing-information-collapsed';

const mapStateToProps = ({ orders: { activeOrder: { billingInfo } } }) => ({
  billingInfo,
});

const ConnectedBillingInformationCollapsed = connect(mapStateToProps)(BillingInformationCollapsed);

export { ConnectedBillingInformationCollapsed as BillingInformationCollapsed };
