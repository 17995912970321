import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  display: PropTypes.bool,
  className: PropTypes.string,
  sectionClassname: PropTypes.string,
  largeValue: PropTypes.bool,
  extraLargeValue: PropTypes.bool,
  truncate: PropTypes.bool,
  highlightTitle: PropTypes.bool,
};

const defaultProps = {
  value: 'N/A',
  display: true,
  className: '',
  sectionClassname: '',
  largeValue: false,
  extraLargeValue: false,
  truncate: true,
  highlightTitle: false,
};

const CollapsedTab = (props) => {
  const {
    className, value, title, display, highlightTitle,
  } = props;
  const defaultValue = (
    <div className="collapsed-section__default-value">
      N/A
    </div>
  );
  if (!display) {
    return null;
  }

  const valueClassName = [
    props.sectionClassname,
    props.largeValue ? 'collapsed-section__large-value' : '',
    props.extraLargeValue ? 'collapsed-section__extra-large-value' : '',
    props.extraLargeValue ? 'collapsed-section__extra-large-value' : '',
    props.truncate ? 'truncate' : '',
    'collapsed-section__value',
  ].join(' ');

  return (
    <div className={[className, 'collapsed-section'].join(' ')}>
      <div className="collapsed-section__title">
        <span className={highlightTitle ? 'highlightedTitle' : ''}>{title}</span>
        :
      </div>

      {
        value && value.replace(/\s/g, '').length
          ? (
            <div className="tooltip-container">
              <div className={valueClassName}>
                {value}
              </div>
              <span className={[props.sectionClassname, 'tooltiptext'].join(' ')}>
                {value}
              </span>
            </div>
          ) : defaultValue
      }

    </div>
  );
};

CollapsedTab.propTypes = IPropTypes;
CollapsedTab.defaultProps = defaultProps;

export { CollapsedTab };
