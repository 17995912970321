import React from 'react';
import PropTypes from 'prop-types';
import { ListPicker } from '../../../generic/list-picker/list-picker';

const IPropTypes = {
  availableItemsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  suscribedItemsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  filteredAvailableList: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterCallback: PropTypes.func.isRequired,
  buttonEnable: PropTypes.bool.isRequired,
};

const ProjectListPicker = ({
  availableItemsList,
  suscribedItemsList,
  addItem,
  removeItem,
  filteredAvailableList,
  filterCallback,
  buttonEnable,
}) => (
  <ListPicker
    title="PROJECTS"
    firstList={availableItemsList}
    secondList={suscribedItemsList}
    firstListTitle="Available"
    secondListTitle="Subscribed"
    addItemCallback={addItem}
    removeItemCallback={removeItem}
    filteredFirstList={filteredAvailableList}
    filterCallback={filterCallback}
    buttonEnable={buttonEnable}
  />
);

ProjectListPicker.propTypes = IPropTypes;
export { ProjectListPicker };
