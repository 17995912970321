import { connect } from 'react-redux';

import { EtrfErrorHandler } from './EtrfErrorHandler';
import { reactToETRFError } from '../../../store/actions/orders/active-order/order';

const mapDispatchToProps = dispatch => ({
  onError: route => dispatch(reactToETRFError(route)),
});

const WrappedEtrfErrorHandler = connect(null, mapDispatchToProps)(EtrfErrorHandler);

export { WrappedEtrfErrorHandler as EtrfErrorHandler };
