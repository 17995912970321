import { LocalStorageApi } from '../../../helpers/local-storage';
import {
  ORDERING_CONFIGURATION,
  ORDERING_PATIENT_STATUSES,
  ORDERING_PATIENT_TYPES,
  ORDERING_TEST_SELECTIONS,
  ORDERING_RELEVANT_CLINICAL_HISTORY,
  ORDERING_DIAGNOSES,
} from '../../../helpers/local-storage-keys';
import { OrdersController } from '../../../networking/controllers/orders';

const staticDataActionTypes = {
  ADD_PATIENT_STATUSES: 'ADD_PATIENT_STATUSES',
  ADD_TEST_SELECTIONS: 'ADD_TEST_SELECTIONS',
  ADD_PATIENT_TYPES: 'ADD_PATIENT_TYPES',
  ADD_CONFIGURATION: 'ADD_CONFIGURATION',
  ADD_RELEVANT_CLINICAL_HISTORY: 'ADD_RELEVANT_CLINICAL_HISTORY',
  ADD_DIAGNOSES: 'ADD_DIAGNOSES',
};

const addPatientStatuses = patientStatuses => ({
  info: patientStatuses,
  type: staticDataActionTypes.ADD_PATIENT_STATUSES,
});

const addTestSelections = testSelections => ({
  info: testSelections,
  type: staticDataActionTypes.ADD_TEST_SELECTIONS,
});

const addPatientTypes = patientTypes => ({
  info: patientTypes,
  type: staticDataActionTypes.ADD_PATIENT_TYPES,
});

const addConfiguration = configuration => ({
  configuration,
  type: staticDataActionTypes.ADD_CONFIGURATION,
});

const addRelevantClinicalHistory = relevantClinicalHistory => ({
  info: relevantClinicalHistory,
  type: staticDataActionTypes.ADD_RELEVANT_CLINICAL_HISTORY,
});

const addDiagnoses = diagnoses => ({
  diagnoses,
  type: staticDataActionTypes.ADD_DIAGNOSES,
});

const loadStoredStaticData = (dispatch, productFamily) => {
  const testSelections = LocalStorageApi.get(`${ORDERING_TEST_SELECTIONS}-${productFamily}`);
  if (testSelections) {
    dispatch(addTestSelections(testSelections));
  }

  const patientTypes = LocalStorageApi.get(ORDERING_PATIENT_TYPES);
  if (patientTypes) {
    dispatch(addPatientTypes(patientTypes));
  }

  const patientStatuses = LocalStorageApi.get(ORDERING_PATIENT_STATUSES);
  if (patientStatuses) {
    dispatch(addPatientStatuses(patientStatuses));
  }

  const configurations = LocalStorageApi.get(ORDERING_CONFIGURATION);
  if (configurations) {
    dispatch(addConfiguration(configurations));
  }

  const relevantClinicalHistory = LocalStorageApi.get(`${ORDERING_RELEVANT_CLINICAL_HISTORY}-${productFamily}`);
  if (relevantClinicalHistory) {
    dispatch(addRelevantClinicalHistory(relevantClinicalHistory));
  }

  const diagnoses = LocalStorageApi.get(ORDERING_DIAGNOSES);
  if (diagnoses) {
    dispatch(addDiagnoses(diagnoses));
  }
};

const fetchPatientStatuses = async (dispatch) => {
  const response = await OrdersController.getPatientStatuses();

  if (response.data) {
    LocalStorageApi.set(ORDERING_PATIENT_STATUSES, response.data);
    dispatch(addPatientStatuses(response.data));
  }
};

const fetchRelevantClinicalHistory = async (dispatch, productFamily) => {
  const data = await OrdersController.getRelevantClinicalHistory(productFamily);

  if (data) {
    LocalStorageApi.set(`${ORDERING_RELEVANT_CLINICAL_HISTORY}-${productFamily}`, data);
    dispatch(addRelevantClinicalHistory(data));
  }
};

const fetchTestSelections = async (dispatch, productFamily) => {
  const data = await OrdersController.getTestSelections(productFamily);

  if (data) {
    LocalStorageApi.set(`${ORDERING_TEST_SELECTIONS}-${productFamily}`, data);
    dispatch(addTestSelections(data));
  }
};

const fetchPatientTypes = async (dispatch) => {
  const response = await OrdersController.getPatientTypes();

  if (response.data) {
    LocalStorageApi.set(ORDERING_PATIENT_TYPES, response.data);
    dispatch(addPatientTypes(response.data));
  }
};

const fetchConfiguration = async (dispatch) => {
  const configuration = await OrdersController.orderingConfiguration();

  LocalStorageApi.set(ORDERING_CONFIGURATION, configuration);
  dispatch(addConfiguration(configuration));
};

const fetchDiagnoses = async (dispatch) => {
  const diagnoses = await OrdersController.getGroupedDiagnoses();

  if (diagnoses) {
    LocalStorageApi.set(ORDERING_DIAGNOSES, diagnoses);
    dispatch(addDiagnoses(diagnoses));
  }
};

const fetchStaticData = productFamily => async (dispatch) => {
  loadStoredStaticData(dispatch, productFamily);
  fetchPatientStatuses(dispatch);
  fetchPatientTypes(dispatch);
  fetchConfiguration(dispatch);
  fetchRelevantClinicalHistory(dispatch, productFamily);
  fetchDiagnoses(dispatch);
  // NOTE: this needs to be awaited since the data is needed
  // when fetching the orders.
  await fetchTestSelections(dispatch, productFamily);
};

export { staticDataActionTypes, fetchStaticData, fetchConfiguration };
