import React from 'react';
import PropTypes from 'prop-types';

import styles from './info-button.module.scss';

const IPropTypes = {
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => {},
};

const InfoButton = props => (
  <button
    className={styles.button}
    type="button"
    onClick={props.onClick}
  >
    <i className="fa fa-info-circle" />
  </button>
);

InfoButton.propTypes = IPropTypes;
InfoButton.defaultProps = defaultProps;

export { InfoButton };
