import React from 'react';
import styles from './orders-banner.module.scss';

const bannerTextNew = 'NEW';
const bannerTextTestOrdering = 'Start Guardant test ordering here!';
const buttonText = 'Start Here';

const OrdersBanner = () => {
  /*
  Sunset V1OO
  const enableOrder = 'Enable Order V2';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let newOrders = userInfo && userInfo.accessInfo.settings[enableOrder].currentValue;
  if (userInfo && userInfo.accessInfo.authorities.admin) {
    newOrders = true;
  }
  */
  return (
    <div role="banner" className={styles.banner}>
      <i className={`fa fa-bullhorn ${styles.bannerIcon}`} />
      <div className={styles.bannerTextNew}>{`${bannerTextNew} -`}</div>
      <div className={styles.bannerTextTestOrdering}>{bannerTextTestOrdering}</div>
      <a role="button" href="/new_orders" className={styles.bannerButton}>{buttonText}</a>
    </div>
  );
};

export { OrdersBanner };
