import React from 'react';
import PropTypes from 'prop-types';

function MyGuardantSlideInMenu(props) {
  const {
    fullName,
    imgSrc,
    lastLogin,
    myGuardantDashboardPath,
    myGuardantSettingsPath,
    myGuardantTermsOfUsePath,
    patientRootPath,
    signOutPath,
  } = props;
  const pathName = window.location.pathname;
  return (
    <>
      <div
        className="visible-xs active"
        id="slide-in-menu"
        style={{ transform: ' translate(388px, 0px)' }}
      >
        <div
          className="slide-in-menu__content affix"
          data-offset-top="0"
          data-spy="affix"
        >
          <img
            className="logo-mobile visible-xs"
            height="300px"
            alt="logo"
            src={imgSrc}
          />
          {lastLogin && (
            <div className="menu-block">
              <div className="menu-block-item header bold">
                {fullName}
              </div>
              <div className="menu-block-item sub-description">
                Last login:
                {' '}
                {lastLogin}
              </div>
            </div>
          )}
          {
            <div className="menu-block">
              <div className="menu-block-item">
                <a
                  className={
                    [
                      myGuardantDashboardPath,
                      patientRootPath,
                    ].includes(pathName) ? 'active' : undefined
                  }
                  href={myGuardantDashboardPath}
                >
                  YOUR REPORT
                </a>
              </div>
            </div>
          }
          <div className="menu-block">
            <div className="menu-block-item margin-bottom__25-px">
              <a
                className={
                  pathName === myGuardantSettingsPath ? 'active' : undefined
                }
                href={myGuardantSettingsPath}
              >
                Settings
              </a>
            </div>
            <div className="menu-block-item margin-bottom__25-px">
              <a
                className={
                  pathName === myGuardantTermsOfUsePath ? 'active' : undefined
                }
                href={myGuardantTermsOfUsePath}
              >
                Terms and Conditions of Use
              </a>
            </div>
            <div className="menu-block-item">
              <a rel="nofollow" data-method="post" href={signOutPath}>
                Sign Out
              </a>
            </div>
          </div>
          <div className="menu-block" />
        </div>
      </div>
    </>
  );
}

export default MyGuardantSlideInMenu;

MyGuardantSlideInMenu.propTypes = {
  fullName: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  lastLogin: PropTypes.string.isRequired,
  myGuardantDashboardPath: PropTypes.string.isRequired,
  myGuardantSettingsPath: PropTypes.string.isRequired,
  myGuardantTermsOfUsePath: PropTypes.string.isRequired,
  patientRootPath: PropTypes.string.isRequired,
  signOutPath: PropTypes.string.isRequired,
};
