import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AddDiagnosisInfo } from '../../../../store/actions/orders/active-order/diagnosis_info';

import { DiagnosisConditionLateStageExpanded } from './diagnosis-condition-late-stage-expanded';

const mapStateToProps = ({ orders: { activeOrder: { diagnosisInfo, formErrors } } }) => ({
  // TODO: we need to get this from the backend when supporting reveal.
  stageText: 'Stage IIIB/IV NSCLC, Stage III/IV - Other Cancer Type',
  diagnosisStore: diagnosisInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveDiagnosisInfo: AddDiagnosisInfo,
}, dispatch);

const ConnectedDiagnosisConditionLateStageExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(DiagnosisConditionLateStageExpanded);

export { ConnectedDiagnosisConditionLateStageExpanded as DiagnosisConditionLateStageExpanded };
