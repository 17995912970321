import { createAction } from 'redux-actions';

export const GET_MATCHGEN = '@usermanagment-workflow/GET_MATCHGEN';
export const getMatchGen = createAction(GET_MATCHGEN);

export const GET_MATCHGEN_SENT = '@usermanagment-workflow/GET_MATCHGEN_SENT';
export const sentGen = createAction(GET_MATCHGEN_SENT);

export const MATCHGEN_ERROR = '@usermanagment-workflow/MATCHGEN_ERROR';
export const matchGenError = createAction(MATCHGEN_ERROR);

export const SAVE_MATCHGEN = '@usermanagment-workflow/SAVE_MATCHGEN';
export const saveMacthGen = createAction(SAVE_MATCHGEN);

export const SAVE_SEARCH_SPACE = '@usermanagment-workflow/SAVE_SEARCH_SPACE';
export const saveSearchSpace = createAction(SAVE_SEARCH_SPACE);

export const SEARCH_ERROR = '@usermanagment-workflow/SEARCH_ERROR';
export const searchError = createAction(SEARCH_ERROR);

export const SAVE_GENOMIC = '@usermanagment-workflow/SAVE_GENOMIC';
export const saveGenomic = createAction(SAVE_GENOMIC);

export const GENOMIC_ERROR = '@usermanagment-workflow/GENOMIC_ERROR';
export const GenomicError = createAction(GENOMIC_ERROR);

export const UPDATE_MATCHGEN = '@usermanagment-workflow/UPDATE_MATCHGEN';
export const updateMatchgen = createAction(UPDATE_MATCHGEN);

export const UPDATE_MATCHGEN_ERROR = '@usermanagment-workflow/UPDATE_MATCHGEN_ERROR';
export const updateMatchGenError = createAction(UPDATE_MATCHGEN_ERROR);

export const MATCH_PAGINATION_CHANGE = '@usermanagment-workflow/MATCH_PAGINATION_CHANGE';
export const changePageMatchGen = createAction(MATCH_PAGINATION_CHANGE);

export const ON_CHANGE_ORGANIZATION = '@usermanagment-workflow/ON_CHANGE_ORGANIZATION';
export const onChangeOrganization = createAction(ON_CHANGE_ORGANIZATION);

export const defaultMatchgen = createAction('');
