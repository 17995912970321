import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAccountsData,
  deleteAccountsData,
  addAccountsData,
  onChange,
  changePagination,
} from './actions';
import AccountView from './AccountsView';

const mapStateToProps = state => ({
  account: state.accounts,
  site: state.sites,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAccountsData,
      deleteAccountsData,
      addAccountsData,
      onChange,
      changePagination,
    },
    dispatch,
  ),
});
export const ConnectedAccountView = connect(mapStateToProps, mapDispatchToProps)(AccountView);
export default ConnectedAccountView;
