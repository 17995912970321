import { CurrentTherapyInfo } from './current-therapy-info';
import { LateStageClinicalHistoryInfo } from './late-stage-clinical-history-info';
import { LateStageDiagnosisInfo } from './late-stage-diagnosis-info';
import { Order } from './order';
import { TestSelectionInfo } from './test-selection-info';

class G360Order extends Order {
  constructor(params) {
    super(params);

    this.testSelectionInfo = new TestSelectionInfo(params.testSelectionInfo);
    this.diagnosisInfo = new LateStageDiagnosisInfo(params.diagnosisInfo);
    this.currentTherapyInfo = new CurrentTherapyInfo(params.currentTherapyInfo);
    this.clinicalHistoryInfo = new LateStageClinicalHistoryInfo(
      params.clinicalHistoryInfo,
    );
  }

  buildFormSummaryForPrintModal = () => ([
    this.formatPhysicianInfoForPrintModal(),
    this.patientInfo.formatForPrintModal(),
    this.diagnosisInfo.formatForPrintModal(),
    this.testSelectionInfo.formatForPrintModal(),
    this.currentTherapyInfo.formatForPrintModal(),
    this.clinicalHistoryInfo.formatForPrintModal(),
    this.billingInfo.formatForPrintModal(),
  ]);

  checkRequiredFields = () => (
    this.accountInfo.checkRequiredFields()
    && this.physicianInfo.checkRequiredFields()
    && this.patientInfo.checkRequiredFields()
    && this.diagnosisInfo.checkRequiredFields()
    && this.testSelectionInfo.checkRequiredFields()
  );

  selectedTests = () => this.testSelectionInfo.selectedProductIds()
}

export { G360Order };
