import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AddDiagnosisInfo } from '../../../../store/actions/orders/active-order/diagnosis_info';

import { DiagnosisConditionLateStageContainer } from './diagnosis-condition-late-stage-container';


const mapStateToProps = ({ orders: { activeOrder: { diagnosisInfo } } }) => ({
  icdCodes: [
    diagnosisInfo.diagnosisPrimaryCode.code,
    diagnosisInfo.diagnosisSecondaryCode.code,
    diagnosisInfo.diagnosisTertiaryCode.code,
    diagnosisInfo.diagnosisQuarternaryCode.code,
    diagnosisInfo.diagnosisQuinaryCode.code,
    diagnosisInfo.diagnosisSenaryCode.code,
  ].filter(Boolean),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveDiagnosisInfo: AddDiagnosisInfo,
}, dispatch);

const ConnectedDiagnosisConditionLateStageContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(DiagnosisConditionLateStageContainer);

export { ConnectedDiagnosisConditionLateStageContainer as DiagnosisConditionLateStageContainer };
