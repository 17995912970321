import moment from 'moment';

export const createData = (id, patient = '', dob, testOrdered, diagnosis = '', createdBy,
  lastAction, status, physician, affiliation = '', portalOrderId, followOnTestOrdered, trfDisplayName, currentOrder) => ({
  patient,
  dob,
  testOrdered,
  diagnosis,
  createdBy,
  lastAction,
  status,
  physician,
  affiliation,
  portalOrderId,
  followOnTestOrdered,
  trfDisplayName,
  currentOrder,
});

export const modifiedOrders = orders => orders && orders.map((item = {}) => createData(
  // eslint-disable-next-line no-underscore-dangle
  item._id,
  [item.patient?.demographic?.lastName, item.patient?.demographic?.firstName].filter(x => (x || '').length > 0).join(', ') || 'N/A',
  item.patient?.demographic?.dob ? moment(item.patient?.demographic?.dob).format('MM-DD-YYYY') : '',
    item.testOrdered?.name?.join(','),
    item.diagnosis?.disease || '',
    [item.orderingUser?.lastName, item.orderingUser?.firstName].filter(x => (x || '').length > 0).join(', ') || 'N/A',
    moment(item.updatedAt).format('MM-DD-YYYY'),
    item.status || '',
    [item?.authorizingProvider?.lastName, item?.authorizingProvider?.firstName].filter(x => (x || '').length > 0).join(', '),
    `${item?.client?.practiceName || ''}`,
    item.portalOrderId,
    item.testOrdered?.followOnTestOrdered,
    item.diagnosis?.trfDisplayName || '',
    item,
));
export const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

export const editPatientData = (pendingOrder, editPatientOrder, ordersType) => (
  editPatientOrder({
    orders: { ...JSON.parse(JSON.stringify(pendingOrder)) },
    ordersType,
  }));

export const getUserInfo = () => {
  const userInfo = localStorage.getItem('userInfo');
  return JSON.parse(userInfo);
};
