import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../generic/Modal';
import warningIcon from '../../../assets/images/icons/warning.svg';
import styles from './LogoutWarningModal.module.scss';

const IPropTypes = {
  timeRemaining: PropTypes.string,
  onSignIn: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  timeRemaining: ' 15 minutes',
  onSignIn: () => {},
  onClose: () => {},
};

const LogoutWarningModal = props => (
  <Modal>
    <div className={styles.container}>
      <div className={styles.header}>
        <button
          type="button"
          className={['fa fa-close modal-close', styles.closeIcon].join(' ')}
          onClick={props.onClose}
        />
        <h3 className={styles.heading1Warning}>
          Your session will expire soon...
        </h3>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.warningContainer}>
          <div>
            <img alt="" src={warningIcon} className={styles.warningIcon} />
          </div>
          <p className={[styles.textLargeStandard, styles.warningText].join(' ')}>
            You’ve been inactive for some time.
            For your security, the Guardant portal will sign you out in
            approximately
            <b>
              {
                props.timeRemaining
              }
            </b>
          </p>
        </div>
        <p className={[styles.textLargeStandard, styles.finalText].join(' ')}>
          Please choose “Stay Signed In” to continue or “Sign Out” if you’re done.
        </p>
        <div className={styles.buttonContainer}>
          <button
            type="button"
            className={[styles.signInButton, 'btn__color-blue'].join(' ')}
            onClick={props.onSignIn}
          >
            Stay signed in
          </button>
          <button
            type="button"
            className={[styles.signOutButton, 'btn__color-grey'].join(' ')}
          >
            <a rel="nofollow" data-method="post" href="/sign_out">Sign out</a>
          </button>
        </div>
      </div>
    </div>
  </Modal>
);

LogoutWarningModal.propTypes = IPropTypes;
LogoutWarningModal.defaultProps = defaultProps;

export { LogoutWarningModal };
