import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShareIndexCollaborator from './commons/components/ShareIndexCollaborator';
import ModalDialogBox from './commons/components/ModalDialogBox';
import AllPracticeShare from './commons/components/AllPracticeShare';

const PhysicianAdminView = (props) => {
  const {
    orderingPhysiciansOfPhysicianAdmin,
    allPracticeShares,
  } = props;
  const numberOfShareableReports = 0;
  const [showModal, setShowModal] = useState(false);
  const showModalWindow = () => {
    setShowModal(!showModal);
  };
  const closeWindow = () => {
    setShowModal(!showModal);
  };
  const orderingPhysiciansView = orderingPhysiciansOfPhysicianAdmin.map((accessedPhysician) => {
    const lastName = accessedPhysician.phy_client.lastname;
    const firstName = accessedPhysician.phy_client.firstname;
    const lastAndFirstName = (firstName && lastName) ? [firstName, lastName].join(', ') : accessedPhysician.phy_client.physician_username;
    return (
      <ShareIndexCollaborator
        key={accessedPhysician.phy_client.id}
        leftColName={`${lastAndFirstName}`}
        leftColEmail={`(${accessedPhysician.phy_client.physician_username})`}
        rightColName={accessedPhysician.share.revoked_at === null ? 'YOU CAN VIEW AND GRANT ACCESS TO' : 'ACCESS REVOKED'}
        sharingTitle="YOU HAVE PHYSICIAN ADMIN ACCESS TO"
        shareDescription={`All ${accessedPhysician.phy_client.patient_ids.length} of their patient reports from ${accessedPhysician.phy_client.practice_name}`}
        numberOfShareableReports={numberOfShareableReports}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="share-index__body">
        <div className="share-index__body__header">
          <div className="share-index__body__header-text">
            <div className="share-index__body__header-title">
              Ordering Physicians
              <i className="fa fa-info-circle shares_info_circle modal-info" onClick={showModalWindow} />
            </div>
          </div>
        </div>
        <div className="share-index__collaborators-container">
          {orderingPhysiciansView}
          <AllPracticeShare
            allPracticeShares={allPracticeShares}
            sharedWithYou
            display="theirs"
          />
        </div>
      </div>
      <ModalDialogBox show={showModal} showModal={closeWindow} ModalContent="See whose reports you have access to" />
    </React.Fragment>
  );
};
export default PhysicianAdminView;
PhysicianAdminView.propTypes = {
  orderingPhysiciansOfPhysicianAdmin: PropTypes.array.isRequired,
  allPracticeShares: PropTypes.array.isRequired,
};
