import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { NewOrderContainer } from './new-order-container';

import { receiveError } from '../../store/actions/errors';
import { fetchStaticData } from '../../store/actions/orders/static-data';
import { addGenericInfo } from '../../store/actions/orders/active-order/generic-info';
import { updateLoadingCount } from '../../store/actions/global';
import { updateModalInfo } from '../../store/actions/orders/active-order';

const mapStateToProps = ({ orders: { dashboard: { ordersAmounts } } }) => ({
  ordersAmounts,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  receiveError,
  fetchStaticData,
  saveGenericInfo: addGenericInfo,
  updateLoadingCount,
  resetBloodCollectionInfo: () => updateModalInfo({ specimenCollectionDate: '', labClinicianText: '' }),
}, dispatch);

const ConnectedNewOrderContainer = connect(mapStateToProps, mapDispatchToProps)(NewOrderContainer);

export { ConnectedNewOrderContainer as NewOrderContainer };
