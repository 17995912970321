import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Controls from '../../admin/common/components/forms/controls';
import ValidationJson from '../validate.json';
import Validation from '../../admin/common/utils/Validation';
import { schema } from '../schema';

const NumberFormatCustom = (props) => {
  const { inputRef, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      format="(###)-###-####"
    />
  );
};
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const PhysicianInfo = (props) => {
  const { newOrder } = props;
  const orderManagement = newOrder && newOrder.toJS();
  const accounts = newOrder.getIn(schema.physician.client) || {};
  const detPhysician = newOrder.getIn(schema.physician.authorizingProvider) || {};
  useEffect(() => {
    if (!orderManagement.user) {
      props.actions.getUserAccountsData(props.viewAsUser?.email);
    }
    if (orderManagement.roOpenFromDashboard
      && !orderManagement.physicians && accounts?.accountNumber !== undefined) {
      props.actions.getAccountNumbers(accounts?.accountNumber, props.viewAsUser?.email);
      props.actions.getPhysiciansNumbers(accounts?.accountNumber, props.viewAsUser?.email);
    }
  }, [orderManagement.user, orderManagement.accounts, orderManagement.roOpenFromDashboard]);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    const { physician } = ValidationJson;
    if (name === 'phone' || name === 'fax') {
      newValue = value.replace(/[\D]/g, '', ' ');
    }
    let currentAccount = null;
    let detPhysicianDetails;
    if (name === 'client') {
      currentAccount = orderManagement.user.find(acc => acc.practiceName === value);
      props.actions.getAccountNumbers(currentAccount.accountNumber, props.viewAsUser?.email);
      props.actions.getPhysiciansNumbers(currentAccount.accountNumber, props.viewAsUser?.email);
      props.actions.onChange({ name: schema.physician.authorizingProvider, value: {} });
    } else if (name === 'authorizingProvider') {
      detPhysicianDetails = orderManagement.physicians
        && orderManagement.physicians[0]?.contactDetails.find(
          x => x.lastName.concat(', ', x.firstName) === value,
        );
      const data = {
        identifiers: [
        ],
        firstName: detPhysicianDetails.firstName,
        lastName: detPhysicianDetails.lastName,
        email: detPhysicianDetails.username,
        phone: detPhysicianDetails.physicianPhone,
      };
      if (detPhysicianDetails.npiNo) {
        data.identifiers.push({
          id: detPhysicianDetails.npiNo,
          idType: 'NPI',
        });
      }
      if (detPhysicianDetails?.contactId) {
        data.identifiers.push({
          id: detPhysicianDetails.contactId.toString(),
          idType: 'RECORD',
        });
      }
      props.actions.onChange({ name: schema.physician[name], value: data });
    } else {
      props.actions.onChange({ name: schema.physician[name], value: newValue });
    }
    if (ValidationJson.physician[name]) {
      if (name === 'client') {
        newValue = currentAccount;
      } if (name === 'authorizingProvider') {
        newValue = detPhysicianDetails;
      }
      let error = Validation(name, newValue, physician) || '';
      error = ValidationJson.physician[name].required && error.trim().length > 0
        ? 'Required'
        : error;
      props.actions.setError({ name: ['phone', 'fax'].includes(name) ? `phy${name}` : name, value: error });
    }
  };
  const { firstName, lastName } = newOrder.getIn(schema.physician.authorizingProvider) || {};

  useEffect(() => {
    if (detPhysician?.email && accounts?.accountNumber) {
      props.actions.getEmbadedSigneeStatus(props.viewAsUser?.email,
        detPhysician?.email, accounts?.accountNumber);
    }
  }, [detPhysician?.email, accounts?.accountNumber]);
  return (
    <Grid container justifyContent="center" alignItems="flex-start" xs={12}>
      <Grid item lg={6} xs={12}>
        <Typography sx={{ my: '30px' }} component="h5" variant="h5">5. Physician Information</Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12}>
                <Controls.Select
                  label="Account"
                  name="client"
                  id="input"
                  onChange={onInputChange}
                  value={newOrder.getIn(schema.physician.client)?.practiceName}
                  options={
                    orderManagement.user?.map(acc => acc.practiceName)
                  }
                  fullWidth
                  error={orderManagement.errors.client}
                  showHelperText="Required"
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Select
                  label="Ordering physician"
                  name="authorizingProvider"
                  id="input"
                  onChange={onInputChange}
                  value={`${lastName || ''}${lastName ? ',' : ''} ${firstName || ''}`}
                  options={
                    (orderManagement.physicians
                      && orderManagement.physicians[0]?.contactDetails?.map(
                        x => x.lastName.concat(', ', x.firstName),
                      ))
                    || []
                  }
                  fullWidth
                  error={orderManagement.errors.authorizingProvider}
                  showHelperText="Required"
                />
              </Grid>
            </Grid>

            {accounts?.practiceName && detPhysician?.firstName && (
              <Grid
                container
                direction="row"
                spacing={2}
                sx={{ backgroundColor: '#FFF', mt: '15px', padding: '15px' }}
              >
                <Grid item lg={12} xs={12}>
                  <Typography component="div" variant="subtitle1" fontWeight="400">Account Information</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" component="div" fontWeight="700">Account ID</Typography>
                  <Typography variant="body2" component="div">{accounts.accountNumber}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" component="div" fontWeight="700">Physician NPI</Typography>
                  <Typography variant="body2" component="div">{detPhysician.identifiers.find(x => x.idType === 'NPI')?.id}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" component="div" fontWeight="700">Account address</Typography>
                  <Typography variant="body2" component="div">{accounts?.address?.address1}</Typography>
                  <Typography variant="body2" component="div">{accounts?.address?.address2}</Typography>
                  <Typography variant="body2" component="div">
                    {accounts?.address?.city}
                    ,
                    {' '}
                    {accounts?.address?.state?.substring(0, 2)}
                    {' '}
                    {accounts?.address?.zipCode}
                  </Typography>
                  <Typography variant="body2" component="div">{accounts?.address?.country}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" component="div" fontWeight="700">Physician Email</Typography>
                  <Typography variant="body2" component="div">
                    {detPhysician.email}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" component="div" fontWeight="700">Account Phone</Typography>
                  <Typography variant="body2" component="div">
                    {accounts?.phone}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" component="div" fontWeight="700">Physician Phone</Typography>
                  <Typography variant="body2" component="div">
                    {detPhysician.phone}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" component="div" fontWeight="700">Account Fax</Typography>
                  <Typography variant="body2" component="div">
                    {accounts.fax}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {accounts?.practiceName && detPhysician?.firstName && (
              <Grid container direction="row" spacing={2}>
                <Grid item md={12} xs={12} sx={{ mt: '10px' }}>
                  <Typography component="h6" variant="h6">Additional Recipient</Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controls.Input
                    label="Full Name"
                    name="firstName"
                    fullWidth
                    onChange={onInputChange}
                    inputProps={{ maxLength: 22 }}
                    value={newOrder.getIn(schema.physician.firstName)}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Controls.Input
                    label="Phone"
                    name="phone"
                    fullWidth
                    format="###-###-####"
                    onChange={onInputChange}
                    error={orderManagement.errors.phyphone}
                    InputLabelProps={{ shrink: newOrder.getIn(schema.physician?.phone) }}
                    value={newOrder.getIn(schema.physician.phone)}
                    InputProps={{
                      maxLength: 15,
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Controls.Input
                    label="Fax"
                    name="fax"
                    fullWidth
                    format="###-###-####"
                    onChange={onInputChange}
                    error={orderManagement.errors.phyfax}
                    value={newOrder.getIn(schema.physician.fax)}
                    InputLabelProps={{ shrink: newOrder.getIn(schema.physician?.fax) }}
                    InputProps={{
                      maxLength: 15,
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PhysicianInfo;

PhysicianInfo.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
};
