import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';

import RequestList from './RequestList';
import { jsAuditAction } from '../util/ajax';
import { setupAuthInterceptor } from '../networking/interceptors/AuthInterceptor';

const IPropTypes = {
  accessKlass: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  tissueStandaloneRefactor: PropTypes.bool.isRequired,
};

class RequestListContainer extends React.Component {
  static handleViewAllClick(e) {
    if (window.$(e.target).hasClass('disabled')) e.preventDefault();
  }

  static noReports() {
    return (
      <div className="requests-list-container">
        <div className="dashboard__tableview">
          <a
            className="dashboard__tableview-link disabled disable_click"
            href="/requests"
            onClick={RequestListContainer.handleViewAllClick}
          >
            <div className="fa fa-table dashboard-table-icon" />
            VIEW ALL REPORTS IN TABLE
          </a>
        </div>
      </div>
    );
  }

  static noReportsToShow() {
    return (
      <div className="request">
        <div className="request__data request__data--no-reports">
          No reports to show
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      affiliations: null,
      affiliationsLimit: 20,
      requestListCurrIdx: 0,
      hasMore: true,
    };
    this.loadAdditionalRequestLists = this.loadAdditionalRequestLists.bind(this);
  }

  componentDidMount() {
    const { csrfToken, tissueGonRefactor } = this.props;
    setupAuthInterceptor(csrfToken);

    const displayMap = window.gon.displayMap;
    this.setState({
      affiliations: displayMap.affiliations,
      requestLimit: (displayMap.requestLimit || 1),
      requestStatus: displayMap.requestStatus,
    });
  }

  handleAffiliationRead(affiliation) {
    return () => {
      this.setState((prevState) => {
        const { affiliations } = prevState;
        const newAffiliations = affiliations.filter(aff => (
          aff.affiliationKey.id !== affiliation.affKeys.id
        ));
        return { affiliations: newAffiliations };
      });
    };
  }

  generateRequestLists() {
    const { accessKlass, tissueStandaloneRefactor } = this.props;
    const { affiliations, requestStatus, requestLimit } = this.state;
    return affiliations.map((affiliation, idx) => {
      const properties = {
        affKeys: affiliation.affiliationKey,
        // requestIds key must change its name after flag is removed and refactor stays permanent.
        requestIds: affiliation.patientRows,
      };

      return (
        <RequestList
          idx={idx}
          key={properties.affKeys.id}
          requestIds={properties.requestIds}
          physician={properties.affKeys}
          requestStatus={requestStatus}
          requestLimit={requestLimit}
          accessKlass={accessKlass}
          onAffiliationRead={this.handleAffiliationRead(properties)}
          tissueStandaloneRefactor={tissueStandaloneRefactor}
        />
      );
    });
  }

  loadAdditionalRequestLists() {
    const { csrfToken } = this.props;
    const {
      requestListCurrIdx,
      affiliations,
      affiliationsLimit,
    } = this.state;

    if (requestListCurrIdx > affiliations.length) return this.setState({ hasMore: false });

    const newCurrIdx = requestListCurrIdx + affiliationsLimit;
    const next = affiliations.slice(requestListCurrIdx, newCurrIdx);
    // TODO: Now that csrfToken is loaded in HttpService headers
    //       by doing setupAuthInterceptor onMount, we can remove it from jsAuditAction.
    //       But every page that calls jsAuditAction should setup the csrf first.
    if (requestListCurrIdx > 0 && window.gon.logLoadMore) jsAuditAction(csrfToken, 'physician_load_more', next);
    return this.setState({ requestListCurrIdx: newCurrIdx });
  }

  requestListContainer() {
    const {
      affiliations,
      hasMore,
    } = this.state;

    if (affiliations.length === 0) {
      return (
        <div className="requests-list-container">
          { RequestListContainer.noReports() }
          { RequestListContainer.noReportsToShow() }
        </div>
      );
    }
    return (
      <div className="requests-list-container">
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadAdditionalRequestLists}
          hasMore={hasMore}
          loader={<div className="loader" key={0} />}
        >
          { this.generateRequestLists() }
        </InfiniteScroll>
      </div>
    );
  }

  render() {
    if (!this.state.affiliations) return null;

    return this.requestListContainer();
  }
}

RequestListContainer.propTypes = IPropTypes;

export default RequestListContainer;
