export const PORTAL_INTERNAL_OKTA_ISSUER = 'internalOktaIssuer';
export const PORTAL_EXTERNAL_OKTA_ISSUER = 'externalOktaIssuer';
export const PORTAL_INTERNAL_OKTA_CLIENT_ID = 'internalOktaClientId';
export const PORTAL_EXTERNAL_OKTA_CLIENT_ID = 'externalOktaClientId';
export const APP_BASE_URL = 'apiBaseUrl';
export const API_GATEWAY_URL = 'apiGatewayUrl';
export const USER_MGMT_V2_PUB_PATH = 'userMgntV2pubPath';
export const USER_MGMT_V2_PATH = 'userMgmtV2path';
export const ORDER_MGMT_V2_PATH = 'orderMgmtV2path';
export const SEARCH_SRVC_V2_PATH = 'searchServicepath';
export const ETRF_MGMT_V2_PATH = 'etrfMgmtV2path';
export const CONFIG_SRVC_V2_PATH = 'configSrvcpath';
export const SFDC_INT_SRVC = 'sfdcIntpath';
export const ORDER_MGMT_V12_PATH = 'orderMgmtV12path';
export const INTERNAL_EMAIL_DOMAINS = 'internalEmailDomains';
