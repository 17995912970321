import { createAction } from 'redux-actions';

export const GET_ACCOUNTS = '@usermanagment-workflow/GET_ACCOUNTS';
export const getAccounts = createAction(GET_ACCOUNTS);

export const GET_ACCOUNT_DONE = '@usermanagment-workflow/G  ET_ACCOUNT_DONE';
export const getAccountsDone = createAction(GET_ACCOUNT_DONE);

export const ACCOUNT_ERROR = '@usermanagment-workflow/ACCOUNT_ERROR';
export const accountsError = createAction(ACCOUNT_ERROR);

export const SAVE_ACCOUNT = '@usermanagment-workflow/SAVE_ACCOUNT';
export const saveAccount = createAction(SAVE_ACCOUNT);

export const SAVE_ACCOUNT_DONE = '@usermanagment-workflow/SAVE_ACCOUNT_DONE';
export const saveAccountDone = createAction(SAVE_ACCOUNT_DONE);

export const SAVE_ACCOUNT_ERROR = '@usermanagment-workflow/SAVE_ACCOUNT_ERROR';
export const saveAccountError = createAction(SAVE_ACCOUNT_ERROR);

export const DELETE_ACCOUNT = '@usermanagment-workflow/DELETE_ACCOUNT';
export const deleteAccount = createAction(DELETE_ACCOUNT);

export const DELETE_ACCOUNT_DONE = '@usermanagment-workflow/DELETE_ACCOUNT_DONE';
export const deleteAccountDone = createAction(DELETE_ACCOUNT_DONE);

export const DELETE_ACCOUNT_ERROR = '@usermanagment-workflow/DELETE_ACCOUNT_ERROR';
export const deleteAccountError = createAction(DELETE_ACCOUNT_ERROR);

export const ORG_PAGINATION_CHANGE = '@usermanagment-workflow/ORG_PAGINATION_CHANGE';
export const changePageAccount = createAction(ORG_PAGINATION_CHANGE);

export const ON_CHANGE_ACCOUNT = '@usermanagment-workflow/ON_CHANGE_ACCOUNT';
export const onChangeAccount = createAction(ON_CHANGE_ACCOUNT);

export const defaultAccount = createAction('');
