import {
  TOGGLE_RELEASE_MODAL,
  TOGGLE_RELEASE_MESSAGE,
  HIDE_FORM_VALIDATION_MESSAGE,
  SHOW_FORM_VALIDATION_MESSAGE,
} from '../../../../actions/patient-page/lunar/release_modal';

const initialState = {
  showReleaseModal: false,
  showReleaseMessage: false,
  showFormValidationMessage: false,
};

const releaseModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_RELEASE_MODAL:
      return {
        ...state,
        showReleaseModal: !state.showReleaseModal,
      };
    case TOGGLE_RELEASE_MESSAGE:
      return {
        ...state,
        showReleaseMessage: !state.showReleaseMessage,
      };
    case HIDE_FORM_VALIDATION_MESSAGE:
      return {
        ...state,
        showFormValidationMessage: false,
      };
    case SHOW_FORM_VALIDATION_MESSAGE:
      return {
        ...state,
        showFormValidationMessage: true,
      };
    default:
      return state;
  }
};

export { releaseModalReducer };
