import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import SiteAssignMeantContainer from './SiteAssignMeantContainer';
import { getUserInfo } from '../helpers';
import Spinner from '../common/utils/spinner/Spinner';

const userPermissions = getUserInfo('Site Assignment');
const SiteAssignMeantRootContainer = () => (
  <Provider store={store}>
    <SiteAssignMeantContainer userPermissions={userPermissions} />
    <Spinner />
  </Provider>
);
export default SiteAssignMeantRootContainer;
