/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Controls from '../common/components/forms/controls';
import { useForm, Form } from '../common/components/forms/useForm';
import MatchGenValidation from './MatchGenValidation';

const initialFValues = {
  matchAlgorithmId: '',
  property: '',
  value: '',
  inclusion: '',
};
const fieldsValidation = {
  property: {
    error: '',
    validate: 'text',
    minLength: 2,
  },

  value: {
    error: '',
    validate: 'alphanumaric',
    minLength: 2,
  },

  inclusion: {
    error: '',
    validate: 'number',
  },
};
const useStyles = makeStyles(theme => createStyles({
  actionButtons: {
    textAlign: 'right',
    margin: theme.spacing(2),
  },
  submitButton: {
    boxShadow: 'none',
    backgroundColor: '#1A5EA7',
    '&:hover': {
      backgroundColor: '#4572A7',
      boxShadow: 'none',
    },
  },
  resetButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  inputText: {
    width: '90%',
  },
}));
const IPropTypes = {
  SearchSpaceFormSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object,
  matchAlgoID: PropTypes.number.isRequired,
  disableAlias: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
};
const defaultProps = {
  formData: null,
};
const MatchGenForm = (props) => {
  const {
    SearchSpaceFormSubmit, formData, matchAlgoID, disableAlias, edit,
  } = props;
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    ['property', 'value', 'inclusion'].forEach((val) => {
      if (val in fieldValues) {
        if ((val === 'inclusion' && fieldValues[val] && fieldValues[val].length > 0) || val !== 'inclusion') {
          const error = MatchGenValidation(val, fieldValues[val], fieldsValidation) || '';
          temp[val] = error;
        } else {
          temp[val] = '';
        }
      }
    });

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
  };

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      SearchSpaceFormSubmit(values, resetForm, edit);
    }
  };
  useEffect(() => {
    if (matchAlgoID) {
      setValues(previous => ({ ...previous, matchAlgorithmId: matchAlgoID }));
    }
  }, [matchAlgoID]);
  useEffect(() => {
    if (formData !== null) {
      setValues({
        ...formData,
      });
    }
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled
            label="Match Algothm ID"
            InputLabelProps={{ required: true }}
            name="matchAlgorithmId"
            value={values.matchAlgorithmId}
            onChange={handleInputChange}
            error={errors.matchAlgorithmId && errors.matchAlgorithmId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled={disableAlias}
            label="Property"
            InputLabelProps={{ required: true }}
            name="property"
            value={values.property}
            onChange={handleInputChange}
            error={errors.property && errors.property}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Value"
            name="value"
            value={values.value}
            onChange={handleInputChange}
            error={errors.value && errors.value}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Inclusion"
            name="inclusion"
            value={values.inclusion}
            onChange={handleInputChange}
            error={errors.inclusion && errors.inclusion}
          />
        </Grid>
        <Grid item xs={12} className={classes.actionButtons}>
          <Controls.Button className={classes.submitButton} type="submit" text="save" />
          <Controls.Button
            text="reset"
            color="default"
            className={classes.resetButton}
            onClick={() => {
              resetForm(formData);
              setValues(previous => ({ ...previous, matchAlgorithmId: matchAlgoID }));
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
export default MatchGenForm;
MatchGenForm.propTypes = IPropTypes;
MatchGenForm.defaultProps = defaultProps;
