import {
  Box, Button, Grid, IconButton, Modal, Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import gdtIcons from '../../../assets/images/icons/gdt-icons.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  overflow: 'auto',
  height: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #f8f8f8',
  p: 4,
  textAlign: 'center',
};
const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '10px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  continueDisabled: {
    backgroundColor: '#b8bec5 !important',
    color: '#fff !important'
  }
}));
function Alert(props) {
  const {
    newOrder,
    actions,
  } = props;
  const orderManagement = newOrder && newOrder.toJS();
  const continueSteps = () => {
    props.actions.onChange({ name: ['showDailog'], value: false });
    props.actions.onTabChange({
      key: ['panal', 'tab'],
      value: Number(orderManagement.panal.tab) + 1,
    });
  };
  const classes = useStyles();
  return (
    <Modal
      open={orderManagement.showDailog}
    >
      <Box sx={style}>
        <CloseIcon
          className={classes.closeButton}
          onClick={() => actions.onChange({ name: ['showDailog'], value: false })}
        />
        <Grid container justify="center" spacing={1}>
          {orderManagement.icdMissMatch
            ? (
              <>
                <Grid item xs={12}>
                  <IconButton
                    size="small"
                    sx={{
                      background: '#d82a34',
                      width: '50px',
                      height: '50px',
                    }}
                  >
                    <svg className="gdt-icon">
                      <use href={`${gdtIcons}#report-problem`} className="icon-error" />
                    </svg>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ my: '20px', color: '#d82a34', margin: 'auto' }} variant="h5"> ICD-10, Cancer Type Mismatch</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: '35px' }}>
                  <Typography sx={{ my: '5px', width: '60%', margin: 'auto' , paddingBottom: '32px' }}>ICD code does not match cancer type. At least one of the ICD-10 codes must match the cancer type in order for you to get the results of your testing. </Typography>
                  <Typography sx={{ my: '5px', width: '60%', margin: 'auto' }}><p>Please reach out at <a href="mailto:clientservices@guardanthealth.com">clientservices@guardanthealth.com</a> if you have any questions about Guardant Health tests.</p> </Typography>
                </Grid>

              </>
            )
            : (
              <>
                <Grid item xs={12}>
                  <IconButton
                    size="small"
                    sx={{
                      background: '#d82a34',
                      width: '50px',
                      height: '50px',
                    }}
                  >
                    <svg className="gdt-icon">
                      <use href={`${gdtIcons}#report-problem`} className="icon-error" />
                    </svg>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ my: '20px', color: '#d82a34', margin: 'auto' }} variant="h5">No Guardant Health tests available for this patient</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: '50px' }}>
                  <Typography sx={{ my: '5px', width: '60%', margin: 'auto' }}>
                    Please contact us at &nbsp;
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>clientservices@guardanthealth.com</a>
                    {' '}
                    or call 855.698.8887 if you have any questions about Guardant Health tests.

                  </Typography>
                </Grid>

              </>
            )
          }

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => actions.onChange({ name: ['showDailog'], value: false })}
            >
              Back to Diagnosis

            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={continueSteps}
              sx={{ width: '170px' }}
              disabled={!orderManagement.icdMissMatch}
              className={!orderManagement.icdMissMatch ? `${classes.continueDisabled}`:''}

            >
              Continue

            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
Alert.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};
export default Alert;
