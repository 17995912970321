import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  filterList: PropTypes.func.isRequired,
};

const defaultProps = {
  users: null,
};

class ManagePermissionsSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchString: '', timer: null };
  }

  triggerChange(e) {
    if (this.state.timer) clearTimeout(this.state.timer);
    this.setState({
      searchString: e.target.value,
      timer: setTimeout(this.handleChange.bind(this), 500),
    });
  }

  handleChange() {
    this.filterUsers(this.state.searchString);
  }

  filterUsers(string) {
    if (this.props.users === null) return;

    let users = [];
    const searchString = string.trim().toLowerCase();

    users = this.props.users.filter((user) => {
      const key = Object.keys(user)[0];

      const stringToMatch = key + user[key].nameOrEmail.toLowerCase();
      return stringToMatch.match(searchString);
    });

    if (string !== '' && users.length === 0) this.props.filterList(undefined);
    if (string !== '' && users.length > 0) this.props.filterList(users);
    if (string === '' && users.length > 0) this.props.filterList(this.props.users);
  }

  render() {
    return (
      <div className="">
        <div className="search-container">
          <div className="search-icon fa fa-search" />
          <input
            className="search-input"
            type="text"
            onChange={this.triggerChange.bind(this)}
            placeholder="Search for PM/SM by email or name"
          />
        </div>
      </div>
    );
  }
}

ManagePermissionsSearch.propTypes = IPropTypes;
ManagePermissionsSearch.defaultProps = defaultProps;

export default ManagePermissionsSearch;
