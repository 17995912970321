import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })).isRequired,
  selectDropdownItem: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
};

const defaultProps = {
  selectDropdownItem: null,
  placeholder: '',
};

class MultiSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.list,
      inputVal: '',
      showDropdown: false,
      formattedSearchValue: null,
      totalInput: '',
      selectedIds: [],
      selectedIdObject: {},
    };
    this.setInputVal = this.setInputVal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.matches = this.matches.bind(this);
    this.checkSelectedItems = this.checkSelectedItems.bind(this);
    this.addSelectedIds = this.addSelectedIds.bind(this);
  }

  setInputVal(e) {
    const { target: { id: eventId } } = e;
    const currentFormattedSearchValue = `${e.target.innerText.split(' - ')[0]}; `;
    this.addSelectedIds(eventId, e.target.innerText.split(' - ')[0]);

    this.setState((prevState) => {
      const totalFormattedSearchValue = prevState.formattedSearchValue
        ? `${prevState.formattedSearchValue}${currentFormattedSearchValue}`
        : currentFormattedSearchValue;

      const index = prevState.totalInput.lastIndexOf('; ');
      const trimmedCurrentValue = prevState.totalInput.substring(0, index !== -1 ? index + 2 : 0);
      const totalInput = trimmedCurrentValue.concat(currentFormattedSearchValue);

      return {
        selectedIds: prevState.selectedIds.slice().concat(eventId),
        inputVal: currentFormattedSearchValue,
        formattedSearchValue: totalFormattedSearchValue,
        totalInput,
        showDropdown: false,
      };
    }, () => {
      if (this.props.selectDropdownItem) {
        const selectedIds = this.checkSelectedItems();
        this.setState({ selectedIds });
        this.props.selectDropdownItem(selectedIds, 'all_ghi_numbers');
      }
    });
  }

  matches() {
    const matches = [];
    if (this.state.inputVal.length === 0) {
      return this.state.list;
    }

    this.state.list.forEach((searchResult) => {
      let text = searchResult.title;
      text = text.replace(/ +/g, ' ').toLowerCase();
      const val = this.state.inputVal.replace(/ +/g, ' ').toLowerCase();
      if (text.indexOf(val) !== -1) {
        matches.push(searchResult);
      }
    });

    return matches;
  }

  addSelectedIds(id, refKey) {
    const currentIds = this.state.selectedIdObject;
    const newIds = { [refKey]: id };
    const updatedCurrent = { ...currentIds, ...newIds };
    this.setState({ selectedIdObject: updatedCurrent });
  }

  checkSelectedItems() {
    const totalInput = this.state.totalInput.split('; ');
    const selectedIds = [];

    for (let i = 0; i < totalInput.length; i += 1) {
      const currentInput = totalInput[i];
      if (currentInput) {
        selectedIds.push(this.state.selectedIdObject[currentInput]);
      }
    }

    return selectedIds;
  }

  handleInputChange(e) {
    const search = this;
    const numInputs = e.target.value.split('; ').length;
    const currentInput = e.target.value.split('; ')[numInputs - 1];
    const currentVal = currentInput.replace(/ +/g, ' ').toLowerCase();

    this.setState({ inputVal: currentVal, totalInput: e.target.value }, () => {
      if (search.state.inputVal.length > 0) {
        return this.setState({ showDropdown: true });
      }
      return this.setState({ showDropdown: false });
    });
  }

  content() {
    const matches = this.matches();

    let results = null;
    if (matches) {
      results = matches.map(result => (
        <div id={result.id} key={result.id} className="dd-list-item" onClick={this.setInputVal}>{`${result.title}`}</div>
      ));
    }

    const dropdown = <React.Fragment>{results}</React.Fragment>;
    const { placeholder, id } = this.props;

    return (
      <div className="physician-admin-search-container" ref={(node) => { this.node = node; }}>
        <div className="search-wrapper physician-admin-search">
          <div className="search-header">
            <input
              type="text"
              name="[project_id]]"
              id={id}
              className="input__ghi-number"
              value={this.state.totalInput}
              onChange={this.handleInputChange}
              required
              placeholder={placeholder}
            />
            <div className="search-i-container">
              <i className="fa fa-search" aria-hidden="true" />
            </div>
          </div>

          {
            this.state.showDropdown

              ? (
                <div className="dd-list search-dropdown">
                  {dropdown}
                </div>
              )
              : null
          }
        </div>
      </div>
    );
  }

  render() {
    const content = this.content();

    return (
      <React.Fragment>{content}</React.Fragment>
    );
  }
}

MultiSearch.propTypes = IPropTypes;
MultiSearch.defaultProps = defaultProps;

export default MultiSearch;
