import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import gdtIcons from '../../../assets/images/icons/gdt-icons.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  overflow: 'auto',
  height: '360px',
  bgcolor: 'background.paper',
  border: '2px solid #f8f8f8',
  p: 4,
  textAlign: 'center',
};
const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '10px',
    fontSize: '20px',
    cursor: 'pointer',
  },
}));

function RchAlert(props) {
  const { newOrder, actions } = props;
  const orderManagement = newOrder && newOrder.toJS();
  const nextTabValue = orderManagement?.nextTabValue;
  const continueSteps = () => {
    props.actions.onChange({ name: ['showClinicalDialog'], value: false });
    if (orderManagement?.isReviewPopUpOpen) {
      props.actions.onChange({ name: ['isReviewOrderOpen'], value: true });
    } else {
      props.actions.onTabChange({
        key: ['panal', 'tab'],
        value:
          nextTabValue === 5
            ? nextTabValue
            : Number(orderManagement.panal.tab) + 1,
      });
    }
  };
  const classes = useStyles();
  return (
    <Modal open={orderManagement?.showClinicalDialog}>
      <Box sx={style}>
        <CloseIcon
          className={classes.closeButton}
          onClick={() => actions.onChange({ name: ['showClinicalDialog'], value: false })}
        />
        <Grid container justify="center" spacing={1}>
          <>
            <Grid item xs={12}>
              <IconButton
                size="small"
                sx={{
                  background: '#d82a34',
                  width: '50px',
                  height: '50px',
                }}
              >
                <svg className="gdt-icon">
                  <use
                    href={`${gdtIcons}#report-problem`}
                    className="icon-error"
                  />
                </svg>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ my: '20px', color: '#d82a34', margin: 'auto' }} variant="h5">Missing Information</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mb: '50px' }}>
              <Typography sx={{ my: '5px', width: '60%', margin: 'auto' }}>
                {'All questions in "Relevant Clinical History" must be answered for insurance coverage determination.'}
              </Typography>
            </Grid>
          </>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => actions.onChange({ name: ['showClinicalDialog'], value: false })}
            >
              Back To Clinical History
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={continueSteps}
              sx={{ width: '170px' }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

RchAlert.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default RchAlert;
