import React from 'react';
import PropTypes from 'prop-types';

import styles from './collection-date-section.module.scss';

const IPropTypes = {
  contentText: PropTypes.string,
  contentDate: PropTypes.string,
};

const defaultProps = {
  contentText: 'Tissue specimen collected on',
  contentDate: '--/--/----',
};

const CollectionDateSection = props => (
  <p className={styles.text}>
    {`${props.contentText} ${props.contentDate}`}
  </p>
);

CollectionDateSection.propTypes = IPropTypes;
CollectionDateSection.defaultProps = defaultProps;

export { CollectionDateSection };
