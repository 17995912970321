import React from 'react';
import PropTypes from 'prop-types';

import styles from './action-bar-container.module.scss';
import { ReleaseModal } from '../release-modal/release-modal';
import { ShareModal } from '../share-modal/share-modal';
import { ShareForm } from '../share-modal/share-form';
import { ReleaseForm } from '../release-modal/release-form';
import { RequestDownloadMenu } from '../../request-download-menu';
import { ActionBar } from '../../../models/action-bar';
import { LoggedUser } from '../../../models/logged-user';

const IPropTypes = {
  data: PropTypes.instanceOf(ActionBar),
  loggedUser: PropTypes.instanceOf(LoggedUser).isRequired,
  viewedAsUser: PropTypes.instanceOf(LoggedUser).isRequired,
  addBannerMessages: PropTypes.func.isRequired,
};

const defaultProps = {
  data: null,
};


class ActionBarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareModal: false,
      showReleaseModal: false,
      showLinksDropdown: false,
    };
  }

  _toggleShareModal = () => {
    this.setState(prevState => ({
      showShareModal: !prevState.showShareModal,
    }));
  }

  _onShareSubmit = (status, message) => {
    this._toggleShareModal();
    this.props.addBannerMessages({ messages: [message], status });
  }

  _toggleReleaseModal = () => {
    this.setState(prevState => ({
      showReleaseModal: !prevState.showReleaseModal,
    }));
  }

  _onReleaseSubmit = (status, message) => {
    this._toggleReleaseModal();
    this.props.addBannerMessages({ messages: [message], status });
  }

  _toggleLinksDropdown = () => {
    this.setState(prevState => ({
      showLinksDropdown: !prevState.showLinksDropdown,
    }));
  }

  render() {
    const { data, loggedUser, viewedAsUser } = this.props;
    const { showShareModal, showReleaseModal, showLinksDropdown } = this.state;
    const { sharePatient, releaseToPatient } = data || {};
    const {
      bloodCollectionDatePerRequest,
      linksPerRequest,
    } = (data && data.downloadReportsLinks) || {};
    return (
      <>
        <div className={styles.actionBar}>
          {
            viewedAsUser && viewedAsUser.canShareReport
            && (
              <div className={styles.buttonWrapper}>
                <button type="button" className={`${styles.actionBarButton}`} onClick={this._toggleShareModal}>
                  <div className="fa fa-share-square-o">&nbsp;</div>
                </button>
                <div className={styles.tooltipText}> Share Report with Colleague </div>
              </div>
            )
          }
          {
            viewedAsUser && viewedAsUser.canReleasePatient
            && (
              <div className={styles.buttonWrapper}>
                <button type="button" className={`${styles.actionBarButton}`} onClick={this._toggleReleaseModal}>
                  <div className="fa fa-paper-plane">&nbsp;</div>
                </button>
                <div className={styles.tooltipText}> Release Report to Patient </div>
              </div>
            )
          }
          {
            linksPerRequest
            && Object.values(linksPerRequest)[0] && Object.values(linksPerRequest)[0].length !== 0
            && loggedUser && loggedUser.canDownloadReport && (
              <div className={styles.buttonWrapper}>
                <button
                  type="button"
                  className={[
                    styles.actionBarButton,
                    loggedUser.canDownloadReport ? '' : styles.disabledDownloadButton,
                    showLinksDropdown ? styles.selectedActionBarButton : '',
                  ].join(' ')}
                  onClick={this._toggleLinksDropdown}
                >
                  <i className={`fa fa-download ${styles.downloadButton}`} />
                </button>
                <div className={styles.tooltipText}> Download Report </div>
              </div>
            )
          }
          {
            showLinksDropdown
            && (
              <RequestDownloadMenu
                menuKlass={styles.downloadMenu}
                accessKlass={styles.downloadMenuLink}
                collectionDate={Object.values(bloodCollectionDatePerRequest)[0]}
                requestId={Object.keys(bloodCollectionDatePerRequest)[0]}
                urls={Object.values(linksPerRequest)[0]}
                canDownloadReport={loggedUser.canDownloadReport}
              />
            )
          }
        </div>
        {
          showShareModal
          && (
            <ShareModal
              onClose={this._toggleShareModal}
              content={(
                <ShareForm
                  patient={sharePatient}
                  authenticityToken={loggedUser.authenticityToken}
                  shareReportEnabled={loggedUser.shareReportEnabled}
                  onSubmit={this._onShareSubmit}
                />
              )}
              patient={sharePatient}
            />
          )
        }
        {
          showReleaseModal
          && (
            <ReleaseModal
              onClose={this._toggleReleaseModal}
              content={(
                <ReleaseForm
                  requestId={releaseToPatient.requestId}
                  patient={releaseToPatient.patient}
                  reportsToRelease={releaseToPatient.reportToRelease}
                  authenticityToken={loggedUser.authenticityToken}
                  releaseReportEnabled={loggedUser.canReleaseReport}
                  onSubmit={this._onReleaseSubmit}
                />
              )}
            />
          )
        }
      </>
    );
  }
}

ActionBarContainer.propTypes = IPropTypes;
ActionBarContainer.defaultProps = defaultProps;

export { ActionBarContainer };
