import React from 'react';
import { Provider } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { store } from '../../store';
import Spinner from '../admin/common/utils/spinner/Spinner';
import NewOrderContainer from './NewOrderContainer';
import { theme } from '../new-orders/theme/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: '1200px',
  },
}));
const SitesRootContainer = (props) => {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NewOrderContainer roles={[]} {...props} className={classes.root} />
      </ThemeProvider>
      <Spinner />
    </Provider>
  );
};
export default SitesRootContainer;
