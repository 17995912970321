import { combineReducers } from 'redux';

import { genericActionTypes } from '../../../actions/orders/active-order/generic-info';
import physicianInfo from './physician_info';
import { patientInfo } from './patient-info';
import diagnosisInfo from './diagnosis_info';
import earlyStageDiagnosisInfo from './early-stage-diagnosis-info';
import testSelectionInfo from './test_selection_info';
import clinicalHistoryInfo from './clinical_history_info';
import earlyStageClinicalHistoryInfo from './early-stage-clinical-history-info';
import { billingInfo } from './billing-info';
import { genericInfo } from './generic-info';
import { printModal } from './print-modal';
import currentTherapyInfo from './current-therapy-info';
import { formErrors } from './form_errors';
import { guardantRevealOrderingInfo } from './guardant-reveal-ordering-info';
import confirmationModal from './confirmation_modal';

const activeOrderReducer = combineReducers({
  physicianInfo,
  patientInfo,
  earlyStageDiagnosisInfo,
  diagnosisInfo,
  testSelectionInfo,
  clinicalHistoryInfo,
  earlyStageClinicalHistoryInfo,
  billingInfo,
  genericInfo,
  printModal,
  currentTherapyInfo,
  formErrors,
  confirmationModal,
  guardantRevealOrderingInfo,
});

const wrappedReducer = (store, action) => activeOrderReducer(
  action.type === genericActionTypes.CLEAR_ACTIVE_ORDER ? undefined : store,
  action,
);

export { wrappedReducer as activeOrderReducer };
