import { restApiConfig } from './restApiConfig';
import { isInternalUser } from '../../../networking/controllers/helper';

const axios = require('axios');

const HttpService = axios.create();
HttpService.defaults.baseURL = restApiConfig.configapiOrdersPath;
const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

function convertToErrorString(payload) {
  let newPayload;
  if (Array.isArray(payload.errors)) {
    newPayload = payload.errors.map(x => `${x.code }-${ x.message}`);
    return { message: newPayload };
  } return payload;
}

HttpService.interceptors.request.use(
  async (config) => {
    HttpService.state = 'sent';
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      ...extHeader,
      Accept: '*',
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept-Language': restApiConfig.lang,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
HttpService.interceptors.response.use(
  response => response,
  err => Promise.reject(convertToErrorString(err?.response?.data || err)),
);

export default HttpService;
