import React from 'react';
import PropTypes from 'prop-types';

import DatepickerInput from '../../generic/DatepickerInput';
import successIcon from '../../../../assets/images/icons/print-checkbox.svg';
import { OnlineOrderingFieldsLimits } from '../../../config/input-character-limit';
import { Button } from '../../generic/button';
import { constants } from '../../../config/constants';

const IPropTypes = {
  alreadyPrinted: PropTypes.bool.isRequired,
  commentsText: PropTypes.string.isRequired,
  footer: PropTypes.object.isRequired,
  informationMessage: PropTypes.string.isRequired,
  informationSubMessage: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  summary: PropTypes.array,
  printOrderText: PropTypes.string.isRequired,
  finalizeOrderText: PropTypes.string.isRequired,
  onClickPrintOrder: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  warningStatus: PropTypes.bool,
  updateModalInfo: PropTypes.func.isRequired,
  labClinicianText: PropTypes.string.isRequired,
  specimenCollectionDate: PropTypes.string.isRequired,
  canChangeOrders: PropTypes.bool.isRequired,
  logoSrc: PropTypes.string,
  canOnlyDemoOrders: PropTypes.bool.isRequired,
  eSignatureEnabled: PropTypes.bool.isRequired,
  onClickFinalizeOrder: PropTypes.func.isRequired,
  hideFinalizeButton: PropTypes.bool,
  disableFinalizeButton: PropTypes.bool,
  disablePrintButton: PropTypes.bool,
  showNoPhysicianEmailDisclaimer: PropTypes.bool,
};

const defaultProps = {
  summary: [],
  warningStatus: true,
  logoSrc: null,
  hideFinalizeButton: false,
  disableFinalizeButton: false,
  disablePrintButton: false,
  showNoPhysicianEmailDisclaimer: false,
};

const {
  modalTextContentWithElectronicSignature,
  modalTextContentWithoutElectronicSignature,
} = constants.printModal;

const PrintModal = ({
  modalTitle,
  showModal,
  toggleModal,
  warningStatus,
  informationMessage,
  informationSubMessage,
  summary,
  alreadyPrinted,
  printOrderText,
  finalizeOrderText,
  footer,
  onClickPrintOrder,
  commentsText,
  updateModalInfo,
  labClinicianText,
  specimenCollectionDate,
  canChangeOrders,
  canOnlyDemoOrders,
  logoSrc,
  eSignatureEnabled,
  onClickFinalizeOrder,
  hideFinalizeButton,
  disableFinalizeButton,
  disablePrintButton,
  showNoPhysicianEmailDisclaimer,
}) => {
  const onClickCloseBack = () => {
    toggleModal();
  };

  const modalTextContent = eSignatureEnabled
    ? modalTextContentWithElectronicSignature
    : modalTextContentWithoutElectronicSignature;

  return (
    showModal && (
      <div className="modal__container-outer sign_up__site-manager billing-overlay-modal">
        <div className="modal__container-inner">
          <div className="modal__content">
            <div className="sign_up__site-manager billing-overlay">
              <div className="sign_up__site-manager__container">
                <div className="billing-overlay-header">
                  <img className="guardantLogo" alt="Guardant Logo" src={logoSrc} />
                  <div className="heading vertical-align">{modalTitle}</div>
                  <div>
                    <button className="fa fa-close modal-close" onClick={onClickCloseBack} type="button" />
                  </div>
                </div>
                <div className="content billing-overlay-content">
                  <div className="billing-warning-container">
                    <div className="billing-warning-container__icon-container vertical-align">
                      {!warningStatus ? (
                        <img
                          alt=""
                          className="billing-warning-container__icon-success "
                          src={successIcon}
                        />
                      ) : (
                        <i className="billing-warning-container__icon fa fa-exclamation-triangle warning-message icon" />
                      )}
                    </div>
                    <div>
                      <div className={warningStatus ? 'warning-message' : ''}>
                        {informationMessage}
                      </div>
                      <div className="billing-warning-container__message">
                        {informationSubMessage}
                      </div>
                    </div>
                  </div>
                  <ul className="billing-patient-summary">
                    {
                      summary.map(item => (
                        <li key={item.title}>
                          <div className="body-bold inactive">{item.title}</div>
                          <div
                            className={
                                  item.isCompleted
                                    ? 'body-text-emphasis'
                                    : 'warning-message'
                                }
                          >
                            {item.info}
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                  <div
                    className={[
                      'print-form-container',
                      !alreadyPrinted ? 'print-form-selected-container' : '',
                    ].join(' ')}
                  >
                    <p
                      className={[
                        'print-form-container__title',
                        alreadyPrinted ? 'inactive' : '',
                      ].join(' ')}
                    >
                      Specimen Information
                    </p>
                    <div className="print-form-container__subcontainer">
                      <div className="col-1">
                        <p
                          className={[
                            alreadyPrinted ? 'inactive' : '',
                            'body-text-emphasis',
                          ].join(' ')}
                        >
                          COLLECTION DATE
                        </p>
                        <DatepickerInput
                          disabled={alreadyPrinted}
                          className="print-form-container__datepicker"
                          name="specimenCollectionDate"
                          id="print-specimen-collection-date"
                          onChange={
                            value => updateModalInfo({ specimenCollectionDate: value }, true)
                          }
                          value={specimenCollectionDate}
                          placeholder="MM/DD/YYYY"
                        />
                      </div>
                      <div className="col-2">
                        <p
                          className={[
                            alreadyPrinted ? 'inactive' : '',
                            'body-text-emphasis',
                          ].join(' ')}
                        >
                          LAB CLINICIAN
                        </p>
                        <div>
                          <input
                            type="text"
                            disabled={alreadyPrinted}
                            placeholder="Last, First Name"
                            onChange={
                              event => updateModalInfo(
                                { labClinicianText: event.target.value }, true,
                              )
                            }
                            value={labClinicianText}
                            maxLength={OnlineOrderingFieldsLimits.genericInputMaxLength}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {alreadyPrinted && (
                    <div className="print-form-container">
                      <p className="print-form-container__title inactive">General Comments</p>
                      <textarea
                        className="print-form-container__textarea"
                        value={commentsText}
                        disabled
                      />
                    </div>
                  )}
                  <div>
                    <div className="heading vertical-align">{modalTextContent.reminders.title}</div>
                    <div className="billing-overlay-footer">
                      <div className="billing-overlay-footer__row">
                        <div className="billing-overlay-footer__row-left body-bold inactive">
                          {modalTextContent.reminders.signature.title}
                        </div>
                        <div className="billing-overlay-footer__row-right body-bold-highlighted">
                          {modalTextContent.reminders.signature.content}
                        </div>
                      </div>
                      <div className="billing-overlay-footer__row">
                        <div className="billing-overlay-footer__row-left body-bold inactive">
                          {modalTextContent.reminders.documents.title}
                        </div>
                        <div className="billing-overlay-footer__row-right body-bold-highlighted">
                          {modalTextContent.reminders.documents.preamble}
                          <ul className="billing-overlay-footer__list">
                            {
                              modalTextContent.reminders.documents.content.map(
                                e => <li key={e}>{e}</li>,
                              )
                            }
                          </ul>
                        </div>
                      </div>
                      <p
                        className={[
                          'billing-overlay-footer__message body-bold-highlighted',
                          footer.isAWarning ? 'warning-message' : '',
                        ].join(' ')}
                      >
                        {footer.message}
                      </p>
                      {
                        showNoPhysicianEmailDisclaimer && (
                          <p className="billing-overlay-footer__message body-bold-highlighted warning-message">
                            <strong>Digital signature option not available</strong>
                            , no email on file for ordering physician.
                            {' '}
                            To sign future orders digitally, contact
                            {' '}
                            clientservices@guardanthealth.com for further assistance.
                          </p>
                        )
                      }
                    </div>
                  </div>

                  <div className="billing-overlay-action-section">
                    <Button type="secondary" onClick={onClickCloseBack}>
                      <i className="fa fa-chevron-left" />
                      Back
                    </Button>
                    {
                      eSignatureEnabled && !hideFinalizeButton && (
                        <Button
                          onClick={onClickFinalizeOrder}
                          disabled={disableFinalizeButton
                            || canOnlyDemoOrders
                            || !canChangeOrders}
                        >
                          {finalizeOrderText}
                        </Button>
                      )
                    }
                    <Button
                      onClick={onClickPrintOrder}
                      disabled={disablePrintButton
                        || (!alreadyPrinted && !canChangeOrders)
                        || canOnlyDemoOrders}
                    >
                      {printOrderText}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
};


PrintModal.propTypes = IPropTypes;
PrintModal.defaultProps = defaultProps;

export { PrintModal };
