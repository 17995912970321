import React from 'react';
import PropTypes from 'prop-types';

import styles from './reference-item.module.scss';

const IPropTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
};

const ReferenceItem = ({ data }) => (
  <div className={styles.container}>
    <button
      className={styles.fileIconRefContainer}
      type="button"
      onClick={() => { window.location = data.path; }}
    >
      <div className={['fa', 'fa-file-text', `${styles.menuIcon}`].join(' ')} />
      <div className={styles.name}>{data.name}</div>
    </button>
  </div>
);

ReferenceItem.propTypes = IPropTypes;

export { ReferenceItem };
