import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { PrimaryCode } from '../primary-code';
import { SecondaryCodeContainer } from '../secondary-code-container';
import { FormatHelper } from '../../helpers/format_helper';
import { DateInput } from '../../../generic/DateInput';
import { CancerTypeSection } from '../cancer-type-section';

import baseStyles from '../../../../assets/stylesheets/etrf/base.module.scss';
import styles from './diagnosis-condition-late-stage-expanded.module.scss';
import { Checkbox } from '../../../generic/checkbox/checkbox';

const IIcdCode = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

const IPropTypes = {
  saveDiagnosisInfo: PropTypes.func.isRequired,
  diagnosisStore: PropTypes.shape({
    disease: PropTypes.string,
    otherDisease: PropTypes.string,
    diagnosisOtherDisease: PropTypes.string,
    diagnosisOriginalDiagnosis: PropTypes.string,
    diagnosisStage: PropTypes.string,
    diagnosisPrimaryCode: IIcdCode,
    diagnosisSecondaryCode: IIcdCode,
    diagnosisTertiaryCode: IIcdCode,
    diagnosisQuarternaryCode: IIcdCode,
    diagnosisQuinaryCode: IIcdCode,
    diagnosisSenaryCode: IIcdCode,
  }).isRequired,
  highlightRequiredFields: PropTypes.bool,
  stageText: PropTypes.string.isRequired,
};

const defaultProps = {
  highlightRequiredFields: false,
};

const DiagnosisConditionLateStageExpanded = ({
  diagnosisStore,
  diagnosisStore: {
    diagnosisOriginalDiagnosis, diagnosisStage,
    disease,
    otherDisease,
    diagnosisPrimaryCode, diagnosisSecondaryCode, diagnosisTertiaryCode,
    diagnosisQuarternaryCode, diagnosisQuinaryCode, diagnosisSenaryCode,
  },
  highlightRequiredFields,
  saveDiagnosisInfo, stageText,
}) => {
  const storeSelectedItem = id => (info) => {
    saveDiagnosisInfo({ [id]: info }, true);
  };

  const updateDiagnosisStage = useCallback(({ target: { checked } }) => {
    saveDiagnosisInfo({ diagnosisStage: checked ? stageText : null }, true);
  }, [stageText]);

  const checkForHighlightingField = (fieldName) => {
    if (fieldName === 'disease') {
      return highlightRequiredFields && !disease && !otherDisease;
    }
    if (fieldName === 'diagnosisPrimaryCode') {
      return highlightRequiredFields && !diagnosisPrimaryCode.code;
    }
    return highlightRequiredFields && !diagnosisStore[fieldName];
  };

  const codes = {
    selectedSecondaryCode: diagnosisSecondaryCode,
    selectedTertiaryCode: diagnosisTertiaryCode,
    selectedQuarternaryCode: diagnosisQuarternaryCode,
    selectedQuinaryCode: diagnosisQuinaryCode,
    selectedSenaryCode: diagnosisSenaryCode,
  };

  return (
    <div className={baseStyles.accordionSection}>
      <div className={baseStyles.sectionRow}>
        <span className={baseStyles.requiredFieldsText}>
          * Required fields
        </span>
      </div>
      <div className={baseStyles.sectionRow}>
        <div className={`${baseStyles.sectionColumn} ${styles.primaryCodeColumn}`}>
          <p className={`${baseStyles.fieldLabel} ${checkForHighlightingField('diagnosisPrimaryCode') ? baseStyles.highlighted : ''}`}>
            Primary Code
            <span className={baseStyles.highlightedAsterix}>*</span>
          </p>
          <PrimaryCode
            primaryCode={diagnosisPrimaryCode}
            storeSelectedItem={useCallback(storeSelectedItem('diagnosisPrimaryCode'), [])}
            highlightedBorder={checkForHighlightingField('diagnosisPrimaryCode')}
          />
        </div>
        <div className={baseStyles.sectionColumn}>
          <p className={baseStyles.fieldLabel}>
            Secondary Codes
          </p>
          <SecondaryCodeContainer
            codes={codes}
            storeSelectedItem={storeSelectedItem}
          />
        </div>
      </div>
      <div className={baseStyles.sectionRow}>
        <div className={baseStyles.sectionColumn}>
          <p className={`${baseStyles.fieldLabel} ${checkForHighlightingField('diagnosisStage') ? baseStyles.highlighted : ''}`}>
            Stage
            <span className={baseStyles.highlightedAsterix}>*</span>
          </p>
          <div className={styles.stageCheckbox}>
            <Checkbox
              id="diagnosis-stage-checkbox"
              checked={!!diagnosisStage}
              onChange={updateDiagnosisStage}
              highlight={checkForHighlightingField('diagnosisStage')}
            />
            <label className={styles.stageLabel} htmlFor="diagnosis-stage-checkbox">
              Advanced Cancer
              <br />
              (
              {stageText}
              )
            </label>
          </div>
        </div>
        <div className={baseStyles.sectionColumn}>
          <p className={baseStyles.fieldLabel}>
            Original Diagnosis
          </p>
          <DateInput
            className={styles.originalDiagnosis}
            value={diagnosisOriginalDiagnosis || ''}
            maxDate={FormatHelper.getTodaysDate()}
            onInputChange={useCallback(storeSelectedItem('diagnosisOriginalDiagnosis'), [])}
          />
        </div>
      </div>
      <CancerTypeSection />
    </div>
  );
};

DiagnosisConditionLateStageExpanded.propTypes = IPropTypes;
DiagnosisConditionLateStageExpanded.defaultProps = defaultProps;

export { DiagnosisConditionLateStageExpanded };
