import React from 'react';
import PropTypes from 'prop-types';

import styles from './alert.module.scss';

const IPropTypes = {
  type: PropTypes.oneOf(['info', 'warning']),
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  displayCloseIcon: PropTypes.bool,
};

const BACKGROUND_COLOR_TYPES = {
  warning: styles.backgroundWarning,
  info: styles.backgroundInfo,
};

const COLOR_TYPES = {
  warning: styles.colorWarning,
  info: styles.colorInfo,
};

const defaultProps = {
  type: 'info',
  onClose: () => {},
  displayCloseIcon: false,
};


const Alert = props => (
  <div className={`${styles.globalAlert} ${styles.alertBox}`}>
    <div className={`${styles.globalAlertColorBar} ${BACKGROUND_COLOR_TYPES[props.type]}`} />
    <div className={`${styles.content} ${styles.container} ${styles.contentInner}`}>
      <i className={`fa fa-info-circle ${styles.alertInfoCircleContainer} ${COLOR_TYPES[props.type]}`} />
      <div className={styles.alertTextContainer}>
        <div className={styles.childrenContent}>
          { props.children }
        </div>
        {
          props.displayCloseIcon && (
            <button type="button" className={styles.closeAlert} onClick={props.onClose}>
              <i className={`fa fa-close ${styles.closeIcon}`} />
            </button>
          )
        }
      </div>
    </div>
  </div>
);

Alert.propTypes = IPropTypes;
Alert.defaultProps = defaultProps;

export { Alert };
