import {
  SHOW_ERROR_BANNER, HIDE_ERROR_BANNER, SHOW_MISSING_REQUIRED_FIELDS,
  HIDE_MISSING_REQUIRED_FIELDS, SAVE_ERROR_BANNER_MSG,
} from '../../../actions/orders/active-order/form_errors';

const initialState = {
  showErrorBanner: false,
  errorBannerMsg: null,
  showMissingRequiredFields: false,
};

const formErrors = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR_BANNER:
      return {
        ...state,
        showErrorBanner: true,
      };
    case SAVE_ERROR_BANNER_MSG:
      return {
        ...state,
        showErrorBanner: true,
        errorBannerMsg: action.info,
      };
    case HIDE_ERROR_BANNER:
      return {
        ...state,
        showErrorBanner: false,
        errorBannerMsg: null,
      };
    case SHOW_MISSING_REQUIRED_FIELDS:
      return {
        ...state,
        showMissingRequiredFields: true,
      };
    case HIDE_MISSING_REQUIRED_FIELDS:
      return {
        ...state,
        showMissingRequiredFields: false,
      };
    default:
      return state;
  }
};

const wrappedFormErrorsReducer = (state, action) => {
  let newState = state;
  if (action.hideBanner) {
    newState = {
      ...state,
      showErrorBanner: false,
      errorBannerMsg: null,
      showMissingRequiredFields: false,
    };
  }

  return formErrors(newState, action);
};

export { wrappedFormErrorsReducer as formErrors };
