import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { constants } from '../../../../config/constants';
import { AuditLogController } from '../../../../networking/controllers/audit-log';

import styles from './test-begin-button.module.scss';

const IPropTypes = {
  productFamily: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const TestBeginButton = ({ disabled, productFamily }) => {
  const beginButtonClassNames = [styles.beginButton, disabled ? styles.disabled : ''].join(' ');
  const beginButtonText = 'Begin Order';

  const onClick = useCallback((e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      AuditLogController.auditLog(constants.auditLogActions.newTestRequisition[productFamily]);
    }
  }, [disabled, productFamily]);

  return (
    <Link className={beginButtonClassNames} to={`/orders/new/${productFamily}`} onClick={onClick} disabled={disabled}>
      {beginButtonText}
      <i className={`fa fa-angle-right ${styles.angleRight}`} />
    </Link>
  );
};

TestBeginButton.propTypes = IPropTypes;
TestBeginButton.defaultProps = defaultProps;

export { TestBeginButton };
