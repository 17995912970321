/* eslint-disable no-undef, no-alert */
import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  show: PropTypes.bool.isRequired,
  closeShareModal: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  patientName: PropTypes.string,
  number: PropTypes.number,
  authenticityToken: PropTypes.string.isRequired,
};
const defaultProps = {
  patientId: '',
  patientName: '',
  number: null,
};
const ShareModel = (props) => {
  const {
    show,
    closeShareModal,
    patientId,
    patientName,
    number,
    authenticityToken,
  } = props;

  const confirmShare = (event) => {
    if (patientName) {
      if (!window.confirm(`Are you sure you want to share ${patientName}'s current and historical reports with this recipient?`)) {
        event.preventDefault();
      }
    } else if (!window.confirm('Are you sure you want to grant this person access to all your reports?')) {
      event.preventDefault();
    }
  };
  // eslint-disable-next-line consistent-return
  const validateForm = () => {
    if (!$('.shares__form-container')[0].checkValidity()) {
      return false;
    }
    confirmShare();
  };
  const header = patientId ? `Share ${patientName}'s report` : 'Grant access to all reports';
  const description = patientId ? `Invite an additional recipient to view ${patientName}'s patient report.` : `Grant access to all ${number} reports you ordered, as well as any new reports you order in the future.`;
  return (
    <React.Fragment>
      {show
        && (
        <div className="modal__container-outer shares__modal" style={{ display: 'block' }}>
          <div className="modal__container-inner shares__modal">
            <div className="modal__content">
              <div className="shares__modal__content">
                <i className="fa fa-close modal-close" onClick={closeShareModal} />
                <div className="header">{header}</div>
                <div className="content">
                  <p className="description">
                    {description}
                  </p>
                  <form className="shares__form-container" action="/shares" acceptCharset="UTF-8" method="post">
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={authenticityToken}
                    />
                    <div className="input-group shares__input-container">
                      <input type="hidden" name="patient_id" id="patient_id" value={patientId} />
                      <input type="hidden" name="patient_name" id="patient_name" value={patientName} />
                      <input type="hidden" name="share_modal" id="share_modal" value="true" />
                      <div className="grey-box__form__item-container">
                        <div className="grey-box__form__item">
                          <div className="grey-box__form__item-inner grey-box__form__item-inner-left">
                            <label
                              className="grey-box__form__item-label share__first__name"
                              htmlFor="sharees_sharee_1__first_name_"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              name="sharees[sharee_1[first_name]]"
                              id="sharees_sharee_1_first_name"
                              className="grey-box__form__item grey-box__form__item-name"
                              placeholder="e.g. Joe"
                              required="required"
                            />
                          </div>
                          <div className="grey-box__form__item-inner grey-box__form__item-inner-right">
                            <label
                              className="grey-box__form__item-label share__last__name"
                              htmlFor="sharees_sharee_1_:last_name"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="sharees[sharee_1[last_name]]"
                              id="sharees_sharee_1_last_name"
                              className="grey-box__form__item grey-box__form__item-name"
                              placeholder="e.g. Smith"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="grey-box__form__item">
                          <label
                            className="grey-box__form__item-label share__email__address"
                            htmlFor="sharees_sharee_1_:email"
                          >
                            Email Address
                          </label>
                          <input
                            type="text"
                            name="sharees[sharee_1[email]]"
                            id="sharees_sharee_1_email"
                            className="grey-box__form__item grey-box__form__item-email"
                            placeholder={patientId ? 'e.g physician@example.com' : 'e.g. practicemanager@example.com'}
                            required="required"
                            autoCorrect="off"
                            autoCapitalize="none"
                          />
                          <input
                            type="hidden"
                            name="sharees[sharee_1[notification]]"
                            id="sharees_sharee_1_notification"
                            value="false"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-group shares__terms">
                      {
                        patientId ? (
                          <label className="label-terms" htmlFor="accept_terms">
                            <input
                              type="checkbox"
                              name="accept_terms"
                              id="accept_terms"
                              value="1"
                              required="required"
                            />
                            I confirm the individual(s) to whom I am sending this invitation
                            is a health professional or workforce member at my organization
                            authorized to access and use health information in accordance
                            with my organization policies, HIPAA, federal, state, and
                            local requirements, and the service
                            <a target="_blank" href="/terms_of_use"> Terms and Conditions of Use </a>
                            . Your invitee will be required to set up a Provider Portal
                            account and to read and accept the Terms and Conditions of
                            Use before access to the Provider Portal will be provided
                            to him/her.
                          </label>
                        ) : (
                          <label className="label-terms" htmlFor="accept_terms">
                            <input
                              type="checkbox"
                              name="accept_terms"
                              id="accept_terms"
                              value="1"
                              required="required"
                            />
                            I confirm the individual(s) to whom I am sending this invitation is
                            a health professional or workforce member at my organization
                            authorized to access, use, and release health information
                            in accordance with my
                            organization policies, HIPAA, federal, state, and local requirements,
                            and the service
                            <a target="_blank" href="/terms_of_use"> Terms and Conditions of Use </a>
                            . Your invitee will be required to set up
                            a Provider Portal account and
                            to read and accept the Terms and Conditions of Use
                            before access to the Provider Portal will be provided to him/her.
                          </label>
                        )
                      }

                    </div>
                    <div className="input-group shares__buttons" />
                    <button type="submit" className="btn__size-dynamic btn__color-blue share__button" onClick={() => validateForm()}>
                      Send Invitation
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }
    </React.Fragment>
  );
};
export default ShareModel;
ShareModel.propTypes = IPropTypes;
ShareModel.defaultProps = defaultProps;
