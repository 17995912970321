import { constants } from '../../../config/constants';

const { productFamilies } = constants.etrfOrdering;

const deserializeProductFamily = ({ logo, configuration }) => ({
  logoSrc: logo,
  formConfiguration: {
    hasCurrentTherapySection: configuration.hasCurrentTherapySection,
    hasEarlyStageClinicalHistorySection: configuration.hasEarlyStageClinicalHistorySection,
    hasLateStageClinicalHistorySection: configuration.hasLateStageClinicalHistorySection,
    hasTestSelectionSection: configuration.hasTestSelectionSection,
    hasGuardantRevealOrderingSection: configuration.hasGuardantRevealOrderingSection,
    hasEarlyStageDiagnosisSection: configuration.hasEarlyStageDiagnosisSection,
    hasLateStageDiagnosisSection: configuration.hasLateStageDiagnosisSection,
    hasPatientDemoFaceSheetFields: configuration.hasPatientDemoFaceSheet,
    stageMapping: configuration.stageMapping,
  },
});

class ConfigurationSerializer {
  static deserialize = ({ productFamilies: { g360, lunar } }) => ({
    [productFamilies.g360]: deserializeProductFamily(g360),
    [productFamilies.reveal]: deserializeProductFamily(lunar),
  });
}

export { ConfigurationSerializer };
