import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const EnvVars = ({ loadKeysFromEnv }) => {
  useEffect(() => {
    window.sessionStorage.setItem('envValues', JSON.stringify(loadKeysFromEnv));
  }, []);
  return <div />;
};
export default EnvVars;
EnvVars.propTypes = {
  loadKeysFromEnv: PropTypes.object.isRequired,
};

export const config = () => JSON.parse(sessionStorage.getItem('envValues'));
