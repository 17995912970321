import { physicianActionTypes } from '../../../actions/orders/active-order/physician_info';
import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';

const physicianInitialState = {
  client: {
    client: null,
    clientAccount: null,
    clientPhone: null,
    clientFax: null,
    clientCountry: null,
    clientState: null,
    clientCity: null,
    clientZipCode: null,
    clientAddress1: null,
    clientAddress2: null,
  },
  physician: {
    id: null,
    lastname: null,
    firstname: null,
    fullname: null,
    email: null,
    phone: null,
    npi: null,
    orderingPhysician: null,
  },
  secondaryRecipient: {
    secondaryRecipientFirstname: null,
    secondaryRecipientLastname: null,
    secondaryRecipientPhone: null,
    secondaryRecipientFax: null,
  },
};

const physicianInfo = (state = physicianInitialState, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return { ...state, ...action.order.physicianInfo };
    case physicianActionTypes.ADD_PHYSICIAN_DATA:
      return { ...state, ...action.info };
    default:
      return state;
  }
};

export default physicianInfo;
