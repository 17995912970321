import { connect } from 'react-redux';

import { GeneralMessage } from './general-message';

const mapStateToProps = store => ({
  generalMessage: store.patientPage.multiProduct.info.generalMessage,
});

const ConnectedGeneralMessage = connect(mapStateToProps)(GeneralMessage);

export { ConnectedGeneralMessage as GeneralMessage };
