import React from 'react';
import {
  Route, Switch, BrowserRouter,
} from 'react-router-dom';

export const Accounts = () => <div>Accounts</div>;
export const Roles = () => <div>Roles</div>;
export const Sites = () => <div>Sites</div>;
export const Users = () => <div>Users</div>;
export const RoleAssigment = () => <div>RoleAssigment</div>;

const Administration = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/accounts" component={Accounts} />
      <Route exact path="/roles" component={Roles} />
      <Route exact path="/sites" component={Sites} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/roleassignment" component={RoleAssigment} />
    </Switch>
  </BrowserRouter>
);

export default Administration;
