import Cookies from 'js-cookie';
import { restApiConfig } from './restApiConfig';
import { isInternalUser } from '../../../networking/controllers/helper';

const axios = require('axios');

const HttpService = axios.create();
const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

HttpService.defaults.baseURL = 'http://a8c69125a37044e55b5977582643e58c-1441906022.us-west-2.elb.amazonaws.com/v2/match-gen-gql';
HttpService.interceptors.request.use(
  async (config) => {
    HttpService.state = 'sent';
    const accessToken = Cookies.get('access_token');
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      ...extHeader,
      Accept: '*',
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept-Language': restApiConfig.lang,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
HttpService.interceptors.response.use(
  response => response,
  // eslint-disable-next-line no-use-before-define
  err => Promise.reject(convertToErrorString(err.response.data || err)),
);

export default HttpService;

function convertToErrorString(payload) {
  if (Array.isArray(payload.errors)) {
    // eslint-disable-next-line no-param-reassign
    payload = payload.errors.map(x => `${x.code}-${x.message}`);
    return { message: payload };
  } return payload;
}
