import React from 'react';
import PropTypes from 'prop-types';

import BasicInput from './BasicInput';

const IPropTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  defaultDate: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
  onChange: () => {},
  defaultDate: null,
  minDate: null,
  maxDate: null,
  disabled: false,
};

const PICKER_OPTIONS = {
  dateFormat: 'mm/dd/yy',
};

class DatepickerInput extends React.Component {
  constructor(props) {
    super(props);
    this.picker = null;
    PICKER_OPTIONS.minDate = props.minDate;
    PICKER_OPTIONS.maxDate = props.maxDate;
    this._updateRef = this._updateRef.bind(this);
  }

  _updateRef(element) {
    if (element) {
      this.picker = window.$(element);
      this.picker.datepicker({
        ...PICKER_OPTIONS,
        onSelect: this.props.onChange,
      });

      this.picker.datepicker('setDate', this.props.value);
    } else {
      this.picker = null;
    }
  }

  render() {
    const propsToPass = { ...this.props };
    delete propsToPass.defaultDate;
    delete propsToPass.className;
    delete propsToPass.maxDate;
    delete propsToPass.minDate;
    return (
      <div className={[this.props.className, 'datepicker__container'].join(' ')}>
        <label
          className="datepicker__label"
          htmlFor={this.props.id}
        >
          <i className={['fa fa-calendar datepicker__calendar-icon-container', this.props.disabled ? 'inactive' : ''].join(' ')} />
          <BasicInput
            {...propsToPass}
            id={this.props.id}
            className="datepicker__input"
            inputRef={this._updateRef}
            readOnly
          />
        </label>
      </div>
    );
  }
}

DatepickerInput.propTypes = IPropTypes;
DatepickerInput.defaultProps = defaultProps;

export default DatepickerInput;
