/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import PopDropdown from '../../forms/PopDropdown';

const IPropTypes = {
  upperCase: PropTypes.bool.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
};
function Cell(props) {
  const { upperCase, item, handleMenuClick } = props;
  function createCell() {
    switch (typeof item) {
      case 'object':
        if (item.key) return item;
        return <PopDropdown items={item} handleMenuClick={handleMenuClick} />;

      default:
        return upperCase ? item.toUpperCase() : item;
    }
  }
  return <TableCell className="table-cell">{createCell()}</TableCell>;
}

export default memo(Cell);
Cell.propTypes = IPropTypes;
