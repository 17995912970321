import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './bubbles.module.scss';

const IPropTypes = {
  statuses: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
    count: PropTypes.number,
    displayName: PropTypes.string,
  })).isRequired,
  selected: PropTypes.string,
};

const defaultProps = {
  selected: '',
};

const Bubbles = ({ statuses, selected }) => (
  <div className={styles.container}>
    {
      statuses.map(({ status, count, displayName }) => (
        <div key={status} className={[styles.tile, selected === status ? styles.active : ''].join(' ')}>
          <Link
            className={[styles.bubble, count > 9999 ? styles.largeNumber : ''].join(' ')}
            to={`orders?status=${status}`}
          >
            {count}
          </Link>
          <span className={styles.label}>
            {displayName}
          </span>
        </div>
      ))
    }
  </div>
);

Bubbles.propTypes = IPropTypes;
Bubbles.defaultProps = defaultProps;

export { Bubbles };
