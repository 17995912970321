import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../Dropdown';
import { PrivateInsurance } from '../private-insurance';
import { constants } from '../../../../config/constants';
import { OnlineOrderingFieldsLimits } from '../../../../config/input-character-limit';

const IPropTypes = {
  billingInfo: PropTypes.object.isRequired,
  saveBillingInfo: PropTypes.func.isRequired,
  saveSelfInsuredBillingInfo: PropTypes.func.isRequired,
  withInsuranceCopyCheckbox: PropTypes.bool,
};

const defaultProps = {
  withInsuranceCopyCheckbox: false,
};

const BillingInformationExpanded = ({
  billingInfo: {
    cardIncluded,
    type,
    insuredRelationship,
    subscriberFirstName,
    subscriberLastName,
  },
  billingInfo,
  withInsuranceCopyCheckbox,
  saveBillingInfo,
  saveSelfInsuredBillingInfo,
}) => {
  const { billingInformation: { billingTypes, insuredTypes, selfInsuredValue } } = constants;

  const updateBillingType = useCallback(
    (_key, info) => {
      const billingType = billingTypes.find(e => e.title === info) || {};
      if (billingType.value !== type) {
        saveBillingInfo({ type: billingType.value }, true);
      }
    },
    [type],
  );

  const updateInsuredRelationship = useCallback(
    (_key, info) => {
      if (info !== insuredRelationship) {
        if (info !== selfInsuredValue) {
          saveBillingInfo({
            insuredRelationship: info,
            subscriberFirstName: null,
            subscriberLastName: null,
            dob: null,
          }, true);
        } else {
          saveSelfInsuredBillingInfo();
        }
      }
    },
    [insuredRelationship],
  );

  const storeInfo = (id, info) => {
    const hideBanner = billingInfo[id] !== info;

    saveBillingInfo({ [id]: info }, hideBanner);
  };

  const updateInput = useCallback(id => ({ target: { value } }) => storeInfo(id, value), []);

  return (
    <div className="accordion-section flex-container__inner-container">
      <div className="flex-container__etrf-section">
        <div className="billing-info-row billing-info-row__max">
          <div className="billing-info-column">
            <div className="action-label bold">Insurance Type</div>
            <div className="content-space-breathable">
              <Dropdown
                title={type || 'Choose one'}
                list={billingTypes}
                storeSelectedItem={updateBillingType}
                defaultValue={type}
              />
            </div>
          </div>
          <div className="billing-info-row__double">
            <div className="billing-info-column-fixed">
              <div className="action-label bold">Insured</div>
              <div className="content-space-breathable">
                <Dropdown
                  title={insuredRelationship || 'Select'}
                  list={insuredTypes}
                  storeSelectedItem={updateInsuredRelationship}
                  defaultValue={insuredRelationship}
                />
              </div>
            </div>
            <div className="private-insurance-column flex-row flex-4">
              <div className="flex-container__etrf-column">
                <div className="action-label bold">Insured First Name</div>
                <div className="content-space-breathable">
                  <input
                    className="billing-info-special-input"
                    placeholder="First Name"
                    type="text"
                    value={subscriberFirstName || ''}
                    onChange={updateInput('subscriberFirstName')}
                    maxLength={OnlineOrderingFieldsLimits.billingInsuredFirstNameMaxLength}
                  />
                </div>
              </div>

              <div className="private-insurance-column__no-margin">
                <div className="action-label bold">Insured Last Name</div>
                <div className="content-space-breathable">
                  <input
                    className="billing-info-special-input"
                    placeholder="Last Name"
                    type="text"
                    value={subscriberLastName || ''}
                    onChange={updateInput('subscriberLastName')}
                    maxLength={OnlineOrderingFieldsLimits.billingInsuredLastNameMaxLength}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="billing-info-row billing-info-row__max">
          <PrivateInsurance />
        </div>
        {
          !!withInsuranceCopyCheckbox && (
            <div className="billing-info-row">
              <div className="text-body">
                <input
                  type="checkbox"
                  defaultChecked={cardIncluded}
                  onClick={e => storeInfo('cardIncluded', e.target.checked)}
                />
                A copy of the insurance card will be inserted in the G360 kit
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

BillingInformationExpanded.propTypes = IPropTypes;
BillingInformationExpanded.defaultProps = defaultProps;

export { BillingInformationExpanded };
