import React from 'react';
import PropTypes from 'prop-types';
import ShareIndexCollaborator from './ShareIndexCollaborator';

const AllPracticeShare = (props) => {
  const { allPracticeShares, display } = props;
  const allPracticeSharesList = allPracticeShares.map((practiceShare) => {
    const [collaborate, collaborateShares] = practiceShare;
    const lastName = (!collaborate.lastname || collaborate.lastname === ' ')
      ? collaborateShares.users[collaborate.id].lastname : collaborate.lastname;
    const firstName = (!collaborate.firstname || collaborate.firstname === ' ')
      ? collaborateShares.users[collaborate.id].firstname : collaborate.firstname;
    const lastAndFirstName = (firstName && lastName) ? [firstName, lastName].join(', ') : collaborate.email;

    if (collaborateShares.theirs && display === 'theirs') {
      return (
        <ShareIndexCollaborator
          key={collaborateShares.theirs[0].id}
          leftColName={lastAndFirstName}
          leftColEmail={`(${collaborate.email ? collaborate.email : ''})`}
          rightColName={collaborateShares.theirs[0].revoked_at === null ? 'YOU CAN VIEW' : 'ACCESS REVOKED'}
          sharingTitle="YOU CAN VIEW"
          collabShares={collaborateShares}
        />
      );
    }
    if (collaborateShares.yours && display === 'yours') {
      return (
        <ShareIndexCollaborator
          key={collaborateShares.yours[0].id}
          leftColName={lastAndFirstName}
          leftColEmail={`(${collaborate.email ? collaborate.email : ''})`}
          rightColName={collaborateShares.yours[0].revoked_at === null ? 'YOU CAN VIEW' : 'ACCESS REVOKED'}
          sharingTitle="YOU CAN VIEW"
          collabShares={collaborateShares}
        />
      );
    }
    return null;
  });
  return (
    <React.Fragment>
      {allPracticeSharesList}
    </React.Fragment>
  );
};
export default AllPracticeShare;
AllPracticeShare.propTypes = {
  allPracticeShares: PropTypes.array.isRequired,
  display: PropTypes.string.isRequired,
};
