import HttpService from '../services/HttpService';
import { AuditLogEndpoints } from '../endpoints';
import { getToken, isInternalUser } from './helper';

const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

export class AuditLogController {
  static auditLog = (action, data) => (
    HttpService.post(AuditLogEndpoints.AUDIT_LOG, {
      args: { action, data },
      js_logging: true,
    }, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    })
  );
}
