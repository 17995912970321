import moment from 'moment';

export const physicianAdmin = user => user.type === 'PhysicianAdmin';
export const studyManager = user => user.type === 'StudyManager';
export const siteManager = user => (user.site_manager && user.ghi_number);
// eslint-disable-next-line max-len
export const getPracticeList = permittedUser => permittedUser.gh_phy_clients.map((ghPhyClients, index) => ({
  id: index,
  practiceName: ghPhyClients.practice_name,
  count: ghPhyClients.patient_ids.length,
}));
export const getLastAndFirstName = (permittedUser) => {
  const { permitted_user: { firstname, lastname, email } } = permittedUser;
  return (firstname && lastname) ? [firstname, lastname].join(', ') : email;
};

export const emailWithParenthesis = permittedUser => permittedUser.permitted_user.email && `(${permittedUser.permitted_user.email})`;
export const getStatusMessage = (share) => {
  const { revoked_at: revoked, created_at: created, accepted_at: accepted } = share;
  let statusMessage;
  if (revoked !== null) {
    statusMessage = `Access revoked ${moment(revoked).format('MMM-DD-YYYY')}`;
  } else if ((revoked === null) && (accepted == null)) {
    statusMessage = `Invitation sent ${moment(created).format('MMM-DD-YYYY')}`;
  } else if ((revoked === null) && (accepted !== null)) {
    statusMessage = `Invitation accepted ${moment(accepted).format('MMM-DD-YYYY')}`;
  }
  return statusMessage;
};
export const isValidEmail = (emailAddress) => {
  // eslint-disable-next-line no-control-regex,no-useless-escape
  const pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return pattern.test(emailAddress);
};
