import React, { useState } from 'react';
import PropTypes from 'prop-types';

const IpropTypes = {
  phy_clients: PropTypes.array,
  HandleMadal: PropTypes.func.isRequired,
};
const defaultProps = {
  phy_clients: [],
};

const SiteManager = (props) => {
  const {
    phy_clients: PhyClients,
    HandleMadal,
  } = props;
  const inialChecked = PhyClients.map(x => x.GHPHYCLIENTID.toString()) || [];
  const [state, setState] = useState({
    checked: inialChecked,
    selectAll: true,
    email: '',
    acceptterms: false,
  });
  const hangleCheckbox = (e) => {
    let checked = [];
    if (!e.target.checked) {
      checked = state.checked.filter(x => x !== e.target.value);
    } else checked = [...state.checked, e.target.value];

    setState({
      ...state,
      checked,
      selectAll: checked.length === inialChecked.length,
    });
  };
  const HandleAccess = () => {
    if (!state.acceptterms) {
      setState({ ...state, error: 'Please accept terms.' });
      return;
    }
    const sharees = [];
    state.email.split(';').forEach((email) => {
      if (window.isValidEmailAddress(email)) {
        sharees.push({
          first_name: '',
          last_name: '',
          email,
          notification: false,
        });
      } else {
        setState({
          ...state,
          emailError: 'Invalid or missing email addresses',
        });
      }
    });
    window.setBulkShares(sharees, state.checked, () => {
      setState({
        ...state, checked: false, email: '', acceptterms: false,
      });
    });
  };
  return (
    <div
      className="modal__container-outer edit__site-manager-shares"
      style={{ display: 'block' }}
    >
      <div className="modal__container-inner">
        <div className="modal__content">
          <div className="site-manager-shares-edit">
            {state.emailError && state.emailError.length > 0 && (
              <div className="errors input__email-invite__errors">
                {state.emailError}
              </div>
            )}
            <i
              className="fa fa-close modal-close"
              onClick={() => HandleMadal(false)}
            />
            <div className="settings-edit__container">
              <div className="header">Grant Access to Reports</div>
              <div className="content">
                <div className="recipient-input-text">
                  REPORTS-ACCESS RECIPIENTS:
                </div>
                <div className="errors input__email-invite__errors" />
                <i className="fa fa-search site-manager-shares-edit__search" />
                <input
                  className="input__email-invite"
                  id="input__email-invite"
                  placeholder="john@example.com; claire@example.com"
                  type="text"
                  value={state.email}
                  onChange={e => setState({
                    ...state,
                    email: e.target.value,
                    emailError: '',
                  })
                  }
                />
                <div className="physician-to-share-text">
                  PHYSICIANS THEY WILL HAVE ACCESS TO:
                </div>
                <div className="errors check-box__physician__errors" />

                <div className="checkbox-text">
                  <input
                    type="checkbox"
                    name="select-all"
                    id="select-all"
                    value="all"
                    className="check-box__select-all"
                    checked={state.selectAll}
                    onChange={e => setState({
                      ...state,
                      checked: e.target.checked ? inialChecked : [],
                      selectAll: e.target.checked,
                    })
                    }
                  />
                  Select All
                </div>
                {PhyClients.map(client => (
                  <div className="checkbox-text">
                    <input
                      type="checkbox"
                      name={client.GHPHYCLIENTID}
                      id={client.GHPHYCLIENTID}
                      value={client.GHPHYCLIENTID}
                      checked={state.checked ? state.checked.includes(
                        client.GHPHYCLIENTID.toString(),
                      ) : false}
                      onChange={hangleCheckbox}
                    />
                    <b>
                      {client.LASTNAME}
                      ,
                      {client.FIRSTNAME}
                    </b>
                    &nbsp;(
                    {client.PRACTICENAME}
                    )
                  </div>
                ))}
                {state.error && state.error.length > 0 && (
                  <div className="errors check-box__terms__errors">
                    {state.error}
                  </div>
                )}
                <div className="buttons-row">
                  <button
                    type="button"
                    className="save__edit__site-manager-shares btn__size-dynamic btn__color-blue"
                    onClick={HandleAccess}
                  >
                    GRANT ACCESS
                  </button>
                </div>
                <div className="input-group shares__terms">
                  <label className="label-terms" htmlFor="accept_terms">
                    <input
                      type="checkbox"
                      name="acceptterms"
                      id="accept_terms"
                      value={state.acceptterms}
                      required="required"
                      onChange={e => setState({
                        ...state,
                        acceptterms: e.target.value,
                        error: '',
                      })
                      }
                    />
                    I confirm the individual(s) to whom I am sending this
                    invitation is a health professional or workforce member at
                    my organization authorized to access, use, and release
                    health information in accordance with my organization
                    policies, HIPAA, federal, state, and local requirements, and
                    the service
                    {' '}
                    <a target="_blank" href="/terms_of_use">
                      Terms and Conditions of Use
                    </a>
                    . Your invitee will be required to set up a Provider Portal
                    account and to read and accept the Terms and Conditions of
                    Use before access to the Provider Portal will be provided to
                    him/her.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteManager;
SiteManager.propTypes = IpropTypes;
SiteManager.defaultProps = defaultProps;
