import React from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'throttle-debounce';

import { KeyCodes } from '../../constants/key_codes';

const IPropTypes = {
  storeSelectedItem: PropTypes.func,
  defaultValue: PropTypes.string,
  toggleShowSearch: PropTypes.func,
  onOptionsFetch: PropTypes.func,
  minLengthSearch: PropTypes.number,
  inputPlaceholder: PropTypes.string,
  iconContainerStyles: PropTypes.string,
  avoidCheckingInputMatches: PropTypes.bool,
  searchWrapperStyles: PropTypes.string,
  dropdownItemStyles: PropTypes.string,
  dropdownTitleStyles: PropTypes.string,
  dropdownListStyles: PropTypes.string,
};

const defaultProps = {
  storeSelectedItem: () => {},
  defaultValue: '',
  toggleShowSearch: () => {},
  onOptionsFetch: () => {},
  minLengthSearch: 0,
  inputPlaceholder: 'Search...',
  iconContainerStyles: '',
  avoidCheckingInputMatches: false,
  searchWrapperStyles: '',
  dropdownItemStyles: '',
  dropdownListStyles: '',
  dropdownTitleStyles: '',
};

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      inputVal: '',
      showDropdown: false,
      selectedItem: 0,
      readOnly: false,
      lastFetchCriteria: '',
      defaultValue: this.props.defaultValue,
    };
    this.searchCallId = 0;
    this.currentMatches = [];
    this.handleInputChange = this.handleInputChange.bind(this);
    this.matches = this.matches.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyDelete = this.handleKeyDelete.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.debounceSearch = debounce(600, this.performSearch.bind(this));
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { defaultValue } = this.state;
    if (defaultValue) {
      this.setState({
        readOnly: true,
      });
    }

    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
    document.removeEventListener('keydown', this.handleKeyDelete);
  }

  matches() {
    const matches = [];
    if (this.state.inputVal.length === 0) {
      return this.state.list;
    }

    this.state.list.forEach((searchResult) => {
      let text = searchResult.title;
      text = text.replace(/ +/g, ' ').toLowerCase();
      const val = this.state.inputVal.replace(/ +/g, ' ').toLowerCase();
      if (text.indexOf(val) !== -1) {
        matches.push(searchResult);
      }
    });

    this.currentMatches = matches;

    return matches;
  }

  selectItem(item) {
    const { toggleShowSearch, storeSelectedItem, avoidCheckingInputMatches } = this.props;
    // We want that `storeSelectedItem` and `toggleShowSearch`
    // have more flexibility, so we send the whole { id, title, subtitle } object
    const selectedValue = avoidCheckingInputMatches ? item : item.title;
    this.setState({ inputVal: item.title, showDropdown: false });

    if (toggleShowSearch) {
      toggleShowSearch(selectedValue);
    }
    storeSelectedItem(selectedValue);
  }

  async handleInputChange(e) {
    const inputVal = e.target.value || '';
    this.setState({ inputVal }, this.debounceSearch);
  }

  async performSearch() {
    const { minLengthSearch, onOptionsFetch } = this.props;
    const { inputVal, lastFetchCriteria } = this.state;

    const showDropdown = inputVal.length >= minLengthSearch;
    const newList = {};

    const fetchCriteria = inputVal.trim();
    if (showDropdown && fetchCriteria !== lastFetchCriteria) {
      const searchCallId = this.searchCallId + 1;
      this.searchCallId = searchCallId;
      const data = await onOptionsFetch(fetchCriteria);
      newList.lastFetchCriteria = fetchCriteria;
      if (this.searchCallId === searchCallId) {
        newList.list = data;
      }
    }
    this.setState({ showDropdown, ...newList });
  }

  handleKeyPress(event) {
    const searchResults = this.props.avoidCheckingInputMatches
      ? this.state.list
      : this.currentMatches;
    if (event.keyCode === KeyCodes.ENTER) {
      const match = this.props.avoidCheckingInputMatches
        ? this.state.list.find((_val, index) => this.state.selectedItem === index)
        : this.currentMatches.find(i => i.id === this.state.selectedItem + 1);
      let currentSelection = '';
      if (match) {
        currentSelection = match.title;
      }
      if (currentSelection) {
        this.selectItem(currentSelection);
      }
      event.preventDefault();
    } else if (event.keyCode === KeyCodes.TAB) {
      this.setState({ showDropdown: false });
      if (this.props.toggleShowSearch) {
        this.props.toggleShowSearch('');
      }
    } else if (event.keyCode === KeyCodes.ARROW_DOWN) {
      if (this.state.selectedItem < searchResults.length - 1) {
        this.setState(prevState => ({
          selectedItem: prevState.selectedItem + 1,
        }));
      } else {
        this.setState({ selectedItem: 0 });
      }
      event.preventDefault();
    } else if (event.keyCode === KeyCodes.ARROW_UP) {
      if (this.state.selectedItem > 0) {
        this.setState(prevState => ({
          selectedItem: prevState.selectedItem - 1,
        }));
      } else {
        this.setState({
          selectedItem: searchResults.length - 1,
        });
      }
    }
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyDelete(event) {
    if (event.keyCode === KeyCodes.DELETE) {
      this.clearInput();
      event.preventDefault();
    }
    document.removeEventListener('keydown', this.handleKeyDelete);
  }

  clearInput() {
    const { toggleShowSearch } = this.props;
    this.setState({
      inputVal: '',
      readOnly: false,
      defaultValue: '',
    });
    if (toggleShowSearch) {
      toggleShowSearch('');
    }
  }

  content() {
    const matches = this.props.avoidCheckingInputMatches ? this.state.list : this.matches();
    let results = null;

    if (matches) {
      results = matches.map((result, idx) => (
        <div
          key={result.id}
          className={[
            'dd-list-item',
            this.props.dropdownItemStyles,
            this.state.selectedItem === idx ? 'dd-list-item__hover' : '',
          ].join(' ')}
          onClick={() => this.selectItem(result)}
        >
          <div className={this.props.dropdownTitleStyles}>
            {result.title}
          </div>
          {result.subtitle && (
          <div>
            {result.subtitle}
          </div>
          )}

        </div>
      ));
    }

    const dropdown = <React.Fragment>{results}</React.Fragment>;

    return (
      <div className="content content-space">
        <div ref={(node) => { this.node = node; }}>
          <div className={`search-wrapper ${this.props.searchWrapperStyles}`}>
            <div className="search-header">
              <div className={`search-i-container ${this.props.iconContainerStyles}`}>
                {
                  this.state.readOnly
                    ? <i className="fa fa-close delete" aria-hidden="true" onClick={this.clearInput} />
                    : <i className="fa fa-search" aria-hidden="true" />
                }
              </div>
              {
                this.state.readOnly
                  ? (
                    <div className="default-value">
                      <div className="default-value__content">
                        {this.state.defaultValue}
                      </div>
                      <input className="invisible-input" value="" onChange={this.handleInputChange} onKeyDown={this.handleKeyDelete} />
                    </div>
                  )
                  : (
                    <input
                      type="text"
                      name="[project_id]]"
                      onKeyDown={this.handleKeyPress}
                      id="_project_id"
                      className="typeahead__input non-erasable-input"
                      value={this.state.inputVal}
                      onChange={this.handleInputChange}
                      required
                      placeholder={this.props.inputPlaceholder}
                      autoComplete="off"
                      ref={this.inputRef}
                    />
                  )
              }
            </div>
            {
              this.state.showDropdown
                ? (
                  <div className={`dd-list search-dropdown ${this.props.dropdownListStyles}`}>
                    {dropdown}
                  </div>
                )
                : null
            }
            <div className="buttons-row" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const content = this.content();

    return (
      <React.Fragment>{content}</React.Fragment>
    );
  }
}

Search.propTypes = IPropTypes;
Search.defaultProps = defaultProps;

export default Search;
