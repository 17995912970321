import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SiteMangerShare from './SiteMangerShare';
import ModalDialogBox from './commons/components/ModalDialogBox';
import SiteManagerShareModel from './commons/components/SiteManagerShareModel';

const SiteManagerShares = (props) => {
  const {
    allSiteManagerShares,
    physicianClients,
    authenticityToken,
    accessClass,
  } = props;
  const phyClients = physicianClients.map(item => Object.assign(item, { checked: false }));
  const [showModal, setShowModal] = useState(false);
  const [showSiteManger, setShowSiteMangerModal] = useState(false);
  const showModalWindow = () => {
    setShowModal(!showModal);
  };
  const closeWindow = () => {
    setShowModal(!showModal);
  };
  const showSiteMangerModal = () => {
    setShowSiteMangerModal(!showSiteManger);
  };
  const closeSiteManagerWindow = () => {
    setShowSiteMangerModal(!showSiteManger);
  };
  const handlePermissionClick = (event) => {
    event.stopPropagation();
    const target = window.$(event.target).closest('.share-index__collaborator__top');
    window.$(target).find('.share-index__collaborator__left-col__email').toggle();
    window.$(target).find('.share-index__count-of-shares').toggle();
    window.$(target).next().slideToggle();
  };
  return (
    <React.Fragment>
      <div className="share-index__body">
        <div className="share-index__body__header">
          <div className="share-index__body__header-text">
            <div className="share-index__body__header-title">
              Reports-Access Invitations
              <i className="fa fa-info-circle shares_info_circle modal-info" onClick={showModalWindow} />
            </div>
          </div>
          <i className="share-index__site-manager-share__modal-toggle fa fa-plus" onClick={showSiteMangerModal} modal-toggle="edit__site-manager-shares" />
        </div>
        <div className="share-index__collaborators-container">
          {
            allSiteManagerShares.map((item) => {
              const [collaborate, collaborateShares] = item;
              const lastName = !collaborate.lastname
                ? collaborateShares.users[collaborate.id].lastname : collaborate.lastname;
              const firstName = !collaborate.firstname
                ? collaborateShares.users[collaborate.id].firstname : collaborate.firstname;
              const firstAndLastName = (firstName && lastName) ? [firstName, lastName].join(', ') : collaborate.email;
              let rightCol;
              let num;
              if (collaborateShares.yours) {
                num = 0;
                collaborateShares.yours.forEach((share) => {
                  num = !share.revoked_at ? num + 1 : num;
                });
                rightCol = `ACCESS TO ${num} ${(num > 1 || num === 0) ? 'PHYSICIANS' : 'PHYSICIAN'}`;
              }
              return (
                <div className="share-index__collaborator" key={collaborate.id}>
                  <div className="share-index__collaborator__top" onClick={handlePermissionClick}>
                    <div className="share-index__collaborator__top-inner">
                      <div className="share-index__collaborator__left-col">
                        <div className="share-index__collaborator__left-col__name">
                          {firstAndLastName}
                          {
                            !firstAndLastName.includes('@') && <div className="share-index__collaborator__left-col__email">{`(${collaborate.email ? collaborate.email : ''})`}</div>
                          }
                        </div>
                      </div>
                      <div className="share-index__collaborator__right-col">
                        {rightCol}
                      </div>
                    </div>
                  </div>
                  <div className="share-index__collaborator__bottom">
                    {
                    collaborateShares.yours.map(share => (
                      <SiteMangerShare
                        key={share.id}
                        share={share}
                        sharees={collaborateShares.sharees}
                        sharers={collaborateShares.sharers}
                        physicians={collaborateShares.physicians || null}
                        sharedWithyou={false}
                      />
                    ))
                  }
                  </div>
                  <div className="share-index__spacing" />
                </div>
              );
            })
        }
        </div>
      </div>
      <ModalDialogBox
        show={showModal}
        showModal={closeWindow}
        ModalContent="Manage who has access to Guardant360 reports you have ordered."
      />
      <SiteManagerShareModel
        physicianClients={phyClients}
        show={showSiteManger}
        closeModal={closeSiteManagerWindow}
        authenticityToken={authenticityToken}
        accessClass={accessClass}
      />
    </React.Fragment>
  );
};
export default SiteManagerShares;
SiteManagerShares.propTypes = {
  allSiteManagerShares: PropTypes.array.isRequired,
  physicianClients: PropTypes.array.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  accessClass: PropTypes.string.isRequired,
};
SiteManagerShares.defaultProps = {
};
