import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionsTypes';

export const DEFAULT_ACCOUNT_STATE = Immutable.fromJS({
  accontsList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },

  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
  isOpen: false,
});

const AccountReducer = handleActions(
  {
    [actionTypes.GET_ACCOUNTS]: (state, action) => state
      .set('reload', false)
      .set('accontsList', action.payload.data.accounts)
      .set('totalRecords', action.payload.data.totalRecords)
      .setIn(
        ['pagination', 'pageNo'],
        action.payload.data.accounts.length === 0
            && state.getIn(['pagination', 'pageNo']) > 0
          ? state.getIn(['pagination', 'pageNo']) - 1
          : state.getIn(['pagination', 'pageNo']),
      ),
    [actionTypes.SAVE_ACCOUNT]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], {
        isOpen: true,
        message: 'Account Added Successfully',
        type: 'success',
      }),

    [actionTypes.DELETE_ACCOUNT]: state => state
      .set('reload', true)
      .setIn(
        ['notify'],
        Immutable.fromJS({
          isOpen: true,
          message: 'Account Deleted Successfully',
          type: 'success',
        }),
      ),
    [actionTypes.ACCOUNT_ERROR]: (state, action) => state
      .set('isOpen', true)
      .setIn(
        ['notify'],
        Immutable.fromJS({
          isOpen: true,
          message: action.payload.message,
          type: 'error',
        }),
      ),
    [actionTypes.ORG_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_ACCOUNT]: (state, action) => state.set(action.payload.key,
      action.payload.value),
  },
  DEFAULT_ACCOUNT_STATE,
);
export default AccountReducer;
