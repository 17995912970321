import { deserializeDate, serializeDate } from './helpers';

const blankIcdCode = () => ({ code: '', description: '' });

const deserializeIcdCode = (icdCode = {}) => ({
  code: icdCode.icdCode || '',
  description: icdCode.description || '',
});

const serializeIcdCode = (icdCode = {}) => ({
  icdCode: icdCode.code,
  description: icdCode.description,
});

// Important: Note that this class is used on EarlyStageDiagnosisSerializer as a parent class.
// If diagnosis data model needs to be changed for G360 product family,
// create a new serializer class which extends from this one.
class DiagnosisSerializer {
  static deserialize = (diagnosis) => {
    if (!diagnosis) {
      return {};
    }

    const secondaryIcdCodes = (diagnosis.secondaryIcdCodes || []).map(deserializeIcdCode);

    return {
      diagnosisPrimaryCode: deserializeIcdCode(diagnosis.primaryIcdCode),
      diagnosisSecondaryCode: secondaryIcdCodes[0] || blankIcdCode(),
      diagnosisTertiaryCode: secondaryIcdCodes[1] || blankIcdCode(),
      diagnosisQuarternaryCode: secondaryIcdCodes[2] || blankIcdCode(),
      diagnosisQuinaryCode: secondaryIcdCodes[3] || blankIcdCode(),
      diagnosisSenaryCode: secondaryIcdCodes[4] || blankIcdCode(),
      disease: diagnosis.disease,
      otherDisease: diagnosis.diagnosisOther,
      diagnosisStage: diagnosis.stage,
      diagnosisOriginalDiagnosis: deserializeDate(diagnosis.dateOfOriginalDiagnosis),
    };
  }

  static serialize = (diagnosisInfo) => {
    const secondaryIcdCodes = [
      diagnosisInfo.diagnosisSecondaryCode,
      diagnosisInfo.diagnosisTertiaryCode,
      diagnosisInfo.diagnosisQuarternaryCode,
      diagnosisInfo.diagnosisQuinaryCode,
      diagnosisInfo.diagnosisSenaryCode,
    ].filter(e => e.code).map(serializeIcdCode);

    return {
      dateOfOriginalDiagnosis: serializeDate(diagnosisInfo.diagnosisOriginalDiagnosis),
      primaryIcdCode: serializeIcdCode(diagnosisInfo.diagnosisPrimaryCode),
      secondaryIcdCodes,
      disease: diagnosisInfo.disease,
      diagnosisOther: diagnosisInfo.otherDisease,
      stage: diagnosisInfo.diagnosisStage,
    };
  }
}

export { DiagnosisSerializer };
