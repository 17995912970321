import { patientActionTypes } from '../../../actions/orders/active-order/patient-info';
import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';

const patientInitialState = {
  patientType: null,
  patientStatus: null,
  patientSex: null,
  faceSheet: false,
  patientMedicalRecordNumber: null,
  patientLastName: null,
  patientFirstName: null,
  patientBirthDate: null,
  patientState: null,
  patientCountry: null,
  patientZipCode: null,
  patientPostalCode: null,
  patientProvince: null,
  patientCity: null,
  patientStreet: null,
  patientStreet2: null,
  patientEmail: null,
  patientPhone: null,
};

const patientInfo = (state = patientInitialState, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return { ...state, ...action.order.patientInfo };
    case patientActionTypes.ADD_PATIENT_DATA:
      return { ...state, ...action.info };
    default:
      return state;
  }
};

export { patientInfo };
