class Request {
  constructor(params) {
    this.cancerType = params.cancerType || '';
    this.downloadLinks = params.downloadLinks;
    this.dateOfBirth = params.patientDateOfBirth && new Date(params.patientDateOfBirth);
    this.parentRequestId = params.parentRequestId || '';
    this.patientName = params.patientName || '';
    this.patientPath = params.patientPath || '#';
    this.physicianName = params.physicianName || '';
    this.practiceName = params.practiceName || '';
    this.receivedDate = params.receivedDate && new Date(params.receivedDate);
    this.reportDate = params.reportDate && new Date(params.reportDate);
    this.reportStatus = params.reportStatus || '';
    this.requestId = params.requestId || '';
    this.sampleType = params.sampleType || '';
    this.testType = params.testType || '';
  }
}

export { Request };
