class ReportSummaryHelper {
  static mrn() {
    return this.reportInfo().mrn;
  }

  static dateOfBirth() {
    return this.reportInfo().dateOfBirth;
  }

  static diagnosis() {
    return 'Colorectal Cancer';
  }

  static gender() {
    return this.reportInfo().gender;
  }

  static testNumber() {
    return this.reportInfo().testNumber;
  }

  static reportStatus() {
    return this.requestInfo().reportStatus;
  }

  static requestId() {
    return this.requestInfo().id;
  }

  static finalReportDate() {
    return this.requestInfo().releaseDate;
  }

  static receivedDate() {
    return this.requestInfo().receivedDate;
  }

  static bloodCollectionDate() {
    return this.requestInfo().bloodCollectionDate;
  }

  static specimen() {
    return this.reportInfo().specimen;
  }

  static surgeryDate() {
    return this.reportInfo().surgeryDate;
  }

  static physicianName() {
    return this.reportInfo().physicianName;
  }

  static practiceName() {
    return this.reportInfo().practiceName;
  }

  static formattedAddress() {
    return this.reportInfo().formattedAddress;
  }

  static phoneNumber() {
    return this.reportInfo().phoneNumber;
  }

  static fax() {
    return this.reportInfo().fax;
  }

  static testResultName() {
    return this.reportInfo().testResult.name;
  }

  static secondaryPhysicianName() {
    return this.reportInfo().secondaryPhysicianName || '-';
  }

  static testResultValue() {
    const testResultValue = this.reportInfo().testResult.value.toString();
    return (testResultValue === '1' || testResultValue === '1.0') ? 'DETECTED' : 'NOT DETECTED';
  }

  static reportInfo() {
    return window.gon.report;
  }

  static requestInfo() {
    return window.gon.requestInformation.attributes;
  }

  static isRequestInProgress() {
    return this.requestInfo().inProgress;
  }

  static isRequestInCancelled() {
    return this.reportInfo().fullStatus === 'CANCELLED';
  }
}

class InProgressReportSummaryHelper extends ReportSummaryHelper {
  static mrn() {
    return 'N/A';
  }

  static dateOfBirth() {
    return 'N/A';
  }

  static diagnosis() {
    return 'N/A';
  }

  static physicianName() {
    return '-';
  }

  static gender() {
    return '-';
  }

  static practiceName() {
    return '-';
  }

  static formattedAddress() {
    return '-';
  }

  static phoneNumber() {
    return '-';
  }

  static fax() {
    return '-';
  }

  static testNumber() {
    return '-';
  }

  static specimen() {
    return '-';
  }

  static testResultName() {
    return '-';
  }

  static testResultValue() {
    return '-';
  }

  static reportStatus() {
    return 'In progress';
  }

  static surgeryDate() {
    return '-';
  }

  static secondaryPhysicianName() {
    return '-';
  }
}

class CancelledReportSummaryHelper extends ReportSummaryHelper {
  static testNumber() {
    return '-';
  }

  static specimen() {
    return '-';
  }

  static testResultValue() {
    return '-';
  }

  static testResultName() {
    return '-';
  }

  static secondaryPhysicianName() {
    return '-';
  }
}

function reportSummaryHelperFactory() {
  if (ReportSummaryHelper.isRequestInProgress()) {
    return InProgressReportSummaryHelper;
  }

  if (ReportSummaryHelper.isRequestInCancelled()) {
    return CancelledReportSummaryHelper;
  }

  return ReportSummaryHelper;
}

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

function formatDay(date) {
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  return day;
}

function formatDate(dateString, format = 'month-day-year') {
  if (!dateString) {
    return undefined;
  }
  const monthName = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const capMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(dateString);
  const month = date.getMonth();
  const day = formatDay(date);
  const year = date.getFullYear();
  const time = formatAMPM(date);
  switch (format) {
    case 'day-month-year':
      return `${day}-${monthName[month]}-${year}`;
    case 'month-day-year-at-time':
      return `${capMonth[month]} ${day}, ${year} at ${time}`;
    default:
      return `${monthName[month]}-${day}-${year}`;
  }
}

export { reportSummaryHelperFactory, formatDate };
