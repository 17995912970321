/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Controls from '../common/components/forms/controls';
import { useForm, Form } from '../common/components/forms/useForm';
import MatchGenValidation from './MatchGenValidation';

const initialFValues = {
  companyName: '',
  matchKeyPrefix: '',
  pharmaTrialsId: '',
  algorithmVersion: '',
  liveDate: '',
  endDate: '',
  backfillWindow: '',
  configurable: '',
  priorityConfig: '',
};
const fieldsValidation = {
  companyName: {
    error: '',
    validate: 'alphanumaric',
    minLength: 2,
  },

  matchKeyPrefix: {
    error: '',
    validate: 'alphanumaric',
    minLength: 2,
  },

  pharmaTrialsId: {
    error: '',
    validate: 'alphanumaric',
    minLength: 2,
  },

  algorithmVersion: {
    error: '',
    validate: 'number',
    minLength: 1,
  },
  liveDate: {
    error: '',
    validate: 'date',
  },
  endDate: {
    error: '',
    validate: 'date',
  },

  backfillWindow: {
    error: '',
    validate: 'date',
  },
  configurable: {
    error: '',
    validate: 'boolean',
  },
  priorityConfig: {
    error: '',
    validate: 'boolean',
  },
};
const useStyles = makeStyles(theme => createStyles({
  actionButtons: {
    textAlign: 'right',
    margin: theme.spacing(2),
  },
  submitButton: {
    boxShadow: 'none',
    backgroundColor: '#1A5EA7',
    '&:hover': {
      backgroundColor: '#4572A7',
      boxShadow: 'none',
    },
  },
  resetButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  inputText: {
    width: '90%',
  },
}));
const IPropTypes = {
  MatchGenSubmitHandler: PropTypes.func.isRequired,
  formData: PropTypes.object,
  edit: PropTypes.bool.isRequired,
};
const defaultProps = {
  formData: null,
};
const MatchGenForm = (props) => {
  const {
    MatchGenSubmitHandler, formData, edit,
  } = props;
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    [
      'companyName',
      'matchKeyPrefix',
      'pharmaTrialsId',
      'algorithmVersion',
      'liveDate',
      'endDate',
      'backfillWindow',
      'configurable',
      'priorityConfig',
    ].forEach((val) => {
      if (val in fieldValues) {
        if (
          ((val === 'liveDate' && fieldValues[val] && fieldValues[val].length > 0) || val !== 'liveDate')
          && ((val === 'endDate' && fieldValues[val] && fieldValues[val].length > 0) || val !== 'endDate')
          && ((val === 'backfillWindow' && fieldValues[val] && fieldValues[val].length > 0) || val !== 'endDate')
          && ((val === 'configurable' && fieldValues[val] && fieldValues[val].length > 0) || val !== 'endDate')
          && ((val === 'priorityConfig' && fieldValues[val] && fieldValues[val].length > 0) || val !== 'endDate')
        ) {
          const error = MatchGenValidation(val, fieldValues[val], fieldsValidation) || '';
          temp[val] = error;
        } else {
          temp[val] = '';
        }
      }
    });

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
  };

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      MatchGenSubmitHandler(values, resetForm, edit);
    }
  };

  useEffect(() => {
    if (formData !== null) {
      formData.companyName = formData.name;
      formData.backfillWindow = formData.lastRunTime;
      delete formData.name;
      delete formData.lastRunTime;
      setValues({
        ...formData,
      });
    }
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        {edit && (
          <Grid item xs={12} sm={6}>
            <Controls.Input
              disabled={edit}
              label="id"
              InputLabelProps={{ required: true }}
              required
              name="id"
              value={values.id}
              onChange={handleInputChange}
              error={errors.id && errors.id}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled={edit}
            label="NAME"
            InputLabelProps={{ required: true }}
            name="companyName"
            value={values.companyName}
            onChange={handleInputChange}
            error={errors.companyName && errors.companyName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled={edit}
            label="MATCH KEY NAME"
            InputLabelProps={{ required: true }}
            name="matchKeyPrefix"
            value={values.matchKeyPrefix}
            onChange={handleInputChange}
            error={errors.matchKeyPrefix && errors.matchKeyPrefix}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="PHARMA TRIALS ID"
            InputLabelProps={{ required: true }}
            name="pharmaTrialsId"
            value={values.pharmaTrialsId}
            onChange={handleInputChange}
            error={errors.pharmaTrialsId && errors.pharmaTrialsId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled={edit}
            label="VERSION"
            InputLabelProps={{ required: true }}
            name="algorithmVersion"
            value={values.algorithmVersion}
            onChange={handleInputChange}
            error={errors.algorithmVersion && errors.algorithmVersion}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="START DATE"
            name="liveDate"
            value={values.liveDate}
            onChange={handleInputChange}
            error={errors.liveDate && errors.liveDate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="END DATE"
            name="endDate"
            value={values.endDate}
            onChange={handleInputChange}
            error={errors.endDate && errors.endDate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="BACK FILL WINDOW"
            name="backfillWindow"
            value={values.backfillWindow}
            onChange={handleInputChange}
            error={errors.backfillWindow && errors.backfillWindow}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled={edit}
            label="MATCH CONFIG"
            name="configurable"
            value={values.configurable}
            onChange={handleInputChange}
            error={errors.configurable && errors.configurable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled={edit}
            label="PRIORITY CONFIG"
            name="priorityConfig"
            value={values.priorityConfig}
            onChange={handleInputChange}
            error={errors.priorityConfig && errors.priorityConfig}
          />
        </Grid>
        <Grid item xs={12} className={classes.actionButtons}>
          <Controls.Button className={classes.submitButton} type="submit" text="save" />
          <Controls.Button
            text="reset"
            color="default"
            className={classes.resetButton}
            onClick={() => {
              resetForm(formData);
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
export default MatchGenForm;
MatchGenForm.propTypes = IPropTypes;
MatchGenForm.defaultProps = defaultProps;
