import { connect } from 'react-redux';

import { ReleaseActivityLog } from './release-activity-log';

const mapStateToProps = store => ({
  releases: store.patientPage.multiProduct.info.actionBar.releaseToPatient.activityLogs,
});

const ConnectedReleaseActivityLog = connect(
  mapStateToProps,
)(ReleaseActivityLog);

export { ConnectedReleaseActivityLog as ReleaseActivityLog };
