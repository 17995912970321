import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './search-results.module.scss';

const IPropTypes = {
  results: PropTypes.array.isRequired,
  selectItem: PropTypes.func.isRequired,
  closeSearch: PropTypes.func,
};

const defaultProps = {
  closeSearch: () => {},
};

const SearchResults = ({ results, selectItem, closeSearch }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Enter': {
        if (results[selectedItem]) {
          selectItem(results[selectedItem]);
        }
        event.preventDefault();
        break;
      }
      case 'Escape': {
        closeSearch();
        event.preventDefault();
        break;
      }
      case 'ArrowDown': {
        setSelectedItem(prevSelectedItem => (
          (prevSelectedItem + 1) % results.length
        ));
        event.preventDefault();
        break;
      }
      case 'ArrowUp': {
        setSelectedItem(prevSelectedItem => (
          (prevSelectedItem - 1 + results.length) % results.length
        ));
        event.preventDefault();
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className={styles.container}>
      { !results.length && <span className={styles.noResults}>No results found</span> }
      {
        results.map((result, idx) => (
          <div
            key={result.id}
            className={[
              styles.item,
              selectedItem === idx ? styles.selected : '',
            ].join(' ')}
            onClick={() => selectItem(result)}
          >
            {result.title}
          </div>
        ))
      }
    </div>
  );
};

SearchResults.propTypes = IPropTypes;
SearchResults.defaultProps = defaultProps;

export { SearchResults };
