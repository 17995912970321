import { ActivityLog } from './activity-logs';

class ReleaseToPatient {
  constructor(params) {
    this.activityLogs = params.activityLogs.map(activityLog => new ActivityLog(activityLog));
    this.message = params.message;
    this.patient = {
      id: params.patientId,
      emailAddress: params.patientEmail,
      readOnlyEmail: params.patientEmailReadOnly,
      firstName: params.patientName.split(', ')[1],
      lastName: params.patientName.split(', ')[0],
    };
    this.requestId = params.requestId;
    this.releasedReportsIds = params.releasedReportsIds;
    this.reportToRelease = params.reportToRelease;
  }
}

export { ReleaseToPatient };
