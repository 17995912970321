import { connect } from 'react-redux';
import { TestSelectionInformationCollapsed } from './test-selection-information-collapsed';

const mapStateToProps = ({ orders: { activeOrder: { testSelectionInfo, formErrors } } }) => ({
  testSelectionInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const ConnectedTestSelectionInformationCollapsed = connect(
  mapStateToProps,
)(TestSelectionInformationCollapsed);

export { ConnectedTestSelectionInformationCollapsed as TestSelectionInformationCollapsed };
