import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';

const IPropTypes = {
  actions: PropTypes.array,
  row: PropTypes.object.isRequired,
};
const defaultProps = {
  actions: null,
};
const ActionsMenu = (props) => {
  const { actions, row } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="more actions"
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {actions.map(item => (
          <MenuItem
            key={item.action}
            value={item.action}
            onClick={() => {
              item.handleAction(row);
              handleClose();
            }}
          >
            {item.action}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default ActionsMenu;
ActionsMenu.propTypes = IPropTypes;
ActionsMenu.defaultProps = defaultProps;
