import { connect } from 'react-redux';

import { ImmunohistochemistryTable } from './immunohistochemistry-table';

const mapStateToProps = store => (
  {
    tableData: store.patientPage.multiProduct.info.immunohistochemistryTable,
    collectionDate: (store.patientPage.multiProduct.info.patientSummary || {}).collectionDate,
  }
);

const ConnectedImmunohistochemistryTable = connect(
  mapStateToProps, null,
)(ImmunohistochemistryTable);

export { ConnectedImmunohistochemistryTable as ImmunohistochemistryTable };
