import axios from 'axios';
import { logger } from '../../../util/logger';
import { isInternalUser } from '../../../networking/controllers/helper';

const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

export const updatePatientName = (data, callback) => {
  const config = {
    method: 'post',
    url: '/rest/myguardant/update_firstname_lastname',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      ...extHeader,
    },
    data,
  };
  return axios(config)
    .then(() => {
      callback('name');
      window.location.reload();
    })
    .catch((error) => {
      logger.warn(error);
    });
};

export const updatePatientDob = (data, callback) => {
  const config = {
    method: 'post',
    url: '/rest/myguardant/update_dob',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      ...extHeader,
    },
    data,
  };
  return axios(config)
    .then(() => {
      callback('dob');
      window.location.reload();
    })
    .catch((error) => {
      logger.warn(error);
    });
};

export const updatePatientAdditionalInfo = (data, callback) => {
  const config = {
    method: 'post',
    url: '/rest/myguardant/update_additional_info',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      ...extHeader,
    },
    data,
  };
  return axios(config)
    .then(() => {
      callback('additionalInfo');
      window.location.reload();
    })
    .catch((error) => {
      logger.warn(error);
    });
};

export const updatePatientEmail = (data, callback) => {
  const config = {
    method: 'post',
    url: '/rest/myguardant/update_email',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      ...extHeader,
    },
    data,
  };
  return axios(config)
    .then(() => {
      callback('email');
      window.location.reload();
    })
    .catch((error) => {
      logger.warn(error);
    });
};
