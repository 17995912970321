import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { OnlineOrderingFieldsLimits } from '../../../../config/input-character-limit';

import baseStyles from '../../../../assets/stylesheets/etrf/base.module.scss';
import styles from './cancer-type-section.module.scss';

const IPropTypes = {
  saveDiagnosisInfo: PropTypes.func.isRequired,
  diagnoses: PropTypes.object.isRequired,
  disease: PropTypes.string,
  otherDisease: PropTypes.string,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  highlightRequiredFields: false,
  disease: '',
  otherDisease: '',
};

const other = 'Other';

const CancerTypeSection = ({
  disease,
  otherDisease,
  highlightRequiredFields,
  saveDiagnosisInfo,
  diagnoses,
}) => {
  const updateDiagnosis = useCallback(({ target: { value } }) => {
    saveDiagnosisInfo({ disease: value, otherDisease: null }, true);
  }, []);

  const updateOtherDisease = useCallback(({ target: { value } }) => {
    saveDiagnosisInfo({ otherDisease: value }, true);
  }, []);

  const highlightCancerType = highlightRequiredFields && !(
    disease === other ? otherDisease : disease
  );

  const diagnosesGroupsEntries = Object.entries({
    ...diagnoses, [other]: [...diagnoses[other], { label: other, value: other }],
  });
  // splits diagnosesGroupsByColumns in two parts
  const diagnosesGroupsByColumns = [
    diagnosesGroupsEntries,
    diagnosesGroupsEntries.splice(Math.ceil(diagnosesGroupsEntries.length / 2)),
  ];

  return !!Object.keys(diagnoses).length && (
    <div className={`${baseStyles.sectionRow} ${highlightCancerType ? styles.highlightedContainer : ''}`}>
      <div className={baseStyles.sectionColumn}>
        <p className={`${baseStyles.fieldLabel} ${highlightCancerType ? baseStyles.highlighted : ''}`}>
          Cancer Type
          <span className={baseStyles.highlightedAsterix}>*</span>
        </p>
        <div className={styles.cancerTypesSection}>
          {
            diagnosesGroupsByColumns.map(
              groupedDiagnoses => (
                <div className={styles.cancerTypesColumn} key={groupedDiagnoses[0][0]}>
                  {
                    groupedDiagnoses.map(
                      ([groupName, groupDiagnoses]) => (
                        <div className={styles.cancerTypesGroup} key={groupName}>
                          <h5 className={styles.cancerGroupName}>{groupName}</h5>
                          {
                            groupDiagnoses.sort((a, b) => a.order - b.order).map(
                              ({ label, value }) => (
                                <div key={value}>
                                  <input
                                    key={value}
                                    id={`cancer-type-radio-${groupName}-${value}`}
                                    name="cancer-type-radio"
                                    type="radio"
                                    value={value}
                                    checked={disease === value}
                                    onChange={updateDiagnosis}
                                  />
                                  <label className={styles.cancerTypeLabel} htmlFor={`cancer-type-radio-${groupName}-${value}`}>
                                    {label}
                                  </label>
                                  {
                                    disease === other && value === other && (
                                      <input
                                        type="text"
                                        placeholder="Other disease"
                                        value={otherDisease || ''}
                                        onChange={updateOtherDisease}
                                        maxLength={OnlineOrderingFieldsLimits.diagnosisMaxLength}
                                        className={highlightCancerType ? styles.highlightedOtherDisease : ''}
                                      />
                                    )
                                  }
                                </div>
                              ),
                            )
                          }
                        </div>
                      ),
                    )
                  }
                </div>
              ),
            )
          }
        </div>
      </div>
    </div>
  );
};

CancerTypeSection.propTypes = IPropTypes;
CancerTypeSection.defaultProps = defaultProps;

export { CancerTypeSection };
