import React, { useEffect, useState } from 'react';
import { Toolbar, Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import ApplicationMessage from '../common/components/elements/ApplicationMessage';
import ConfirmModalWindow from '../common/components/elements/ConfirmModalWindow';
import ModalPopup from '../common/components/elements/ModalPopup';
import Pagination from '../common/components/elements/Table/Pagination';
import Controls from '../common/components/forms/controls';
import RolesForm from './RolesForm';
import RolesTableBody from './RolesTableBody';

const useStyles = makeStyles(() => createStyles({
  root: {
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#ffffff',
    flexGrow: 1,
  },
  searchInput: {
    width: '50%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#1A5EA7',
    boxShadow: 'none',
    '@media (max-width: 445px)': {
      width: '40%',
      height: '75%',
    },
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
  borderRight: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '16px',
    marginLeft: '2%',
  },
  container: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    textAlign: 'start',
    placeContent: 'flex-start',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '20px',
    marginLeft: '10px',
  },
}));
const objDemo = {
  Administration: {
    Role: ['VIEW', 'DELETE', 'CREATE', 'UPDATE'],
    User: ['VIEW', 'DELETE', 'CREATE', 'UPDATE'],
    'Role Assignment': ['VIEW', 'UPDATE'],
    'Tenant Settings': ['VIEW', 'UPDATE'],
    'Org Settings': ['VIEW', 'UPDATE'],
  },
  Orders: {
    Orders: ['VIEW', 'DELETE', 'CREATE', 'UPDATE'],
  },
  Study: { Study: ['VIEW', 'DELETE', 'CREATE', 'UPDATE'] },
  Permissions: { Permissions: ['VIEW', 'DELETE', 'CREATE', 'UPDATE'] },
  Dashboard: { Dashboard: ['VIEW', 'DELETE', 'CREATE', 'UPDATE'] },
};
const IPropTypes = {
  rolesData: PropTypes.array,
  actions: PropTypes.object,
  roles: PropTypes.array.isRequired,
};
const defaultProps = {
  rolesData: [],
  actions: null,
};
const RolesView = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false, title: '', subTitle: '', onConfirm: () => {},
  });
  const [formData, setFormData] = useState(null);
  const [title, setTitle] = useState('');
  const classes = useStyles();

  const {
    rolesData,
    actions: {
      getRolesData,
      addRolesData,
      editRolesData,
      deleteRolesData,
      onChange,
      changePagination,
      getClaims,
    },
    roles,
  } = props;
  const rolesList = rolesData.toJS().rolesList || [];
  const notify = rolesData && rolesData.toJS().notify;
  const pagination = rolesData && rolesData.toJS().pagination;
  const claimsList = rolesData.toJS().claimsList || objDemo;
  const rolesManagement = rolesData && rolesData.toJS();
  useEffect(() => {
    if (rolesList.length === 0 || rolesManagement.reload) {
      setConfirmDialog({
        isOpen: false, title: '', subTitle: '', onConfirm: () => {},
      });
      getRolesData(pagination);
      if (roles) {
        if (roles.includes('CREATE') || roles.includes('UPDATE')) {
          getClaims();
        }
      }
      onChange({ key: 'reload', value: false });
    }
  }, [rolesManagement.reload]);
  useEffect(() => {
    if (rolesManagement.isOpen) {
      setConfirmDialog({
        isOpen: false, title: '', subTitle: '', onConfirm: () => {},
      });
      onChange({ key: 'isOpen', value: false });
    }
    if (rolesList.length === 0 && rolesManagement.totalRecords !== 0) {
      getRolesData(pagination);
    }
  }, [rolesManagement.isOpen, pagination]);

  const addClickHandler = () => {
    setOpenPopup(true);
    setFormData(null);
    setTitle('Add Role');
  };
  const editClickHandler = (row) => {
    setOpenPopup(true);
    setFormData(row);
    setTitle('Edit Role');
  };
  const deleteClickHandler = (row) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Are you sure to delete this record',
      subTitle: '',
      onConfirm: () => {
        deleteRolesData(row);
        setConfirmDialog({
          isOpen: false, title: '', subTitle: '', onConfirm: () => {},
        });
      },
    });
  };
  const setNotify = (data) => {
    onChange({ key: 'notify', value: { ...data } });
  };

  const submitHandler = (values, resetForm, edit) => {
    if (edit === true) editRolesData(values);
    else addRolesData(values);
    resetForm();
    setFormData(null);
    setOpenPopup(false);
  };
  const userPermission = {
    Edit: roles && roles.includes('UPDATE'),
    Delete: roles && roles.includes('DELETE'),
  };
  const actionButtons = [
    { action: 'Edit', handleAction: editClickHandler },
    { action: 'Delete', handleAction: deleteClickHandler },
  ];
  const handlePagination = (e, pageNum) => {
    e.preventDefault();
    getRolesData(pageNum);
    changePagination(pageNum);
  };
  return (
    <React.Fragment>
      <div className={classes.title}>Users</div>
      <div id="RolesContainer" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Controls.Button
            text="Add Role"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => addClickHandler()}
            disabled={roles && !roles.includes('CREATE')}
          />
        </Toolbar>
        <Grid container xs={12} style={{ placeContent: 'center' }}>
          <Grid container xs={11} direction="row" className={classes.container}>
            <Grid item xs={2}>
              <Box className={classes.borderRight}>Name</Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.borderRight}>Module</Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.borderRight}>Feature</Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.borderRight}>Permissions</Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.borderRight}>Actions</Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.borderRight} />
            </Grid>
          </Grid>
          <RolesTableBody
            rolesList={rolesList}
            actionButtons={actionButtons}
            userPermission={userPermission}
          />
        </Grid>
        <Pagination
          count={rolesData.toJS().totalRecords || 0}
          rowsPerPage={pagination.pageSize || 0}
          page={pagination.pageNo || 0}
          handleChangePage={(e, val) => handlePagination(e, { ...pagination, pageNo: val })}
          handleChangeRowsPerPage={e => handlePagination(e,
            { ...pagination, pageSize: e.target.value, pageNo: 0 })}
        />
        <ModalPopup title={title} openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <RolesForm
            edit={title === 'Edit Role'}
            formData={formData}
            submithandler={submitHandler}
            claimsList={claimsList}
          />
        </ModalPopup>
        <ConfirmModalWindow confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        <ApplicationMessage notify={notify} setNotify={setNotify} />
      </div>
    </React.Fragment>
  );
};

export default RolesView;
RolesView.propTypes = IPropTypes;
RolesView.defaultProps = defaultProps;
