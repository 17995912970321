import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceMenu } from './reference-menu';

const IPropTypes = {
  data: PropTypes.object,
};

const defaultProps = {
  data: null,
};

const ReferencesContainer = ({ data }) => (
  <div>
    {
      !!data && data.referenceItems.length > 0
      && <ReferenceMenu header="References and Appendices" data={data.referenceItems} />
    }
  </div>
);

ReferencesContainer.propTypes = IPropTypes;
ReferencesContainer.defaultProps = defaultProps;

export { ReferencesContainer };
