import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';
import { PhysicianInformationExpanded } from '../physician-information-expanded';
import { PhysicianInformationCollapsed } from '../physician-information-collapsed';
import physicianInfoReducer from '../../../../store/reducers/orders/active-order/physician_info';
import { OrdersController } from '../../../../networking/controllers/orders';

const IPropTypes = {
  clients: PropTypes.array,
  enabled: PropTypes.bool,
  finishLoading: PropTypes.func,
  onToggle: PropTypes.func,
  physicianStore: PropTypes.shape(physicianInfoReducer).isRequired,
  savePhysicianInfo: PropTypes.func.isRequired,
  showErrorBanner: PropTypes.func.isRequired,
  resetOnlyPhysicianData: PropTypes.func.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
  highlightRequiredFields: PropTypes.bool,
  emrUser: PropTypes.bool.isRequired,
  emrProvider: PropTypes.object,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
  finishLoading: () => {},
  clients: [],
  highlightRequiredFields: false,
  emrProvider: null,
};

class PhysicianInformationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      physicians: [],
    };
    this.setDropdownClient = this.setDropdownClient.bind(this);
    this._storeDefaultData = this._storeDefaultData.bind(this);
    this._storeSelectedItemsForClient = this._storeSelectedItemsForClient.bind(this);
    this._setDefaultAccount = this._setDefaultAccount.bind(this);
    this._finishLoading = this._finishLoading.bind(this);
  }

  componentDidMount() {
    this.props.updateLoadingCount(1);
    this._setDefaultAccount();
  }

  async setDropdownClient(id) {
    const {
      clients, resetOnlyPhysicianData,
      physicianStore: { client: { clientAccount } },
      emrUser,
    } = this.props;
    const selectedClient = clients.find(item => item.id === id);
    this._storeSelectedItemsForClient(selectedClient);

    if (clientAccount !== selectedClient.account) {
      this.setState({ physicians: [] });
      resetOnlyPhysicianData();
    }

    try {
      if (!emrUser) {
        const physicians = await OrdersController.getPhysiciansForAccount(selectedClient.account);

        this.setState({ isLoading: false, physicians });
      }
    } catch (error) {
      this.props.showErrorBanner();
    }
  }

  _storeDefaultData() {
    const { clients, emrUser, emrProvider } = this.props;
    const { physicians } = this.state;
    if (clients.length === 1) {
      const defaultClient = clients[0];
      this._storeSelectedItemsForClient(defaultClient);
    }

    if ((emrUser && emrProvider) || physicians.length === 1) {
      const defaultPhysician = (emrUser && emrProvider) || physicians[0];
      const info = {
        physician: {
          id: defaultPhysician.id,
          lastname: defaultPhysician.lastname,
          firstname: defaultPhysician.firstname,
          email: defaultPhysician.email,
          phone: defaultPhysician.phone,
          npi: defaultPhysician.npi,
          orderingPhysician: defaultPhysician.title,
        },
      };

      if (emrUser) {
        info.physician.fullname = defaultPhysician.title;
      }

      this.props.savePhysicianInfo(info);
    }
    this._finishLoading();
  }

  _storeSelectedItemsForClient(defaultAccount) {
    const hideBanner = this.props.physicianStore.client.client !== defaultAccount.title;

    this.props.savePhysicianInfo({
      client: {
        client: defaultAccount.title,
        clientAccount: defaultAccount.account,
        clientPhone: defaultAccount.phone,
        clientFax: defaultAccount.fax,
        clientAddress1: defaultAccount.address1,
        clientAddress2: defaultAccount.address2,
        clientCountry: defaultAccount.country,
        clientState: defaultAccount.state,
        clientCity: defaultAccount.city,
        clientZipCode: defaultAccount.zipCode,
      },
    }, hideBanner);
  }

  async _setDefaultAccount() {
    const { clients, emrUser, physicianStore: { client: { clientAccount } } } = this.props;
    const selectedClient = clients.find(item => item.account === clientAccount);
    const defaultAccount = selectedClient ? selectedClient.account : clients[0].account;

    if (clients.length === 1 || selectedClient) {
      try {
        if (!emrUser) {
          const physicians = await OrdersController.getPhysiciansForAccount(defaultAccount);
          this.setState({ physicians });
        }
        this._storeDefaultData();
      } catch (error) {
        this.props.showErrorBanner();
      }
    } else if (clients.length > 1) {
      this._finishLoading();
    }
  }

  _finishLoading() {
    this.props.updateLoadingCount(-1);

    this.setState({ isLoading: false });

    this.props.finishLoading();
  }

  render() {
    const { isLoading, physicians } = this.state;
    const {
      clients,
      enabled,
      onToggle,
      highlightRequiredFields,
    } = this.props;
    return (
      <>
        {
          !isLoading
          && (
            <GenericHeader
              handleContainerArrowClick={onToggle}
              enabled={enabled}
              label="Physician Information"
            />
          )
        }
        {
          enabled
            ? (
              <PhysicianInformationExpanded
                accounts={clients}
                isLoading={isLoading}
                physicians={physicians}
                setDropdownClient={this.setDropdownClient}
              />
            ) : (
              <PhysicianInformationCollapsed
                onToggle={onToggle}
                highlightRequiredFields={highlightRequiredFields}
              />
            )
        }
      </>
    );
  }
}


PhysicianInformationContainer.propTypes = IPropTypes;
PhysicianInformationContainer.defaultProps = defaultProps;

export { PhysicianInformationContainer };
