import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './alterations-table.module.scss';
import { AlterationRow } from '../alteration-row';
import { InfoButton } from '../info-button';
import { AlterationInfoModal } from '../alteration-info-modal';
import {
  DEFAULT_COLUMN_ORDER,
  ALTERATION_COLUMNS,
  ROW_ORDERS,
  DEFAULT_ROW_ORDER,
  sortAlterations,
} from '../../../helpers/alterations-table';

const IPropTypes = {
  alterationHeaderText: PropTypes.string.isRequired,
  frequencyHeaderText: PropTypes.string.isRequired,
  therapiesHeaderText: PropTypes.string.isRequired,
  clinicalHeaderText: PropTypes.string.isRequired,
  noAlterationsText: PropTypes.string,
  hideTherapies: PropTypes.bool.isRequired,
  alterationData: PropTypes.array,
  requestId: PropTypes.string.isRequired,
  colorTable: PropTypes.object,
  showSuperscript: PropTypes.bool.isRequired,
  therapyUrl: PropTypes.string.isRequired,
  showFrequencyColumn: PropTypes.bool.isRequired,
};

const defaultProps = {
  noAlterationsText: null,
  alterationData: [],
  colorTable: null,
};

const noop = () => {};

const AlterationsTable = (props) => {
  const [showTherapyModal, setShowTherapyModal] = useState(false);
  const [rowOrder, setRowOrder] = useState(DEFAULT_ROW_ORDER);
  const colorHeader = !!props.colorTable && <th className={styles.tableHeaderFirstItem} />;
  const setAlterationOrder = () => (
    rowOrder === ROW_ORDERS.ALTERATION_ASC
      ? setRowOrder(ROW_ORDERS.ALTERATION_DESC) : setRowOrder(ROW_ORDERS.ALTERATION_ASC)
  );
  const setFrequencyOrder = () => (
    rowOrder === ROW_ORDERS.FREQUENCY_ASC
      ? setRowOrder(ROW_ORDERS.FREQUENCY_DESC) : setRowOrder(ROW_ORDERS.FREQUENCY_ASC)
  );
  const setTherapyOrder = () => (
    rowOrder === ROW_ORDERS.THERAPY_ASC
      ? setRowOrder(ROW_ORDERS.THERAPY_DESC) : setRowOrder(ROW_ORDERS.THERAPY_ASC)
  );
  const setClinicalOrder = () => (
    rowOrder === ROW_ORDERS.CLINICAL_ASC
      ? setRowOrder(ROW_ORDERS.CLINICAL_DESC) : setRowOrder(ROW_ORDERS.CLINICAL_ASC)
  );
  const alterationHeader = (
    <th
      className={[
        styles.sortableTableHeaderItem,
        rowOrder === ROW_ORDERS.ALTERATION_ASC ? styles.ascending : '',
        rowOrder === ROW_ORDERS.ALTERATION_DESC ? styles.descending : '',
      ].join(' ')}
      onClick={setAlterationOrder}
    >
      {props.alterationHeaderText}
    </th>
  );
  const frequencyHeader = (
    props.showFrequencyColumn
      ? (
        <th
          className={[
            styles.percentageHeaderItem,
            rowOrder === ROW_ORDERS.FREQUENCY_ASC ? styles.ascending : '',
            rowOrder === ROW_ORDERS.FREQUENCY_DESC ? styles.descending : '',
          ].join(' ')}
          onClick={props.showFrequencyColumn ? setFrequencyOrder : noop}
        >
          {props.frequencyHeaderText}
        </th>
      )
      : null
  );
  const therapiesHeader = (
    <th
      className={[
        styles.percentageHeaderItem,
        props.hideTherapies ? styles.inactive : '',
        rowOrder === ROW_ORDERS.THERAPY_ASC ? styles.ascending : '',
        rowOrder === ROW_ORDERS.THERAPY_DESC ? styles.descending : '',
      ].join(' ')}
      onClick={!props.hideTherapies ? setTherapyOrder : noop}
    >
      {
        !props.hideTherapies
        && (
          <>
            {props.therapiesHeaderText}
            <InfoButton onClick={() => setShowTherapyModal(true)} />
          </>
        )
      }
    </th>
  );
  const clinicalHeader = (
    <th
      className={[
        styles.availabilityHeaderItem,
        props.hideTherapies ? styles.inactive : '',
        rowOrder === ROW_ORDERS.CLINICAL_ASC ? styles.ascending : '',
        rowOrder === ROW_ORDERS.CLINICAL_DESC ? styles.descending : '',
      ].join(' ')}
      onClick={!props.hideTherapies ? setClinicalOrder : noop}
    >
      {!props.hideTherapies && props.clinicalHeaderText}
    </th>
  );

  const columns = {
    [ALTERATION_COLUMNS.COLOR]: colorHeader,
    [ALTERATION_COLUMNS.ALTERATIONS]: alterationHeader,
    [ALTERATION_COLUMNS.FREQUENCY]: frequencyHeader,
    [ALTERATION_COLUMNS.THERAPIES]: therapiesHeader,
    [ALTERATION_COLUMNS.CLINICAL]: clinicalHeader,
  };

  const columnOrder = DEFAULT_COLUMN_ORDER;

  return (
    <>
      {
        showTherapyModal
        && (
          <AlterationInfoModal
            onClose={() => setShowTherapyModal(false)}
          >
            <>
              <strong>
                Therapeutic annotations:
              </strong>
              {' '}
              All annotations are based on publicly available information
              as described in the &quot;Detailed Therapy Results&quot;
              and &quot;Clinical Relevance&quot; sections available in the Appendix.
            </>
          </AlterationInfoModal>
        )
      }
      <table className={styles.table}>
        <thead>
          <tr>
            {
              columnOrder.map(columnName => (
                <React.Fragment key={columnName}>
                  {columns[columnName]}
                </React.Fragment>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            (!props.alterationData || !props.alterationData.length)
              ? (
                <td className={styles.noAlterationsRow} colSpan={6}>
                  {props.noAlterationsText}
                </td>
              )
              : sortAlterations(props.alterationData, rowOrder).map((data, index) => (
                <AlterationRow
                  key={data.id}
                  color={props.colorTable ? props.colorTable.colors[data.id] : null}
                  bordered={!!props.colorTable && !props.colorTable.colors[data.id]}
                  noTopBorder={index > 0 && props.alterationData[index - 1].bordered}
                  showSuperscript={props.showSuperscript}
                  requestId={props.requestId}
                  hideTherapies={props.hideTherapies}
                  therapyUrl={props.therapyUrl}
                  showFrequencyColumn={props.showFrequencyColumn}
                  columnOrder={columnOrder}
                  {...data}
                />
              ))
          }
        </tbody>
      </table>
    </>
  );
};

AlterationsTable.propTypes = IPropTypes;
AlterationsTable.defaultProps = defaultProps;

export { AlterationsTable };
