import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { rootReducer } from './reducers';

const composeEnhancers = composeWithDevTools({ trace: true }) || compose;
const middleware = composeEnhancers(
  applyMiddleware(thunk),
);

export const store = createStore(
  rootReducer,
  middleware,
);
