import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CollapsedTab } from '../../generic/CollapsedTab';

const IPropTypes = {
  clinicalHistoryStore: PropTypes.object.isRequired,
  onToggle: PropTypes.func,
};

const defaultProps = {
  onToggle: () => {},
};

const mapStateToProps = store => ({
  clinicalHistoryStore: store.orders.activeOrder.clinicalHistoryInfo,
});

const ClinicalHistoryCollapsed = ({ clinicalHistoryStore, onToggle }) => {
  const {
    clinicalHistoryTreatment,
    clinicalHistoryReport,
    clinicalHistoryCgpFeasible,
    clinicalHistoryCgpCompletion,
    clinicalHistoryCgpResult,
  } = clinicalHistoryStore;

  return (
    <div className="requests-list requests-list--collapsed--reactified">
      <div
        className="accordion-section collapsed flex-container__inner-container"
        type="button"
        onClick={onToggle}
      >
        <div className="flex-container__etrf-section">
          <div className="flex-row">
            <div className="patient-information-column-1-3">
              <CollapsedTab title="Patient Condition" value={clinicalHistoryTreatment} largeValue />
              <CollapsedTab title="Tissue-Based CGP Possible" value={clinicalHistoryCgpFeasible} />
            </div>
            <div className="clinical-information-column-1-3">
              <CollapsedTab title="Guardant Report Since Last Progression" value={clinicalHistoryReport} />
              <CollapsedTab title="Tissue-Based CGP Performed" value={clinicalHistoryCgpCompletion} />
            </div>
            <div className="clinical-information-column-1-2">
              <CollapsedTab className="clinical-history" title="Tissue-Based CGP Results" value={clinicalHistoryCgpResult} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ClinicalHistoryCollapsed.propTypes = IPropTypes;
ClinicalHistoryCollapsed.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(ClinicalHistoryCollapsed);
