import {
  RECEIVE_ORDER_ERROR, REDIRECT_AFTER_ERROR, CLEAR_ORDER_ERROR, CLEAR_REDIRECT_ROUTE,
} from '../../actions/errors/index';

const errorsReducerInitialState = {
  messages: [],
  redirectRoute: null,
};

const errorsReducer = (state = errorsReducerInitialState, action) => {
  switch (action.type) {
    case CLEAR_ORDER_ERROR:
      return {
        ...state,
        messages: [],
      };
    case CLEAR_REDIRECT_ROUTE:
      return {
        ...state,
        redirectRoute: null,
      };
    case RECEIVE_ORDER_ERROR:
      return {
        ...state,
        messages: action.errors,
      };
    case REDIRECT_AFTER_ERROR:
      return {
        ...state,
        redirectRoute: action.route,
      };
    default:
      return state;
  }
};

export { errorsReducer };
