import React from 'react';
import PropTypes from 'prop-types';

import { OnlineOrderingFieldsLimits } from '../../../../../config/input-character-limit';

const IPropTypes = {
  updateInput: PropTypes.func.isRequired,
  toggleAdditionalReceipient: PropTypes.func.isRequired,
  secondaryRecipient: PropTypes.object.isRequired,
};

const MedicalCollaborator = ({ toggleAdditionalReceipient, secondaryRecipient, updateInput }) => (
  <div className="flex-container__etrf-section">
    <div className="flex-container__etrf-row">
      <div className="flex-container__etrf-column">
        <div className="flex-container" onClick={toggleAdditionalReceipient}>
          <i className="fa fa-minus-circle medical-collaborator-minus" />
          <div className="action-label bold click-btn">Additional Recipient</div>
        </div>
        <div className="additional-recipient-name">
          <input
            className="content-space-breathable first-name"
            type="text"
            placeholder="First Name"
            value={secondaryRecipient.secondaryRecipientFirstname || ''}
            onChange={updateInput('secondaryRecipientFirstname')}
            maxLength={OnlineOrderingFieldsLimits.additionalRecipientFirstNameMaxLength}
          />
          <input
            className="content-space-breathable"
            type="text"
            placeholder="Last Name"
            value={secondaryRecipient.secondaryRecipientLastname || ''}
            onChange={updateInput('secondaryRecipientLastname')}
            maxLength={OnlineOrderingFieldsLimits.additionalRecipientLastNameMaxLength}
          />
        </div>
      </div>
      <div className="flex-container__etrf-column">
        <div className="action-label bold">Phone Number</div>
        <input
          className="content-space-breathable"
          type="text"
          placeholder="(###) ###-####"
          value={secondaryRecipient.secondaryRecipientPhone || ''}
          onChange={updateInput('secondaryRecipientPhone')}
          maxLength={OnlineOrderingFieldsLimits.phoneNumberMaxLength}
        />
      </div>
      <div className="flex-container__etrf-column">
        <div className="action-label bold">Fax Number</div>
        <input
          className="content-space-breathable"
          type="text"
          placeholder="(###) ###-####"
          value={secondaryRecipient.secondaryRecipientFax || ''}
          onChange={updateInput('secondaryRecipientFax')}
          maxLength={OnlineOrderingFieldsLimits.phoneNumberMaxLength}
        />
      </div>
    </div>
  </div>
);

MedicalCollaborator.propTypes = IPropTypes;

export { MedicalCollaborator };
