class OrderingUser {
  constructor(params) {
    this.id = params.portalUserId;
    this.lastname = params.lastName;
    this.firstname = params.firstName;
    this.email = params.email;
  }

  fullName = () => `${this.firstname || ''} ${this.lastname || ''}`.trim();
}

export { OrderingUser };
