import React from 'react';
import PropTypes from 'prop-types';
import { jsAuditAction } from '../util/ajax';
import { constants } from '../config/constants';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  requestId: PropTypes.string,
};

const defaultProps = {
  requestId: null,
};

class OrderProgressionTestButton extends React.Component {
  _onClick = async () => {
    const { authenticityToken, requestId } = this.props;

    await jsAuditAction(
      authenticityToken,
      constants.auditLogActions.progression_test_button,
      requestId,
    );
    window.location.assign(`${window.location.origin}/orders/requests/${requestId}`);
  }

  render() {
    return (
      <button type="button" className="action-bar-button" onClick={this._onClick}>
        <i className="fa fa-refresh" />
      </button>
    );
  }
}

OrderProgressionTestButton.propTypes = IPropTypes;
OrderProgressionTestButton.defaultProps = defaultProps;

export default OrderProgressionTestButton;
