import React from 'react';
import PropTypes from 'prop-types';

import styles from './request.module.scss';
import { RequestDownloadMenu } from '../request-download-menu';
import { RequestMoreActions } from '../dashboard/request-more-actions';
import { ReportController } from '../../networking/controllers/reports';
import { logger } from '../../util/logger';
import { ProductLogo } from '../generic/product-logo/product-logo';

// Shapes must change after refactor from multireports gets finished
const IPropTypes = {
  requestStatus: PropTypes.string.isRequired,
  request: PropTypes.shape({
    requestId: PropTypes.string.isRequired,
    latestRevisionId: PropTypes.number,
    patientUrl: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    ignoreParent: PropTypes.bool.isRequired,
    report: PropTypes.shape({
      downloadable: PropTypes.bool.isRequired,
      urls: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string,
        link: PropTypes.string,
      })).isRequired,
      patientNameFormatted: PropTypes.string.isRequired,
      reportDateStatus: PropTypes.string.isRequired,
      cancerType: PropTypes.string.isRequired,
    }).isRequired,
    childRequests: PropTypes.arrayOf(PropTypes.shape({
      requestId: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        downloadable: PropTypes.bool.isRequired,
        itemId: PropTypes.string.isRequired,
        latestRevisionId: PropTypes.number,
        reportDateStatus: PropTypes.string.isRequired,
        revisions: PropTypes.arrayOf(PropTypes.number),
      })).isRequired,
    })),
    trm: PropTypes.string,
  }).isRequired,
  revisionsMap: PropTypes.array.isRequired,
  accessKlass: PropTypes.string.isRequired,
  onRead: PropTypes.func.isRequired,
};

const miniTrm = (request) => {
  const { trm } = request;

  return (
    <div
      className={`${styles.requestData} ${styles.trm}`}
      // NOTE: in this particular case we need to set HTML here, though
      // it would be a far better idea not to do it. We should think of ways to avoid
      // this, if it can be done.
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: trm || 'N/A' }}
    />
  );
};


const productRow = (request) => {
  let statuses = null;
  let testType = null;
  let key = null;

  if (request.items && request.items.length > 0) {
    statuses = request.items.map(item => (
      <p key={item.itemId}>{ item.reportDateStatus }</p>
    ));
    ([{ testType }] = request.items);
    key = request.items[0].reportDateStatus;
  } else {
    if (request.report && request.report.reportDateStatus) {
      statuses = <p>{ request.report.reportDateStatus }</p>;
      ({ testType } = request);
      key = request.report.reportDateStatus;
    }
  }

  return (
    <div
      key={key}
      className={styles.productRow}
    >
      <div className={`${styles.requestData} ${styles.requestLogo}`}>
        <ProductLogo testType={testType} />
      </div>
      <div className={`${styles.requestData} ${styles.reportStatus}`}>{ statuses }</div>
      {miniTrm(request)}
    </div>
  );
};

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDownloadDropdown: false, showActionsDropdown: false };
    this.handleMoreActionsReturn = this.handleMoreActionsReturn.bind(this);
    this.handleRequestClick = this.handleRequestClick.bind(this);
    this.handleDashboardDownload = this.handleDashboardDownload.bind(this);
    this.handleDashboardMoreAction = this.handleDashboardMoreAction.bind(this);
    this._toggleDownloadDropdown = this._toggleDownloadDropdown.bind(this);
    this._toggleActionsDropdown = this._toggleActionsDropdown.bind(this);
    this._handleWindowClick = this._handleWindowClick.bind(this);
    this.dropdownRef = React.createRef();
    this.downloadButtonRef = React.createRef();
    this.actionsButtonRef = React.createRef();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this._handleWindowClick);
  }

  moreActionsButton = (downloadable) => {
    if (!downloadable) {
      return <div className={styles.moreActionsButton} ref={this.actionsButtonRef} />;
    }

    return (
      <div className={styles.moreActionsButton}>
        <button className={styles.dropdownToggle} type="button" onClick={this._toggleActionsDropdown} ref={this.actionsButtonRef}>
          <div className={`fa fa-ellipsis-v request_elliptical ${styles.actionsIcon}`} />
        </button>
      </div>
    );
  }

  handleDashboardDownload(e) {
    const { requestStatus, onRead } = this.props;
    e.stopPropagation();
    if (e.target.className.indexOf('disabled') !== -1) {
      e.preventDefault();
      return;
    }

    if (requestStatus === 'new' && e.target.className.indexOf('react-dashboard-download') !== -1) {
      const $new = window.$('.request-counts-tile--new .request-counts-tile__count');
      $new.html(parseInt($new.html(), 10) - 1);

      const $downloaded = window.$('.request-counts-tile--downloaded .request-counts-tile__count');
      $downloaded.html(parseInt($downloaded.html(), 10) + 1);
      onRead();
    }
  }

  async handleDashboardMoreAction(e) {
    const { request: { latestRevisionId } } = this.props;
    const revisionIds = e.target.dataset.jsRevisionids;

    e.stopPropagation();
    if (e.target.className.indexOf('popup-menu-header') !== -1) {
      e.preventDefault();
      this._toggleActionsDropdown();
      return;
    }

    window.showSpinner();
    try {
      if (latestRevisionId === null) {
        await ReportController.toggleReadRequest(revisionIds.split(',')[0], revisionIds);
      } else {
        await ReportController.toggleReadRequest(latestRevisionId, revisionIds);
      }
      this.handleMoreActionsReturn();
    } catch (error) {
      logger.warn(error);
    }
    window.hideSpinner();
  }

  // TODO: reimplement this function using React instead of JQuery
  handleMoreActionsReturn() {
    const { requestStatus, onRead } = this.props;
    const difference = requestStatus === 'new' ? 1 : -1;

    const $new = window.$('.request-counts-tile--new .request-counts-tile__count');
    $new.html(parseInt($new.html(), 10) - difference);

    const $downloaded = window.$('.request-counts-tile--downloaded .request-counts-tile__count');
    $downloaded.html(parseInt($downloaded.html(), 10) + difference);

    onRead();
  }

  handleRequestClick(e) {
    const { request } = this.props;

    if (this.downloadButtonRef.current.contains(e.target)
      || this.actionsButtonRef.current.contains(e.target)) {
      return;
    }

    window.location.href = request.patientUrl;
  }

  _toggleDownloadDropdown() {
    this.setState(prevState => ({
      showDownloadDropdown: !prevState.showDownloadDropdown,
    }));
    window.addEventListener('click', this._handleWindowClick);
  }

  _toggleActionsDropdown() {
    this.setState(prevState => ({
      showActionsDropdown: !prevState.showActionsDropdown,
    }));
    window.addEventListener('click', this._handleWindowClick);
  }

  _handleWindowClick(e) {
    if (this.dropdownRef
      && this.dropdownRef.current
      && !this.dropdownRef.current.contains(e.target)
      && !this.downloadButtonRef.current.contains(e.target)
      && !this.actionsButtonRef.current.contains(e.target)) {
      this.setState(
        {
          showDownloadDropdown: false,
          showActionsDropdown: false,
        },
      );
      window.removeEventListener('click', this._handleWindowClick);
    }
  }

  downloadableRequest() {
    const { report, childRequests } = this.props.request;

    if (report.downloadable) {
      return true;
    }

    if (childRequests !== undefined) {
      return childRequests
        .map(child => child.items
          .map(item => item.downloadable)
          .reduce((res, downloadable) => res || downloadable, false))
        .reduce((requestResult, childResult) => requestResult || childResult, false);
    }

    return false;
  }

  downloadButton(downloadable) {
    if (downloadable) {
      return <button type="button" className={`fa fa-download ${styles.requestData} ${styles.downloadButton}`} onClick={this._toggleDownloadDropdown} ref={this.downloadButtonRef} />;
    }
    return <button type="button" className={`fa fa-download ${styles.requestData} ${styles.downloadButton} ${styles.disabledDownloadButton}`} ref={this.downloadButtonRef} />;
  }


  productList() {
    const { request } = this.props;
    const requestToCreateRow = [];
    if (!request.ignoreParent) {
      requestToCreateRow.push(request);
    }

    requestToCreateRow.push(...request.childRequests);
    const list = requestToCreateRow.map(item => (
      productRow(item)
    ));
    return (
      <div className={styles.productList}>
        {list}
      </div>
    );
  }

  render() {
    const {
      request,
      revisionsMap,
      requestStatus,
      accessKlass,
    } = this.props;

    const reportUrls = request.report.urls.map(link => ({
      displayName: link.display_name,
      link: link.link,
    }));
    const downloadBtnStatus = this.downloadableRequest();
    const actionsBtnStatus = downloadBtnStatus;

    const { showDownloadDropdown, showActionsDropdown } = this.state;

    return (
      <div data-request-id={request.requestId} className={styles.container}>
        <div role="button" tabIndex="0" className={styles.requestReact} onClick={this.handleRequestClick}>
          <div className={styles.dataBlock}>
            <div className={`${styles.requestData} ${styles.requestDataName}`}>{ request.report.patientNameFormatted }</div>
            <div className={`${styles.requestData} ${styles.cancer}`}>{ request.report.cancerType }</div>
            {this.productList()}
            { this.downloadButton(downloadBtnStatus) }
            { this.moreActionsButton(actionsBtnStatus) }
          </div>
          { showDownloadDropdown && downloadBtnStatus
            ? (
              <RequestDownloadMenu
                ref={this.dropdownRef}
                handleDashboardDownload={this.handleDashboardDownload}
                requestId={request.requestId}
                urls={reportUrls}
                accessKlass={`${accessKlass} ${styles.title}`}
                menuKlass={styles.downloadDropdown}
                contentKlass={styles.downloadLinksContainer}
                canDownloadReport={downloadBtnStatus}
                onTitleClick={this._toggleDownloadDropdown}
              />
            )
            : null
          }
          { showActionsDropdown && actionsBtnStatus
            ? (
              <RequestMoreActions
                handleDashboardMoreAction={this.handleDashboardMoreAction}
                requestId={request.requestId}
                requestStatus={requestStatus}
                revisionsMap={revisionsMap}
                accessKlass={accessKlass}
              />
            )
            : null
          }
        </div>
      </div>
    );
  }
}

Request.propTypes = IPropTypes;

export { Request };
