import React from 'react';
import PropTypes from 'prop-types';
import { Box, CardActionArea } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { isEmail } from '../../admin/common/utils/Validation';
import { schema } from '../schema';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#ffffff',
    },
    '& .MuiSelect-nativeInput': {},
    lineHeight: '4rem',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardGreyOut: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#8b8b98',
  },
  cardActionArea: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: '#e1f3ff',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '20px',
  },
  mainbox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    border: '2px solid #f8f8f8',
    borderRadius: '5px',
    padding: '50px',
    minWidth: '57%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  divider: {
    marginTop: '150px',
  },
  close: {
    alignItems: 'flex-end',
  },
  closeButtonStyle: {
    position: 'absolute',
    top: '5px',
    right: '10px',
    fontSize: '20px',
    cursor: 'pointer',
  },
  warningMsg: {
    color: 'red',
    textAlign: 'center',
    margin: '10px',
    fontWeight: 'bold',
  },
  submit: {
    border: '1px solid #d0d0da !important',
  },
  typography: {
    textTransform: 'capitalize',
  },
  signatureNotAvl: {
    textTransform: 'capitalize',
    margin: '10px 0px',
    color: 'red',
  },
}));

export default function ActionAreaCard(props) {
  const classes = useStyles();
  const {
    viewAsUser:
    {
      email = '', firstname = '', lastname = '', id = '', phone = '',
    },
  } = props;
  const orderingUser = {
    portalUserId: id?.toString(),
    firstName: firstname,
    lastName: lastname,
    email,
    phone,
  };
  const downButton = React.useRef(null);
  const { newOrder } = props;
  const orderManagement = newOrder && newOrder.toJS();
  const { selectedCard } = orderManagement;
  const { delegee } = orderManagement;
  const { authorizingProvider } = orderManagement.schema.order || {};
  const physicianEmailExists = !!authorizingProvider?.email;
  const showESignEmailText = (props.viewAsUser.email !== authorizingProvider?.email && orderManagement?.isConsentExists === false);
  const submitHandler = async () => {
    const patientPhone = orderManagement?.schema?.order?.patient?.demographic?.phone;
    const patientEmail = orderManagement?.schema?.order?.patient?.demographic?.email;
    const patientZipCode = orderManagement?.schema?.order?.patient?.demographic?.address?.zipCode;
    const secondaryPhone = orderManagement?.schema?.order?.secondaryRecipient?.phone;
    const secondaryFax = orderManagement?.schema?.order?.secondaryRecipient?.fax;
    /*
      ECS-8647: keep only valid items in secondaryIcdCodes array.
      Long term solution is to never let the data go wrong in the first place.
    */
    if(orderManagement?.schema?.order?.diagnosis?.secondaryIcdCodes && orderManagement.schema.order.diagnosis.secondaryIcdCodes.length > 0){
      orderManagement.schema.order.diagnosis.secondaryIcdCodes = orderManagement.schema.order.diagnosis.secondaryIcdCodes.filter(i => i);
    }
    const tissuePhone = orderManagement?.schema?.order?.specimens?.tissueSpecimen?.phone;
    const tissueFax = orderManagement?.schema?.order?.specimens?.tissueSpecimen?.fax;
    const data = {
      order: {
        ...orderManagement.schema.order,
        orderingUser,
        patient: {
          ...orderManagement?.schema?.order?.patient,
          demographic: {
            ...orderManagement?.schema?.order?.patient?.demographic,
            phone: patientPhone?.trim().length === 10 ? patientPhone : undefined,
            email: isEmail?.test(patientEmail) ? patientEmail : undefined,
            address: {
              ...orderManagement?.schema?.order?.patient?.demographic?.address,
              zipCode: patientZipCode?.trim().length === 5 ? patientZipCode : undefined,
            },
          },
        },
        diagnosis: {
          ...orderManagement?.schema?.order?.diagnosis,
          trfDisplayName: undefined,
        },
        secondaryRecipient: {
          ...orderManagement?.schema?.order?.secondaryRecipient,
          phone: secondaryPhone?.trim().length === 10 ? secondaryPhone : undefined,
          fax: secondaryFax?.trim().length === 10 ? secondaryFax : undefined,
        },
        specimens: orderManagement?.schema?.order?.specimens ? {
          ...orderManagement?.schema?.order?.specimens,
          tissueSpecimen: orderManagement?.schema?.order?.specimens?.tissueSpecimen ? {
            ...orderManagement?.schema?.order?.specimens?.tissueSpecimen,
            phone: tissuePhone?.trim().length === 10 ? tissuePhone : undefined,
            fax: tissueFax?.trim().length === 10 ? tissueFax : undefined,
          } : undefined,
        } : undefined,
      },
    };
    if (!orderManagement.embaded && selectedCard === 1) {
      props.actions.onChange({ name: ['showEmbadedDailog'], value: !orderManagement.embaded });
    } else {
      await props.actions.completeSaveData(data, orderManagement.embaded).then((saveData) => {
        if (selectedCard === 2) {
          props.actions.printSignSend(saveData.data).then((printRes) => {
            const {
              portalOrderId,
              productFamily,
            } = saveData.data;
            const findata = {
              order: {
                portalOrderId,
                orderingUser,
                productFamily,
                esignature: printRes.data,
              },
            };
            props.actions.finalizeOrder(findata).then(() => {
              props.actions.downloadOrderForm(printRes.data.envelopeId).then(() => {
                window.location.href = '/new_orders';
              });
            });
          });
        } else if (selectedCard === 1 && orderManagement.embaded) {
          props.actions.eSignAndSubmit({ data: saveData.data, embaded: orderManagement.embaded, delegee, signeeEmail: props.viewAsUser?.email })
            .then((esign) => {
              const {
                portalOrderId,
                productFamily,
              } = saveData.data;
              const findata = {
                order: {
                  portalOrderId,
                  orderingUser,
                  productFamily,
                  esignature: esign.data,
                },
              };
              props.actions.updateSignature(esign.data.envelopeId).then(() => {
                props.actions.finalizeOrder(findata).then(() => {
                  window.location.href = esign.data.embeddedUrl;
                });
              });
            });
        }
      }).catch(() => props.actions.closeOederSpinner());
    }
  };

  const handleClose = () => {
    props.actions.onChange({ name: ['isSignAndSubmitOpen'], value: false });
    props.actions.onChange({ name: ['selectedCard'], value: null });
  };

  const handleBack = () => {
    props.actions.onChange({ name: ['isSignAndSubmitOpen'], value: false });
    props.actions.onChange({ name: ['isReviewOrderOpen'], value: true });
    props.actions.onChange({ name: ['selectedCard'], value: null });
  };

  return (
    <Modal
      open={orderManagement.isSignAndSubmitOpen}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.mainbox}>
        <CloseIcon color="error" onClick={handleClose} className={classes.closeButtonStyle} />
        <Typography gutterBottom variant="h6" component="div">
          How will you submit this order ?
        </Typography>
        <Box className={classes.box}>
          <Grid container spacing={2}>
            {(!physicianEmailExists && !orderManagement?.isConsentExists) ? (
              <Grid item md={6} xs={12}>
                <Card
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#e7e7e7',
                    pointerEvents: 'none',
                  }}
                >
                  <CardActionArea className={classes.cardActionArea} style={(selectedCard === 1) ? { background: '#e4f3ff' } : {}}>

                    <CardContent>
                      <Typography color="#1b437b" gutterBottom variant="h5" className={classes.typography} component="div">
                        <SendIcon color="primary" />
                        <br />
                        E-Sign &amp; Submit
                      </Typography>
                      <Typography variant="body2" color="red" className={classes.typography}>
                        Digital signature option is not available because
                        <br />
                        there is no email on file for ordering Physician.
                          &nbsp;
                          &nbsp;
                      </Typography>
                    </CardContent>

                    <CardActions>
                      <Button variant={selectedCard === 1 ? 'contained' : 'outlined'} disable={physicianEmailExists || orderManagement?.isConsentExists}>
                        {selectedCard === 1 ? 'Selected' : 'Select'}
                      </Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            ) : (
              <Grid item md={6} xs={12}>
                <Card
                  sx={classes.card}
                  onClick={() => props.actions.onChange({ name: ['selectedCard'], value: 1 })}
                >
                  <CardActionArea className={classes.cardActionArea} style={(selectedCard === 1) ? { background: '#e4f3ff' } : {}}>

                    <CardContent>
                      <Typography color="#1b437b" gutterBottom variant="h5" className={classes.typography} component="div">
                        <SendIcon color="primary" />
                        <br />
                        E-Sign &amp; Submit
                      </Typography>
                      <Typography variant="body2" color="text.primary" className={classes.typography}>
                        Use Docusign
                        {' '}
                        <span style={{ textTransform: 'none' }}>to electronically sign and submit the order</span>
                        <br />
                        {' '}
                        &nbsp;
                        &nbsp;
                      </Typography>
                    </CardContent>

                    <CardActions>
                      <Button variant={selectedCard === 1 ? 'contained' : 'outlined'} disable={physicianEmailExists || orderManagement?.isConsentExists}>
                        {selectedCard === 1 ? 'Selected' : 'Select'}
                      </Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <Card className={classes.card} onClick={() => props.actions.onChange({ name: ['selectedCard'], value: 2 })}>
                <CardActionArea className={classes.cardActionArea} style={(selectedCard === 2) ? { background: '#e4f3ff' } : {}}>
                  <CardContent>
                    <Typography color="#1b437b" gutterBottom variant="h5" className={classes.typography} component="div">
                      <PrintIcon color="primary" />
                      <br />
                      Print, Sign &amp; Send
                    </Typography>
                    <Typography variant="body2" color="text.primary" className={classes.typography}>
                      print
                      {' '}
                      <span style={{ textTransform: 'none' }}>
                        order and sign the paper copy to be
                        <br />
                        included with the sample
                        to send to Guardant Health.
                      </span>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button variant={selectedCard === 2 ? 'contained' : 'outlined'}>
                      {selectedCard === 2 ? 'Selected' : 'Select'}
                    </Button>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <div style={{ display: selectedCard ? 'block' : 'none' }}>
          <div style={{ display: selectedCard === 1 ? 'block' : 'none' }}>
            <Typography variant="body2" fontWeight="600" sx={{ my: '10px' }} style={{ display: showESignEmailText ? 'block' : 'none' }}>Important</Typography>
              <div style={{ display: showESignEmailText ? 'block' : 'none' }} >
                Selecting E-Sign &amp; Submit will send an email to the physician requesting
                order review and e-signature
              </div>
          </div>
          <div className={classes.warningMsg} sx={{ my: '10px' }}>
            WARNING: Once Submitted, no further changes can be made using this tool.
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.footer}>
          <Button variant="outlined" onClick={handleBack}>Back</Button>
          <Button disabled={!selectedCard} className={classes.submit} variant="contained" onClick={submitHandler}>Submit</Button>
        </div>
        <a ref={downButton} href={orderManagement.pdfFile}> </a>

      </Box>
    </Modal>
  );
}

ActionAreaCard.propTypes = {
  newOrder: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  viewAsUser: PropTypes.object.isRequired,
  actions: PropTypes.objectOf({
    action: PropTypes.objectOf({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};
