import React from 'react';
import PropTypes from 'prop-types';

import styles from './status-message-container.module.scss';
import { Table } from '../table';

const IPropTypes = {
  messages: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

const defaultProps = {
  messages: null,
};

const columns = ['Test', 'Status'];

const StatusMessageContainer = ({ messages }) => {
  if (!messages || !messages.length) {
    return null;
  }

  const rows = messages.map(([name, value]) => ({
    key: name,
    name,
    values: [value],
  }));

  return (
    <div className={styles.container}>
      <Table
        tableTitle="Testing Progress"
        columns={columns}
        rows={rows}
        withColorCell={false}
      />
    </div>
  );
};

StatusMessageContainer.propTypes = IPropTypes;
StatusMessageContainer.defaultProps = defaultProps;

export { StatusMessageContainer };
