import { constants } from '../../config/constants';

const { COMPLETE, INCOMPLETE } = constants.printModal.infoCompleteness;

class EarlyStageClinicalHistoryInfo {
  constructor(params) {
    this.chemotherapy = params.chemotherapy;
    this.evidenceOfRecurrence = params.evidenceOfRecurrence;
    this.mrdSurveillance = params.mrdSurveillance;
  }

  isComplete = () => !!(this.chemotherapy && this.evidenceOfRecurrence && this.mrdSurveillance);

  formatForPrintModal = () => {
    const isCompleted = this.isComplete();

    return {
      title: 'CLINICAL HISTORY',
      info: isCompleted ? COMPLETE : INCOMPLETE,
      isCompleted,
    };
  }
}

export { EarlyStageClinicalHistoryInfo };
