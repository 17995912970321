import { connect } from 'react-redux';
import { GuardantRevealOrderingCollapsed } from './guardant-reveal-ordering-collapsed';

const mapStateToProps = ({
  orders: { activeOrder: { guardantRevealOrderingInfo, formErrors } },
}) => ({
  highlightRequiredFields: formErrors.showMissingRequiredFields,
  ...guardantRevealOrderingInfo,
});

const ConnectedGuardantRevealOrderingCollapsed = connect(
  mapStateToProps,
)(GuardantRevealOrderingCollapsed);

export { ConnectedGuardantRevealOrderingCollapsed as GuardantRevealOrderingCollapsed };
