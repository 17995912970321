import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './patient-tests-dropdown.module.scss';
import Dropdown from '../../orders/Dropdown';

const IPropTypes = {
  selectedDropdownName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
  })).isRequired,
};

const generateOptionList = options => (
  options.map((request) => {
    const title = request.displayName;
    return { title, id: title, key: request.redirectTo };
  })
);

const PatientTestsDropdown = ({ selectedDropdownName, options }) => {
  const handleReportChange = useCallback((dropdownTitle, dropdownKey) => {
    if (dropdownTitle !== selectedDropdownName) {
      window.location = dropdownKey;
    }
  }, [selectedDropdownName]);

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.title}>
        TESTS
      </div>

      <Dropdown
        list={generateOptionList(options)}
        defaultId={selectedDropdownName}
        defaultValue={selectedDropdownName}
        resetThenSet={handleReportChange}
        arrowClass={styles.arrowClass}
        itemLabelClass={styles.itemLabelClass}
      />
    </div>
  );
};


PatientTestsDropdown.propTypes = IPropTypes;

export { PatientTestsDropdown };
