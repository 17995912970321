import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { AdminManageStudyManagerSearch } from '../manage-study-manager-search';
import { NewStudyManagerSignup } from '../study-manager-signup';
import { StudyManagerUpdate } from '../study-manager-update';
import { store } from '../../../store';
import styles from './manage-study-manager.module.scss';

const IPropTypes = {
  internalUsersSsoEnabled: PropTypes.bool.isRequired,
  studyManagerHasMultipleProjects: PropTypes.bool.isRequired,
};

class AdminManageStudyManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      selected: false,
      searchInput: '',
      errorText: '',
      futurePage: null,
      email: '',
      id: '',
      display: false,
    };
    this._cleanModal = this._cleanModal.bind(this);
    this.switchRender = this.switchRender.bind(this);
    this._nextOnClick = this._nextOnClick.bind(this);
  }

  updateSearchInput = (input) => {
    this.setState({
      searchInput: input,
      selected: false,
      errorText: '',
    });
  }

  processResponse = (response) => {
    const email = this.state.searchInput;
    switch (response.data.type) {
      case 'existing_user':
        this.setState({
          errorText: 'User already exists in a different role',
        });
        break;
      case 'study_manager':
        this.setState({
          selected: true,
          email,
          futurePage: 2,
          id: response.data.id,
        });
        break;
      default:
        this.setState({
          selected: true,
          email,
          futurePage: 3,
        });
    }
    window.hideSpinner();
  }

  switchRender = (n) => {
    this.setState({
      page: n,
      selected: false,
      searchInput: '',
      errorText: '',
      futurePage: undefined,
    });
    if (n !== 1) {
      window.showSpinner();
    } else {
      this.setState({
        display: false,
      });
    }
  }

  _cleanModal() {
    this.setState({
      email: '',
      searchInput: '',
      errorText: '',
      selected: false,
      display: false,
    });
  }

  _nextOnClick() {
    this.setState({ display: true });
    this.switchRender(this.state.futurePage);
  }

  content() {
    return (
      <div className={styles.content}>
        <div className={styles.emailErrors}>{this.state.errorText}</div>
        <p>SEARCH (email address)</p>
        <AdminManageStudyManagerSearch
          input={this.state.searchInput}
          callback={this.processResponse}
          inputCallback={this.updateSearchInput}
          internalUsersSsoEnabled={this.props.internalUsersSsoEnabled}
        />
        <button
          type="button"
          disabled={!this.state.selected}
          className={styles.button}
          onClick={this._nextOnClick}
        >
          NEXT
        </button>
      </div>
    );
  }

  render() {
    const content = this.content();
    const outerClassNames = [styles.modalContainerOuter, styles.manageStudyManager, 'manageStudyManagerModal'].join(' ');
    const {
      page,
      email,
      display,
    } = this.state;
    return (
      <>
        <Provider store={store}>
          {
            page === 1
            && (
              <div className={outerClassNames} id="manageStudyManagerModal" style={{ display: display ? 'block' : 'none' }}>
                <div className={[styles.modalContainerInner, styles.alignTop].join(' ')}>
                  <div className={styles.modalContent}>
                    <div>
                      <i className={[styles.closeIcon, 'modal-close-study-manager', 'fa fa-close'].join(' ')} onClick={this._cleanModal} />
                      <div className={styles.header}>
                        Manage Study Managers
                      </div>
                      {content}
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            page === 2
              && (
                <StudyManagerUpdate
                  {...this.props}
                  id={this.state.id}
                  callback={this.switchRender}
                  email={email}
                  display={display}
                  closeModal={this.switchRender}
                />
              )
          }
          {
            page === 3
              && (
                <NewStudyManagerSignup
                  {...this.props}
                  callback={this.switchRender}
                  email={email}
                  display={display}
                  closeModal={this.switchRender}
                />
              )
          }
        </Provider>
      </>
    );
  }
}
AdminManageStudyManager.propTypes = IPropTypes;
export { AdminManageStudyManager };
