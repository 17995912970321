import React from 'react';
import PropTypes from 'prop-types';

import { NewTestRequisition } from '../new-test-requisition';
import { constants } from '../../../../config/constants';

import {
  g360cdxLogo, g360Logo, mrLogo, tissueLogo, lunarLogo,
} from '../../../../assets/images/logos';
import styles from './new-tests-buttons-container.module.scss';

const { productFamilies } = constants.etrfOrdering;

const IPropTypes = {
  disable: PropTypes.bool.isRequired,
};

const g360FamilyLogos = disabledStyle => (
  <div className={[styles.logosGroup, disabledStyle ? styles.disabled : ''].join(' ')}>
    <img className={styles.leftGuardantLogo} alt="" src={g360cdxLogo} />
    <img className={styles.leftGuardantLogo} alt="" src={tissueLogo} />
    <img className={styles.leftGuardantLogo} alt="" src={g360Logo} />
    <img className={styles.leftGuardantLogo} alt="" src={mrLogo} />
  </div>
);

const revealFamilyLogo = disabledStyle => (
  <img className={[styles.rightGuardantLogo, disabledStyle ? styles.disabled : ''].join(' ')} alt="" src={lunarLogo} />
);

const testOptionsTitle360 = 'Advanced Stage Cancer: Therapy Selection & Response Monitoring';

const testOptionsTitleReveal = 'Early Stage Colorectal Cancer: Detection of Minimal Residual Disease for Post-Surgical & Surveillance Monitoring';

const NewTestButtonsGroup = ({
  disable, productFamily, logosGroup, testOptionsTitle,
}) => (
  <NewTestRequisition
    disabled={disable}
    productFamily={productFamily}
    logosGroup={logosGroup}
    testOptionsTitle={testOptionsTitle}
  />
);

NewTestButtonsGroup.propTypes = {
  disable: PropTypes.bool.isRequired,
  productFamily: PropTypes.string.isRequired,
  logosGroup: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  testOptionsTitle: PropTypes.string.isRequired,
};

const NewTestsButtonsContainer = ({ disable }) => (
  <div className={styles.container}>
    <div className={styles.leftColumn}>
      <NewTestButtonsGroup
        disable={disable}
        productFamily={productFamilies.g360}
        logosGroup={g360FamilyLogos(disable)}
        testOptionsTitle={testOptionsTitle360}
      />
    </div>

    <div className={styles.rightColumn}>
      <NewTestButtonsGroup
        disable={disable}
        productFamily={productFamilies.reveal}
        logosGroup={revealFamilyLogo(disable)}
        testOptionsTitle={testOptionsTitleReveal}
      />
    </div>
  </div>
);

NewTestsButtonsContainer.propTypes = IPropTypes;

export { NewTestsButtonsContainer };
