import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { OrdersDashboardEndpoints } from '../../../../networking/endpoints';

import styles from './test-requisition-button.module.scss';
import { getToken, isInternalUser } from '../../../../networking/controllers/helper';

const IPropTypes = {
  productFamily: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const requisitionLinkText = 'Patient Signature Page';

const TestRequisitionButton = ({ productFamily, disabled }) => {
  const requisBtnClassNames = [styles.link, disabled ? styles.disabled : ''].join(' ');
  const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
  const downloadPDF = (event) => {
    event.preventDefault();
    if (!disabled) {
      axios.get(`${OrdersDashboardEndpoints.BLANK_SIGNATURE_PAGE}?product_family=${productFamily}`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          window.open(url, '_blank');
        });
    }
    return false;
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={requisBtnClassNames}
      target="_blank"
      rel="noopener noreferrer"
      disabled={disabled}
      href="#"
      onClick={downloadPDF}
    >
      <span className={styles.title}>{requisitionLinkText}</span>
      <i className={`fa fa-download ${styles.downloadIcon}`} />
    </a>
  );
};

TestRequisitionButton.propTypes = IPropTypes;
TestRequisitionButton.defaultProps = defaultProps;

export { TestRequisitionButton };
