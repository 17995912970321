import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  onChange,
  onTabChange,
  nextsaveData,
  setError,
  setReveal,
  getCancerList,
  getSecoundaryList,
  completeSaveData,
  saveCurrentData,
  getAllStates,
  getAccountNumbers,
  getPhysiciansNumbers,
  printSignSend,
  finalizeOrder,
  updateSignature,
  downloadOrderForm,
  getAllcancer,
  eSignAndSubmit,
  getTestSelections,
  getClinicalHistory360,
  getClinicalHistoryReveal,
  updateCurrentData,
  getUserAccountsData,
  ValidateIcdCodes,
  closeOederSpinner,
  updateDelegeeFlag,
  getEmbadedSigneeStatus,
} from './actions/NewOrderAction';
import NewOrder from './NewOrder';
import { openAlertWindow } from './alert-modal/actions';
import { getOrder } from './selectors';

const mapStateToProps = (state) => {
  const order = getOrder(state);
  return {
    newOrder: state.newOrder,
    roleBasedAuthorisation: state.ordersDashboard?.get('roleBasedAuthorisation'),
    order,
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onChange,
      onTabChange,
      nextsaveData,
      setError,
      getCancerList,
      getSecoundaryList,
      completeSaveData,
      saveCurrentData,
      setReveal,
      getAllStates,
      getAccountNumbers,
      getPhysiciansNumbers,
      printSignSend,
      finalizeOrder,
      updateSignature,
      downloadOrderForm,
      eSignAndSubmit,
      getAllcancer,
      getTestSelections,
      getClinicalHistory360,
      getClinicalHistoryReveal,
      updateCurrentData,
      getUserAccountsData,
      openAlertWindow,
      ValidateIcdCodes,
      closeOederSpinner,
      updateDelegeeFlag,
      getEmbadedSigneeStatus,
    },
    dispatch,
  ),
});
// eslint-disable-next-line max-len
export const ConnectedNewOrderContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(NewOrder));
export default ConnectedNewOrderContainer;
