import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../../assets/stylesheets/etrf/buttons.module.scss';

const IPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  type: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  type: 'primary',
  className: '',
  onClick: () => {},
  disabled: false,
};

const Button = ({
  children, type, className, onClick, disabled,
}) => {
  const classNames = [
    styles.dynamicSizeButton,
    type === 'primary' ? styles.blueButton : styles.greyButton,
    className,
  ].join(' ');

  return (
    <button
      disabled={disabled}
      type="button"
      className={classNames}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = IPropTypes;
Button.defaultProps = defaultProps;

export { Button };
