import HttpService from '../services/HttpService';
import { StudyManagerEndpoints } from '../endpoints';
import { StudyManagerSerializer } from '../serializers/study-manager';
import { getToken, isInternalUser } from './helper';

const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
const headers = {
  Authorization: `Bearer ${getToken()}`,
  ...extHeader,
};
export class StudyManagerController {
  static getEmailValidation = email => (
    HttpService.get(StudyManagerEndpoints.GET_EMAIL_VALIDATION, {
      params: { email },
      headers,
    })
  )

  static getStudyManagerInfo = async (id) => {
    const response = await HttpService.get(`${StudyManagerEndpoints.GET_STUDY_MANAGER_INFO}/${id}`, {
      headers,
    });

    return StudyManagerSerializer.deserialize(response.data.data);
  }

  static getAllProjectIds = () => (
    HttpService.get(StudyManagerEndpoints.GET_ALL_PROJECT_IDS, {
      headers,
    }).then(response => response.data)
  )

  static createStudyManager = async (
    firstName,
    lastName,
    email,
    projectIds,
    sendInvitationEmail,
    sendCourtesyEmail,
    projects,
  ) => (
    HttpService.post(StudyManagerEndpoints.STUDY_MANAGER_SIGNUP, {
      firstName,
      lastName,
      type: 'StudyManager',
      email,
      projectIds,
      project_id: projectIds[0],
      projects,
      sendInvitationEmail,
      sendCourtesyEmail,
    }, {
      headers,
    })
  )

  static updateStudyManager = (
    id,
    firstName,
    lastName,
    email,
    projectIds,
    sendInvitationEmail,
    sendCourtesyEmail,
  ) => (
    HttpService.put(`${StudyManagerEndpoints.EDIT_STUDY_MANAGER}/${id}`, {
      data: {
        firstName,
        lastName,
        type: 'StudyManager',
        userId: id,
        email,
        projectIds,
      },
      sendInvitationEmail,
      sendCourtesyEmail,
    }, {
      headers,
    })
  )
}
