import { ReleaseToPatientSerializer } from './release-to-patient';

export class AlertSerializer {
  static deserialize = alert => (
    alert && {
      autoReleaseDate: alert.auto_release_date,
      unreleasedReportsForPatient: alert.unreleased_reports_for_patient,
      autoReleaseDateTimeAgo: alert.auto_release_date_time_ago,
      latestReleasedReportDateTimeAgo: alert.latest_released_report_date_time_ago,
      showAlert: alert.show_alert,
      releaseToPatient: ReleaseToPatientSerializer.deserialize(alert.release_to_patient),
    }
  );
}
