import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import SitesViewContainer from './SitesViewContainer';
import { getUserInfo } from '../helpers';
import Spinner from '../common/utils/spinner/Spinner';

const userRoles = getUserInfo('Site');
const SitesRootContainer = () => (
  <Provider store={store}>
    <SitesViewContainer roles={userRoles} />
    <Spinner />
  </Provider>
);
export default SitesRootContainer;
