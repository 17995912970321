import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Toolbar,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Search } from '@mui/icons-material';
import _ from 'loadsh';
import Immutable from 'immutable';
import ApplicationMessage from '../common/components/elements/ApplicationMessage';
import ConfirmModalWindow from '../common/components/elements/ConfirmModalWindow';
import Controls from '../common/components/forms/controls';
import ModalPopup from '../common/components/elements/ModalPopup';
import Pagination from '../common/components/elements/Table/Pagination';
import Table from '../common/Table';
import TableBody from '../common/TableBody';
import TableHeader from '../common/TableHeader';
import SiteAssignmentForm from './SiteAssignmentForm';

const useStyles = makeStyles(theme => createStyles({
  root: {
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#ffffff',
  },
  searchInput: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#1A5EA7',
    boxShadow: 'none',
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '20px',
    marginLeft: '10px',
  },
}));

const SiteAssignmentView = (props) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => {},
  });
  const [formData, setFormData] = useState(null);
  const [title, setTitle] = useState('');
  const classes = useStyles();
  const {
    siteAssignment,
    userPermissions,
    actions: {
      getSiteUsersList,
      changePagination,
      onChange,
      editSite,
      getSitesList,
    },
  } = props;
  const siteAssignmentList = siteAssignment.toJS().siteAssignmentList || [];
  const notify = siteAssignment.toJS().notify || {};
  const pagination = siteAssignment.toJS().pagination || 5;
  const siteAssignmentManagement = siteAssignment.toJS() || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (siteAssignmentManagement.reload) {
      setConfirmDialog({
        isOpen: false,
        title: '',
        subTitle: '',
        onConfirm: () => {},
      });

      getSiteUsersList(pagination);
      onChange({ key: 'reload', value: false });
      // eslint-disable-next-line no-unused-expressions
      siteAssignmentManagement.reload && setFormData(null);
    }
  }, [siteAssignmentManagement.reload]);
  useEffect(() => {
    if (siteAssignmentManagement.isOpen) {
      setConfirmDialog({
        isOpen: false,
        title: '',
        subTitle: '',
        onConfirm: () => {},
      });
      onChange({ key: 'isOpen', value: false });
    }
  }, [siteAssignmentManagement.isOpen]);

  const setNotify = (data) => {
    onChange({ key: 'notify', value: { ...data } });
  };
  useEffect(() => {
    onChange({ key: 'reload', value: true });
  }, []);
  const editClickHandler = (row) => {
    getSitesList('');
    onChange({ key: 'openPopup', value: true });
    const {
      uuid, firstName, lastName, email, sites,
    } = row;
    setFormData({
      uuid,
      firstName,
      lastName,
      email,
      sites,
    });
    setTitle('Edit Site Assignment');
  };

  const submitHandler = (values) => {
    const data = values.sites.map(x => x.id);
    editSite({ uuid: values.uuid, sites: data });
  };
  const delayedQuery = _.debounce(q => getSiteUsersList(q), 500);
  const handleSearch = (e) => {
    delayedQuery({ sortBy: e.target.value });
  };
  const userPermission = {
    Edit: userPermissions && userPermissions.includes('UPDATE'),
  };
  const actions = [{ action: 'Edit', handleAction: editClickHandler }];
  const handlePagination = (e, num) => {
    e.preventDefault();
    getSiteUsersList(num);
    changePagination(num);
  };
  const headers = [
    { name: 'firstName', type: 'text' },
    { name: 'lastName', type: 'text' },
    { name: 'email', type: 'text' },
    { name: 'sites', type: 'array' },
  ];
  return (
    <React.Fragment>
      <div className={classes.title}>Site Assignment</div>
      <div id="siteAssignmentContainer" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Controls.Input
            label="search site assignment"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <Table>
              {!isMobile && (
              <TableHeader
                headers={[...headers]}
                {...{
                  rows: siteAssignmentList || [],
                  actions,
                }}
              />
              )}
              <TableBody
                headers={headers}
                {...{ rows: siteAssignmentList || [], actions }}
                userPermission={userPermission}
              />
            </Table>
            <Pagination
              count={siteAssignmentManagement.totalRecords}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNo}
              handleChangePage={(e, val) => handlePagination(e, { ...pagination, pageNo: val })
            }
              handleChangeRowsPerPage={e => handlePagination(e, {
                ...pagination,
                pageSize: e.target.value,
                pageNo: 0,
              })
            }
            />
          </Grid>
        </Grid>
        <ModalPopup
          title={title}
          openPopup={siteAssignmentManagement.openPopup}
          setOpenPopup={val => onChange({ key: 'openPopup', value: val })}
        >
          <SiteAssignmentForm
            formData={formData}
            submitHandler={submitHandler}
            sitesList={siteAssignmentManagement.sitesList}
            edit={title === 'Edit Site Assignment'}
            getSitesList={getSitesList}
          />
        </ModalPopup>
        <ConfirmModalWindow
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <ApplicationMessage notify={notify} setNotify={setNotify} />
      </div>
    </React.Fragment>
  );
};

export default SiteAssignmentView;
SiteAssignmentView.propTypes = {
  siteAssignment: PropTypes.object,
  actions: PropTypes.object.isRequired,
  userPermissions: PropTypes.array,
};
SiteAssignmentView.defaultProps = {
  siteAssignment: Immutable.fromJS({}),
  userPermissions: [],
};
