import React from 'react';
import PropTypes from 'prop-types';
import styles from './notification-modal.module.scss';

const IPropTypes = {
  onConfirmCallback: PropTypes.func.isRequired,
  errorTitle: PropTypes.string.isRequired,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  centeredButtonText: PropTypes.string,
  toggleConfirmationModal: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

const defaultProps = {
  leftButtonText: '',
  rightButtonText: '',
  centeredButtonText: '',
};

const NotificationModal = ({
  onConfirmCallback, toggleConfirmationModal,
  errorTitle, content, centeredButtonText,
  leftButtonText, rightButtonText,
}) => (
  <div className={styles.modalContainerOutter}>
    <div className={styles.modalContainerInner}>
      <div className={styles.modalContent}>
        <div onClick={toggleConfirmationModal}><i className="fa fa-close modal-close" /></div>
        <div>
          <div className={styles.header}>
            {errorTitle}
          </div>
          <div className={styles.content}>
            <div className={styles.discardWarningContainer}>
              <i className="discard-warning-container__icon fa fa-exclamation-triangle warning-message icon" />
            </div>
            <div className={styles.discardWarningContent}>
              {content}
            </div>
            { leftButtonText && rightButtonText
              && (
                <div className={styles.buttonsRow}>
                  <button
                    type="button"
                    className="btn__color-blue btn__size-dynamic"
                    onClick={toggleConfirmationModal}
                  >
                    <i className="fa fa-chevron-left" />
                    {leftButtonText}
                  </button>
                  <button
                    type="button"
                    className="btn__color-blue btn__size-dynamic"
                    onClick={onConfirmCallback}
                  >
                    {rightButtonText}
                  </button>
                </div>
              )
            }
            { !(leftButtonText && rightButtonText) && centeredButtonText
              && (
                <div className={styles.buttonRow}>
                  <button
                    type="button"
                    className="btn__color-blue btn__size-dynamic"
                    onClick={toggleConfirmationModal}
                  >
                    {centeredButtonText}
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);

NotificationModal.propTypes = IPropTypes;
NotificationModal.defaultProps = defaultProps;

export { NotificationModal };
