import React from 'react';
import PropTypes from 'prop-types';

import styles from './request-download-menu.module.scss';

const IPropTypes = {
  accessKlass: PropTypes.string,
  menuKlass: PropTypes.string,
  contentKlass: PropTypes.string,
  collectionDate: PropTypes.string,
  handleDashboardDownload: PropTypes.func,
  onTitleClick: PropTypes.func,
  requestId: PropTypes.string.isRequired,
  withTitle: PropTypes.bool,
  urls: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string,
    link: PropTypes.string,
  })),
  canDownloadReport: PropTypes.bool,
};


const defaultProps = {
  accessKlass: '',
  menuKlass: '',
  contentKlass: '',
  collectionDate: '',
  handleDashboardDownload: () => {},
  onTitleClick: () => {},
  urls: [],
  canDownloadReport: false,
  withTitle: true,
};

const DownloadLinkPropTypes = {
  accessKlass: PropTypes.string,
  handleDashboardDownload: PropTypes.func,
  reportUrl: PropTypes.string,
  title: PropTypes.string,
  canDownloadReport: PropTypes.bool,
};

const defaultPropsDownloadLink = {
  accessKlass: '',
  handleDashboardDownload: () => {},
  reportUrl: null,
  title: '',
  canDownloadReport: false,
};

export const DownloadLink = ({
  accessKlass, handleDashboardDownload,
  reportUrl, title, canDownloadReport,
}) => (reportUrl
  ? (
    <li className={`${canDownloadReport ? styles.linkRow : ''}`}>
      {
        canDownloadReport
          ? (
            <a
              className={`${styles.link} ${accessKlass}`}
              href={reportUrl}
              onClick={handleDashboardDownload}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
          )
          : (
            <span className={styles.disabled}>
              {title}
            </span>
          )
      }
    </li>
  )
  : null
);

const DownloadTitlePropTypes = {
  collectionDate: PropTypes.string,
  onTitleClick: PropTypes.func,
};

const defaultPropsDownloadTitle = {
  collectionDate: '',
  onTitleClick: () => {},
};

const DownloadTitle = ({
  collectionDate, onTitleClick,
}) => (collectionDate
  ? (
    <div className={styles.title}>
      {collectionDate}
        &nbsp;
    </div>
  )
  : (
    <div onClick={onTitleClick} className={styles.downloadTitle}>
      <span className={styles.titleSpan}>Download:</span>
      <div className={`fa fa-download ${styles.downloadIcon}`}>
        {' '}
      </div>
    </div>
  )
);

const RequestDownloadMenu = React.forwardRef((props, ref) => {
  const {
    requestId, handleDashboardDownload, accessKlass, menuKlass, contentKlass,
    urls, collectionDate, canDownloadReport, onTitleClick, withTitle,
  } = props;
  const propsToPass = { ...props };
  delete propsToPass.urls;
  return (
    <ul
      ref={ref}
      className={`${styles.dropdownUl} ${menuKlass}`}
      id={`download-menu-${requestId}`}
    >
      {
        withTitle
        && (
          <li>
            <div
              className={styles.titleButton}
              onClick={handleDashboardDownload}
            >
              <DownloadTitle
                collectionDate={collectionDate}
                accessKlass={accessKlass}
                onTitleClick={onTitleClick}
              />
            </div>
          </li>
        )
      }
      <div className={contentKlass}>
        {
        urls.map(url => (
          <DownloadLink
            title={url.displayName}
            key={url.link}
            reportUrl={url.link}
            canDownloadReport={canDownloadReport}
            {...propsToPass}
          />
        ))
        }
      </div>
    </ul>
  );
});

RequestDownloadMenu.propTypes = IPropTypes;
RequestDownloadMenu.defaultProps = defaultProps;

DownloadLink.propTypes = DownloadLinkPropTypes;
DownloadLink.defaultProps = defaultPropsDownloadLink;

DownloadTitle.propTypes = DownloadTitlePropTypes;
DownloadTitle.defaultProps = defaultPropsDownloadTitle;

export { RequestDownloadMenu };
