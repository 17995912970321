import React from 'react';
import PropTypes from 'prop-types';

import { DateInput } from '../../../generic/DateInput';
import { OnlineOrderingFieldsLimits } from '../../../../config/input-character-limit';

const ITissueSpecimenRetrievalPropTypes = {
  tissueSpecimenRetrieval: PropTypes.shape({
    pathologyLabName: PropTypes.string,
    pathologyLabPhone: PropTypes.string,
    pathologyLabFax: PropTypes.string,
    tissueCollectionDate: PropTypes.string,
  }).isRequired,
  saveTestSelectionInfo: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    sectionSubheading: PropTypes.string,
    sectionRow: PropTypes.string,
    sectionColumn: PropTypes.string,
    fieldLabel: PropTypes.string,
    field: PropTypes.string,
  }).isRequired,
};

const TissueSpecimenRetrieval = ({ styles, tissueSpecimenRetrieval, saveTestSelectionInfo }) => {
  const storeTissueSpecimenRetrieval = field => (e) => {
    saveTestSelectionInfo({
      tissueSpecimenRetrieval: {
        ...tissueSpecimenRetrieval,
        [field]: e.target.value,
      },
    }, true);
  };

  const storeTissueCollectionDate = (value) => {
    saveTestSelectionInfo({
      tissueSpecimenRetrieval: {
        ...tissueSpecimenRetrieval,
        tissueCollectionDate: value,
      },
    }, true);
  };

  return (
    <>
      <h6 className={styles.sectionSubheading}>
        Tissue Specimen Retrieval
      </h6>
      <div className={styles.sectionRow}>
        <div className={styles.sectionColumn}>
          <span className={styles.fieldLabel}>Pathology Lab Name</span>
          <input
            className={styles.field}
            type="text"
            value={tissueSpecimenRetrieval.pathologyLabName || ''}
            onChange={storeTissueSpecimenRetrieval('pathologyLabName')}
            maxLength={OnlineOrderingFieldsLimits.pathologyLabNameMaxLength}
          />
        </div>
        <div className={styles.sectionColumn}>
          <span className={styles.fieldLabel}>Phone</span>
          <input
            className={styles.field}
            type="text"
            placeholder="(###) ###-####"
            value={tissueSpecimenRetrieval.pathologyLabPhone || ''}
            onChange={storeTissueSpecimenRetrieval('pathologyLabPhone')}
            maxLength={OnlineOrderingFieldsLimits.pathologyLabPhoneMaxLength}
          />
        </div>
        <div className={styles.sectionColumn}>
          <span className={styles.fieldLabel}>Fax</span>
          <input
            className={styles.field}
            type="text"
            placeholder="(###) ###-####"
            value={tissueSpecimenRetrieval.pathologyLabFax || ''}
            onChange={storeTissueSpecimenRetrieval('pathologyLabFax')}
            maxLength={OnlineOrderingFieldsLimits.pathologyLabPhoneMaxLength}
          />
        </div>
        <div className={styles.sectionColumn}>
          <span className={styles.fieldLabel}>Tissue Collection Date</span>
          <DateInput
            className={styles.field}
            value={tissueSpecimenRetrieval.tissueCollectionDate || ''}
            onInputChange={storeTissueCollectionDate}
          />
        </div>
      </div>
    </>
  );
};

TissueSpecimenRetrieval.propTypes = ITissueSpecimenRetrievalPropTypes;

export { TissueSpecimenRetrieval };
