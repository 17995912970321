import PropTypes from 'prop-types';
import React from 'react';

import styles from './order-form-header.module.scss';

const IPropTypes = {
  logoSrc: PropTypes.string,
};

const defaultProps = {
  logoSrc: null,
};

const OrderFormHeader = ({ logoSrc }) => (
  <div className={styles.container}>
    {logoSrc && <img className={styles.guardantLogo} alt="" src={logoSrc} />}
    <span className={styles.title}>Test Requisition & Statement of Medical Necessity</span>
  </div>
);

OrderFormHeader.propTypes = IPropTypes;
OrderFormHeader.defaultProps = defaultProps;

export { OrderFormHeader };
