import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HttpService from '../../networking/services/HttpService';

function TutorialModal({ class_name: className, csrfToken }) {
  const [state, setState] = useState({ showLess: false, show: true });
  const HandleClick = () => {
    setState({ showLess: false, show: false });
    window.patientTutorialFinish(HttpService);
    window.setSessionKey(HttpService, csrfToken);
  };
  return (
    state.show && (
      <div className={`modal__container-outer tutorial__modal ${className}`}>
        <div className="modal__container-inner">
          <div className="modal__content patient-tutorial">
            <div
              className="patient-tutorial__inner"
              style={{ maxHeight: '948px', overflow: 'auto' }}
            >
              <div className="tutorial__content">
                <div className="tutorial__header">Your Report</div>
                <div className="descriptive-1">
                  This site gives you access to your report to review with your
                  medical care team. We recommend you review your results with
                  your physician, as he or she is best equipped to describe how
                  it affects your care.
                </div>
                <div className="descriptive-2">
                  We hope you find this site useful. Please don’t hesitate to
                  send us feedback using the “Send Feedback” button. We want to
                  hear from you.
                </div>
                <div className="descriptive-3">
                  When is your report released to you?
                  {!state.showLess ? (
                    <div className="text-link-tutorial clickable" onClick={() => setState({ ...state, showLess: true })}>
                      Learn more &gt;
                    </div>
                  ) : (
                    <>
                      <div className="text-link-tutorial clickable" onClick={() => setState({ ...state, showLess: false })}>
                        Show less
                      </div>
                      <div className="collapsable-text-tutorial">
                        <p>
                          Option 1) Your physician manually releases your report
                          to you through the Guardant Provider Portal after
                          reviewing it with you
                        </p>
                        <p>
                          Option 2) If your physician does not manually release
                          your report to you, we automatically release your
                          report to you 16 days after we send it to your
                          physician
                        </p>
                        <p>
                          Option 3) If you contact Guardant Health Client
                          Services, we will manually release your report to you
                          upon your request
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="tutorial__footer">
                <div className="button-finish">
                  <div className="fa fa-caret-right" />
                  <div className="button-text" onClick={HandleClick}>
                    FINISH
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default TutorialModal;
TutorialModal.propTypes = {
  class_name: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
};
