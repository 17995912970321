import React from 'react';
import PropTypes from 'prop-types';

const RequestListEmpty = (props) => {
  const {
    requestCounts: { newRequests = 0, download = 0, inProgress = 0 },
    accessClass,
  } = props;
  const isDisabled = (newRequests === 0 && download === 0 && inProgress === 0) ? 'disabled disable_click' : '';

  return (
    <>
      <div className="requests-list-container-empty">
        <a className="dashboard__widget__tableview" href="/requests">
          <div className="dashboard__tableview">
            <a className={`dashboard__tableview-link ${isDisabled} ${accessClass}`} href="/requests">
              <div className="fa fa-table dashboard-table-icon">
                VIEW ALL REPORTS IN TABLE
              </div>
            </a>
          </div>
        </a>
      </div>
      <div className="request">
        <div className="request__data request__data--no-reports">
          No reports to show
        </div>
      </div>
    </>
  );
};
export default RequestListEmpty;
RequestListEmpty.propTypes = {
  requestCounts: PropTypes.object.isRequired,
  newRequests: PropTypes.number.isRequired,
  download: PropTypes.number.isRequired,
  inProgress: PropTypes.number.isRequired,
  accessClass: PropTypes.string.isRequired,
};
