/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmail } from '../admin/common/utils/Validation';

const options = {
  viewing_as_self: {
    option: [
      { value: 'account setup request', label: 'Account setup request' },
      { value: 'idea', label: 'Ideas' },
      { value: 'issue', label: 'Issues' },
      { value: 'other', label: 'Other' },
    ],
    user_type: 'portal',
  },
  physician_portal: {
    option: [
      { value: 'kit', label: 'Request for kits' },
      { value: 'report', label: 'Patient report' },
      { value: 'idea', label: 'Ideas' },
      { value: 'issue', label: 'Issues' },
      { value: 'other', label: 'Other' },
    ],
    user_type: 'provider',
  },
  patient_portal: {
    option: [
      { value: 'report', label: 'My report' },
      { value: 'idea', label: 'Ideas' },
      { value: 'issue', label: 'Issues' },
      { value: 'other', label: 'Other' },
    ],
    user_type: 'patient',
  },
  pharma_portal: {
    option: [
      { value: 'idea', label: 'Ideas' },
      { value: 'issue', label: 'Issues' },
      { value: 'other', label: 'Other' },
    ],
    user_type: 'pharma',
  },
};
const IPropTypes = {
  className: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  viewingAsSelf: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  patientPortal: PropTypes.any,
};
const defaultProps = {
  patientPortal: null,
};

const FeedbackForm = (props) => {
  const {
    className,
    setOpen,
    viewingAsSelf,
    user,
    patientPortal,
  } = props;
  const [state, setState] = useState({});
  const [sent, setRes] = useState(false);
  const [error, setError] = useState(null);
  const onSubmit = (e) => {
    e.preventDefault();
    if (!isEmail.test(state.email)) {
      setError('Please enter a valid email address.');
    } else if (!state.type || state.type.length === 0) {
      setError('Please select a feedback type');
    } else if (!state.message || state.message.length === 0) {
      setError('Please enter a message."');
    } else {
      const postUrl = className === 'non-user'
        ? '/submit_feedback_non_current_user'
        : '/submit_feedback_current_user';
      window.sendFeedback(
        { ...state, user_type: getOptions().user_type, url: postUrl },
        postUrl,
        setResponse,
      );
    }
  };
  const setResponse = (res) => {
    if (res.status === 200) {
      setRes(true);
      setState({});
      setOpen(false);
    }
  };
  const HandleChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getOptions = () => {
    if (viewingAsSelf || className === 'non-user') {
      return options.viewing_as_self;
    } if (user.physician_portal) {
      return options.physician_portal;
    } if (user.patient_portal || patientPortal) {
      return options.patient_portal;
    } if (user.pharma_portal) {
      return options.pharma_portal;
    } return options.viewing_as_self;
  };
  return (
    <form
      className="feedback-form submit_feedback_current_user"
      style={{ display: ' block' }}
    >
      <p className="help-block" />
      <p className="help-block-text">
        Please send us your feedback and questions, or call Client Services at
        {' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className='href="tel:1-855-698-8887"'>1 (855) 698-8887</a>
        .
      </p>
      <div className="feedback-errors">{error}</div>
      <select
        className="feedback-select form-control"
        required=""
        onChange={HandleChange}
        value={state.type}
        name="type"
      >
        <option value="">Select feedback type</option>
        {getOptions().option.map(x => (
          <option key={x.value} value={x.value}>{x.label}</option>
        ))}
      </select>
      <input
        type="email"
        name="email"
        id="email"
        placeholder="Your email address"
        required="required"
        className="feedback_form_email"
        onChange={HandleChange}
        value={state.email}
      />
      <textarea
        placeholder="Your questions or feedback."
        rows="6"
        name="message"
        onChange={HandleChange}
        value={state.message}
      />
      <p />
      <div className="row">
        <div className="col-xs-6">
          {!sent ? (
            <div
              className="feedback-btn submit-feedback feedback-form__left-btn"
              onClick={onSubmit}
            >
              SUBMIT
            </div>
          ) : (
            <div className="feedback-btn success-feedback feedback-form__left-btn">
              <i className="fa fa-check" />
              SENT
            </div>
          )}
        </div>
        <div className="col-xs-6">
          <div
            className="feedback-btn close-feedback"
            onClick={() => setOpen(false)}
          >
            CLOSE
          </div>
        </div>
      </div>
    </form>
  );
};
export default FeedbackForm;
FeedbackForm.propTypes = IPropTypes;
FeedbackForm.defaultProps = defaultProps;
