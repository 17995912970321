import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';
import { OrderFormHeader } from '../order-form-header';
import { PhysicianInformationContainer } from '../physician-information/physician-information-container';
import { PatientInformationContainer } from '../patient-information/patient-information-container';
import { DiagnosisConditionLateStageContainer } from '../diagnosis-condition-late-stage/diagnosis-condition-late-stage-container';
import { DiagnosisConditionEarlyStageContainer } from '../diagnosis-condition-early-stage/diagnosis-condition-early-stage-container';
import { TestSelectionInformationContainer } from '../test-selection-information/test-selection-information-container';
import { CurrentTherapyContainer } from '../current-therapy-information/current-therapy-container';
import ClinicalHistoryContainer from '../relevant_clinical_history/ClinicalHistoryContainer';
import { ClinicalHistoryEarlyStageContainer } from '../relevant-clinical-history-early-stage/clinical-history-early-stage-container';
import { BillingInformationContainer } from '../billing-information/billing-information-container';
import { PrintModalContainer } from '../print-modal-container';
import { OrdersActionContainer } from '../orders-action-container';
import { DiscardOverlay } from '../discard-overlay';
import BannerMessage from '../trf_button/BannerMessage';
import { constants } from '../../../config/constants';
import styles from './orders-container.module.scss';
import { GuardantRevealOrderingContainer } from '../guardant-reveal-ordering/guardant-reveal-ordering-container';
import { OrdersController } from '../../../networking/controllers/orders';


const IPropTypes = {
  showErrorBanner: PropTypes.bool.isRequired,
  errorBannerMsg: PropTypes.string,
  canOnlyDemoOrders: PropTypes.bool.isRequired,
  hideErrorBanner: PropTypes.func.isRequired,
  onGhiAccountsError: PropTypes.func.isRequired,
  showConfirmationModal: PropTypes.bool,
  showMissingRequiredFields: PropTypes.bool.isRequired,
  hideMissingRequiredFields: PropTypes.func.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
  configuration: PropTypes.shape({
    logoSrc: PropTypes.string,
    formConfiguration: PropTypes.shape({
      hasCurrentTherapySection: PropTypes.bool,
      hasEarlyStageClinicalHistorySection: PropTypes.bool,
      hasLateStageClinicalHistorySection: PropTypes.bool,
      hasTestSelectionSection: PropTypes.bool,
      hasGuardantRevealOrderingSection: PropTypes.bool,
      hasEarlyStageDiagnosisSection: PropTypes.bool,
      hasLateStageDiagnosisSection: PropTypes.bool,
      hasPatientDemoFaceSheetFields: PropTypes.bool,
    }),
  }),
  productFamily: PropTypes.string.isRequired,
  editingOrder: PropTypes.bool,
  emrUser: PropTypes.bool.isRequired,
  emrAccounts: PropTypes.array.isRequired,
};

const defaultProps = {
  showConfirmationModal: false,
  configuration: {
    formConfiguration: {},
  },
  editingOrder: false,
  errorBannerMsg: null,
};

const errorBannerBuilder = (errors, toggleBanner) => (
  <BannerMessage
    status="error"
    messages={errors}
    className={styles.errorBanner}
    toggleBanner={toggleBanner}
  />
);

class OrdersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSection: constants.onlineOrderSections.PHYSICIAN_INFORMATION,
      clients: [],
      isLoading: true,
      clientFetchError: false,
    };
    this.finishLoading = this.finishLoading.bind(this);
    this._dismissBlankRequiredFieldsAdverts = this._dismissBlankRequiredFieldsAdverts.bind(this);
    this._renderErrors = this._renderErrors.bind(this);
  }

  async componentDidMount() {
    const { emrUser, onGhiAccountsError } = this.props;

    try {
      this.props.updateLoadingCount(1);
      if (!emrUser) {
        const accounts = await OrdersController.getQualifiedAccounts();
        this.setState({ clients: accounts });
      }
    } catch (error) {
      onGhiAccountsError();
      this.setState({ clientFetchError: true });
    } finally {
      this.props.updateLoadingCount(-1);
    }
  }

  finishLoading() {
    this.setState({ isLoading: false });
  }

  toggleOpenSection(newSection) {
    return () => {
      this.setState(prevState => ({
        openSection: prevState.openSection !== newSection ? newSection : null,
      }));
    };
  }

  _dismissBlankRequiredFieldsAdverts() {
    this.props.hideErrorBanner();
    this.props.hideMissingRequiredFields();
  }

  _renderErrors() {
    if (this.props.showMissingRequiredFields) {
      return errorBannerBuilder(
        [constants.etrfOrdering.requiredFiledsMissingError],
        this._dismissBlankRequiredFieldsAdverts,
      );
    }
    return errorBannerBuilder(
      [this.props.errorBannerMsg || constants.etrfOrdering.errorEncounteredMessage],
      this.props.hideErrorBanner,
    );
  }

  render() {
    const {
      clients, openSection, isLoading, clientFetchError,
    } = this.state;
    const {
      showConfirmationModal, showErrorBanner, hideErrorBanner, canOnlyDemoOrders, editingOrder,
      configuration: { logoSrc, formConfiguration },
      emrUser, emrAccounts,
    } = this.props;

    if (editingOrder && canOnlyDemoOrders) {
      return <Redirect to="/orders" />;
    }

    const unexpectedErrorBanner = errorBannerBuilder(
      [constants.etrfOrdering.errorEncounteredMessage],
      hideErrorBanner,
    );

    if (clientFetchError) {
      return unexpectedErrorBanner;
    }

    const accounts = emrUser ? emrAccounts : clients;

    if (accounts.length < 1) {
      return null;
    }

    const sectionIdentifiers = constants.onlineOrderSections;

    return (
      <div className={styles.container}>
        {showErrorBanner && this._renderErrors()}
        <PrintModalContainer />
        { showConfirmationModal && <DiscardOverlay productFamily={this.props.productFamily} /> }
        <OrderFormHeader logoSrc={logoSrc} />
        <div className={styles.section}>
          <PhysicianInformationContainer
            enabled={openSection === sectionIdentifiers.PHYSICIAN_INFORMATION}
            onToggle={this.toggleOpenSection(sectionIdentifiers.PHYSICIAN_INFORMATION)}
            clients={accounts}
            finishLoading={this.finishLoading}
          />
        </div>
        {
          !isLoading && (
            <>
              <div className={styles.section}>
                <PatientInformationContainer
                  enabled={openSection === sectionIdentifiers.PATIENT_INFORMATION}
                  onToggle={this.toggleOpenSection(sectionIdentifiers.PATIENT_INFORMATION)}
                  hasPatientDemoFaceSheetFields={formConfiguration.hasPatientDemoFaceSheetFields}
                />
              </div>
              {
                formConfiguration.hasGuardantRevealOrderingSection && (
                  <GuardantRevealOrderingContainer
                    enabled={openSection === sectionIdentifiers.GUARDANT_REVEAL_ORDERING}
                    onToggle={this.toggleOpenSection(sectionIdentifiers.GUARDANT_REVEAL_ORDERING)}
                  />
                )
              }
              {
                formConfiguration.hasTestSelectionSection && (
                  <div className={styles.section}>
                    <TestSelectionInformationContainer
                      enabled={openSection === sectionIdentifiers.TEST_SELECTION_INFORMATION}
                      onToggle={this.toggleOpenSection(
                        sectionIdentifiers.TEST_SELECTION_INFORMATION,
                      )}
                    />
                  </div>
                )
              }
              {
                formConfiguration.hasEarlyStageDiagnosisSection && (
                  <div className={styles.section}>
                    <DiagnosisConditionEarlyStageContainer
                      enabled={
                        openSection === sectionIdentifiers.EARLY_DIAGNOSIS_CONDITION_INFORMATION
                      }
                      onToggle={this.toggleOpenSection(
                        sectionIdentifiers.EARLY_DIAGNOSIS_CONDITION_INFORMATION,
                      )}
                    />
                  </div>
                )
              }
              {
                formConfiguration.hasLateStageDiagnosisSection && (
                  <div className={styles.section}>
                    <DiagnosisConditionLateStageContainer
                      enabled={openSection === sectionIdentifiers.DIAGNOSIS_CONDITION_INFORMATION}
                      onToggle={this.toggleOpenSection(
                        sectionIdentifiers.DIAGNOSIS_CONDITION_INFORMATION,
                      )}
                    />
                  </div>
                )
              }
              {
                formConfiguration.hasCurrentTherapySection && (
                  <div className={styles.section}>
                    <CurrentTherapyContainer
                      enabled={openSection === sectionIdentifiers.CURRENT_THERAPY_INFORMATION}
                      onToggle={this.toggleOpenSection(
                        sectionIdentifiers.CURRENT_THERAPY_INFORMATION,
                      )}
                    />
                  </div>
                )
              }
              {
                formConfiguration.hasEarlyStageClinicalHistorySection && (
                  <div className={styles.section}>
                    <ClinicalHistoryEarlyStageContainer
                      enabled={
                        openSection === sectionIdentifiers.EARLY_CLINICAL_HISTORY_INFORMATION
                      }
                      onToggle={this.toggleOpenSection(
                        sectionIdentifiers.EARLY_CLINICAL_HISTORY_INFORMATION,
                      )}
                    />
                  </div>
                )
              }
              {
                formConfiguration.hasLateStageClinicalHistorySection && (
                  <div className={styles.section}>
                    <ClinicalHistoryContainer
                      enabled={openSection === sectionIdentifiers.CLINICAL_HISTORY_INFORMATION}
                      onToggle={this.toggleOpenSection(
                        sectionIdentifiers.CLINICAL_HISTORY_INFORMATION,
                      )}
                    />
                  </div>
                )
              }
              <div className={styles.lastSection}>
                <BillingInformationContainer
                  enabled={openSection === sectionIdentifiers.BILLING_INFORMATION}
                  onToggle={this.toggleOpenSection(sectionIdentifiers.BILLING_INFORMATION)}
                  hasPatientDemoFaceSheetFields={formConfiguration.hasPatientDemoFaceSheetFields}
                />
              </div>
              <OrdersActionContainer />
            </>
          )
        }
      </div>
    );
  }
}

OrdersContainer.propTypes = IPropTypes;
OrdersContainer.defaultProps = defaultProps;

export { OrdersContainer };
