import { connect } from 'react-redux';

import { StatusMessageContainer } from './status-message-container';

const mapStateToProps = store => ({
  messages: store.patientPage.multiProduct.info.primaryStatusMessages,
  collectionDate: (store.patientPage.multiProduct.info.patientSummary || {}).collectionDate,
});

const ConnectedStatusMessageContainer = connect(mapStateToProps)(StatusMessageContainer);

export { ConnectedStatusMessageContainer as StatusMessageContainer };
