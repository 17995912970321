class ImmunohistochemistryTableSerializer {
  static deserialize(data) {
    return data && {
      tableTitle: data.tableTitle,
      stainingPercentage: data.stainingPercentage,
      scoreType: data.scoreType,
    };
  }
}

export { ImmunohistochemistryTableSerializer };
