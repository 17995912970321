const TOGGLE_CONFIRMATION_MODAL = 'TOGGLE_CONFIRMATION_MODAL';
const HIDE_FORM_VALIDATION_MESSAGE = 'HIDE_FORM_VALIDATION_MESSAGE';
const SHOW_FORM_VALIDATION_MESSAGE = 'SHOW_FORM_VALIDATION_MESSAGE';

const toggleConfirmationModalAction = () => ({
  type: TOGGLE_CONFIRMATION_MODAL,
});

const hideFormValidationMessageAction = () => ({
  type: HIDE_FORM_VALIDATION_MESSAGE,
});

const showFormValidationMessageAction = warnings => ({
  type: SHOW_FORM_VALIDATION_MESSAGE,
  warnings,
});

export {
  TOGGLE_CONFIRMATION_MODAL,
  HIDE_FORM_VALIDATION_MESSAGE,
  SHOW_FORM_VALIDATION_MESSAGE,
  toggleConfirmationModalAction,
  hideFormValidationMessageAction,
  showFormValidationMessageAction,
};
