import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatepickerInput from '../../generic/DatepickerInput';
import { updatePatientDob } from '../service/PatientService';

const IPropTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
};
const defaultProps = {
  showModal: false,
};

const DobModal = (props) => {
  const {
    showModal,
    closeModal,
    info: { dob },
  } = props;
  let dateOfBirth = dob ? moment(dob).format('DD-MM-YYYY ') : '';
  const savePatientDob = () => {
    const params = { dob: dateOfBirth };
    updatePatientDob(params, closeModal);
  };
  return (
    <>
      {showModal && (
        <div className="modal__container-outer modal__patient edit__dob">
          <div className="modal__container-inner">
            <div className="modal__content">
              <div className="patient__settings-edit patient__settings-edit__dob">
                <div className="settings-edit__container">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="myguardant-form">Your date of birth</label>
                  <p className="help-block">Please enter your date of birth</p>
                  <div className="content">
                    <div className="dob-errors" />
                    <DatepickerInput
                      className="print-form-container__datepicker"
                      name="specimenCollectionDate"
                      id="print-specimen-collection-date"
                      onChange={(value) => {
                        dateOfBirth = moment(value).format('DD-MM-YYYY ');
                      }}
                      value={dateOfBirth}
                      placeholder="DD-MM-YYYY"
                    />
                  </div>
                  <div className="buttons-row">
                    <div
                      className="button button-clear-background close__edit__dob"
                      onClick={() => closeModal('dob')}
                    >
                      CANCEL
                    </div>
                    <div
                      className="button form-submit__btn save__edit__dob"
                      onClick={savePatientDob}
                    >
                      SAVE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default DobModal;
DobModal.propTypes = IPropTypes;
DobModal.defaultProps = defaultProps;
