import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionTypes';

export const DEFAULT_USER_STATE = fromJS({
  usersList: null,
  usersSearchList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },

  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
});

const UserReducer = handleActions(
  {
    [actionTypes.GET_USER]: (state, action) => state
      .set('reload', false)
      .set('usersList', action.payload.data.users || [action.payload.data])
      .set(
        'totalRecords',
        action.payload.data.totalRecords === 0
          ? 0
          : action.payload.data.totalRecords || [action.payload.data].length,
      )
      .setIn(
        ['pagination', 'pageNo'],
        (action.payload.data.users || [action.payload.data]).length === 0
            && state.getIn(['pagination', 'pageNo']) > 0
          ? state.getIn(['pagination', 'pageNo']) - 1
          : state.getIn(['pagination', 'pageNo']),
      ),
    [actionTypes.GET_SEARCH_USER]: (state, action) => state
      .set(
        'usersSearchList',
        action.payload.data.users || action.payload.data,
      )
      .setIn(['notify'], {
        isOpen: action.payload.data.length === 0 && true,
        message: 'No Results Found',
        type: 'error',
      }),
    [actionTypes.SAVE_USER]: state => state.set('reload', true).set('openPopup', false).setIn(['notify'], {
      isOpen: true,
      message: 'User Added Successfully',
      type: 'success',
    }),

    [actionTypes.UPDATE_USER]: state => state.set('reload', true).set('openPopup', false).setIn(['notify'], {
      isOpen: true,
      message: 'User Saved Successfully',
      type: 'success',
    }),

    [actionTypes.DELETE_USER]: state => state.set('reload', true).setIn(
      ['notify'],
      fromJS({
        isOpen: true,
        message: 'User Deleted Successfully',
        type: 'success',
      }),
    ),

    [actionTypes.USER_ERROR]: (state, action) => state.setIn(
      ['notify'],
      fromJS({ isOpen: true, message: action.payload.message, type: 'error' }),
    ),
    [actionTypes.USERS_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_USER]: (state, action) => state
      .set(action.payload.key, action.payload.value),
  },
  DEFAULT_USER_STATE,
);
export default UserReducer;
