import { AnnouncementsEndpoints } from '../endpoints';
import HttpService from '../services/HttpService';
import { getToken, isInternalUser } from './helper';

const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

class AnnouncementsController {
  static checkSignatureNotFinalized = async () => {
    const { data: { show } } = await HttpService.get(
      AnnouncementsEndpoints.VERIFY_SIGNATURE_NOT_FINALIZED,
      {
        params: { type: 'signature_not_finalized' },
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return show;
  }

  static inactivateAnnouncements = async () => {
    HttpService.get(
      AnnouncementsEndpoints.INACTIVE_ANNOUNCEMENTS,
      {
        params: { type: 'signature_not_finalized' },
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );
  }
}

export { AnnouncementsController };
