export const getUserInfo = (feature) => {
  const userInfo = localStorage.getItem('userInfo');
  const userAccessInfo = userInfo ? JSON.parse(userInfo) : {};
  const { accessInfo = {} } = userAccessInfo;
  const { authorities = {} } = accessInfo;
  const { featureClaims = {} } = authorities;
  return featureClaims[feature];
};
export const getUserAccessInfo = () => {
  const userInfo = localStorage.getItem('userInfo');
  const userAccessInfo = userInfo ? JSON.parse(userInfo) : {};
  return userAccessInfo;
};
