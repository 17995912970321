export class DictionarySerializer {
  static deserialize = data => (
    data && {
      biomarkers: data.biomarker && {
        table: data.biomarker.table,
        label: data.biomarker.label,
        details: data.biomarker.details,
      },
      alterations: data.alteration && {
        tableHeader: data.alteration.label,
        alterationHeader: data.alteration.alteration,
        frequencyHeader: data.alteration.frequency,
        therapiesHeader: data.alteration.therapies,
        clinicalHeader: data.alteration.trials.label,
        noAlterationsText: data.alteration.description.negative,
      },
    }
  );
}
