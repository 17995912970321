import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { receiveOrder } from '../../store/actions/orders/active-order/order';
import { receiveError } from '../../store/actions/errors';
import { clearActiveOrder } from '../../store/actions/orders/active-order/generic-info';
import { ProgressionOrderContainer } from './progression-order-container';


const mapStateToProps = ({ orders: { activeOrder } }) => ({
  activeOrder,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveOrder: receiveOrder,
  clearActiveOrder,
  receiveError,
}, dispatch);

const ConnectedProgressionOrderContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(ProgressionOrderContainer);

export { ConnectedProgressionOrderContainer as ProgressionOrderContainer };
