const TOGGLE_RELEASE_MODAL = 'TOGGLE_RELEASE_MODAL';
const TOGGLE_RELEASE_MESSAGE = 'TOGGLE_RELEASE_MESSAGE';
const HIDE_FORM_VALIDATION_MESSAGE = 'HIDE_FORM_VALIDATION_MESSAGE';
const SHOW_FORM_VALIDATION_MESSAGE = 'SHOW_FORM_VALIDATION_MESSAGE';

const toggleReleaseMessageAction = () => ({
  type: TOGGLE_RELEASE_MESSAGE,
});

const toggleReleaseModalAction = () => ({
  type: TOGGLE_RELEASE_MODAL,
});

const hideFormValidationMessageAction = () => ({
  type: HIDE_FORM_VALIDATION_MESSAGE,
});

const showFormValidationMessageAction = () => ({
  type: SHOW_FORM_VALIDATION_MESSAGE,
});

export {
  TOGGLE_RELEASE_MODAL,
  TOGGLE_RELEASE_MESSAGE,
  HIDE_FORM_VALIDATION_MESSAGE,
  SHOW_FORM_VALIDATION_MESSAGE,
  toggleReleaseModalAction,
  toggleReleaseMessageAction,
  hideFormValidationMessageAction,
  showFormValidationMessageAction,
};
