import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';

import styles from './current-therapy-information-collapsed.module.scss';
import baseEtrfStyles from '../../../../assets/stylesheets/etrf/base.module.scss';

const IPropTypes = {
  currentTherapyInfoStore: PropTypes.shape({
    advancedCancer: PropTypes.bool,
    targetedTherapy: PropTypes.bool,
    immunotherapy: PropTypes.bool,
    chemotherapy: PropTypes.bool,
    combinationTherapy: PropTypes.bool,
    specificTherapy: PropTypes.string,
    dateOfTherapyInitiation: PropTypes.string,
  }).isRequired,
  onToggle: PropTypes.func,
};

const defaultProps = {
  onToggle: () => { },
};

const CurrentTherapyInformationCollapsed = ({
  currentTherapyInfoStore: {
    targetedTherapy,
    immunotherapy,
    chemotherapy,
    combinationTherapy,
    specificTherapy,
    dateOfTherapyInitiation,
  },
  onToggle,
}) => {
  const therapyText = [
    targetedTherapy && 'Targeted Therapy',
    immunotherapy && 'Immunotherapy',
    chemotherapy && 'Chemotherapy',
    combinationTherapy && 'Combination Therapy',
  ].filter(Boolean).join(', ');
  return (
    <div className={[baseEtrfStyles.accordionSection, baseEtrfStyles.collapsed].join(' ')} onClick={onToggle}>
      <CollapsedTab title="Currently on Therapy" value={therapyText} truncate={false} />
      <div className={styles.tabRow}>
        <CollapsedTab title="Specific Therapy" value={specificTherapy} />
        <CollapsedTab title="Date of therapy initiation" value={dateOfTherapyInitiation} truncate={false} />
      </div>
    </div>
  );
};


CurrentTherapyInformationCollapsed.propTypes = IPropTypes;
CurrentTherapyInformationCollapsed.defaultProps = defaultProps;

export { CurrentTherapyInformationCollapsed };
