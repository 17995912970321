import React from 'react';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const useStyles = makeStyles(theme => createStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    paddingLeft: '0',
  },
  iconButtonLabel: {
    fontSize: '1rem',
    color: '#142840',
    '&:hover': {
      color: '#1A5EA7',
    },
  },
  iconButton: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
const openCloseHeader = ({ opened, label, onToggle }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <IconButton
            classes={{ label: classes.iconButtonLabel, root: classes.iconButton }}
            onClick={() => onToggle()}
            size="large"
          >
            {opened ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            {label}
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default openCloseHeader;
