import { connect } from 'react-redux';

import { AlterationsContainer } from './alterations-container';

const mapStateToProps = (store) => {
  const { alterationsTable, dictionary } = store.patientPage.multiProduct.info;
  return {
    hasInfoModal: alterationsTable.hasInfoModal,
    hasTherapyLegend: alterationsTable.hasTherapyLegend,
    showContent: alterationsTable.showContent,
    showFootnote: alterationsTable.showFootnote,
    tableHeader: dictionary && dictionary.alterations && dictionary.alterations.tableHeader,
    collectionDate: (store.patientPage.multiProduct.info.patientSummary || {}).collectionDate,
  };
};

const ConnectedAlterationsContainer = connect(
  mapStateToProps, null,
)(AlterationsContainer);

export { ConnectedAlterationsContainer as AlterationsContainer };
