import { connect } from 'react-redux';

import { InterpretationAndMethodology } from './interpretation-and-methodology';

const mapStateToProps = store => (
  {
    interpretationAndMethodology: store.patientPage.multiProduct.info.interpretationAndMethodology,
  }
);

const ConnectedInterpretationAndMethodology = connect(
  mapStateToProps, null,
)(InterpretationAndMethodology);

export { ConnectedInterpretationAndMethodology as InterpretationAndMethodology };
