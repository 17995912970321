import {
  UPDATE_MODAL_INFO,
  TOGGLE_MODAL,
} from '../../../actions/orders/active-order/print-modal';

const initialState = {
  title: 'TEST REQUISITION FORM',
  showModal: false,
  informationMessage: '',
  informationSubMessage: '',
  warningStatus: false,
  summary: null,
  alreadyPrinted: false,
  printOrderText: 'PRINT NOW',
  finalizeOrderText: null,
  footer: {
    message: '',
    isAWarning: true,
  },
  commentsText: '',
  specimenCollectionDate: '',
  labClinicianText: '',
  loadPrintingData: null,
  order: null,
};

const printModal = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MODAL_INFO: {
      return { ...state, ...action.info };
    }
    case TOGGLE_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
      };
    default:
      return state;
  }
};

export { printModal };
