/* eslint-disable no-mixed-operators, no-nested-ternary */
/* eslint-disable max-len */
import {
  Box, Grid, IconButton, Tooltip, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'loadsh';
import NumberFormat from 'react-number-format';
import { withStyles } from '@mui/styles';
import Controls from '../../admin/common/components/forms/controls';
import { schema } from '../schema';
import ValidationJson from '../validate.json';
import Validation from '../../admin/common/utils/Validation';
import info from '../../../../assets/images/icons/Info.svg';
import guardantcdx from '../../../../assets/images/icons/guardant360cdx-logo.svg';
import guardant from '../../../../assets/images/icons/guardant360.svg';
import tissuenext from '../../../../assets/images/icons/logo-360TissueNext.svg';
import lunarLogo from '../../../../assets/images/logos/lunar-logo.svg';
import response from '../../../../assets/images/icons/logo-360-Response.svg';
import { descriptionObject, disobj, tooltipText } from '../utils/helper';
import gdtIcons from '../../../assets/images/icons/gdt-icons.svg';
import AlertMessage from '../alert-modal/AlertMessage';
import {getRootDisease} from "../helpers";

const useStyles = makeStyles(() => ({
  root: {
  },
  checkboxContainer: {
    paddingTop: '0 !important',
    backgroundColor: '#ffffff',
  },
  guardantLogo: {
    marginLeft: '20px',
  },
  infoIcon: {
    marginTop: '7px',
  },
  guardantLogo2: {
    marginLeft: '-40px',
  },
  guardantLogo3: {
    marginLeft: '20px',
  },
  body2alt: {
    marginLeft: '-50px !important',
  },
  errorBorder: {
    border: '1px solid #d82a34',
    backgroundColor: '#ffe9ea',
    borderRadius: '2px',
    margin: 'auto',
    marginTop: '16px',
    display: 'flex',
    textAlign: 'center',
    width: '60%',
    placeSelf: 'center',
  },
  IconBorder: {
    backgroundColor: '#d82a34',
    marginRight: '5px',
  },
  tooltipPopper: {
    opacity: '0.8 !important',
    background: '#273644',
    borderRadius: '4px',
    padding: '4px 12px !important',
    fontFamily: 'Helvetica, sans-serif, Arial',
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'transparent !important',
      fontSize: '12px',
      fontWeight: '400',
    },
  },
  formControl: {
    '& .MuiFormControlLabel-root': {
      marginLeft: '40px',
    },
    position: 'relative',
    '& .MuiRadio-root': {
      position: 'absolute',
      top: '-6px',
      left: '-40px',
    },
  }
}));
const DecimalNumberFormat = (props) => {
  const { inputRef, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      decimalScale={2}
    />
  );
};
DecimalNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const NumberFormatCustom = (props) => {
  const { inputRef, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      format="(###)-###-####"
    />
  );
};
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
function TestSelection(props) {
  const classes = useStyles();
  const { newOrder } = props;
  const orderManagement = newOrder && newOrder.toJS();
  const [showMessage, setShowMessage] = useState(false);
  const productFamily = newOrder.getIn(schema.testSelection.productFamily) || {};
  useEffect(() => {
    if (!orderManagement.testSelection) {
      props.actions.getTestSelections();
    }
  }, [orderManagement.testSelection]);
  const logos = {
    G360CDx: guardantcdx,
    G360: guardant,
    TGPDL1: tissuenext,
    TG: tissuenext,
    G360MR: response,
    reveal: lunarLogo,
  };
  const closeMessage = () => {
    setShowMessage(false);
  };
  const onInputChange = (e, code) => {
    const { name, type } = e.target;
    const {
      relevantClinicalHistory = [],
      advancedCancerStage = {},
      testOrdered = {},
    } = orderManagement?.schema?.order || {};
    const isAdvancedCancerStageValid = Object.keys(advancedCancerStage)
      .filter(item => advancedCancerStage[item] === true).length > 1;
    if (name === 'productFamily' && (type === 'radio' || type === 'checkbox') && (relevantClinicalHistory.length > 0 || isAdvancedCancerStageValid || testOrdered.dateOfSurgicalResection)) {
      setShowMessage(true);
    }
    let { value } = e.target;
    if (name === 'phone' || name === 'fax') {
      value = value.replace(/[^0-9]/g, '', ' ');
    }
    if (name === 'testOrdered') {
      value = [value];
    }
    if (name === 'productFamily') {
      props.actions.onChange({ name: ['schema', 'order', 'advancedCancerStage'], value: {} });
      props.actions.onChange({
        name: schema.clinicalHistory.dateOfSurgicalResection,
        value: undefined,
      });
      props.actions.onChange({
        name: schema.clinicalHistory.relevantClinicalHistory,
        value: undefined,
      });
      props.actions.setError({name: 'dateOfTherapyInitiation', value: undefined});

      let trial = _.uniq([...newOrder.getIn(schema.testSelection.testOrdered)]);
      trial = trial.filter(x => code.includes(x)).length > 0
        ? trial.filter(x => !code.includes(x))
        : [...trial, ...code];
      if (
        [...code].join('').startsWith('G360')
        && !trial.find(x => x.startsWith('G360') && x !== 'G360MR')
      ) {
        trial = trial.filter(x => x !== 'TG' && x !== 'PDL1');
      }
      if (e.target.checked === false && trial.length === 0) {
        value = {};
      } else if (e.target.checked || trial.length > 0) {
        value = { id: value, name: value.toLowerCase() };
      }
      const stage = newOrder.getIn(schema.diagnosis.stage) || '';
      if (e.target.checked === false && code.join('') === 'REVEAL') {
        trial = [];
        value = {};
        props.actions.onChange({ name: schema.testSelection.preferenceForBloodDraw, value: '' });
      } else {
        props.actions.onChange({ name: schema.diagnosis.stage, value: stage });
      }
      let disabled = [];
      if (
        trial.includes('G360MR')
        && !trial.find(x => x.startsWith('G360') && x !== 'G360MR')
      ) {
        disabled = [...disabled, 'TGPDL1', 'TG'];
      } else if (!trial.find(x => x.startsWith('G360') && x !== 'G360MR')) {
        disabled = [...disabled, 'TGPDL1', 'TG'];
      }
      trial.forEach((x) => {
        if (disobj[[...x].join('')]) {
          if (trial.includes('PDL1') && x === 'TG') {
            disabled = [...disabled, ...disobj.TGPDL1];
          } else if (!trial.includes('PDL1')) {
            disabled = [...disabled, ...disobj[[...x].join('')]];
          }
        }
      });

      if (e.target.checked === true && code.join('') === 'REVEAL') {
        trial = ['GUARDANT_REVEAL'];
      }


      props.actions.onChange({ name: ['disabled'], value: disabled });
      props.actions.onChange({
        name: schema.testSelection.testOrdered,
        value: trial,
      });
      if (!trial?.includes('GUARDANT_REVEAL')) {
        props.actions.onChange({ name: schema.testSelection.reorderType, value: undefined });
        props.actions.onChange({ name: schema.diagnosis.noAdjuvantTherapy, value: undefined });
        props.actions.onChange({ name: schema.clinicalHistory.molecularMarker, value: undefined });
        props.actions.onChange({ name: schema.clinicalHistory.dateOfAdjuvantChemotherapy, value: undefined });
        props.actions.setError({ name: 'subDiagnosis', value: undefined });
        props.actions.setError({ name: 'diagnosisOther', value: undefined });
        props.actions.setError({ name: 'dateOfSurgicalResection', value: undefined });
        props.actions.setError({ name: 'noAdjuvantTherapy', value: undefined });
        props.actions.setError({ name: 'dateOfAdjuvantChemotherapy', value: undefined });
        const rootDisease = getRootDisease(newOrder.getIn(schema.diagnosis.disease), orderManagement.cancerTypeList)?.diagnosis;
        if(rootDisease !== newOrder.getIn(schema.diagnosis.disease)){
          props.actions.onChange({ name: schema.diagnosis.disease, value: rootDisease });
          props.actions.onChange({ name: schema.diagnosis.diagnosisOther, value: undefined });
        }
      }
    }

    if (Array.isArray(code) && code.join('') === 'G360MR') {
      props.actions.onChange({
        name: schema.testSelection.followOnTestOrdered,
        value: e.target.checked ? 'G360MR' : '',
      });
    }

    if (e.target.checked === false && code && code.includes('TG')) {
      props.actions.onChange({ name: schema.testSelection.pathologyLabName, value: '' });
      props.actions.onChange({ name: schema.testSelection.phone, value: '' });
      props.actions.onChange({ name: schema.testSelection.fax, value: '' });
      props.actions.onChange({ name: schema.testSelection.tissueCollectionDate, value: undefined });
    }

    const { testSelection } = ValidationJson;
    if (ValidationJson.testSelection.testOrdered && code && code !== 'REVEAL' && name !== 'reorderTypeId' && name !== 'intervalId') {
      const error = Validation('testOrdered', value, testSelection) || '';
      props.actions.setError({ name: ['testOrdered'], value: error });
      props.actions.setError({ name: 'preferenceForBloodDraw', value: error });
      props.actions.setError({ name: 'reorderTypeId', value: '' });
      props.actions.setError({ name: 'intervalValue', value: '' });
    } else if (ValidationJson.testSelection.productFamily && name === 'productFamily') {
      const error = Validation('productFamily', value, testSelection) || '';
      props.actions.setError({ name: ['productFamily'], value: error });
    } else if (ValidationJson.testSelection[name] && name === 'intervalValue') {
      const error = Validation('intervalValue', value, ValidationJson.testSelection) || '';
      props.actions.setError({ name: 'intervalValue', value: error });
    } else if (ValidationJson.testSelection[name]) {
      let error = Validation(name, value, ValidationJson.testSelection) || '';
      error = (ValidationJson.testSelection[name].required && error.trim().length > 0 && (value || '').length === 0) ? 'Required' : error;
      props.actions.setError({ name: `testSelection${name}`, value: error });
    }
    if (name === 'intervalId') {
      props.actions.onChange({
        name: schema.testSelection.reorderType,
        value: {
          id: 'S',
          interval: {
            id: value,
            value: value === 'NMONTH' ? '' : value?.substr(0, 1),
          },
        },
      });
      if (value !== 'NMONTH') {
        props.actions.setError({ name: 'intervalValue', value: '' });
      }
      props.actions.setError({ name: 'reorderTypeId', value: '' });
    } else {
      if (name === 'reorderTypeId' && (value === 'S') && !testOrdered?.reorderType?.interval) {
        props.actions.onChange({
          name: schema.testSelection.reorderType,
          value: {
            id: 'S',
            interval: {
              id: '',
              value: '',
            },
          },
        });
      }
      if (name === 'reorderTypeId' && (value === 'P' || value === 'O')) {
        props.actions.onChange({
          name: schema.testSelection.reorderType,
          value: {
            id: value,
            interval: null,
          },
        });
        props.actions.setError({ name: 'intervalValue', value: '' });
        props.actions.setError({ name: 'reorderTypeId', value: '' });
      }
    }
    if (name !== 'intervalId' && name !== 'reorderTypeId') {
      props.actions.onChange({ name: schema.testSelection[name], value });
    }
  };
  const handleMobilePhlebotomy = (e) => {
    const { testSelection } = ValidationJson;
    const { name, value } = e.target;
    props.actions.onChange({ name: schema.testSelection[name], value });
    const error = Validation('preferenceForBloodDraw', value, testSelection) || '';
    props.actions.setError({ name: 'preferenceForBloodDraw', value: error });
  };
  useEffect(() => {
    let disabled = [];

    const productFamilyId = newOrder.getIn(schema.testSelection.productFamily);
    const testOrdered = newOrder.getIn(schema.testSelection.testOrdered);
    // eslint-disable-next-line max-len
    const followOnTestOrdered = newOrder.getIn(schema.testSelection.followOnTestOrdered);
    let trial = [];
    if (testOrdered?.length > 0 && testOrdered[0] !== '') {
      trial = _.uniq([...testOrdered?.filter(x => x !== 'G360MR')]);
    }

    if (followOnTestOrdered !== null && followOnTestOrdered) {
      trial = [...trial, followOnTestOrdered];
    }
    if (trial.length === 0 || !trial?.includes('G360CDx')) {
      disabled = ['TGPDL1', 'TG'];
    }
    trial.forEach((x) => {
      if (disobj[[...x].join('')]) {
        if (trial.includes('PDL1') && x === 'TG') {
          disabled = [...disabled, ...disobj.TGPDL1];
        } else if (!trial.includes('PDL1')) {
          disabled = [...disabled, ...disobj[[...x].join('')]];
        }
      }
    });
    if (productFamilyId?.id === 'REVEAL') {
      disabled = disobj.REVEAL;
    }
    if (orderManagement.isReveal && !orderManagement.isG360) {
      disabled = disobj.REVEAL;
    }
    props.actions.onChange({ name: ['disabled'], value: disabled });
    props.actions.onChange({
      name: schema.testSelection.testOrdered,
      value: trial,
    });
  }, [orderManagement.roOpenFromDashboard]);
  const survellience = test => (
    <div className={classes.checkBoxDiscript}>
      <div>
        <Typography component="div" variant="body2" fontWeight="600">
          {test?.text[0]}
        </Typography>
      </div>
      {test?.text[0]?.includes('Surveillance')
        ? (
          <RadioGroup
            aria-labelledby="Surveillance Program"
            name="intervalId"
            onChange={onInputChange}
          >
            {test?.children?.map(txt => (
              <FormControlLabel
                className={classes.formControl}
                sx={{ margin: '0 0 0 30px !important' }}
                value={txt?.code?.join('')?.toUpperCase()}
                control={(
                  <Radio
                    checked={
                      orderManagement?.schema?.order?.testOrdered?.reorderType?.interval?.id
                        ?.includes(txt?.code?.join('')?.toUpperCase()) || false
                      }
                  />
                  )}
                label={txt?.text[0]?.includes('Other') ? (
                  <React.Fragment>
                    <Typography component="h6" variant="body2">Other: Every 4-12 months</Typography>
                    {orderManagement?.schema?.order?.testOrdered?.reorderType?.interval?.id
                      ?.includes('NMONTH') && (
                        <Grid item sx={{ marginTop: '10px', minWidth: '18rem' }}>
                          <Controls.Input
                            label="Please enter a number between 4 and 12"
                            name="intervalValue"
                            fullWidth
                            value={orderManagement?.schema?.order?.testOrdered?.reorderType?.interval?.value}
                            onChange={onInputChange}
                            error={orderManagement?.errors?.intervalValue}
                            placeholder="Please enter a number between 4 and 12"
                            InputProps={{
                              inputComponent: DecimalNumberFormat,
                            }}
                            InputLabelProps={{ shrink: orderManagement?.schema?.order?.testOrdered?.reorderType?.interval?.value ? true : false }}
                            showHelperText="Required"
                          />
                        </Grid>
                      )}
                  </React.Fragment>
                ) : (
                  <Typography component="h6" variant="body2">{txt?.text[0]}</Typography>
                )}
              />
            ))}
          </RadioGroup>
        ) : test?.text?.map((txt, index) => (
          (index !== 0) && <Typography component="h6" variant="body2">{txt}</Typography>
        ))}
    </div>
  );
  useEffect(() => {
    if (orderManagement.isReveal === false) {
      if (productFamily?.id === 'REVEAL') {
        props.actions.onChange({ name: schema.testSelection.productFamily, value: {} });
        props.actions.onChange({ name: schema.testSelection.testOrdered, value: [] });
        props.actions.onChange({ name: schema.testSelection.reorderType, value: undefined });
        props.actions.onChange({ name: ['disabled'], value: ['TGPDL1', 'TG'] });
      }
    }
    if (orderManagement.isG360 === false) {
      if (productFamily?.id === 'G360') {
        props.actions.onChange({ name: schema.testSelection.productFamily, value: {} });
        props.actions.onChange({ name: schema.testSelection.followOnTestOrdered, value: '' });
        props.actions.onChange({ name: schema.testSelection.testOrdered, value: [] });
        props.actions.onChange({ name: schema.testSelection.reorderType, value: undefined });
        props.actions.onChange({ name: ['disabled'], value: ['TGPDL1', 'TG'] });
      }
    }
  }, [orderManagement.isG360, orderManagement.isReveal]);
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item lg={8} justifyContent="center" alignItems="center">
        {((orderManagement.isReveal === false && orderManagement.isG360 === false)
        || (orderManagement?.errors?.reorderTypeId && orderManagement?.errors?.reorderTypeId?.length > 0)
        || (orderManagement.errors.testOrdered && orderManagement.errors.testOrdered?.length > 0)
        || (orderManagement.errors.preferenceForBloodDraw && orderManagement.errors.preferenceForBloodDraw?.length > 0))
          && (
            <Box className={classes.errorBorder}>
              <Box className={classes.IconBorder}>
                <IconButton size="small" sx={{ background: '#d82a34' }}>
                  <svg className="gdt-icon">
                    <use href={`${gdtIcons}#report-problem`} className="icon-error" />
                  </svg>
                </IconButton>
              </Box>
              {((orderManagement.isReveal === false && orderManagement.isG360 === false)
                || (orderManagement.errors.testOrdered && orderManagement.errors.testOrdered?.length > 0)
                || (orderManagement?.errors?.reorderTypeId && orderManagement?.errors?.reorderTypeId?.length > 0))
                ? (<Typography component="p" variant="body2" sx={{ paddingTop: '10px', paddingBottom: '10px' }} color="#a11f25">
                  {(orderManagement?.errors?.testOrdered || orderManagement?.errors?.reorderTypeId) ? 'Required Test Selection'
                    : 'For the current diagnosis selections there are no available tests.'}
                </Typography>)
                : (<Typography component="p" variant="body2" sx={{ paddingTop: '10px', paddingBottom: '10px' }} color="#a11f25">
                  Required Preference for Managing Blood Draws Selection
                </Typography>)
              }
            </Box>
          )}
        <Typography sx={{ my: '30px' }} component="h5" variant="h5">
          3. Test Selection
        </Typography>
        {orderManagement.testSelection && (
          <Grid container direction="row">
            <Typography
              variant="div"
              component="body1"
              fontWeight="bold"
              sx={{ mb: '20px' }}
            >
              Minimal Residual Disease Detection
            </Typography>
            <Grid container className={classes.checkboxContainer} item xs={12}>
              <Grid item xs={6} direction="column" className={classes.checkboxContainer}>
                <CustomColorCheckbox
                  disabled={orderManagement.disabled.includes('REVEAL') || orderManagement.isReveal === false}
                  onChange={e => onInputChange(e, ['REVEAL'])}
                  checked={
                    productFamily.id
                    === 'REVEAL'
                  }
                  inputProps={{ value: 'REVEAL', name: 'productFamily' }}
                />

                <img
                  alt="Guardant-Logo"
                  height="28"
                  className={classes.guardantLogo}
                  src={lunarLogo}
                />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <Typography component="span" variant="body2" color="primary" sx={{ mr: '30px' }}>
                  {orderManagement.isReveal === false ? descriptionObject.DEFAULT : orderManagement.disabled.includes('REVEAL') ? descriptionObject.REVEAL : ''}
                </Typography>

                <Tooltip title={tooltipText.REVEAL} classes={{ popper: classes.tooltipPopper }} placement="top-end">
                  <img alt="Guardant-Logo" height="32" width="32" src={info} className={classes.infoIcon} />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}

        {productFamily.id === 'REVEAL' && (
          <Grid
            container
            direction="row"

          >
            <Typography
              component="div"
              variant="body2"
              sx={{ mt: '10px' }}
            >
              Select one

            </Typography>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: '#FFFFFF', border: '1px solid #1f5fac', padding: '5px', mt: '10px',
              }}
            >
              <Grid container item xs={12}>

                  <FormControl className={classes.formControl}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="reorderTypeId"
                      onChange={onInputChange}
                      className={(orderManagement?.errors?.reorderTypeId?.length > 0
                        || orderManagement?.errors?.intervalValue?.length > 0) ? 'error' : classes.margin}
                    >
                      {orderManagement.testSelection
                        && orderManagement.testSelection?.Reveal?.['GUARDANT_REVEAL'][0]?.children.map(
                          test => (
                            <FormControlLabel
                              className={classes.formControl}
                              value={test?.code?.join('')?.toUpperCase()}
                              control={(
                                <Radio
                                  checked={
                                    orderManagement?.schema?.order?.testOrdered?.reorderType?.id
                                      ?.includes(test?.code?.join('')?.toUpperCase()) || false
                                  }
                                />
                              )}
                              label={survellience(test)}
                            />
                          ),
                        )}
                    </RadioGroup>
                    <Typography component="div" variant="body2" fontWeight="600" sx={{ marginTop: '10px', marginLeft: '10px' }}>
                      Preference for Managing Blood Draws
                    </Typography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="preferenceForBloodDraw"
                      onChange={handleMobilePhlebotomy}
                      className={orderManagement?.errors?.preferenceForBloodDraw?.length > 0 ? 'error' : classes.margin}
                    >
                      <FormControlLabel
                        className={classes.formControl}
                        value="Mobile Phlebotomy"
                        control={(
                          <Radio
                            checked={newOrder.getIn(schema.testSelection.preferenceForBloodDraw) === 'Mobile Phlebotomy'}
                          />
                        )}
                        label={<Typography component="h6" variant="body2">Mobile Phlebotomy</Typography>}
                      />
                      <FormControlLabel
                        className={classes.formControl}
                        value="In clinic"
                        control={(
                          <Radio
                            checked={newOrder.getIn(schema.testSelection.preferenceForBloodDraw) === 'In clinic'}
                          />
                        )}
                        label={<Typography component="h6" variant="body2">In Clinic</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sx={{ mt: '30px' }}>
          <Typography
            variant="body1"
            component="div"
            sx={{ mb: '10px' }}
          >
            <b>
              Treatment Selection
            </b>
          </Typography>
        </Grid>
        {orderManagement.testSelection
          && Object.keys(orderManagement.testSelection?.G360).map((key, index) => (
            <Grid container direction="row" sx={{ mb: '25px' }}>
              <Typography
                component="div"
                sx={{ mb: '15px' }}
                variant="body1"
              >

                {['Liquid Profiling', 'Tissue Profiling'].includes(key) ? (
                  <>
                    <b>{key === 'Tissue Profiling' ? 'Add Tissue Profiling' : key}</b>
                    {' '}
                    <i> (Select one)</i>
                    {' '}
                  </>
                ) : <b>{key === 'Tissue Profiling' ? 'Add Tissue Profiling' : key}</b>}

              </Typography>
              {orderManagement.testSelection?.G360[key].map((test) => {
                const disabled = orderManagement.isG360 === false || orderManagement.disabled.includes([...test.code].join(''));
                let content = orderManagement.isG360 === false ? descriptionObject.DEFAULT : orderManagement.disabled.includes([...test.code].join('')) ? descriptionObject[key] : '';
                if (productFamily.id !== 'G360' && test.code.join('') === 'G360MR') {
                  content = descriptionObject[key];
                }
                if (productFamily.id === 'G360' && ['TGPDL1', 'TG'].includes(test.code.join(''))) {
                  content = disabled ? descriptionObject.REVEAL : '';
                }
                content = productFamily.id === 'REVEAL' ? descriptionObject.AlterMsg : content;
                return (
                  <Grid container className={classes.checkboxContainer} xs={12} sx={{ mb: '1px' }}>
                    <Grid item xs={6}>
                      <CustomColorCheckbox
                        disabled={disabled}
                        checked={
                          newOrder
                            .getIn(schema.testSelection.testOrdered)
                            ?.find(x => test.code.join('').endsWith([...x].join('')) && (x === 'TG' && !orderManagement.schema.order.testOrdered.name.includes('PDL1') || x !== 'TG')) || test.code.join('') === 'G360MR' && newOrder
                              .getIn(schema.testSelection.followOnTestOrdered) === 'G360MR'
                        }
                        name="productFamily"
                        inputProps={{ value: 'G360' }}
                        onChange={e => onInputChange(e, test.code)}
                      />
                      <img
                        alt="Guardant-Logo"
                        height={(index === 1 || index === 2) ? 50 : 28}
                        className={(index === 1 || index === 2)
                          ? classes.guardantLogo2 : classes.guardantLogo3}
                        src={logos[test.code.join('')]}
                      />
                      {test.code.join('') === 'TGPDL1' && (
                        <Typography
                          variant="body2"
                          className={classes.body2alt}
                          component="span"
                        >
                          + PD L1
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                      <Typography component="span" variant="body2" color="primary" sx={{ mr: '30px' }}>
                        {content}
                      </Typography>
                      <Tooltip
                        title={tooltipText[test.code.join('')]}
                        placement="top-end"
                        classes={{ popper: classes.tooltipPopper }}
                      >
                        <img
                          alt="Guardant-Logo"
                          height="32"
                          width="32"
                          className={classes.infoIcon}
                          src={info}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}

              {orderManagement.testSelection.G360[key].find(
                test => ['TG'].find(x => test.code.join('').startsWith(x))
                  && newOrder
                    .getIn(schema.testSelection.testOrdered)
                    ?.find(x => test.code.join('').startsWith(x)),
              ) && (
              <Grid
                container
                className={classes.revealDetail}
                item
                xs={12}
                spacing={1}
                sx={{ mt: '20px' }}
              >
                <Grid item xs={3}>
                  <Controls.Input
                    label="Pathology Lab Name"
                    name="pathologyLabName"
                    fullWidth
                    value={newOrder.getIn(
                      schema.testSelection.pathologyLabName,
                    )}
                    inputProps={{ maxLength: 36 }}
                    onChange={onInputChange}
                    error={orderManagement.errors.testSelectionpathologyLabName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controls.Input
                    label="Phone"
                    name="phone"
                    fullWidth
                    id="input"
                    value={newOrder.getIn(schema.testSelection.phone)}
                    onChange={onInputChange}
                    error={orderManagement.errors.testSelectionphone}
                    format="###-###-####"
                    InputProps={{
                      maxLength: 15,
                      inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{ shrink: newOrder.getIn(schema.testSelection?.phone) }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controls.Input
                    label="Fax"
                    name="fax"
                    fullWidth
                    id="input"
                    value={newOrder.getIn(schema.testSelection.fax)}
                    onChange={onInputChange}
                    format="###-###-####"
                    InputProps={{
                      maxLength: 15,
                      inputComponent: NumberFormatCustom,
                    }}
                    InputLabelProps={{ shrink: newOrder.getIn(schema.testSelection?.fax) }}
                    error={orderManagement.errors.testSelectionfax}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controls.DatePicker
                    mask="__-__-____"
                    label="Collection Date"
                    name="tissueCollectionDate"
                    type="date"
                    fullWidth
                    inputFormat="MM-DD-YYYY"
                    maxDate={moment()}
                    minDate={moment().year(1900).month(0).date(1)}
                    value={newOrder.getIn(schema.testSelection.tissueCollectionDate) || null}
                    error={orderManagement.errors.testSelectiontissueCollectionDate}
                    // eslint-disable-next-line consistent-return
                    onChange={e => {
                      props.actions.setError({ name: 'testSelectiontissueCollectionDate', value: '' });
                      props.actions.onChange({ name: schema.testSelection.tissueCollectionDate, value: undefined });
                      if (!e) return;
                      const val = moment(e);
                      //invalid.isBefore(another), invalid.isAfter(another) return false
                      const isFutureDate = val.isAfter(new Date(),'day');
                      const isPastDate = val.isBefore(moment().year(1900).month(0).date(1),'day');
                      if (!(isFutureDate || isPastDate)) {
                        onInputChange({
                          target: {
                            name: 'tissueCollectionDate',
                            value: val.format('YYYY-MM-DD').toString(),
                          },
                        });
                      } else {
                        props.actions.setError({ name: 'testSelectiontissueCollectionDate', value: 'Please select a date between 01-01-1900 to today' });
                      }
                    }}
                  />
                </Grid>
              </Grid>
              )}
            </Grid>
          ))}
      </Grid>
      <AlertMessage
        message="Changing your test selection requires an update to the next section on clinical history section."
        showMessage={showMessage}
        closeMessage={closeMessage}
      />
    </Grid>
  );
}
const CustomColorCheckbox = withStyles({
  root: {
    '&.Mui-disabled .MuiSvgIcon-root': {
      color: '#8b8b98',
      backgroundColor: '#d0d0da',
    },
  },
})(props => <Checkbox color="default" {...props} />);
export default React.memo(TestSelection);

TestSelection.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};
