import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import HttpService from '../../service/interceptor';
import * as actionTypes from './actionsTypes';

export const getRolesData = params => (dispatch) => {
  const query = `?${new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());

  return HttpService.get(`/api/v1/roles${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getRoles(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.rolesError(err));
      dispatch(closeSpinner());
    });
};

export const addRolesData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.post('/api/v1/roles', params)
    .then(({ data }) => {
      dispatch(actionTypes.saveRoles(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.rolesError(err));
      dispatch(closeSpinner());
    });
};
export const editRolesData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.put('/api/v1/roles', params)
    .then(({ data }) => {
      dispatch(actionTypes.updatRoles(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.rolesError(err));
      dispatch(closeSpinner());
    });
};
export const deleteRolesData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.delete(`/api/v1/roles/${params.uuid}`)
    .then(({ data }) => {
      dispatch(actionTypes.deleteRoles(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.rolesError(err));
      dispatch(closeSpinner());
    });
};

export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePageRoles(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeRoles(params));
};
export const getClaims = (params = {}) => (dispatch) => {
  const query = `?${new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());
  return HttpService.get(`/api/v1/claims${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getClaims(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.rolesError(err));
      dispatch(closeSpinner());
    });
};
