import { createAction } from 'redux-actions';

export const REQUEST_ADD = '@spinner/REQUEST_ADD';
export const REQUEST_POP = '@spinner/REQUEST_POP';
export const REQUEST_SHOW = '@spinner/REQUEST_SHOW';
export const REQUEST_CLOSE = '@spinner/REQUEST_CLOSE';

export const requestAdd = createAction(REQUEST_ADD);
export const requestClose = createAction(REQUEST_POP);

export function showSpinner() {
  return dispatch => dispatch(requestAdd());
}
export function closeSpinner() {
  return dispatch => dispatch(requestClose());
}
export function showAlert({ alerts, severity }) {
  return {
    type: REQUEST_SHOW,
    alerts,
    severity,
  };
}
export function closeAlert() {
  return {
    type: REQUEST_CLOSE,
  };
}
