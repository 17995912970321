import { constants } from '../../config/constants';
import { EarlyStageClinicalHistoryInfo } from './early-stage-clinical-history-info';
import { EarlyStageDiagnosisInfo } from './early-stage-diagnosis-info';
import { Order } from './order';
import { RevealOrderingInfo } from './reveal-ordering-info';

class RevealOrder extends Order {
  constructor(params) {
    super(params);

    this.revealOrderingInfo = new RevealOrderingInfo(params.guardantRevealOrderingInfo);
    this.diagnosisInfo = new EarlyStageDiagnosisInfo(params.earlyStageDiagnosisInfo);
    this.clinicalHistoryInfo = new EarlyStageClinicalHistoryInfo(
      params.earlyStageClinicalHistoryInfo,
    );
  }

  buildFormSummaryForPrintModal = () => ([
    this.formatPhysicianInfoForPrintModal(),
    this.patientInfo.formatForPrintModal(),
    this.revealOrderingInfo.formatForPrintModal(),
    this.diagnosisInfo.formatForPrintModal(),
    this.clinicalHistoryInfo.formatForPrintModal(),
    this.billingInfo.formatForPrintModal(),
  ]);

  checkRequiredFields = () => (
    this.accountInfo.checkRequiredFields()
    && this.physicianInfo.checkRequiredFields()
    && this.patientInfo.checkRequiredFields()
    && this.diagnosisInfo.checkRequiredFields()
    && this.revealOrderingInfo.checkRequiredFields()
  );

  checkNeededSurgicalResectionDateMissing = () => (
    this.revealOrderingInfo.checkNeededSurgicalResectionDateMissing()
  );

  selectedTests = () => [constants.testTypes.LUNAR_PREFIX]
}

export { RevealOrder };
