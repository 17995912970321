import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialogBox from './commons/components/ModalDialogBox';
import ShareModel from './ShareModel';

const PhysicianSharesView = (props) => {
  const {
    authenticityToken,
    patientName,
    patientId,
    number,
    accessClass,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const showModalWindow = () => {
    setShowModal(!showModal);
  };
  const closeWindow = () => {
    setShowModal(!showModal);
  };
  const closeShareWindow = () => {
    setShowShareModal(!showShareModal);
  };
  const showShareModalWindow = () => {
    setShowShareModal(!showShareModal);
  };
  return (
    <React.Fragment>
      <div className="share-index__body">
        <div className="share-index__body__header">
          <div className="share-index__body__header-text margin-right__40px">
            <div className="share-index__body__header-title">
              Users with Access to Your Reports
              <i className="fa fa-info-circle shares_info_circle modal-info" onClick={showModalWindow} />
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="icon-container no-spinner" modal-toggle="" href="#">
            <i className="button__left-logo button__icon-dashboard-add fa fa-plus" onClick={showShareModalWindow} />
          </a>
        </div>
        <div className="share-index__collaborators-container">
          <div className="share-index__collaborator">
            <div className="share-index__collaborator__top">
              <div className="share-index__collaborator__top-inner">
                <em>You have not yet granted any others access to all your reports.</em>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialogBox
        show={showModal}
        showModal={closeWindow}
        ModalContent="Manage who has access to Guardant360 reports you have ordered."
      />
      <ShareModel
        show={showShareModal}
        closeShareModal={closeShareWindow}
        patientName={patientName}
        patientId={patientId}
        number={number}
        authenticityToken={authenticityToken}
        accessClass={accessClass}
      />
    </React.Fragment>
  );
};
export default PhysicianSharesView;
PhysicianSharesView.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  accessClass: PropTypes.string.isRequired,
  patientName: PropTypes.string,
  patientId: PropTypes.string,
  number: PropTypes.number,
};
PhysicianSharesView.defaultProps = {
  patientName: '',
  patientId: '',
  number: null,
};
