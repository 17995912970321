/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Paper, Table, TableBody, TableContainer, TableHead,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Pagination from './Pagination';
import Row from './Row';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    placeSelf: 'center',
  },
  tableHead: {
    textTransform: 'uppercase',
    backgroundColor: '#cfd3d8',
  },
});
const TableComponent = (props) => {
  const classes = useStyles();
  const { schema, rows, handleMenuClick } = props;
  const headers = Object.keys(schema);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table>
          <TableHead className={classes.tableHead}>
            <Row
              upperCase={false}
              rows={schema}
              handleMenuClick={handleMenuClick}
              headers={headers}
            />
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
              <Row
                upperCase={false}
                key={row.id}
                rows={row}
                handleMenuClick={handleMenuClick}
                headers={headers}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default TableComponent;
