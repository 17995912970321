import { TestResultSerializer } from './test-result';

export class PatientSummarySerializer {
  static deserialize = summary => (
    summary && {
      accessionId: summary.request_id,
      aditionalRecipient: summary.secondary_physician_name,
      collectionDate: summary.collection_date,
      diagnosis: summary.cancer_type,
      dob: summary.date_of_birth,
      gender: summary.gender,
      mrn: summary.mrn,
      name: summary.name,
      physicianFax: summary.fax,
      physicianName: summary.physician_name,
      practiceFullAddress: summary.full_address,
      practiceName: summary.practice_name,
      practicePhoneNumber: summary.phone_number,
      patientPhoneNumber: summary.patient_phone_number,
      receiptDate: summary.received_date,
      reportDate: summary.report_date,
      reportDateLabel: summary.report_date_label,
      revisionDate: summary.revision_date,
      revisionDateLabel: summary.revision_date_label,
      specimen: summary.specimen,
      status: summary.full_status,
      testNumber: summary.test_number,
      type: summary.type,
      baselineTimepoint: summary.baseline_timepoint,
      surgeryDate: summary.surgery_date,
      testResult: TestResultSerializer.deserialize(summary.test_result),
      showTestResult: summary.show_test_result,
    }
  );
}
