/* eslint-disable no-nested-ternary, no-mixed-operators */
import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import Controls from '../../admin/common/components/forms/controls';
import ActionAreaCard from './SignAndSubmitReview';
import ValidationJson from '../validate.json';
import Validation from '../../admin/common/utils/Validation';
import { schema } from '../schema';
import {
  getBillingInfoStatus,
  getClinicalHistoryStatus,
  getDiagnosisStatus,
  getPatientInfoStatus,
  getPhysicianInfoStatus,
  getTestSelectionStatus,
  getDisease,
  allowG360,
  allowReveal
} from '../helpers';
import { getTests } from '../utils/helper';
import Dialog from '../utils/Dailog';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '54%',
  overflow: 'auto',
  height: '75%',
  bgcolor: 'background.paper',
  border: '2px solid #f8f8f8',
  p: 4,
};

const closeButtonStyle = {
  position: 'absolute',
  top: '5px',
  right: '10px',
  fontSize: '20px',
  cursor: 'pointer',
};

const useStyles1 = makeStyles(theme => ({
  footerWrapper: {
    paddingTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white',
  },
  editButtonStyle: {
    cursor: 'pointer',
  },
  Complete: {
    color: theme.palette.success.main,
  },
  Incomplete: {
    color: theme.palette.warning.main,
  },
  Required: {
    color: theme.palette.error.main,
  },
  NotStarted: {
    color: theme.palette.primary.main,
  },
  helperText: {
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    'overflow-y': 'clip'
  }
}));

const IPropTypes = {
  newOrder: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
  roleBasedAuthorisation: PropTypes.object.isRequired,
};

const ReviewOrderPopUp = (props) => {
  const { newOrder, history, roleBasedAuthorisation } = props;
  const orderManagement = newOrder && newOrder.toJS();
  const isEditVisible = !orderManagement.roOpenFromDashboard || (roleBasedAuthorisation.toJS().reviewModelEditOptions && orderManagement.ordersType === 'pending');
  const isButtonEditVisible = !orderManagement.roOpenFromDashboard || orderManagement.ordersType === 'pending';
  const {
    esignature = {}, status: orderStatus = '', portalOrderId, relevantClinicalHistory = [],
  } = orderManagement.schema.order || {};

  const orderId = esignature.envelopeId || portalOrderId;
  const {
    schema: {
      order,
      errors,
      order: {
        patient,
        billing,
        diagnosis,
      },
    },
    panal: {
      tab,
    },
    infoType,
    delegee,
    cancerTypeList,
  } = orderManagement;
  const patientStatus = getPatientInfoStatus(patient, tab, true, errors);
  const diagnosisStatus = getDiagnosisStatus(diagnosis, tab, true, cancerTypeList);
  const testSelectionStatus = getTestSelectionStatus(order, tab, true, cancerTypeList);
  const clinicalHistoryStatus = getClinicalHistoryStatus(order, tab, true, cancerTypeList);
  const physicianStatus = getPhysicianInfoStatus(order, tab, true);
  const billingStatus = getBillingInfoStatus(billing, infoType, tab, true);
  const detPhysician = newOrder.getIn(schema.physician.authorizingProvider) || {};

  const accountNumber = newOrder.getIn(schema.physician.client)?.accountNumber;

  useEffect(() => {
    if (orderManagement.roOpenFromDashboard && detPhysician?.email && accountNumber) {
      props.actions.getEmbadedSigneeStatus(props.viewAsUser?.email, detPhysician?.email,
        accountNumber);
    }
  }, [orderManagement.roOpenFromDashboard]);

  const downloadPrintPDF = () => {
    props.actions.downloadOrderForm(orderId, history);
  };

  const closeReviewModal = () => {
    if (orderManagement.roOpenFromDashboard === true) {
      history.push('/new_orders');
    }
    props.actions.onChange({ name: ['isReviewOrderOpen'], value: false });
    props.actions.onChange({ name: ['roOpenFromDashboard'], value: false });
  };

  const finalizeOrder = () => {
    props.actions.onChange({ name: ['isSignAndSubmitOpen'], value: true });
  };
  const eSign = () => {
    /*
      ECS-8647: keep only valid items in secondaryIcdCodes array.
      Long term solution is to never let the data go wrong in the first place.
    */
    if(orderManagement.schema?.order?.diagnosis?.secondaryIcdCodes && orderManagement.schema.order.diagnosis.secondaryIcdCodes.length > 0){
      orderManagement.schema.order.diagnosis.secondaryIcdCodes = orderManagement.schema.order.diagnosis.secondaryIcdCodes.filter(i => i);
    }
    if (orderManagement.schema?.order?.diagnosis?.trfDisplayName) {
      orderManagement.schema.order.diagnosis.trfDisplayName = undefined;
    }
    props.actions.eSignAndSubmit({ data: orderManagement.schema.order, embaded: true, delegee, signeeEmail: props?.viewAsUser?.email })
      .then((esign) => {
        const {
          productFamily,
        } = orderManagement.schema.order;
        const findata = {
          order: {
            portalOrderId: orderManagement.schema.order.portalOrderId,
            orderingUser: orderManagement.schema.order.orderingUser,
            productFamily,
            esignature: esign.data,
          },
        };
        props.actions.updateSignature(esign.data.envelopeId).then(() => {
          props.actions.finalizeOrder(findata).then(() => {
            window.location.href = esign.data.embeddedUrl;
          });
        });
      });
  };
  const getRelevanCLinical = (relevantClinical, productFamily) => {
    let cilinicalDisplay = '';
    if (productFamily && productFamily === 'G360') {
      cilinicalDisplay = relevantClinicalHistory
        ?.filter(item => item.questionNumber === 1)[0]?.answerValue;
    } if (productFamily && productFamily === 'REVEAL') {
      if (relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue === 'Yes') {
        cilinicalDisplay = 'Currently on therapy';
      } else if (relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue === 'No') {
        cilinicalDisplay = 'Not Currently on therapy';
      }
    }
    return cilinicalDisplay;
  };
  const onInputChange = (e) => {
    const { name, value } = e.target;
    const { clinicianName } = ValidationJson;
    if (clinicianName[name]) {
      let error = Validation(name, value, clinicianName) || '';
      error = clinicianName[name].required && error.trim().length > 0 && (value || '').length === 0 ? 'Required' : error;
      props.actions.setError({ name, value: error });
    }
    props.actions.onChange({ name: schema.testSelection[name], value });
  };

  const classes = useStyles1();
  const productFamily = newOrder.getIn(schema?.testSelection?.productFamily)?.id;
  const { stage, primaryIcdCode, disease } = orderManagement.schema.order.diagnosis || {};
  const trfDisplayName = cancerTypeList?.filter(i => i?.diagnosis?.toUpperCase() === disease?.toUpperCase())[0]?.trfDisplayName;

  // Disable finalize button if required input is missing anywhere in the order form
  const isFinalizeButtonEnabled = ['INCOMPLETE', 'COMPLETE'].includes(patientStatus.toUpperCase())
    && ['INCOMPLETE', 'COMPLETE'].includes(diagnosisStatus.toUpperCase())
    && ['INCOMPLETE', 'COMPLETE'].includes(testSelectionStatus.toUpperCase())
    && ((productFamily === 'REVEAL' && ['INCOMPLETE', 'COMPLETE'].includes(clinicalHistoryStatus.toUpperCase())) || productFamily === 'G360')
    && ['INCOMPLETE', 'COMPLETE'].includes(physicianStatus.toUpperCase());

  const handleEditButton = (e, tabIndex) => {
    if (tabIndex === 2) {
      const stage = newOrder.getIn(schema.diagnosis.stage);
      const disease = getDisease(orderManagement.schema.order.diagnosis, orderManagement.cancerTypeList);
      const isReveal = allowReveal(stage, disease);
      const isG360 = allowG360(stage, disease);
      props.actions.onChange({ name: ['isReveal'], value: isReveal });
      props.actions.onChange({ name: ['isG360'], value: isG360 });
    }
    props.actions.onTabChange({
      key: ['panal', 'tab'],
      value: tabIndex,
    });
    props.actions.onChange({ name: ['isReviewOrderOpen'], value: false });
  };

  return (
    <div>
      <Modal
        open={orderManagement.isReviewOrderOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            color="error"
            sx={closeButtonStyle}
            onClick={closeReviewModal}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Review Order
          </Typography>
          <Card variant="outlined" sx={{ padding: '20px', marginTop: '10px' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={11}>
                <Typography component="span" variant="h6" sx={{ mr: '20px' }}>
                  Patient Information
                </Typography>
                <Typography component="span" variant="body2" className={patientStatus === 'Not Started' ? classes.NotStarted : classes[patientStatus]}>
                  {patientStatus === 'Required' ? 'Required fields missing' : patientStatus}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {
                  isEditVisible && <EditIcon className={classes.editButtonStyle} color="primary" onClick={e => handleEditButton(e, 0)} />
                }
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Name:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {`${newOrder.getIn(schema?.patient?.firstName) || ''}
                  ${newOrder.getIn(schema?.patient?.lastName) || ''}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  DOB:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {' '}
                  {newOrder.getIn(schema?.patient?.dob) ? moment(newOrder.getIn(schema?.patient?.dob)).format('MM-DD-YYYY').toString() : ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Sex:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {' '}
                  {newOrder.getIn(schema.patient?.sex || '')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Patient Type:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {' '}
                  {newOrder.getIn(schema.patient?.type || '')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Status:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {' '}
                  {newOrder.getIn(schema.patient.hospitalizationStatus) || ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  MRN:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {' '}
                  {` ${newOrder.getIn(schema.patient.identifiers, [{}])[0] ? newOrder.getIn(schema.patient.identifiers, [{}])[0].id || '' : ''}`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ padding: '20px', marginTop: '10px' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={11}>
                <Typography variant="h6" component="span" sx={{ mr: '20px' }}>
                  Diagnosis
                  {' '}
                </Typography>
                <Typography component="span" variant="body2" className={diagnosisStatus === 'Not Started' ? classes.NotStarted : classes[diagnosisStatus]}>
                  {diagnosisStatus === 'Required' ? 'Required fields missing' : diagnosisStatus}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {
                  isEditVisible && <EditIcon className={classes.editButtonStyle} color="primary" onClick={e => handleEditButton(e, 1)} />
                }
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Cancer: </b>
                  {trfDisplayName || ''}
                  {trfDisplayName && ','}
                  {' '}
                  {stage || ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <b>Primary Code:</b>
                  {' '}
                  {newOrder.getIn(schema.diagnosis.primaryIcdCode, {})?.icdCode || ''}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ padding: '20px', marginTop: '10px' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={11}>
                <Typography variant="h6" component="span" sx={{ mr: '20px' }}>
                  Test Selection
                </Typography>
                <Typography component="span" variant="body2" className={testSelectionStatus === 'Not Started' ? classes.NotStarted : classes[testSelectionStatus === 'Requires Diagnosis' ? 'Required' : testSelectionStatus]}>
                  {testSelectionStatus === 'Required' ? 'Required fields missing' : testSelectionStatus}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {
                  isEditVisible
                  && (
                    <IconButton disabled={(!(stage && getDisease(diagnosis, cancerTypeList) && primaryIcdCode))}>
                      <EditIcon className={classes.editButtonStyle} color="primary" onClick={e => handleEditButton(e, 2)} />
                    </IconButton>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {getTests([...newOrder.getIn(schema.testSelection.testOrdered)?.filter(x => x !== 'G360MR'),
                    newOrder.getIn(schema.testSelection.followOnTestOrdered)])}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ padding: '20px', marginTop: '10px' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={11}>
                <Typography variant="h6" component="span" sx={{ mr: '20px' }}>
                  Clinical History
                </Typography>
                <Typography component="span" variant="body2" className={clinicalHistoryStatus === 'Not Started' ? classes.NotStarted : classes[clinicalHistoryStatus === 'Requires Test Selection' ? 'Required' : clinicalHistoryStatus]}>
                  {clinicalHistoryStatus === 'Required' ? 'Required fields missing' : clinicalHistoryStatus}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {
                  isEditVisible
                  && (
                    <IconButton disabled={!productFamily}>
                      <EditIcon className={classes.editButtonStyle} color="primary" onClick={e => handleEditButton(e, 3)} />
                    </IconButton>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {
                    getRelevanCLinical(relevantClinicalHistory, productFamily)

                  }

                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ padding: '20px', marginTop: '10px' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={11}>
                <Typography variant="h6" component="span" sx={{ mr: '20px' }}>
                  Physician Information
                </Typography>
                <Typography component="span" variant="body2" className={physicianStatus === 'Not Started' ? classes.NotStarted : classes[physicianStatus]}>
                  {physicianStatus === 'Required' ? 'Required fields missing' : physicianStatus}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {
                  isEditVisible && <EditIcon className={classes.editButtonStyle} color="primary" onClick={e => handleEditButton(e, 4)} />
                }
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Account:
                </Typography>
                <Typography component="span" variant="body2">
                  {` ${newOrder.getIn(schema.physician.client)?.practiceName || ''}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Physician name:

                </Typography>
                <Typography component="span" variant="body2">
                  {` ${newOrder.getIn(schema.physician.authorizingProvider)
                    ?.firstName || ''
                  } ${newOrder.getIn(schema.physician.authorizingProvider)
                    ?.lastName || ''
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Account number:
                </Typography>
                <Typography component="span" variant="body2">
                  {` ${newOrder.getIn(schema.physician.client)?.accountNumber || ''}`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ padding: '20px', marginTop: '10px' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={11}>
                <Typography variant="h6" component="span" sx={{ mr: '20px' }}>
                  Billing Information
                </Typography>
                <Typography component="span" variant="body2" className={billingStatus === 'Not Started' ? classes.NotStarted : classes[billingStatus]}>
                  {billingStatus === 'Required' ? 'Required fields missing' : billingStatus}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {
                  isEditVisible && <EditIcon className={classes.editButtonStyle} color="primary" onClick={e => handleEditButton(e, 5)} />
                }
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Billing Type:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {` ${newOrder.getIn(schema.billing.billingIndicator) === 'Institution' ? 'Institution (e.g. hospital)' : newOrder.getIn(schema.billing.billingIndicator) || ''}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Insured Name:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {` ${newOrder.getIn(schema.billing.subscriberFirstName) || ''
                  } ${newOrder.getIn(schema.billing.subscriberLastName) || ''}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Primary Insurance:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {` ${newOrder.getIn(schema.billing.company) || ''}`}
                </Typography>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Group#:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {` ${newOrder.getIn(schema.billing.groupNumber) || ''}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  Policy#:&nbsp;
                </Typography>
                <Typography component="span" variant="body2">
                  {`${newOrder.getIn(schema.billing.policyNumber) || ''}`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ padding: '20px', marginTop: '10px' }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Typography variant="h6" component="div">
                  Blood Specimen Retrieval
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Controls.DatePicker
                  mask="__-__-____"
                  className={classes.input}
                  label="Collection Date"
                  name="collectionDate"
                  type="date"
                  id="input"
                  fullWidth
                  inputFormat="MM-DD-YYYY"
                  minDate={moment().year(1900).month(0).date(1)}
                  disabled={!isEditVisible}
                  value={newOrder.getIn(schema.testSelection.collectionDate) || null}
                  error={orderManagement.errors.collectionDate}
                  FormHelperTextProps={{ classes: { root: classes.helperText } }}
                  // eslint-disable-next-line consistent-return
                  onChange={e => {
                    const name = 'collectionDate';
                    props.actions.setError({ name: name, value: '' });
                    props.actions.onChange({ name: schema.testSelection.collectionDate, value: undefined });
                    if (!e) return;
                    const val = moment(e);
                    if(!val.isValid()){
                      props.actions.setError({ name: name, value: 'Please select a valid date in MM-DD-YYYY format' });
                      return;
                    }
                    if (val.isBefore(moment().year(1900).month(0).date(1),'day')) {
                      props.actions.setError({ name: name, value: 'Please select a date greater than or equal to 01-01-1900' });
                      return;
                    }
                    props.actions.onChange({ name: schema.testSelection[name], value: val.format('YYYY-MM-DD').toString() });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="600" component="span" variant="subtitle2">
                  <Controls.Input
                    name="clinicianName"
                    id="input"
                    fullWidth
                    onChange={onInputChange}
                    label="Lab Clinician First and Last Name"
                    error={orderManagement.errors.clinicianName}
                    value={newOrder.getIn(schema.testSelection.clinicianName)}
                    inputProps={{ maxLength: 22 }}
                    disabled={!isEditVisible}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card variant="outlined" sx={{ padding: '20px', marginTop: '10px' }}>
            <Typography variant="h6" component="div">
              Reminders
            </Typography>
            <Typography variant="body2"><b>Signature</b></Typography>
            <Typography variant="body2">
              Authorizing provider must either provide a digital signature
              (E-Sign & Submit) or sign printed form (Print, Sign & Send).
            </Typography>
            <Typography variant="body2"><b>Documents</b></Typography>
            <Typography variant="body2">
              Send the following with blood sample
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>
                  . Second page of Test Requisition Form *signed by patient*
                </li>
                <li> . Patient face sheet</li>
                <li> . Insurance card (front and back)</li>
                <li>
                  . Relevant test results (Pathology/cytology report, IHC, FISH)
                </li>
              </ul>
            </Typography>
          </Card>

          <div className={classes.footerWrapper}>
            <Button variant="outlined" onClick={closeReviewModal}>
              Back
            </Button>
            {(orderStatus === 'PRINTED' || orderStatus === 'SIGNED' || (orderStatus === 'PENDINGSIGNATURE' && roleBasedAuthorisation.toJS().isSuperGHAdmin) || (!isButtonEditVisible && !orderManagement?.isConsentExists && (props.viewAsUser.email !== detPhysician.email))) ? (
              <Button variant="contained" onClick={downloadPrintPDF} disabled={!roleBasedAuthorisation.toJS().reviewModelPrintOption}>
                Print
              </Button>
            ) : !isButtonEditVisible && (props.viewAsUser.email === detPhysician.email || orderManagement?.isConsentExists) ? (
              <Button variant="contained" onClick={eSign} disabled={!roleBasedAuthorisation.toJS().reviewModelESignButton}>
                E-sign
              </Button>
            ) : (
              <Button variant="contained" onClick={finalizeOrder} disabled={!isFinalizeButtonEnabled || !roleBasedAuthorisation.toJS().finalizeOrder}>
                Finalize Order
              </Button>
            )}
          </div>
        </Box>
      </Modal>
      <ActionAreaCard {...props} />
      {orderManagement.selectedCard === 1
        && <Dialog {...props} />}
    </div>
  );
};

export default ReviewOrderPopUp;
ReviewOrderPopUp.propTypes = IPropTypes;
