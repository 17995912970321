import { TestSelectionSerializer } from './test-selection';
import { DiagnosisSerializer } from './diagnosis';
import { LateStageClinicalHistorySerializer } from './late-stage-clinical-history';
import { CurrentTherapySerializer } from './current-therapy';

class G360Serializer {
  static deserialize = order => ({
    testSelectionInfo: TestSelectionSerializer.deserialize(
      order.testOrdered, order.specimens,
    ),
    diagnosisInfo: DiagnosisSerializer.deserialize(order.diagnosis),
    currentTherapyInfo: CurrentTherapySerializer.deserialize(order.advancedCancerStage),
    clinicalHistoryInfo: LateStageClinicalHistorySerializer.deserialize(
      order.relevantClinicalHistory,
    ),
  });

  static serialize = ({
    testSelectionInfo, diagnosisInfo, currentTherapyInfo, clinicalHistoryInfo,
  }) => ({
    testOrdered: TestSelectionSerializer.serialize(testSelectionInfo),
    diagnosis: DiagnosisSerializer.serialize(diagnosisInfo),
    advancedCancerStage: CurrentTherapySerializer.serialize(currentTherapyInfo),
    relevantClinicalHistory: LateStageClinicalHistorySerializer.serialize(
      clinicalHistoryInfo,
    ),
  });
}

export { G360Serializer };
