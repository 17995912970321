import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  enabled: PropTypes.bool,
  handleContainerArrowClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};

const defaultProps = {
  enabled: false,
  handleContainerArrowClick: () => {},
};

const GenericHeader = ({ enabled, handleContainerArrowClick, label }) => {
  const arrowDirection = enabled ? 'down' : 'right';
  return (
    <div className="h4 requests-list__header">
      <div className="h4 requests-list__header">
        <div className="requests-list__header__label">
          <button
            className="requests-list__header-button"
            onClick={handleContainerArrowClick}
            type="button"
          >
            <div className={`fa fa-angle-${arrowDirection}
                        expanded dashboard-angle-reactified`}
            />
            <div className="requests-list__header__label__name">
              {label}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

GenericHeader.propTypes = IPropTypes;
GenericHeader.defaultProps = defaultProps;

export default GenericHeader;
