import { constants } from '../../../../config/constants';
import { saveSelfInsuredBillingInfo } from './billing-info';

const patientActionTypes = {
  ADD_PATIENT_DATA: 'ADD_PATIENT_DATA',
};

const savePatientInfo = (info, hideBanner) => async (dispatch, getState) => {
  dispatch({
    type: patientActionTypes.ADD_PATIENT_DATA,
    info,
    hideBanner,
  });

  const { orders: { activeOrder: { billingInfo } } } = getState();
  const { billingInformation: { selfInsuredValue } } = constants;

  if (billingInfo.insuredRelationship === selfInsuredValue) {
    dispatch(saveSelfInsuredBillingInfo());
  }
};

export { savePatientInfo, patientActionTypes };
