const diagnosisActionTypes = {
  ADD_DIAGNOSIS_DATA: 'ADD_DIAGNOSIS_DATA',
};

const OTHER_DISEASE = 'Other';

const AddDiagnosisInfo = (info, hideBanner) => ({
  type: diagnosisActionTypes.ADD_DIAGNOSIS_DATA,
  info,
  hideBanner,
});

export {
  AddDiagnosisInfo, diagnosisActionTypes, OTHER_DISEASE,
};
