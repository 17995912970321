import React from 'react';
import PropTypes from 'prop-types';

import { constants } from '../../../../config/constants';
import { TestRequisitionButton } from '../test-requisition-button';
import { TestBeginButton } from '../test-begin-button';

import styles from './new-test-requisition.module.scss';

const IPropTypes = {
  productFamily: PropTypes.string.isRequired,
  logosGroup: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  testOptionsTitle: PropTypes.string.isRequired,
  userPermissions: PropTypes.shape({
    canOnlyDemoOrders: PropTypes.bool.isRequired,
    onlyNewOrders: PropTypes.bool.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const NewTestRequisition = ({
  productFamily, logosGroup, testOptionsTitle, disabled, userPermissions,
}) => (
  <div className={styles.container}>
    <div className={styles.testOptionsTitle}>
      {testOptionsTitle}
    </div>
    {logosGroup}
    <TestBeginButton productFamily={productFamily} disabled={disabled} />
    {disabled && (
      <div className={styles.warningTextInfo}>
        {constants.etrfOrdering.maxPendingOrdersReachedMessage}
      </div>
    )}
    <TestRequisitionButton
      productFamily={productFamily}
      disabled={userPermissions.canOnlyDemoOrders || userPermissions.onlyNewOrders}
    />
  </div>
);

NewTestRequisition.propTypes = IPropTypes;
NewTestRequisition.defaultProps = defaultProps;

export { NewTestRequisition };
