import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchConfiguration } from '../../../../store/actions/orders/static-data';
import { receiveOrders } from '../../../../store/actions/orders/dashboard';
import { updateLoadingCount } from '../../../../store/actions/global';

import { OrdersListContainer } from './orders-list-container';

const mapStateToProps = ({ orders: { dashboard: { orders } } }) => ({
  orders,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchConfiguration: () => fetchConfiguration,
  receiveOrders,
  updateLoadingCount,
}, dispatch);

const ConnectedOrdersListContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(OrdersListContainer);

export { ConnectedOrdersListContainer as OrdersListContainer };
