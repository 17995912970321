import { connect } from 'react-redux';

import { PatientNameContainer } from './patient-name-container';

const mapStateToProps = store => ({
  data: store.patientPage.multiProduct.info.patientSummary,
  isAdminLogged: store.patientPage.multiProduct.info.loggedUser.admin,
});

const ConnectedPatientNameContainer = connect(
  mapStateToProps,
)(PatientNameContainer);

export { ConnectedPatientNameContainer as PatientNameContainer };
