import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ConfirmationOverlay } from '../confirmation-overlay';
import { constants } from '../../../config/constants';

const IPropTypes = {
  toggleConfirmationModal: PropTypes.func.isRequired,
  hideErrorBanner: PropTypes.func.isRequired,
  discardOrder: PropTypes.func.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  productFamily: PropTypes.string.isRequired,
};

const DiscardOverlay = ({
  toggleConfirmationModal, hideErrorBanner, discardOrder,
  history, productFamily, updateLoadingCount,
}) => {
  const emrUser = useSelector(state => state.global.initialProps.userPermissions.onlyNewOrders);

  const onClick = useCallback(() => {
    toggleConfirmationModal();
    hideErrorBanner();
  }, [toggleConfirmationModal, hideErrorBanner]);

  const onConfirm = useCallback(async () => {
    onClick();

    updateLoadingCount(1);
    if (emrUser) {
      await discardOrder();
      window.close();
    } else {
      await discardOrder(() => history.push('/orders'));
    }
    updateLoadingCount(-1);
  }, [emrUser, history]);

  const productFamilyTest = productFamily === constants.etrfOrdering.productFamilies.g360 ? 'Guardant360' : 'Reveal';

  return (
    <ConfirmationOverlay
      toggleConfirmationModal={onClick}
      onConfirmCallback={onConfirm}
      header="Please Confirm"
      contentLineOne="Are you sure you want to discard"
      contentLineTwo={`the current ${productFamilyTest} test requisition?`}
      leftButtonText="BACK"
      rightButtonText="YES, DISCARD"
    />
  );
};


DiscardOverlay.propTypes = IPropTypes;

export { DiscardOverlay };
