import { TissueSpecimenRetrieval } from './tissue-specimen-retrieval-info';
import { constants } from '../../config/constants';

const { INCOMPLETE } = constants.printModal.infoCompleteness;
class TestSelectionInfo {
  constructor(params) {
    this.testSelectionType = params.testSelectionType || {};
    this.treatmentResponse = params.treatmentResponse || {};
    this.tissueSpecimenRetrieval = new TissueSpecimenRetrieval(
      params.tissueSpecimenRetrieval || {},
    );
  }

  isComplete = () => !!this.testSelectionType.text || !!this.treatmentResponse.text;

  formatForPrintModal = () => {
    const isCompleted = this.isComplete();

    return {
      title: 'TEST SELECTION',
      info: isCompleted
        ? [this.testSelectionType.text, this.treatmentResponse.text].filter(Boolean).join(', ')
        : INCOMPLETE,
      isCompleted,
    };
  }

  checkRequiredFields = () => !!(this.testSelectionType.code || this.treatmentResponse.code);

  selectedProductIds = () => ([
    ...(this.testSelectionType.code || []),
    ...(this.treatmentResponse.code || []),
  ]);
}

export { TestSelectionInfo };
