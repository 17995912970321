import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { logger } from '../../util/logger';

class OrdersErrorHandler extends Component {
  state = { hasError: false, error: '' };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
    });
    logger.error(errorInfo);
  }

  render() {
    const { children } = this.props;
    if (this.state.hasError) {
      return <Typography component="h2" sx={{ color: 'secondary' }}>{this.state.error.message}</Typography>;
    }
    return children;
  }
}

export default OrdersErrorHandler;
OrdersErrorHandler.propTypes = {
  children: PropTypes.element.isRequired,
};
