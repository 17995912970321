import { combineReducers } from 'redux';
import { errorsReducer } from './errors';
import { globalReducer } from './global';
import { patientPageReducer } from './patient-page';
import { requestsReducer } from './requests';
import { ordersReducer } from './orders';
import AccountsReducer from '../../components/admin/accounts/reducers/AccountsReducer';
import spinnerReducer from '../../components/admin/common/utils/spinner/reducer';
import siteReducer from '../../components/admin/sites/reducers/SitesReducer';
import RoleAssignmentReducer from '../../components/admin/roleAssignment/reducers/RoleAssignmentReducer';
import RolesReducer from '../../components/admin/roles/reducer/RolesReducer';
import UserReducer from '../../components/admin/users/reducers/UsersReducer';
import SiteAssignmentReducer from '../../components/admin/siteAssignment/reducers/SiteAssignmentReducer';
import ProjectAssignmentReducer from '../../components/admin/project-assignment/reducers/ProjectAssignmentReducer';
import PatientManagmentReducer from '../../components/admin/patients/reducers/PatientManagmentReducer';
import SharesReducers from '../../components/shares/reducers/SharesReducers';
import MatchGenReducer from '../../components/admin/matchgen/reducers/MatchGenReducer';
import NewOrderReducer from '../../components/addEditOrder/reducers/NewOrderReducer';
import OrdersDashboardReducer from '../../components/new-orders/orders-dashboard/reducers/OrdersDashboardReducer';

const rootReducer = combineReducers({
  ordersDashboard: OrdersDashboardReducer,
  orders: ordersReducer,
  errors: errorsReducer,
  global: globalReducer,
  patientPage: patientPageReducer,
  requests: requestsReducer,
  accounts: AccountsReducer,
  spinner: spinnerReducer,
  sites: siteReducer,
  roleAssignment: RoleAssignmentReducer,
  roles: RolesReducer,
  users: UserReducer,
  siteAssignment: SiteAssignmentReducer,
  projectAssignment: ProjectAssignmentReducer,
  patients: PatientManagmentReducer,
  shares: SharesReducers,
  matchGen: MatchGenReducer,
  newOrder: NewOrderReducer,
});


export { rootReducer };
