import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './requests-table.module.scss';
import { REQUESTS_COLUMNS, sortRequests } from '../../../helpers/requests-table';
import { Request } from '../../../models/request';
import { formatDate } from '../../../helpers/date';
import { RequestDownloadMenu } from '../request-download-menu';

const IPropTypes = {
  requests: PropTypes.arrayOf(PropTypes.instanceOf(Request)).isRequired,
};

const RequestsTable = ({ requests }) => {
  const [orderByColumn, setOrderByColumn] = useState(null);
  const [isOrderAsc, setIsOrderAsc] = useState(false);
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState(null);

  const columns = [
    { key: REQUESTS_COLUMNS.REPORT_STATUS, text: 'Report Status' },
    { key: REQUESTS_COLUMNS.PATIENT_NAME, text: 'Patient' },
    { key: REQUESTS_COLUMNS.DATE_OF_BIRTH, text: 'DOB', tooltip: 'Date of Birth' },
    { key: REQUESTS_COLUMNS.CANCER_TYPE, text: 'Cancer Type' },
    { key: REQUESTS_COLUMNS.REQUEST_ID, text: 'Request ID' },
    { key: REQUESTS_COLUMNS.PARENT_REQUEST_ID, text: 'Parent Request ID' },
    { key: REQUESTS_COLUMNS.SAMPLE_TYPE, text: 'Sample Type' },
    { key: REQUESTS_COLUMNS.TEST_TYPE, text: 'Test Type' },
    { key: REQUESTS_COLUMNS.RECEIVED_DATE, text: 'Received' },
    { key: REQUESTS_COLUMNS.PHYSICIAN_NAME, text: 'Physician' },
    { key: REQUESTS_COLUMNS.PRACTICE_NAME, text: 'Practice' },
  ];

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>PDF</th>
          {
            columns.map((col) => {
              const classNames = orderByColumn === col.key
                ? [
                  styles.sortableTableHeaderItem,
                  isOrderAsc ? styles.ascending : styles.descending,
                ]
                : [styles.sortableTableHeaderItem];

              return (
                <th
                  key={col.key}
                  className={classNames.join(' ')}
                  title={col.tooltip}
                  onClick={
                    () => {
                      setOrderByColumn(col.key);
                      setIsOrderAsc(!isOrderAsc);
                    }
                  }
                >
                  {col.text}
                </th>
              );
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          (requests && !!requests.length)
            && sortRequests(requests, orderByColumn, isOrderAsc).map((request, index) => {
              const thereAreLinks = !!request.downloadLinks && !!request.downloadLinks.length;

              return (
                <tr key={`${request.requestId}-${request.testType}`}>
                  <td className={styles.downloadCell}>
                    <button
                      type="button"
                      className={[styles.reportDownloadButton, thereAreLinks ? '' : styles.disabled].join(' ')}
                      disabled={!thereAreLinks}
                      title={!thereAreLinks ? 'No report available for download' : undefined}
                      onClick={() => setOpenedDropdownIndex(index)}
                    >
                      <i className="fa fa-download" />
                    </button>
                    {
                      thereAreLinks
                        && (
                          <RequestDownloadMenu
                            links={request.downloadLinks}
                            showDropdown={openedDropdownIndex === index}
                            onClose={() => setOpenedDropdownIndex(null)}
                          />
                        )
                    }
                  </td>
                  <td>
                    {formatDate(request.reportDate)}
                    {' '}
                    {request.reportStatus}
                  </td>
                  <td>
                    <a href={request.patientPath}>
                      {request.patientName}
                    </a>
                  </td>
                  <td>
                    {formatDate(request.dateOfBirth)}
                  </td>
                  <td>
                    {request.cancerType}
                  </td>
                  <td>
                    {request.requestId}
                  </td>
                  <td>
                    {request.parentRequestId}
                  </td>
                  <td>
                    {request.sampleType}
                  </td>
                  <td>
                    {request.testType}
                  </td>
                  <td>
                    {formatDate(request.receivedDate)}
                  </td>
                  <td>
                    {request.physicianName}
                  </td>
                  <td>
                    {request.practiceName}
                  </td>
                </tr>
              );
            })
        }
      </tbody>
    </table>
  );
};

RequestsTable.propTypes = IPropTypes;

export { RequestsTable };
