import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../orders/Dropdown';
import styles from './LunarPatientDropdown.module.scss';

const IPropTypes = {
  patientLunarRequests: PropTypes.array.isRequired,
  selectedRequestId: PropTypes.string.isRequired,
};

class LunarPatientDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.handleReportChange = this.handleReportChange.bind(this);
    this.generateOptionList = this.generateOptionList.bind(this);
  }

  generateOptionList() {
    return this.props.patientLunarRequests.map((request) => {
      const title = request.bloodCollectionDate || 'In-progress';
      return { id: request.id, title: title.toUpperCase() };
    });
  }

  handleReportChange(requestId) {
    if (requestId !== this.props.selectedRequestId) {
      window.location.search = `?request_id=${requestId}`;
    }
  }

  render() {
    return (
      <div className={styles.dropdownContainer}>
        <div className={styles.label}>
          COLLECTION DATE
        </div>

        <Dropdown
          list={this.generateOptionList()}
          defaultId={this.props.selectedRequestId}
          resetThenSet={this.handleReportChange}
          arrowClass={styles.arrowClass}
          itemLabelClass={styles.itemLabelClass}
        />
      </div>
    );
  }
}


LunarPatientDropdown.propTypes = IPropTypes;

export { LunarPatientDropdown };
