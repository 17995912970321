class PatientSummary {
  constructor(params) {
    this.accessionId = params.accessionId;
    this.aditionalRecipient = params.aditionalRecipient;
    this.collectionDate = params.collectionDate;
    this.diagnosis = params.diagnosis;
    this.dob = params.dob;
    this.gender = params.gender;
    this.mrn = params.mrn;
    this.name = params.name;
    this.physicianFax = params.physicianFax;
    this.physicianName = params.physicianName;
    this.practiceFullAddress = params.practiceFullAddress;
    this.practiceName = params.practiceName;
    this.practicePhoneNumber = params.practicePhoneNumber;
    this.patientPhoneNumber = params.patientPhoneNumber;
    this.receiptDate = params.receiptDate;
    this.reportDate = params.reportDate;
    this.reportDateLabel = params.reportDateLabel;
    this.revisionDate = params.revisionDate;
    this.revisionDateLabel = params.revisionDateLabel;
    this.specimen = params.specimen;
    this.status = params.status;
    this.testNumber = params.testNumber;
    this.type = params.type;
    this.surgeryDate = params.surgeryDate;
    this.baselineTimepoint = params.baselineTimepoint;
    this.testResult = params.testResult;
    this.showTestResult = params.showTestResult;
  }
}

export { PatientSummary };
