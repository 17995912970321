import React from 'react';
import PropTypes from 'prop-types';

import SortableTable from './SortableTable';

const IPropTypes = {
  biomarkers: PropTypes.array.isRequired,
  compatibility: PropTypes.number.isRequired,
  dictionary: PropTypes.shape({
    biomarker: PropTypes.shape({
      table: PropTypes.string,
      label: PropTypes.string,
      details: PropTypes.string,
    }),
  }).isRequired,
};

const getNameAndDescription = (biomarker, compatibility) => {
  const { name, description, type } = biomarker.data;
  if (type === 'hrd') {
    return { name, description };
  }

  if (type === 'tmb' && description && compatibility >= 38) {
    return { name, description };
  }

  const newName = description ? `${name} (${description})` : name;
  return { name: newName, description: '' };
};

const getBiomarkerData = (biomarker, compatibility, index) => {
  const { name, description } = getNameAndDescription(biomarker, compatibility);

  return {
    id: index + 1,
    name,
    description,
    type: biomarker.data.type,
    value: biomarker.data.value,
    detected: biomarker.data.detected,
    biomarkerDisplaySort: biomarker.data.biomarkerDisplaySort,
  };
};

const BiomarkerTable = (props) => {
  const tableHeader = props.dictionary.biomarker.table;
  const { compatibility } = props;

  const columns = [
    { id: 1, name: String(props.dictionary.biomarker.label) },
    { id: 2, name: String(props.dictionary.biomarker.details) },
  ];

  const rows = props.biomarkers.map((biomarker, index) => (
    getBiomarkerData(biomarker, compatibility, index)
  ));

  const sortedRows = [...rows];
  sortedRows.sort((a, b) => a.biomarkerDisplaySort - b.biomarkerDisplaySort);

  return (
    <SortableTable
      {...props}
      tableHeader={tableHeader}
      columns={columns}
      rows={sortedRows}
    />
  );
};

BiomarkerTable.propTypes = IPropTypes;

export default BiomarkerTable;
