import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ReleaseActivity from './release_activity/ReleaseActivity';
import { formatDate } from '../../../../lunar_patient_summary/ReportSummaryHelper';

const IPropTypes = {
  releases: PropTypes.array.isRequired,
};

const ReleaseActivityLog = ({ releases }) => {
  const activities = releases
    .map(({ attributes }) => (
      <ReleaseActivity
        key={`${attributes.releasedAt} ${attributes.releasingUserName}`}
        reportDate={formatDate(attributes.reportDate, 'day-month-year')}
        releaseDateHour={formatDate(attributes.releasedAt, 'month-day-year-at-time')}
        releaser={attributes.releasingUserName}
      />
    ));

  return (
    <Fragment>
      <h1>Activity Log</h1>
      <div className="release-to-patient-line" />
      <div>
        {activities}
      </div>
    </Fragment>
  );
};

ReleaseActivityLog.propTypes = IPropTypes;

export { ReleaseActivityLog };
