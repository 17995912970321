import React from 'react';
import PropTypes from 'prop-types';

import { DashboardList } from '../dashboard-list';
import { DashboardListHeader } from '../dashboard-list-header';
import { Order } from '../../../../models/order/order';

const IPropTypes = {
  list: PropTypes.arrayOf(PropTypes.instanceOf(Order)).isRequired,
  initialDashboardListItems: PropTypes.number.isRequired,
  showAllDashboardListItems: PropTypes.bool.isRequired,
  loadAdditionalDashboardListItems: PropTypes.func.isRequired,
  handleCollapseClick: PropTypes.func,
  userPermissions: PropTypes.shape({
    canOnlyDemoOrders: PropTypes.bool.isRequired,
  }).isRequired,
};

const defaultProps = {
  handleCollapseClick: () => {},
};

class DashboardListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: true,
      containerArrow: 'down',
    };
    this.handleContainerArrowClick = this.handleContainerArrowClick.bind(this);
  }


  handleContainerArrowClick(forcedArrowDirection) {
    let newDirection = '';
    const { containerArrow } = this.state;
    if (forcedArrowDirection) {
      newDirection = forcedArrowDirection;
    } else {
      newDirection = containerArrow === 'down' ? 'right' : 'down';
    }
    this.setState(prevState => ({
      containerArrow: newDirection,
      toggled: !prevState.toggled,
    }));
  }

  render() {
    const {
      list,
      initialDashboardListItems,
      showAllDashboardListItems,
      loadAdditionalDashboardListItems,
      handleCollapseClick,
      userPermissions,
    } = this.props;
    const {
      containerArrow,
      toggled,
    } = this.state;

    return (
      <>
        <DashboardListHeader
          handleContainerArrowClick={this.handleContainerArrowClick}
          newArrowDirection={containerArrow}
          title={list[0].buildHeaderForPrintedOrdersDashboard()}
        />
        <DashboardList
          list={list}
          handleContainerArrowClick={this.handleContainerArrowClick}
          toggled={toggled}
          initialDashboardListItems={initialDashboardListItems}
          showAllDashboardListItems={showAllDashboardListItems}
          loadAdditionalDashboardListItems={loadAdditionalDashboardListItems}
          handleCollapseClick={handleCollapseClick}
          disabledForSales={userPermissions.canOnlyDemoOrders}
        />
      </>
    );
  }
}

DashboardListContainer.propTypes = IPropTypes;
DashboardListContainer.defaultProps = defaultProps;

export { DashboardListContainer };
