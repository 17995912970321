import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { OrderingWarnings } from '../../../config/ordering-warnings';
import { ConfirmationOverlay } from '../confirmation-overlay';

const IPropTypes = {
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TrfValidationOverlay = (props) => {
  const propsToPass = { ...props };
  delete propsToPass.warnings;

  const warningText = useMemo(() => (
    <div>
      { props.warnings.map(warning => (
        <p key={warning}>
          {OrderingWarnings[warning].description}
        </p>
      ))}
    </div>
  ), [props.warnings]);

  return (
    <ConfirmationOverlay
      header="Missing Information"
      contentLineOne={warningText}
      leftButtonText="BACK"
      rightButtonText="CONTINUE"
      {...propsToPass}
    />
  );
};

TrfValidationOverlay.propTypes = IPropTypes;

export { TrfValidationOverlay };
