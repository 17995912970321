import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './AdministrationDropdown.module.scss';

const IPropTypes = {
  tabIsActive: PropTypes.bool,
};

const defaultProps = {
  tabIsActive: false,
};

const AdministrationDropdown = ({ tabIsActive }) => {
  const dropdownOptions = [
    { id: 1, title: 'Account', url: 'accounts' },
    { id: 2, title: 'Site', url: 'site' },
    { id: 3, title: 'Role', url: 'role' },
    { id: 4, title: 'User', url: 'user' },
    { id: 5, title: 'Role Assignment', url: 'roleAssignment' },
  ];
  const [isOpened, setIsOpened] = useState(false);
  const toggleDropdown = () => setIsOpened(prevState => !prevState);
  // eslint-disable-next-line no-return-assign
  const selectItem = url => window.location.href = `administrations/${url}`;

  const buttonClassName = [
    isOpened ? 'fa fa-chevron-up' : 'fa fa-chevron-down',
    tabIsActive && styles.tabIsActive,
    styles.dropdownButton].join(' ');
  return (
    <li className="administration-tab" onClick={toggleDropdown} role="presentation">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="main-link_" href="#">ADMINISTRATION</a>
      <button type="button" className={buttonClassName} />
      {isOpened
      && (
        <ul className={styles.dropdownList}>
          {
            dropdownOptions.map(item => (
              <li key={item.id} id={item.id}>
                <button type="button" onClick={() => selectItem(item.url)}>
                  {item.title}
                </button>
              </li>
            ))
          }
        </ul>
      )
      }
    </li>
  );
};
export default AdministrationDropdown;

AdministrationDropdown.propTypes = IPropTypes;
AdministrationDropdown.defaultProps = defaultProps;
