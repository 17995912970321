import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IdentitySettings from './IdentitySettings';
import AccountSettings from './AccountSettings';
import AdditionalInfoModal from '../AlertModal/AdditionalInfoModal';
import DobModal from '../AlertModal/DobModal';
import EmailUpdateModal from '../AlertModal/EmailUpdateModal';
import NameUpdateModal from '../AlertModal/NameUpdateModal';
import PasswordRestModal from '../AlertModal/PasswordRestModal';

const IPropTypes = {
  info: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  confirm_email_text: PropTypes.string.isRequired,
  verified: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  isAutoCreated: PropTypes.bool.isRequired,
};
const Settings = (props) => {
  const {
    info,
    user,
    confirm_email_text: confirmEmailText,
    verified,
    editable,
    isAutoCreated,
  } = props;
  const [showEmailModal, setEmailModal] = useState(false);
  const [showDobModal, setDobModal] = useState(false);
  const [showAdditionalModal, setAdditionalModal] = useState(false);
  const [showNameModal, setNameModal] = useState(false);
  const [showPasswordResetModal, setPasswordResetModal] = useState(false);
  const showModalWindow = (modal) => {
    switch (modal) {
      case 'email': setEmailModal(true); break;
      case 'dob': setDobModal(true); break;
      case 'name': setNameModal(true); break;
      case 'additionalInfo': setAdditionalModal(true); break;
      case 'passwordReset': setPasswordResetModal(true); break;
      default:
    }
  };
  const closeModalWindow = (modal) => {
    switch (modal) {
      case 'email': setEmailModal(false); break;
      case 'dob': setDobModal(false); break;
      case 'name': setNameModal(false); break;
      case 'additionalInfo': setAdditionalModal(false); break;
      case 'passwordReset': setPasswordResetModal(false); break;
      default:
    }
  };
  return (
    <>
      <div className="grey-container__style-A">
        <div className="container__inner">
          <div className="header">Settings</div>
          <div className="content-section">
            <div className="sub-header">Patient Verification Information</div>
            <div className="sub-header__description">
              Information in this section will be used to match your account to your blood sample.
            </div>
            <div className="sub-content">
              <IdentitySettings
                info={info}
                verified={verified}
                editable={editable}
                isAutoCreated={isAutoCreated}
                showModalWindow={showModalWindow}
              />
            </div>
          </div>
          <div className="content-section">
            <div className="sub-header">Account Settings</div>
            <div className="sub-content">
              <AccountSettings
                info={info}
                user={user}
                editable={editable}
                confirm_email_text={confirmEmailText}
                showModalWindow={showModalWindow}
              />
            </div>
          </div>
        </div>
      </div>
      <AdditionalInfoModal
        showModal={showAdditionalModal}
        closeModal={closeModalWindow}
        info={info}
      />
      <DobModal showModal={showDobModal} closeModal={closeModalWindow} info={info} />
      <EmailUpdateModal showModal={showEmailModal} closeModal={closeModalWindow} user={user} />
      <NameUpdateModal showModal={showNameModal} closeModal={closeModalWindow} info={info} />
      <PasswordRestModal
        showModal={showPasswordResetModal}
        closeModal={closeModalWindow}
        info={info}
      />
    </>
  );
};
export default Settings;
Settings.propTypes = IPropTypes;
