import { staticDataActionTypes } from '../../actions/orders/static-data';

const initialState = {
  patientStatuses: null,
  testSelections: null,
  patientTypes: null,
  configuration: null,
  diagnoses: [],
};

const staticDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case staticDataActionTypes.ADD_PATIENT_STATUSES:
      return {
        ...state,
        patientStatuses: action.info,
      };
    case staticDataActionTypes.ADD_TEST_SELECTIONS:
      return {
        ...state,
        testSelections: action.info,
      };
    case staticDataActionTypes.ADD_PATIENT_TYPES:
      return {
        ...state,
        patientTypes: action.info,
      };
    case staticDataActionTypes.ADD_CONFIGURATION:
      return {
        ...state,
        configuration: action.configuration,
      };
    case staticDataActionTypes.ADD_RELEVANT_CLINICAL_HISTORY:
      return {
        ...state,
        relevantClinicalHistory: action.info,
      };
    case staticDataActionTypes.ADD_DIAGNOSES:
      return {
        ...state,
        diagnoses: action.diagnoses,
      };
    default:
      return state;
  }
};

export { staticDataReducer };
