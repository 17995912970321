import React from 'react';
import PropTypes from 'prop-types';

import { StudyManagerController } from '../../../networking/controllers/study-manager-controller';
import { StudyManagerModal } from '../study-manager-modal';

const IPropTypes = {
  internalUsersSsoEnabled: PropTypes.bool,
  studyManagerHasMultipleProjects: PropTypes.bool.isRequired,
  callback: PropTypes.func,
  email: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  addBannerMessages: PropTypes.func.isRequired,
};

const defaultProps = {
  internalUsersSsoEnabled: true,
  callback: () => false,
};

class NewStudyManagerSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allProjectIds: null,
      allProjects: null,
    };
    this.updateAllProjectIds = this.updateAllProjectIds.bind(this);
    this.createStudyManager = this.createStudyManager.bind(this);
  }

  componentDidMount() {
    StudyManagerController.getAllProjectIds().then(this.updateAllProjectIds);
  }

  componentDidUpdate() {
    window.hideSpinner();
  }

  createStudyManager = async (
    firstName,
    lastName,
    projectIds,
    sendInvitationEmail,
    sendCourtesyEmail,
  ) => {
    window.showSpinner();
    let status;
    let bannerMessage;
    // eslint-disable-next-line max-len
    const projects = this.state.allProjects.filter(project => projectIds.some(pId => pId === project.id));
    try {
      const response = await StudyManagerController.createStudyManager(
        firstName,
        lastName,
        this.props.email,
        projectIds,
        sendInvitationEmail,
        sendCourtesyEmail,
        projects,
      );
      bannerMessage = response.data.message;
      status = 'success';
    } catch (error) {
      bannerMessage = error.response.data.message;
      status = 'error';
    } finally {
      window.hideSpinner();
      this.props.closeModal(1);
      this.props.addBannerMessages({ messages: [bannerMessage], status });
    }
  }

  updateAllProjectIds(data) {
    const ids = data.map(p => p.id);
    this.setState({ projectIds: ids, allProjectIds: ids, allProjects: data });
  }

  render() {
    const { projectIds } = this.state;
    if (!projectIds) {
      return null;
    }

    return this.props.display && (
      <StudyManagerModal
        {...this.props}
        firstList={this.state.allProjectIds}
        secondList={[]}
        submitCallback={this.createStudyManager}
        buttonText="Sign Up"
      />
    );
  }
}

NewStudyManagerSignup.propTypes = IPropTypes;
NewStudyManagerSignup.defaultProps = defaultProps;
export { NewStudyManagerSignup };
