import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Orders from './Orders';
import { store } from '../../store';
import OrdersErrorHandler from './OrdersErrorHandler';
import { theme } from './theme/theme';

const IPropTypes = {
  viewAsUser: PropTypes.object.isRequired,
  userPermissions: PropTypes.object.isRequired,
};

const OrdersContainer = (props) => {
  const {
    viewAsUser,
    userPermissions,
  } = props;
  return (
    <Provider store={store}>
      <OrdersErrorHandler>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Orders
            viewAsUser={viewAsUser}
            userPermissions={userPermissions}
          />
        </ThemeProvider>
      </OrdersErrorHandler>
    </Provider>
  );
};

OrdersContainer.propTypes = IPropTypes;
export default OrdersContainer;
