class ExpressionValuesSerializer {
  static deserialize(data) {
    return data && {
      expressionLevel: data.expressionLevel,
      referenceRange: data.referenceRange,
      context: data.context,
    };
  }
}

export { ExpressionValuesSerializer };
