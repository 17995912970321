import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { StudyManagerController } from '../../../networking/controllers/study-manager-controller';
import { StudyManagerModal } from '../study-manager-modal';
import { logger } from '../../../util/logger';

const IPropTypes = {
  studyManagerHasMultipleProjects: PropTypes.bool.isRequired,
  callback: PropTypes.func,
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  display: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  addBannerMessages: PropTypes.func.isRequired,
};

const defaultProps = {
  callback: () => false,
};

const StudyManagerUpdate = ({
  studyManagerHasMultipleProjects,
  callback,
  email,
  id,
  display,
  closeModal,
  addBannerMessages,
}) => {
  const [availableProjectIds, setAvailableProjectIds] = useState(null);
  const [studyManagerInfo, setStudyManagerInfo] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const [projectIds, userInfo] = await Promise.all([
          StudyManagerController.getAllProjectIds(),
          StudyManagerController.getStudyManagerInfo(id),
        ]);
        const filteredProjects = projectIds.map(p => p.id).filter(
          project => !userInfo.projectIds.includes(project),
        );
        setAvailableProjectIds(filteredProjects);
        setStudyManagerInfo(userInfo);
      } catch (error) {
        logger.error(error);
      }
      window.hideSpinner();
    }
    fetchData();
  }, []);

  const updateStudyManager = async (
    firstName,
    lastName,
    projectIds,
    sendInvitationEmail,
    sendCourtesyEmail,
  ) => {
    window.showSpinner();
    let status;
    let bannerMessage;
    try {
      const response = await StudyManagerController.updateStudyManager(
        id,
        firstName,
        lastName,
        email,
        projectIds,
        sendInvitationEmail,
        sendCourtesyEmail,
      );
      bannerMessage = response.data.message;
      status = 'success';
    } catch (error) {
      bannerMessage = error.response.data.message;
      status = 'error';
    } finally {
      window.hideSpinner();
      closeModal(1);
      addBannerMessages({ messages: [bannerMessage], status });
    }
  };

  const props = {
    studyManagerHasMultipleProjects,
    callback,
    email,
  };

  return (
    availableProjectIds
      && display
      && studyManagerInfo
      && (
      <StudyManagerModal
        {...props}
        firstName={studyManagerInfo.firstName}
        lastName={studyManagerInfo.lastName}
        firstList={availableProjectIds}
        secondList={studyManagerInfo.projectIds}
        submitCallback={updateStudyManager}
        buttonText="Update"
      />
      )
  );
};

StudyManagerUpdate.propTypes = IPropTypes;
StudyManagerUpdate.defaultProps = defaultProps;
export { StudyManagerUpdate };
