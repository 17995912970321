import React from 'react';
import PropTypes from 'prop-types';

import { isUsername } from '../util/validator';

const IPropTypes = {
  viewAsUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
  }).isRequired,
};

class AdminEditUser extends React.Component {
  static handleCloseModal() {
    window.$(':input').val('');
    window.$('.email-errors').html('');
  }

  constructor(props) {
    super(props);
    this.state = {
      oldUsername: this.props.viewAsUser.email,
      oldFirstname: this.props.viewAsUser.firstname,
      oldLastname: this.props.viewAsUser.lastname,
      newUsername: '',
      newFirstname: '',
      newLastname: '',
      errorText: '',
    };

    this.form = null;

    this.updateUser = this.updateUser.bind(this);
    this.content = this.content.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetState = this.resetState.bind(this);
    this._setFormRef = this._setFormRef.bind(this);
  }

  updateUser() {
    const {
      oldUsername,
      newUsername,
      newFirstname,
      newLastname,
    } = this.state;

    if (newUsername && !isUsername(newUsername)) {
      this.setState({ errorText: 'Please enter a valid email address.' });
      return;
    }
    if (!newUsername && !newFirstname && !newLastname) {
      this.setState({ errorText: 'At least one field must be completed.' });
      return;
    }
    this.setState({ errorText: '' });


    window.updateUsername(oldUsername, newUsername, newFirstname, newLastname, this.resetState);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.updateUser();
  }

  update(field) {
    return (e) => {
      this.setState({ [field]: e.target.value });
    };
  }

  _setFormRef(element) {
    this.form = element || null;
  }

  content() {
    return (
      <div className="content">
        <form id="modal-form" onSubmit={this.handleSubmit} ref={this._setFormRef}>
          <div className="email-errors">
            {this.state.errorText}
          </div>
          <div id="ghadmin-current-username-label" className="grey-box__form__item-label share__email__address">Current Username</div>
          <p id="old_username" className="grey-box__form__item-label change-admin-current-value">{this.state.oldUsername}</p>
          <label
            id="ghadmin-new-username-label"
            className="grey-box__form__item-label share__email__address"
            htmlFor="new_username"
          >
            New Username
            <input
              type="text"
              id="new_username"
              className="grey-box__form__item grey-box__form__item-email change-admin-input"
              placeholder="user@example.com"
              onChange={this.update('newUsername')}
            />
          </label>

          <div id="ghadmin-current-firstname-label" className="grey-box__form__item-label share__email__address">Current First Name</div>
          <p id="old_firstname" className="grey-box__form__item-label change-admin-current-value">{this.state.oldFirstname}</p>
          <label
            id="ghadmin-new-firstname-label"
            className="grey-box__form__item-label share__email__address"
            htmlFor="new_firstname"
          >
            New First Name
            <input
              type="text"
              id="new_firstname"
              className="grey-box__form__item grey-box__form__item-email change-admin-input"
              placeholder="John"
              onChange={this.update('newFirstname')}
            />
          </label>

          <div id="ghadmin-current-lastname-label" className="grey-box__form__item-label share__email__address">Current Last Name</div>
          <p id="old_lastname" className="grey-box__form__item-label change-admin-current-value">{this.state.oldLastname}</p>
          <label
            id="ghadmin-new-lastname-label"
            className="grey-box__form__item-label share__email__address"
            htmlFor="new_lastname"
          >
            New Last Name
            <input
              type="text"
              id="new_lastname"
              className="grey-box__form__item grey-box__form__item-email admin-change-username-current-val change-admin-input"
              placeholder="Penobscot"
              onChange={this.update('newLastname')}
            />
          </label>

          <button
            type="button"
            className="btn__size-dynamic btn__color-blue"
            onClick={this.handleSubmit}
          >
            Update
          </button>
        </form>
      </div>
    );
  }

  resetState(data) {
    const newUsername = data.email;
    const newFirstname = data.firstname;
    const newLastname = data.lastname;

    this.setState({
      oldUsername: newUsername, oldFirstname: newFirstname, oldLastname: newLastname,
    });

    if (this.form) {
      this.form.reset();
    }
  }

  render() {
    if (!this.props.viewAsUser.email) {
      return null;
    }

    const content = this.content();

    return (
      <div className="modal__container-outer change-username">
        <div className="modal__container-inner align-top">
          <div className="modal__content">
            <div>
              <i className="fa fa-close modal-close" onClick={AdminEditUser.handleCloseModal} />
              <div className="header">
                Modify
                {' '}
                {this.state.oldUsername}
              </div>
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminEditUser.propTypes = IPropTypes;

export default AdminEditUser;
