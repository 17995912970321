import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import MatchGenViewContainer from './MatchGenViewContainer';
import { getUserInfo } from '../helpers';
import Spinner from '../common/utils/spinner/Spinner';

const userRoles = getUserInfo('Matchgen');
const MatchgenRootContainer = () => (
  <Provider store={store}>
    <MatchGenViewContainer roles={userRoles} />
    <Spinner />
  </Provider>
);
export default MatchgenRootContainer;
