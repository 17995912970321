import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const NoOrders = (props) => {
  const {
    text,
    totalPendingRecords,
  } = props;
  return (
    <Box sx={{
      height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
    }}
    >
      <Typography component="h2" variant="h5">
        {`You currently have no orders ${text}`}
      </Typography>
      <br />
      <br />
      <Link to={totalPendingRecords >= 10 ? '/new_orders' : '/new_orders/new'}>
        <Button disabled={totalPendingRecords >= 10} variant={totalPendingRecords !== 10 ? 'contained' : 'outlined'} size="large" startIcon={<AddIcon />}>start A New Order</Button>
      </Link>
    </Box>
  );
};
export default NoOrders;
NoOrders.propTypes = {
  text: PropTypes.string.isRequired,
  totalPendingRecords: PropTypes.number.isRequired,
};
