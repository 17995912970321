import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    width: '360px',
    '& .MuiInputBase-input': {
      paddingLeft: '40px !important',
    },
    '& .MuiInputLabel-root': {
      left: '30px !important',
      top: '3px',
    },
    '& .MuiInputLabel-shrink': {
      marginLeft: '-20px !important',
    },
  },
  endAdornment: {
    color: '#1F5FAC',
  },
});

const IProptypes = {
  onInputChange: PropTypes.func.isRequired,
  handleKeySearchHandler: PropTypes.func.isRequired,
  results: PropTypes.array,
  searchValue: PropTypes.string,
  searchResultClick: PropTypes.func.isRequired,
  loadOrdersData: PropTypes.func.isRequired,
  enableSearchFlag: PropTypes.boolean,

};
const defaultProps = {
  results: [],
  searchValue: null,
  enableSearchFlag: true,
};
const MuiAutoComplete = (props) => {
  const {
    onInputChange,
    results = [],
    searchValue,
    searchResultClick,
    loadOrdersData,
    handleKeySearchHandler,
    enableSearchFlag,
  } = props;

  const styles = useStyles();
  const onChangeHandler = (event, value, reason) => {
    if (reason === 'clear') {
      loadOrdersData();
    }
  };

  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        disabled={!enableSearchFlag}
        fullWidth
        classes={{ root: styles.root }}
        id="SearchInput"
        freeSolo
        onChange={onChangeHandler}
        onInputChange={onInputChange}
        noOptionsText="no records found"
        options={results}
        filterOptions={x => x}
        onKeyPress={handleKeySearchHandler}
        getOptionLabel={option => ((option && option.firstName && option.lastName) ? `${option.firstName} ${option.lastName}` : searchValue)}
        renderOption={(propss, option) => (
          <li {...propss}>
            { results.length > 0 ? (
              <Box key={option.mrn} sx={{ display: 'flex', flexDirection: 'column' }} onClick={() => searchResultClick(option)}>
                <Typography component="div" variant="body1" color="primary">{`${option.firstName || ''} ${option.lastName || ''} (${option.yearMonthDay || ''})`}</Typography>
                <Typography component="div" variant="body2">{option.mrn ? `MRN: ${option.mrn}` : 'MRN: '}</Typography>
              </Box>
            ) : <Typography component="div" variant="body2">No Records found</Typography>}
          </li>
        )}
        renderInput={params => (
          <TextField
            fullWidth
            {...params}
            label="Quick Jump to Patient (Name, DOB, MRN)"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Stack>
  );
};
export default MuiAutoComplete;
MuiAutoComplete.propTypes = IProptypes;
MuiAutoComplete.defaultProps = defaultProps;
