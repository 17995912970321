/* eslint-disable radix */
import * as actionTypes from './actionsTypes';
import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import HttpService from '../../service/graphqlInterceptor';

export const getMatchGenData = (params, reload) => (dispatch) => {
  const query = JSON.stringify({
    query: `query {
    getAllMatchAlgorithms(pageNo:${params.pageNo},
      pageSize:${params.pageSize},sortBy: "id", asc: true) {
          totalRecords,
          matchAlgorithms {
            id,
            name,
            pharmaTrialsId,
            algorithmVersion,
            liveDate,
            endDate,
            lastRunTime,
            configurable,
            priorityConfig,
            createdAt,
            updatedAt,
            matchKeyPrefix
          }
      }
  }`,
    variables: {},
  });
  dispatch(showSpinner());
  return HttpService.post('', query)
    .then(({ data }) => {
      dispatch(actionTypes.getMatchGen({ ...data, reload }));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.matchGenError(err));
      dispatch(closeSpinner());
    });
};
export const addMatchGenData = params => (dispatch) => {
  const mutation = JSON.stringify({
    query: `mutation {
      createMatchAlgorithm(input :{
        companyName: "${params.companyName}",
        matchKeyPrefix: "${params.matchKeyPrefix}",
        pharmaTrialsId: "${params.pharmaTrialsId}",
        algorithmVersion: ${parseInt(params.algorithmVersion)},
        liveDate: "${params.liveDate}",
        endDate: "${params.endDate}",
        backfillWindow: "${params.backfillWindow}",
        configurable: ${parseInt(params.configurable)},
        priorityConfig: ${parseInt(params.priorityConfig)},
      }) {
      id
    }
  }`,
    variables: {},
  });
  dispatch(showSpinner());
  return HttpService.post('', mutation)
    .then(({ data }) => {
      if (data.errors) {
        dispatch(actionTypes.matchGenError(data.errors[0]));
        dispatch(closeSpinner());
      } else {
        dispatch(actionTypes.saveMacthGen(data));
        dispatch(closeSpinner());
      }
    })
    .catch((err) => {
      dispatch(actionTypes.matchGenError(err));
      dispatch(closeSpinner());
    });
};

export const addSearchSpaceData = params => (dispatch) => {
  const mutation = JSON.stringify({
    query: `mutation {
      createSearchSpace(input: {
        matchAlgorithmId: ${parseInt(params.matchAlgorithmId)},
        property: "${params.property}",
        value: "${params.value}",
        inclusion: ${parseInt(params.inclusion)}
      }) {
      id
    }
  }`,
    variables: {},
  });
  dispatch(showSpinner());
  return HttpService.post('', mutation)
    .then(({ data }) => {
      if (data.errors) {
        dispatch(actionTypes.searchError(data.errors[0]));
        dispatch(closeSpinner());
      } else {
        dispatch(actionTypes.saveSearchSpace(data));
        dispatch(closeSpinner());
      }
    })
    .catch((err) => {
      dispatch(actionTypes.matchGenError(err));
      dispatch(closeSpinner());
    });
};

export const addGenomicData = params => (dispatch) => {
  const mutation = JSON.stringify({
    query: `mutation {
      createGenomicCriteria(input: {
        matchAlgorithmId: ${parseInt(params.matchAlgorithmId)},
        geneName: "${params.geneName}",
        altType: "${params.altType}",
        altDescription: "${params.altDescription}"
        exon: ${parseInt(params.exon)}
        geneInclusion: ${parseInt(params.geneInclusion)}
        cancerType: "${params.cancerType}"
        cancerInclusion: ${parseInt(params.cancerInclusion)}
      }) {
      id
    }
  }`,
    variables: {},
  });
  dispatch(showSpinner());
  return HttpService.post('', mutation)
    .then(({ data }) => {
      if (data.errors) {
        dispatch(actionTypes.GenomicError(data.errors[0]));
        dispatch(closeSpinner());
      } else {
        dispatch(actionTypes.saveGenomic(data));
        dispatch(closeSpinner());
      }
    })
    .catch((err) => {
      dispatch(actionTypes.matchGenError(err));
      dispatch(closeSpinner());
    });
};

export const editMatchGenData = params => (dispatch) => {
  const mutation = JSON.stringify({
    query: `mutation {
      updateMatchAlgorithm(input: {
        id: "${parseInt(params.id)}",
        endDate: "${params.endDate}",
        pharmaTrialsId: "${params.pharmaTrialsId}",
        liveDate: "${params.liveDate}",
        backfillWindow: "${params.backfillWindow}"
      }) {
        id
    }
  }`,
    variables: {},
  });
  dispatch(showSpinner());
  return HttpService.post('', mutation)
    .then(({ data }) => {
      if (data.errors) {
        dispatch(actionTypes.updateMatchGenError(data.errors[0]));
        dispatch(closeSpinner());
      } else {
        dispatch(actionTypes.updateMatchgen(data));
        dispatch(closeSpinner());
      }
    })
    .catch((err) => {
      dispatch(actionTypes.matchGenError(err));
      dispatch(closeSpinner());
    });
};
export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePageMatchGen(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeOrganization(params));
};
