import React from 'react';
import PropTypes from 'prop-types';
import ToggleRevoke from './commons/components/ToggleRevoke';
import { getStatusMessage } from './helpers';

const PatientsShare = (props) => {
  const {
    share,
    accessClass,
    sharees,
    sharers,
    sharedWithYou,
    patients,
  } = props;
  return (
    <>
      {
    [share].map((item) => {
      const shareClass = item.revoked_at && 'share-created--revoked';
      return (
        <div className={`share-created ${shareClass}`} key={item.id}>
          <div className={`share-created__patient ${shareClass}`}>
            <div className="share-created__text">
              <div className="share-created__text-icon-patient" />
              <div className="share-created__text-description">
                {`${patients[item.patient_id].patient_last_name}, ${patients[item.patient_id].patient_first_name}`}
              </div>
            </div>
            <div className="share-created__status">
              {getStatusMessage(share)}
            </div>
            <ToggleRevoke
              sharedWithYou={sharedWithYou}
              sharees={sharees}
              sharers={sharers}
              share={share}
              accessClass={accessClass}
            />
          </div>
        </div>
      );
    })
    }
    </>
  );
};
export default PatientsShare;
PatientsShare.propTypes = {
  share: PropTypes.object.isRequired,
  accessClass: PropTypes.string.isRequired,
  sharees: PropTypes.object.isRequired,
  sharers: PropTypes.object.isRequired,
  sharedWithYou: PropTypes.bool.isRequired,
  patients: PropTypes.object.isRequired,
};
