import { createAction } from 'redux-actions';

export const GET_SITE = '@usermanagment-workflow/GET_SITE';
export const getSites = createAction(GET_SITE);

export const GET_SITE_DONE = '@usermanagment-workflow/GET_SITE_DONE';
export const getSitesDone = createAction(GET_SITE_DONE);

export const SITE_ERROR = '@usermanagment-workflow/SITE_ERROR';
export const siteError = createAction(SITE_ERROR);

export const SAVE_SITE = '@usermanagment-workflow/SAVE_SITE';
export const saveSite = createAction(SAVE_SITE);

export const SAVE_SITE_DONE = '@usermanagment-workflow/SAVE_SITE_DONE';
export const saveSiteDone = createAction(SAVE_SITE_DONE);

export const SAVE_SITE_ERROR = '@usermanagment-workflow/SAVE_SITE_ERROR';
export const saveSiteError = createAction(SAVE_SITE_ERROR);

export const UPDATE_SITE = '@usermanagment-workflow/UPDATE_SITE';
export const updatSite = createAction(UPDATE_SITE);

export const UPDATE_SITE_DONE = '@usermanagment-workflow/UPDATE_SITE_DONE';
export const updatSiteDone = createAction(UPDATE_SITE_DONE);

export const UPDATE_SITE_ERROR = '@usermanagment-workflow/UPDATE_SITE_ERROR';
export const updatSiteError = createAction(UPDATE_SITE_ERROR);

export const DELETE_SITE = '@usermanagment-workflow/DELETE_SITE';
export const deleteSite = createAction(DELETE_SITE);

export const DELETE_SITE_DONE = '@usermanagment-workflow/DELETE_SITE_DONE';
export const deleteSiteDone = createAction(DELETE_SITE_DONE);

export const DELETE_SITE_ERROR = '@usermanagment-workflow/DELETE_SITE_ERROR';
export const deleteSiteError = createAction(DELETE_SITE_ERROR);

export const SITE_PAGINATION_CHANGE = '@usermanagment-workflow/SITE_PAGINATION_CHANGE';
export const changePageSite = createAction(SITE_PAGINATION_CHANGE);

export const ON_CHANGE_SITE = '@usermanagment-workflow/ON_CHANGE_SITE';
export const onChangeSite = createAction(ON_CHANGE_SITE);

export const defaultSite = createAction('');
