import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionTypes';

export const DEFAULT_ROLEASSIGNMENT_STATE = fromJS({
  roleAssignmentList: null,
  rolesList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },
  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
  isOpen: false,
});

const RoleAssignmentReducer = handleActions(
  {
    [actionTypes.GET_ROLE_ASSIGNMENT]: (state, action) => state
      .set('reload', false)
      .set('roleAssignmentList', action.payload.data.userRoles)
      .set('totalRecords', action.payload.data.totalRecords),
    [actionTypes.GET_ADMIN_ROLES]: (state, action) => state.set('rolesList', action.payload.data.userRoles || action.payload.data),
    [actionTypes.SAVE_ROLE_ASSIGNMENT]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Role Added Successfully', type: 'success' }),

    [actionTypes.UPDATE_ROLE_ASSIGNMENT]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Role Added Successfully', type: 'success' }),

    [actionTypes.DELETE_ROLE_ASSIGNMENT]: state => state
      .set('reload', true)
      .setIn(['notify'], fromJS({ isOpen: true, message: 'Role Deleted Successfully', type: 'success' })),

    [actionTypes.ROLE_ASSIGNMENT_ERROR]: (state, action) => state.setIn(['notify'], fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.ROLE_ASSIGN_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_ROLE_ASSIGNMENT]: (state, action) => state
      .set(action.payload.key, action.payload.value),
  },
  DEFAULT_ROLEASSIGNMENT_STATE,
);
export default RoleAssignmentReducer;
