import { globalActionTypes } from '../../actions/global';

const initialState = {
  initialProps: {
    viewAsUser: null,
    authenticityToken: null,
    userPermissions: null,
  },
  banner: {
    messages: [],
    status: null,
  },
  processesLoading: 0,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case globalActionTypes.UPDATE_INITIAL_PROPS:
      return {
        ...state,
        initialProps: action.initialProps,
      };
    case globalActionTypes.ADD_BANNER_DATA:
      return {
        ...state,
        banner: {
          status: action.status,
          messages: action.messages,
        },
      };
    case globalActionTypes.RESET_BANNER:
      return {
        ...state,
        banner: initialState.banner,
      };
    case globalActionTypes.INCREMENT_PROCESSES_LOADING:
      return {
        ...state,
        processesLoading: state.processesLoading + action.increment,
      };
    default:
      return state;
  }
};

export { globalReducer };
