const KeyCodes = {
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ESC: 27,
  ENTER: 13,
  TAB: 9,
  DELETE: 8,
};

export { KeyCodes };
