import React, { useCallback, useEffect, useState } from 'react';
import {
  Toolbar,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Search } from '@mui/icons-material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import _ from 'loadsh';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ApplicationMessage from '../common/components/elements/ApplicationMessage';
import ConfirmModalWindow from '../common/components/elements/ConfirmModalWindow';
import ModalPopup from '../common/components/elements/ModalPopup';
import Pagination from '../common/components/elements/Table/Pagination';
import Controls from '../common/components/forms/controls';
import Table from '../common/Table';
import TableBody from '../common/TableBody';
import TableHeader from '../common/TableHeader';
import UsersForm from './UsersForm';

const useStyles = makeStyles(() => createStyles({
  root: {
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#ffffff',
  },
  searchInput: {
    width: '50%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#1A5EA7',
    boxShadow: 'none',
    '@media (max-width: 445px)': {
      width: '40%',
      height: '75%',
    },
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '20px',
    marginLeft: '10px',
  },
}));
const IPropTypes = {
  users: PropTypes.object,
  roles: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};
const defaultProps = {
  users: Immutable.fromJS({}),
};

const UsersView = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => {},
  });
  const [formData, setFormData] = useState(null);
  const [title, setTitle] = useState('');
  const classes = useStyles();
  const [disableEmail, setDisableEmail] = useState(false);
  const {
    users,
    roles,
    actions: {
      getUsersData,
      addUsersData,
      deleteUsersData,
      editUsersData,
      changePagination,
      onChange,
      getUsersSearchData,
    },
  } = props;
  const usersList = users.toJS().usersList || [];
  const usersSearchList = users.toJS().usersSearchList || [];
  const notify = users.toJS().notify || {};
  const pagination = users.toJS().pagination || 5;
  const usersManagement = users.toJS() || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (usersList.length === 0 || usersManagement.reload) {
      setConfirmDialog({
        isOpen: false,
        title: '',
        subTitle: '',
        onConfirm: () => {},
      });
      getUsersData(pagination);
      onChange({ key: 'reload', value: false });
    }
  }, [usersManagement.reload]);
  useEffect(() => {
    if (usersList.length === 0 && usersManagement.totalRecords !== 0) {
      getUsersData(pagination);
    }
  }, [pagination]);

  const addClickHandler = (e) => {
    e.preventDefault();
    setDisableEmail(false);
    setOpenPopup(true);
    setFormData(null);
    setTitle('Add User');
  };
  const editClickHandler = (row) => {
    setDisableEmail(true);
    setOpenPopup(true);
    setFormData(row);
    setTitle('Edit User');
  };
  const deleteClickHandler = (row) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Are you sure to delete this record',
      subTitle: '',
      onConfirm: () => {
        deleteUsersData(row);
      },
    });
  };
  const setNotify = (dat) => {
    onChange({ key: 'notify', value: { ...dat } });
  };
  const submitHandler = (values, resetForm, edit) => {
    const editedValues = { ...values };
    if (edit === true) {
      editUsersData(editedValues);
    } else {
      addUsersData(values);
    }
    resetForm();
    setFormData(null);
    setOpenPopup(false);
  };
  const isName = RegExp(/^[A-Za-z0-9 ]+$/i);

  const handleSearch = useCallback(
    _.debounce((name, value) => {
      onChange({ key: 'usersSearchList', value: null });
      onChange({ key: name, value });
      if (
        value !== ''
        && value
        && value.trim().length > 0
        && isName.test(value)
      ) {
        getUsersSearchData(`name:contains=${value}`);
      } else if (value === null) {
        getUsersData(pagination);
      }
    }, 1000),
    [],
  );
  const handlePagination = (e, pageNum) => {
    getUsersData(pageNum);
    changePagination(pageNum);
  };
  const userPermission = {
    Edit: roles && roles.includes('UPDATE'),
    Delete: roles && roles.includes('DELETE'),
  };
  const actions = [
    { action: 'Edit', handleAction: editClickHandler },
    { action: 'Delete', handleAction: deleteClickHandler },
  ];
  const headers = [
    { name: 'email', type: 'text' },
    { name: 'firstName', type: 'text' },
    { name: 'middleName', type: 'text' },
    { name: 'lastName', type: 'text' },
    { name: 'site', type: 'object' },
    { name: 'createdAt', type: 'date' },
  ];
  const handleChange = (e) => {
    onChange({ key: 'usersSearchList', value: null });
    if (e.target.value !== null) {
      getUsersData(`/${e.target.value.uuid}`);
    } else getUsersData({ ...pagination, pageSize: 5, pageNo: 0 });
  };
  return (
    <React.Fragment>
      <div className={classes.title}>Users</div>
      <div id="usersContainer" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Controls.AutoCompleteInput
            label="Search Users"
            className={classes.searchInput}
            options={
              (usersManagement.firstName || '').length > 0 && usersSearchList
            }
            name="firstName"
            property="uuid"
            displaylabel={['firstName', 'middleName', 'lastName']}
            onChange={handleChange}
            manualFilter
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onTextChange={e => handleSearch(e.target.name, e.target.value)}
          />
          <Controls.Button
            text="Add User"
            startIcon={<GroupAddIcon />}
            className={classes.newButton}
            onClick={addClickHandler}
            disabled={roles && !roles.includes('CREATE')}
          />
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <Table>
              {!isMobile && (
                <TableHeader
                  headers={[...headers]}
                  {...{ rows: usersList || [], actions }}
                />
              )}
              <TableBody
                headers={headers}
                {...{
                  rows: usersList || [],
                  actions,
                  ...pagination,
                  userPermission,
                }}
              />
            </Table>
            <Pagination
              count={usersManagement.totalRecords}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNo}
              handleChangePage={(e, val) => handlePagination(e, { ...pagination, pageNo: val })
              }
              handleChangeRowsPerPage={e => handlePagination(e, {
                ...pagination,
                pageSize: e.target.value,
                pageNo: 0,
              })
              }
            />
          </Grid>
        </Grid>
        <ModalPopup
          title={title}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <UsersForm
            disableEmail={disableEmail}
            edit={title === 'Edit User'}
            formData={formData}
            submitHandler={submitHandler}
          />
        </ModalPopup>
        <ConfirmModalWindow
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <ApplicationMessage notify={notify} setNotify={setNotify} />
      </div>
    </React.Fragment>
  );
};

export default UsersView;
UsersView.propTypes = IPropTypes;
UsersView.defaultProps = defaultProps;
