import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { config } from '../config/config';
import { API_GATEWAY_URL, USER_MGMT_V2_PUB_PATH } from '../config';
import { logger } from '../../util/logger';

const BannerMessage = (props) => {
  const {
    className, toggleBanner, messages, status,
  } = props;
  return (
    <div className={`flash-alert-container ${className}`}>
      <div className="close-flash-alert fa fa-close" onClick={toggleBanner} />
      <div className={['alert', `alert--${status}`].join(' ')}>
        <ul className="message">
          {messages.map(message => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
BannerMessage.propTypes = {
  className: PropTypes.string,
  toggleBanner: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};
BannerMessage.defaultProps = {
  className: '',
};
const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
};
const internalUserError = 'Password cannot be reset here. Please log into portal via SSO (Okta) or contact your administrator';

const ResetPassword = (props) => {
  const { authenticityToken } = props;
  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState(internalUserError);
  const [isInternalUser, setinternalUser] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const onFocusOut = async (name) => {
    if (name.length > 0) {
      const requestOptions = {
        method: 'GET',
      };
      fetch(
        `${config()[API_GATEWAY_URL]}${
          config()[USER_MGMT_V2_PUB_PATH]
        }/users/email/${name}`,
        requestOptions,
      )
        .then(response => response.json())
        .then((result) => {
          if (result.status === 'FAIL') {
            setShowMessage(true);
            setErrorMsg(result.errors[0].message);
          } else {
            setinternalUser(result.data.isInternal);
            setShowMessage(() => (result.data.isInternal ? result.data.isInternal : false));
          }
        })
        .catch(error => logger.error(error));
    }
  };
  const toggleReleaseMessage = () => {
    setShowMessage(false);
  };
  const submitHandler = async (userEmail) => {
    try {
      const res = await axios.get(
        `${config()[API_GATEWAY_URL]}${
          config()[USER_MGMT_V2_PUB_PATH]
        }/users/email/${userEmail}`,
      );

      if (res.data.data.isInternal === false) {
        document.getElementById('new_password_reset').submit();
      } else {
        setErrorMsg(internalUserError);
        setShowMessage(true);
        setinternalUser(true);
      }
    } catch (err) {
      setErrorMsg('Sorry we couldn\'t find your username. Either you mistyped your email or you have not created an account.');
      setShowMessage(true);
    }
  };
  return (
    <>
      <div className="php_landing_container">
        <a href="/">
          <div className="logo__gh-logo" />
        </a>
        <div className="php_landing__trm-img" />
        <div className="header">Forgot your password?</div>
        {showMessage && (
          <BannerMessage
            status="error"
            toggleBanner={toggleReleaseMessage}
            messages={[errorMsg]}
          />
        )}
        <div className="php_landing__grey-box_container">
          <div className="grey-box__text">
            We’ll send password-reset instructions to the email below.
          </div>
          <form
            className="fancy-form"
            id="new_password_reset"
            action="/password_resets"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler(email);
            }}
          >
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
            />
            <input
              value="physician"
              type="hidden"
              name="password_reset[account_type]"
              id="password_reset_account_type"
            />
            <input
              type="email"
              placeholder="Email Address"
              required="required"
              className="field margin-bottom__30-px"
              name="password_reset[email]"
              id="password_reset_email"
              onBlur={() => onFocusOut(email)}
              onChange={(e) => {
                setEmail(e.target.value);
                setinternalUser(false);
                setShowMessage(false);
              }}
              value={email}
            />
            <div className="input-group">
              <div className="buttons-row">
                <button
                  className="btn__size-large btn__color-blue"
                  type="submit"
                  disabled={isInternalUser}
                >
                  Send Reset Email
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="php_landing__page__text">
          <div className="php_landing__page__text-link">
            <a href="/">Back to sign in page</a>
          </div>
        </div>
        <div className="php_landing__page__text help-footer">
          <div className="php_landing__page__text-desc">Need help?&nbsp;</div>
          <div className="php_landing__page__text-link bold open-contact-us no-spinner">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="open-contact-us no-spinner" href="#">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
ResetPassword.propTypes = IPropTypes;
