import { DownloadLinksSerializer } from './download-links';
import { DropdownOptionSerializer } from './dropdown-option';

export class TestsDropdownSerializer {
  static deserialize = testsDropdown => (
    testsDropdown && {
      selectedDropdownName: testsDropdown.selected_dropdown_name,
      errorHandlingSupport: testsDropdown.error_handling_support,
      downloadButtonName: testsDropdown.download_button_name,
      options: (testsDropdown.dropdown_options || []).map(DropdownOptionSerializer.deserialize),
      links: (testsDropdown.links || []).map(DownloadLinksSerializer.deserialize),
    }
  );
}
