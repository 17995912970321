import HttpService from '../services/HttpService';
import { MutationEndpoints } from '../endpoints';
import { BiologicalGeneDetailSerializer } from '../serializers/biological-gene-detail';
import { ClinicalGeneDetailSerializer } from '../serializers/clinical-gene-detail';
import { BiologicalGeneDetail } from '../../models/biological-gene-detail';
import { ClinicalGeneDetail } from '../../models/clinical-gene-detail';
import { getToken, isInternalUser } from './helper';

class MutationsController {
  static async getGeneDetails(requestId) {
    const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
    const response = await HttpService.get(MutationEndpoints.GENE_DETAILS, {
      params: { id: requestId },
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    const biologicalData = (((response.data || {}).geneDetails || {}).biological || []);
    const clinicalData = (((response.data || {}).geneDetails || {}).clinical || []);

    return {
      biological: biologicalData.map((item) => {
        const deSerialized = BiologicalGeneDetailSerializer.deserialize(item);
        return new BiologicalGeneDetail(deSerialized);
      }),
      clinical: clinicalData.map((item) => {
        const deSerialized = ClinicalGeneDetailSerializer.deserialize(item);
        return new ClinicalGeneDetail(deSerialized);
      }),
    };
  }
}

export { MutationsController };
