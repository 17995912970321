import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import HttpService from '../../service/interceptor';
import * as actionTypes from './actionTypes';

export const getRoleAssignmentsData = params => (dispatch) => {
  const query = `?${new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());

  return HttpService.get(`/api/v1/userroles${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getroleAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.roleAssignmentError(err));
      dispatch(closeSpinner());
    });
};

export const getAdminRoles = (params = {}) => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.get(`/api/v1/roles?name:contains=${params}`)
    .then(({ data }) => {
      dispatch(actionTypes.getAdminRoles(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.roleAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const addRoleAssignmentsData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.post('/api/v1/userroles', params)
    .then(({ data }) => {
      dispatch(actionTypes.saveRoleAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.roleAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const editRoleAssignmentsData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.post(`/api/v1/users/${params.uuid}/roles`, params.roles)
    .then(({ data }) => {
      dispatch(actionTypes.updatRoleAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.roleAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const deleteRoleAssignmentsData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.delete('/api/v1/userroles', params)
    .then(({ data }) => {
      dispatch(actionTypes.deleteRoleAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.roleAssignmentError(err));
      dispatch(closeSpinner());
    });
};

export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePageRoleAssignment(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeRoleAssignment(params));
};
