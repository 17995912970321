import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import { isValidEmail } from '../../helpers';
import { logger } from '../../../../util/logger';

const IPropTypes = {
  physicianClients: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  accessClass: PropTypes.string.isRequired,
};
const SiteManagerShareModel = (props) => {
  const {
    physicianClients,
    show,
    closeModal,
    authenticityToken,
    accessClass,
  } = props;
  const [state, setState] = useState({
    checked: false,
    selectAll: true,
    email: '',
    acceptterms: false,
  });
  const [physicianClientsList, setPhysicianClientsList] = useState(physicianClients);
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [accept, setAccept] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    acceptError: '',
    emailError: '',
    checkListError: '',
  });

  useEffect(() => {
    const selectedCheckItems = physicianClientsList.filter(item => item.checked === true);
    if (selectedCheckItems.length === physicianClientsList.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [physicianClientsList]);
  const selectAllCheckBoxes = (event) => {
    setChecked(!checked);
    let newPhysicianClients;
    if (!event.target.checked) {
      newPhysicianClients = physicianClientsList.map(item => Object.assign(item,
        { checked: false }));
    } else {
      newPhysicianClients = physicianClientsList.map(item => Object.assign(item,
        { checked: true }));
    }
    setPhysicianClientsList(newPhysicianClients);
  };
  const checkBoxInputHandler = (id, selected) => {
    const newPhysicianClients = physicianClientsList.map((item) => {
      if (item.physician_id === id) {
        return Object.assign(item, { checked: !selected });
      }
      return item;
    });
    setPhysicianClientsList(newPhysicianClients);
  };
  const editSiteManagerShares = () => {
    let errors = false;
    const emails = email.split(';');
    const sharees = [];
    if (emails.length > 0) {
      emails.forEach((item) => {
        const trimEmail = item.trim();
        if (isValidEmail(trimEmail)) {
          sharees.push({
            first_name: '',
            last_name: '',
            email: item,
            notification: false,
          });
        } else {
          errors = true;
          setErrorMsg({
            ...errorMsg,
            emailError: 'Invalid or missing email addresses',
          });
        }
      });
    } else {
      setErrorMsg({
        ...errorMsg,
        emailError: 'Invalid or missing email addresses',
      });
      errors = true;
    }
    const physiciansList = physicianClientsList.filter(physician => physician.checked === true)
      .map(selectedPhysician => selectedPhysician.physician_id);
    if (physiciansList.length === 0) {
      setErrorMsg({
        ...errorMsg,
        checkListError: 'Please select at least one physician',
      });
      errors = true;
    }
    if (accept === false) {
      setErrorMsg({
        ...errorMsg,
        acceptError: 'Please accept terms.',
      });
      errors = true;
    }
    if (errors) {
      return;
    }
    closeModal();
    window.showSpinner();
    window.setBulkSharesCsrf(sharees, physiciansList, authenticityToken, () => {
      setState({
        ...state, checked: false, email: '', acceptterms: false,
      });
    });
  };
  return (
    <React.Fragment>
      {
        show && (
        <div className="modal__container-outer edit__site-manager-shares" style={{ display: 'block' }}>
          <div className="modal__container-inner">
            <div className="modal__content">
              <div className="site-manager-shares-edit">
                <i className="fa fa-close modal-close" onClick={closeModal} />
                <div className="settings-edit__container">
                  <div className="header">Grant Access to Reports</div>
                  <div className="content">
                    <div className="recipient-input-text">REPORTS-ACCESS RECIPIENTS:</div>
                    {errorMsg.emailError && <div className="errors input__email-invite__errors">{errorMsg.emailError}</div>}
                    <i className="fa fa-search site-manager-shares-edit__search" />
                    <input
                      className="input__email-invite"
                      id="input__email-invite"
                      placeholder="john@example.com; claire@example.com"
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                      type="text"
                    />
                    <div className="physician-to-share-text">PHYSICIANS THEY WILL HAVE ACCESS TO:</div>
                    {errorMsg.checkListError && <div className="errors check-box__physician__errors">{errorMsg.checkListError}</div>}
                    {
                      physicianClientsList.length > 0 ? (
                        <React.Fragment>
                          <div className="checkbox-text">
                            <input
                              type="checkbox"
                              name="select-all"
                              id="select-all"
                              value="all"
                              checked={checked}
                              className="check-box__select-all"
                              onChange={event => selectAllCheckBoxes(event)}
                            />
                            Select All
                          </div>
                          {
                      physicianClientsList.map(item => (
                        <div className="checkbox-text" key={item.physician_id}>
                          <input
                            type="checkbox"
                            name={item.physician_id}
                            id={item.physician_id}
                            value={item.physician_id}
                            className="check-box__physician"
                            checked={item.checked}
                            onChange={() => checkBoxInputHandler(item.physician_id, item.checked)}
                          />
                          <b>{`${item.physician_first_name}, ${item.physician_last_name}`}</b>
                      &nbsp;(
                          {item.practice_name}
                          )
                        </div>
                      ))
                    }
                        </React.Fragment>
                      ) : <em>No physicians to select</em>
                    }
                    <div className="buttons-row">
                      <button type="button" disabled={accessClass.includes('disabled')} onClick={editSiteManagerShares} className={`save__edit__site-manager-shares btn__size-dynamic btn__color-blue ${accessClass}`}>
                        GRANT ACCESS
                      </button>
                    </div>
                    <p />
                    {errorMsg.acceptError && <div className="errors check-box__terms__errors">{errorMsg.acceptError}</div>}
                    <div className="input-group shares__terms">
                      <label className="label-terms" htmlFor="accept_terms">
                        <input
                          type="checkbox"
                          name="accept_terms"
                          id="accept_terms"
                          required="required"
                          checked={accept}
                          onChange={() => setAccept(!accept)}
                        />
                        I confirm the individual(s) to whom I am sending this invitation is a health
                        professional or workforce member at my organization authorized to access,
                        use, and release health information in accordance with my organization
                        policies, HIPAA, federal, state, and local requirements, and the service
                        <a target="_blank" href="/terms_of_use">Terms and Conditions of Use </a>
                        . Your invitee will be required to set up a Provider Portal account and to
                        read and accept the Terms and Conditions of Use before access to the
                        Provider Portal will be provided to him/her.
                      </label>
                    </div>
                    <p />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }
    </React.Fragment>
  );
};
export default SiteManagerShareModel;
SiteManagerShareModel.propTypes = IPropTypes;
