import { emrActionTypes } from '../../actions/orders/emr';

const initialState = {
  accounts: [],
  provider: null,
};

const emrReducer = (state = initialState, action) => {
  switch (action.type) {
    case emrActionTypes.ADD_EMR_ACCOUNTS:
      return {
        ...state,
        accounts: action.info,
      };
    case emrActionTypes.ADD_EMR_PROVIDER:
      return {
        ...state,
        provider: action.info,
      };
    default:
      return state;
  }
};

export { emrReducer };
