import { testSelectionActionTypes } from '../../../actions/orders/active-order/test_selection_info';
import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';

const testSelectionInitialState = {
  testSelectionType: {
    text: null,
    code: null,
  },
  treatmentResponse: {
    text: null,
    code: null,
  },
  tissueSpecimenRetrieval: {
    pathologyLabName: null,
    pathologyLabPhone: null,
    pathologyLabFax: null,
    tissueCollectionDate: null,
  },
  isTissueOption: false,
};

const testSelectionInfo = (state = testSelectionInitialState, action) => {
  let newState;
  switch (action.type) {
    case RECEIVE_ORDER:
      return { ...state, ...action.order.testSelectionInfo };
    case testSelectionActionTypes.ADD_TEST_SELECTION_INFO:
      newState = { ...state, ...action.info };
      return newState;
    default:
      return state;
  }
};

export default testSelectionInfo;
