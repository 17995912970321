import axios from 'axios';
import { getToken, isInternalUser } from '../../../networking/controllers/helper';
import { etrfOrdersPath } from '../endpoints';

const HttpEtrfService = axios.create();
HttpEtrfService.defaults.baseURL = etrfOrdersPath;
const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

const requestHandler = (request) => {
  request.headers = {
    Authorization: `Bearer ${getToken()}`,
    ...extHeader,
    Accept: '*',
    'Content-Type': 'application/json; charset=UTF-8',
  };
  return request;
};
const errorHandler = error => Promise.reject(error);

HttpEtrfService.interceptors.request.use(request => requestHandler(request),
  error => errorHandler(error));

export default HttpEtrfService;
