import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatepickerInput from '../../generic/DatepickerInput';
import { updatePatientAdditionalInfo } from '../service/PatientService';

const IPropTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
};
const defaultProps = {
  showModal: false,
};
const AdditionalInfoModal = (props) => {
  const {
    showModal,
    closeModal,
    info: { physician_last_name: physicianName = '', bloodcolldate },
  } = props;

  const [bloodCollDate, setBloodColDate] = useState(moment(bloodcolldate || '01-01-1990').format('DD-MM-YYYY'));
  const [physicalLastName, setPhysicalLastName] = useState(physicianName || '');
  const savePatientAdditional = () => {
    const params = {
      bloodcolldate: bloodCollDate,
      physician_last_name: physicalLastName,
    };
    updatePatientAdditionalInfo(params, closeModal);
  };
  return (
    <>
      {showModal && (
        <div className="modal__container-outer modal__patient edit__additional-info">
          <div className="modal__container-inner">
            <div className="modal__content">
              <div className="patient__settings-edit patient__settings-edit__additional-info">
                <div className="settings-edit__container">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="myguardant-form">
                    Additional information
                  </label>
                  <p className="help-block">
                    To securely verify your identity, we need a bit more
                    information in addition to your full name and date of birth.
                  </p>
                  <div className="content no-welcome-code">
                    <div className="header-description">
                      What was the date that your blood was drawn or
                      tissue sample collected for Guardant360?
                    </div>
                    <DatepickerInput
                      className="input__bloodcolldate hasDatepicker"
                      name="specimenCollectionDate"
                      id="input__bloodcolldate"
                      onChange={(value) => {
                        setBloodColDate(moment(value).format('MMM DD,YYYY'));
                      }}
                      value={bloodCollDate}
                      placeholder="e.g. Jan 29, 2016"
                    />

                    <div className="header-description">
                      What’s your physician’s last name?
                    </div>
                    <input
                      className="input__physician_last_name"
                      id="input__physician_last_name"
                      placeholder="eg. Smith"
                      type="text"
                      value={physicalLastName}
                      onChange={event => setPhysicalLastName(event.target.value)
                      }
                    />
                  </div>
                  <div className="buttons-row">
                    <div
                      className="button button-clear-background close__edit__additional-info"
                      onClick={() => closeModal('additionalInfo')}
                    >
                      CANCEL
                    </div>
                    <div
                      className="button form-submit__btn save__edit__additional-info"
                      onClick={savePatientAdditional}
                    >
                      SAVE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AdditionalInfoModal;
AdditionalInfoModal.propTypes = IPropTypes;
AdditionalInfoModal.defaultProps = defaultProps;
