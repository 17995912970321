import React, { useState } from 'react';
import PropTypes from 'prop-types';
import myGuardantLogo from '../../../assets/images/icons/patient-page/myguardant-logo.svg';
import styles from './learn-more-modal.module.scss';
import { Modal } from '../../generic/Modal';

const IPropTypes = {
  onClose: PropTypes.func.isRequired,
};

const LearnMoreModal = ({ onClose }) => {
  const [startClose, setStartClose] = useState(false);

  return (
    <Modal className={styles.learnMoreModal} startClose={startClose} onClose={onClose}>
      <div className={styles.learnMorePanelContainer}>
        <i className={`fa fa-close ${styles.modalClose}`} type="button" onClick={() => setStartClose(true)} />
        <center className={styles.spaceDown}>
          <img src={myGuardantLogo} alt="" />
        </center>
        <p className={styles.spaceDown}> Dear Colleague, </p>
        <p>
          We are pleased to introduce myGuardant Patient Portal, a website exclusively for
          patients who have had a Guardant assay. As you may know,federal regulations now
          give patients the right to access to their health data; myGuardant makes this easy
          for patients and reduces the administrative burden for your staff. We believe
          myGuardant strikes a balance between patient access to data and your practice’s
          ability to manage every patient’s care.
        </p>
        <h5 className={styles.spaceDown}> Features </h5>
        <div className={styles.learnMoreBullet}> -  </div>
        <div className={styles.learnMoreText}>
          Patients can follow the status of their sample and receive a notification when their
          report is released to you for review. We believe this will minimize calls to your
          office about the status of the test and report.
        </div>
        <div className={styles.learnMoreBullet}> -  </div>
        <div className={styles.learnMoreText}>
          Patients can access useful videos and resources about Guardant that are designed
          to minimize questions for you and your care team.
        </div>
        <div className={styles.learnMoreBullet}> - </div>
        <div className={styles.learnMoreText}>
          Patients can access a digital copy of their report only after it has
          been released to them.
        </div>
        <h5> Report Release Policy </h5>
        <p className={styles.spaceDown}>
          Reports are released to a patient in one of three ways:
        </p>
        <div className={styles.learnMoreBullet}> -  </div>
        <div className={styles.learnMoreText}>
          Auto-release: By default, Guardant Health will release the report to the patient 16
          days after it has been sent to the treating physician of record.
        </div>
        <div className={styles.learnMoreBullet}> -  </div>
        <div className={styles.learnMoreText}>
          Manual release by physician or staff: The physician of record, or individuals
          authorized by the physician, may immediately release a report to the
          patient through the Guardant Provider Portal.
        </div>
        <div className={styles.learnMoreBullet}> -  </div>
        <div className={styles.learnMoreText}>
          Manual release by Client Services: Federal regulations require Guardant Health
          to release a copy of the report to patients upon their request. In such instances,
          we will notify you by email when the report has been released to the patient.
        </div>
        <p>
          We believe in empowering and informing patients, while appreciating that the treating
          physician is in  the best position to explain the complex data found in our report.
          We hope you will direct your patients to
          <a href="http://patients.guardanthealth.com/"> http://patients.guardanthealth.com/</a>
          , and we are always open to your feedback about ways to improve this experience for
          you, your staff, and your patients.
        </p>
      </div>
    </Modal>
  );
};

LearnMoreModal.propTypes = IPropTypes;

export { LearnMoreModal };
