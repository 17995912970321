/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Controls from '../common/components/forms/controls';
import { useForm, Form } from '../common/components/forms/useForm';
import MatchGenValidation from './MatchGenValidation';

const initialFValues = {
  matchAlgorithmId: '',
  geneName: '',
  altType: '',
  altDescription: '',
  exon: '',
  geneInclusion: '',
  cancerType: '',
  cancerInclusion: '',
};
const useStyles = makeStyles(theme => createStyles({
  actionButtons: {
    textAlign: 'right',
    margin: theme.spacing(2),
  },
  submitButton: {
    boxShadow: 'none',
    backgroundColor: '#1A5EA7',
    '&:hover': {
      backgroundColor: '#4572A7',
      boxShadow: 'none',
    },
  },
  resetButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  inputText: {
    width: '90%',
  },
}));
const IPropTypes = {
  matchAlgoID: PropTypes.number.isRequired,
  GenomicCriteriaFormSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object,
  buttonName: PropTypes.string,
  setOpenPopup: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  disableAlias: PropTypes.bool.isRequired,
};

const defaultValues = {
  formData: null,
  buttonName: '',
};

const GenomicCriteriaForm = (props) => {
  const {
    matchAlgoID,
    GenomicCriteriaFormSubmit,
    formData,
    disableAlias,
    buttonName,
    setOpenPopup,
    edit,
  } = props;
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    ['geneName', 'altType', 'altDescription', 'exon', 'geneInclusion', 'cancerType', 'cancerInclusion'].forEach(
      (val) => {
        if (val in fieldValues) {
          if (
            (val === 'cancerInclusion' && fieldValues[val] && fieldValues[val].length > 0)
            || val !== 'cancerInclusion'
          ) {
            const error = MatchGenValidation(val, fieldValues[val], fieldsValidation) || '';
            temp[val] = error;
          } else {
            temp[val] = '';
          }
        }
      },
    );

    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
  };

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      GenomicCriteriaFormSubmit(values, resetForm, edit);
    }
  };
  useEffect(() => {
    if (matchAlgoID) {
      setValues(previous => ({ ...previous, matchAlgorithmId: matchAlgoID }));
    }
  }, [matchAlgoID]);
  useEffect(() => {
    if (formData !== null) {
      setValues({
        ...formData,
      });
    }
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled
            label="Match Algorithm ID"
            InputLabelProps={{ required: true }}
            name="matchAlgorithmId"
            value={values.matchAlgorithmId}
            onChange={handleInputChange}
            error={errors.matchAlgorithmId && errors.matchAlgorithmId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled={disableAlias}
            label="Gene Name"
            InputLabelProps={{ required: true }}
            name="geneName"
            value={values.geneName}
            onChange={handleInputChange}
            error={errors.geneName && errors.geneName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Alt Type"
            name="altType"
            value={values.altType}
            onChange={handleInputChange}
            error={errors.altType && errors.altType}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Alt Description"
            name="altDescription"
            value={values.altDescription}
            onChange={handleInputChange}
            error={errors.altDescription && errors.altDescription}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Exon"
            name="exon"
            value={values.exon}
            onChange={handleInputChange}
            error={errors.exon && errors.exon}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Gene Inclusion"
            name="geneInclusion"
            value={values.geneInclusion}
            onChange={handleInputChange}
            error={errors.geneInclusion && errors.geneInclusion}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Cancer Type"
            name="cancerType"
            value={values.cancerType}
            onChange={handleInputChange}
            error={errors.cancerType && errors.cancerType}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Cancer Inclusion"
            name="cancerInclusion"
            value={values.cancerInclusion}
            onChange={handleInputChange}
            error={errors.cancerInclusion && errors.cancerInclusion}
          />
        </Grid>

        <Grid item xs={12} className={classes.actionButtons}>
          <Controls.Button
            className={classes.submitButton}
            onClick={() => {
              resetForm();
              setOpenPopup([false, false, false]);
            }}
            text="Close Form"
          />
          <Controls.Button className={classes.submitButton} type="submit" text={buttonName} />
          <Controls.Button
            text="reset"
            color="default"
            className={classes.resetButton}
            onClick={() => {
              resetForm(formData);
              setValues(previous => ({ ...previous, matchAlgorithmId: matchAlgoID }));
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
export default GenomicCriteriaForm;
GenomicCriteriaForm.propTypes = IPropTypes;
GenomicCriteriaForm.defaultProps = defaultValues;

const fieldsValidation = {
  geneName: {
    error: '',
    validate: 'text',
    minLength: 2,
  },

  altType: {
    error: '',
    validate: 'text',
    minLength: 2,
  },

  altDescription: {
    error: '',
    validate: 'alphanumaric',
    minLength: 2,
  },

  exon: {
    error: '',
    validate: 'number',
    minLength: 1,
  },
  geneInclusion: {
    error: '',
    validate: 'number',
    minLength: 1,
  },
  cancerType: {
    error: '',
    validate: 'alphanumaric',
    minLength: 2,
  },

  cancerInclusion: {
    error: '',
    validate: 'number',
  },
};
