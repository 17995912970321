import { genericActionTypes } from '../../../actions/orders/active-order/generic-info';
import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';

const genericInitialState = {
  updatedAt: null,
  redirectContinueLater: false,
  activeOrderId: null,
  productFamily: null,
  trfCompleted: false,
  successMessage: null,
};

const genericInfo = (state = genericInitialState, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return { ...state, ...action.order.genericInfo };
    case genericActionTypes.ADD_GENERIC_INFO:
      return { ...state, ...action.info };
    default:
      return state;
  }
};

export { genericInfo };
