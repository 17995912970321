import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../generic/Modal';
import styles from './clinical-trials-modal.module.scss';
import { ExpandableContainer } from '../../generic/expandable-container/expandable-container';

const IPropTypes = {
  onClose: PropTypes.func,
  geneName: PropTypes.string.isRequired,
  clinicalTrials: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    url: PropTypes.string,
    contact: PropTypes.string,
    phase: PropTypes.string,
    sites: PropTypes.arrayOf(PropTypes.shape({
      center: PropTypes.string,
      city: PropTypes.string,
      region: PropTypes.string,
    })).isRequired,
  })).isRequired,
};

const defaultProps = {
  onClose: () => {},
};

const transformSites = (sites) => {
  const sortedSites = sites.sort((a, b) => (a.region || '').localeCompare(b.region || ''));
  const returnObject = {
    counts: {},
    siteData: {},
  };
  sortedSites.forEach((site) => {
    returnObject.counts[site.region] = returnObject.counts[site.region] || 0;
    returnObject.siteData[site.region] = returnObject.siteData[site.region] || {};
    returnObject.siteData[site.region][site.city] = returnObject.siteData[site.region][site.city]
      || [];

    returnObject.counts[site.region] += 1;
    returnObject.siteData[site.region][site.city].push(site);
  });
  return returnObject;
};

const renderRow = (clinicalTrial) => {
  const sites = transformSites(clinicalTrial.sites);
  return (
    <tr key={clinicalTrial.id} className={styles.tableRow}>
      <td>
        <a
          href={clinicalTrial.url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.trialUrl}
        >
          {clinicalTrial.id}
        </a>
        {
          !!clinicalTrial.contact
          && (
            <ExpandableContainer
              expandedButtonContent="Hide content"
              collapsedButtonContent="Show content"
              buttonClassName={styles.overallContent}
            >
              Overall contact:
              {' '}
              {clinicalTrial.contact}
            </ExpandableContainer>
          )
        }
      </td>
      <td className={styles.title}>
        {clinicalTrial.title}
      </td>
      <td className={styles.phase}>
        {clinicalTrial.phase}
      </td>
      <td>
        {
          Object.keys(sites.siteData).map(region => (
            <ExpandableContainer
              expandedButtonContent={`${region} (${sites.counts[region]})`}
              collapsedButtonContent={`${region} (${sites.counts[region]})`}
              buttonClassName={styles.region}
              key={region}
            >
              {Object.keys(sites.siteData[region]).map(city => (
                <div key={city} className={styles.city}>
                  {city}
                  {' '}
                  (
                  {sites.siteData[region][city].length}
                  )
                  <p className={styles.centers}>
                    {sites.siteData[region][city].map(s => s.center).join(', ')}
                  </p>
                </div>
              ))}
            </ExpandableContainer>
          ))
        }
      </td>
    </tr>
  );
};

const ClinicalTrialsModal = (props) => {
  const [startClose, setStartClose] = useState(false);
  return (
    <Modal startClose={startClose} onClose={props.onClose}>
      <div className={styles.container}>
        <button
          className={styles.closeButton}
          type="button"
          onClick={() => setStartClose(true)}
        >
          <i className="fa fa-close" />
        </button>
        <div className={styles.titleContainer}>
          Available Clinical Trials -
          {' '}
          {props.geneName}
        </div>
        <table>
          <tbody>
            <tr className={styles.tableHeader}>
              <th className={styles.trialColumn}>Trial ID</th>
              <th className={styles.titleColumn}>Title</th>
              <th className={styles.phaseColumn}>Phase</th>
              <th className={styles.sitesColumn}>Site</th>
            </tr>
            {
              props.clinicalTrials.map(renderRow)
            }
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

ClinicalTrialsModal.propTypes = IPropTypes;
ClinicalTrialsModal.defaultProps = defaultProps;

export { ClinicalTrialsModal };
