import React from 'react';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AdminCreateGHAdmin } from './AdminCreateGHAdmin';
import { addBannerMessages } from '../store/actions/global';
import { store } from '../store';

const mapDispatchToProps = dispatch => bindActionCreators({
  addBannerMessages,
}, dispatch);


const ConnectedAdminCreateGHAdmin = connect(null, mapDispatchToProps)(AdminCreateGHAdmin);

export { ConnectedAdminCreateGHAdmin as AdminCreateGHAdmin };

const AdminCreateGHAdminContainer = () => (
  <Provider store={store}>
    <ConnectedAdminCreateGHAdmin />
  </Provider>
);

export default AdminCreateGHAdminContainer;
