import HttpService from '../services/HttpService';
import { PatientPageEndpoints } from '../endpoints';
import { PatientPageSerializer } from '../serializers/patient-page';
import { PatientPageMultiProduct } from '../../models/patient-page-multi-product';
import { getToken, isInternalUser } from './helper';

const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

export class PatientPageController {
  static getPatientInformation = async (requestId, patientRecordId, type, cacheReset) => {
    const response = await HttpService.get(`${PatientPageEndpoints.GET_PATIENT_INFORMATION}/${patientRecordId}`, {
      params: { request_id: requestId, cache_reset: cacheReset, type },
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    const patientPageSerialized = PatientPageSerializer.deserialize(response.data);
    return new PatientPageMultiProduct(patientPageSerialized);
  };

  static dismissAutoReleasePanel = async (requestId, authenticityToken) => {
    const response = await HttpService.post(`${PatientPageEndpoints.DISMISS_AUTO_RELEASE_PANEL}`, {
      request_id: requestId,
    }, {
      headers: {
        'X-CSRF-Token': authenticityToken,
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return response.data;
  }

  static releaseReport = async (params, authenticityToken) => {
    const response = await HttpService.post(PatientPageEndpoints.RELEASE_REPORT(params.patient_id),
      params, {
        headers: {
          'X-CSRF-Token': authenticityToken,
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      });

    return response;
  }

  static shareReport = async (params, authenticityToken) => (
    HttpService.post(PatientPageEndpoints.SHARE_REPORT,
      params, {
        headers: {
          'X-CSRF-Token': authenticityToken,
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      })
  )

  static downloadReport = async (link) => {
    const response = await HttpService.get(link);
    return response;
  }
}
