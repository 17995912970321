import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import axios from 'axios';
import { PrintModal } from '../print-modal';
import { printPDFURL } from '../../../networking/print-helper';
import { OrdersController } from '../../../networking/controllers/orders';
import { logger } from '../../../util/logger';
import { ElectronicSignatureConfirmationContainer } from '../electronic-signature-confirmation-container';
import { Order } from '../../../models/order/order';
import { getToken, isInternalUser } from '../../../networking/controllers/helper';

const IPropTypes = {
  toggleElectronicSignatureModal: PropTypes.func.isRequired,
  togglePrintModal: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  saveErrorMsg: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  order: PropTypes.instanceOf(Order),
  emrUser: PropTypes.bool.isRequired,
  saveGenericInfo: PropTypes.func.isRequired,
  trfCompleted: PropTypes.bool.isRequired,
  orderingUserEmail: PropTypes.string.isRequired,
  updateDashboard: PropTypes.func.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
};

const defaultProps = {
  order: null,
};

const PrintModalContainer = ({
  toggleElectronicSignatureModal,
  togglePrintModal,
  onError,
  saveErrorMsg,
  store,
  order,
  emrUser,
  saveGenericInfo,
  trfCompleted,
  orderingUserEmail,
  updateDashboard,
  updateLoadingCount,
}) => {
  if (!order) {
    return null;
  }
  if (trfCompleted) {
    return <Redirect to="/orders" />;
  }

  const onPrintError = (error) => {
    logger.error(error);

    if (error.response && error.response.data.order_id) {
      saveErrorMsg('Error encountered when Printing or Finalizing. Order created succesfully.');
      saveGenericInfo({ activeOrderId: error.response.data.order_id });
    } else {
      onError();
    }
  };

  const finalizingOrderServerAction = useCallback(async () => (
    OrdersController.printOrFinalizeOrder(
      store.genericInfo.activeOrderId,
      store,
      true,
    )
  ), [store]);

  const onClickPrintNow = useCallback(async () => {
    updateLoadingCount(1);
    const {
      activeOrderId,
      productFamily,
    } = store.genericInfo;

    try {
      const { order: completedOrder } = await OrdersController.printOrFinalizeOrder(
        activeOrderId, store,
      );
      const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
      axios.get(printPDFURL(completedOrder.metadata.envelopeId,
        completedOrder.id, productFamily), {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          window.open(url, '_blank');
        });
      if (emrUser) {
        window.close();
        return;
      }

      saveGenericInfo({
        trfCompleted: true,
        successMessage: `Requisition form successfully printed for ${completedOrder.patientInfo.formatFullName()}`,
      });

      updateDashboard(order.id ? order : null, completedOrder);
    } catch (error) {
      onPrintError(error);
    } finally {
      updateLoadingCount(-1);
    }
  }, [store, emrUser]);

  const onClickFinalizeOrder = useCallback(async () => {
    if (order.isEmbeddedSignature(orderingUserEmail)) {
      try {
        updateLoadingCount(1);

        const { redirectUrl } = await finalizingOrderServerAction();

        window.location.href = redirectUrl;
      } catch (error) {
        onPrintError(error);
      } finally {
        updateLoadingCount(-1);
      }
    } else {
      toggleElectronicSignatureModal();
      togglePrintModal();
    }
  }, [store.printModal, order, orderingUserEmail]);

  return (
    <>
      <PrintModal
        onClickPrintOrder={onClickPrintNow}
        onClickFinalizeOrder={onClickFinalizeOrder}
        disableFinalizeButton={
          !order.finalizationAvailable()
          || !order.canBeSigned(orderingUserEmail)
        }
        showNoPhysicianEmailDisclaimer={!order.finalizationAvailable()}
      />
      <ElectronicSignatureConfirmationContainer serverAction={finalizingOrderServerAction} />
    </>
  );
};

PrintModalContainer.propTypes = IPropTypes;
PrintModalContainer.defaultProps = defaultProps;

export { PrintModalContainer };
