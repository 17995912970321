import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  tableHeader: PropTypes.string,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

const defaultProps = {
  tableHeader: null,
};

const SortableTable = (props) => {
  const { tableHeader, columns, rows } = props;

  return (
    <div className="alterations__container">
      <div className="alterations__header" />
      <div className="alterations__header__title">
        {tableHeader}
      </div>

      <table className="alterations-table table--responsive">
        <thead>
          <tr>
            <th className="alterations-table__color" />
            {columns.map(column => (

              <th key={column.name} className="alterations-table__biomarker">{column.name}</th>
            ))}
            <th />
          </tr>

        </thead>

        <tbody>
          {
            rows.map((row, index) => (
              <tr key={`${row.name}-${row.type}-${row.value}`}>
                {/* If this isn't the first biomarker then the box
                should not have a top border to avoid duplicating it */}
                <td className="alterations-table__biomarker-color" style={{ borderTop: index > 0 ? 'none' : '' }} />
                <td className="alterations-table__biomarker alteration_shaded ">
                  <div className="alteration-description__gene">
                    {row.name}
                  </div>
                </td>
                <td className="alterations-table__additional-details alteration_shaded">{row.value}</td>
                <td className="alterations-table__additional-description alteration_shaded">{row.description}</td>
              </tr>
            ))
          }

        </tbody>
      </table>

    </div>
  );
};

SortableTable.propTypes = IPropTypes;
SortableTable.defaultProps = defaultProps;

export default SortableTable;
