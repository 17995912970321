import React from 'react';
import PropTypes from 'prop-types';

import styles from './share-form.module.scss';
import { PatientPageController } from '../../../networking/controllers/patient-page';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  patient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  shareReportEnabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

class ShareForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareForm: {
        firstName: '',
        lastName: '',
        email: '',
      },
    };
  }

  inputChangeHandler = field => (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      shareForm: {
        ...prevState.shareForm,
        [field]: value,
      },
    }));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { patient, authenticityToken, onSubmit } = this.props;

    // TODO: Add more cocmplex validation on email field before yielding the submission.
    // eslint-disable-next-line no-alert
    if (!window.confirm(`Are you sure you want to share ${patient.lastName} ${patient.firstName}'s current and historical reports with this recipient?`)) {
      return;
    }

    window.showSpinner();
    let bannerMessage;
    let status;
    try {
      const response = await PatientPageController.shareReport(
        this.shareParams(),
        authenticityToken,
      );
      bannerMessage = response.data.message;
      status = 'success';
    } catch (error) {
      bannerMessage = error.response.data.message;
      status = 'error';
    }
    window.hideSpinner();
    onSubmit(status, bannerMessage);
  }

  shareParams() {
    const { patient } = this.props;
    const { shareForm } = this.state;

    return {
      patient_id: patient.id,
      accept_terms: true,
      sharee: {
        first_name: shareForm.firstName,
        last_name: shareForm.lastName,
        email: shareForm.email,
        notification: false,
      },
    };
  }

  render() {
    const {
      shareForm,
    } = this.state;
    const { patient, shareReportEnabled } = this.props;

    return (
      <>
        <p className={styles.description}>{`Invite an additional recipient to view ${patient.lastName} ${patient.firstName}'s patient report.`}</p>
        <form onSubmit={this.handleSubmit}>
          <div className={styles.formItem}>
            <div className={styles.leftItem}>
              <label className={styles.nameLabel} htmlFor="__first_name_">First Name</label>
              <input type="text" name="[first_name]" id="_first_name" placeholder="e.g. Joe" required value={shareForm.firstName} onChange={this.inputChangeHandler('firstName')} />
            </div>

            <div className={styles.rightItem}>
              <label className={styles.nameLabel} htmlFor="__last_name_">Last Name</label>
              <input type="text" name="[last_name]]" id="_last_name" placeholder="e.g. Smith" required value={shareForm.lastName} onChange={this.inputChangeHandler('lastName')} />
            </div>
          </div>

          <div className={styles.formItem}>
            <label className={styles.nameLabel} htmlFor="__email_">Email Address</label>
            <input type="email" name="[email_address]]" id="_email_address" placeholder="e.g. physician@example.com" required value={shareForm.email} onChange={this.inputChangeHandler('email')} />
          </div>

          <div className={styles.termsContainer}>
            <label className={styles.labelTerms} htmlFor="accept_terms">
              <input type="checkbox" name="accept_terms" id="accept_terms" required="required" onChange={this.handleCheckmarkChange} />
              I confirm the individual(s) to whom I am sending this invitation is a health
              professional or workforce member at my organization authorized to access and
              use health information in accordance with my organization policies, HIPAA
              , federal, state, and local requirements, and the service
              <a target="_blank" href="/terms_of_use">Terms and Conditions of Use</a>
              . Your invitee will be required to set up a Provider Portal account and to
              read and accept the Terms and Conditions of Use before access to the Provider
              Portal will be provided to him/her.
            </label>
          </div>

          <button type="submit" className={styles.submitButton} disabled={!shareReportEnabled}>Send Invitation</button>
        </form>
      </>
    );
  }
}

ShareForm.propTypes = IPropTypes;

export { ShareForm };
