import { currentTherapyActionTypes } from '../../../actions/orders/active-order/current-therapy-info';
import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';

const currentTherapyInitialState = {
  advancedCancer: false,
  targetedTherapy: false,
  immunotherapy: false,
  chemotherapy: false,
  combinationTherapy: false,
  specificTherapy: '',
  dateOfTherapyInitiation: '',
};

const currentTherapyInfo = (state = currentTherapyInitialState, action) => {
  let newState;
  switch (action.type) {
    case RECEIVE_ORDER:
      return { ...state, ...action.order.currentTherapyInfo };
    case currentTherapyActionTypes.ADD_CURRENT_THERAPY_INFO:
      newState = { ...state, ...action.info };
      return newState;
    default:
      return state;
  }
};

export default currentTherapyInfo;
