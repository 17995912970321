import { RevealOrder } from './reveal-order';
import { G360Order } from './g360-order';
import { constants } from '../../config/constants';

const buildOrder = (store) => {
  if (store.genericInfo.productFamily === constants.etrfOrdering.productFamilies.reveal) {
    return new RevealOrder(store);
  }

  return new G360Order(store);
};

export { buildOrder };
