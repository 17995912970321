import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => createStyles({
  root: {
    margin: '0.5rem 0',
    [theme.breakpoints.down('sm')]: {
      margin: '0.2 !important',
    },
  },
  ghFormatText: {
    fontSize: '0.8rem',
    color: '#00478B',
  },
  tooltipText: {
    fontSize: '0.8rem',
    fontWeight: 'bolder',
    color: '#8B939C',
    textTransform: 'uppercase',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    whiteSpace: 'nowrap',
    boxShadow: theme.shadows[1],
    width: 'auto',
    fontSize: 11,
    position: 'absolute',
    top: '-20px',
  },
  tooltipButton: {
    verticalAlign: 'top',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5',
    whiteSpace: 'nowrap',
    color: '#00478B',
    textTransform: 'capitalize',
    fontSize: '0.8rem',
    paddingTop: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
export const FormatedText = ({ text }) => {
  const classes = useStyles();
  return <div className={classes.ghFormatText}>{text}</div>;
};
FormatedText.propTypes = {
  text: PropTypes.string.isRequired,
};

const IPropTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const Tooltip = (props) => {
  const { name, text, title } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.tooltipText}>
        {name}
        :
        {' '}
      </span>
      <MuiTooltip disableFocusListener title={title} classes={{ tooltip: classes.tooltip }}>
        <Button className={classes.tooltipButton}>{text}</Button>
      </MuiTooltip>
    </div>
  );
};

export default Tooltip;
Tooltip.propTypes = IPropTypes;
