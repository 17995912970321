import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MedicalCollaborator } from './medical-collaborator';
import { AdditionalRecipient } from './additional-recipient';

const IPropTypes = {
  secondaryRecipient: PropTypes.shape({
    secondaryRecipientPhone: PropTypes.string,
    secondaryRecipientFax: PropTypes.string,
    secondaryRecipientFirstname: PropTypes.string,
    secondaryRecipientLastname: PropTypes.string,
  }),
  savePhysicianInfo: PropTypes.func.isRequired,
};

const defaultProps = {
  secondaryRecipient: {},
};

const AdditionalRecipientContainer = ({ secondaryRecipient, savePhysicianInfo }) => {
  const [additionalReceipientToggled, setAdditionalReceipientToggled] = useState(false);

  const {
    secondaryRecipientPhone,
    secondaryRecipientFax,
    secondaryRecipientFirstname,
    secondaryRecipientLastname,
  } = secondaryRecipient;

  useEffect(
    () => {
      if (secondaryRecipientPhone
        || secondaryRecipientFax
        || secondaryRecipientFirstname
        || secondaryRecipientLastname
      ) {
        setAdditionalReceipientToggled(true);
      }
    }, [],
  );

  const updateInput = id => (event) => {
    const { value } = event.target;

    savePhysicianInfo({
      secondaryRecipient: {
        ...secondaryRecipient,
        [id]: value,
      },
    }, true);
  };

  const toggleAdditionalReceipient = () => {
    setAdditionalReceipientToggled(!additionalReceipientToggled);
  };

  return (
    <div>
      { additionalReceipientToggled
        ? (
          <div>
            <MedicalCollaborator
              toggleAdditionalReceipient={toggleAdditionalReceipient}
              updateInput={updateInput}
            />

          </div>
        )
        : (
          <div>
            <AdditionalRecipient
              toggleAdditionalReceipient={toggleAdditionalReceipient}
            />
          </div>
        )
          }
    </div>
  );
};

AdditionalRecipientContainer.propTypes = IPropTypes;
AdditionalRecipientContainer.defaultProps = defaultProps;

export { AdditionalRecipientContainer };
