import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  toggleShowSearch: PropTypes.func,
  storeSelectedItem: PropTypes.func,
  fetchData: PropTypes.func,
};

const defaultProps = {
  toggleShowSearch: null,
  storeSelectedItem: () => {},
  fetchData: () => {},
};

class ApiSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      inputVal: '',
      showDropdown: false,
    };

    this.setInputVal = this.setInputVal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.matches = this.matches.bind(this);
    this.updateList = this.updateList.bind(this);
  }

  setInputVal(e) {
    this.setState({
      inputVal: e.target.innerText,
      showDropdown: false,
    });

    if (this.props.toggleShowSearch) {
      this.props.toggleShowSearch(e, e.target.innerText.split(' ')[0]);
    }
    this.props.storeSelectedItem(e.target.innerText);
  }

  matches() {
    const matches = [];
    if (this.state.inputVal.length > 1) {
      return this.state.list;
    }

    this.state.list.forEach((searchResult) => {
      let text = searchResult.title;
      text = text.replace(/ +/g, ' ').toLowerCase();
      const val = this.state.inputVal.replace(/ +/g, ' ').toLowerCase();
      if (text.indexOf(val) !== -1) {
        matches.push(searchResult);
      }
    });

    return matches;
  }

  updateList(data) {
    this.setState({ list: data });
  }

  handleInputChange(e) {
    this.setState({ inputVal: e.target.value }, () => {
      if (this.state.inputVal.length > 1) {
        this.props.fetchData(this.state.inputVal, this.updateList);
        return this.setState({ showDropdown: true });
      }
      return this.setState({ showDropdown: false });
    });
  }

  content() {
    const matches = this.matches();

    let dropdown = null;
    if (matches) {
      dropdown = matches.map(result => (
        <div id={result.id} key={result.id} className="dd-list-item" onClick={e => this.setInputVal(e)}>{`${result.title}`}</div>
      ));
    }

    return (
      <div className="content content-space">
        <div ref={(node) => { this.node = node; }}>
          <div className="search-wrapper">
            <div className="search-header">
              <input type="text" name="[project_id]]" id="_project_id" className="input__ghi-number" value={this.state.inputVal} onChange={this.handleInputChange} required placeholder="Search..." />
              <div className="search-i-container">
                <i className="fa fa-search" aria-hidden="true" />
              </div>
            </div>

            {
              this.state.showDropdown

                ? (
                  <div className="dd-list search-dropdown">
                    {dropdown}
                  </div>
                )
                : null
            }

          </div>
        </div>
      </div>
    );
  }

  render() {
    const content = this.content();

    return (
      <div>{content}</div>
    );
  }
}

ApiSearch.propTypes = IPropTypes;
ApiSearch.defaultProps = defaultProps;

export default ApiSearch;
