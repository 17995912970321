import { createAction } from 'redux-actions';

export const GET_PROJECT_ASSIGNMENT_LIST = '@usermanagment-workflow/GET_PROJECT_ASSIGNMENT_LIST';
export const getProjectAssignmentList = createAction(GET_PROJECT_ASSIGNMENT_LIST);

export const GET_ALL_PROJECTS = '@usermanagment-workflow/GET_ALL_PROJECTS';
export const getAlProjects = createAction(GET_ALL_PROJECTS);

export const GET_PROJECT_ASSIGNMENT_LIST_DONE = '@usermanagment-workflow/GET_PROJECT_ASSIGNMENT_LIST_DONE';
export const getProjectsListDone = createAction(GET_PROJECT_ASSIGNMENT_LIST_DONE);

export const PROJECT_ASSIGNMENT_LIST_ERROR = '@usermanagment-workflow/PROJECT_ASSIGNMENT_LIST_ERROR';
export const projectAssignmentListError = createAction(PROJECT_ASSIGNMENT_LIST_ERROR);

export const SAVE_PROJECT_ASSIGNMENT = '@usermanagment-workflow/SAVE_PROJECT_ASSIGNMENT';
export const saveProjectAssignment = createAction(SAVE_PROJECT_ASSIGNMENT);

export const SAVE_PROJECT_ASSIGNMENT_DONE = '@usermanagment-workflow/SAVE_PROJECT_ASSIGNMENT_DONE';
export const saveProjectAssignmentDone = createAction(SAVE_PROJECT_ASSIGNMENT_DONE);

export const SAVE_PROJECT_ASSIGNMENT_ERROR = '@usermanagment-workflow/SAVE_PROJECT_ASSIGNMENT_ERROR';
export const saveProjectAssignmentError = createAction(SAVE_PROJECT_ASSIGNMENT_ERROR);

export const UPDATE_PROJECT_ASSIGNMENT = '@usermanagment-workflow/UPDATE_PROJECT_ASSIGNMENT';
export const updateProjectAssignment = createAction(UPDATE_PROJECT_ASSIGNMENT);

export const UPDATE_PROJECT_ASSIGNMENT_DONE = '@usermanagment-workflow/UPDATE_PROJECT_ASSIGNMENT_DONE';
export const updateProjectAssignmentDone = createAction(UPDATE_PROJECT_ASSIGNMENT_DONE);

export const UPDATE_PROJECT_ASSIGNMENT_ERROR = '@usermanagment-workflow/UPDATE_PROJECT_ASSIGNMENT_ERROR';
export const updateProjectAssignmentError = createAction(UPDATE_PROJECT_ASSIGNMENT_ERROR);

export const DELETE_PROJECT_ASSIGNMENT = '@usermanagment-workflow/DELETE_PROJECT_ASSIGNMENT';
export const deleteProjectAssignment = createAction(DELETE_PROJECT_ASSIGNMENT);

export const DELETE_PROJECT_ASSIGNMENT_DONE = '@usermanagment-workflow/DELETE_PROJECT_ASSIGNMENT_DONE';
export const deleteProjectAssignmentDone = createAction(DELETE_PROJECT_ASSIGNMENT_DONE);

export const DELETE_PROJECT_ASSIGNMENT_ERROR = '@usermanagment-workflow/DELETE_PROJECT_ASSIGNMENT_ERROR';
export const deleteProjectAssignmentError = createAction(DELETE_PROJECT_ASSIGNMENT_ERROR);

export const PROJECT_ASSIGN_PAGINATION_CHANGE = '@usermanagment-workflow/PROJECT_ASSIGN_PAGINATION_CHANGE';
export const changePageProjectAssignment = createAction(PROJECT_ASSIGN_PAGINATION_CHANGE);

export const ON_CHANGE_PROJECT_ASSIGNMENT = '@usermanagment-workflow/ON_CHANGE_PROJECT_ASSIGNMENT';
export const onChangeProjectAssignment = createAction(ON_CHANGE_PROJECT_ASSIGNMENT);

export const defaultProjectAssignment = createAction('');
