import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  addSitesData,
  getSitesData,
  changePagination,
  deleteSitesData,
  editSitesData,
  onChange,
} from './actions/SitesAction';
import { getAccountsData, onChange as accountOnChange } from '../accounts/actions';
import SitesView from './SitesView';

const mapStateToProps = state => ({
  sites: state.sites,
  accounts: state.accounts,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addSitesData,
      getSitesData,
      changePagination,
      deleteSitesData,
      editSitesData,
      getAccountsData,
      onChange,
      accountOnChange,
    },
    dispatch,
  ),
});
// eslint-disable-next-line max-len
export const ConnectedSitesViewContainer = connect(mapStateToProps, mapDispatchToProps)(SitesView);
export default ConnectedSitesViewContainer;
