import { connect } from 'react-redux';

import { PatientInformationDemoFaceSheet } from './patient-information-demo-face-sheet';

const mapStateToProps = store => ({
  patientStore: store.orders.activeOrder.patientInfo,
});

const ConnectedPatientInformationDemoFaceSheet = connect(
  mapStateToProps,
)(PatientInformationDemoFaceSheet);

export { ConnectedPatientInformationDemoFaceSheet as PatientInformationDemoFaceSheet };
