import { deserializeDate, serializeDate } from './helpers';
import { TissueSpecimenRetrievalSerializer } from './tissue-specimen-retrieval';

class SpecimenSerializer {
  static deserialize = (specimen) => {
    if (!specimen || !specimen.bloodSpecimen) {
      return {};
    }

    const { bloodSpecimen } = specimen;

    return {
      specimenCollectionDate: deserializeDate(bloodSpecimen.collectionDate),
      labClinicianText: bloodSpecimen.clinicianName,
    };
  }

  static serialize = (bloodSpecimen = {}, tissueSpecimenRetrieval) => {
    const specimen = {
      bloodSpecimen: {
        collectionDate: serializeDate(bloodSpecimen.specimenCollectionDate),
        clinicianName: bloodSpecimen.labClinicianText,
      },
    };
    if (tissueSpecimenRetrieval) {
      specimen.tissueSpecimen = TissueSpecimenRetrievalSerializer.serialize(
        tissueSpecimenRetrieval,
      );
    }
    return specimen;
  }
}

export { SpecimenSerializer };
