import { closeSpinner, showSpinner } from '../../admin/common/utils/spinner/actions';
import HttpServiceSearch from '../../admin/service/searchInterceptor';
import HttpServiceOrders from '../../admin/service/newOrdersInterceptor';
import HttpServiceConfig from '../../admin/service/configSrvcInterceptor';
import HttpServiceSfdc from '../../admin/service/sfdcIntSrvcInterceptor';
import HttpServiceEtrf from '../../admin/service/etrfInterceptor';
import HttpServiceV2api from '../../admin/service/orderV2Api';
import * as actionTypes from './actionTypes';
import { config } from '../../config/config';
import { APP_BASE_URL } from '../../config';
import { logger } from '../../../util/logger';
import {
  loadPhysiciansDone,
  loadPhysicianAdminDone,
  downloadReportDone,
  eSignReportDone,
} from '../../shares/actions';

export const completeSaveData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceOrders.post('/orders/validateAndSave', params);
};
export const getAllcancer = () => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceOrders.get('/orders/diagnosis?key=all')
    .then(({ data }) => {
      data.push(
        {
          id: -1,
          diagnosis: "Other",
          trfDisplayName: "Other",
          limsDiseaseId: "-1",
          category: "Other",
          cancerTypeOrder: 2,
          categoryOrder: 8,
          parentId: null
        });
      //const newData = _.groupBy(data, 'categoryOrder');
      dispatch(closeSpinner());
      dispatch(actionTypes.onChangeNewOrder({ name: ['cancerTypeList'], value: data }));
    })
    .catch((err) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['cancerTypeList'], value: {} }));
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};
export const getTestSelections = () => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceV2api.get('/order-mgmt/api/v2/orders/testSelection')
    .then(({ data }) => {
      dispatch(closeSpinner());
      dispatch(actionTypes.onChangeNewOrder({ name: ['testSelection'], value: data }));
    })
    .catch((err) => {
      dispatch(actionTypes.showError(err));
      dispatch(actionTypes.onChangeNewOrder({ name: ['testSelection'], value: [] }));
      dispatch(closeSpinner());
    });
};

export const getCancerList = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceSearch.get(
    `/search/icdcodes?key=${params}&limit=30&offset=1`,
  )
    .then((res) => {
      dispatch(actionTypes.getICRCODES(res));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};
export const getSecoundaryList = (params, key) => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceSearch.get(
    `/search/icdcodes?key=${params}&limit=30&offset=1`,
  )
    .then((res) => {
      dispatch(actionTypes.getICRCODES21({ data: res.data, key }));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};
export const nextsaveData = params => (dispatch) => {
  dispatch(actionTypes.saveData(params));
};

export const setReveal = params => (dispatch) => {
  dispatch(actionTypes.setGuardantReveal(params));
};
export const setError = params => (dispatch) => {
  dispatch(actionTypes.onError(params));
};

export const onTabChange = params => (dispatch) => {
  dispatch(actionTypes.changeTabChange(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeNewOrder(params));
};
export const saveCurrentData = (data, callback) => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceOrders.post('/orders/', data)
    .then((res) => {
      dispatch(actionTypes.saveData(res));
      dispatch(closeSpinner());
      callback();
    })
    .catch((err) => {
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};
export const updateCurrentData = (data, callback) => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceOrders.put('/orders/', data)
    .then((res) => {
      dispatch(actionTypes.saveData(res));
      dispatch(closeSpinner());
      callback();
    })
    .catch((err) => {
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};
export const getAllStates = (country = 'usa') => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceConfig.get(`/states?country=${country}`)
    .then(({ data }) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['stateList'], value: data.data }));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['stateList'], value: [] }));
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};

export const getAccountNumbers = (accountNumbers, params) => (dispatch) => {
  dispatch(showSpinner());
  dispatch(actionTypes.onChangeNewOrder({ name: ['isLoaded'], value: true }));
  return HttpServiceSfdc.get(`/accounts?account-number=${accountNumbers}&email-address=${encodeURIComponent(params)}`)
    .then(({ data }) => {
      const currentAccount = data.data;
      const client = {
        accountNumber: currentAccount.accountNumber,
        practiceName: currentAccount.practiceName,
        phone: currentAccount.practicePhone,
        fax: currentAccount.practiceFax,
        address: {
          address1: currentAccount.address1,
          address2: currentAccount.address2,
          city: currentAccount.practiceCity,
          state: currentAccount.practiceState,
          zipCode: currentAccount.practiceZipcode,
          country: currentAccount.practiceCountry,
        },
      };
      dispatch(actionTypes.onChangeNewOrder({ name: ['schema', 'order', 'client'], value: client }));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['accounts'], value: [] }));
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};
export const getPhysiciansNumbers = (accountNumbers, params) => (dispatch) => {
  dispatch(showSpinner());
  dispatch(actionTypes.onChangeNewOrder({ name: ['physicians'], value: [] }));
  return HttpServiceSfdc.get(`/contacts/physicians-by-account?accountNumbers=${accountNumbers}&email-address=${encodeURIComponent(params)}`)
    .then(({ data }) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['physicians'], value: data.data }));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['physicians'], value: [] }));
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};
export const eSignAndSubmit = params => (dispatch) => {
  dispatch(showSpinner());
  const order = `{"order": ${JSON.stringify(params.data)}}`;
  if (params.delegee && params.embaded) {
    return HttpServiceEtrf.post(`/signatures?embedded=${params.embaded}&signatureDelegee=${params.delegee}&signeeEmail=${encodeURIComponent(params.signeeEmail)}&callbackUrl=${config()[APP_BASE_URL]}/new_orders`, order);
  }
  return HttpServiceEtrf.post(`/signatures?embedded=${params.embaded}&signeeEmail=${encodeURIComponent(params.signeeEmail)}&callbackUrl=${config()[APP_BASE_URL]}/new_orders`, order);
};

export const printSignSend = params => () => {
  const newParams = `{"order": ${JSON.stringify(params)}}`;
  return HttpServiceEtrf.post('/etrfs', newParams);
};

export const finalizeOrder = params => () => HttpServiceOrders.put('/orders/finalize', params);
export const updateSignature = params => () => HttpServiceEtrf.patch(`/etrfs/${params}`);

export const getClinicalHistory360 = () => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceOrders.get('/orders/relevantClinicalHistory?productFamilyId=G360')
    .then((res) => {
      dispatch(actionTypes.clinicalHistoryData360(res));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};
export const getClinicalHistoryReveal = () => (dispatch) => {
  dispatch(showSpinner()); return HttpServiceOrders.get('/orders/relevantClinicalHistory?productFamilyId=REVEAL&version=2')
    .then((res) => {
      dispatch(actionTypes.clinicalHistoryDataReveal(res));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.showError(err));
      dispatch(closeSpinner());
    });
};

export const downloadOrderForm = (orderId, history) => dispatch => HttpServiceEtrf.get(`/etrfs/${orderId}`, { responseType: 'blob' })
  .then((response) => {
    dispatch(actionTypes.reviewOrderData({ key: ['isDownload'], value: 'complete' }));
    const windowUrl = window.URL || window.webkitURL;
    const href = windowUrl.createObjectURL(response.data);
    const a = document.createElement('a');
    a.setAttribute('download', `Guardant360-${orderId}-TestRequisition`);
    a.setAttribute('href', href);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    dispatch(closeSpinner());
    if (history) {
      history.push('/new_orders');
    }
  })
  .catch(() => {
    dispatch(closeSpinner());
  });
export const getUserAccountsData = params => (dispatch) => {
  dispatch(actionTypes.onChangeNewOrder({ name: ['user'], value: [] }));
  dispatch(showSpinner());
  return HttpServiceSfdc.get(`contacts/physicians-by-email?email-address=${encodeURIComponent(params)}`)
    .then(({ data }) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['user'], value: Array.isArray(data.data) ? data.data : [data.data] }));
      dispatch(closeSpinner());
    }).catch(() => {
      dispatch(closeSpinner());
    });
};

export const discardOrder = (orderId, params) => {
  HttpServiceOrders.delete(`orders/${orderId}?userId=${params.userId}&physicianEmail=${params.physicianEmail}`)
    .then(() => {
      window.location.pathname = '/new_orders';
    })
    .catch(error => logger.error(error));
};


export const resetDefaultState = () => dispatch => dispatch(actionTypes.resetState());
// eslint-disable-next-line no-unused-vars
export const ValidateIcdCodes = (code, type) => dispatch => HttpServiceOrders.get(`/icdcancertype?icdCodes=${code}&cancerType=${type}`);
export const closeOederSpinner = () => dispatch => dispatch(closeSpinner());

export const updateDelegeeFlag = () => dispatch => dispatch(actionTypes.updateDelegeeFlag());

export const loadPhysicianAdminList = (email = '') => (dispatch) => {
  return HttpServiceEtrf.get(`/consents/search?user=${encodeURIComponent(email)}&userType=physician`)
    .then(({ data }) => {
      dispatch(loadPhysicianAdminDone(data));
    })
    .catch((error) => {
      dispatch(actionTypes.showError(error));
    });
};

export const loadPhysiciansList = (email = '') => (dispatch) => {
  return HttpServiceEtrf.get(`/consents/search?user=${encodeURIComponent(email)}&userType=physicianAdmin`)
    .then(({ data }) => {
      dispatch(loadPhysiciansDone(data));
    })
    .catch((error) => {
      dispatch(actionTypes.showError(error));
    });
};

export const downloadContractReport = (requestedBy, signee, accountNumber) => (dispatch) => {
  window.showSpinner();
  return HttpServiceEtrf.get(`/consents?requestedBy=${encodeURIComponent(requestedBy)}&signee=${encodeURIComponent(signee)}&accountId=${accountNumber}`, { responseType: 'blob' })
    .then((response) => {
      const windowUrl = window.URL || window.webkitURL;
      const href = windowUrl.createObjectURL(response.data);
      const a = document.createElement('a');
      a.setAttribute('download', 'Authorized Consent');
      a.setAttribute('href', href);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      dispatch(downloadReportDone());
      window.hideSpinner();
    })
    .catch((error) => {
      dispatch(actionTypes.showError(error));
      window.hideSpinner();
    });
};

export const eSignContractReport = params => (dispatch) => {
  window.showSpinner();
  return HttpServiceEtrf.post('/consents/', params)
    .then(() => {
      dispatch(eSignReportDone());
      window.hideSpinner();
    })
    .catch((error) => {
      dispatch(actionTypes.showError(error));
      window.hideSpinner();
    });
};

export const getEmbadedSigneeStatus = (requestedBy, signee, accountNumber) => (dispatch) => {
  dispatch(showSpinner());
  return HttpServiceEtrf.get(`/consents/exists?requestedBy=${encodeURIComponent(requestedBy)}&signee=${encodeURIComponent(signee)}&accountId=${accountNumber}`)
    .then(({ data }) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['isConsentExists'], value: data }));
      if (!data) {
        dispatch(actionTypes.onChangeNewOrder({ name: ['embaded'], value: requestedBy === signee }));
      } else {
        dispatch(actionTypes.onChangeNewOrder({ name: ['embaded'], value: data }));
      }
      dispatch(updateDelegeeFlag());
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.onChangeNewOrder({ name: ['isConsentExists'], value: false }));
      dispatch(actionTypes.showError(err));
      dispatch(updateDelegeeFlag());
      dispatch(closeSpinner());
    });
};
