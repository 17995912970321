/* eslint-disable no-nested-ternary, consistent-return */
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  TableBody as MuiTableBody,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ActionsMenu from './ActionsMenu';
import Download from '../../../../assets/images/icons/Download-Icon.svg';
import Print from '../../../../assets/images/icons/Print.svg';


const useStyles = makeStyles(() => createStyles({
  card: {
    width: '100%',
    fontWeight: 'normal',
  },
  cardContent: {
    marginTop: '-3.5rem',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  tableCell: {
    borderBottom: 'none !important',
  },
  gridItem: {
    padding: '5px',
  },
  typography: {
    textTransform: 'capitalize',
  },
  TableCell: {
    padding: '6px 30px !important',
  },
}));

export const ResponsiveTableBody = (props) => {
  const {
    rows, headers: columns, actions = null, userPermission,
  } = props;
  const classes = useStyles();
  return (
    <>
      {rows.length > 0 ? (
        rows.map((row) => {
          const columnContent = columns.map((column) => {
            switch (column.type) {
              case 'text':
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography
                        variant="body2"
                        className={classes.typography}
                      >
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">
                        {row[column.name]}
                      </Typography>
                    </Grid>
                  </>
                );
              case 'date':
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography
                        variant="body2"
                        className={classes.typography}
                      >
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">
                        {moment(row[column.name]).format('DD-MMM-YYYY')}
                      </Typography>
                    </Grid>
                  </>
                );
              case 'object':
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography
                        variant="body2"
                        className={classes.typography}
                      >
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">
                        {(row[column.name].name || '').toString()}
                      </Typography>
                    </Grid>
                  </>
                );
              case 'array':
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography
                        variant="body2"
                        className={classes.typography}
                      >
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">
                        {row[column.name].map(x => (x.name ? x.name : x)).join(',')}
                      </Typography>
                    </Grid>
                  </>
                );
              default:
                return (
                  <>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography
                        variant="body2"
                        className={classes.typography}
                      >
                        {column.name}
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                      <Typography variant="body2">
                        {row[column.name]}
                      </Typography>
                    </Grid>
                  </>
                );
            }
          });
          return (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Card className={classes.card}>
                  <CardHeader
                    title=""
                    action={(
                      <ActionsMenu
                        actions={actions}
                        row={row}
                        userPermission={userPermission}
                      />
                    )}
                  />
                  <CardContent className={classes.cardContent}>
                    <Grid container>{columnContent}</Grid>
                  </CardContent>
                </Card>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell>
            <Typography>No data to display</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
ResponsiveTableBody.propTypes = {
  rows: PropTypes.array,
  headers: PropTypes.array,
  actions: PropTypes.array,
  userPermission: PropTypes.array,
};
ResponsiveTableBody.defaultProps = {
  rows: [],
  headers: [],
  actions: null,
  userPermission: [],
};
const TableBody = (props) => {
  const {
    rows,
    headers,
    userPermission,
    download,
    buttonClass,
    editHandler,
  } = props;
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const noDataMessage = () => {
    if (buttonClass[0]) {
      return 'You currently have no orders To Be Completed';
    }
    if (buttonClass[1]) {
      return 'You currently have no orders Pending E-signature';
    }
    if (buttonClass[2]) {
      return 'You currently have no orders Completed';
    }
  };
  return (
    <MuiTableBody>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isMobile ? (
        <ResponsiveTableBody {...props} />
      ) : rows.length > 0 ? (

        rows.map((row) => {
          const { status } = row;
          const cloumnsKeys = headers;
          const columns = cloumnsKeys.map((item) => {
            switch (item.type) {
              case 'name':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    <Link
                      to="/newOrder"
                      onClick={() => {
                        editHandler(row);
                      }}
                    >
                      {`${row.patient?.demographic?.lastName || ''}, ${row.patient?.demographic?.firstName || ''}`}
                    </Link>
                  </TableCell>
                );
              case 'client':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    {`${row.client ? row.client.practiceName : ''}`}
                  </TableCell>
                );
              case 'authorizingProvider':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    {`${row.authorizingProvider?.lastName || ''}, ${row.authorizingProvider?.firstName || ''}`}
                  </TableCell>
                );
              case 'date':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    {moment(row.patient?.demographic[item.name]).format('MMM-DD-YYYY').toUpperCase() || ''}
                  </TableCell>
                );
              case 'object':
                return (
                  <TableCell key={Math.random()}>
                    {(row[item.name]?.name || '').toString()}
                  </TableCell>
                );
              case 'testOrdered':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    {(row[item.type]?.name != null ? row[item.type]?.name[0] : '').toString()}
                  </TableCell>
                );
              case 'orderingUser':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    {`${row.orderingUser?.lastName || ''}, ${row.orderingUser?.firstName || ''}`}
                  </TableCell>
                );
              case 'updatedAt':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    {moment(row.updatedAt).format('MM-DD-YYYY').toUpperCase()}
                  </TableCell>
                );
              case 'diagnosis':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    {(row[item.type]?.disease || '').toString()}
                  </TableCell>
                );
              case 'array':
                return (
                  <TableCell key={Math.random()}>
                    {row[item.name]?.map(x => x.name).join(', ')}
                  </TableCell>
                );
              case 'image':
                return (
                  <TableCell className={classes.TableCell} key={Math.random()}>
                    {status === 'PRINTED' || status === 'COMPLETE' ? (
                      <img style={{ width: '25px', height: '25px' }} alt="Download-Logo" src={Print} />
                    ) : (status === 'SIGNED'
                      ? (
                        <div style={{
                          width: '25px', height: '25px', backgroundColor: '#F9DB24', marginBottom: '10px',
                        }}
                        >
                          <img style={{ width: '25px', height: '25px' }} alt="Print-Logo" src={Download} />
                        </div>
                      ) : null
                    )}
                  </TableCell>
                );
              default:
                return (
                  <TableCell key={Math.random()}>
                    {row[item.name]}
                  </TableCell>
                );
            }
          });
          const actionItems = isTab ? (
            <ActionsMenu download={download} row={row} userPermission={userPermission} />
          ) : (!buttonClass[0]
            ? download.map(item => (
              <IconButton
                aria-label={item.action}
                key={item.action}
                disabled={!userPermission[item.action]}
                onClick={() => item.handleAction(row)}
                size="large"
              >
                <i style={{ color: 'rgb(152,152,152)' }} className="fa fa-download" />
              </IconButton>
            )) : null
          );
          return (
            <TableRow key={row.uuid}>
              {columns}
              {download && !buttonClass[0] && <TableCell style={{ whiteSpace: 'nowrap' }}>{actionItems}</TableCell>}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell variant="footer" colSpan={(headers?.length || 0) + 1}>
            <Typography style={{ textAlign: 'center', lineHeight: '15' }}>{!download ? 'No data to display' : noDataMessage()}</Typography>
          </TableCell>
        </TableRow>
      )}
    </MuiTableBody>
  );
};
TableBody.propTypes = {
  rows: PropTypes.array,
  actions: PropTypes.array,
  headers: PropTypes.array,
  userPermission: PropTypes.object,
  dynamic: PropTypes.string,
  link: PropTypes.object,
  download: PropTypes.any,
  buttonClass: PropTypes.string,
  editHandler: PropTypes.func.isRequired,
};
TableBody.defaultProps = {
  rows: [],
  actions: null,
  headers: [],
  userPermission: {},
  dynamic: '',
  link: {},
  download: null,
  buttonClass: null,
};
export default TableBody;
