import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './release-form.module.scss';
import { PatientPageController } from '../../../networking/controllers/patient-page';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  patient: PropTypes.shape({
    emailAddress: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.number.isRequired,
    lastName: PropTypes.string,
    readOnlyEmail: PropTypes.bool.isRequired,
  }).isRequired,
  requestId: PropTypes.string.isRequired,
  releaseReportEnabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

class ReleaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      releaseForm: {
        textMessage: 'Hello, here is a copy of your report to keep for your records.',
        email: props.patient.emailAddress || '',
      },
    };
  }

  componentDidMount() {
    // TODO: remove JQuery usage on React components.
    window.$('[data-toggle="popover"]').popover();
  }

  inputChangeHandler = field => (event) => {
    const updatedReleaseForm = { ...this.state.releaseForm };
    updatedReleaseForm[field] = event.target.value;
    this.setState(() => ({ releaseForm: updatedReleaseForm }));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { authenticityToken, onSubmit } = this.props;

    // TODO: Add more cocmplex validation on email field before yielding the submission.

    window.showSpinner();
    let bannerMessage;
    let status;
    try {
      const response = await PatientPageController.releaseReport(
        this.releaseParams(),
        authenticityToken,
      );
      bannerMessage = response.data.message;
      status = 'success';
    } catch (error) {
      bannerMessage = error.response.data.message;
      status = 'error';
    }
    window.hideSpinner();
    onSubmit(status, bannerMessage);
  }

  releaseParams() {
    const { patient, requestId } = this.props;
    const { textMessage, email } = this.state.releaseForm;

    return {
      patient_id: patient.id,
      email,
      message: textMessage,
      released_reports: [requestId],
    };
  }

  render() {
    const { releaseForm } = this.state;
    const { lastName, firstName, readOnlyEmail } = this.props.patient;
    const { releaseReportEnabled } = this.props;

    return (
      <Fragment>
        <h1 className={styles.title}>Release report to patient</h1>
        <form acceptCharset="UTF-8" onSubmit={this.handleSubmit}>
          <div className={styles.formItem}>
            <label className={styles.label} htmlFor="Patient_s_Email">Patient&apos;s Email Address</label>
            <button
              className={`fa fa-info-circle ${styles.infoCircle}`}
              data-content="Your patient will receive a notification at this email address with a secure link to their report on the myGuardant patient portal."
              data-placement="auto right"
              data-toggle="popover"
              data-trigger="focus"
              id="Email-pop"
              tabIndex="0"
              type="button"
              data-original-title=""
              title=""
            />
            <input type="email" name="email" id="email" value={releaseForm.email} placeholder="e.g. name@example.com" className={styles.emailInput} readOnly={readOnlyEmail} required="required" onChange={this.inputChangeHandler('email')} />
            <label className={styles.label} htmlFor="Message">Include a Message</label>
            <button
              className={`fa fa-info-circle ${styles.infoCircle}`}
              data-content="Please include a message for your patient to see when they access their report."
              data-placement="auto right"
              data-toggle="popover"
              data-trigger="focus"
              id="Message-pop"
              tabIndex="0"
              type="button"
              data-original-title=""
              title=""
            />
            <textarea name="message" id="message" placeholder="Message" className={styles.messageArea} cols="18" rows="9" value={releaseForm.textMessage} onChange={this.inputChangeHandler('textMessage')} />
            <p className={styles.releaseText}>{`Ready to release report from about 1 year ago to your patient, ${lastName} ${firstName}?`}</p>
            <div className={styles.buttonRow}>
              <button className={styles.releaseButton} type="submit" disabled={!releaseReportEnabled}>Release Report</button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

ReleaseForm.propTypes = IPropTypes;


export { ReleaseForm };
