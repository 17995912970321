import React from 'react';
import PropTypes from 'prop-types';

import { PatientSummary } from '../../../models/patient-summary';
import styles from './patient-summary-container.module.scss';
import { PatientSummarySection } from '../patient-summary-section';

const IPropTypes = {
  data: PropTypes.instanceOf(PatientSummary),
  isAdminLogged: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: null,
};

const PatientSummaryContainer = ({ data, isAdminLogged }) => {
  if (!data) {
    return null;
  }

  return (
    <div className={styles.container}>
      {data.type === 'Open'
        && <PatientSummarySection data={data} isAdminLogged={isAdminLogged} />}
    </div>
  );
};

PatientSummaryContainer.propTypes = IPropTypes;
PatientSummaryContainer.defaultProps = defaultProps;

export { PatientSummaryContainer };
