import React from 'react';
import {
  FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import PropTypes from 'prop-types';

const IProptypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.array,
};
const defaultProps = {
  name: '',
  label: '',
  value: '',
  onChange: () => {},
  items: [],
};
const RadioGroup = (props) => {
  const {
    name, label, value, onChange, items,
  } = props;
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup row name={name} value={value} onChange={onChange}>
        {items.map(item => (
          <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.title} />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};
export default RadioGroup;
RadioGroup.propTypes = IProptypes;
RadioGroup.defaultProps = defaultProps;
