import { deserializeDate, serializeDate } from './helpers';

class CurrentTherapySerializer {
  static deserialize = (currentTherapyInfo) => {
    if (!currentTherapyInfo) {
      return {};
    }

    return {
      targetedTherapy: currentTherapyInfo.targetedTherapy,
      immunotherapy: currentTherapyInfo.immunotherapy,
      chemotherapy: currentTherapyInfo.chemotherapy,
      combinationTherapy: currentTherapyInfo.combinationTherapy,
      specificTherapy: currentTherapyInfo.specificTherapy,
      dateOfTherapyInitiation: deserializeDate(currentTherapyInfo.dateOfTherapyInitiation),
    };
  }

  static serialize = cancerStageInfo => ({
    targetedTherapy: cancerStageInfo.targetedTherapy,
    immunotherapy: cancerStageInfo.immunotherapy,
    chemotherapy: cancerStageInfo.chemotherapy,
    combinationTherapy: cancerStageInfo.combinationTherapy,
    specificTherapy: cancerStageInfo.specificTherapy,
    dateOfTherapyInitiation: serializeDate(cancerStageInfo.dateOfTherapyInitiation),
  })
}

export { CurrentTherapySerializer };
