import { createAction } from 'redux-actions';

export const GET_SITES = '@usermanagment-workflow/GET_SITES';
export const getSites = createAction(GET_SITES);

export const GET_SITES_DONE = '@usermanagment-workflow/GET_SITES_DONE';
export const getSitesDone = createAction(GET_SITES_DONE);

export const GET_ALL_SITES = '@usermanagment-workflow/GET_ALL_SITES';
export const getAllSites = createAction(GET_ALL_SITES);

export const SITE_ASSIGNMENT_ERROR = '@usermanagment-workflow/SITE_ASSIGNMENT_ERROR';
export const siteAssignmentError = createAction(SITE_ASSIGNMENT_ERROR);

export const SAVE_SITE_ASSIGNMENT = '@usermanagment-workflow/SAVE_SITE_ASSIGNMENT';
export const saveSiteAssignment = createAction(SAVE_SITE_ASSIGNMENT);

export const SAVE_SITE_ASSIGNMENT_DONE = '@usermanagment-workflow/SAVE_SITE_ASSIGNMENT_DONE';
export const saveSiteAssignmentDone = createAction(SAVE_SITE_ASSIGNMENT_DONE);

export const SAVE_SITE_ASSIGNMENT_ERROR = '@usermanagment-workflow/SAVE_SITE_ASSIGNMENT_ERROR';
export const saveSiteAssignmentError = createAction(SAVE_SITE_ASSIGNMENT_ERROR);

export const UPDATE_SITE_ASSIGNMENT = '@usermanagment-workflow/UPDATE_SITE_ASSIGNMENT';
export const updateSiteAssignment = createAction(UPDATE_SITE_ASSIGNMENT);

export const UPDATE_SITE_ASSIGNMENT_DONE = '@usermanagment-workflow/UPDATE_SITE_ASSIGNMENT_DONE';
export const updateRoleAssignmentDone = createAction(UPDATE_SITE_ASSIGNMENT_DONE);

export const UPDATE_SITE_ASSIGNMENT_ERROR = '@usermanagment-workflow/UPDATE_SITE_ASSIGNMENT_ERROR';
export const updateSiteAssignmentError = createAction(UPDATE_SITE_ASSIGNMENT_ERROR);

export const DELETE_SITE_ASSIGNMENT = '@usermanagment-workflow/DELETE_SITE_ASSIGNMENT';
export const deleteSiteAssignment = createAction(DELETE_SITE_ASSIGNMENT);

export const DELETE_SITE_ASSIGNMENT_DONE = '@usermanagment-workflow/DELETE_SITE_ASSIGNMENT_DONE';
export const deleteSiteAssignmentDone = createAction(DELETE_SITE_ASSIGNMENT_DONE);

export const DELETE_SITE_ASSIGNMENT_ERROR = '@usermanagment-workflow/DELETE_SITE_ASSIGNMENT_ERROR';
export const deleteSiteAssignmentError = createAction(DELETE_SITE_ASSIGNMENT_ERROR);

export const SITE_ASSIGN_PAGINATION_CHANGE = '@usermanagment-workflow/SITE_ASSIGN_PAGINATION_CHANGE';
export const changePageSiteAssignment = createAction(SITE_ASSIGN_PAGINATION_CHANGE);

export const ON_CHANGE_SITE_ASSIGNMENT = '@usermanagment-workflow/ON_CHANGE_SITE_ASSIGNMENT';
export const onChangeSiteAssignment = createAction(ON_CHANGE_SITE_ASSIGNMENT);

export const defaultSiteAssignment = createAction('');
