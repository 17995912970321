import { connect } from 'react-redux';

import { PatientInformationContainer } from './patient-information-container';

const mapStateToProps = ({
  orders: {
    staticData: { patientStatuses, patientTypes },
    activeOrder: { patientInfo: { patientType } },
  },
}) => ({
  patientStatuses,
  patientTypes,
  currentPatientType: patientType,
});

const ConnectedPatientInformationContainer = connect(
  mapStateToProps,
)(PatientInformationContainer);

export { ConnectedPatientInformationContainer as PatientInformationContainer };
