/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  selectedUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  authenticity_token: PropTypes.string.isRequired,
};

class AdminManagePatientsDetailPage extends React.Component {
  static handleError(err) {
    window.$('.manage__portal-patients__content-inner').text(err.statusText);
  }

  static releaseReportToPatient(event) {
    if (!window.confirm('Are you sure you want to release reports to this patient?')) {
      event.preventDefault();
    }
  }

  static statusText(status) {
    if (status === 'NOT LINKED') {
      return 'Not linked to patient record';
    } if (status === 'NOT ACTIVATED') {
      return 'Auto-created but not activated';
    } if (status === 'ACTIVATED') {
      return 'Activated';
    } if (status === 'ACTIVATED - NOT VISITED') {
      return 'Activated but not visited';
    }
    return 'Bad database data';
  }

  static requests(reqs, color) {
    return reqs.map((req) => {
      const date = window.dateWrapper(req.BLOODCOLLDATE);
      return (
        <div
          key={`${req.REQUEST}-${date}-${req.REPORTSTATUS}`}
          style={{ color }}
        >
          {req.REQUEST}
          {' '}
          (
          {date}
          {' '}
          {req.REPORTSTATUS}
          )
        </div>
      );
    });
  }

  static nameOrEmail(firstname, lastname, email) {
    if (firstname !== undefined && firstname !== null && firstname !== '' && lastname !== undefined && lastname !== null && lastname !== '') {
      return `${lastname}, ${firstname}`;
    }
    return email;
  }

  constructor(props) {
    super(props);
    this.state = { user: null, status: 'Unknown' };

    this.sendPatientPasswordResetEmail = this.sendPatientPasswordResetEmail.bind(this);
    this.updateDetail = this.updateDetail.bind(this);
  }

  componentDidMount() {
    window.getManagePatientsPatientDetail(
      this.props.selectedUser.id,
      this.updateDetail,
      AdminManagePatientsDetailPage.handleError,
    );
  }

  updateDetail(user) {
    this.setState({ user, status: user.status });
  }

  sendPatientPasswordResetEmail() {
    if (window.confirm(`Are you sure you want to send a password reset link to '${this.state.user.email}'?`)) {
      window.sendPatientPasswordResetEmail(this.state.user.email);
    }
  }

  status() {
    if (this.state.user !== null && this.state.status !== 'ACTIVATED') {
      const statusText = AdminManagePatientsDetailPage.statusText(this.state.status);
      const notLinkedReasons = this.notLinkedReasons();
      return (
        <div className="alert-box danger">
          <div className="fa fa-times-circle" />
          <div className="alert-box__text">
            {statusText}
            {notLinkedReasons}
          </div>
        </div>
      );
    }
    return null;
  }

  notLinkedReasons() {
    if (this.state.status === 'NOT LINKED') {
      const reasonsHTML = this.state.user.unallowed_ptp_user_reasons.map(reason => (
        <li key={reason}>
          ◦
          {reason}
        </li>
      ));
      return (
        <ul>{reasonsHTML}</ul>
      );
    }
    return null;
  }

  details() {
    if (this.state.user !== null) {
      const info = this.state.user.user_info || {};
      const name = AdminManagePatientsDetailPage.nameOrEmail(
        info.firstname,
        info.lastname,
        this.state.user.email,
      );
      const releasedRequests = AdminManagePatientsDetailPage.requests(this.state.user.released_and_unreleased_requests[0], 'black');
      const unreleasedRequests = AdminManagePatientsDetailPage.requests(this.state.user.released_and_unreleased_requests[1], 'red');
      const actions = this.actions();

      return (
        <div>
          <div className="row">
            <div className="manage__portal-patients__detail-item col-sm-7">
              <div className="item-label">NAME</div>
              <div className="item-content">{name}</div>
            </div>
            <div className="manage__portal-patients__detail-item col-sm-5">
              <div className="item-label">DOB</div>
              <div className="item-content">{info.dob}</div>
            </div>
          </div>
          <div className="row">
            <div className="manage__portal-patients__detail-item col-sm-7">
              <div className="item-label">COLLECTION DATE</div>
              <div className="item-content">{window.dateWrapper(info.bloodcolldate)}</div>
            </div>
            <div className="manage__portal-patients__detail-item col-sm-5">
              <div className="item-label">PHYSICIAN&apos;S LAST NAME</div>
              <div className="item-content">{info.physician_last_name}</div>
            </div>
          </div>
          <div className="row">
            <div className="manage__portal-patients__detail-item col-sm-7">
              <div className="item-label">EMAIL</div>
              <div className="item-content">{this.state.user.email}</div>
            </div>
            <div className="manage__portal-patients__detail-item col-sm-5" />
          </div>
          <div className="row">
            <div className="manage__portal-patients__detail-item col-sm-7">
              <div className="item-label">STATUS</div>
              <div className="item-content manage-patients__requests-container">
                {releasedRequests}
                {unreleasedRequests}
              </div>
            </div>
            <div className="manage__portal-patients__detail-item col-sm-5">
              <div className="item-label">GH ADMIN ACTIONS</div>
              <div className="item-content">{actions}</div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="manage__portal-patients__detail-item col-sm-7">
            <div className="item-label">NAME</div>
            <div className="item-content spinner" />
          </div>
          <div className="manage__portal-patients__detail-item col-sm-5">
            <div className="item-label">DOB</div>
            <div className="item-content spinner" />
          </div>
        </div>
        <div className="row">
          <div className="manage__portal-patients__detail-item col-sm-7">
            <div className="item-label">COLLECTION DATE</div>
            <div className="item-content spinner" />
          </div>
          <div className="manage__portal-patients__detail-item col-sm-5">
            <div className="item-label">PHYSICIAN&apos;S LAST NAME</div>
            <div className="item-content spinner" />
          </div>
        </div>
        <div className="row">
          <div className="manage__portal-patients__detail-item col-sm-7">
            <div className="item-label">EMAIL</div>
            <div className="item-content spinner" />
          </div>
          <div className="manage__portal-patients__detail-item col-sm-5" />
        </div>
        <div className="row">
          <div className="manage__portal-patients__detail-item col-sm-7">
            <div className="item-label">STATUS</div>
            <div className="item-content manage-patients__requests-container spinner" />
          </div>
          <div className="manage__portal-patients__detail-item col-sm-5">
            <div className="item-label">GH ADMIN ACTIONS</div>
            <div className="item-content spinner" />
          </div>
        </div>
      </div>
    );
  }

  actions() {
    const info = this.state.user.user_info || {};
    const name = AdminManagePatientsDetailPage.nameOrEmail(
      info.firstname,
      info.lastname,
      this.state.user.email,
    );
    const actionReleaseToPatient = this.actionReleaseToPatient();

    return (
      <ul>
        <li>
          <form action="/admin_view_as" method="post" target="_blank">
            <input type="hidden" name="_method" value="PUT" />
            <input type="hidden" name="auth_token" value={JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken} />
            <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
            <input type="hidden" id="user_id" name="user_id" value={this.state.user.id} />
            <input type="hidden" id="user_name_and_email" name="user_name_and_email" value={name} />
            <button className="btn-as-a" type="submit">Go to patient</button>
          </form>
        </li>
        {actionReleaseToPatient}
        <li>
          <button
            className="btn-as-a"
            type="button"
            onClick={this.sendPatientPasswordResetEmail}
          >
            Send a password reset link
          </button>
        </li>
      </ul>
    );
  }

  actionReleaseToPatient() {
    if (this.state.status !== 'NOT LINKED' && this.state.user.patient.PATIENTRECORDID && this.state.user.patient_requests.length > 0) {
      const requestHiddenInputs = this.requestHiddenInputs();
      return (
        <li>
          <form action={`/patients/${this.state.user.patient.PATIENTRECORDID}/release_report`} method="post" onSubmit={AdminManagePatientsDetailPage.releaseReportToPatient.bind(this)} target="_blank">
            <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
            <input type="hidden" name="patient_id" id="patient_id" value={this.state.user.patient.PATIENTRECORDID} />
            {requestHiddenInputs}
            <input type="hidden" name="email" id="email" value={this.state.user.email} />
            <input type="hidden" name="message" id="message" value="Hello, here is a copy of your report to keep for your records." />
            <button className="btn-as-a" type="submit">Release report to patient</button>
          </form>
        </li>
      );
    }
    return null;
  }

  requestHiddenInputs() {
    return this.state.user.patient_requests.map((request) => {
      const requestKey = `released_reports[${request.REQUEST}]`;
      return (
        <input key={requestKey} type="hidden" name={requestKey} id={requestKey} value={request.REQUEST} />
      );
    });
  }

  render() {
    const status = this.status();
    const details = this.details();
    return (
      <div className="container manage__portal-patients__content-inner">
        {status}
        {details}
      </div>
    );
  }
}

AdminManagePatientsDetailPage.propTypes = IPropTypes;

export default AdminManagePatientsDetailPage;
