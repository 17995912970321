import React from 'react';
import PropTypes from 'prop-types';

import styles from './filter-list.module.scss';

const IPropTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FilterList = ({ value, onChange }) => (
  <>
    <div className={styles.filterContainer}>
      <div className={styles.searchIcon}>
        <i className="fa fa-search" />
      </div>
      <input
        className={styles.input}
        onChange={onChange}
        value={value}
      />
    </div>
  </>
);

FilterList.propTypes = IPropTypes;

export { FilterList };
