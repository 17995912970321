import React from 'react';
import Table from './Table';

class GeneTable extends React.Component {
  static genePanelCssClassname(gene) {
    let geneType = gene.types === '' ? null : `gene-panel__${gene.types}`;
    if (gene.promoter && geneType) {
      geneType += '_and_promoter';
    } else if (gene.promoter && !geneType) {
      geneType = 'gene-panel__promoter';
    }
    return ['gene-diagnostic-key', geneType].filter(x => !!x).join(' ');
  }

  render() {
    return (
      <div>
        <Table
          {...this.props}
          cssStyle={GeneTable.genePanelCssClassname}
        />
      </div>
    );
  }
}

export default GeneTable;
