import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';
import { AdditionalRecipientContainer } from '../additional-recipient-container';
import Dropdown from '../../Dropdown';
import { OrderingPhysicianSelection } from '../ordering-physician-selection';

const IPropTypes = {
  client: PropTypes.shape({
    client: PropTypes.string,
    clientAddress1: PropTypes.string,
    clientAddress2: PropTypes.string,
    clientAccount: PropTypes.string,
    clientFax: PropTypes.string,
    clientPhone: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  physicians: PropTypes.array,
  setDropdownClient: PropTypes.func.isRequired,
  highlightRequiredFields: PropTypes.bool,
  accounts: PropTypes.array,
};

const defaultProps = {
  client: {
    client: '',
    clientAddress1: '',
    clientAddress2: '',
    clientAccount: '',
    clientFax: '',
    clientPhone: '',
  },
  isLoading: false,
  accounts: [],
  physicians: [],
  highlightRequiredFields: false,
};

const PhysicianInformationExpanded = ({
  client: {
    client,
    clientAddress1,
    clientAddress2,
    clientAccount,
    clientFax,
    clientPhone,
  },
  isLoading,
  accounts,
  physicians,
  setDropdownClient,
  highlightRequiredFields,
}) => isLoading || (
  <div className="accordion-section flex-container__inner-container">
    <div className="flex-container__etrf-section">
      <div className="flex-container__etrf-row">
        <span className="required-fields-text">* Required fields</span>
      </div>
    </div>
    <div className="flex-container__etrf-section">
      <div className="flex-container__etrf-row">
        <div className="flex-container__etrf-column">
          <div className={`action-label bold ${highlightRequiredFields && !client ? 'highlighted' : ''}`}>
            <strong>Account</strong>
            <span className="highlighted-asterix">*</span>
          </div>
          <div className="content-space-breathable">
            <Dropdown
              title={client || 'Select Account'}
              list={accounts}
              resetThenSet={setDropdownClient}
              highlightedBorder={highlightRequiredFields && !client}
            />
          </div>
        </div>
        <div className="flex-container__etrf-column">
          <CollapsedTab className="no-padding" title="ACCOUNT ID" value={clientAccount} />
          <div className="column-top-breathable">
            <div className="action-label">
              <span className="collapsed-section__value">
                {clientAddress1}
              </span>
            </div>
            <div className="action-label physician-information-breathable-row">
              <span className="collapsed-section__value">
                {clientAddress2}
              </span>
            </div>
          </div>
        </div>
        <div className="flex-container__etrf-column">
          <CollapsedTab className="no-padding" title="PHONE" value={clientPhone} />
          <div className="action-label column-top-breathable">
            <CollapsedTab className="no-padding" title="FAX" value={clientFax} />
          </div>
        </div>
      </div>
    </div>
    <OrderingPhysicianSelection
      physicians={physicians}
    />
    <div>
      <AdditionalRecipientContainer />
    </div>
  </div>
);

PhysicianInformationExpanded.propTypes = IPropTypes;
PhysicianInformationExpanded.defaultProps = defaultProps;

export { PhysicianInformationExpanded };
