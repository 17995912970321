import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';
import { TestSelectionInformationExpanded } from '../test-selection-information-expanded';
import { TestSelectionInformationCollapsed } from '../test-selection-information-collapsed';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
};

const TestSelectionInformationContainer = ({ enabled, onToggle }) => (
  <>
    <GenericHeader
      enabled={enabled}
      handleContainerArrowClick={onToggle}
      label="Test Selection"
    />

    {
      enabled
        ? <TestSelectionInformationExpanded onToggle={onToggle} />
        : <TestSelectionInformationCollapsed onToggle={onToggle} />
    }
  </>
);

TestSelectionInformationContainer.propTypes = IPropTypes;
TestSelectionInformationContainer.defaultProps = defaultProps;

export { TestSelectionInformationContainer };
