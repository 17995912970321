import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { config } from '../config/config';
import { getAuthClient } from './AuthClient';
import { API_GATEWAY_URL, USER_MGMT_V2_PUB_PATH } from '../config';
import { isValidUser } from '../../util/validator';
import gonative from '../../util/gonative';
import { BiometryButton } from './Biometry';

const BannerMessage = (props) => {
  const {
    className,
    toggleBanner,
    messages,
    status,
  } = props;
  return (
    <div className={`flash-alert-container ${className}`}>
      <div className="close-flash-alert fa fa-close" onClick={toggleBanner} />
      <div className={['alert', `alert--${status}`].join(' ')}>
        <ul className="message">
          {messages.map(message => (
            <li key={message}>
              {message}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
BannerMessage.propTypes = {
  className: PropTypes.string,
  toggleBanner: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};
BannerMessage.defaultProps = {
  className: '',
};

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  autoUsername: PropTypes.string,
  autoPassword: PropTypes.string,
  returnTo: PropTypes.string,
};
const defaultProps = {
  autoUsername: null,
  autoPassword: null,
  returnTo: null,
};
const Login = (props) => {
  const {
    authenticityToken,
    autoUsername,
    autoPassword,
    returnTo,
  } = props;
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [startSignIn, setStartSignIn] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showBiometryButton, setShowBiometryButton] = useState(false);

  const emailChange = (value) => {
    const name = value;
    if (name.length > 0) {
      Cookies.set('isAdmin', isValidUser(name));
      Cookies.set('isInternal', isValidUser(name));
    }
    setEmail(name);
  };

  const biometryLogin = () => {
    gonative.auth.status().then((data) => {
      if (data.hasSecret) {
        gonative.auth.get().then((data) => {
          const { success, secret } = data;

          if (!success) return;

          const userSecret = JSON.parse(decodeURIComponent(secret));
          const { username, password } = userSecret;
          emailChange(username);
          setPassword(password);
          loginForm(username, password);
        });
      }
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && gonative.available()) {
      const userLoggedOut = sessionStorage.getItem('userLoggedOut');
      if (userLoggedOut) {
        setShowBiometryButton(true);
        return;
      }

      biometryLogin();
    }
  }, []);

  const loginForm = async (uName, uPassword) => {
    const authClient = getAuthClient();
    const url = returnTo ? window.location.origin + returnTo : window.location.origin;
    if (returnTo) { // to adhere changes from v2
      if (returnTo === '/dashboard?cache_reset=true') {
        sessionStorage.setItem('path', '/patients');
      } else {
        sessionStorage.setItem('path', returnTo);
      }
    }
    sessionStorage.setItem('url', url);
    const formData = {
      'session[email]': uName,
      'session[password]': uPassword,
      authenticity_token: authenticityToken,
      portal: 'physician',
      has_touch_id: '',
      has_secret: '',
    };
    sessionStorage.setItem('login-form-data', JSON.stringify(formData));
    window.showSpinner();
    authClient
      .signInWithCredentials({ username: uName, password: uPassword })
      .then(async (transaction) => {
        if (transaction.status === 'SUCCESS') {
          if (gonative.available()) {
            const result = await gonative.auth.status();
            if (
              result
              && result.hasTouchId
              && (sessionStorage.getItem('error') || !result.hasSecret)
            ) {
              const secret = encodeURIComponent(
                JSON.stringify({
                  username,
                  password,
                }),
              );
              await gonative.auth.save({ secret });
              sessionStorage.removeItem('error');
            }
          }

          authClient.token.getWithRedirect({
            sessionToken: transaction.sessionToken,

            responseType: 'token',
          });
        }
      })
      .catch(async () => {
        sessionStorage.setItem('error', '1');
        setStartSignIn(false);
        window.hideSpinner();
        window.location.href = `${window.location.origin}/sign-in`;
      });
  };
  const isAutoLogin = (autoUsername !== null && autoPassword !== null);

  const form = useRef(null);
  useEffect(() => {
    if (isAutoLogin) {
      Cookies.set('isAdmin', isValidUser(autoUsername));
      Cookies.set('isInternal', isValidUser(autoUsername));
      loginForm(autoUsername, autoPassword);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isInternal = Cookies.get('isInternal');
    if (isInternal === 'undefined') {
      setShowMessage(true);
    } else {
      loginForm(username, password);
    }
  };
  const toggleReleaseMessage = () => {
    setShowMessage(false);
  };
  const hiddenStyle = {
    overflow: 'inherit',
  };
  return (
    <React.Fragment>
      {
       !isAutoLogin ? (
         <div className="container container--breathable sign-in-page-inner" style={hiddenStyle}>
           {showMessage && (
           <BannerMessage
             status="error"
             toggleBanner={toggleReleaseMessage}
             messages={['User not found.']}
           />
           )
          }
           <div className="php_landing_container php_landing_container__bottom-padding">
             <a href="/">
               <div className="logo__gh-logo" />
             </a>
             <div className="php_landing__trm-img" />
             <div className="php_landing__grey-box_container">
               <form ref={form} className="fancy-form" onSubmit={e => handleSubmit(e)}>
                 <input type="hidden" name="portal" id="portal" value="physician" />
                 <input type="hidden" name="has_touch_id" id="has_touch_id" />
                 <input type="hidden" name="has_secret" id="has_secret" />
                 <input type="hidden" name="authenticity_token" id="authenticity_token" value={authenticityToken} />
                 <div className="input-group">
                   <div className="label">
                     <label className="label" htmlFor="session_email">Email</label>
                   </div>
                   <input
                     type="email"
                     name="session[email]"
                     value={username}
                     placeholder="Email Address"
                     required
                     onChange={e => emailChange(e.target.value)}
                   />
                 </div>
                 <div className="input-group">
                   <div className="label">
                     <label className="label" htmlFor="session_password">Password</label>
                   </div>
                   <input
                     type="password"
                     name="session[password]"
                     value={password}
                     placeholder="Password"
                     required
                     onChange={e => setPassword(e.target.value)}
                   />
                 </div>
                 <div className="input-group">
                   <div className="flex-row">
                     <button
                       type="submit"
                       disabled={startSignIn}
                       className="btn__size-normal btn__color-blue show-spinner sign-in-button simple-sign-in"
                     >
                       SIGN IN
                     </button>
                     <a className="forgot-password-link font-weight__bold" href="/password_resets/new">
                       Forgot your password?
                     </a>
                   </div>
                 </div>
               </form>
             </div>
             {showBiometryButton ? <BiometryButton onClick={biometryLogin} /> : null}
             <div className="register-cta">
               <span>New to Guardant?&nbsp;&nbsp;&nbsp;</span>
               <a className="font-weight__bold" href="/welcome">Register new account</a>
             </div>
           </div>
         </div>
       ) : <div className="dummyLoginPage" />
      }
    </React.Fragment>
  );
};

export default Login;
Login.propTypes = IPropTypes;
Login.defaultProps = defaultProps;
