import { connect } from 'react-redux';

import { LunarPatientHeader } from './LunarPatientHeader';
import { toggleShareMessageAction } from '../../../store/actions/patient-page/lunar/share_modal';
import { toggleReleaseMessageAction } from '../../../store/actions/patient-page/lunar/release_modal';

const mapStateToProps = store => ({
  showShareMessage: store.patientPage.lunar.share.showShareMessage,
  showReleaseMessage: store.patientPage.lunar.release.showReleaseMessage,
});

const mapDispatchToProps = dispatch => ({
  toggleShareMessage: () => dispatch(toggleShareMessageAction()),
  toggleReleaseMessage: () => dispatch(toggleReleaseMessageAction()),
});

const LunarPatientHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LunarPatientHeader);

export { LunarPatientHeaderContainer as LunarPatientHeader };
