class AccountInfo {
  constructor(params) {
    this.accountNumber = params.clientAccount;
    this.name = params.client;
    this.phone = params.clientPhone;
    this.fax = params.clientFax;
    this.country = params.clientCountry;
    this.state = params.clientState;
    this.city = params.clientCity;
    this.address1 = params.clientAddress1;
    this.zipCode = params.clientZipCode;
    this.address2 = params.clientAddress2;
  }

  isComplete = () => !!this.name;

  checkRequiredFields = () => !!this.name;
}

export { AccountInfo };
