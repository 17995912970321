import { DownloadReportsLinks } from './download-reports-links';
import { ReleaseToPatient } from './release-to-patient';
import { SharePatient } from './share-patient';

class ActionBar {
  constructor(params) {
    this.canOrderProgressionTest = params.canOrderProgressionTest;
    this.downloadReportsLinks = params.downloadReportsLinks
      && new DownloadReportsLinks(params.downloadReportsLinks);
    this.releaseToPatient = params.releaseToPatient
      && new ReleaseToPatient(params.releaseToPatient);
    this.sharePatient = params.sharePatient
      && new SharePatient(params.sharePatient);
  }
}

export { ActionBar };
