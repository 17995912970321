import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addGenericInfo } from '../../../store/actions/orders/active-order/generic-info';
import { receiveOrder } from '../../../store/actions/orders/active-order/order';
import { showErrorBanner, saveErrorBannerMsg } from '../../../store/actions/orders/active-order/form_errors';
import { toggleElectronicSignatureModal } from '../../../store/actions/orders/electronic-signature-confirmation';
import { toggleModalAction } from '../../../store/actions/orders/active-order/print-modal';
import { updateDashboard } from '../../../store/actions/orders/dashboard';
import { updateLoadingCount } from '../../../store/actions/global';

import { PrintModalContainer } from './print-modal-container';

const mapStateToProps = ({
  orders: { activeOrder },
  global: { initialProps: { viewAsUser, userPermissions } },
}) => ({
  store: activeOrder,
  order: activeOrder.printModal.order,
  emrUser: userPermissions.onlyNewOrders,
  trfCompleted: activeOrder.genericInfo.trfCompleted,
  orderingUserEmail: viewAsUser.email,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleElectronicSignatureModal,
  togglePrintModal: toggleModalAction,
  saveGenericInfo: addGenericInfo,
  saveOrder: receiveOrder,
  onError: showErrorBanner,
  saveErrorMsg: saveErrorBannerMsg,
  updateDashboard,
  updateLoadingCount,
}, dispatch);

const ConnectedPrintModalContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(PrintModalContainer);

export { ConnectedPrintModalContainer as PrintModalContainer };
