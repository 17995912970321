import axios from 'axios';
import { loadSharesData, loadSharesDone, loadSharesError } from './index';
import { addBannerMessages } from '../../../store/actions/global';
import { isInternalUser } from '../../../networking/controllers/helper';

const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;

export const loadSharesDataList = () => (dispatch) => {
  window.showSpinner();
  const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
    ...extHeader,
  };
  return axios.get('rest/shares/index', config)
    .then((response) => {
      dispatch(loadSharesData(response));
      dispatch(loadSharesDone());
      window.hideSpinner();
    })
    .catch((error) => {
      dispatch(loadSharesError(error));
      window.hideSpinner();
      dispatch(addBannerMessages(error.message, 'error'));
    });
};
