/* eslint-disable no-useless-escape */
const isText = RegExp(/^[A-Z ]+$/i);
const isName = RegExp(/^[A-Z '-]+$/i);
const isAlphaNumaric = /^[A-Za-z0-9\- _]{1,256}$/;
const isEmail = RegExp(/^[A-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,15}$/i);
const isPhone = RegExp(/^[0-9\-\+]{7,15}$/);
const isNumber = RegExp(/^\d+$/);
const isBoolean = RegExp(/^[0-1]$/);
const isDate = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/;
const isCity = /^$|^([a-zA-Z-ɏ]+(?:. |-| |'))*[a-zA-Z-ɏ]*$/;
const isWebsite = RegExp(
  /^(http:\/\/|https:\/\/)?(www.)*?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
);

export default function MatchGenValidation(name, value, schema) {
  const {
    validate, minLength, maxLength, required,
  } = schema[name];
  let error = '';
  if (minLength && value.length < minLength) return `Minimum ${minLength} characters is required`;
  if (maxLength && value.length > maxLength) return `Maximum length of ${maxLength} exceeded`;
  if (!validate) return false;
  if (!minLength && !required && (!value || value.length === 0)) return false;
  switch (validate) {
    case 'name':
      if (!isName.test(value)) error = 'name validation error';
      break;
    case 'text':
      if (!isText.test(value)) error = 'text validation error';
      break;
    case 'alphanumaric':
      if (!isAlphaNumaric.test(value)) error = 'alphanumaric validation error';
      break;

    case 'number':
      if (!isNumber.test(value)) error = 'number validation error';
      break;

    case 'boolean':
      if (!isBoolean.test(value)) error = 'This field excepts 0 and 1 value only';
      break;

    case 'email':
      if (!isEmail.test(value)) error = 'email validation error';
      break;

    case 'phone':
      if (!isPhone.test(value)) error = 'phone validation error';
      break;
    case 'date':
      if (!isDate.test(value)) error = 'Please enter valid format ie YYYY-MM-DD hh:mm:ss';
      break;

    case 'website':
      if (!isWebsite.test(value)) error = 'website validation error';
      break;

    case 'city':
      if (!isCity.test(value)) error = 'city validation error';
      break;
    case 'dropDown':
      if (!value || value.length === 0) error = 'dropdown validation error';
      break;

    case 'checkbox':
      if (!value) error = 'checkbox validation error';
      break;

    default:
      break;
  }

  return error;
}
