import React from 'react';
import PropTypes from 'prop-types';

import { ViewAssociatedButton } from '../view-associated-button/index';

import styles from './blood-view-associated-button.module.scss';

const IPropTypes = {
  associatedTest: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
};

const defaultProps = {
  associatedTest: null,
};

const BloodViewAssociatedButton = ({ associatedTest }) => (
  !!associatedTest && (
    <div className={
      [
        styles.container,
        associatedTest.name === 'blood' ? styles.left : styles.right,
      ].join(' ')}
    >
      <ViewAssociatedButton
        to={associatedTest.path}
        text={`View ${associatedTest.name} Testing Results`}
        alignDirection={associatedTest.name === 'blood' ? 'left' : 'right'}
      />
    </div>
  )
);

BloodViewAssociatedButton.propTypes = IPropTypes;
BloodViewAssociatedButton.defaultProps = defaultProps;

export default BloodViewAssociatedButton;
