export const getCompletedOrdersList = state => state.ordersDashboard.get('completedOrders');
export const getPendingOrdersList = state => state.ordersDashboard.get('pendingOrders');
export const getPendingESignatureOrdersList = state => state.ordersDashboard.get('pendingESignatureOrders');
export const getCompletedRecords = state => state.ordersDashboard.get('totalCompletedOrders');
export const getPendingRecords = state => state.ordersDashboard.get('totalPendingOrders');
export const getESignPendingRecords = state => state.ordersDashboard.get('totalESignPendingOrders');
export const isPendingOrdersLoaded = state => state.ordersDashboard.get('isPendingOrdersLoaded');
export const isCompletedOrdersLoaded = state => state.ordersDashboard.get('isCompletedOrdersLoaded');
export const isPendingESignOrdersLoaded = state => state.ordersDashboard.get('isPendingSignOrdersLoaded');
export const hasErrors = state => state.ordersDashboard.get('errors');
export const getErrorMessage = state => state.ordersDashboard.get('errorMessage');
