import React from 'react';

class AuditLogModal extends React.Component {
  static tableHeader() {
    return (
      <div className="content-header hidden-xs">
        <div className="row">
          <div className="col-sm-3">
            <div className="content-header-label">Username</div>
          </div>
          <div className="col-sm-3">
            <div className="content-header-label">Action</div>
          </div>
          <div className="col-sm-3">
            <div className="content-header-label">Action Value</div>
          </div>
          <div className="col-sm-3">
            <div className="content-header-label">Updated At</div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = { rowId: null, auditLogInfo: null };
  }

  componentDidMount() {
    window.$(document).on('click touch', '.audit-log__link', (e) => {
      const rowId = e.target.id;
      this.sendRowIdUpdate(rowId);
    });
  }

  getContent() {
    if (this.state.auditLogInfo === null) {
      return (
        <div className="detail-content">
          <div className="modal-loading-spinner" />
        </div>
      );
    }
    return this.formatAuditLog();
  }

  updateAuditLogState(data) {
    this.setState({ auditLogInfo: data });
  }

  sendRowIdUpdate(rowId) {
    if (this.state.rowId !== rowId) {
      this.setState({ auditLogInfo: null, rowId });
    }
    window.getMatchAuditDetails(rowId, this.updateAuditLogState.bind(this));
  }

  formatAuditLog() {
    const logs = [];

    if (this.state.auditLogInfo.length === 0) {
      return (
        <div className="audit-none-found">
          No activity found.
        </div>
      );
    }

    this.state.auditLogInfo.forEach((log) => {
      logs.push(
        <div className="content-row" key={`${log.username}-${log.action}-${log.actionValue}-${log.updatedAt}`}>
          <div className="row">
            <div className="col-sm-3">
              <div className="col-content">
                {log.username}
              </div>
            </div>
            <div className="col-sm-3">
              <div className="col-content">
                {log.action}
              </div>
            </div>
            <div className="col-sm-3">
              <div className="col-content">
                {log.actionValue}
              </div>
            </div>
            <div className="col-sm-3">
              <div className="col-content">
                {log.updatedAt}
              </div>
            </div>
          </div>
        </div>,
      );
    });
    return logs;
  }

  render() {
    const content = this.getContent();

    const tableHeader = AuditLogModal.tableHeader();
    return (
      <div className="modal__container-outer audit-log__modal">
        <div className="modal__container-inner">
          <div className="modal__content">
            <div className="audit-log__details">
              <i className="fa fa-close modal-close" />
              <div className="audit-log__container">
                <div className="header">
                  Activity Log -
                  {this.state.rowId}
                </div>
                {tableHeader}
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuditLogModal;
