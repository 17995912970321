import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';
import Dropdown from '../../Dropdown';
import Typeahead from '../../../generic/Typeahead';
import { OrdersController } from '../../../../networking/controllers/orders';

const IPropTypes = {
  physician: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    fullname: PropTypes.string,
    npi: PropTypes.string,
    phone: PropTypes.string,
  }),
  clientAccount: PropTypes.string,
  physicians: PropTypes.array,
  withAutoSuggest: PropTypes.bool.isRequired,
  savePhysicianInfo: PropTypes.func.isRequired,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  physician: {
    id: '',
    email: '',
    firstname: '',
    lastname: '',
    fullname: '',
    npi: '',
    phone: '',
  },
  clientAccount: '',
  physicians: [],
  highlightRequiredFields: false,
};

const formatOrderingPhysicianName = ({ firstname, lastname, fullname }) => {
  if (fullname) {
    return fullname;
  }

  return [lastname, firstname].filter(Boolean).join(', ');
};

const OrderingPhysicianSelection = ({
  physician: {
    id: physicianId,
    email,
    firstname,
    lastname,
    fullname,
    npi,
    phone,
  },
  clientAccount,
  physicians,
  withAutoSuggest,
  savePhysicianInfo,
  highlightRequiredFields,
}) => {
  const onPhysicianInputChange = useCallback((event) => {
    const { value } = event.target;

    savePhysicianInfo({ physician: { fullname: value } }, true);
  }, []);

  const onPhysicianTypeaheadSelection = useCallback((option) => {
    savePhysicianInfo({ physician: option.value }, true);
  }, []);

  const onPhysicianDropdownSelection = useCallback((id) => {
    const selectedPhysician = physicians.find(item => item.id === id);

    const hideBanner = physicianId !== id;

    savePhysicianInfo({
      physician: {
        id: selectedPhysician.id,
        lastname: selectedPhysician.lastname,
        firstname: selectedPhysician.firstname,
        email: selectedPhysician.email,
        phone: selectedPhysician.phone,
        npi: selectedPhysician.npi,
        orderingPhysician: selectedPhysician.title,
      },
    }, hideBanner);
  }, [physicians, physicianId]);

  const physiciansSearch = useCallback(
    keyword => OrdersController.physicianSearch(clientAccount, keyword),
    [clientAccount],
  );

  return (
    <div className="flex-container__etrf-section">
      <div className="flex-container__etrf-row">
        <div className="flex-container__etrf-column">
          <div className={`action-label bold ${highlightRequiredFields && !lastname ? 'highlighted' : ''}`}>
            <strong>ORDERING PHYSICIAN</strong>
            <span className="highlighted-asterix">*</span>
          </div>
          <div className="content-space-breathable">
            {
              withAutoSuggest ? (
                <Typeahead
                  placeholder="Select Physician"
                  onOptionsFetch={physiciansSearch}
                  autoComplete="off"
                  name="physician-auto-suggest"
                  onInputChange={onPhysicianInputChange}
                  onOptionSelect={onPhysicianTypeaheadSelection}
                  inputValue={formatOrderingPhysicianName({ firstname, lastname, fullname })}
                  highlightedBorder={highlightRequiredFields && !fullname}
                />
              ) : (
                <Dropdown
                  className="physician-information-select"
                  title={formatOrderingPhysicianName({ firstname, lastname }) || 'Select Physician'}
                  list={physicians}
                  resetThenSet={onPhysicianDropdownSelection}
                  highlightedBorder={highlightRequiredFields && !lastname}
                />
              )
            }
          </div>
        </div>
        <div className="flex-container__etrf-column">
          <div className="column-top-breathable-2">
            <CollapsedTab className="no-padding" title="NPI" value={npi} />
            <CollapsedTab clasName="no-padding" sectionClassname="lowercase" title="Email" value={email} />
          </div>
        </div>
        <div className="flex-container__etrf-column">
          <div className="column-top-breathable-2">
            <CollapsedTab className="no-padding" title="PHONE" value={phone} />
          </div>
        </div>
      </div>
    </div>
  );
};

OrderingPhysicianSelection.propTypes = IPropTypes;
OrderingPhysicianSelection.defaultProps = defaultProps;

export { OrderingPhysicianSelection };
