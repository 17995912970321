import React from 'react';

class GeneDetailsModal extends React.Component {
  static getAttrData(e) {
    const alteration = window.$(e.target).closest('.alteration_gene_and_mutation');
    return ({
      request_id: alteration.data('request'),
      biomarkerId: alteration.data('biomarker-id'),
      gene: alteration.data('gene'),
      mutation: alteration.data('mutation'),
      description: alteration.data('description'),
    });
  }

  static setPanel(tab) {
    if (tab === 1) {
      window.$('.clinical-relevance-header').addClass('selected');
      window.$('.clinical-relevance-description').removeClass('hide-details');
      window.$('.biological-significance-header').removeClass('selected');
      window.$('.biological-relevance-description').addClass('hide-details');
    } else if (tab === 2) {
      window.$('.clinical-relevance-header').removeClass('selected');
      window.$('.clinical-relevance-description').addClass('hide-details');
      window.$('.biological-significance-header').addClass('selected');
      window.$('.biological-relevance-description').removeClass('hide-details');
    }
  }

  static handlePanelClick(e) {
    if (window.$(e.target).text() === 'Clinical Relevance') {
      GeneDetailsModal.setPanel(1);
    } else {
      GeneDetailsModal.setPanel(2);
    }
  }

  static renderBiologicalSignificance(marker) {
    if (!marker) {
      return 'Biomarker not found';
    }
    const details = [];
    if (marker.content) {
      details.push(
        <div key="content">
          <div className="gene-panel-header">Content</div>
          <div className="text__paragraph">{marker.content}</div>
        </div>,
      );
    }
    if (marker.molecularFunction) {
      details.push(
        <div key="molecular-function">
          <div className="gene-panel-header">Molecular Function</div>
          <div className="text__paragraph">{marker.molecularFunction}</div>
        </div>,
      );
    }
    if (marker.incidence) {
      details.push(
        <div key="incidence">
          <div className="gene-panel-header">Incidence</div>
          <div className="text__paragraph">{marker.incidence}</div>
        </div>,
      );
    }
    if (details.length === 0) {
      details.push(
        <div key="empty">
          <div className="text__paragraph">No biological significance found.</div>
        </div>,
      );
    }
    return details;
  }

  static renderClinicalRelevance(marker) {
    if (!marker) {
      return 'Biomarker not found';
    }
    const details = [];
    if (marker.roleInDisease) {
      details.push(
        <div key="role-in-disease">
          <div className="gene-panel-header">Role in Disease</div>
          <div className="text__paragraph">{marker.roleInDisease}</div>
        </div>,
      );
    }
    if (marker.drugSensitivity) {
      details.push(
        <div key="drug-sensitivity">
          <div className="gene-panel-header">Effect on Drug Sensitivity</div>
          <div className="text__paragraph">{marker.drugSensitivity}</div>
        </div>,
      );
    }
    if (marker.drugResistance) {
      details.push(
        <div key="drug-resistance">
          <div className="gene-panel-header">Effect on Drug Resistance</div>
          <div className="text__paragraph">{marker.drugResistance}</div>
        </div>,
      );
    }
    if (details.length === 0) {
      details.push(
        <div key="empty">
          <div className="text__paragraph">No clincal relevance found.</div>
        </div>,
      );
    }
    return details;
  }

  constructor(props) {
    super(props);
    this.state = {
      request_id: '',
      biomarkerId: '',
      gene: '',
      mutation: '',
      description: '',
      details: { biological: [], clinical: [] },
      fetching: false,
    };

    this.updateGeneDetails = this.updateGeneDetails.bind(this);
  }

  componentDidMount() {
    window.$(document).on('click touch', '.alteration_gene_and_mutation', (e) => {
      GeneDetailsModal.setPanel(1);
      const attrData = GeneDetailsModal.getAttrData(e);
      if (!attrData.request_id) {
        return; // user clicked on unclickable gene/mutation
      }
      if (attrData.request_id !== this.state.request_id) {
        this.setState({ fetching: true });
        window.getGeneDetails(attrData.request_id, this.updateGeneDetails);
      }
      this.setState(attrData);
    });
  }

  updateGeneDetails(data) {
    this.setState({ details: data.geneDetails, fetching: false });
  }

  renderContent() {
    let bsMarker;
    let crMarker;
    if (this.state.fetching) {
      return (
        <div className="detail-content">
          <div className="modal-loading-spinner" />
        </div>
      );
    }
    this.state.details.biological.forEach((biomarker) => {
      if (String(biomarker.id) === String(this.state.biomarkerId)) {
        bsMarker = biomarker;
      }
    });
    this.state.details.clinical.forEach((biomarker) => {
      if (String(biomarker.id) === String(this.state.biomarkerId)) {
        crMarker = biomarker;
      }
    });
    return (
      <div className="detail-content">
        <div className="clinical-relevance-description">
          <div className="biological_significance">{ GeneDetailsModal.renderClinicalRelevance(crMarker) }</div>
        </div>
        <div className="biological-relevance-description hide-details">
          <div className="biological_significance">{ GeneDetailsModal.renderBiologicalSignificance(bsMarker) }</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="modal__container-outer gene-details__modal">
        <div className="modal__container-inner align-top">
          <div className="modal__content">
            <div className="gene-details">
              <i className="fa fa-close modal-close" />
              <div className="gene-details__modal__container">

                <div className="generated-content">
                  <div className="header">
                    <span className="gene-header">
                      { this.state.gene }
                      {' '}
                    </span>
                    <span>{ this.state.mutation }</span>
                    <span>
                      {' '}
                      { this.state.description }
                    </span>
                  </div>
                  <div className="modal-panel">
                    <div
                      className="col-xs-6 gene-detail-panel clinical-relevance-header selected"
                      onClick={GeneDetailsModal.handlePanelClick}
                    >
                      Clinical Relevance
                    </div>
                    <div
                      className="col-xs-6 gene-detail-panel biological-significance-header"
                      onClick={GeneDetailsModal.handlePanelClick}
                    >
                      Biological Significance
                    </div>
                  </div>
                  {this.renderContent()}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneDetailsModal;
