import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { constants } from '../../../../config/constants';
import { TissueSpecimenRetrieval } from '../tissue-specimen-retrieval';

import styles from './test-selection-information-expanded.module.scss';

const {
  liquidProfiling: bloodType,
  liquidToTissueProfiling: tissueType,
  treatmentResponse: responseType,
} = constants.etrfOrdering.testSelectionTypes;

const IPropTypes = {
  testSelectionInfo: PropTypes.shape({
    testSelectionType: PropTypes.shape({
      text: PropTypes.string,
    }),
    treatmentResponse: PropTypes.shape({
      text: PropTypes.string,
    }),
    tissueSpecimenRetrieval: PropTypes.shape({
      pathologyLabName: PropTypes.string,
      pathologyLabPhone: PropTypes.string,
      pathologyLabFax: PropTypes.string,
      tissueCollectionDate: PropTypes.string,
    }),
    isTissueOption: PropTypes.bool,
  }).isRequired,
  saveTestSelectionInfo: PropTypes.func.isRequired,
  highlightRequiredFields: PropTypes.bool,
  testSelectionOptions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
      }),
    ),
  ),
};

const defaultProps = {
  highlightRequiredFields: false,
  testSelectionOptions: null,
};

const InformationTooltip = () => (
  <div className={styles.tooltip}>
    <strong>
      Guardant360 CDx: FDA approved, 74-gene panel including MSI status
    </strong>
    <p>
      Includes both the FDA-approved report with 55 genes and the professional
      services report with 74 genes and MSI status.
    </p>
    <strong>
      Guardant360 TissueNext: Comprehensive panel including MSI status and TMB; PD-L1 if requested
    </strong>
    <p>
      Run as a reflex test if Guardant360CDx finds no actionable alterations.
      To review the reflex criteria, visit
      {' '}
      <a href="https://guardant360cdx.com/reflex" target="_blank" rel="noopener noreferrer">Guardant360CDx.com/reflex</a>
      .
    </p>
    <strong>
      Guardant360: Expanded panel including TMB and HRR genes
    </strong>
    <p>
      Report includes TMB, NTRK2, NTRK3, CHEK2, FANCA, KEAP1, MSH2, MSH6, PALB2, PMS2, RAD51D.
    </p>
    <div className={styles.square} />
  </div>
);

const TestSelectionInformationExpanded = ({
  testSelectionInfo: {
    testSelectionType,
    treatmentResponse,
    tissueSpecimenRetrieval,
    isTissueOption,
  },
  saveTestSelectionInfo,
  highlightRequiredFields,
  testSelectionOptions,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  if (!testSelectionOptions) {
    return null;
  }

  const storeSelectedTest = (e) => {
    const { value } = e.target;
    const newValue = value === testSelectionType.text ? null : value;
    saveTestSelectionInfo({ testSelectionType: newValue }, true);
  };

  const storeTreatmentResponse = (e) => {
    saveTestSelectionInfo({ treatmentResponse: e.target.checked }, true);
  };

  const highlightBoxes = highlightRequiredFields
    && !testSelectionType.text
    && !treatmentResponse.text;

  return (
    <div className={styles.accordionSection}>
      <div className={styles.infoRow}>
        <span className={styles.requiredFieldsText}>
          * Required fields (at least one test must be selected)
        </span>
        <span className={styles.infoSpan}>
          Test Selection Details
          <button type="button" className={styles.infoButton} onClick={() => setShowTooltip(prevState => !prevState)}>
            <i className={['fa fa-info-circle', styles.infoIcon].join(' ')} />
          </button>
          {showTooltip && <InformationTooltip />}
        </span>
      </div>
      <div className={styles.testSelectionRow}>
        <fieldset className={[styles.firstBox, highlightBoxes ? styles.highlighted : ''].join(' ')}>
          <legend>Select One</legend>
          <div className={styles.firstBoxTests}>
            <span className={styles.aster}>*</span>
            <div className={styles.firstColumn}>
              <h5>LIQUID TO TISSUE PROFILING</h5>
              {
                [
                  ['guardant360-cdx-tissue-pdl1-radio', testSelectionOptions[tissueType][0].text],
                  ['guardant360-cdx-tissue-radio', testSelectionOptions[tissueType][1].text],
                ].map(([id, optionText]) => {
                  const [firstLine, ...rest] = optionText.split('+');
                  const option = rest.length ? [`${firstLine} +`, <br key={id} />, rest.join('+')] : optionText;

                  return (
                    <div className={styles.option} key={id}>
                      <input
                        id={id}
                        type="radio"
                        value={optionText}
                        checked={testSelectionType.text === optionText}
                        onClick={storeSelectedTest}
                        onChange={() => {}}
                      />
                      <label htmlFor={id}>{option}</label>
                    </div>
                  );
                })
              }
            </div>
            <div>
              <h5>LIQUID PROFILING</h5>
              {
                [
                  ['guardant360-cdx-radio', testSelectionOptions[bloodType][0].text],
                  ['guardant360-radio', testSelectionOptions[bloodType][1].text],
                ].map(([id, optionText]) => (
                  <div className={styles.option} key={id}>
                    <input
                      id={id}
                      type="radio"
                      value={optionText}
                      checked={testSelectionType.text === optionText}
                      onClick={storeSelectedTest}
                      onChange={() => {}}
                    />
                    <label htmlFor={id}>{optionText}</label>
                  </div>
                ))
              }
            </div>
          </div>
        </fieldset>
        <p>And/Or</p>
        <div className={[styles.secondBox, highlightBoxes ? styles.highlighted : ''].join(' ')}>
          <span className={styles.aster}>*</span>
          <h5>TREATMENT RESPONSE</h5>
          <div className={styles.option}>
            <input
              id="treatment-response-checkbox"
              type="checkbox"
              checked={treatmentResponse.text === testSelectionOptions[responseType][0].text}
              onChange={storeTreatmentResponse}
            />
            <label htmlFor="treatment-response-checkbox">
              {testSelectionOptions[responseType][0].text}
            </label>
          </div>
        </div>
      </div>
      {
        isTissueOption && (
          <TissueSpecimenRetrieval
            styles={styles}
            tissueSpecimenRetrieval={tissueSpecimenRetrieval}
            saveTestSelectionInfo={saveTestSelectionInfo}
          />
        )
      }
    </div>
  );
};

TestSelectionInformationExpanded.propTypes = IPropTypes;
TestSelectionInformationExpanded.defaultProps = defaultProps;

export { TestSelectionInformationExpanded };
