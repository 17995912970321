import { OktaAuth } from '@okta/okta-auth-js';
import Cookies from 'js-cookie';
import { config } from '../config/config';
import {
  PORTAL_INTERNAL_OKTA_CLIENT_ID,
  PORTAL_INTERNAL_OKTA_ISSUER,
  PORTAL_EXTERNAL_OKTA_ISSUER,
  PORTAL_EXTERNAL_OKTA_CLIENT_ID,
  APP_BASE_URL,
} from '../config';

export const getAuthClient = () => {
  const isInternalUser = Cookies.get('isInternal');
  const oktaIssuer = isInternalUser === 'true'
    ? config()[PORTAL_INTERNAL_OKTA_ISSUER] : config()[PORTAL_EXTERNAL_OKTA_ISSUER];
  const oktaClientId = isInternalUser === 'true'
    ? config()[PORTAL_INTERNAL_OKTA_CLIENT_ID] : config()[PORTAL_EXTERNAL_OKTA_CLIENT_ID];
  const headers = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
  return new OktaAuth({
    issuer: oktaIssuer,
    clientId: oktaClientId,
    headers,
    redirectUri: `${config()[APP_BASE_URL]}/login/callback`,
    responseType: 'code',
    pkce: false,
    tokenManager: {
      storage: 'localStorage',
      autoRenew: true,
    },
  });
};
