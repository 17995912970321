import { connect } from 'react-redux';

import { AddEarlyStageDiagnosisInfo } from '../../../../store/actions/orders/active-order/early-stage-diagnosis-info';

import { DiagnosisConditionEarlyStageExpanded } from './diagnosis-condition-early-stage-expanded';

const mapDispatchToProps = dispatch => ({
  saveEarlyStageDiagnosisInfo:
    (info, hideBanner) => dispatch(AddEarlyStageDiagnosisInfo(info, hideBanner)),
});

const mapStateToProps = ({ orders: { activeOrder: { earlyStageDiagnosisInfo, formErrors } } }) => ({
  diagnosisEarlyStore: earlyStageDiagnosisInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const ConnectedDiagnosisConditionEarlyStageExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(DiagnosisConditionEarlyStageExpanded);

export { ConnectedDiagnosisConditionEarlyStageExpanded as DiagnosisConditionEarlyStageExpanded };
