export const AnnouncementsEndpoints = {
  VERIFY_SIGNATURE_NOT_FINALIZED: '/rest/api/announcements',
  INACTIVE_ANNOUNCEMENTS: '/rest/api/announcements/inactivate',
};

export const OrdersEndpoints = {
  CHECK_SIGNATURE_COMPLETION: '/rest/check_signature_completion',
  CONFIGURATION: '/rest/orders/configuration',
  CREATE_TRF_ORDER: '/rest/orders',
  DIAGNOSIS_SEARCH: '/rest/orders/diagnosis_search',
  DISCARD_ORDER: '/rest/orders',
  FINALIZE_PRINTED_ORDER: '/rest/finalize_printed_order',
  GET_COMPLETED_ORDERS: '/rest/completed_orders',
  GET_ORDER: '/rest/order_by_order_id',
  GET_PENDING_ORDERS: '/rest/pending_orders',
  GET_PROGRESSION_ORDER: '/rest/request_from_lims',
  ICD_CODES_SEARCH: '/rest/orders/icd_codes_search',
  NUMBER_OF_PENDING_ORDERS: '/rest/number_of_pending_orders',
  PATIENT_STATUSES: '/rest/orders/patient_statuses',
  PATIENT_TYPES: '/rest/orders/patient_types',
  PRINT_OR_FINALIZE_ORDER: '/rest/print_or_finalize_order',
  PRINT_PDF: '/rest/print_order',
  QUALIFIED_ACCOUNTS: '/rest/all_ghi_accounts',
  QUALIFIED_PHYSICIANS_FOR_ACCOUNT: '/rest/all_physicians_for_ghi',
  RELEVANT_CLINICAL_HISTORY: '/rest/orders/relevant_clinical_history',
  TEST_SELECTIONS: '/rest/orders/test_selections',
  UPDATE_TRF_ORDER: '/rest/orders',
  VALIDATE_SCHEMA: '/rest/orders/validate_schema',
};

export const OrdersDashboardEndpoints = {
  BLANK_SIGNATURE_PAGE: '/rest/orders_dashboard/blank_trf_signature_template',
  GET_ORDERS: '/rest/orders_dashboard',
  GET_ORDERS_AMOUNTS: '/rest/orders_dashboard/amounts',
};

export const MutationEndpoints = {
  GENE_DETAILS: '/rest/api/reports/gene_details',
};

export const SessionEndpoints = {
  CHECK_SESSION: '/rest/admin_session/validate',
  REFRESH_SESSION: '/rest/admin_session/refresh',
};

export const ReportEndpoints = {
  MOVE_REPORT: '/move_report',
};

export const PatientPageEndpoints = {
  GET_PATIENT_INFORMATION: '/rest/api/patients',
  DISMISS_AUTO_RELEASE_PANEL: '/rest/dismiss_auto_release_panel',
  RELEASE_REPORT: id => `/api/patients/${id}/release_report`,
  SHARE_REPORT: '/rest/api/shares',
};

export const RequestsEndpoints = {
  GET_REQUESTS: '/rest/multi_product_requests',
};

export const AuditLogEndpoints = {
  AUDIT_LOG: '/rest/audit_log',
};

export const StudyManagerEndpoints = {
  GET_EMAIL_VALIDATION: '/rest/study_manager_email_validation',
  GET_ALL_PROJECT_IDS: '/rest/all_project_ids',
  GET_STUDY_MANAGER_INFO: '/rest/study_managers',
  EDIT_STUDY_MANAGER: '/rest/study_managers',
  STUDY_MANAGER_SIGNUP: '/rest/study_manager_sign_up',
};
