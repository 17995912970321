import React from 'react';
import PropTypes from 'prop-types';

import styles from './patient-tests-dropdown-container.module.scss';
import { PatientTestsDropdown } from '../patient-tests-dropdown';
import { DownloadButton } from '../download-button';

const IPropTypes = {
  canDownloadReport: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    downloadButtonName: PropTypes.string,
    selectedDropdownName: PropTypes.string.isRequired,
    errorHandlingSupport: PropTypes.bool.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })),
    options: PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      redirectTo: PropTypes.string.isRequired,
    })).isRequired,
  }),
  showErrorMessage: PropTypes.func,
};

const defaultProps = {
  data: null,
  showErrorMessage: () => {},
};

const PatientTestsDropdownContainer = ({ canDownloadReport, data, showErrorMessage }) => {
  if (!data) {
    return null;
  }

  const {
    selectedDropdownName,
    links,
    options,
    downloadButtonName,
    errorHandlingSupport,
  } = data;

  return (
    <div className={styles.container}>
      <div className={styles.dropdownContainer}>
        <PatientTestsDropdown
          selectedDropdownName={selectedDropdownName}
          options={options}
        />
      </div>
      { !!links.length && (
        <div className={styles.downloadButton}>
          <DownloadButton
            downloadButtonName={downloadButtonName}
            errorHandlingSupport={errorHandlingSupport}
            downloadLinks={links}
            canDownloadReport={canDownloadReport}
            showErrorMessage={showErrorMessage}
          />
        </div>
      )
      }
    </div>
  );
};

PatientTestsDropdownContainer.propTypes = IPropTypes;
PatientTestsDropdownContainer.defaultProps = defaultProps;

export { PatientTestsDropdownContainer };
