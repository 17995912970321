import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { receiveOrder } from '../../store/actions/orders/active-order/order';
import { addGenericInfo, clearActiveOrder } from '../../store/actions/orders/active-order/generic-info';
import { receiveError } from '../../store/actions/errors/index';
import { fetchStaticData } from '../../store/actions/orders/static-data';
import { updateLoadingCount } from '../../store/actions/global';
import { EditOrderContainer } from './edit-order-container';

const mapStateToProps = ({ orders: { activeOrder } }) => ({
  activeOrder,
  productFamily: activeOrder.genericInfo.productFamily,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveGenericInfo: addGenericInfo,
  saveOrder: receiveOrder,
  clearActiveOrder,
  receiveError,
  fetchStaticData,
  updateLoadingCount,
}, dispatch);

const ConnectedEditOrderContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(EditOrderContainer);

export { ConnectedEditOrderContainer as EditOrderContainer };
