import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { logger } from '../../../util/logger';
import { RequestsController } from '../../../networking/controllers/requests';
import { Request } from '../../../models/request';
import { RequestsTable } from '../requests-table';
import styles from './requests-container.module.scss';

const IPropTypes = {
  saveRequestsPageData: PropTypes.func.isRequired,
  requests: PropTypes.arrayOf(PropTypes.instanceOf(Request)).isRequired,
  rootUrl: PropTypes.string,
};

const defaultProps = {
  rootUrl: '#',
};

const RequestsContainer = ({ saveRequestsPageData, requests, rootUrl }) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(
    () => {
      (async () => {
        window.showSpinner();
        try {
          saveRequestsPageData(await RequestsController.getRequests());
        } catch (error) {
          logger.warn(error);
        } finally {
          setIsLoading(false);
          window.hideSpinner();
        }
      })();
    },
    [],
  );

  return !isLoading && (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.column}>
          <a className={styles.backButton} href={rootUrl}>
            <i className={['fa', 'fa-angle-left', styles.iconAngleLeft].join(' ')} />
            BACK
          </a>
        </div>
        <h1 className={[styles.column, styles.headerText].join(' ')}>Reports Table View</h1>
      </div>
      <RequestsTable requests={requests} />
    </>
  );
};

RequestsContainer.propTypes = IPropTypes;
RequestsContainer.defaultProps = defaultProps;

export { RequestsContainer };
