import { constants } from '../../config/constants';

const { COMPLETE, INCOMPLETE } = constants.printModal.infoCompleteness;

class LateStageClinicalHistoryInfo {
  constructor(params) {
    this.treatment = params.clinicalHistoryTreatment;
    this.report = params.clinicalHistoryReport;
    this.cgpFeasible = params.clinicalHistoryCgpFeasible;
    this.cgpCompletion = params.clinicalHistoryCgpCompletion;
    this.cgpResult = params.clinicalHistoryCgpResult;
  }

  isComplete = () => !!(
    this.treatment && this.report
    && this.cgpFeasible && this.cgpCompletion && this.cgpResult
  )

  formatForPrintModal = () => {
    const isCompleted = this.isComplete();

    return {
      title: 'CLINICAL HISTORY',
      info: isCompleted ? COMPLETE : INCOMPLETE,
      isCompleted,
    };
  }
}

export { LateStageClinicalHistoryInfo };
