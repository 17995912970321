import { connect } from 'react-redux';
import { TestSelectionInformationExpanded } from './test-selection-information-expanded';

import { AddTestSelectionInfo } from '../../../../store/actions/orders/active-order/test_selection_info';

const mapStateToProps = ({
  orders: { activeOrder: { testSelectionInfo, formErrors }, staticData },
}) => ({
  testSelectionInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
  testSelectionOptions: staticData.testSelections.testTypes,
});

const mapDispatchToProps = dispatch => ({
  saveTestSelectionInfo: (info, hideBanner) => dispatch(AddTestSelectionInfo(
    info, hideBanner,
  )),
});

const ConnectedTestSelectionInformationExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(TestSelectionInformationExpanded);

export { ConnectedTestSelectionInformationExpanded as TestSelectionInformationExpanded };
