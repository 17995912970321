
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AddPhysicianInfo, resetOnlyPhysicianData } from '../../../../store/actions/orders/active-order/physician_info';
import { showErrorBanner } from '../../../../store/actions/orders/active-order/form_errors';
import { updateLoadingCount } from '../../../../store/actions/global';

import { PhysicianInformationContainer } from './physician-information-container';


const mapStateToProps = ({
  global: { initialProps: { userPermissions: { emrUser } } },
  orders: { activeOrder: { physicianInfo, formErrors }, emr },
}) => ({
  physicianStore: physicianInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
  emrUser,
  emrProvider: emr.provider,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  savePhysicianInfo: AddPhysicianInfo,
  showErrorBanner,
  resetOnlyPhysicianData,
  updateLoadingCount,
}, dispatch);

const ConnectedPhysicianInformationContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(PhysicianInformationContainer);

export { ConnectedPhysicianInformationContainer as PhysicianInformationContainer };
