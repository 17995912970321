import { deserializeDate, serializeDate } from './helpers';

class RevealOrderingSerializer {
  static deserialize = (revealOrdering) => {
    if (!revealOrdering) {
      return {};
    }

    return {
      orderFrequency: {
        code: revealOrdering.name,
        name: revealOrdering.text && revealOrdering.text[0],
      },
      preferenceForBloodDraw: revealOrdering.preferenceForBloodDraw,
      surgicalResectionDate: deserializeDate(revealOrdering.dateOfSurgicalResection),
    };
  }

  static serialize = ({ orderFrequency, preferenceForBloodDraw, surgicalResectionDate }) => ({
    name: orderFrequency.code,
    preferenceForBloodDraw,
    dateOfSurgicalResection: serializeDate(surgicalResectionDate),
  })
}

export { RevealOrderingSerializer };
