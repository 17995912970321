import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import HttpService from '../../service/interceptor';
import * as actionTypes from './actionTypes';

export const getSiteUsersList = params => (dispatch) => {
  const query = `?${new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());

  return HttpService.get(`/api/v1/usersites${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getSites(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.siteAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const getSitesList = (params = {}) => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.get(`/api/v1/sites?name:contains=${params}`)
    .then(({ data }) => {
      dispatch(actionTypes.getAllSites(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.siteAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const addSite = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.post('/api/v1/userroles', params)
    .then(({ data }) => {
      dispatch(actionTypes.saveSiteAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.saveSiteAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const editSite = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.put(`/api/v1/users/${params.uuid}/sites`, params.sites)
    .then(({ data }) => {
      dispatch(actionTypes.updateSiteAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.updateSiteAssignmentError(err));
      dispatch(closeSpinner());
    });
};
export const deleteSite = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.delete('/api/v1/userroles', params)
    .then(({ data }) => {
      dispatch(actionTypes.deleteSiteAssignment(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.deleteSiteAssignmentError(err));
      dispatch(closeSpinner());
    });
};

export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePageSiteAssignment(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeSiteAssignment(params));
};
