import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  toggleAdditionalReceipient: PropTypes.func,
};

const defaultProps = {
  toggleAdditionalReceipient: () => {},
};

const AdditionalRecipient = ({ toggleAdditionalReceipient }) => (
  <div className="flex-container__etrf-section">
    <div className="flex-container__etrf-row">
      <div className="flex-container__etrf-column">
        <div className="flex-container">
          <i className="fa fa-plus-circle medical-collaborator-minus" onClick={toggleAdditionalReceipient} />
          <div className="action-label bold click-btn" onClick={toggleAdditionalReceipient}>ADDITIONAL RECIPIENT</div>
        </div>
      </div>
    </div>
  </div>
);

AdditionalRecipient.propTypes = IPropTypes;
AdditionalRecipient.defaultProps = defaultProps;

export { AdditionalRecipient };
