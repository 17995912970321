import { config } from '../../config/config';
import {
  API_GATEWAY_URL,
  CONFIG_SRVC_V2_PATH,
  ETRF_MGMT_V2_PATH, ORDER_MGMT_V2_PATH,
  SEARCH_SRVC_V2_PATH,
  USER_MGMT_V2_PATH,
  SFDC_INT_SRVC,
} from '../../config';

export const FINISHED = 'finished';
export const REQUESTED = 'requested';
export const SENT = 'sent';
export const ERROR = 'error';

export const REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};
// eslint-disable-next-line import/no-mutable-exports
export let restApiConfig = {
  dev: true,
  defaultHeaders: {
    Accept: '*/*',
  },
  defaultOptions: {
    Accept: '*/*',

  },
  lang: 'en-US',
  defaultBody: {},
  apiBasePath: config()[API_GATEWAY_URL] + config()[USER_MGMT_V2_PATH],
  apiOrdersV2Path: config()[API_GATEWAY_URL] + config()[ORDER_MGMT_V2_PATH],
  searchapiOrdersPath: config()[API_GATEWAY_URL] + config()[SEARCH_SRVC_V2_PATH],
  etrfapiOrdersPath: config()[API_GATEWAY_URL] + config()[ETRF_MGMT_V2_PATH],
  configapiOrdersPath: config()[API_GATEWAY_URL] + config()[CONFIG_SRVC_V2_PATH],
  sfdcapiOrdersPath: config()[API_GATEWAY_URL] + config()[SFDC_INT_SRVC],
  apiOrdersV2PathVersion2Path: config()[API_GATEWAY_URL],
  authenHeader: 'Authorization',

};

export const constants = {
  FINISHED,
  ERROR,
  SENT,
  REQUESTED,
  REQUEST_TYPES,
  FAIL_REQUEST_CODES: ['501', '404', '401', '412', '400', '500'],
};

export const setRestApiConfig = (conf) => {
  // eslint-disable-next-line no-const-assign
  restApiConfig = { ...restApiConfig, ...conf };
};
