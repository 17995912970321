import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Dashboard from './Dashboard';
import {
  loadPendingOrders,
  loadCompletedOrders,
  editPatientOrder,
  loadESignPendingOrders,
  downloadEtrfPdf,
  ordersDashboardRoleBasedAuth,
} from './actions';
import {
  getCompletedOrdersList,
  getPendingESignatureOrdersList,
  getPendingOrdersList,
  getCompletedRecords,
  getPendingRecords,
  getESignPendingRecords,
  isPendingOrdersLoaded,
  isCompletedOrdersLoaded,
  isPendingESignOrdersLoaded, hasErrors, getErrorMessage,
} from './selector';
import { resetDefaultState, getAllcancer } from '../../addEditOrder/actions/NewOrderAction';
import { closeBannerMessage, ordersSelectedTab, ordersDashboardInitialLoad } from './actions/actionTypes';

const getDiseaseDisplayName = (orders, cancersList) => {
  return orders?.map(order => {
    if (order?.diagnosis) {
      order.diagnosis.trfDisplayName = cancersList?.filter(i => i?.diagnosis?.toUpperCase() === order?.diagnosis?.disease?.toUpperCase())[0]?.trfDisplayName;
    }
    return order;
  });
};

const mapStateToProps = state => {
  const newOrder = state.newOrder.toJS();
  const {cancerTypeList} = newOrder || {};
  return {
  completedOrders: getDiseaseDisplayName(getCompletedOrdersList(state), cancerTypeList),
  pendingOrders: getDiseaseDisplayName(getPendingOrdersList(state), cancerTypeList),
  pendingSignatureOrders: getDiseaseDisplayName(getPendingESignatureOrdersList(state), cancerTypeList),
  totalCompletedRecords: getCompletedRecords(state),
  totalPendingRecords: getPendingRecords(state),
  totalESignPendingRecords: getESignPendingRecords(state),
  isPendingOrdersLoaded: isPendingOrdersLoaded(state),
  isCompletedOrdersLoaded: isCompletedOrdersLoaded(state),
  isPendingESignOrdersLoaded: isPendingESignOrdersLoaded(state),
  hasError: hasErrors(state),
  errorMessage: getErrorMessage(state),
  dashboardInitialLoad: state.ordersDashboard.get('dashboardInitialLoad'),
  newOrder,
  selectedTab: state.ordersDashboard.get('selectedTab'),
  roleBasedAuthorisation: state.ordersDashboard?.get('roleBasedAuthorisation'),
  }
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadPendingOrders,
      loadCompletedOrders,
      loadESignPendingOrders,
      closeBannerMessage,
      downloadEtrfPdf,
      editPatientOrder,
      ordersSelectedTab,
      ordersDashboardInitialLoad,
      resetDefaultState,
      ordersDashboardRoleBasedAuth,
      getAllcancer,
    }, dispatch,
  ),
});

const ConnectedDashboard = connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
export default ConnectedDashboard;
