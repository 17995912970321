import { addGenericInfo, clearActiveOrder } from './generic-info';
import { toggleModalAction, updateModalInfo, updateSummaryModalInfo } from './print-modal';
import { toggleConfirmationModalAction, hideFormValidationMessageAction } from './confirmation_modal';
import { logger } from '../../../../util/logger';
import { showErrorBanner, hideErrorBanner } from './form_errors';
import { OrdersController } from '../../../../networking/controllers/orders';
import { constants } from '../../../../config/constants';
import { constants as configConstants } from '../../../../constants/config';
import { receiveTestSelectionInfo } from './test_selection_info';
import { receiveGuardantRevealOrderingInfo } from './guardant-reveal-ordering-info';
import { buildOrder } from '../../../../models/order/order-builder';
import { AuditLogController } from '../../../../networking/controllers/audit-log';
import { updateDashboard } from '../dashboard';
import { updateLoadingCount } from '../../global';

const RECEIVE_ORDER = 'RECEIVE_ORDER';

const receiveOrder = order => (dispatch, getState) => {
  const { orders: { staticData: { testSelections: { testTypes } } } } = getState();

  let parsedOrder = receiveTestSelectionInfo(order, testTypes);

  parsedOrder = receiveGuardantRevealOrderingInfo(parsedOrder, testTypes);

  dispatch({ type: RECEIVE_ORDER, order: parsedOrder });

  const specimen = parsedOrder.specimen || {};
  dispatch(updateModalInfo({
    specimenCollectionDate: specimen.specimenCollectionDate || '',
    labClinicianText: specimen.labClinicianText || '',
  }));
};

const continueETRFLater = () => async (dispatch, getState) => {
  dispatch(hideErrorBanner());
  dispatch(updateLoadingCount(1));

  const { orders: { activeOrder: activeOrderStore } } = getState();

  const { activeOrderId } = activeOrderStore.genericInfo;

  try {
    const order = activeOrderId
      ? await OrdersController.updateTrfOrder(activeOrderId, activeOrderStore)
      : await OrdersController.createTrfOrder(activeOrderStore);

    dispatch(addGenericInfo({ activeOrderId: order.orderId, redirectContinueLater: true }));

    dispatch(updateDashboard(
      activeOrderId ? buildOrder(activeOrderStore) : null,
      buildOrder(order),
    ));

    dispatch(clearActiveOrder());
  } catch (error) {
    dispatch(addGenericInfo({ redirectContinueLater: false }));
    dispatch(showErrorBanner());
    logger.warn(error);
  }
  dispatch(updateLoadingCount(-1));
};

const discardOrder = onSuccess => async (dispatch, getState) => {
  const { orders: { activeOrder: activeOrderStore } } = getState();
  const {
    activeOrderId, productFamily,
  } = activeOrderStore.genericInfo;

  await dispatch(hideErrorBanner());

  try {
    if (activeOrderId) {
      await OrdersController.discardTrfOrder(activeOrderId, productFamily);
      dispatch(
        updateDashboard(buildOrder(activeOrderStore)),
      );
    }
    dispatch(toggleConfirmationModalAction());
    dispatch(clearActiveOrder());

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (error) {
    dispatch(showErrorBanner());
    logger.warn(error);
  }
};

const toggleBillingOverlay = () => async (dispatch, getState) => {
  const { orders: { activeOrder } } = getState();

  const order = buildOrder(activeOrder);

  dispatch(updateSummaryModalInfo({ order }));
  dispatch(toggleModalAction());

  AuditLogController.auditLog(constants.auditLogActions.printForm, order.id);
};

const validateOrderAndPrint = () => dispatch => (
  dispatch(hideFormValidationMessageAction())
);
/*
  The purpose of this action is to avoid the race condition between saving the ETRF
  for later and rerouting the page.
*/
const reactToETRFError = () => async (dispatch, getState) => {
  // Saving the ETRF for later only makes sense if the error happened
  // when the user actually is on the edit or create pages for the ETRF.
  // NOTE: the router helpers are not used here since this component is outside
  // the router's scope.
  // This regular expression matches /orders/:id and by inclusion /orders/new
  if (/^\/orders\/[^/]+$/.test(window.location.pathname)) {
    try {
      await continueETRFLater()(dispatch, getState);
    } catch (error) {
      logger.error(error);
    }
  }

  if (!configConstants.environment.isDevelopment) {
    window.location = '/500';
  }
};

export {
  RECEIVE_ORDER,
  receiveOrder,
  continueETRFLater,
  discardOrder,
  toggleBillingOverlay,
  validateOrderAndPrint,
  reactToETRFError,
};
