import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  toggleModalAction,
  updateModalInfo,
} from '../../../store/actions/orders/active-order/print-modal';
import { hideErrorBanner as hideErrorBannerAction } from '../../../store/actions/orders/active-order/form_errors';
import { PrintModal } from './print-modal';

const mapStateToProps = ({
  orders: {
    activeOrder: { printModal },
    staticData: { configuration },
  },
  global: { initialProps: { userPermissions } },
}) => ({
  alreadyPrinted: printModal.alreadyPrinted,
  modalTitle: printModal.title,
  showModal: printModal.showModal,
  informationMessage: printModal.informationMessage,
  informationSubMessage: printModal.informationSubMessage,
  warningStatus: printModal.warningStatus,
  summary: printModal.summary,
  printOrderText: printModal.printOrderText,
  finalizeOrderText: printModal.finalizeOrderText,
  footer: printModal.footer,
  commentsText: printModal.commentsText,
  labClinicianText: printModal.labClinicianText,
  specimenCollectionDate: printModal.specimenCollectionDate,
  canChangeOrders: userPermissions.canChangeOrders,
  canOnlyDemoOrders: userPermissions.canOnlyDemoOrders,
  onClickPrintNow: printModal.onClickPrintNow,
  logoSrc: printModal.productFamily && configuration[printModal.productFamily].logoSrc,
  eSignatureEnabled: userPermissions.eSignatureEnabled,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleModal: toggleModalAction,
  updateModalInfo,
  hideErrorBanner: hideErrorBannerAction,
}, dispatch);

const ConnectedPrintModal = connect(mapStateToProps, mapDispatchToProps)(PrintModal);

export { ConnectedPrintModal as PrintModal };
