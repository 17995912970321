import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { DashboardListItem } from '../dashboard-list-item';
import { constants } from '../../../../config/constants';
import { Order } from '../../../../models/order/order';
import { AuditLogController } from '../../../../networking/controllers/audit-log';
import { ProductLogo } from '../../../generic/product-logo';
import { printPDFURL } from '../../../../networking/print-helper';
import { getToken, isInternalUser } from '../../../../networking/controllers/helper';

const IPropTypes = {
  order: PropTypes.instanceOf(Order).isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  loggedUserEmail: PropTypes.string.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
};

const CompletedOrderItem = ({
  order, toggleModal, updateModal, disabled, onError, loggedUserEmail, updateLoadingCount,
}) => {
  const onClick = useCallback((e) => {
    e.preventDefault();

    if (!disabled) {
      AuditLogController.auditLog(
        constants.auditLogActions.viewPrintedOrder,
        order.id,
      );

      const finalizeOrderText = order.orderingUser.email === loggedUserEmail
        ? 'Finalize order' : 'Provide digital signature';

      updateModal({ order, finalizeOrderText });
      toggleModal();
    }
  }, [order, disabled]);

  const onClickPrintNow = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    updateLoadingCount(1);
    const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
    try {
      axios.get(printPDFURL(order.metadata.envelopeId, order.id, order.productFamily), {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          window.open(url, '_blank');
        });
    } catch (error) {
      onError(error);
    } finally {
      updateLoadingCount(-1);
    }
  }, [order]);

  const productLogos = (order.selectedTests() || []).map(productId => (
    <ProductLogo
      key={productId}
      productId={productId}
    />
  ));

  return (
    <DashboardListItem
      firstBlock={order.patientInfo.formatFullName()}
      secondBlock={order.patientInfo.formatBirthDate()}
      thirdBlock={order.diagnosisInfo.formatDisease()}
      fourthBlock={productLogos.length ? productLogos : ['N/A']}
      fifthBlock={order.formatOrderingUserAndStatus()}
      callback={onClick}
      disabled={disabled}
      displayBtn
      onBtnClick={onClickPrintNow}
    />
  );
};

CompletedOrderItem.propTypes = IPropTypes;

export { CompletedOrderItem };
