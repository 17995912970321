/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { FormHelperText, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Controls from '../common/components/forms/controls';
import { useForm, Form } from '../common/components/forms/useForm';
import Validation from '../common/utils/Validation';

const useStyles = makeStyles(theme => createStyles({
  actionButtons: {
    textAlign: 'right',
    margin: theme.spacing(2),
  },
  submitButton: {
    boxShadow: 'none',
    backgroundColor: '#1A5EA7',
    '&:hover': {
      backgroundColor: '#4572A7',
      boxShadow: 'none',
    },
  },
  resetButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  inputText: {
    width: '90%',
  },
}));

const initialFValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  salutation: '',
  email: '',
};
const fieldsValidation = {
  firstName: {
    error: '',
    validate: 'name',
    required: true,
    minLength: 2,
    maxLength: 50,
  },
  middleName: {
    error: '',
    validate: 'name',
    minLength: 0,
    maxLength: 50,
  },
  lastName: {
    error: '',
    validate: 'name',
    required: true,
    minLength: 2,
    maxLength: 50,
  },
  email: {
    error: '',
    validate: 'email',
    required: true,
    minLength: 8,
    maxLength: 256,
  },
  salutation: {
    error: '',
    validate: 'dropDown',
    required: true,
  },
};
const IPropTypes = {
  submitHandler: PropTypes.func.isRequired,
  formData: PropTypes.object,
  edit: PropTypes.bool,
  disableEmail: PropTypes.bool,
};
const defaultProps = {
  formData: null,
  disableEmail: false,
  edit: false,
};

const UsersForm = (props) => {
  const {
    submitHandler, disableEmail, formData, edit,
  } = props;
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    ['firstName', 'middleName', 'lastName', 'salutation', 'email'].forEach(
      (val) => {
        if (val in fieldValues) {
          if (
            (val === 'middleName'
              && fieldValues[val]
              && fieldValues[val].length > 0)
            || val !== 'middleName'
          ) {
            const error = Validation(val, fieldValues[val], fieldsValidation) || '';
            temp[val] = error;
          } else {
            temp[val] = '';
          }
        }
      },
    );

    setErrors({
      ...temp,
    });
    if (fieldValues === values) return Object.values(temp).every(x => x === '');
  };

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      submitHandler(values, resetForm, edit);
    }
  };

  useEffect(() => {
    if (formData !== null) {
      const salutationValue = formData.salutation != null ? formData.salutation : '';
      setValues({
        ...formData,
        salutation: salutationValue,
      });
    }
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FormControl size="small" variant="outlined" error={errors.salutation}>
            <Select
              labelId="account-select"
              id="select-component"
              name="salutation"
              value={values.salutation}
              onChange={(e) => {
                handleInputChange(e);
              }}
              inputProps={{ 'aria-label': 'Without label' }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Salutation
              </MenuItem>
              <MenuItem value="MR">MR</MenuItem>
              <MenuItem value="MISS">MISS</MenuItem>
              <MenuItem value="MS">MS</MenuItem>
              <MenuItem value="MRS">MRS</MenuItem>
              <MenuItem value="DR">DR</MenuItem>
            </Select>
            {errors.salutation && (
              <FormHelperText>{errors.salutation}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            name="firstName"
            label="FirstName"
            value={values.firstName}
            onChange={handleInputChange}
            error={errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="MiddleName"
            name="middleName"
            value={values.middleName}
            onChange={handleInputChange}
            error={errors.middleName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="LastName"
            name="lastName"
            value={values.lastName}
            onChange={handleInputChange}
            error={errors.lastName}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controls.Input
            disabled={disableEmail}
            label="Email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
          />
        </Grid>
        <Grid item xs={12} className={classes.actionButtons}>
          <Controls.Button
            className={classes.submitButton}
            type="submit"
            text="Save"
          />
          <Controls.Button
            text="Reset"
            color="default"
            className={classes.resetButton}
            onClick={() => {
              resetForm(formData);
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
export default UsersForm;
UsersForm.propTypes = IPropTypes;
UsersForm.defaultProps = defaultProps;
