import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { isInternalUser } from '../../networking/controllers/helper';
import { config } from '../config/config';
import { API_GATEWAY_URL, USER_MGMT_V2_PUB_PATH } from '../config';

const PatientAdditionalInfo = (props) => {
  const { authenticityToken } = props;
  const dos = useRef(null);
  const physicianName = useRef(null);
  const activationCode = useRef(null);
  const handleSubmit = async () => {
    const physicaian = physicianName.current.value;
    const dateOfSample = dos.current.value;
    const welcomeCode = activationCode.current.value;
    const data = JSON.stringify({
      bloodCollectionDate: `${dateOfSample}`,
      physicianLastName: `${physicaian}`,
      welcomeCode: `${welcomeCode}`,
    });
    const uuid = sessionStorage.getItem('userId');
    const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

    const axiosconfig = {
      method: 'put',
      url: `${config()[API_GATEWAY_URL]}${config()[USER_MGMT_V2_PUB_PATH]}/patients/${uuid}/info`,
      headers: {
        Accept: '*',
        'Content-Type': 'application/json; charset=UTF-8',
        ...extHeader,
      },
      data,
    };
    const moreInfoRes = await axios(axiosconfig);
    // eslint-disable-next-line no-constant-condition
    if (moreInfoRes.status === 200 || 201) {
      window.location.pathname = '/';
    }
  };
  useEffect(() => {
    sessionStorage.removeItem('patient-more-info');
  }, []);
  return (
    <div className="row content_ registry">
      <div className="registry-content col-xs-12">
        <h1>Please verify your identity</h1>
        <form
          className="ptp__form"
          id="new_user_info"
          acceptCharset="UTF-8"
          method="post"
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          <div className="form-group">
            <label htmlFor="txt-first-name">Additional Information</label>
            <p className="help-block">
              To securely verify the patient&apos;s identity, we need more
              information in addition to the patient&apos;s full name and date of
              birth.
            </p>
          </div>
          <div className="form-group form-group__additional_info">
            <p className="help-block">
              What was the date that your blood was drawn or
              tissue sample collected for Guardant360?
            </p>
            <div className="row">
              <div className="col-xs-12 col-sm-7">
                <input
                  className="form-control hasDatepicker"
                  id="dobpicker"
                  ref={dos}
                  data-template="MMM D YYYY"
                  data-format="YYYY-MM-DD"
                  type="date"
                  name="user_info[bloodcolldate]"
                />
              </div>
            </div>
          </div>
          <div className="form-group form-group__additional_info">
            <p className="help-block">
              What is the ordering physician’s last name?
            </p>
            <div className="row">
              <div className="col-xs-12 col-sm-7">
                <input
                  ref={physicianName}
                  placeholder="Physician Last Name"
                  className="form-control"
                  type="text"
                  name="user_info[physician_last_name]"
                  id="user_info_physician_last_name"
                />
              </div>
            </div>
          </div>
          <div className="form-group form-group__additional_info hide">
            <p className="help-block">Please enter the activation code</p>
            <div className="row">
              <div className="col-xs-12 col-sm-7">
                <input
                  ref={activationCode}
                  placeholder="e.g. A12BC"
                  className="form-control"
                  type="text"
                  name="user_info[welcome_code]"
                  id="user_info_welcome_code"
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="registry-btn"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            SUBMIT
          </button>
        </form>
      </div>
      <div className="col-xs-12">
        <div className="registry-headline">
          <div className="registry-text">
            Don’t have this information handy?
          </div>
          <div className="registry-links">
            <a className="registry-links" href="/" title="">
              SKIP THIS STEP
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientAdditionalInfo;
PatientAdditionalInfo.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
};
