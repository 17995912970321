import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { constants } from '../../../config/constants';
import { OrderingWarnings } from '../../../config/ordering-warnings';
import { OrdersController } from '../../../networking/controllers/orders';
import { TrfValidationOverlay } from '../trf-validation-overlay';
import { buildOrder } from '../../../models/order/order-builder';
import { logger } from '../../../util/logger';
import { Button } from '../../generic/button';

import styles from './orders-action-container.module.scss';

const IPropTypes = {
  store: PropTypes.object.isRequired,
  continueETRFLater: PropTypes.func.isRequired,
  redirectContinueLater: PropTypes.bool.isRequired,
  hideFormValidationMessage: PropTypes.func.isRequired,
  toggleConfirmationModal: PropTypes.func.isRequired,
  validateOrderAndPrint: PropTypes.func.isRequired,
  toggleBillingOverlay: PropTypes.func.isRequired,
  showFormValidationMessage: PropTypes.bool.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
  triggerFormValidationMessage: PropTypes.func.isRequired,
  hideErrorBanner: PropTypes.func.isRequired,
  showErrorBanner: PropTypes.func.isRequired,
  showMissingRequiredFields: PropTypes.func.isRequired,
  canChangeOrders: PropTypes.bool.isRequired,
  canOnlyDemoOrders: PropTypes.bool.isRequired,
  showContinueLaterButton: PropTypes.bool.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
};

const OrdersActionContainer = ({
  store,
  continueETRFLater,
  redirectContinueLater,
  warnings,
  hideFormValidationMessage,
  toggleConfirmationModal,
  validateOrderAndPrint,
  toggleBillingOverlay,
  showFormValidationMessage,
  triggerFormValidationMessage,
  hideErrorBanner,
  showErrorBanner,
  showMissingRequiredFields,
  canChangeOrders,
  canOnlyDemoOrders,
  showContinueLaterButton,
  updateLoadingCount,
}) => {
  const onDisclaimerConfirmation = useCallback(() => {
    validateOrderAndPrint();
    toggleBillingOverlay();
  }, []);

  const onDiscardClick = useCallback(() => {
    hideErrorBanner();
    toggleConfirmationModal();
  }, []);

  const toggleOverlay = useCallback((order, icdCodeMatchesDiagnosis) => {
    const {
      clinicalHistoryWarning,
      icdCodeMatchWarning,
      revealOrderingSurgicalResectionWarning,
      insuredDOBMatchWarning,
    } = OrderingWarnings;

    const warns = [
      !order.clinicalHistoryInfo.isComplete() && clinicalHistoryWarning.name,
      !order.checkMatchingFields() && insuredDOBMatchWarning.name,
      !icdCodeMatchesDiagnosis && icdCodeMatchWarning.name,
      order.checkNeededSurgicalResectionDateMissing()
        && revealOrderingSurgicalResectionWarning.name,
    ].filter(Boolean);

    if (warns.length > 0) {
      triggerFormValidationMessage(warns);
    } else {
      toggleBillingOverlay();
    }
  }, []);

  const triggerBlankRequiredFieldsBanner = useCallback(() => {
    window.scrollTo(0, 0);
    showMissingRequiredFields();
    showErrorBanner();
  }, []);

  const onNextClick = async () => {
    hideErrorBanner();
    const order = buildOrder(store);

    if (!order.checkRequiredFields()) {
      triggerBlankRequiredFieldsBanner();
      return;
    }

    updateLoadingCount(1);

    try {
      const validation = await OrdersController.validateSchema(store);

      toggleOverlay(order, validation.icdMatchesDiagnosis);
    } catch (error) {
      logger.warn(error);
      if (error.response && error.response.status === 400) {
        triggerBlankRequiredFieldsBanner();
      }
      showErrorBanner([constants.etrfOrdering.errorEncounteredMessage]);
    } finally {
      updateLoadingCount(-1);
    }
  };

  if (redirectContinueLater) {
    return <Redirect to="/orders" />;
  }

  return (
    <div className={styles.ordersActionContainer}>
      {
        showFormValidationMessage
          && (
            <TrfValidationOverlay
              toggleConfirmationModal={hideFormValidationMessage}
              onConfirmCallback={onDisclaimerConfirmation}
              warnings={warnings}
            />
          )
      }
      {
        showContinueLaterButton && (
          <Button
            onClick={continueETRFLater}
            disabled={!canChangeOrders || canOnlyDemoOrders}
            className={styles.continueLaterButton}
          >
            Continue Later
          </Button>
        )
      }
      <Button
        type="secondary"
        className={styles.discardButton}
        onClick={onDiscardClick}
        disabled={!canChangeOrders || canOnlyDemoOrders}
      >
        <i className="fa fa-trash" aria-hidden="true" />
        Discard
      </Button>
      <Button onClick={onNextClick} className={styles.nextButton}>
        Next
        <i className="fa fa-chevron-right" aria-hidden="true" />
      </Button>
    </div>
  );
};

OrdersActionContainer.propTypes = IPropTypes;

export { OrdersActionContainer };
