import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '../table';
import { Biomarker } from '../../../models/biomarker';

const IPropTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Biomarker)).isRequired,
  dictionary: PropTypes.shape({
    table: PropTypes.string,
    label: PropTypes.string,
    details: PropTypes.string,
  }).isRequired,
};

const BiomarkersTable = ({ data, dictionary }) => {
  const columns = [dictionary.label, dictionary.details];

  const rows = data.map(biomarker => ({
    key: biomarker.name,
    name: biomarker.formattedName(),
    values: [biomarker.value],
  }));

  return (
    <Table
      tableTitle={dictionary.table}
      columns={columns}
      rows={rows}
    />
  );
};

BiomarkersTable.propTypes = IPropTypes;

export { BiomarkersTable };
