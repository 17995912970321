import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { StudyManagerUpdate } from './study-manager-update';
import { addBannerMessages } from '../../../store/actions/global';

const mapDispatchToProps = dispatch => bindActionCreators({
  addBannerMessages,
}, dispatch);


const ConnectedStudyManagerUpdate = connect(null, mapDispatchToProps)(StudyManagerUpdate);

export { ConnectedStudyManagerUpdate as StudyManagerUpdate };
