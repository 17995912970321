import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';
import DownloadLogo from '../../../../assets/images/icons/Download-Icon.svg';
import ShareIcon from '../../../../assets/images/icons/share-icon.svg';

const PhysicianAdminAccessList = (props) => {
  const {
    leftColName,
    leftColUnit,
    rightColName,
    downloadContractReport,
    eSignContractReport,
    accessedPhysician,
    viewAsUser,
  } = props;

  const isPhysician = (viewAsUser?.role === 'physician' || accessedPhysician?.role === 'physicianAdmin');
  const isPhysicianAdmin = (viewAsUser?.role === 'physicianAdmin' || accessedPhysician?.role === 'physician');

  const accessedUser = {
    ...accessedPhysician,
    status: undefined,
  };

  const onEsignHandleClick = () => {
    const params = {
      requestedBy: isPhysicianAdmin ? viewAsUser : accessedUser,
      signeeList: isPhysician ? [viewAsUser] : [accessedUser],
      invitationDate: moment().format('YYYY-MM-DD'),
    };
    eSignContractReport(JSON.stringify(params));
  };

  const onDownloadHandleClick = () => {
    const requestedBy = isPhysicianAdmin ? viewAsUser?.email : accessedPhysician?.email;
    const signee = isPhysicianAdmin ? accessedPhysician?.email : viewAsUser?.email;
    const accountNumber = isPhysicianAdmin ? accessedPhysician?.accountId : viewAsUser?.accountId;
    downloadContractReport(requestedBy, signee, accountNumber);
  };

  return (
    <div className="share-index__collaborator">
      <div className="share-index__collaborator__top">
        <Grid container spacing={0}>
          <Grid item xs={9} style={{ marginleft: '5px' }}>
            {leftColName}
            {leftColUnit && (
              <div style={{ fontSize: '13px', marginTop: '5px' }}>
                {leftColUnit}
              </div>
            )}
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right', fontSize: '12px', color: '#8B939C' }}>
            {rightColName}
          </Grid>
          <Grid
            item
            xs={1}
            style={{ textAlign: 'center' }}
            onClick={rightColName === 'PENDING' ? onEsignHandleClick : onDownloadHandleClick}
          >
            <img
              alt="Guardant-Logo"
              style={rightColName === 'PENDING' ? { border: '1px solid', borderRadius: '3px' } : {}}
              height="18"
              src={rightColName === 'PENDING' ? ShareIcon : DownloadLogo}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default PhysicianAdminAccessList;
PhysicianAdminAccessList.propTypes = {
  leftColName: PropTypes.string,
  leftColUnit: PropTypes.string,
  rightColName: PropTypes.string,
  downloadContractReport: PropTypes.func.isRequired,
  eSignContractReport: PropTypes.func.isRequired,
  accessedPhysician: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
};
PhysicianAdminAccessList.defaultProps = {
  leftColName: '',
  leftColUnit: '',
  rightColName: '',
};
