import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updatePatientName } from '../service/PatientService';

const IPropTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
};
const defaultProps = {
  showModal: false,
};

const NameUpdateModal = (props) => {
  const {
    showModal,
    closeModal,
    info: { firstname, lastname },
  } = props;
  const [firstName, setFirstName] = useState(firstname);
  const [lastName, setLastName] = useState(lastname);

  const savePatientName = () => {
    const params = { firstname: firstName, lastname: lastName };
    updatePatientName(params, closeModal);
  };

  return (
    <>
      {
        showModal && (
          <div className="modal__container-outer modal__patient edit__name">
            <div className="modal__container-inner">
              <div className="modal__content">
                <div className="patient__settings-edit patient__settings-edit__name">
                  <div className="settings-edit__container">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="myguardant-form">Patient&apos;s Full Name</label>
                    <p className="help-block">Please enter your full legal first and last name</p>
                    <div className="content">
                      <div className="row">
                        <div className="col-xs-12 col-md-6">
                          <input
                            className="input__firstname"
                            placeholder="First Name"
                            type="text"
                            value={firstName}
                            onChange={event => setFirstName(event.target.value)}
                          />
                        </div>
                        <div className="col-xs-12 col-md-6">
                          <input
                            className="input__lastname"
                            placeholder="Last Name"
                            type="text"
                            value={lastName}
                            onChange={event => setLastName(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="buttons-row">
                      <div className="button button-clear-background close__edit__name" onClick={() => closeModal('name')}>CANCEL</div>
                      <div className="button form-submit__btn save__edit__name" onClick={savePatientName}>SAVE</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};
export default NameUpdateModal;
NameUpdateModal.propTypes = IPropTypes;
NameUpdateModal.defaultProps = defaultProps;
