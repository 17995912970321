class ParamsHelper {
  static parseQueryParams(queryParams = '') {
    const params = {};
    const decodedParams = queryParams;
    if (decodedParams.length) {
      decodedParams.slice(1).split('&').forEach((param) => {
        const splitParam = param.split('=').map(window.decodeURIComponent);
        /* eslint-disable-next-line prefer-destructuring */
        params[splitParam[0]] = splitParam[1];
      });
    }
    return params;
  }
}

export { ParamsHelper };
