import React from 'react';
import PropTypes from 'prop-types';

import { States } from '../../constants/states';
import Dropdown from '../orders/Dropdown';

const IPropTypes = {
  country: PropTypes.string,
};

const defaultProps = {
  country: '',
};

const StatesDropdown = (props) => {
  let adaptedStateList = [];
  const country = props.country || 'United States';

  const selectedCountry = States.find(i => i.country === country);

  if (selectedCountry) {
    adaptedStateList = selectedCountry.states.map((state, index) => ({
      id: String(index + 1),
      title: state.value,
      key: 'patientState',
    }));
  }

  return (
    <Dropdown
      {...props}
      list={adaptedStateList}
      title="Select"
    />
  );
};

StatesDropdown.propTypes = IPropTypes;
StatesDropdown.defaultProps = defaultProps;

export { StatesDropdown };
