import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  showArrow: PropTypes.string,
  newArrowDirection: PropTypes.string,
  handleContainerArrowClick: PropTypes.func,
  title: PropTypes.string,
  extraBig: PropTypes.oneOf(['__extra-big', '']),
};

const defaultProps = {
  showArrow: '',
  newArrowDirection: 'down',
  title: '',
  extraBig: '',
  handleContainerArrowClick: () => {},
};

class DashboardListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { handleContainerArrowClick } = this.props;
    e.preventDefault();
    handleContainerArrowClick();
  }

  render() {
    const {
      title, newArrowDirection, showArrow, extraBig,
    } = this.props;

    return (
      <div className="h4 requests-list__header">
        <div className="h4 requests-list__header">
          <div className={`requests-list__header__label${extraBig}`}>
            <div onClick={this.handleClick}>
              <div className={`fa fa-angle-${newArrowDirection}
                         expanded dashboard-angle-reactified ${showArrow}`}
              />
              <div className={`requests-list__header__label__name${extraBig}`}>
                { title }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DashboardListHeader.propTypes = IPropTypes;
DashboardListHeader.defaultProps = defaultProps;

export { DashboardListHeader };
