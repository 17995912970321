import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PatientSummary } from '../../../models/patient-summary';
import styles from './patient-name-container.module.scss';
import { PatientSummarySection } from '../patient-summary-section';

const IPropTypes = {
  data: PropTypes.instanceOf(PatientSummary),
  isAdminLogged: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: null,
};

const PatientNameContainer = ({ data, isAdminLogged }) => {
  if (!data) {
    return null;
  }

  const [showModal, setShowModal] = useState(false);

  const renderPatientName = () => {
    const buttonStyle = [styles.button, showModal ? styles.activeButton : '', isAdminLogged ? styles.adminButton : ''].join(' ');
    return (
      <button
        type="button"
        onClick={() => (data.type === 'Floating' ? setShowModal(!showModal) : '')}
        className={[buttonStyle, data.type === 'Open' ? styles.titleLabel : ''].join('')}
      >
        <div>{data.name}</div>
        {data.type === 'Floating' && (
          showModal
            ? <i className="fa fa-angle-up" />
            : <i className="fa fa-angle-down" />)
        }
      </button>
    );
  };

  return (
    <div className={styles.container}>
      {renderPatientName()}
      {data.type === 'Floating' && showModal
        && <PatientSummarySection data={data} isAdminLogged={isAdminLogged} />}
    </div>
  );
};

PatientNameContainer.propTypes = IPropTypes;
PatientNameContainer.defaultProps = defaultProps;

export { PatientNameContainer };
