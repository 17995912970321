import { connect } from 'react-redux';

import { PatientInformationCollapsed } from './patient-information-collapsed';

const mapStateToProps = ({ orders: { activeOrder: { patientInfo, formErrors } } }) => ({
  patientStore: patientInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const ConnectedPatientInformationCollapsed = connect(mapStateToProps)(PatientInformationCollapsed);

export { ConnectedPatientInformationCollapsed as PatientInformationCollapsed };
