class AccountSerializer {
  static deserialize = (account) => {
    if (!account) {
      return {};
    }

    const { address = {} } = account;

    return {
      clientAccount: account.accountNumber,
      client: account.practiceName,
      clientPhone: account.phone,
      clientFax: account.fax,
      clientCountry: address.country,
      clientState: address.state,
      clientCity: address.city,
      clientAddress1: address.address1,
      clientZipCode: address.zipCode,
      clientAddress2: address.address2,
    };
  }

  static serialize = physicianInfo => ({
    accountNumber: physicianInfo.clientAccount,
    practiceName: physicianInfo.client,
    phone: physicianInfo.clientPhone,
    fax: physicianInfo.clientFax,
    address: {
      address1: physicianInfo.clientAddress1,
      address2: physicianInfo.clientAddress2,
      city: physicianInfo.clientCity,
      state: physicianInfo.clientState,
      country: physicianInfo.clientCountry,
      zipCode: physicianInfo.clientZipCode,
    },
  })
}

export { AccountSerializer };
