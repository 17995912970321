import Validation, { isEmail } from '../../admin/common/utils/Validation';
import {isString} from 'lodash';
import ValidationJson from '../validate.json';

/* eslint-disable max-len */
export const isValidPatientRequiredFields = (errors = {}) => errors.firstName === 'Required' || errors.lastName === 'Required' || errors.hospitalizationStatus === 'Required' || errors.sex === 'Required' || errors.dob === 'Required';
export const isValidDiagnosisRequiredFields = (errors = {}) => errors.disease === 'Required' || errors.primaryIcdCode === 'Required' || errors.stage === 'Required';
export const isValidTestSelectionRequiredFields = (errors = {}) => errors.testOrdered === 'Please enter a valid value' || errors.productFamily === 'Please enter a valid value';
export const isValidClinicalHistoryRequiredFields = (errors = {}) => errors.dateOfSurgicalResection === 'Required';
export const isValidPhysicianRequiredFields = (errors = {}) => errors.client === 'Required' || errors.authorizingProvider === 'Required';

export const allowReveal = (stage, disease) => {
  return isString(stage) && isString(disease) && ['STAGE II', 'STAGE III'].includes(stage.toUpperCase()) &&
    ['COLORECTAL ADENOCARCINOMA', 'BREAST CARCINOMA', 'SMALL CELL LUNG CARCINOMA',
      'LUNG SQUAMOUS CELL CARCINOMA', 'LARGE CELL LUNG CARCINOMA', 'LUNG ADENOCARCINOMA'].includes(disease.toUpperCase());
};

export const allowG360 = (stage, disease) => {
  return isString(stage) && isString(disease) && ['STAGE III', 'STAGE IV'].includes(stage.toUpperCase());
};

export const getRootDisease = (disease, cancerTypeList) => {
  if(!disease) return undefined;
  if(!cancerTypeList) return {diagnosis: disease};
  let curr = cancerTypeList.find(i => i.diagnosis.toUpperCase() === disease.toUpperCase());
  while(curr && curr.parentId){
    curr = cancerTypeList.find(i => i.id === curr.parentId);
  }
  return curr;
};

export const getChildDiseases = (disease, cancerTypeList) => {
  const rootDiseaseId = getRootDisease(disease, cancerTypeList)?.id;
  return cancerTypeList?.filter(e => e.parentId === rootDiseaseId);
};

export const getDisease = (diagnosis, cancerTypeList) => {
  let {
    disease,
    diagnosisOther,
  } = diagnosis || {};
  disease = getRootDisease(disease, cancerTypeList)?.diagnosis;
  if (disease === 'Other' && !diagnosisOther) {
    return undefined;
  }
  return disease;
};

export const getPatientInfoStatus = (patient, currentTab, reviewModal, errors = {}) => {
  let status;
  const {
    demographic,
    type,
    identifiers,
    hospitalizationStatus,
  } = patient || {};
  const { id } = identifiers ? identifiers[0] : {};
  const {
    firstName, lastName, dob = '', phone, sex, address, email = '',
  } = demographic || {};
  const {
    address1, address2, city, state, zipCode,
  } = address || {};
  const isDobValid = (parseInt(dob?.slice(0, 4), 10) >= 1900);
  const isEmailValid = isEmail?.test(email);
  if (currentTab === 0 && !reviewModal) {
    status = 'Current';
  } else if (!firstName && !lastName && !dob && !hospitalizationStatus && !sex && !phone && !email && !address1 && !city && !state && !zipCode && !address2 && !type && !id) {
    status = 'Not Started';
  } else if (isValidPatientRequiredFields(errors) || ((currentTab !== 0 || reviewModal) && !(firstName && lastName && dob && hospitalizationStatus && sex))) {
    status = 'Required';
  } else if (firstName && lastName && dob && isDobValid && hospitalizationStatus && sex && phone && (phone?.length === 10) && email && isEmailValid && address1 && city && state && zipCode && (zipCode?.trim().length === 5) && type && id) {
    status = 'Complete';
  } else if (!firstName || !lastName || !dob || !isDobValid || !hospitalizationStatus || !sex || !phone || !(phone?.length === 10) || !email || !isEmailValid || !address1 || !city || !state || !zipCode || !(zipCode?.trim().length === 5) || !type || !id) {
    status = 'Incomplete';
  } else {
    status = 'Not Started';
  }
  return status;
};

export const getPhysicianInfoStatus = (order, currentTab, reviewModal) => {
  let status;
  const {
    authorizingProvider,
    secondaryRecipient,
    client,
  } = order;
  const { firstName: physicianFirstName = '', lastName: physicianLastName = '' } = authorizingProvider || {};
  const {
    firstName: secondaryRecipeintFName = '', phone: secondaryRecipeintPhone = '', fax: secondaryRecipeintFax = '',
  } = secondaryRecipient || {};
  const { practiceName } = client || {};
  const isPhoneValid = (secondaryRecipeintPhone?.length === 10);
  const isFaxValid = (secondaryRecipeintFax?.length === 10);
  if ((currentTab !== 4 || reviewModal) && !physicianFirstName && practiceName) {
    status = 'Required';
  } else if (currentTab === 4 && !reviewModal) {
    status = 'Current';
  } else if (physicianFirstName && physicianLastName && practiceName
    && ((secondaryRecipeintFName && !(secondaryRecipeintPhone && secondaryRecipeintFax && isPhoneValid && isFaxValid))
    || (secondaryRecipeintPhone && !(secondaryRecipeintFName && secondaryRecipeintFax && isPhoneValid && isFaxValid))
    || (secondaryRecipeintFax && !(secondaryRecipeintPhone && secondaryRecipeintFName && isPhoneValid && isFaxValid)))) {
    status = 'Incomplete';
  } else if (!physicianFirstName && !physicianLastName && !practiceName) {
    status = 'Not Started';
  } else if (physicianFirstName && physicianLastName && practiceName) {
    status = 'Complete';
  } else if (!physicianFirstName || !physicianLastName || !practiceName) {
    status = 'Incomplete';
  } else {
    status = 'Not Started';
  }
  return status;
};

export const getBillingInfoStatus = (billing, infoType, currentTab, reviewModal) => {
  let status;
  const {
    primaryInsurance,
  } = billing;
  const {
    insuredRelationship = '',
    billingIndicator = '',
    company = '',
    subscriberFirstName = '',
    subscriberLastName = '',
    policyNumber = '',
    groupNumber = '',
    dob = '',
  } = primaryInsurance || {};
  const isDobValid = (parseInt(dob?.slice(0, 4), 10) >= 1900);
  if (currentTab === 5 && !reviewModal) {
    status = 'Current';
  } else if (infoType === 'first' && insuredRelationship && billingIndicator && company && subscriberFirstName && subscriberLastName && policyNumber && groupNumber && dob && isDobValid) {
    status = 'Complete';
  } else if (infoType === 'first' && (insuredRelationship || billingIndicator || company || subscriberFirstName || subscriberLastName || policyNumber || groupNumber || dob || isDobValid)) {
    status = 'Incomplete';
  } else if (infoType === 'second') {
    status = 'Complete';
  } else if (infoType === 'first') {
    status = 'Incomplete';
  } else {
    status = 'Not Started';
  }
  return status;
};

export const getDiagnosisStatus = (diagnosis, currentTab, reviewModal, cancerTypeList) => {
  let status;
  const {
    disease,
    primaryIcdCode,
    stage,
    dateOfOriginalDiagnosis,
    secondaryIcdCodes,
  } = diagnosis || {};
  const secondaryCodes = secondaryIcdCodes && secondaryIcdCodes.filter(item => item).length >= 4;

  if (currentTab === 1 && !reviewModal) {
    status = 'Current';
  } else if (!disease && !primaryIcdCode && !stage && !dateOfOriginalDiagnosis) {
    status = 'Not Started';
  } else if ((
    (currentTab !== 1 || reviewModal)
    && (getDisease(diagnosis, cancerTypeList) || primaryIcdCode || stage || dateOfOriginalDiagnosis || secondaryCodes)
    && (!disease || !primaryIcdCode || !stage || !getDisease(diagnosis, cancerTypeList)))) {
    status = 'Required';
  } else if (!disease || !primaryIcdCode || !stage || (dateOfOriginalDiagnosis && parseInt(dateOfOriginalDiagnosis?.slice(0, 4), 10) < 1900)) {
    status = 'Incomplete';
  } else if (getDisease(diagnosis, cancerTypeList) && primaryIcdCode && stage) {
    status = 'Complete';
  } else {
    status = 'Not Started';
  }
  return status;
};


const inRangeInclusive = (input, start, end) => {
  if(Number.isNaN(+input)) return false;
  const number = parseFloat(input);
  return number >= start && number <= end;
};
export const getTestSelectionStatus = (order, currentTab, reviewModal, cancerTypeList) => {
  let status;
  const {
    testOrdered,
    diagnosis,
    productFamily = {},
    specimens,
  } = order || {};
  const { name = [], reorderType = {} } = testOrdered || {};
  const { stage } = diagnosis || {};
  const { tissueSpecimen = {} } = specimens || {};
  const {
    pathologyLabName = '',
    fax = '',
    phone = '',
    tissueCollectionDate = '',
  } = tissueSpecimen || {};
  const { interval = {}, id: reorderTypeId = '' } = reorderType || {};
  const { id: intervalId = '', value: intervalValue = '' } = interval || {};

  if (currentTab !== 2 && name.length === 0 && !allowReveal(stage, getDisease(diagnosis, cancerTypeList)) && !allowG360(stage, getDisease(diagnosis, cancerTypeList))) {
    status = 'Requires Diagnosis';
  } else if (currentTab !== 2 && !productFamily?.id && testOrdered?.name.length === 0 && testOrdered?.followOnTestOrdered !== 'G360MR') {
    status = 'Not Started';
  } else if ((currentTab !== 2 || reviewModal)
    && productFamily?.id && ((testOrdered?.name.length === 0 && testOrdered?.followOnTestOrdered !== 'G360MR')
      || !isValid(name, reorderTypeId, intervalId, intervalValue, testOrdered?.preferenceForBloodDraw))){
    status = 'Required';
  } else if (currentTab === 2 && !reviewModal) {
    status = 'Current';
  } else if (name.length > 0 && name.includes('TG') && !(pathologyLabName && fax && fax.length === 10 && phone && phone.length === 10 && tissueCollectionDate && (parseInt(tissueCollectionDate?.slice(0, 4), 10) >= 1900))) {
    status = 'Incomplete';
  } else if (name.length > 0 || testOrdered?.followOnTestOrdered === 'G360MR') {
    status = 'Complete';
  } else if (productFamily?.id && testOrdered?.name.length === 0) {
    status = 'Incomplete';
  } else {
    status = 'Not Started';
  }
  return status;
};

const isValid = (codes = [], reorderTypeId, intervalId, intervalValue, preferenceForBloodDraw) => {
  if(["LUNAR1-CRC-P", "LUNAR1-CRC-S", "LUNAR1-CRC-O"].includes(codes[0])){
    return true;
  }
  let valid = true;
  if(codes.includes("GUARDANT_REVEAL")){
    if(['P', 'O', 'S'].includes(reorderTypeId) && preferenceForBloodDraw){
      if(reorderTypeId === 'S'){
        if(['3MONTH', '6MONTH', 'NMONTH'].includes(intervalId)){
          if(intervalId === 'NMONTH'){
            valid = inRangeInclusive(intervalValue, 4, 12);
          }
        }else{
          valid = false;
        }
      }
    }else{
      valid = false;
    }
  }
  return valid;
}

const allFalsy = (...vars) => {
  return !vars.reduce((acc, val) => acc || val, false);
};

export const getClinicalHistoryStatus = (order, currentTab, reviewModal, cancerTypeList) => {
  const {
    productFamily = {},
    relevantClinicalHistory = [],
    advancedCancerStage = {},
    testOrdered = {},
    diagnosis = {},
  } = order;
  const {
    dateOfAdjuvantChemotherapy = null,
    noAdjuvantTherapy,
    disease,
    molecularMarker,
    diagnosisOther,
  } = diagnosis || {};

  let isSubDiagnosis = true;
  const childDiseases = getChildDiseases(disease, cancerTypeList);

  if (childDiseases?.length > 0) {
    const isLeaf = cancerTypeList?.filter(i => i.diagnosis.toUpperCase() === disease?.toUpperCase() && i.parentId).length === 1;
    if (!(isLeaf || molecularMarker)) {
      isSubDiagnosis = false;
    }
    if(isLeaf && disease?.toUpperCase().indexOf("OTHER") === 0) {
      if(!diagnosisOther) {
        isSubDiagnosis = false;
      }
    }
  }

  const isG360Complete = relevantClinicalHistory.length === 5
    && ((relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue === 'Not responding to therapy'
      && (advancedCancerStage?.targetedTherapy
        || advancedCancerStage?.immunotherapy
        || advancedCancerStage?.combinationTherapy
        || advancedCancerStage?.chemotherapy
        || advancedCancerStage?.specificTherapy)
        && advancedCancerStage?.dateOfTherapyInitiation
      && !Validation('dateOfTherapyInitiation', advancedCancerStage?.dateOfTherapyInitiation, ValidationJson.clinicalHistory))
      || relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue === 'Newly diagnosed (Stage III/IV)');


  let status = "Complete";
  if(!reviewModal && currentTab === 3){
    status = 'Current';
  }else if (testOrdered?.name.length === 0 && testOrdered?.followOnTestOrdered !== 'G360MR') {
    status = 'Requires Test Selection';
  }else if(getRootDisease(disease, cancerTypeList)?.diagnosis === disease && !molecularMarker
    && (noAdjuvantTherapy === undefined || noAdjuvantTherapy === null) && !testOrdered?.dateOfSurgicalResection && relevantClinicalHistory.length === 0
    && allFalsy(...[advancedCancerStage.targetedTherapy, advancedCancerStage.immunotherapy, advancedCancerStage.combinationTherapy, advancedCancerStage.chemotherapy
      , advancedCancerStage.specificTherapy, advancedCancerStage.dateOfTherapyInitiation])){
    status = 'Not Started';
  }else if ((testOrdered.name.includes("GUARDANT_REVEAL") && (!testOrdered?.dateOfSurgicalResection || !isSubDiagnosis || ((noAdjuvantTherapy === undefined || noAdjuvantTherapy === null) || (noAdjuvantTherapy === false && !dateOfAdjuvantChemotherapy))))
    || (["LUNAR1-CRC-P", "LUNAR1-CRC-S", "LUNAR1-CRC-O"].includes(testOrdered.name[0]) && !testOrdered?.dateOfSurgicalResection)) {
    status = 'Required';
  }else if((testOrdered.name.includes("GUARDANT_REVEAL") && relevantClinicalHistory.length < 3)
    || (["LUNAR1-CRC-P", "LUNAR1-CRC-S", "LUNAR1-CRC-O"].includes(testOrdered.name[0]) && !dateOfAdjuvantChemotherapy && relevantClinicalHistory.length < 3)
    || (productFamily?.id === 'G360' && !isG360Complete)){
    status = 'Incomplete';
  }
return status;





  /*if ((currentTab !== 3 || reviewModal) && productFamily?.id === 'REVEAL' && testOrdered?.name.length !== 0
    && (!testOrdered?.dateOfSurgicalResection || !isSubDiagnosis || ((noAdjuvantTherapy === undefined || noAdjuvantTherapy === null) || (noAdjuvantTherapy === false && !dateOfAdjuvantChemotherapy)))) {
    status = 'Required';
  } else if (currentTab === 3 && !reviewModal) {
    status = 'Current';
  } else if ((productFamily?.id === 'REVEAL' && relevantClinicalHistory.length >= 3)
      || (productFamily?.id === 'G360' && relevantClinicalHistory.length >= 5
      && ((relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue === 'Not responding to therapy' && (advancedCancerStage?.targetedTherapy || advancedCancerStage?.immunotherapy || advancedCancerStage?.combinationTherapy || advancedCancerStage?.chemotherapy || advancedCancerStage?.specificTherapy) && advancedCancerStage?.dateOfTherapyInitiation)
      || (relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue === 'Newly diagnosed (Stage III/IV)')))) {
    if (productFamily?.id === 'REVEAL'
      && (!testOrdered?.dateOfSurgicalResection || !isSubDiagnosis || ((noAdjuvantTherapy === undefined || noAdjuvantTherapy === null) || (noAdjuvantTherapy === false && !dateOfAdjuvantChemotherapy)))) {
      status = 'Required';
    // } else if (moment(advancedCancerStage?.dateOfTherapyInitiation, 'YYYY-MM-DD').year() < 1900) {
    } else if ((parseInt(advancedCancerStage?.dateOfTherapyInitiation?.slice(0, 4), 10) < 1900)) {
      status = 'Incomplete';
    } else {
      status = 'Complete';
    }
  } else if ((productFamily?.id === 'REVEAL' && ((relevantClinicalHistory.length <= 3 && relevantClinicalHistory.length >= 1) || testOrdered?.dateOfSurgicalResection))
  || (productFamily?.id === 'G360' && ((relevantClinicalHistory.length <= 5 && relevantClinicalHistory.length >= 1) || advancedCancerStage?.targetedTherapy || advancedCancerStage?.immunotherapy || advancedCancerStage?.combinationTherapy || advancedCancerStage?.chemotherapy || advancedCancerStage?.specificTherapy || advancedCancerStage?.dateOfTherapyInitiation || (parseInt(advancedCancerStage?.dateOfTherapyInitiation?.slice(0, 4), 10) < 1900)))) {
    status = 'Incomplete';
  } else if (testOrdered?.name.length === 0 && testOrdered?.followOnTestOrdered !== 'G360MR') {
    status = 'Requires Test Selection';
  } else {
    status = 'Not Started';
  }
  return status;*/
};

export const getClinicalHistory = (relevantClinicalHistory = [], productFamily) => {
  let cilinicalDisplay = '';
  if (productFamily && productFamily === 'G360') {
    cilinicalDisplay = relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue;
  } if (productFamily && productFamily === 'REVEAL') {
    if (relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue === 'Yes') {
      cilinicalDisplay = 'Currently on therapy';
    } else if (relevantClinicalHistory?.filter(item => item.questionNumber === 1)[0]?.answerValue === 'No') {
      cilinicalDisplay = 'Not Currently on therapy';
    }
  }
  return cilinicalDisplay;
};

export const getUserRole = () => {
  const userInfo = localStorage.getItem('userInfo');
  return JSON.parse(userInfo);
};
