import { TrialSiteSerializer } from './trial-site';

class ClinicalTrialSerializer {
  static deserialize(data) {
    return {
      id: data.trialId,
      contact: data.contact,
      phase: data.phase,
      title: data.title,
      url: data.url,
      sites: (data.sites || []).map(TrialSiteSerializer.deserialize),
    };
  }
}

export { ClinicalTrialSerializer };
