import React from 'react';
import PropTypes from 'prop-types';

const Welcome = (props) => {
  const {
    isPharmaWelcome,
    rootPath,
    signUpPath,
    myGuardantSignUpPath,
    pharmaSignUpPath,
  } = props;
  return (
    <div className="php_landing_container">
      <a href="/">
        <div className="logo__gh-logo" />
      </a>
      <div className="php_landing__trm-img" />
      <div className="header">Sign up for a Guardant Account</div>
      <div className="php_landing__grey-box_container">
        <div className="grey-box__text">Are you a provider or patient?</div>
        <div className="buttons-row margin-bottom__20-px">
          <a className="btn__size-large btn__color-blue" href={signUpPath}>PROVIDER</a>
        </div>
        <div className="buttons-row margin-bottom__20-px">
          <a className="btn__size-large btn__color-blue" href={myGuardantSignUpPath}>PATIENT</a>
        </div>
        {
          isPharmaWelcome && (
          <div className="buttons-row margin-bottom__20-px">
            <a className="btn__size-large btn__color-blue" href={pharmaSignUpPath}>PHARMA PARTNER</a>
          </div>
          )
        }
      </div>
      <div className="php_landing__page__text">
        <div className="php_landing__page__text-desc">
          Already have an account? &nbsp;
        </div>
        <div className="php_landing__page__text-link bold">
          <a href={rootPath}>Sign in</a>
        </div>
      </div>
    </div>
  );
};
export default Welcome;
Welcome.propTypes = {
  isPharmaWelcome: PropTypes.bool.isRequired,
  rootPath: PropTypes.string.isRequired,
  signUpPath: PropTypes.string.isRequired,
  myGuardantSignUpPath: PropTypes.string.isRequired,
  pharmaSignUpPath: PropTypes.string.isRequired,
};
