import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updatePatientEmail } from '../service/PatientService';


const IPropTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
const defaultProps = {
  showModal: false,
};

const EmailUpdateModal = (props) => {
  const {
    showModal,
    closeModal,
    user: { email: currentEmail },
  } = props;
  const [email, setEmail] = useState(currentEmail);

  const savePatientEmail = () => {
    const params = { email };
    updatePatientEmail(params, closeModal);
  };
  return (
    <>
      {
        showModal && (
          <div className="modal__container-outer modal__patient edit__email">
            <div className="modal__container-inner">
              <div className="modal__content">
                <div className="patient__settings-edit patient__settings-edit__email">
                  <div className="settings-edit__container">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="myguardant-form">Your email address</label>
                    <p className="help-block">
                      Please enter your email address. We will use this email address to contact
                      you, and you will need to enter this email address when you sign
                      in to myGuardant
                    </p>
                    <div className="content">
                      <input
                        className="input__email"
                        id="input__email"
                        placeholder="Email"
                        type="text"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                      />
                    </div>
                    <div className="buttons-row">
                      <div className="button button-clear-background close__edit__email" onClick={() => closeModal('email')}>CANCEL</div>
                      <div className="button form-submit__btn long_button save__edit__email" onClick={savePatientEmail}>CHANGE YOUR EMAIL</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};
export default EmailUpdateModal;
EmailUpdateModal.propTypes = IPropTypes;
EmailUpdateModal.defaultProps = defaultProps;
