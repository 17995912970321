/*
data fetched from clinical API is returned as an array of arrays without keys.
[
  [1, 'GHI-100', Stanford],
  [2, 'GHI-200', UCLA]
]
We need to transform the data into an array of objects with keys "id", "title", and "selected"
in order to feed the data into the reusable Search component.
[
  {id: 1, title: 'GHI-100 Stanford', selected: false}
  {id: 2, title: 'GHI-200 UCLA', selected: false}
]
*/

export const mapArrayForReusableSearch = (data) => {
  const result = [];
  for (let i = 0; i < data.length; i += 1) {
    const obj = {};
    const newData = { ...data };
    [obj.id, newData.ghi, newData.practiceName] = data[i];
    obj.title = `${newData.ghi} - ${newData.practiceName}`;
    obj.selected = false;
    obj.key = 'physicianAdmin';
    result.push(obj);
  }
  return result;
};
