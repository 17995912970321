import { DownloadLinksSerializer } from './download-links';

export class DownloadReportsLinksSerializer {
  static deserialize = links => (
    links && {
      bloodCollectionDatePerRequest: links.blood_coll_date_per_request,
      linksPerRequest: Object.entries(links.links_per_request).reduce((acc, [name, link]) => {
        acc[name] = link.map(DownloadLinksSerializer.deserialize);
        return acc;
      }, {}),
    }
  );
}
