import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { store } from '../../store';

import { LunarPatientPageContainer } from './LunarPatientPageContainer';
import { TestsDropdown } from '../../models/tests-dropdown';
import { TestsDropdownSerializer } from '../../networking/serializers/tests-dropdown';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canDownloadReport: PropTypes.bool.isRequired,
  downloadReportEnabled: PropTypes.bool.isRequired,
  canShareReport: PropTypes.bool.isRequired,
  shareReportEnabled: PropTypes.bool.isRequired,
  canReleaseReport: PropTypes.bool.isRequired,
  releaseReportEnabled: PropTypes.bool.isRequired,
  testsDropdown: PropTypes.shape({
    selected_dropdown_name: PropTypes.string.isRequired,
    error_handling_support: PropTypes.bool.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
      display_name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })),
    dropdown_options: PropTypes.arrayOf(PropTypes.shape({
      dropdown_display_name: PropTypes.string.isRequired,
      redirect_to: PropTypes.string.isRequired,
    })).isRequired,
  }),
};
const defaultProps = {
  testsDropdown: null,
};

const LunarRootContainer = ({
  canDownloadReport, downloadReportEnabled, canShareReport, shareReportEnabled,
  canReleaseReport, releaseReportEnabled, authenticityToken, testsDropdown,
}) => {
  const dropdownModel = testsDropdown && new TestsDropdown(
    TestsDropdownSerializer.deserialize(testsDropdown),
  );
  return (
    <Provider store={store}>
      <LunarPatientPageContainer
        canDownloadReport={canDownloadReport}
        downloadReportEnabled={downloadReportEnabled}
        canShareReport={canShareReport}
        shareReportEnabled={shareReportEnabled}
        canReleaseReport={canReleaseReport}
        releaseReportEnabled={releaseReportEnabled}
        authenticityToken={authenticityToken}
        testsDropdown={dropdownModel}
      />
    </Provider>
  );
};

LunarRootContainer.propTypes = IPropTypes;
LunarRootContainer.defaultProps = defaultProps;

export default LunarRootContainer;
