import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleRevoke from './ToggleRevoke';
import Share from '../../Share';
import { getStatusMessage } from '../../helpers';

const ShareIndexCollaborator = (props) => {
  const {
    leftColName,
    leftColEmail,
    rightColName,
    sharingTitle,
    shareDescription,
    share,
    sharee,
    sharer,
    accessClass,
    collabShares,
  } = props;

  const [showStatus, setShowStatus] = useState(true);
  const showShareStatus = (status) => {
    if (status === true) {
      setShowStatus(true);
    } else {
      setShowStatus(false);
    }
  };
  const handlePermissionClick = (event) => {
    event.stopPropagation();
    const target = window.$(event.target).closest('.share-index__collaborator__top');
    window.$(target).find('.share-index__collaborator__left-col__email').toggle();
    window.$(target).find('.share-index__count-of-shares').toggle();
    window.$(target).next().slideToggle();
  };

  return (
    <div className="share-index__collaborator">
      <div className="share-index__collaborator__top" onClick={handlePermissionClick}>
        <div className="share-index__collaborator__top-inner">
          <div className="share-index__collaborator__left-col">
            <div className="share-index__collaborator__left-col__name">
              {leftColName}
              {!leftColName.includes('@') && <div className="share-index__collaborator__left-col__email">{leftColEmail}</div>}
            </div>
          </div>
          <div className="share-index__collaborator__right-col">
            <div className="share-index__count-of-shares">{rightColName}</div>
          </div>
        </div>
      </div>
      <div className="share-index__collaborator__bottom">
        {
          collabShares
            ? (
              <>
                {
                collabShares.yours
                  ? (
                    <>
                      <div className="share-index__you-sharing__title">{sharingTitle}</div>
                      {
                        collabShares.yours.map(item => (
                          <Share
                            key={item.id}
                            share={item}
                            sharees={collabShares.sharees}
                            sharers={collabShares.sharers}
                            physicians={collabShares.physicians || null}
                            sharedWithYou={false}
                            accessClass={accessClass}
                          />
                        ))
                      }
                    </>
                  ) : (
                    <>
                      <div className="share-index__you-sharing__title">{sharingTitle}</div>
                      {
                        collabShares.theirs.map(item => (
                          <Share
                            key={item.id}
                            share={item}
                            sharees={collabShares.sharees}
                            sharers={collabShares.sharers}
                            physicians={collabShares.physicians || null}
                            sharedWithYou
                            accessClass={accessClass}
                          />
                        ))
                      }
                    </>
                  )
              }
              </>
            ) : (
              <>
                {sharingTitle && <div className="share-index__you-sharing__title">{sharingTitle}</div> }
                <div className="share-created">
                  <div className="share-created__patient">
                    <div className="share-created__text__wide">
                      <div className="share-created__text-icon-report" />
                      {shareDescription && (
                      <div className="share-created__text-description">
                        {shareDescription}
                      </div>
                      )}
                    </div>
                    {share && (
                    <React.Fragment>
                      {showStatus && <div className="share-created__status">{getStatusMessage(share)}</div>}
                      <ToggleRevoke
                        share={share}
                        accessClass={accessClass}
                        sharees={sharee}
                        sharers={sharer}
                        showShareStatus={showShareStatus}
                      />
                    </React.Fragment>
                    )
                }
                  </div>
                </div>
              </>
            )
        }
        <div className="share-index__spacing" />
      </div>
    </div>
  );
};
export default ShareIndexCollaborator;
ShareIndexCollaborator.propTypes = {
  leftColName: PropTypes.string,
  leftColEmail: PropTypes.string,
  rightColName: PropTypes.string,
  sharingTitle: PropTypes.string,
  shareDescription: PropTypes.string,
  share: PropTypes.object,
  accessClass: PropTypes.string,
  collabShares: PropTypes.object,
  sharee: PropTypes.any,
  sharer: PropTypes.any,
};
ShareIndexCollaborator.defaultProps = {
  leftColName: '',
  leftColEmail: '',
  rightColName: '',
  sharingTitle: null,
  shareDescription: null,
  share: null,
  accessClass: '',
  collabShares: null,
  sharee: '',
  sharer: '',
};
