import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box, Divider, Typography,
} from '@mui/material';

const IPropTypes = {
  message: PropTypes.string.isRequired,
  showMessage: PropTypes.bool.isRequired,
  closeMessage: PropTypes.func.isRequired,
};

const AlertMessage = ({ message, closeMessage, showMessage = false }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={showMessage}
        onClose={closeMessage}
        aria-labelledby="Message window"
      >
        <DialogTitle id="responsive-dialog-title" />
        <DialogContent sx={{ padding: '30px 30px', textAlign: 'center' }}>
          <DialogContentText>
            <Typography component="div" variant="body1">{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box sx={{
            display: 'flex', justifyContent: 'center', width: '100%', padding: '10px 0',
          }}
          >
            <Button variant="outlined" onClick={closeMessage}>
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertMessage;
AlertMessage.propTypes = IPropTypes;
