import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { NewStudyManagerSignup } from './new-study-manager-signup';
import { addBannerMessages } from '../../../store/actions/global';

const mapDispatchToProps = dispatch => bindActionCreators({
  addBannerMessages,
}, dispatch);


const ConnectedNewStudyManagerSignup = connect(null, mapDispatchToProps)(NewStudyManagerSignup);

export { ConnectedNewStudyManagerSignup as NewStudyManagerSignup };
