import React from 'react';
import PropTypes from 'prop-types';

import { ElectronicSignatureConfirmationOverlay } from '../electronic-signature-confirmation-overlay';
import { logger } from '../../../util/logger';
import { Order } from '../../../models/order/order';

const IPropTypes = {
  serverAction: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  order: PropTypes.instanceOf(Order),
  emrUser: PropTypes.bool.isRequired,
  saveGenericInfo: PropTypes.func.isRequired,
  showElectronicSignatureConfirmationOverlay: PropTypes.bool.isRequired,
  updateDashboard: PropTypes.func.isRequired,
  updateLoadingCount: PropTypes.number.isRequired,
};

const ElectronicSignatureConfirmationContainer = ({
  emrUser,
  order,
  serverAction,
  onError,
  saveGenericInfo,
  showElectronicSignatureConfirmationOverlay,
  updateDashboard,
  updateLoadingCount,
}) => {
  if (!order) {
    return null;
  }

  const handleConfirmation = async () => {
    updateLoadingCount(1);

    try {
      const { order: finalizedOrder } = await serverAction();

      if (emrUser) {
        window.close();
        return;
      }

      saveGenericInfo({
        trfCompleted: true,
        successMessage: `${finalizedOrder.physicianInfo.formatFullName()} has been sent a notification for electronic signature.`,
      });

      updateDashboard(order.id ? order : null, finalizedOrder);
    } catch (error) {
      logger.warn(error);
      onError();
    } finally {
      updateLoadingCount(-1);
    }
  };

  return showElectronicSignatureConfirmationOverlay && (
    <ElectronicSignatureConfirmationOverlay
      onConfirm={handleConfirmation}
      physicianName={order.physicianInfo.formatFullName()}
    />
  );
};


ElectronicSignatureConfirmationContainer.propTypes = IPropTypes;

export { ElectronicSignatureConfirmationContainer };
