/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { isInternalUser } from '../networking/controllers/helper';
import { config } from './config/config';
import {
  API_GATEWAY_URL,
  USER_MGMT_V2_PATH,
} from './config';

const IPropTypes = {
  user_id: PropTypes.number.isRequired,
  form_authenticity_token: PropTypes.string.isRequired,
  notification: PropTypes.string.isRequired,
  error: PropTypes.any,
  current_user_delegator: PropTypes.object.isRequired,
};
const defaultProps = {
  error: '',
};
const Settings = (props) => {
  const [state, setState] = useState({
    switch: false,
    tooltip: false,
    currentPassword: '',
    newPassword: '',
    currentPassError: '',
    newPassError: '',
    displayMessage: false,
  });

  const handleTooltip = () => {
    setState(previous => ({ ...previous, tooltip: !previous.tooltip }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState(previous => ({
      ...previous,
      currentPassError:
          previous.currentPassword.length !== 0 ? '' : previous.currentPassError,
      [name]: value,
    }));
  };
  const validate = (fieldValue = state, submit = false) => {
    if (submit === true) {
      if (
        fieldValue.newPassword.length === 0
        || fieldValue.currentPassword.length === 0
      ) {
        setState(previous => ({
          ...previous,
          newPassError:
              fieldValue.newPassword.length === 0
                ? 'Please fill the new password'
                : previous.newPassError,
          currentPassError:
              fieldValue.currentPassword.length === 0
                ? 'Please fill the current password'
                : '',
        }));
      }
    }
    if (submit === false && fieldValue.newPassword.length === 0) {
      setState(previous => ({ ...previous, newPassError: '' }));
      return;
    }
    const isPassword = /(?=[A-Za-z0-9]+)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,}).*$/g;
    const samePass = 'New password can not be same as that of current password';
    if (submit === false && !isPassword.test(fieldValue.newPassword)) {
      setState(previous => ({
        ...previous,
        newPassError:
            fieldValue.newPassword !== state.currentPassword
              ? 'Password must contain at least 12 characters, a lowercase letter, an uppercase letter, a number'
              : samePass,
      }));
    } else if (submit === false) {
      setState(previous => ({
        ...previous,
        newPassError:
              fieldValue.newPassword !== state.currentPassword ? '' : samePass,
      }));
    }
  };
  const FocusOutHandler = (e) => {
    const { name, value } = e.target;
    validate({ [name]: value });
  };
  const submitHandler = async (fieldValues = state) => {
    validate(undefined);
    if (
      state.currentPassword.length !== 0
      && state.newPassError === ''
      && state.newPassword.length !== 0
    ) {
      window.onbeforeunload();
      const { accessToken } = JSON.parse(
        localStorage.getItem('okta-token-storage'),
      ).accessToken;
      const data = JSON.stringify({
        oldPassword: `${fieldValues.currentPassword}`,
        newPassword: `${fieldValues.newPassword}`,
      });
      const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
      const axiosConfig = {
        method: 'put',
        url: `${config()[API_GATEWAY_URL]}${config()[USER_MGMT_V2_PATH]}/users/me/change_password`,
        headers: {
          Accept: '*',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${accessToken}`,
          ...extHeader,
        },
        data,
      };
      await axios(axiosConfig)
        .then(() => {
          window.hideSpinner();
          setState(previous => ({
            ...previous,
            currentPassword: '',
            newPassword: '',
            displayMessage: true,
          }));
        })
        .catch((error) => {
          const errorMsg = error.response.data.errors[0].message;
          setState(previous => ({ ...previous, newPassError: errorMsg }));
          window.hideSpinner();
        });
    }
  };
  const {
    user_id: userId,
    form_authenticity_token: formAuthenticityToken,
    notification,
    error,
    current_user_delegator: currentUserDelegator,
  } = props;

  return (
    <>
      <div className="container container--breathable">
        {state.displayMessage && (
          <div className="flash-alert-container">
            <div
              className="close-flash-alert fa fa-close"
              onClick={() => {
                setState({ ...state, displayMessage: false });
              }}
            />
            <div className="alert alert--success">
              <div className="message">Your password has been updated.</div>
            </div>
          </div>
        )}
        <div className="settings-header-container">
          <a
            className="page-back-button-grey page-button-grey no-spinner"
            href="/"
          >
            <i className="fa fa-angle-left" />
            {' '}
            BACK
          </a>
          <h1 className="settings__page">Settings</h1>
        </div>
        <div className="settings__container">
          <div className="settings__item__container">
            <div className="settings__header">NOTIFICATIONS</div>
            <div className="content-divider-line-settings" />
            <div className="settings-content-container">
              <div className="settings__description">
                Send me email notifications when new reports are available
              </div>

              <form
                className="notification-form"
                id={`edit_user_${userId}`}
                action={`/user.${userId}`}
                acceptCharset="UTF-8"
                method="post"
              >
                <input type="hidden" name="_method" value="patch" />
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={formAuthenticityToken}
                />
                <input
                  name="notification"
                  type="hidden"
                  value={notification ? 'switch-off' : 'switch-on'}
                />
                <button
                  className={
                    notification
                      ? 'simple-toggle on settings_toggle'
                      : 'simple-toggle settings_toggle'
                  }
                  type="submit"
                >
                  <div className="simple-toggle-circle" />
                </button>
              </form>
            </div>
          </div>
          <div className="settings__item__container__change-password">
            <div className="settings__header">CHANGE PASSWORD</div>
            <div className="content-divider-line-settings" />
            <div className="form__change-password__container">
              <form
                className="fancy-form form__change-password"
                id={`edit_user_${userId}`}
                acceptCharset="UTF-8"
              >
                <input type="hidden" name="_method" value="patch" />
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={formAuthenticityToken}
                />
                <div className="input-group settings_password_group">
                  <label
                    className="settings_password_label current_password_label"
                    htmlFor="user_current_password"
                  >
                    Current Password
                  </label>
                  <div
                    className={
                      Object.keys(error).length === 0
                        ? ''
                        : 'field_with_errors'
                    }
                  >
                    <input
                      required="required"
                      placeholder="Confirm your current password"
                      className="settings_password_input"
                      type="password"
                      name="currentPassword"
                      id="user_current_password"
                      value={state.currentPassword}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="errors">{state.currentPassError}</div>
                </div>
                <div className="input-group settings_password_group">
                  <label
                    className="settings_password_label"
                    htmlFor="user_password"
                  >
                    New Password
                  </label>
                  <input
                    required
                    placeholder="Choose a Password"
                    className="settings_password_input"
                    type="password"
                    name="newPassword"
                    id="user_password"
                    value={state.newPassword}
                    onChange={handleInputChange}
                    onBlur={FocusOutHandler}
                  />
                  <i
                    className="fa fa-info-circle confirm__password-req__toggle confirm__password-req__toggle__with-setting"
                    onClick={handleTooltip}
                  />
                  <div
                    className="confirm__password-req"
                  >
                    <div className="tooltip">
                      <ul>
                        <li>at least 12 characters</li>
                        <li>at least one UPPERCASE</li>
                        <li>at least one lowercase</li>
                        <li>at least one number (123)</li>
                        <li>no parts of your username</li>
                        <li>your password cannot be any of your last 4 passwords</li>
                      </ul>
                    </div>
                  </div>
                  <div className="errors">{state.newPassError}</div>
                </div>
                <div className="input-group" />
                <button
                  type="button"
                  className="btn__size-dynamic btn__color-blue save-password-button"
                  disabled={currentUserDelegator.admin || isInternalUser === 'true'}
                  onClick={(e) => {
                    e.preventDefault();
                    submitHandler();
                  }}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
Settings.propTypes = IPropTypes;
Settings.defaultProps = defaultProps;
