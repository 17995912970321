import { deserializeDate, serializeDate } from './helpers';

class TissueSpecimenRetrievalSerializer {
  static deserialize = (tissueSpecimenRetrieval = {}) => ({
    pathologyLabName: tissueSpecimenRetrieval.pathologyLabName,
    pathologyLabPhone: tissueSpecimenRetrieval.phone,
    pathologyLabFax: tissueSpecimenRetrieval.fax,
    tissueCollectionDate: deserializeDate(tissueSpecimenRetrieval.tissueCollectionDate),
  })

  static serialize = tissueSpecimenRetrieval => ({
    pathologyLabName: tissueSpecimenRetrieval.pathologyLabName,
    phone: tissueSpecimenRetrieval.pathologyLabPhone,
    fax: tissueSpecimenRetrieval.pathologyLabFax,
    tissueCollectionDate: serializeDate(tissueSpecimenRetrieval.tissueCollectionDate),
  })
}

export { TissueSpecimenRetrievalSerializer };
