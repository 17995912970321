import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  clinicalHistoryStore: PropTypes.object.isRequired,
  saveClinicalHistoryInfo: PropTypes.func.isRequired,
};

const ClinicalHistoryExpanded = ({ clinicalHistoryStore, saveClinicalHistoryInfo }) => {
  const {
    clinicalHistoryTreatment,
    clinicalHistoryReport,
    clinicalHistoryCgpFeasible,
    clinicalHistoryCgpCompletion,
    clinicalHistoryCgpResult,
  } = clinicalHistoryStore;

  const updateInput = id => (event) => {
    const { value } = event.target;
    saveClinicalHistoryInfo({ [id]: value }, true);
  };

  return (
    <div className="accordion-section flex-container__inner-container">
      <div className="flex-container__etrf-section">
        <div className="flex-container__etrf-row">
          <div className="clinical-information-column-extra-space-1-1">
            The patient is seeking further treatment and is
          </div>
          <div className="clinical-information-column-extra-space-1-2">
            <input
              className="clinical-history-radio"
              type="radio"
              value="Newly diagnosed (Stage III/IV)"
              checked={clinicalHistoryTreatment === 'Newly diagnosed (Stage III/IV)'}
              onChange={updateInput('clinicalHistoryTreatment')}
            />
            {' '}
            Newly diagnosed (Stage III/IV)
          </div>
          <div className="clinical-information-column-extra-space-1-3">
            <input
              className="clinical-history-radio"
              type="radio"
              value="Not responding to therapy"
              checked={clinicalHistoryTreatment === 'Not responding to therapy'}
              onChange={updateInput('clinicalHistoryTreatment')}
            />
            {' '}
            Not responding to therapy
          </div>
        </div>
      </div>
      <div className="flex-container__etrf-section">
        <div className="flex-container__etrf-row">
          <div className="clinical-information-expanded-column-1-3">
            Has the patient recieved a Guardant360 report since their most recent progression?
          </div>
          <div className="clinical-information-expanded-column-1-2">
            <input
              className="clinical-history-radio"
              type="radio"
              value="Yes"
              checked={clinicalHistoryReport === 'Yes'}
              onChange={updateInput('clinicalHistoryReport')}
            />
            {' '}
            Yes
          </div>
          <div className="clinical-information-expanded-column-1-2">
            <input
              className="clinical-history-radio"
              type="radio"
              value="No"
              checked={clinicalHistoryReport === 'No'}
              onChange={updateInput('clinicalHistoryReport')}
            />
            {' '}
            No
          </div>
        </div>
      </div>
      <div className="flex-container__etrf-section">
        <div className="flex-container__etrf-row">
          <div className="clinical-information-expanded-column-1-3">
            <div>
              Is tissue-based comprehensive genomic profiling (CGP) from a recent biopsy feasible?
            </div>
          </div>
          <div className="clinical-information-expanded-column-1-2">
            <input
              className="clinical-history-radio"
              type="radio"
              value="Yes"
              checked={clinicalHistoryCgpFeasible === 'Yes'}
              onChange={updateInput('clinicalHistoryCgpFeasible')}
            />
            {' '}
            Yes
          </div>
          <div className="clinical-information-expanded-column-1-2">
            <input
              type="radio"
              value="No"
              checked={clinicalHistoryCgpFeasible === 'No'}
              onChange={updateInput('clinicalHistoryCgpFeasible')}
            />
            {' '}
            No
          </div>
        </div>
      </div>
      <div className="flex-container__etrf-section">
        <div className="flex-container__etrf-row">
          <div className="clinical-information-expanded-column-1-3">
            Has tissue-based CGP from a recent biopsy been performed with a non-QNS result?
          </div>
          <div className="clinical-information-expanded-column-1-2">
            <input
              className="clinical-history-radio"
              type="radio"
              value="Yes"
              checked={clinicalHistoryCgpCompletion === 'Yes'}
              onChange={updateInput('clinicalHistoryCgpCompletion')}
            />
            {' '}
            Yes
          </div>
          <div className="clinical-information-expanded-column-1-2">
            <input
              className="clinical-history-radio"
              type="radio"
              value="No"
              checked={clinicalHistoryCgpCompletion === 'No'}
              onChange={updateInput('clinicalHistoryCgpCompletion')}
            />
            {' '}
            No
          </div>
        </div>
      </div>
      <div className="flex-container__etrf-section">
        <div className="flex-container__etrf-row">
          <div className="clinical-information-expanded-column-1-3">
            Has tissue-based CGP from a recent biopsy already
            returned an actionable result?
          </div>
          <div className="clinical-information-expanded-column-1-2">
            <input
              className="clinical-history-radio"
              type="radio"
              value="Yes"
              checked={clinicalHistoryCgpResult === 'Yes'}
              onChange={updateInput('clinicalHistoryCgpResult')}
            />
            {' '}
            Yes
          </div>
          <div className="clinical-information-expanded-column-1-2">
            <input
              type="radio"
              value="No"
              checked={clinicalHistoryCgpResult === 'No'}
              onChange={updateInput('clinicalHistoryCgpResult')}
            />
            {' '}
            No
          </div>
        </div>
      </div>
    </div>
  );
};

ClinicalHistoryExpanded.propTypes = IPropTypes;

export { ClinicalHistoryExpanded };
