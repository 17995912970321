import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
  },
  dialogTitle: {
    paddingRight: '0px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
}));

const IPropTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
};
const ModalPopup = (props) => {
  const {
    title,
    children,
    openPopup,
    setOpenPopup,
  } = props;
  const classes = useStyles();

  return (
    <Dialog open={openPopup} maxWidth="md" classes={{ paper: classes.dialogWrapper, container: classes.container }}>
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setOpenPopup(false);
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
export default ModalPopup;
ModalPopup.propTypes = IPropTypes;
