import React from 'react';

import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './request-download-menu.module.scss';

const IPropTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  showDropdown: PropTypes.bool,
  onClose: PropTypes.func,
};

const defaultProps = {
  showDropdown: false,
  onClose: () => {},
};

const RequestDownloadMenu = ({ links, showDropdown, onClose }) => showDropdown && (
  <OutsideClickHandler onOutsideClick={onClose}>
    <div className={styles.container}>
      <div className={[styles.dropdown, showDropdown ? styles.visible : ''].join(' ')}>
        <span className={styles.dropdownToggle}>
          Download:
          <i className="fa fa-download" />
        </span>
        {
          links.map(downloadLink => (
            <a key={downloadLink.label} className={styles.link} href={downloadLink.url} target="_blank" rel="noopener noreferrer">
              {downloadLink.label}
            </a>
          ))
        }
      </div>
    </div>
  </OutsideClickHandler>
);

RequestDownloadMenu.propTypes = IPropTypes;
RequestDownloadMenu.defaultProps = defaultProps;

export { RequestDownloadMenu };
