
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AddDiagnosisInfo } from '../../../../store/actions/orders/active-order/diagnosis_info';

import { DiagnosisConditionLateStageCollapsed } from './diagnosis-condition-late-stage-collapsed';

const mapStateToProps = ({ orders: { activeOrder: { diagnosisInfo, formErrors } } }) => ({
  diagnosisStore: diagnosisInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveEarlyStageDiagnosisInfo: AddDiagnosisInfo,
}, dispatch);

const ConnectedDiagnosisConditionLateStageCollapsed = connect(
  mapStateToProps, mapDispatchToProps,
)(DiagnosisConditionLateStageCollapsed);

export { ConnectedDiagnosisConditionLateStageCollapsed as DiagnosisConditionLateStageCollapsed };
