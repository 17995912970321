import React from 'react';
import PropTypes from 'prop-types';

import { DashboardListItem } from '../dashboard-list-item';
import { Order } from '../../../../models/order/order';
import { ProductLogo } from '../../../generic/product-logo/product-logo';

const IPropTypes = {
  order: PropTypes.instanceOf(Order).isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: true,
};

const PendingOrderItem = ({ order, disabled }) => {
  const productLogos = (order.selectedTests() || []).map(productId => (
    <ProductLogo
      key={productId}
      productId={productId}
    />
  ));

  const link = disabled ? '' : `/orders/${order.id}?productFamily=${order.productFamily}`;

  return (
    <DashboardListItem
      firstBlock={order.patientInfo.formatFullName()}
      secondBlock={order.patientInfo.formatBirthDate()}
      thirdBlock={order.diagnosisInfo.formatDisease()}
      fourthBlock={productLogos.length ? productLogos : ['N/A']}
      fifthBlock={order.formatOrderingUserAndStatus()}
      link={link}
      disabled={disabled}
    />
  );
};

PendingOrderItem.propTypes = IPropTypes;
PendingOrderItem.defaultProps = defaultProps;

export { PendingOrderItem };
