import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionTypes';
import { EDIT_ORDER } from '../../new-orders/orders-dashboard/actions/actionTypes';
import { CLOSE_ALERT_WINDOW, OPEN_ALERT_WINDOW } from '../alert-modal/actions';
import { getUserRole } from '../helpers';

export const DEFAULT_NEW_ORDER_STATE = fromJS({
  panal: {
    tab: 0,
    tabs: [
      {
        index: 0,
        label: 'Patient Info',
        disabled: false,
        identifier: 'patient',
        content: ' Not Started',
        optionLabel: '',
        pathOps: ['firstName', 'lastName'],
      },
      {
        index: 1,
        label: 'Diagnosis',
        disabled: false,
        identifier: 'diagnosis',
        content: ' Not Started',
        optionLabel: '',
        pathOps: ['disease'],
      },
      {
        index: 2,
        label: 'Test Selection',
        disabled: false,
        identifier: 'testSelection',
        content: ' Requires Diagnosis',
        optionLabel: '',
        pathOps: ['productFamily'],
        keyval: 'id',
      },
      {
        index: 3,
        label: 'Clinical History',
        disabled: false,
        identifier: 'clinicalHistory',
        content: ' Requires Test Selection',
        optionLabel: '',
        pathOps: '',
      },
      {
        index: 4,
        label: 'Physician Info',
        disabled: false,
        identifier: 'physician',
        content: ' Not Started',
        optionLabel: '',
        pathOps: 'authorizingProvider',
      },
      {
        index: 5,
        label: 'Billing Info',
        disabled: false,
        identifier: 'billing',
        content: ' Not Started',
        optionLabel: '',
        pathOps: 'insuredRelationship',
      },
    ],
  },
  currentData: {},
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  guardantReveal: false,
  errors: {},
  openPopup: false,
  isReviewOrderOpen: false,
  isSignAndSubmitOpen: false,
  reload: false,
  isOpen: false,
  stateList: null,
  physicians: null,
  accounts: null,
  schema: {
    order: {
      version: '2.0',
      trfVersion: 'D-000463 R6',
      externalOrderSource: {
        name: 'Portal',
      },
      advancedCancerStage: {},
      productFamily: {},
      client: {
      },
      orderingUser: {
      },
      authorizingProvider: {
      },
      secondaryRecipient: {
      },
      patient: {
        demographic: {
        },
      },
      diagnosis: { },
      testOrdered: {
        name: [],
      },
      billing: {
        cardIncluded: false,
        primaryInsurance: {
        },
      },
    },
  },
  cancerList: [],
  secondaryCode0: [],
  secondaryCode1: [],
  secondaryCode2: [],
  isReviewPopUpOpen: false,
  clinicalHistory360: null,
  clinicalHistoryReveal: null,
  disabled: ['TGPDL1', 'TG'],
  portal: {
    portalOrderId: '',
  },
  envelope: {
    envelopeId: '',
  },
  isDownload: '',
  cancerTypeList: null,
  testSelection: null,
  isLoaded: false,
  showSaveAndDiscardModal: false,
  showDailog: false,
  isG360: false,
  isReveal: false,
  embaded: true,
  delegee: false,
  showEmbadedDailog: false,
  selectedCard: null,
  formUpdated: false,
  showClinicalDialog: false,
  nextTabValue: -1,
  clinicalRevealVisited: false,
  isConsentExists: false,
});

const getFlagStatusByRole = (state, payload) => {
  if (state.get('isConsentExists')) {
    return true;
  }
  return false;
};

const NewOrderReducer = handleActions(
  {
    [actionTypes.NEXT_SAVE_DATA]: state => state.setIn(
      ['notify'],
      fromJS({
        isOpen: true,
        message: 'Saved Successfully',
        type: 'success',
      }),
    ),
    [actionTypes.NEWORDER_TAB_CHANGE]: (state, action) => state
      .setIn(action.payload.key, action.payload.value),
    [actionTypes.ICR_CODES]: (state, action) => state.set('cancerList', action.payload.data),
    [actionTypes.ICR_CODES21]: (state, action) => state.set(`secondaryCode${action.payload.key}`, action.payload.data),
    [actionTypes.ON_ERROR]: (state, action) => state.setIn(['errors', action.payload.name], action.payload.value),
    [actionTypes.NEWORDER_ON_CHANGE]: (state, action) => state
      .setIn(action.payload.name, action.payload.value === 'Invalid date' ? undefined : action.payload.value),
    [actionTypes.GUARDANT_REVEAL]: (state, action) => state.set('guardantReveal', action.payload),
    [actionTypes.REVIEW_ORDER_DATA]: (state, action) => state
      .setIn(action.payload.key, action.payload.value),
    [actionTypes.CLINICAL_HISTORY_DATA_360]: (state, action) => state.set('clinicalHistory360', action.payload.data),
    [actionTypes.CLINICAL_HISTORY_DATA_REVEAL]: (state, action) => state.set('clinicalHistoryReveal', action.payload.data),
    [OPEN_ALERT_WINDOW]: (state, action) => state.set('showSaveAndDiscardModal', action.payload),
    [CLOSE_ALERT_WINDOW]: (state, action) => state.set('showSaveAndDiscardModal', action.payload),
    [EDIT_ORDER]: (state, action) => state
      .setIn(['schema', 'order'], action.payload.orders)
      .set('roOpenFromDashboard', true)
      .setIn(['panal', 'tab'], 5)
      .set('isReviewOrderOpen', true)
      .set('ordersType', action.payload.ordersType)
      .set('overEsign', true),
    [actionTypes.ORDER_ERROR]: (state, action) => state.setIn(
      ['notify'],
      fromJS({ isOpen: true, message: action.payload.message, type: 'error' }),
    ),
    [actionTypes.DELEGEE_FLAG]: (state, action) => state.set('delegee', getFlagStatusByRole(state, action.payload)),
    [actionTypes.RESET_STATE]: () => DEFAULT_NEW_ORDER_STATE,
  },
  DEFAULT_NEW_ORDER_STATE,
);
export default NewOrderReducer;
