import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../store';
import RolesViewContainer from './RolesViewContainer';
import { getUserInfo } from '../helpers';
import Spinner from '../common/utils/spinner/Spinner';

const userRoles = getUserInfo('Role');
const RolesRootContainer = () => (
  <Provider store={store}>
    <RolesViewContainer roles={userRoles} />
    <Spinner />
  </Provider>
);
export default RolesRootContainer;
