function decodeForIE(string) {
  // atob can work with strings with whitespaces, even inside the encoded part,
  // but only \t, \n, \f, \r and ' ', which can be stripped.
  let stringy = String(string).replace(/[\t\n\f\r ]+/g, '');
  // base64 character set, plus padding character (=)
  const b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  // Regular expression to check formal correctness of base64 encoded strings
  const b64re = /^(?:[A-Za-z\d+/]{4})*?(?:[A-Za-z\d+/]{2}(?:==)?|[A-Za-z\d+/]{3}=?)?$/;

  if (!b64re.test(stringy)) {
    throw new TypeError("Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded.");
  }
  // Adding the padding if missing, for semplicity
  /* eslint-disable no-bitwise */
  stringy += '=='.slice(2 - (stringy.length & 3));
  /* eslint-enable no-bitwise */
  let bitmap = '';
  let result = '';
  let r1 = 0;
  let r2 = 0;
  let i = 0;
  for (; i < stringy.length;) {
    /* eslint-disable */
    bitmap = b64.indexOf(stringy.charAt(i++)) << 18 | b64.indexOf(stringy.charAt(i++)) << 12
            | (r1 = b64.indexOf(stringy.charAt(i++))) << 6 | (r2 = b64.indexOf(stringy.charAt(i++)));

    result += r1 === 64 ? String.fromCharCode(bitmap >> 16 & 255)
            : r2 === 64 ? String.fromCharCode(bitmap >> 16 & 255, bitmap >> 8 & 255)
            : String.fromCharCode(bitmap >> 16 & 255, bitmap >> 8 & 255, bitmap & 255);
    /* eslint-enable */
  }
  return result;
}

function base64ToArrayBuffer(base64, isIe) {
  const binaryString = isIe ? decodeForIE(base64) : window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

function createAnchorWithBase64PDF(data) {
  const downloadLink = document.createElement('a');
  downloadLink.target = '_blank';
  downloadLink.download = data.filename;
  downloadLink.rel = 'noopener noreferrer';
  const pdfData = base64ToArrayBuffer(data.data, false);
  const blob = new Blob([pdfData], { type: 'application/pdf' });
  const URL = window.URL || window.webkitURL;
  const downloadUrl = URL.createObjectURL(blob);
  downloadLink.href = downloadUrl;
  document.body.appendChild(downloadLink);
  return [downloadUrl, downloadLink];
}

function clickAnchor(anchor) {
  anchor.click();
}

function cleanupElements(anchor, url) {
  document.body.removeChild(anchor);
  URL.revokeObjectURL(url);
}

function downloadPDFForIE(data) {
  const pdfData = base64ToArrayBuffer(data.data, true);
  const blob = new Blob([pdfData], { type: 'application/pdf' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, data.filename);
  }
}

function downloadPDFInNewTab(response) {
  const isIE = (window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./));
  if (isIE) {
    downloadPDFForIE(response.data);
  } else {
    const [downloadUrl, downloadLink] = createAnchorWithBase64PDF(response.data);
    clickAnchor(downloadLink);
    cleanupElements(downloadLink, downloadUrl);
  }
}


export { downloadPDFInNewTab };
