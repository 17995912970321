import React from 'react';

const TermsOfUse = () => (
  <div className="terms">
    <h1 className="terms__header">Provider Portal Terms and Conditions of Use</h1>
    <p className="terms__updated-on">Last Updated: May 1, 2018</p>
    <p>
      THIS IS A LEGALLY BINDING AGREEMENT between Guardant Health, Inc.
      (Guardant or we or us) and you. BY CLICKING “I AGREE,” OR BY
      OTHERWISE SIGNING UP OR FOR AN ACCOUNT, OR BY ACCESSING OR USING
      THE GUARDANT HEALTH PROVIDER PORTAL AND SERVICES (DEFINED BELOW),
      YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS (THIS AGREEMENT),
      WHICH FORM A LEGALLY BINDING CONTRACT. Please read this Agreement
      carefully, and do not sign up for an account or use the Services
      if you are unwilling or unable to be bound by this Agreement. You
      and we are collectively referred to as the “Parties.”
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">
      Privacy Notice and Consent for Processing
      Personal Data of Users of the Provider Portal
    </h4>
    <p>
      Data shared on Guardant Health’s Portal will contain personal identifiable
      information (PII) and protected health information (PHI). Guardant Health
      is committed to compliance with the General Data Protection Regulation of
      the European Union (Regulation (EU) 2016/679) (hereinafter “GDPR”) and the U. S.
      Health Insurance Portability and Accountability Act of 1996 (Public Law 104-191)
      (hereinafter “HIPAA”). PII transferred to Guardant will be data that is provided
      to Guardant either by you, your Authorized Workforce (as defined below), or your
      patients. Data generated by Guardant, such as patient test result reports, will
      be generated and transferred to you by Guardant as the Controller of the data.
      By agreeing to this Agreement, you consent to the following:
    </p>
    <p />
    <ol>
      <li>
        Transfer of your personal data to Guardant Health’s location in Redwood City,
        California, USA for the purpose of facilitating the provision of Guardant Health’s
        health care services to your patients
      </li>
      <li>
        Your PII that will be transferred and stored will include your name, address,
        email address, phone number, specialty, title, and any other information supplied
        by you to set up your portal account
      </li>
      <li>
        Storage of your personal data on Guardant Health’s portal and other secure servers.
      </li>
      <li>Use of your personal data to contact you regarding your patients</li>
      <li>
        Use of your personal data to contact you regarding educational information about
        Guardant Health’s products and services
      </li>
      <li>
        Use of your personal data to notify you of any advocacy opportunities, such as Advisory
        Boards, which may be available at Guardant Health
      </li>
    </ol>
    <p />
    <p>
      Please read our Privacy Policy (
      <a href="https://guardanthealth.com/contact/privacy-policy/">
        https://guardanthealth.com/contact/privacy-policy/
      </a>
      )
      which also governs your use of the Guardant Health Provider Portal to understand our
      policies about what information we collect, how we will treat this information, and
      what options you have to direct our activities.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Confidentiality</h4>
    <p>
      You are responsible for maintaining the confidentiality of all laboratory reports
      and other PII and PHI , as those terms are defined by GDPR and HIPAA, respectively,
      which you access via the Services in accordance with federal, state, and local
      requirements. You will maintain appropriate privacy and security with regard
      to all personnel, systems, and administrative processes used by you or members
      of your Authorized Workforce, as such term is defined by HIPAA and GDPR, to transmit,
      store and process PHI through the use of the Services. As a health care provider,
      you recognize that you are and/or are employed by a Covered Entity, as
      such term is defined by HIPAA. You represent that you will abide by all applicable
      HIPAA and GDPR obligations with respect to use of the Services and acknowledge that
      Guardant is not responsible for your HIPAA and GDPR compliance.
    </p>
    <p>
      You may grant access to records available via the Services to other healthcare
      professionals and other Authorized Workforce members at your organization solely
      for the purposes of treatment of patients and healthcare operations in accordance
      with your organization policies and applicable law.. It is your
      responsibility to revoke access to such Authorized Workforce members promptly when
      they are no longer employed by your organization or otherwise should no longer have
      access to such records.
    </p>
    <p>
      If you are granted access rights to PHI available via the Services by another healthcare
      provider, you may use such information solely for purposes permitted by HIPAA, including
      patient treatment and health care operational purposes; provided that: (i) you may
      access only information pertaining to individuals with whom you have a treatment
      relationship or for whom a provider who has a treatment relationship with the
      individual has requested a professional consultation from you; (ii) you are an
      authorized member of the organization workforce working on behalf of the physician
      or the patient care team, or (iii) you have received authorization from a patient
      to access and use their health information.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Grant of Rights</h4>
    <p>
      Guardant Health grants to you a non-exclusive, personal, non-transferable, limited
      right to access and use the Guardant Health Provider Portal and its related services
      and/or any other services offered to or through this website (collectively, the
      &quot;Services&quot;), in accordance with and subject to your full compliance with the
      terms and conditions set forth in this Agreement. You will not: (a) make the Services,
      in whole or in part, available to any other person, entity or business other than as
      expressly permitted herein; (b) copy, reverse engineer, decompile or disassemble the
      Services, in whole or in part, or otherwise attempt to
      discover the source code to any software that may be used by or in connection with the
      Services; or (c) modify, combine, integrate, render interoperable, or otherwise access
      for purposes of automating data conversion or transfer, the Services with any other
      service or software not provided or approved by us. You obtain no rights to the
      Services except for those rights that are expressly granted herein.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Access to the Services</h4>
    <p>
      The Services are made available to physicians and health care providers who order
      Guardant testing services, to members of a Provider’s Authorized Workforce (as
      defined and described below) and to authorized Site Managers who manage data for
      many health care providers.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Provider of Record</h4>
    <p>
      Providers and individuals who register for an account on behalf of a Provider must
      provide the Provider’s full legal name and their organization information as part
      of the registration process. The Provider in whose name an account is established
      serves as that account’s “Provider of Record”. Only the Provider of Record is
      entitled to the rights, remedies or benefits under this Agreement. Certain
      administrative rights for the account may be assigned or delegated to one or more
      members of the Provider of Record’s Authorized Workforce, but the Provider of Record
      remains responsible for all activity occurring under such account.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Authorized Workforce</h4>
    <p>
      For purposes of this Agreement, the term “Authorized Workforce” means those individuals
      who are employees, volunteers, interns, trainees, and other persons whose conduct,
      in the performance of work for Provider, is under the direct control of such Provider,
      whether or not paid by the Provider, and who Provider has identified using the Guardant
      Health Provider Portal as authorized to access the Services on their behalf.
      If you are a member of a Provider’s Authorized Workforce, and such Provider has
      authorized you to access the Services on its behalf by authorizing Guardant to
      create a unique set of &quot;Credentials&quot; (i.e., username and password) for you,
      then you are authorized under this Agreement to access the Services solely on
      behalf and at the direction of such Provider. As such, you may sign in and use the
      functionality of the Services solely on behalf and at the direction of such Provider.
      You and/or your Provider are solely responsible for all activities occurring under the
      Provider of Record’s account and/or using your Credentials. You consent to
      and authorize the disclosure to such Provider of any content related to, or otherwise
      generated by your use of the Services, including secure messages sent or received
      using your Credentials. You hereby agree and acknowledge that you are subject to,
      and we may enforce against you, all of the covenants, obligations, restrictions,
      limitations, acknowledgements, consents, representations and warranties set forth
      in this Agreement that are applicable to the person addressed as “you” in this
      Agreement, and you hereby grant and
      make all rights, waivers and releases set forth in this Agreement that are granted
      and made by the person
      addressed as “you” in this Agreement, but you are entitled to none of, and hereby
      waive and agree not to
      exercise or assert any of, the rights, remedies or benefits under this Agreement
      other than the limited,
      non-exclusive, non-transferable, personal right under this paragraph to sign in and
      use the functionality of
      the Services solely on behalf and at the direction of such Provider. Notwithstanding
      anything contained
      herein, you acknowledge that your access to the Services may be terminated by the
      Provider or us at any
      time, for any reason or no reason at all, with or without notice. By (i) accessing
      any of the Services under
      a Provider’s account(s), or (ii) contacting us by any means and requesting or
      directing us to take any
      action with respect to any Provider’s account(s) or data held by such account(s),
      or (iii) asserting any
      right or authority with respect to such account(s) or data, you represent and
      warrant that you have the
      authority to act on such Provider’s behalf and that you are not using the
      Services, or otherwise engaging in
      the activities described in clauses (i) through (iii) above, for the benefit
      or at the direction, of any
      person or entity other than such Provider.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Consulted Provider</h4>
    <p>
      A Provider of Record may grant a Consulted Provider access to patient records
      for consulting purposes. As a Consulted Provider, you attest that you are a
      healthcare provider authorized to handle protected health information and to
      view the shared patient’s report. You will not use the information shared with
      you for any purpose but to consult on the shared patient’s care, as requested
      by the Provider of Record.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Your Authorized Workforce</h4>
    <div className="overflow__auto">
      You may permit your Authorized Workforce to use the Services on your behalf,
      subject to the terms of this Agreement. In such event, you will:
      <div className="margin-top__10-px margin-bottom__20-px overflow__auto">
        <div className="float-left margin-left__10-px">• &nbsp;&nbsp;</div>
        <div className="margin-left__40-px">
          Require each member of your Authorized Workforce to have unique
          Credentials, and will provide valid institution email addresses to Guardant
          of each such member for which you are seeking access;
        </div>
      </div>
      <div className="margin-bottom__20-px overflow__auto">
        <div className="float-left margin-left__10-px">• &nbsp;&nbsp;</div>
        <div className="margin-left__40-px">
          Train all members of your Authorized Workforce in the requirements of
          this Agreement and the Policies and Procedures relating to their access to
          and use of the Guardant Health Provider Portal and Services, and ensure that
          they comply with such requirements;
        </div>
      </div>
      <div className="margin-bottom__20-px overflow__auto">
        <div className="float-left margin-left__10-px">• &nbsp;&nbsp;</div>
        <div className="margin-left__40-px">
          Suspend and/or terminate access to and use of the Services by any
          member of your Authorized Workforce who violates the terms of this Agreement
          or the Guardant Health Provider Portal Policies and Procedures, and notify
          Guardant of the same;
        </div>
      </div>
      <div className="margin-bottom__20-px overflow__auto">
        <div className="float-left margin-left__10-px">• &nbsp;&nbsp;</div>
        <div className="margin-left__40-px">
          Ensure that only the person to whom a specific set of Credentials has
          been assigned accesses the Services with such Credentials; and
        </div>
      </div>
      <div className="margin-bottom__20-px overflow__auto">
        <div className="float-left margin-left__10-px">• &nbsp;&nbsp;</div>
        <div className="margin-left__40-px">
          Immediately notify us using the Guardant Health Provider Portal of the
          termination of employment of any member of your Authorized Workforce, or of
          your withdrawal of authorization for any such person to access the Services.
        </div>
      </div>
    </div>
    <h4 className="font-weight__bold underline margin-top__25-px">Credentials</h4>
    <p>
      You agree that your use of the Services is subject to verification by us of your
      identity and credentials as a health care provider, and to your ongoing
      qualification as such. As a registered user, you will have log-in information,
      including a username and password. Your account is personal to the primary account
      holder, and you may not share your account and log-in information with, or allow
      access to your account by, any third party. As you will be responsible for all
      activity that occurs under your account and access credentials, you should take care
      to preserve the confidentiality of your username and password, and any
      device that you use to access the Service.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Reports Sharing</h4>
    <p>
      This Service facilitates the sharing of individual health information and patient
      results among users and other authorized parties at your organization. You agree
      and are responsible to ensure that individuals you share information with are
      authorized healthcare providers involved in the administration of patient
      records, are involved in the care of the patient, or that you have received
      appropriate authorization from the patient applicable to the sharing. You further
      agree that you will abide by HIPAA and GDPR health privacy obligations, as applicable,
      and follow your organization’s policies and procedures regarding access
      and authorization with regard to sharing such information.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Notifications</h4>
    <p>
      By signing up for the Services, you agree to receive email notifications about
      the service, permissions and
      access changes, and information about our products and services.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">No Third-Party Access</h4>
    <p>
      Except as required by law, you will not permit any third party (other than
      persons who satisfy the definition of Authorized Workforce member and meet the
      requirements set forth above) to use or access the Services without our prior
      written agreement. Nor will you authorize or assist any person or entity in
      accessing, or attempting to access, any portion of the Services via any means
      other than a commercial browser (such as Internet Explorer, Mozilla Firefox,
      Safari, or Chrome) or an authorized mobile application
      developed and operated by us.
    </p>
    <p>
      You will promptly notify us in writing of any order or demand for compulsory
      disclosure of health information if the disclosure requires access to or use
      of the Services by a party other than you or the Authorized Workforce. You will
      cooperate fully with us in connection with any such demand.
    </p>
    <p>
      You will notify us if you suspect that your Credentials or the credentials of a
      member of your Authorized Workforce may have been compromised. You will also
      notify us in writing in the event that you become aware that any person or entity,
      whether or not a member of your Authorized Workforce, (a) attempts to access the
      Services by any means other than a commercial browser, (b) claims to offer a
      service or system that &quot;integrates with&quot; our Services or (c) requests
      to use your Credentials or requests that you obtain Credentials in order to access
      the Services in a manner that would violate this Agreement if you engaged in
      such activity.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Compliance with Law</h4>
    <p>
      You are solely responsible for ensuring that your use of the Services complies with
      applicable law, including HIPAA, GDPR and other laws relating to the maintenance of
      the privacy, security, and confidentiality of PHI and PII. You will not grant any user,
      including members of your Authorized Workforce, any rights to access or use of our
      Services that they would not be allowed to have under applicable laws. We offer no
      assurance that your use of the Services under the terms of this Agreement will not
      violate any law or regulation applicable to you. Except as otherwise provided in this
      Agreement, we will keep information that you or your Authorized Workforce input or
      upload onto the Services private and will not share it with
      third parties, unless we believe in good faith that disclosure of Your Information
      is necessary to (i) comply with a court order, warrant or other legal process, (ii)
      protect the rights, property or safety of Guardant Health or others, (iii) investigate
      or enforce suspected breaches of this Agreement, or (iv) allow our third-party partners
      to comply with their obligations under federal, state or local laws.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Warranties</h4>
    <p>
      ACCESS TO THE SERVICES AND THE INFORMATION MAINTAINED VIA THE SERVICES IS PROVIDED
      &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; WITHOUT ANY WARRANTY OF ANY KIND, AND
      WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING
      BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, NONINFRINGEMENT, AND TITLE. YOU ARE SOLELY RESPONSIBLE FOR ANY
      AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE SERVICES, INCLUDING THE
      ACTS OR OMISSIONS OF MEMBERS OF YOUR AUTHORIZED WORKFORCE, OR THE INFORMATION IN THE
      SERVICES, INCLUDING INACCURATE OR INCOMPLETE INFORMATION.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Indemnification</h4>
    <p>
      You hereby agree to indemnify, defend, and hold harmless us and other users, and our
      and their respective
      affiliates, officers, directors, employees and agents, from and against any claim,
      cost or liability,
      including reasonable attorneys’ fees, arising out of or relating to: (a) the use of
      the Services by you or
      your Authorized Workforce; (b) any breach by you or your Authorized Workforce of any
      representations,
      warranties or agreements contained in this Agreement; (c) the actions of any person
      gaining access to the
      Services under Credentials assigned to you or a member of your Authorized Workforce;
      (d) the actions of
      anyone using Credentials assigned to you or any member of your Authorized Workforce
      that adversely affects
      the Services or any information accessed through the Services; and (e) your negligent
      or willful misconduct,
      or that of any member of your Authorized Workforce. Your indemnification obligations
      in this Agreement are
      cumulative, and are not intended to, nor do they, limit your indemnification obligations
      elsewhere in this
      Agreement or at law, even if such obligations arise or are occasioned or triggered by a
      single assertion,
      claim, circumstance, action, event or transaction.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Limitation of Liability</h4>
    <p>
      IN NO EVENT SHALL GUARDANT HEALTH BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL,
      OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUES,
      LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF INFORMATION OR DATA. GUARDANT HEALTH’S
      AGGREGATE LIABILITY UNDER THIS AGREEMENT SHALL IN NO EVENT EXCEED $100.
    </p>
    <h4 className="font-weight__bold underline margin-top__25-px">Changes to these Terms</h4>
    <p>
      Guardant Health may revise or modify these Terms from time to time or impose new
      conditions on the use of
      the Services. Such changes, revisions, or modifications shall be effective immediately
      upon notice to you,
      which may be given by any means, including, without limitation, posting on the Portal
      or by e-mail.&nbsp; Any use of the Services by you after such notice shall be deemed
      to constitute acceptance of such changes, revisions, or modifications.
    </p>
  </div>
);
export default TermsOfUse;
