import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../generic/Modal';
import styles from './alteration-info-modal.module.scss';

const IPropTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
};

const AlterationInfoModal = (props) => {
  const [startClose, setStartClose] = useState(false);
  return (
    <Modal startClose={startClose} onClose={props.onClose}>
      <div className={styles.container}>
        <button
          className={styles.closeButton}
          type="button"
          onClick={() => setStartClose(true)}
        >
          <i className="fa fa-close" />
        </button>
        {props.children}
      </div>
    </Modal>
  );
};

AlterationInfoModal.propTypes = IPropTypes;

export { AlterationInfoModal };
