import React, { memo } from 'react';
import { Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';

const IPropTypes = {
  text: PropTypes.string,
  isImportant: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.string,
};
const defaultProps = {
  text: '',
  isImportant: false,
  handleChange: () => {},
  value: '',
};

const InputBox = (props) => {
  const {
    text, isImportant, handleChange, value,
  } = props;
  return (
    <Box className="form-box">
      <label style={{ maxWidth: '100%', marginBottom: '5px' }}>
        {text}
        {isImportant && <HelpOutlineIcon style={{ float: 'right' }} />}
      </label>
      <input type="text" className="form-control" value={value} onChange={handleChange} />
    </Box>
  );
};
export default memo(InputBox);
InputBox.propTypes = IPropTypes;
InputBox.defaultProps = defaultProps;
