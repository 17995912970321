import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { savePatientInfo } from '../../../../store/actions/orders/active-order';
import { PatientInformationShowAll } from './patient-information-show-all';

const mapStateToProps = ({ orders: { activeOrder: { patientInfo } } }) => ({
  patientInfo,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  savePatientInfo,
}, dispatch);

const ConnectedPatientInformationShowAll = connect(
  mapStateToProps, mapDispatchToProps,
)(PatientInformationShowAll);

export { ConnectedPatientInformationShowAll as PatientInformationShowAll };
