import React from 'react';
import PropTypes from 'prop-types';

const ModalDialogBox = (props) => {
  const { show, showModal, ModalContent } = props;
  if (!show) {
    return null;
  }
  return (
    <div className="modal__container-outer" style={{ display: 'block' }}>
      <div className="modal__container-inner">
        <div className="modal__content">
          <div className="info-circle__container">
            <i className="fa fa-close modal-close" onClick={showModal} />
            <div>{ModalContent}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalDialogBox;
ModalDialogBox.propTypes = {
  show: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  ModalContent: PropTypes.string.isRequired,
};
