import React from 'react';
import PropTypes from 'prop-types';

import { ImmunohistochemistryTable as ImmunohistochemistryTableModel } from '../../../models/immunohistochemistry-table';

import styles from './immunohistochemistry-table.module.scss';

const IPropTypes = {
  tableData: PropTypes.instanceOf(ImmunohistochemistryTableModel),
};

const defaultProps = {
  tableData: null,
};

const ImmunohistochemistryTable = ({ tableData }) => !!tableData && (
  <div>
    <h3 className={styles.title}>
      {tableData.tableTitle}
    </h3>
    <table className={styles.table}>
      <tbody>
        <tr>
          <td className={styles.cell}>{tableData.scoreType}</td>
          <td className={styles.cell}>{tableData.stainingPercentage}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

ImmunohistochemistryTable.propTypes = IPropTypes;
ImmunohistochemistryTable.defaultProps = defaultProps;

export { ImmunohistochemistryTable };
