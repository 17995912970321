import React from 'react';
import PropTypes from 'prop-types';

import styles from './release-modal.module.scss';
import { ReleaseActivityLog } from './release-activity-log';

const IPropTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};


const ReleaseModal = ({ onClose, content }) => (
  <div className={styles.outerContainer}>
    <div className={styles.modalContent}>
      <i className={`fa fa-close ${styles.modalClose}`} type="button" onClick={onClose} />
      <div className={styles.modalBody}>
        <div className={styles.leftSide}>
          {content}
        </div>
        <div className={styles.rightSide}>
          <ReleaseActivityLog />
        </div>
      </div>
    </div>
  </div>
);

ReleaseModal.propTypes = IPropTypes;

export { ReleaseModal };
