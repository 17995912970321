class Biomarker {
  constructor(params) {
    this.name = params.name;
    this.value = params.value;
    this.description = params.description;
  }

  formattedName() {
    return this.description ? `${this.name} (${this.description})` : this.name;
  }
}

export { Biomarker };
