import * as actionTypes from './actionTypes';
import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import HttpService from '../../service/interceptor';

export const getUsersData = params => (dispatch) => {
  const query = `${typeof params === 'string' ? params : `?${new URLSearchParams(params).toString()}`}`;
  dispatch(showSpinner());

  return HttpService.get(`/api/v1/users${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getUsers(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.userError(err));
      dispatch(closeSpinner());
    });
};

export const getUsersSearchData = params => (dispatch) => {
  const query = `?${typeof params === 'string' ? params : new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());

  return HttpService.get(`/api/v1/users${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getSearchUsers(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.userError(err));
      dispatch(closeSpinner());
    });
};

export const addUsersData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.post('/api/v1/users', params)
    .then(({ data }) => {
      dispatch(actionTypes.saveUser(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.userError(err));
      dispatch(closeSpinner());
    });
};
export const editUsersData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.put('/api/v1/users', params)
    .then(({ data }) => {
      dispatch(actionTypes.updatUser(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.userError(err));
      dispatch(closeSpinner());
    });
};
export const deleteUsersData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.delete(`/api/v1/users/${params.uuid}`)
    .then(({ data }) => {
      dispatch(actionTypes.deleteUser(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.userError(err));
      dispatch(closeSpinner());
    });
};

export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePageUser(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeUser(params));
};
