import { logger } from './logger';
import { isInternalUser, getToken } from '../networking/controllers/helper';

const axios = require('axios');

const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
const headers = {
  Authorization: `Bearer ${getToken()}`,
  ...extHeader,
};

export const getStudiesList = (url, callback) => {
  const config = {
    headers,
  };
  window.showSpinner();
  axios.get(url, config)
    .then((response) => {
      callback(response.data);
      window.hideSpinner();
    })
    .catch((error) => {
      window.hideSpinner();
      logger.warn(error);
    });
};

export const getSubjectsList = (url, callback) => {
  const config = {
    headers,
  };
  axios.get(url, config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      logger.warn(error);
    });
};


export const loadDashboardData = (callback, params) => {
  const config = {
    headers,
  };
  axios.get(`/rest/dashboard?request_status=${params}`, config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      logger.warn(error);
    });
};

export const fetchInitialRequestData = (params, callback) => {
  const config = {
    headers,
    params,
  };
  axios.get('rest/multi_reports', config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      logger.warn(error);
    });
};

export const fetchLoadMoreRequestData = (params, callback) => {
  const config = {
    headers,
    params,
  };
  axios.get('rest/multi_reports', config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      logger.warn(error);
    });
};

// TODO: Do setupAuthInterceptor on page load and we won't need csrfToken as param
export const jsAuditAction = async (csrfToken, action, data) => {
  const params = {
    args: {
      action,
      data,
    },
    js_logging: true,
  };
  axios.defaults.headers = { 'X-CSRF-Token': csrfToken };

  return axios.post('/audit_log', params)
    .catch((error) => {
      logger.warn(error);
    });
};

export const shareReport = async (csrfToken, data) => {
  const params = data;
  const header = { headers: { 'X-CSRF-Token': csrfToken } };

  return axios.post('/shares', params, header)
    .catch((error) => {
      logger.warn(error);
    });
};

export const shareRecreate = async (params) => {
  const config = {
    headers,
  };

  return axios.post('/rest/shares_recreate', params, config)
    .catch((error) => {
      logger.warn(error);
    });
};

export const shareRevoke = async (params) => {
  const config = {
    headers,
  };

  return axios.delete(`/rest/shares/${params.id}`, config)
    .catch((error) => {
      logger.warn(error);
    });
};

export const releaseReport = async (csrfToken, data) => {
  const params = data;
  const header = { headers: { 'X-CSRF-Token': csrfToken } };
  const urn = `/patients/${data.patient_id}/release_report`;

  return axios.post(urn, params, header)
    .catch((error) => {
      logger.warn(error);
    });
};

export const getAllProjectIds = () => {
  const config = {
    headers,
  };
  return axios.get('/rest/all_project_ids', config)
    .then(response => response.data)
    .catch(logger.error);
};

export const getGhiPracticeList = (callback) => {
  const config = {
    headers,
  };
  axios.get('/rest/ghi_practice_list', config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      logger.warn(error);
    });
};

export const getGhPhyClientByGhiPracticeList = (selectedIds, callback) => {
  axios.get('/gh_phy_client_by_ghi_practice_list', {
    params: {
      selectedIds,
    },
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      logger.warn(error);
    });
};

export const getAllGhiNumbers = (callback) => {
  const config = {
    headers,
  };
  axios.get('/rest/practice_manager/get_all_ghi_numbers', config)
    .then((response) => {
      callback(response.data.all_ghi_numbers);
    })
    .catch((error) => {
      logger.warn(error);
    });
};

export const getAllAdminPermissions = (callback) => {
  const config = {
    headers,
  };
  axios.get('/rest/shares/get_all_admin_permissions', config)
    .then((response) => {
      callback(response.data.users);
    })
    .catch((error) => {
      logger.warn(error);
    });
};
