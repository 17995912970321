/* eslint-disable no-nested-ternary, jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TablePaginationActions from './TablePaginationActions';
import { modifiedOrders } from '../../../helpers';
import gdtIcons from '../../../../../assets/images/icons/gdt-icons.svg';
import { getTests } from '../../../../addEditOrder/utils/helper';

const useStyles = makeStyles(theme => ({
  tableSortLabel: {
    flexDirection: 'row-reverse !important',
    '&.Mui-active': {
      color: `${theme.palette.primary.main} !important`,
    },
    marginLeft: '-25px !important',
  },
  disableColumn: {
    pointerEvents: 'none',
    cursor: 'pointer',
  },
  tableBorder: {
    borderBottom: '1px solid #eee',
    whiteSpace: 'nowrap',
  },
  tableCellMaxWidth: {
    borderBottom: '1px solid #eee',
    maxWidth: '180px',
    paddingRight: '20px',
  },
  physicianMaxWidth: {
    borderBottom: '1px solid #eee',
    maxWidth: '200px',
    paddingRight: '40px',
  },
  tableBoxSize: {
    minWidth: '1600px', mb: 2,
  },
  wrap: {
    overflowWrap: 'break-word'
  }
}));

function EnhancedTableHead(props) {
  const styles = useStyles();
  const {
    order, orderBy, onRequestSort, columns, completeOrders,
  } = props;

  const createSortHandler = property => (event) => {
    onRequestSort(event, property);
  };

  return (
    <thead>
      <tr>
        {columns.map(column => (
          <React.Fragment>
            {(column.id !== 'download') ? (
              <th
                key={column.id}
                sortDirection={orderBy === column.id ? order : false}
                className={!completeOrders && styles.disableColumn}
                style={{
                  textAlign: column.id === 'status' && 'center', borderTop: '2px solid #eee', borderBottom: '2px solid #eee', textTransform: 'none',
                }}
              >
                <TableSortLabel
                  key={column.id}
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                  className={styles.tableSortLabel}
                >
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                  {column.key}
                </TableSortLabel>
              </th>
            ) : (
              <th
                key={column.id}
                align="center"
                style={{
                  textAlign: 'center', borderTop: '2px solid #eee', borderBottom: '2px solid #eee', textTransform: 'none',
                }}
              >
                {column.key}
              </th>
            )}
          </React.Fragment>
        ))}
      </tr>
    </thead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  completeOrders: PropTypes.array.isRequired,
};

const OrdersTable = (props) => {
  const {
    orders,
    columns,
    pageChange,
    totalRecords,
    ordersType,
    pageLimit,
    downloadPdf,
    editOrder,
    history,
    onSortRequest,
    roleBasedAuthorisation,
  } = props;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('lastAction');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);
  const [pageChangeSortVal, setSortVal] = React.useState({ sortBy: 'updatedAt', orderBy: 'desc' });
  const styles = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let newProperty;
    switch (property) {
      case 'patient': newProperty = 'patient.demographic.lastName'; break;
      case 'dob': newProperty = 'patient.demographic.dob'; break;
      case 'testOrdered': newProperty = 'testOrdered.name'; break;
      case 'diagnosis': newProperty = 'diagnosis.disease'; break;
      case 'createdBy': newProperty = 'orderingUser.lastName'; break;
      case 'lastAction': newProperty = 'updatedAt'; break;
      case 'physician': newProperty = 'authorizingProvider.lastName'; break;
      case 'affiliation': newProperty = 'client.practiceName'; break;
      case 'status': newProperty = 'status'; break;
      default: newProperty = 'updatedAt';
    }
    onSortRequest(newProperty, isAsc ? 'desc' : 'asc');
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSortVal({ sortBy: newProperty, orderBy: isAsc ? 'desc' : 'asc' });
  };

  const handleChangePage = (event, pageNum) => {
    pageChange(pageNum, rowsPerPage, pageChangeSortVal);
    setPage(pageNum);
  };

  const handleChangeRowsPerPage = (event) => {
    pageChange(0, parseInt(event.target.value, 10), pageChangeSortVal);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const completeOrders = modifiedOrders(orders);

  const testOrderedDisplayNames = (testNames = '', followOnTestOrdered = '') => {
    const code = testNames != null ? testNames.split(',') : [];
    if (followOnTestOrdered !== '') {
      code.push(followOnTestOrdered);
    }

    // eslint-disable-next-line no-nested-ternary
    const result = getTests(code).split(", ");
    return result.map((displayName, index) => {
      const isLast = result.length === (index + 1);
      return !isLast ? (
        <>
          {displayName}
          <br />
        </>
      ) : displayName;
    });
  };


  const editOrders = (event, currentOrder) => {
    event.preventDefault();
    let newOrderData;
    let bloodSpecimen = currentOrder?.specimens?.bloodSpecimen || {};
    const tissueSpecimen = currentOrder?.specimens?.tissueSpecimen || {};
    const testNames = currentOrder?.testOrdered?.name || [];

    // else {
    //   bloodSpecimen = currentOrder?.specimens?.bloodSpecimen;
    // }
    if (currentOrder.billing.primaryInsurance === null) {
      newOrderData = {
        ...currentOrder,
        billing: {
          ...currentOrder.billing,
          primaryInsurance: {},
        },
      };
    } else {
      newOrderData = currentOrder;
    }
    if (currentOrder?.specimens?.bloodSpecimen === null) {
      bloodSpecimen = {
        bloodSpecimen: {},
        tissueSpecimen,

      };


      newOrderData.specimens = { ...bloodSpecimen };
    }

    if (testNames.length === 0) {
      newOrderData.testOrdered.name = testNames;
    }


    editOrder(newOrderData, ordersType);
    history.push('/new_orders/new');
  };
  if (totalRecords < 10 && page !== 0) {
    setPage(0);
  }
  return (
    <div className={styles.tableBoxSize}>
      <table className="mui-table mui--text-body2">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
          completeOrders={completeOrders}
        />
        <tbody>
          {completeOrders ? completeOrders.map(completeOrder => (
            <tr
              key={completeOrder.id}
              hover
              tabIndex={-1}
            >
              <td align="left" className={`${styles.tableCellMaxWidth} ${styles.wrap}`}>
                <a
                  href="#"
                  onClick={event => editOrders(event, completeOrder.currentOrder)}
                  style={{ borderBottom: '1px solid #18A9E3', pointerEvents: roleBasedAuthorisation.toJS().patientNameClickable ? '' : 'none' }}
                >
                  {completeOrder.patient}
                </a>
              </td>
              {roleBasedAuthorisation.toJS().dobFlag
                ? (<td align="left" className={styles.tableBorder}>{completeOrder.dob || ''}</td>)
                : (<td align="left" className={styles.tableBorder}>MM-DD-YYYY</td>)
              }
              <td align="left" className={styles.tableBorder}>{testOrderedDisplayNames(completeOrder.testOrdered, completeOrder.followOnTestOrdered)}</td>
              <td align="left" className={styles.tableBorder}>{completeOrder?.trfDisplayName || ''}</td>
              <td align="left" className={`${styles.tableCellMaxWidth} ${styles.wrap}`}>{completeOrder.createdBy || ''}</td>
              <td align="left" className={styles.tableBorder}>{completeOrder.lastAction || ''}</td>
              {ordersType === 'completed' && (
              <td style={{ textAlign: 'center' }} className={styles.tableBorder}>
                {
                      (completeOrder.status === 'PRINTED' || completeOrder.status === 'COMPLETE') && (
                        <svg className="gdt-icon">
                          <use href={`${gdtIcons}#print`} />
                        </svg>
                      )
                    }
                {
                      completeOrder.status === 'SIGNED' && (
                      <svg className="gdt-icon" color="#D82A34" style={{ backgroundColor: '#FFD600' }}>
                        <use href={`${gdtIcons}#file-download`} />
                      </svg>
                      )
                    }
              </td>
              )}
              <td align="left" className={`${styles.physicianMaxWidth} ${styles.wrap}`}>{completeOrder.physician || ''}</td>
              <td align="left" className={`${styles.tableBorder} ${styles.wrap}`}>{completeOrder.affiliation !== 'undefined' ? completeOrder.affiliation : ''}</td>
              {ordersType !== 'pending' && (
              <td style={{ textAlign: 'center' }} className={styles.tableBorder}>
                <IconButton size="small" onClick={() => downloadPdf(completeOrder.portalOrderId)} disabled={!roleBasedAuthorisation.toJS().download}>
                  <svg className="gdt-icon">
                    <use href={`${gdtIcons}#file-download`} />
                  </svg>
                </IconButton>
              </td>
              )}
            </tr>
          )) : (
            <tr>
              <td colSpan="9" align="center">
                <Typography variant="h5" sx={{ my: '20px' }}>
                  No Patients Found
                </Typography>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="gdt-pagination" style={{ paddingTop: '10px' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage={<p style={{ paddingTop: '20px' }}>Rows per page</p>}
          labelDisplayedRows={({ from, to, count }) => (<p style={{ paddingTop: '20px' }}>{`${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}</p>)}
        />
      </div>
    </div>
  );
};
export default OrdersTable;
OrdersTable.propTypes = {
  orders: PropTypes.array,
  columns: PropTypes.array.isRequired,
  pageChange: PropTypes.func.isRequired,
  totalRecords: PropTypes.number,
  ordersType: PropTypes.string,
  pageLimit: PropTypes.number.isRequired,
  downloadPdf: PropTypes.func,
  editOrder: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
  onSortRequest: PropTypes.func.isRequired,
  roleBasedAuthorisation: PropTypes.object.isRequired,
};
OrdersTable.defaultProps = {
  orders: [],
  totalRecords: 0,
  ordersType: undefined,
  downloadPdf: undefined,
};
