export class RequestSerializer {
  static deserialize = info => (
    {
      cancerType: info.cancer_type,
      downloadLinks: info.download_links.map(dl => ({ label: dl.display_name, url: dl.link })),
      patientDateOfBirth: info.patient_date_of_birth,
      parentRequestId: info.parent_request_id,
      patientName: info.patient_name,
      patientPath: info.patient_path,
      physicianName: info.physician_name,
      practiceName: info.practice_name,
      receivedDate: info.received_date,
      reportDate: info.report_date,
      reportStatus: info.report_status,
      requestId: info.request_id,
      sampleType: info.sample_type,
      testType: info.test_type,
    }
  )
}
