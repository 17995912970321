import React from 'react';
import PropTypes from 'prop-types';

import styles from './share-modal.module.scss';

const IPropTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  patient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const ShareModal = ({ onClose, content, patient }) => (
  <div className={styles.outerContainer}>
    <div className={styles.container}>
      <button className={styles.closeButton} type="button" onClick={onClose}>
        <i className="fa fa-close" />
      </button>
      <div className={styles.header}>
        {`SHARE ${patient.lastName} ${patient.firstName}'S REPORT`}
      </div>
      {content}
    </div>
  </div>
);

ShareModal.propTypes = IPropTypes;

export { ShareModal };
