import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { store } from '../../store';
import ConnectedSharesContainer from './SharesContainer';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  accessClass: PropTypes.string.isRequired,
  viewAsUser: PropTypes.object.isRequired,
};
const SharesRootContainer = (props) => {
  const {
    authenticityToken,
    accessClass,
    viewAsUser
  } = props;
  return (
    <Provider store={store}>
      <ConnectedSharesContainer {...{ authenticityToken, accessClass, viewAsUser }} />
    </Provider>
  );
};
export default SharesRootContainer;
SharesRootContainer.propTypes = IPropTypes;
