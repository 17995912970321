import React from 'react';

const SiteManagerShare = () => (
  <div className="modal__container-outer edit__site-manager-shares">
    <div className="modal__container-inner">
      <div className="modal__content">
        <div className="site-manager-shares-edit">
          <i className="fa fa-close modal-close" />
          <div className="settings-edit__container">
            <div className="header">
              Grant Access to Reports
            </div>
            <div className="content">
              <div className="recipient-input-text">
                REPORTS-ACCESS RECIPIENTS:
              </div>
              <div className="errors input__email-invite__errors" />
              <i className="fa fa-search site-manager-shares-edit__search" />
              <input type="search" className="input__email-invite" id="input__email-invite" placeholder="john@example.com; claire@example.com" />
              <div className="physician-to-share-text">PHYSICIANS THEY WILL HAVE ACCESS TO:</div>
              <div className="errors.check-box__physician__errors" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default SiteManagerShare;
