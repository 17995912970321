class InterpretationAndMethodologySerializer {
  static deserialize(data) {
    return data && {
      interpretationAndUse: data.interpretationAndUse,
      methodology: data.methodology,
      limitations: data.limitations,
      references: data.references,
    };
  }
}

export { InterpretationAndMethodologySerializer };
