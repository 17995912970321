import { Biomarker } from './biomarker';

class BiomarkersTable {
  constructor(params) {
    this.biomarkers = params.biomarkers.map(b => new Biomarker(b));
    this.footnote = params.footnote;
  }
}

export { BiomarkersTable };
