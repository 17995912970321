import { connect } from 'react-redux';

import { AddPhysicianInfo } from '../../../../store/actions/orders/active-order/physician_info';
import { AdditionalRecipientContainer } from './additional-recipient-container';

const mapStateToProps = ({
  orders: { activeOrder: { physicianInfo: { secondaryRecipient } } },
}) => ({
  secondaryRecipient: {
    secondaryRecipientPhone: secondaryRecipient.secondaryRecipientPhone,
    secondaryRecipientFax: secondaryRecipient.secondaryRecipientFax,
    secondaryRecipientFirstname: secondaryRecipient.secondaryRecipientFirstname,
    secondaryRecipientLastname: secondaryRecipient.secondaryRecipientLastname,
  },
});

const mapDispatchToProps = dispatch => ({
  savePhysicianInfo: (info, hideBanner) => dispatch(AddPhysicianInfo(info, hideBanner)),
});

const ConnectedAdditionalRecipientContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(AdditionalRecipientContainer);

export { ConnectedAdditionalRecipientContainer as AdditionalRecipientContainer };
