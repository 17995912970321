import React from 'react';
import PropTypes from 'prop-types';
import styles from './LunarPatientDownload.module.scss';

const IPropTypes = {
  canDownloadReport: PropTypes.bool.isRequired,
  downloadReportEnabled: PropTypes.bool.isRequired,
};

const LunarPatientDownload = ({ canDownloadReport, downloadReportEnabled }) => {
  const { downloadLink } = window.gon;
  const disabled = !canDownloadReport || !downloadReportEnabled;

  const buttonClasses = [styles.downloadButton];
  if (disabled) {
    buttonClasses.push('disabled');
  }

  return (
    <button type="button" className={buttonClasses.join(' ')} disabled={disabled} onClick={() => window.open(downloadLink)}>
      <span className={styles.downloadText}>
        DOWNLOAD REPORT &nbsp;
      </span>
      <div className="fa fa-download" />
    </button>
  );
};

LunarPatientDownload.propTypes = IPropTypes;

export { LunarPatientDownload };
