import { electronicSignatureConfirmationActionTypes } from '../../actions/orders/electronic-signature-confirmation';

const initialState = {
  showModal: false,
};

const electronicSignatureConfirmationReducer = (state = initialState, action) => {
  switch (action.type) {
    case electronicSignatureConfirmationActionTypes.TOGGLE_ELECTRONIC_SIGNATURE_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
      };
    default:
      return state;
  }
};

export { electronicSignatureConfirmationReducer };
