import HttpService from '../services/HttpService';

const xCsrfToken = 'X-CSRF-Token';
let authInterceptorId = null;

export const buildAuthInterceptor = csrfToken => (config) => {
  if (config[xCsrfToken] && config[xCsrfToken] === csrfToken) {
    return config;
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      [xCsrfToken]: csrfToken,
    },
  };
};

export const setupAuthInterceptor = (csrfToken) => {
  const authInterceptor = buildAuthInterceptor(csrfToken);
  authInterceptorId = HttpService.interceptors.request.use(
    authInterceptor, error => Promise.reject(error),
  );
};

export const clearAuthInterceptor = () => {
  if (authInterceptorId !== null) {
    HttpService.interceptors.request.eject(authInterceptorId);
    authInterceptorId = null;
  }
};
