export class LoggedUserSerializer {
  static deserialize = info => (
    info && {
      admin: info.admin,
      authenticityToken: info.authenticity_token,
      canDownloadReport: info.can_download_report,
      canReleasePatient: info.can_release_patient,
      canReleaseReport: info.can_release_report,
      canSeeComments: info.can_see_comments,
      canShareReport: info.can_share_report,
      id: info.id,
      releaseReportsEnabled: info.release_reports_enabled,
      rootUrl: info.root_url,
      shareReportEnabled: info.share_report_enabled,
      showDisclaimer: info.show_disclaimer,
    }
  );
}
