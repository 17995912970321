import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    top: theme.spacing(8),
    paddingLeft: '1.5rem',
  },
  content: {
    width: '100%',
  },
}));
const IPropTypes = {
  confirm: PropTypes.bool.isRequired,
  setConfirm: PropTypes.func.isRequired,
};

export default function DescriptionAlerts(props) {
  const { confirm, setConfirm } = props;
  const classes = useStyles();
  return (
    <Snackbar
      className={classes.root}
      open={confirm}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={setConfirm}
    >
      <Alert className={classes.content} severity="error" onClose={() => setConfirm(false)}>
        Organization error
      </Alert>
    </Snackbar>
  );
}
DescriptionAlerts.propTypes = IPropTypes;
