import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';
import { GuardantRevealOrderingExpanded } from '../guardant-reveal-ordering-expanded';
import { GuardantRevealOrderingCollapsed } from '../guardant-reveal-ordering-collapsed';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
};

const GuardantRevealOrderingContainer = ({ enabled, onToggle }) => (
  <>
    <GenericHeader
      enabled={enabled}
      handleContainerArrowClick={onToggle}
      label="Guardant Reveal Ordering"
    />

    {
      enabled
        ? <GuardantRevealOrderingExpanded />
        : <GuardantRevealOrderingCollapsed onToggle={onToggle} />
    }
  </>
);

GuardantRevealOrderingContainer.propTypes = IPropTypes;
GuardantRevealOrderingContainer.defaultProps = defaultProps;

export { GuardantRevealOrderingContainer };
