const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL';
const TOGGLE_SHARE_MESSAGE = 'TOGGLE_SHARE_MESSAGE';
const HIDE_FORM_VALIDATION_MESSAGE = 'HIDE_FORM_VALIDATION_MESSAGE';
const SHOW_FORM_VALIDATION_MESSAGE = 'SHOW_FORM_VALIDATION_MESSAGE';

const toggleShareMessageAction = () => ({
  type: TOGGLE_SHARE_MESSAGE,
});

const toggleShareModalAction = () => ({
  type: TOGGLE_SHARE_MODAL,
});

const hideFormValidationMessageAction = () => ({
  type: HIDE_FORM_VALIDATION_MESSAGE,
});

const showFormValidationMessageAction = () => ({
  type: SHOW_FORM_VALIDATION_MESSAGE,
});

export {
  TOGGLE_SHARE_MODAL,
  TOGGLE_SHARE_MESSAGE,
  HIDE_FORM_VALIDATION_MESSAGE,
  SHOW_FORM_VALIDATION_MESSAGE,
  toggleShareModalAction,
  toggleShareMessageAction,
  hideFormValidationMessageAction,
  showFormValidationMessageAction,
};
