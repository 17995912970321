import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PropTypes from 'prop-types';
import Controls from '../forms/controls';


const useStyles = makeStyles(() => ({
  dialog: {
    padding: useTheme().spacing(2),
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: '250px',
    minWidth: '450px',
    margin: 'auto',
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '0',
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAction: {
    justifyContent: 'center',
  },
  titleIcon: {
    backgroundColor: 'transparent',
    color: useTheme().palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '3rem',
      color: '#F05A5C',
    },
  },
  deleteButton: {
    boxShadow: 'none',
    backgroundColor: '#F05A5C',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#F42534',
    },
  },
  cancelButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  container: {
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
}));
const IPropTypes = {
  confirmDialog: PropTypes.object.isRequired,
  setConfirmDialog: PropTypes.func.isRequired,
};

const ConfirmModalWindow = (props) => {
  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();

  return (
    // eslint-disable-next-line max-len
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog, container: classes.container }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon} size="large">
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Controls.Button
          text="Cancel"
          color="default"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
          className={classes.cancelButton}
        />
        <Controls.Button
          className={classes.deleteButton}
          text="Delete"
          color="secondary"
          onClick={confirmDialog.onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmModalWindow;
ConfirmModalWindow.propTypes = IPropTypes;
