import React, { useState } from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  optionsForSelect: PropTypes.array.isRequired,
  requestsData: PropTypes.array.isRequired,
  multi_product: PropTypes.bool.isRequired,
};

function VerifiedPatientTimeline(props) {
  const [state, setState] = useState({ selected: '' });
  const {
    optionsForSelect: options,
    requestsData,
    multi_product: multiProduct,
  } = props;
  if (options && options.length > 0 && state.selected === '') {
    setState({ selected: options[0].label });
  }
  return (
    <div className="container">
      <div className="row content_">
        <div className="date-pick-container">
          <div className="dropdown bootstrap-select fake-select bs3">
            <select defaultValue={state.selected}>
              {options.map(opt => (
                <option key={`${opt.label}.${opt.data}`} data={opt.data} value="">
                  {opt.label}
                </option>
              ))}
            </select>
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <button
              type="button"
              className="btn dropdown-toggle btn-default"
              data-toggle="dropdown"
              role="button"
              title={(options.find(opt => opt.label === state.selected) || {}).label}
              aria-expanded="true"
            >
              <div className="filter-option">
                <div className="filter-option-inner">
                  <div className="filter-option-inner-inner">
                    {(options.find(opt => opt.label === state.selected) || {}).label}
                  </div>
                </div>
              </div>
              <span className="bs-caret">
                <span className="caret" />
              </span>
            </button>
            <div
              className="dropdown-menu open"
              style={{
                maxHeight: '763px',
                overflow: 'hidden',
                minHeight: '0px',
              }}
            >
              <div
                className="inner open"
                role="listbox"
                aria-expanded="false"
                tabIndex={-1}
                style={{
                  maxHeight: '763px',
                  overflowY: 'auto',
                  minHeight: '0px',
                }}
              >
                <ul className="dropdown-menu inner ">
                  {options.map(opt => (
                    <li
                      key={`${opt.label}.${opt.data}`}
                      role="presentation"
                      className={`${
                        opt.label === state.selected && 'selected active'
                      }`}
                      onClick={() => setState({ selected: opt.label })}
                    >
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        role="option"
                        aria-disabled="false"
                        tabIndex={0}
                        className={`${
                          opt.label === state.selected && 'selected active'
                        }`}
                        aria-selected="true"
                      >
                        <span className="text">{opt.label}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="date-pick-arrow" />
        </div>
        {requestsData.map(
          req => state.selected?.includes(req.report_type) && (
          <div className="patient-request__timeline" data-index={0} key={`${req.report_type}.${req.request_id}`}>
            <div
              className="col-xs-12 col-md-10 col-md-offset-1"
              data-target={req.request_id}
            >
              <div className="report-title hidden-xs">
                YOUR REPORT STATUS
              </div>
              <div className="report-info hidden-xs">
                {req.status.message}
              </div>
              {(req.status['auto_released_to_patient'] === true || req.status['released_to_physician'] == true || req.status['physician_released_to_patient'] === true) &&
                (<div className="steps-container">
                <p className="steps-title visible-xs">
                  {req.status['latest-status'][0]}
                </p>
                <p className="steps-date visible-xs">
                  {req.status['latest-status'][1]}
                </p>
                <div className="steps-line" />
                <div className="steps-block done" id="step-01">
                  <div className="steps-circle" data-target={1}>
                    <div className="steps-check" />
                    <div className="steps-icon" />
                  </div>
                  {multiProduct ? (
                    <p className="steps-title">
                      Sample
                      <br />
                      Collected
                    </p>
                  ) : (
                    <p className="steps-title">
                      Blood
                      <br />
                      Drawn
                    </p>
                  )}
                  <p className="steps-date">
                    {req.collection_date}
                  </p>
                </div>
                <div className="steps-line ind" />
                <div className="steps-block done" id="step-02">
                  <div className="steps-circle" data-target={2}>
                    <div className="steps-check" />
                    <div className="steps-icon" />
                  </div>
                  <p className="steps-title">
                    Sample
                    <br />
                    Received
                  </p>
                  <p className="steps-date">
                    {req.received_date}
                  </p>
                </div>
                <div className="steps-line ind second" />
                <div className={`steps-block ${req.status['step-03-class']}`} id="step-03">
                  <div className="steps-circle" data-target={3}>
                    <div className="steps-check" />
                    <div className="steps-icon" />
                  </div>
                  {multiProduct && req.tissue_report ? (
                    <p className="steps-title">
                      Test
                      <br />
                      Pending
                    </p>
                  ) : (
                    <p className="steps-title">
                      Sample
                      <br />
                      Processing
                    </p>
                  )}
                  <p className="steps-date">
                    {req.received_date}
                  </p>
                </div>
                <div className="steps-line ind third" />
                <div className={`steps-block ${req.status['step-04-class']}`} id="step-04">
                  <div className="steps-circle" data-target={4}>
                    <div className="steps-check" />
                    <div className="steps-icon" />
                  </div>
                  <p className="steps-title">
                    Report Released to
                    <br />
                    Your Doctor
                  </p>
                  <p className="steps-date">
                    {req.updated_date_formatted}
                  </p>
                </div>
                <div className="steps-line ind last" />
                <div className={`steps-block ${req.status['step-05-class']}`} id="step-05">
                  <div className="steps-circle" data-target={5}>
                    <div className="steps-check" />
                    <div className="steps-icon" />
                  </div>
                  <p className="steps-title">
                    Report Released
                    <br />
                    to You
                  </p>
                  <p className="steps-date">
                    {req.auto_release_date_formatted}
                  </p>
                </div>
              </div>)}
              <div className="report-info visible-xs">
                {req.status.message}
              </div>
              {req.download_link && (
              <div className="download-button-container row">
                <a
                  className="download-button"
                  target="_blank"
                  href={req.download_link.link}
                  rel="noreferrer"
                >
                  Download Report
                </a>
              </div>
              )}
              {req.released_report.length > 0
                    && req.released_report.map(r => (
                      <div
                        className="report-info-block-container"
                        id="doc-messages"
                        key={r.id}
                      >
                        <div className="report-info-block-header">
                          MESSAGE FROM YOUR PHYSICIAN
                        </div>
                        <div className="report-info-block-content">
                          <div className="row report-message">
                            <div className="col-xs-12">
                              <p className="report-info-block-label">
                                {r.releasing_user_fullname}
                                <span>
                                  {' '}
                                  {r.released_report.released_at}
                                </span>
                              </p>
                              <p className="report-info-block-text">
                                {r.released_report.message}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              {(req.status['auto_released_to_patient'] === true || req.status['released_to_physician'] == true || req.status['physician_released_to_patient'] === true) &&
                (<div className="report-info-block-container">
                <div className="report-info-block-header">
                  REPORT INFORMATION
                </div>
                <div className="report-info-block-content">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <p className="report-info-block-label">
                        {req.multi_product
                          ? 'Sample Received Date'
                          : ' Blood Drawn Date'}
                      </p>
                      <p className="report-info-block-text">
                        {req.collection_date}
                      </p>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <p className="report-info-block-label">
                        Sample Received Date
                      </p>
                      <p className="report-info-block-text">
                        {req.received_date}
                      </p>
                    </div>
                    <div className="col-xs-12 col-sm-1" />
                    <div className="col-xs-12 col-sm-3">
                      <p className="report-info-block-label">
                        Accession ID
                      </p>
                      <p className="report-info-block-text">
                        {req.request_id}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <p className="report-info-block-label">
                        Physician Phone
                      </p>
                      <p className="report-info-block-text">
                        {req.phone_number}
                      </p>
                    </div>
                    <div className="col-xs-12 col-sm-8">
                      <p className="report-info-block-label">
                        PHYSICIAN NAME
                      </p>
                      <p className="report-info-block-text">
                        {req.physician_last_name}
                        ,
                        {' '}
                        {req.physician_first_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>)}
            </div>
          </div>
          ),
        )}
      </div>
    </div>
  );
}

export default VerifiedPatientTimeline;
VerifiedPatientTimeline.propTypes = IPropTypes;
