import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../Dropdown';
import { PatientInformationShowAll } from '../patient-information-show-all';
import { PatientInformationDemoFaceSheet } from '../patient-information-demo-face-sheet';
import { FormatHelper } from '../../helpers/format_helper';
import { DateInput } from '../../../generic/DateInput';
import { OnlineOrderingFieldsLimits } from '../../../../config/input-character-limit';

const IPropTypes = {
  savePatientInfo: PropTypes.func.isRequired,
  patientInfo: PropTypes.object.isRequired,
  patientTypes: PropTypes.array.isRequired,
  showSelectPatientType: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  patientStatuses: PropTypes.array.isRequired,
  patientSexes: PropTypes.array.isRequired,
  showEnterAllInfo: PropTypes.bool,
  showDemoFaceSheet: PropTypes.bool,
  setProgressionPatientTypeDefault: PropTypes.func.isRequired,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  showEnterAllInfo: false,
  showDemoFaceSheet: false,
  highlightRequiredFields: false,
};
class PatientInformationExpanded extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientTypeIsEnabled: true,
      isLoading: true,
    };
    this._storeSelectedItem = this._storeSelectedItem.bind(this);
    this._displayInitialFormat = this._displayInitialFormat.bind(this);
    this._checkForHighlightingField = this._checkForHighlightingField.bind(this);
  }

  componentDidMount() {
    const { patientType } = this.props.patientInfo;
    if (patientType && patientType === 'Existing Guardant360 Patient') {
      this.setState({
        patientTypeIsEnabled: false,
      });
      this.props.setProgressionPatientTypeDefault();
    }
    this.setState({ isLoading: false });
  }

  _storeSelectedItem(id, info) {
    const { savePatientInfo, patientInfo } = this.props;

    if (patientInfo[id] !== info) {
      savePatientInfo({ [id]: info }, true);
    }
  }

  _displayInitialFormat() {
    return !this.props.showSelectPatientType;
  }

  _checkForHighlightingField(fieldName) {
    const { highlightRequiredFields, patientInfo } = this.props;
    return highlightRequiredFields && !patientInfo[fieldName];
  }

  render() {
    const { patientInfo } = this.props;
    const { isLoading } = this.state;

    const { showSelectPatientType } = this.props;

    const patientStatusTitle = patientInfo.patientStatus || 'Choose One';
    const patientSexTitle = 'Select';

    return (
      <div className="accordion-section flex-container__inner-container">
        { !showSelectPatientType && (
          <div className="flex-container__etrf-section">
            <div className="flex-container__etrf-row">
              <span className="required-fields-text">* Required fields</span>
            </div>
          </div>
        )}
        <div className="flex-container__etrf-section">
          <div className={['flex-container__etrf-row', showSelectPatientType ? 'collapsed' : ''].join(' ')}>
            <div className={this._displayInitialFormat() ? 'flex-container__etrf-column' : 'flex-container__etrf-column__single'}>
              <div className="action-label bold">Patient Type</div>
              {
                  !isLoading && this.state.patientTypeIsEnabled
                    ? (
                      <div className="content-space-breathable">
                        <Dropdown
                          title="Choose One"
                          list={this.props.patientTypes}
                          toggleSelected={this.props.toggleSelected}
                          resetThenSet={this.props.onFieldChange}
                          storeSelectedItem={this._storeSelectedItem}
                          defaultValue={patientInfo.patientType}
                        />
                      </div>
                    )
                    : (

                      <input
                        className="content-space-breathable read-only"
                        type="text"
                        readOnly
                        value="Exisiting Guardant360 Patient"
                      />
                    )
                }
            </div>
            {
              !showSelectPatientType
                && (
                  <>
                    <div className="flex-container__etrf-column">
                      <div className={`action-label bold ${this._checkForHighlightingField('patientStatus') ? 'highlighted' : ''}`}>
                        Status
                        <span className="highlighted-asterix">*</span>
                      </div>
                      <div className="content-space-breathable">
                        {
                          !!this.props.patientStatuses
                          && (
                            <Dropdown
                              title={patientStatusTitle}
                              list={this.props.patientStatuses}
                              toggleSelected={this.props.toggleSelected}
                              resetThenSet={this.props.onFieldChange}
                              storeSelectedItem={this._storeSelectedItem}
                              defaultValue={patientInfo.patientStatus}
                              highlightedBorder={this._checkForHighlightingField('patientStatus')}
                            />
                          )
                        }
                      </div>
                    </div>
                    <div className="flex-container__etrf-column">
                      <div className="action-label bold">Medical Record Number</div>
                      <input
                        className="content-space-breathable"
                        placeholder={'Patient\'s MRN'}
                        type="text"
                        value={patientInfo.patientMedicalRecordNumber || ''}
                        onChange={e => this._storeSelectedItem('patientMedicalRecordNumber', e.target.value)}
                        maxLength={OnlineOrderingFieldsLimits.genericInputMaxLength}
                      />
                    </div>
                  </>
                )
            }
          </div>
        </div>
        {
          !showSelectPatientType
          && (
          <div className="flex-container__etrf-section">
            <div className="flex-container__etrf-row">
              <div className="flex-container__etrf-column">
                <div className={`action-label bold ${this._checkForHighlightingField('patientLastName') ? 'highlighted' : ''}`}>
                  Last Name
                  <span className="highlighted-asterix">*</span>
                </div>
                <input
                  className={['content-space-breathable', this._checkForHighlightingField('patientLastName') ? 'red-box' : ''].join(' ')}
                  placeholder="e.g., Smith"
                  type="text"
                  value={patientInfo.patientLastName || ''}
                  onChange={e => this._storeSelectedItem('patientLastName', e.target.value)}
                  maxLength={OnlineOrderingFieldsLimits.genericInputMaxLength}
                />
              </div>
              <div className="flex-container__etrf-column">
                <div className={`action-label bold ${this._checkForHighlightingField('patientFirstName') ? 'highlighted' : ''}`}>
                  First Name
                  <span className="highlighted-asterix">*</span>
                </div>
                <input
                  className={['content-space-breathable', this._checkForHighlightingField('patientFirstName') ? 'red-box' : ''].join(' ')}
                  placeholder="e.g., Jane"
                  type="text"
                  value={patientInfo.patientFirstName || ''}
                  onChange={e => this._storeSelectedItem('patientFirstName', e.target.value)}
                  maxLength={OnlineOrderingFieldsLimits.genericInputMaxLength}
                />
              </div>
              <div className="flex-container__etrf-column">
                <div className="flex-row multiple-item">
                  <div className="action-label-container col-70">
                    <div className={`action-label bold ${this._checkForHighlightingField('patientBirthDate') ? 'highlighted' : ''}`}>
                      Date of Birth
                      <span className="highlighted-asterix">*</span>
                    </div>
                    <DateInput
                      className="content-space-breathable"
                      value={patientInfo.patientBirthDate || ''}
                      maxDate={FormatHelper.getTodaysDate()}
                      onInputChange={value => this._storeSelectedItem('patientBirthDate', value, true)}
                      highlightedBorder={this._checkForHighlightingField('patientBirthDate')}
                    />
                  </div>
                  <div className="action-label-container col-30">
                    <div className={`action-label bold ${this._checkForHighlightingField('patientSex') ? 'highlighted' : ''}`}>
                      Sex
                      <span className="highlighted-asterix">*</span>
                    </div>
                    <div className="content-space-breathable">
                      <Dropdown
                        title={patientSexTitle}
                        list={this.props.patientSexes}
                        toggleSelected={this.props.toggleSelected}
                        resetThenSet={this.props.onFieldChange}
                        storeSelectedItem={this._storeSelectedItem}
                        defaultValue={patientInfo.patientSex}
                        highlightedBorder={this._checkForHighlightingField('patientSex')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        }
        {
          this.props.showEnterAllInfo && !showSelectPatientType
            && (
              <PatientInformationShowAll
                toggleSelected={this.props.toggleSelected}
              />
            )
        }
        {
          this.props.showDemoFaceSheet && !showSelectPatientType
            && (
              <PatientInformationDemoFaceSheet
                toggleSelected={this.props.toggleSelected}
                storeSelectedItem={this._storeSelectedItem}
              />
            )
        }
      </div>
    );
  }
}

PatientInformationExpanded.propTypes = IPropTypes;
PatientInformationExpanded.defaultProps = defaultProps;

export { PatientInformationExpanded };
