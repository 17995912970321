import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';
import { StoreHelper } from '../../helpers/store_helper';

import baseEtrfStyles from '../../../../assets/stylesheets/etrf/base.module.scss';
import styles from './diagnosis-condition-early-stage-collapsed.module.scss';

const IIcdCode = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

const IPropTypes = {
  diagnosisEarlyStore: PropTypes.shape({
    diagnosisPrimaryCode: IIcdCode,
    diagnosisSecondaryCode: IIcdCode,
    diagnosisTertiaryCode: IIcdCode,
    diagnosisQuarternaryCode: IIcdCode,
    diagnosisQuinaryCode: IIcdCode,
    diagnosisSenaryCode: IIcdCode,
    diagnosisStage: PropTypes.string,
    diagnosisOriginalDiagnosis: PropTypes.string,
    diagnosisAdjuvantChemotherapy: PropTypes.string,
    disease: PropTypes.string,
  }).isRequired,
  onToggle: PropTypes.func,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  onToggle: () => {},
  highlightRequiredFields: false,
};

const DiagnosisConditionEarlyStageCollapsed = ({
  diagnosisEarlyStore: {
    diagnosisPrimaryCode, diagnosisSecondaryCode, diagnosisTertiaryCode,
    diagnosisQuarternaryCode, diagnosisQuinaryCode, diagnosisSenaryCode,
    diagnosisOriginalDiagnosis,
    diagnosisStage,
    diagnosisAdjuvantChemotherapy,
    disease,
  }, onToggle, highlightRequiredFields,
}) => {
  const diagnosisMultipleCodes = [
    diagnosisSecondaryCode, diagnosisTertiaryCode, diagnosisQuarternaryCode,
    diagnosisQuinaryCode, diagnosisSenaryCode,
  ].map(code => code.code).filter(Boolean).join(', ');

  return (
    <div className={[baseEtrfStyles.accordionSection, baseEtrfStyles.collapsed, styles.shadow].join(' ')} onClick={onToggle}>
      <div className={styles.row}>
        <div className={styles.column}>
          <CollapsedTab
            title="Primary Code"
            value={diagnosisPrimaryCode && diagnosisPrimaryCode.code}
            highlightTitle={
              highlightRequiredFields
                && (!diagnosisPrimaryCode || !diagnosisPrimaryCode.code)
            }
          />
          <CollapsedTab className={styles.noMargin} title="Original Diagnosis" value={StoreHelper.formatDate(diagnosisOriginalDiagnosis)} />
        </div>
        <div className={styles.column}>
          <CollapsedTab title="Secondary Code(s)" value={diagnosisMultipleCodes} largeValue />
          <CollapsedTab className={styles.noMargin} title="Stage" value={diagnosisStage} highlightTitle={highlightRequiredFields && !diagnosisStage} />
        </div>
        <div className={styles.column}>
          <CollapsedTab sectionClassname={styles.cancerTypeSection} title="Cancer Type" value={disease} />
          <CollapsedTab className={styles.noMargin} title="Adjuvant Chemotherapy" value={StoreHelper.formatDate(diagnosisAdjuvantChemotherapy)} />
        </div>
      </div>
    </div>
  );
};

DiagnosisConditionEarlyStageCollapsed.propTypes = IPropTypes;
DiagnosisConditionEarlyStageCollapsed.defaultProps = defaultProps;

export { DiagnosisConditionEarlyStageCollapsed };
