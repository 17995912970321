import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveBillingInfo, saveSelfInsuredBillingInfo } from '../../../../store/actions/orders/active-order';
import { BillingInformationExpanded } from './billing-information-expanded';

const mapDispatchToProps = dispatch => bindActionCreators({
  saveBillingInfo,
  saveSelfInsuredBillingInfo,
}, dispatch);

const mapStateToProps = ({
  orders: {
    activeOrder: {
      billingInfo,
      genericInfo: { productFamily },
    },
    staticData: { configuration },
  },
}) => {
  const res = {
    billingInfo,
    withInsuranceCopyCheckbox: null,
  };

  if (productFamily) {
    res.withInsuranceCopyCheckbox = configuration[productFamily]
      .formConfiguration.hasPatientDemoFaceSheetFields;
  }

  return res;
};

const ConnectedBillingInformationExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(BillingInformationExpanded);

export { ConnectedBillingInformationExpanded as BillingInformationExpanded };
