/* eslint-disable no-undef */
const validate = (fieldValues = values) => {
  const temp = { ...errors };
  if ('name' in fieldValues) {
    if (!fieldValues.name) {
      temp.name = 'This field is required.';
    } else {
      const nameValue = fieldValues.name;
      const regex = /[a-zA-Z ]$/;
      if (nameValue.match(regex)) {
        temp.name = '';
      } else {
        temp.name = 'Please enter a valid organization Name';
      }
    }
  }
  if ('alias' in fieldValues) {
    if (!fieldValues.alias) {
      temp.alias = 'This field is required.';
    } else {
      const aliasValue = fieldValues.alias;
      const regex = /^[a-z0-9 ]*$/;
      if (aliasValue.match(regex)) {
        temp.alias = '';
      } else {
        temp.alias = 'Please enter a valid alias Name';
      }
    }
  }
  if ('phone' in fieldValues) {
    const phoneNumber = fieldValues.phone;
    const regex = /^[0-9]{7,15}$/;
    if (!fieldValues.phone) {
      temp.phone = '';
    } else if (phoneNumber.match(regex)) {
      temp.phone = '';
    } else {
      temp.phone = 'Please enter 10 digits valid phone number eg. 7888565773';
    }
  }
  if ('mobile' in fieldValues) {
    const mobileNumber = fieldValues.mobile;
    const regex = /^[0-9]{7,15}$/;
    if (!fieldValues.mobile) {
      temp.mobile = '';
    } else if (mobileNumber.match(regex)) {
      temp.mobile = '';
    } else {
      temp.mobile = 'Please enter 10 digits valid mobile number eg. 7888565773';
    }
  }

  if ('fax' in fieldValues) {
    const faxNumber = fieldValues.fax;
    const regex = /(^$|[0-9]{7,15})/;
    if (!fieldValues.fax) {
      temp.fax = '';
    } else if (faxNumber.match(regex)) {
      temp.fax = '';
    } else {
      temp.fax = 'Please enter valid fax number';
    }
  }
  if ('website' in fieldValues) {
    const websiteValue = fieldValues.website;
    // eslint-disable-next-line no-useless-escape
    const regex = /^(http:\/\/|https:\/\/)?(www.)*?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    if (!fieldValues.website) {
      temp.website = '';
    } else if (websiteValue.match(regex)) {
      temp.website = '';
    } else {
      temp.website = 'Please enter valid email address eg. https://abc@domain.com';
    }
  }

  setErrors({
    ...temp,
  });
};

export default validate;
