import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';
import { StoreHelper } from '../../helpers/store_helper';
import { formatFirstLastName } from '../../util/order_util';

const IPropTypes = {
  billingInfo: PropTypes.object.isRequired,
  onToggle: PropTypes.func,
};

const defaultProps = {
  onToggle: () => {},
};

const BillingInformationCollapsed = ({
  billingInfo: {
    type,
    company,
    dob,
    groupNumber,
    insuredRelationship,
    subscriberFirstName,
    subscriberLastName,
    policyNumber,
  },
  onToggle,
}) => (
  <div className="requests-list requests-list--collapsed--reactified">
    <div
      className="accordion-section collapsed billing-information-container"
      onClick={onToggle}
    >
      <div className="billing-information-section">
        <div className="flex-row">
          <div className="billing-information-section__column">
            <CollapsedTab title="Insurance Type" display value={type} />
            <CollapsedTab className="no-padding" title="Primary Insurance" value={company} />
          </div>
          <div className="billing-information-section__column">
            <CollapsedTab title="Insured" value={insuredRelationship} />
            <div className="patient-information-column-1-1__double-column flex-row two-item">
              <CollapsedTab className="no-padding" title="Policy" value={policyNumber} largeValue />
              <CollapsedTab className="no-padding" title="Group" value={groupNumber} largeValue />
            </div>
          </div>
          <div className="billing-information-section__column">
            <CollapsedTab title="Insured Name" value={formatFirstLastName(subscriberFirstName, subscriberLastName)} largeValue />
            <CollapsedTab className="no-padding" title="Insured DOB" value={StoreHelper.formatDate(dob)} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

BillingInformationCollapsed.propTypes = IPropTypes;
BillingInformationCollapsed.defaultProps = defaultProps;

export { BillingInformationCollapsed };
