import React from 'react';

function SimplifiedRequestCounts() {
  return (
    <div className="container unverified__patient-request__timeline">
      <div className="row content_">
        <div className="patient-request__timeline">
          <div className="col-xs-12 col-md-10 col-md-offset-1" data-target="">
            <div className="report-title">WELCOME TO MYGUARDANT</div>
            <div className="report-info-check-list">
              Your profile is still pending verification. Once verified, you
              will get access to:
            </div>
            <div className="report-info-check-list">
              <div className="report-info-check-list-item">
                <div className="fa fa-check" />
                <span>LATEST REPORT STATUS</span>
              </div>
              <div className="report-info-check-list-item">
                <div className="fa fa-check" />
                <span>EMAIL NOTIFICATION</span>
              </div>
              <div className="report-info-check-list-item">
                <div className="fa fa-check" />
                <span>DOWNLOAD REPORT</span>
              </div>
            </div>
            <div className="report-info-check-list" />
            <div className="steps-container">
              <div className="steps-line" />
              <div className="steps-block undone" id="step-01">
                <div className="steps-circle" data-target="1">
                  <div className="steps-check" />
                  <div className="steps-icon" />
                </div>
                <p className="steps-title">
                  Blood
                  <br />
                  Drawn
                </p>
                <p className="steps-date" />
              </div>
              <div className="steps-line ind" />
              <div className="steps-block undone" id="step-02">
                <div className="steps-circle" data-target="2">
                  <div className="steps-check" />
                  <div className="steps-icon" />
                </div>
                <p className="steps-title">
                  Sample
                  <br />
                  Received
                </p>
                <p className="steps-date" />
              </div>
              <div className="steps-line ind second" />
              <div className="steps-block undone" id="step-03">
                <div className="steps-circle" data-target="3">
                  <div className="steps-check" />
                  <div className="steps-icon" />
                </div>
                <p className="steps-title">
                  Sample
                  <br />
                  Processing
                </p>
                <p className="steps-date" />
              </div>
              <div className="steps-line ind third" />
              <div className="steps-block undone" id="step-04">
                <div className="steps-circle" data-target="4">
                  <div className="steps-check" />
                  <div className="steps-icon" />
                </div>
                <p className="steps-title">
                  Report Released to
                  <br />
                  Your Doctor
                </p>
                <p className="steps-date" />
              </div>
              <div className="steps-line ind last" />
              <div className="steps-block undone" id="step-05">
                <div className="steps-circle" data-target="5">
                  <div className="steps-check" />
                  <div className="steps-icon" />
                </div>
                <p className="steps-title">
                  Report Released
                  <br />
                  to You
                </p>
                <p className="steps-date" />
              </div>
            </div>
            <div className="report-info-block-container">
              <div className="report-info-block-header">REPORT INFORMATION</div>
              <div className="report-info-block-content">
                <div className="row nothing-to-show">
                  Currently, there is nothing to show here.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimplifiedRequestCounts;
