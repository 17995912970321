import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { continueETRFLater, validateOrderAndPrint, toggleBillingOverlay } from '../../../store/actions/orders/active-order/order';
import { toggleConfirmationModalAction, hideFormValidationMessageAction, showFormValidationMessageAction } from '../../../store/actions/orders/active-order/confirmation_modal';
import { updateLoadingCount } from '../../../store/actions/global';
import { hideErrorBanner, showErrorBanner, showMissingRequiredFields } from '../../../store/actions/orders/active-order/form_errors';
import { OrdersActionContainer } from './orders-action-container';

const mapStateToProps = ({
  orders: { activeOrder },
  global: {
    initialProps: { userPermissions },
  },
}) => ({
  store: activeOrder,
  redirectContinueLater: activeOrder.genericInfo.redirectContinueLater,
  showFormValidationMessage: activeOrder.confirmationModal.showFormValidationMessage,
  warnings: activeOrder.confirmationModal.warnings,
  canChangeOrders: userPermissions.canChangeOrders,
  canOnlyDemoOrders: userPermissions.canOnlyDemoOrders,
  showContinueLaterButton: userPermissions.canContinueLaterOrders,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  continueETRFLater,
  toggleConfirmationModal: toggleConfirmationModalAction,
  hideFormValidationMessage: hideFormValidationMessageAction,
  triggerFormValidationMessage: showFormValidationMessageAction,
  validateOrderAndPrint,
  toggleBillingOverlay,
  hideErrorBanner,
  showErrorBanner,
  showMissingRequiredFields,
  updateLoadingCount,
}, dispatch);

const ConnectedOrdersActionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersActionContainer);

export { ConnectedOrdersActionContainer as OrdersActionContainer };
