import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


const useStyles = makeStyles({
  tableCell: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    position: 'relative',
    cursor: 'pointer',
    paddingLeft: '30px',
  },
  arrow: {
    position: 'absolute',
    left: '5px',
  },
});
const IPropTypes = {
  actions: PropTypes.array,
  headers: PropTypes.array,
  download: PropTypes.any,
  buttonClass: PropTypes.string,
  sortingFunc: PropTypes.func.isRequired,
};
const defaultProps = {
  actions: null,
  headers: [],
  download: null,
  buttonClass: null,
};
const TableHeader = ({
  actions = null, headers, download, buttonClass, sortingFunc,
}) => {
  const [arrowIcon, setArrow] = useState(null);
  const [arrowToggle, setToggle] = useState(false);
  const classes = useStyles();
  const headerKeys = headers || [];
  return (
    <TableHead>
      <TableRow>
        {headerKeys.map(item => (
          <TableCell
            className={classes.tableCell}
            key={item}
            onClick={(e) => {
              if (sortingFunc) {
                const input = e.target;
                setArrow(input.textContent);
                setToggle(!arrowToggle);
                sortingFunc(input.textContent);
              }
            }}
          >
            {arrowIcon === item.name
              && (arrowToggle ? (
                <ArrowDownwardIcon className={classes.arrow} />
              ) : (
                <ArrowUpwardIcon className={classes.arrow} />
              ))}
            {`${item.name}`}
          </TableCell>
        ))}
        {actions && (
          <TableCell className={classes.tableCell}>Actions</TableCell>
        )}
        {download && !buttonClass[0] && (
        <TableCell className={classes.tableCell}>Download</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
TableHeader.propTypes = IPropTypes;
TableHeader.defaultProps = defaultProps;
