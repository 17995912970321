import React, { useState, useEffect } from 'react';
import {
  Box, Paper, Tab, Tabs, Toolbar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TabContext, TabPanel } from '@mui/lab';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import DashboardHeader from './dashboard-header';
import OrdersList from './orders-table';
import NoOrders from './common/components/NoOrders';
import { Search, SearchIconWrapper } from './common/components/StyledInputs';
import BannerMessage from '../common/components/BannerMessage';
import MuiAutoComplete from './common/components/MuiAutoComplete';
import { completedPatchRequest, getFilterData } from './actions';
import { editPatientData } from '../helpers';

const useStyles = makeStyles(() => ({
  tabRoot: {
    fontStyle: 'italic',
  },
}));

const IPropTypes = {
  viewAsUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  completedOrders: PropTypes.array,
  pendingSignatureOrders: PropTypes.array,
  pendingOrders: PropTypes.array,
  totalCompletedRecords: PropTypes.number,
  totalPendingRecords: PropTypes.number,
  totalESignPendingRecords: PropTypes.number,
  isPendingOrdersLoaded: PropTypes.bool.isRequired,
  isCompletedOrdersLoaded: PropTypes.bool.isRequired,
  isPendingESignOrdersLoaded: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.array,
  history: PropTypes.any.isRequired,
  dashboardInitialLoad: PropTypes.bool,
  selectedTab: PropTypes.string.isRequired,
  roleBasedAuthorisation: PropTypes.any.isRequired,
};
const defaultProps = {
  completedOrders: [],
  pendingSignatureOrders: [],
  pendingOrders: [],
  totalCompletedRecords: 0,
  totalPendingRecords: 0,
  totalESignPendingRecords: 0,
  hasError: false,
  dashboardInitialLoad: false,
  errorMessage: [],
};

const Dashboard = (props) => {
  const {
    viewAsUser: {
      id,
      firstname,
      email,
    },
    history,
    actions: {
      loadPendingOrders,
      loadCompletedOrders,
      loadESignPendingOrders,
      closeBannerMessage,
      downloadEtrfPdf,
      editPatientOrder,
      ordersSelectedTab,
      ordersDashboardInitialLoad,
      resetDefaultState,
      ordersDashboardRoleBasedAuth,
      getAllcancer,
    },
    roleBasedAuthorisation,
    completedOrders,
    pendingSignatureOrders,
    pendingOrders,
    totalCompletedRecords,
    totalPendingRecords,
    totalESignPendingRecords,
    isPendingOrdersLoaded,
    isCompletedOrdersLoaded,
    isPendingESignOrdersLoaded,
    hasError,
    errorMessage,
    dashboardInitialLoad,
    selectedTab,
  } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({ offset: 0, limit: rowsPerPage });
  const [searchResults, setFilterResults] = useState([]);
  const [searchInputValues, setSearchInputValues] = useState({});
  const [searchKey, setSearchKey] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sortValues] = useState({ sortBy: 'updatedAt', orderBy: 'desc' });
  const classes = useStyles();
  const loadOrdersData = (pages = pagination,
    currentTab, sortObj = sortValues, data = searchInputValues) => {
    if (currentTab === '1') {
      loadPendingOrders(pages, id, sortObj, data, email);
    } else if (currentTab === '2') {
      loadESignPendingOrders(pages, id, sortObj, data, email);
    } else if (currentTab === '3') {
      loadCompletedOrders(pages, id, sortObj, data, email);
    }
  };
  const patchSuccessCallback = () => {
    loadESignPendingOrders(pagination, id, sortValues, {}, email);
  };
  const { ordersDashboardTable } = roleBasedAuthorisation.toJS();

  useEffect(() => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const envelopeId = searchParams.get('envelopeId');
    const productFamilyId = searchParams.get('productFamilyId');
    getAllcancer();
    if (envelopeId) {
      completedPatchRequest(envelopeId, productFamilyId, patchSuccessCallback);
    } else {
      loadESignPendingOrders(pagination, id, sortValues, {}, email);
    }
    resetDefaultState();
    ordersDashboardRoleBasedAuth();
  }, []);

  useEffect(() => {
    if (dashboardInitialLoad === true && isPendingESignOrdersLoaded) {
      if (totalESignPendingRecords === 0) {
        ordersDashboardInitialLoad(false);
        loadOrdersData(pagination, '1', sortValues);
        ordersSelectedTab('1');
      } else {
        ordersDashboardInitialLoad(false);
      }
    }
    if (searchKey) {
      if (totalPendingRecords > 0) {
        selectedTab !== '1' && tabChangeHandler('', '1');
      } else if (totalESignPendingRecords > 0) {
        selectedTab !== '2' && tabChangeHandler('', '2');
      } else if (totalCompletedRecords > 0) {
        selectedTab !== '3' && tabChangeHandler('', '3');
      }
    }
  }, [totalESignPendingRecords, totalCompletedRecords, totalPendingRecords]);

  const sortHandler = (sortBy, orderBy) => {
    loadOrdersData(pagination, selectedTab, { sortBy, orderBy }, searchInputValues, email);
  };
  const tabChangeHandler = (event, newValue) => {
    loadOrdersData({ offset: 0, limit: rowsPerPage }, newValue, sortValues);
    ordersSelectedTab(newValue);
    setPagination(prevState => ({
      ...prevState,
      offset: 0,
      limit: rowsPerPage,
    }));
  };

  const pageChangeHandler = (offset, limit, pageSortValue) => {
    loadOrdersData({ offset, limit }, selectedTab, pageSortValue);
    setRowsPerPage(limit);
    setPagination(prevState => ({
      ...prevState,
      offset,
      limit,
    }));
  };
  const toggleBannerMessage = () => {
    closeBannerMessage();
  };
  const filterResults = (data) => {
    setFilterResults(data);
  };
  const searchResultClick = (searchValues) => {
    const body = {
      'patient.demographic.firstName': searchValues.firstName,
      'patient.demographic.lastName': searchValues.lastName,
    };
    setSearchKey(true);
    setSearchInputValues(body);
    setPagination(prevState => ({
      ...prevState,
      offset: 0,
      limit: rowsPerPage,
    }));
    loadOrdersData({...pagination, offset: 0}, selectedTab, sortValues, body, email);
  };
  const InputSearchHandler = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    setFilterResults([]);
    if (value && value.length > 2) {
      getFilterData(value, id, filterResults, email);
    }
  };
  const handleKeySearchHandler = (event) => {
    if (event.key === 'Enter') {
      const { value } = event.target;
      if (value.split(' ').length > 0) {
        const body = {
          'patient.demographic.firstName': value.split(' ')[0],
          'patient.demographic.lastName': value.split(' ')[1],
        };
        setSearchKey(true);
        setSearchInputValues(body);
        loadOrdersData(pagination, selectedTab, sortValues, body, email);
      }
    }
  };
  const searchInputClear = () => {
    setSearchKey(false);
    setSearchInputValues({});
    loadOrdersData(pagination, selectedTab, sortValues, {}, email);
  };
  const editOrder = (completeOrder, ordersTypes) => {
    editPatientData(completeOrder, editPatientOrder, ordersTypes);
  };
  return (
    <React.Fragment>
      {hasError && (
        <BannerMessage
          toggleBanner={toggleBannerMessage}
          messages={errorMessage}
          status="error"
        />
      )}
      <DashboardHeader
        currentUser={firstname}
        totalPendingRecords={totalPendingRecords}
        roleBasedAuthorisation={roleBasedAuthorisation}
      />
      <Box sx={{ width: '100%', typography: 'body1' }} className="dashboard-table">
        <Paper elevation={1} sx={{ boxShadow: 'none' }}>
          <TabContext value={selectedTab}>
            <Box sx={{ paddingTop: '10px' }}>
              <Toolbar disableGutters>
                <Tabs sx={{ flexGrow: 1, minHeight: '52px' }} value={selectedTab} onChange={tabChangeHandler} textColor="primary" aria-label="Tabs List">
                  {ordersDashboardTable && ordersDashboardTable.toBeCompleted && <Tab className={searchKey ? classes.tabRoot : ''} label={`To Be Completed (${totalPendingRecords || 0})`} value="1" />}
                  {ordersDashboardTable && ordersDashboardTable.pendingESignature && <Tab className={searchKey ? classes.tabRoot : ''} label={`Pending E-signature (${totalESignPendingRecords || 0})`} value="2" />}
                  {ordersDashboardTable && ordersDashboardTable.completed && <Tab className={searchKey ? classes.tabRoot : ''} label={`Completed (${totalCompletedRecords || 0})`} value="3" />}
                </Tabs>
                <Search className="mui-panel dashboard-search">
                  <SearchIconWrapper>
                    <SearchIcon color="primary" sx={{ zIndex: '1000', left: '0px' }} />
                  </SearchIconWrapper>
                  <MuiAutoComplete
                    enableSearchFlag={roleBasedAuthorisation.toJS().searchBox}
                    onInputChange={InputSearchHandler}
                    results={searchResults}
                    searchValue={searchValue}
                    searchResultClick={searchResultClick}
                    loadOrdersData={searchInputClear}
                    handleKeySearchHandler={handleKeySearchHandler}
                  />
                </Search>
              </Toolbar>
            </Box>
            {ordersDashboardTable && ordersDashboardTable.toBeCompleted && (
            <TabPanel value="1" sx={{ padding: '0px 10px' }}>
              {isPendingOrdersLoaded && (
                <React.Fragment>
                  {(pendingOrders?.length > 0 || searchKey) ? (
                    <OrdersList
                      orders={pendingOrders}
                      pageChange={pageChangeHandler}
                      totalRecords={totalPendingRecords}
                      ordersType="pending"
                      pageLimit={rowsPerPage}
                      editOrder={editOrder}
                      history={history}
                      onSortRequest={sortHandler}
                      roleBasedAuthorisation={roleBasedAuthorisation}
                    />
                  ) : <NoOrders text="To Be Completed" totalPendingRecords={totalPendingRecords} />}
                </React.Fragment>
              )}
            </TabPanel>
            )}
            {ordersDashboardTable && ordersDashboardTable.pendingESignature && (
              <TabPanel value="2" sx={{ padding: '0px 10px' }}>
                {isPendingESignOrdersLoaded && (
                  <React.Fragment>
                    {(pendingSignatureOrders?.length > 0 || searchKey) ? (
                      <OrdersList
                        orders={pendingSignatureOrders}
                        pageChange={pageChangeHandler}
                        totalRecords={totalESignPendingRecords}
                        ordersType="pendingSignature"
                        pageLimit={rowsPerPage}
                        downloadPdf={downloadEtrfPdf}
                        editOrder={editOrder}
                        history={history}
                        onSortRequest={sortHandler}
                        roleBasedAuthorisation={roleBasedAuthorisation}
                      />
                    ) : <NoOrders text="Pending E-Signature" totalPendingRecords={totalPendingRecords} />
                    }
                  </React.Fragment>
                )}
              </TabPanel>
            )}
            {ordersDashboardTable && ordersDashboardTable.completed && (
              <TabPanel value="3" sx={{ padding: '0px 10px' }}>
                {isCompletedOrdersLoaded && (
                  <React.Fragment>
                    {(completedOrders?.length > 0 || searchKey) ? (
                      <OrdersList
                        orders={completedOrders}
                        pageChange={pageChangeHandler}
                        totalRecords={totalCompletedRecords}
                        ordersType="completed"
                        pageLimit={rowsPerPage}
                        downloadPdf={downloadEtrfPdf}
                        editOrder={editOrder}
                        history={history}
                        onSortRequest={sortHandler}
                        roleBasedAuthorisation={roleBasedAuthorisation}
                      />
                    ) : <NoOrders text="Completed" totalPendingRecords={totalPendingRecords} />
                    }
                  </React.Fragment>
                )}
              </TabPanel>
            )}
          </TabContext>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
Dashboard.propTypes = IPropTypes;
Dashboard.defaultProps = defaultProps;
