import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getAuthClient } from './AuthClient';
import { config } from '../config/config';
import { API_GATEWAY_URL, USER_MGMT_V2_PATH } from '../config';

const Callback = () => {
  const isInternalUser = Cookies.get('isInternal');
  const [error, setError] = useState(null);
  const authClient = getAuthClient();
  const formData = new FormData();
  const fetchUserPermission = () => {
    window.showSpinner();
    const data = JSON.parse(sessionStorage.getItem('login-form-data'));
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });
    sessionStorage.removeItem('login-form-data');
    const myHeaders = new Headers();
    const oktaAccessToken = JSON.parse(
      localStorage.getItem('okta-token-storage'),
    ).accessToken.accessToken;
    if (isInternalUser === 'false') {
      myHeaders.append('x-portal-auth-type', 'v2ext');
    }
    myHeaders.append('Authorization', `Bearer ${oktaAccessToken}`);
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    async function fetchUserInfoAndSession() {
      const [userResponse, sessionResponse] = await Promise.all([
        fetch(`${config()[API_GATEWAY_URL]}${config()[USER_MGMT_V2_PATH]}/users/me`, requestOptions),
        fetch('/session', {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${oktaAccessToken}`,
          },
        }),
      ]);
      const userData = await userResponse.json();
      return [userData, sessionResponse];
    }
    fetchUserInfoAndSession().then(([userData, sessionResponse]) => {
      localStorage.setItem(
        'userInfo',
        JSON.stringify({ isLoggedIn: true, accessInfo: userData.data }),
      );
      if (sessionResponse.ok) {
        const patientMoreInfo = sessionStorage.getItem('patient-more-info');
        if (patientMoreInfo) {
          window.location.pathname = 'myguardant/more_info';
        } else {
          // window.location.reload();
          const url = sessionStorage.getItem('url');
          window.location.assign(url);
          sessionStorage.removeItem('url');
        }
      } else {
        // setStartSignIn(false);
        window.hideSpinner();
      }
    }).catch((err) => {
      setError(err);
    });
  };
  useEffect(() => {
    authClient.token
      .parseFromUrl()
      .then((res) => {
        authClient.tokenManager.setTokens(res.tokens);
        if (res.tokens.accessToken) {
          const url = sessionStorage.getItem('url');
          const portalVersion = res.tokens.accessToken.claims.portal_version;
          Cookies.set('portal_version', portalVersion);
          if (portalVersion === 'v1') {
            fetchUserPermission();
          } else { // Default and V2
            const urlOrigin = new URL(url).origin;
            if (isInternalUser === 'true') {
              window.location.assign(`${urlOrigin}/v2/sso/sign-in`);
            } else {
              window.location.assign(`${urlOrigin}/v2/sso/sign-in-ext`);
            }
          }
        }
      })
      .catch((err) => {
        setError(err);
      });
  }, []);
  return error ? <div>{error}</div> : null;
};
export default Callback;
