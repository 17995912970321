import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './ReleaseForm.module.scss';
import { releaseReport } from '../../../../../util/ajax';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
  reportsToRelease: PropTypes.object.isRequired,
  releaseReportEnabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
const defaultProps = {};

class ReleaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      releaseForm: {
        textMessage: 'Hello, here is a copy of your report to keep for your records.',
        email: props.patient.patientEmailAddress,
      },
    };
  }

  componentDidMount() {
    window.$('[data-toggle="popover"]').popover();
  }

  inputChangeHandler = field => (event) => {
    const updatedReleaseForm = { ...this.state.releaseForm };
    updatedReleaseForm[field] = event.target.value;
    this.setState(() => ({ releaseForm: updatedReleaseForm }));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { authenticityToken, onSubmit } = this.props;

    // TODO: Add more cocmplex validation on email field before yielding the submission.

    window.showSpinner();
    await releaseReport(authenticityToken, this.releaseParams());
    window.hideSpinner();
    onSubmit();
  }

  releaseParams() {
    const { patient, reportsToRelease } = this.props;
    const { textMessage, email } = this.state.releaseForm;

    return {
      patient_id: patient.id,
      request_id: patient.requestId,
      email,
      message: textMessage,
      released_reports: reportsToRelease,
    };
  }

  render() {
    const { releaseForm } = this.state;
    const { patientLastName, patientFirstName, readOnlyEmail } = this.props.patient;
    const { releaseReportEnabled } = this.props;

    const buttonClasses = ['btn__size-dynamic btn__color-blue release-reports__submit-button'];
    if (!releaseReportEnabled) {
      buttonClasses.push('disabled');
    }

    return (
      <Fragment>
        <h1>Release report to patient</h1>
        <form acceptCharset="UTF-8" onSubmit={this.handleSubmit}>
          <div className="release-reports__form__item">
            <label className="release-reports__email" htmlFor="Patient_s_Email">Patient&apos;s Email Address</label>
            <button className={`email fa fa-info-circle no-spinner ${styles.infoCircle}`} data-content="Your patient will receive a notification at this email address with a secure link to their report on the myGuardant patient portal." data-placement="auto right" data-toggle="popover" data-trigger="focus" id="Email-pop" tabIndex="0" type="button" data-original-title="" title="" />
            <input type="email" name="email" id="email" value={releaseForm.email} placeholder="e.g. name@example.com" className="release-reports__form-item-email" readOnly={readOnlyEmail} required="required" onChange={this.inputChangeHandler('email')} />
            <label className="release-reports__email" htmlFor="Message">Include a Message</label>
            <button className={`message fa fa-info-circle no-spinner ${styles.infoCircle}`} data-content="Please include a message for your patient to see when they access their report." data-placement="auto right" data-toggle="popover" data-trigger="focus" id="Message-pop" tabIndex="0" type="button" data-original-title="" title="" />
            <textarea name="message" id="message" placeholder="Message" className="release-reports__form-item-message" cols="18" rows="9" value={releaseForm.textMessage} onChange={this.inputChangeHandler('textMessage')} />
            <p className="release-reports__text">{`Ready to release report from about 1 year ago to your patient, ${patientLastName} ${patientFirstName}?`}</p>
            <div className="buttons-row">
              <button className={buttonClasses.join(' ')} type="submit" disabled={!releaseReportEnabled}>Release Report</button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

ReleaseForm.propTypes = IPropTypes;
ReleaseForm.defaultProps = defaultProps;


export { ReleaseForm };
