import { constants } from '../../config/constants';

const { INCOMPLETE } = constants.printModal.infoCompleteness;
class LateStageDiagnosisInfo {
  constructor(params) {
    this.primaryCode = params.diagnosisPrimaryCode || {};
    this.secondaryCode = params.diagnosisSecondaryCode;
    this.tertiaryCode = params.diagnosisTertiaryCode;
    this.quarternaryCode = params.diagnosisQuarternaryCode;
    this.quinaryCode = params.diagnosisQuinaryCode;
    this.senaryCode = params.diagnosisSenaryCode;
    this.disease = params.disease;
    this.otherDisease = params.otherDisease;
    this.stage = params.diagnosisStage;
    this.originalDiagnosis = params.diagnosisOriginalDiagnosis;
  }

  formatDisease = () => `${this.disease || (this.otherDisease ? 'Other' : 'N/A')}`;

  isComplete = () => (
    !!this.primaryCode.code && (!!this.disease || !!this.otherDisease) && !!this.stage
  );

  formatForPrintModal = () => {
    const isCompleted = this.isComplete();
    const disease = this.disease || (this.otherDisease ? 'Other' : 'N/A');

    return {
      title: 'DIAGNOSIS',
      info: (
        isCompleted
          ? `${this.primaryCode.code} - ${disease} - ${this.stage}`
          : INCOMPLETE
      ),
      isCompleted,
    };
  }

  checkRequiredFields = () => !!(
    this.primaryCode.code && this.stage && (this.disease || this.otherDisease)
  )
}

export { LateStageDiagnosisInfo };
