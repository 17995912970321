export class StudyManagerSerializer {
  static deserialize = studyManagerInfo => (
    studyManagerInfo && {
      userId: studyManagerInfo.data.userId,
      firstName: studyManagerInfo.data.firstName,
      lastName: studyManagerInfo.data.lastName,
      projectIds: studyManagerInfo.data.projectIds,
    }
  );
}
