import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getMatchGenData,
  addMatchGenData,
  addSearchSpaceData,
  addGenomicData,
  editMatchGenData,
  changePagination,
  onChange,
} from './actions/index';
import MatchGenView from './MatchGenView';

const mapStateToProps = state => ({
  matchGen: state.matchGen,
  tenant: state.tenants,
  user: state.users,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getMatchGenData,
      addMatchGenData,
      addSearchSpaceData,
      addGenomicData,
      editMatchGenData,
      changePagination,
      onChange,
    },
    dispatch,
  ),
});
export const ConnectedMatchGenView = connect(mapStateToProps, mapDispatchToProps)(MatchGenView);
export default ConnectedMatchGenView;
