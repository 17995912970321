import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './release-activity-log.module.scss';
import { ReleaseActivity } from './release-activity';
import { formatDate } from '../../../lunar_patient_page/lunar_patient_summary/ReportSummaryHelper';

const IPropTypes = {
  releases: PropTypes.array.isRequired,
};

const ReleaseActivityLog = ({ releases }) => {
  const activities = releases
    .map(attributes => (
      <ReleaseActivity
        key={`${attributes.createdAt} ${attributes.releasingUserName}`}
        reportDate={attributes.reportDate}
        releaseDateHour={formatDate(attributes.createdAt, 'month-day-year-at-time')}
        releaser={attributes.releasingUserName}
      />
    ));

  return (
    <Fragment>
      <h1>Activity Log</h1>
      <div className={styles.releaseToPatientLine} />
      <div>
        {activities}
      </div>
    </Fragment>
  );
};

ReleaseActivityLog.propTypes = IPropTypes;

export { ReleaseActivityLog };
