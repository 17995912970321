import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PatientsView from './PatientsView';
import {
  getPatientsData,
  deletePatient,
  changePagination,
  onChange,
} from './actions/PatientsAction';

const mapStateToProps = state => ({
  patients: state.patients,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPatientsData,
      deletePatient,
      changePagination,
      onChange,
    },
    dispatch,
  ),
});
// eslint-disable-next-line max-len
export const ConnectedPatientManagementView = connect(mapStateToProps, mapDispatchToProps)(PatientsView);
export default ConnectedPatientManagementView;
