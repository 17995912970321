/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { TableRow } from '@mui/material';
import Cell from './Cell';

const Row = (props) => {
  const {
    upperCase, rows, handleMenuClick, headers,
  } = props;
  return (
    <TableRow>
      {headers.map(item => (
        <Cell
          key={Math.random()}
          upperCase={upperCase}
          item={rows[item]}
          handleMenuClick={handleMenuClick}
        />
      ))}
    </TableRow>
  );
};
export default memo(Row);
