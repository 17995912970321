import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../GenericHeader';
import { ClinicalHistoryExpanded } from './clinical_history_expanded';
import ClinicalHistoryCollapsed from './ClinicalHistoryCollapsed';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
};

const ClinicalHistoryContainer = ({ enabled, onToggle }) => (
  <div>
    <GenericHeader
      enabled={enabled}
      handleContainerArrowClick={onToggle}
      label="Relevant Clinical History"
    />

    {
      enabled
        ? (
          <div>
            <ClinicalHistoryExpanded />
          </div>
        )
        : (
          <div>
            <ClinicalHistoryCollapsed onToggle={onToggle} />
          </div>
        )
    }
  </div>
);

ClinicalHistoryContainer.propTypes = IPropTypes;
ClinicalHistoryContainer.defaultProps = defaultProps;

export default ClinicalHistoryContainer;
