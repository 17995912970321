import React, { useState, useEffect } from 'react';
import {
  Toolbar, InputAdornment, useTheme, useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Search } from '@mui/icons-material';
import _ from 'loadsh';
import Immutable from 'immutable';
import ApplicationMessage from '../common/components/elements/ApplicationMessage';
import ConfirmModalWindow from '../common/components/elements/ConfirmModalWindow';
import Controls from '../common/components/forms/controls';
import ModalPopup from '../common/components/elements/ModalPopup';
import Pagination from '../common/components/elements/Table/Pagination';
import Table from '../common/Table';
import TableBody from '../common/TableBody';
import TableHeader from '../common/TableHeader';
import RoleAssignmentForm from './RoleAssignmentForm';

const useStyles = makeStyles(theme => createStyles({
  root: {
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#ffffff',
  },
  searchInput: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#1A5EA7',
    boxShadow: 'none',
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '20px',
    marginLeft: '10px',
  },
}));

const RoleAssignmentView = (props) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false, title: '', subTitle: '', onConfirm: () => {},
  });
  const [formData, setFormData] = useState(null);
  const [title, setTitle] = useState('');
  const classes = useStyles();
  const {
    roleAssignment,
    roles,
    actions: {
      getRoleAssignmentsData, changePagination, onChange, getAdminRoles, editRoleAssignmentsData,
    },
  } = props;
  const roleAssignmentList = roleAssignment.toJS().roleAssignmentList || [];
  const notify = roleAssignment.toJS().notify || {};
  const pagination = roleAssignment.toJS().pagination || 5;
  const roleAssignmentManagement = roleAssignment.toJS() || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (roleAssignmentList.length === 0 || roleAssignmentManagement.reload) {
      setConfirmDialog({
        isOpen: false, title: '', subTitle: '', onConfirm: () => {},
      });
      getRoleAssignmentsData(pagination);
      onChange({ key: 'reload', value: false });
      // eslint-disable-next-line no-unused-expressions
      roleAssignmentManagement.reload && setFormData(null);
    }
  }, [roleAssignmentManagement.reload]);
  useEffect(() => {
    if (roleAssignmentManagement.isOpen) {
      setConfirmDialog({
        isOpen: false, title: '', subTitle: '', onConfirm: () => {},
      });
      onChange({ key: 'isOpen', value: false });
    }
  }, [roleAssignmentManagement.isOpen]);

  const setNotify = (data) => {
    onChange({ key: 'notify', value: { ...data } });
  };
  useEffect(() => {
    onChange({ key: 'reload', value: true });
  }, []);
  const editClickHandler = (row) => {
    getAdminRoles('');
    onChange({ key: 'openPopup', value: true });
    const {
      // eslint-disable-next-line no-shadow
      uuid, firstName, lastName, email, roles, sites,
    } = row;
    const role = roles && roles[0];
    setFormData({
      uuid, firstName, lastName, email, roles: role, sites,
    });
    setTitle('Edit Role Assignment');
  };

  const submitHandler = (values) => {
    const data = values.roles.uuid;
    editRoleAssignmentsData({ uuid: values.uuid, roles: [data] });
  };
  const delayedQuery = _.debounce(q => getRoleAssignmentsData(q), 500);
  const handleSearch = (e) => {
    delayedQuery({ sortBy: e.target.value });
  };
  const userPermission = {
    Edit: roles && roles.includes('UPDATE'),
  };
  const actions = [{ action: 'Edit', handleAction: editClickHandler }];
  const handlePagination = (e, pageNum) => {
    e.preventDefault();
    getRoleAssignmentsData(pageNum);
    changePagination(pageNum);
  };
  const headers = [
    { name: 'firstName', type: 'text' },
    { name: 'lastName', type: 'text' },
    { name: 'email', type: 'text' },
    { name: 'roles', type: 'array' },
  ];
  return (
    <React.Fragment>
      <div className={classes.title}>Role Assigment</div>
      <div id="roleAssignmentContainer" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Controls.Input
            label="search role assignment"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <Table>
              {
                !isMobile
            && (
            <TableHeader
              headers={[...headers]}
              {...{
                rows: roleAssignmentList || [],
                actions,
              }}
            />
            )
              }
              <TableBody
                headers={headers}
                {...{ rows: roleAssignmentList || [], actions }}
                userPermission={userPermission}
                dynamic="roles"
              />
            </Table>
            <Pagination
              count={roleAssignmentManagement.totalRecords}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNo}
              handleChangePage={(e, val) => handlePagination(e, { ...pagination, pageNo: val })}
              handleChangeRowsPerPage={e => handlePagination(e,
                { ...pagination, pageSize: e.target.value, pageNo: 0 })}
            />
          </Grid>
        </Grid>
        <ModalPopup
          title={title}
          openPopup={roleAssignmentManagement.openPopup}
          setOpenPopup={val => onChange({ key: 'openPopup', value: val })}
        >
          <RoleAssignmentForm
            formData={formData}
            submitHandler={submitHandler}
            rolesList={roleAssignmentManagement.rolesList}
            edit={title === 'Edit Role Assignment'}
            getAdminroles={getAdminRoles}
            HandleChange={onChange}
          />
        </ModalPopup>
        <ConfirmModalWindow confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        <ApplicationMessage notify={notify} setNotify={setNotify} />
      </div>
    </React.Fragment>
  );
};

export default RoleAssignmentView;
RoleAssignmentView.propTypes = {
  actions: PropTypes.object,
  roleAssignment: PropTypes.object,
  roles: PropTypes.array,
};
RoleAssignmentView.defaultProps = {
  actions: {},
  roleAssignment: Immutable.fromJS({}),
  roles: [],
};
