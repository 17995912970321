import React from 'react';
import PropTypes from 'prop-types';

import styles from './general-message.module.scss';

const IPropTypes = {
  generalMessage: PropTypes.string,
};

const defaultProps = {
  generalMessage: null,
};

const GeneralMessage = ({ generalMessage }) => generalMessage && (
  <div className={styles.container}>
    {generalMessage}
  </div>
);

GeneralMessage.propTypes = IPropTypes;
GeneralMessage.defaultProps = defaultProps;

export { GeneralMessage };
