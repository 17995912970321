import React from 'react';
import PropTypes from 'prop-types';
import styles from './table.module.scss';

const IPropTypes = {
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
  withColorCell: PropTypes.bool,
};

const defaultProps = {
  withColorCell: true,
};

const Table = ({
  tableTitle, columns, rows, withColorCell,
}) => (
  <div>
    <div className={styles.title}>
      {tableTitle}
    </div>
    <table className={styles.table}>
      <thead>
        <tr className={styles.tableHeaderRow}>
          { withColorCell && <th className={styles.headerColorCell} /> }
          {
            columns.map(col => (
              <th key={col} className={styles.headerCell}>{col}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map(row => (
            <tr key={row.key}>
              { withColorCell && <td className={styles.rowColorCell} /> }
              <td className={styles.rowNameCell}>
                <div className={styles.nameLabel}>
                  {row.name}
                </div>
              </td>
              {row.values.map(
                val => <td key={val} className={styles.rowAdditionalDetailsCell}>{val}</td>,
              )}
            </tr>
          ))
        }
      </tbody>
    </table>
  </div>
);

Table.propTypes = IPropTypes;
Table.defaultProps = defaultProps;

export { Table };
