import React, { useEffect, useState } from 'react';

import styles from './SubjectContainer.module.scss';
import { getSubjectsList } from '../../../util/ajax';
import DownloadReportsContainer from '../DownloadReportsDropdown/DownloadReportsDropdown';

const IPropTypes = {};

const defaultProps = {};

const SubjectContainer = () => {
  const [subjects, setSubjects] = useState({});
  const [isLoading, setLoading] = useState(true);
  const subjectDetails = (data) => {
    setSubjects(data);
    setLoading(false);
  };
  const url = `${window.location.origin}/rest${window.location.pathname}`;
  useEffect(() => {
    getSubjectsList(url, subjectDetails);
  }, []);

  const {
    subject,
    projectStudyId,
    request_links: requestLinks,
    requests_blood_coll_date: requestsBloodCollDate,
    can_download_report: canDownloadReport,
  } = subjects;
  return (
    <>
      {!isLoading && (
      <>
        <div className={styles.container}>
          <div className={styles.header}>
            <a className={styles.backButton} href="/studies">
              <i className="fa fa-angle-left" />
              {' '}
              BACK
            </a>
            <div className={styles.title}>
              <div>
                STUDY:
                {' '}
                {projectStudyId}
              </div>
              <div>
                SUBJECT:
                {' '}
                {subject}
              </div>
              <hr />
            </div>
          </div>
        </div>
        <DownloadReportsContainer
          requestLinks={requestLinks}
          requestsBloodCollDate={requestsBloodCollDate}
          canDownloadReport={canDownloadReport}
        />
      </>
      )}
    </>
  );
};

SubjectContainer.propTypes = IPropTypes;
SubjectContainer.defaultProps = defaultProps;

export { SubjectContainer };
