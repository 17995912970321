const emrActionTypes = {
  ADD_EMR_ACCOUNTS: 'ADD_EMR_ACCOUNTS',
  ADD_EMR_PROVIDER: 'ADD_EMR_PROVIDER',
};

const loadEmrAccounts = accounts => ({
  type: emrActionTypes.ADD_EMR_ACCOUNTS,
  info: accounts,
});

const loadEmrProvider = provider => ({
  type: emrActionTypes.ADD_EMR_PROVIDER,
  info: provider,
});

export { emrActionTypes, loadEmrAccounts, loadEmrProvider };
