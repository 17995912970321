/* eslint-disable no-useless-escape, no-useless-escape, consistent-return */
import moment from 'moment';

const isText = RegExp(/^[A-Z ]+$/i);
// const isName = RegExp(/^([A-Za-z0-9 \-\'_]?)*$/);
const isAlphaNumeric = /^[A-Za-z0-9\-]{1,256}$/;
export const isEmail = RegExp(
  /^[A-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[A-Z0-9-]+\.[A-Z]{2,15}$/i,
);
const isPhone = RegExp(/^[0-9\-\+]{10,15}$/);
const isZip = RegExp(/^[0-9]{5}([- /]?[0-9]{4})?$/); // us
const isNumber = RegExp(/^\d+$/);
const isCity = /^$|^([a-zA-Z-ɏ]+(?:. |-| |'))*[a-zA-Z-ɏ]*$/;
const isWebsite = RegExp(
  /^(http:\/\/|https:\/\/)?(www.)*?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
);
const isAddress = RegExp(/^[a-z0-9\s,'-]*$/);

export default function Validation(name, value = '', schema) {
  const {
    validate, minLength, maxLength, required, key,
  } = schema[name];
  let error = '';
  if (minLength && value && value.length < minLength) { return `Minimum ${minLength} characters is required.`; }
  if (maxLength && value && value.length > maxLength) { return `Maximum length of ${maxLength} exceeded!`; }
  if (!validate) return;
  if (!minLength && !required && (!value || value.length === 0)) return;
  switch (validate) {
    case 'name':
      if (!value || (value && value.length === 0)) error = 'Please enter a valid name';
      break;
    case 'text':
      if (!isText.test(value)) error = 'This field accept text only.';
      break;
    case 'alphanumeric':
      if (!isAlphaNumeric.test(value)) error = 'This field accept alpha-numaric values.';
      break;

    case 'number':
      if (!isNumber.test(value)) error = 'This field accept numbers only.';
      break;

    case 'email':
      if (!isEmail.test(value)) error = 'Please enter a valid email';
      break;

    case 'phone':
      if (!isPhone.test(value)) error = 'Please enter a valid phone number. i.e: xxx-xxx-xxxx';
      break;
    case 'fax':
      if (!isPhone.test(value)) error = 'Please enter a valid fax number. i.e: xxx-xxx-xxxx';
      break;

    case 'website':
      if (!isWebsite.test(value)) error = 'Please enter a valid website address. i.e https://www.abc.com';
      break;

    case 'city':
      if (!isCity.test(value)) error = 'Please enter a valid state/city name';
      break;
    case 'dropDown':
      if (!value || value.length === 0) error = 'Please select a value';
      break;
    case 'address':
      if (!isAddress.test(value)) error = 'Please enter a valid address';
      break;
    case 'zip':
      if (!isZip.test(value)) error = 'Please enter a valid zip code';
      break;
    case 'zipCode':
      if (!isZip.test(value)) error = 'Please provide 5 digit zipcode';
      break;
    case 'checkbox':
      if (!value) error = 'Please select a value';
      break;
    case 'datePattern':
      if (!moment(value, 'YYYY-MM-DD', true).isValid()) error = 'Invalid Date';
      break;
    case 'collectionDate':
      if (!moment(value, 'YYYY-MM-DD', true).isValid()) error = 'Please select a valid date in MM-DD-YYYY format';
      break;
    case 'dobPattern':
      if (!moment(value, 'YYYY-MM-DD', true).isValid()) error = 'Please select a valid date in MM-DD-YYYY format';
      break;
    case 'dateResection':
      if (!moment(value, 'YYYY-MM-DD', true).isValid()) error = 'Please select a valid date in MM-DD-YYYY format';
      break;
    case 'dateOfAdjuvantChemotherapy':
      if (!moment(value, 'YYYY-MM-DD', true).isValid()) error = 'Please select a valid date in MM-DD-YYYY format';
      break;
    case 'object':
      if (Object.keys(value || {}).length === 0 || (key && !Object.keys(value || {}).find(x => x === key))) error = 'Please enter a valid value';
      break;
    case 'content':
      // eslint-disable-next-line no-mixed-operators
      if (!value || value && value.length === 0) error = 'Please enter a valid value';
      break;
    case 'intervalValue':
      if (!value || (parseFloat(value) < 4.00 || parseFloat(value) > 12.00) || Number.isNaN(parseFloat(value))) error = 'Please enter a number between 4 and 12';
      break;
    default:
      break;
  }

  return error;
}
