import HttpService from '../services/HttpService';
import { OrdersDashboardEndpoints, OrdersEndpoints } from '../endpoints';
import { OrderValidationSerializer } from '../serializers/order/order-validation';
import { DiagnosisSearchSerializer } from '../serializers/order/diagnosis-search';
import { IcdCodeSerializer } from '../serializers/order/icd-code';
import { ConfigurationSerializer } from '../serializers/order/configuration';
import {
  OrderSerializer,
} from '../serializers/order';
import { buildOrder } from '../../models/order/order-builder';
import { TestSelectionOptionsSerializer } from '../serializers/order/test-selection-options';
import { RelevantClinicalHistoryOptionsSerializer } from '../serializers/order/relevant-clinical-history-options';
import { PhysicianDropdown } from '../serializers/order/physician-dropdown';
import { AccountDropdown } from '../serializers/order/account-dropdown';
import { PhysicianSearch } from '../serializers/order/physician-search';
import { getToken, isInternalUser } from './helper';

const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };

export class OrdersController {
  static createTrfOrder = async (order) => {
    const serializedOrder = OrderSerializer.serialize(order);
    const response = await HttpService.post(
      OrdersEndpoints.CREATE_TRF_ORDER,
      {
        order: serializedOrder,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return OrderSerializer.deserialize(response.data);
  };

  static updateTrfOrder = async (id, order) => {
    const serializedOrder = OrderSerializer.serialize(order);

    const response = await HttpService.put(
      `${OrdersEndpoints.UPDATE_TRF_ORDER}/${id}`,
      {
        order: serializedOrder,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return OrderSerializer.deserialize(response.data);
  };

  static printOrFinalizeOrder = async (
    orderId,
    order,
    withSignature = false,
  ) => {
    const serializedOrder = OrderSerializer.serialize(order);

    const {
      data: { order: orderData, redirect_to: redirectUrl },
    } = await HttpService.post(
      OrdersEndpoints.PRINT_OR_FINALIZE_ORDER,
      {
        order_id: orderId,
        order: serializedOrder,
        signature_request: withSignature,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return {
      order: buildOrder(OrderSerializer.deserialize(orderData)),
      redirectUrl,
    };
  };

  static getOrdersAmounts = async (status) => {
    const ordersAmounts = await HttpService.get(
      OrdersDashboardEndpoints.GET_ORDERS_AMOUNTS,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
        params: status ? { status } : {},
      },
    );

    return ordersAmounts.data;
  };

  static getOrdersByStatus = async (status) => {
    const { data } = await HttpService.get(
      OrdersDashboardEndpoints.GET_ORDERS,
      {
        params: { status },
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    // eslint-disable-next-line max-len
    return (data || []).map(e => e.map(orderData => buildOrder(OrderSerializer.deserialize(orderData))));
  };

  static getPreviousOrders = async () => {
    const response = await HttpService.get(
      OrdersEndpoints.GET_PREVIOUS_ORDERS,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    // eslint-disable-next-line max-len
    return (response.data || []).map((e = []) => e.map(orderData => buildOrder(OrderSerializer.deserialize(orderData))));
  };

  static getPendingOrders = async () => {
    const response = await HttpService.get(OrdersEndpoints.GET_PENDING_ORDERS, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return (response.data || []).map(e => buildOrder(OrderSerializer.deserialize(e)));
  };

  static getOrder = async (id, productFamily) => {
    const response = await HttpService.get(
      `${OrdersEndpoints.GET_ORDER}/${id}?product_family=${productFamily}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return (
      response.data.order && OrderSerializer.deserialize(response.data.order)
    );
  };

  static getProgressionOrder = requestId => HttpService.get(OrdersEndpoints.GET_PROGRESSION_ORDER, {
    params: { request_id: requestId },
    headers: {
      Authorization: `Bearer ${getToken()}`,
      ...extHeader,
    },
  });

  static discardTrfOrder = (orderId, productFamily) => HttpService.delete(
    `${OrdersEndpoints.DISCARD_ORDER}/${orderId}?product_family=${productFamily}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    },
  );

  static getNumberOfPendingOrders = async () => {
    const response = await HttpService.get(
      OrdersEndpoints.NUMBER_OF_PENDING_ORDERS,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return response.data;
  };

  static validateSchema = async (order) => {
    const serializedOrder = OrderSerializer.serialize(order);

    const response = await HttpService.post(
      OrdersEndpoints.VALIDATE_SCHEMA,
      {
        order: serializedOrder,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return OrderValidationSerializer.deserialize(response.data);
  };

  static getRelevantClinicalHistory = async (productFamily) => {
    const response = await HttpService.get(
      `${OrdersEndpoints.RELEVANT_CLINICAL_HISTORY}?product_family=${productFamily}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return RelevantClinicalHistoryOptionsSerializer.deserialize(response.data);
  };

  static getPatientStatuses = async () => HttpService.get(OrdersEndpoints.PATIENT_STATUSES, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      ...extHeader,
    },
  });

  static getTestSelections = async (productFamily) => {
    const response = await HttpService.get(
      `${OrdersEndpoints.TEST_SELECTIONS}?product_family=${productFamily}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return TestSelectionOptionsSerializer.deserialize(
      response.data,
      productFamily,
    );
  };

  static getPatientTypes = async () => HttpService.get(OrdersEndpoints.PATIENT_TYPES, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      ...extHeader,
    },
  });

  static getGroupedDiagnoses = async () => {
    const response = await HttpService.get(OrdersEndpoints.DIAGNOSIS_SEARCH, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return Object.fromEntries(Object.entries(response.data || {}).map(
      ([key, groupedDiagnoses]) => (
        [key, groupedDiagnoses.filter(o => !o.parentId).map(DiagnosisSearchSerializer.deserialize)]
      ),
    ));
  }

  static diagnosisSearch = async (keyword) => {
    const response = await HttpService.get(OrdersEndpoints.DIAGNOSIS_SEARCH, {
      params: { keyword },
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return (response.data || []).map(DiagnosisSearchSerializer.deserialize);
  };

  static icdCodesSearch = async (keyword, productFamily) => {
    const response = await HttpService.get(OrdersEndpoints.ICD_CODES_SEARCH, {
      params: { text: keyword, product_family: productFamily },
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return (response.data || []).map(IcdCodeSerializer.deserialize);
  };

  static orderingConfiguration = async () => {
    const response = await HttpService.get(OrdersEndpoints.CONFIGURATION, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return ConfigurationSerializer.deserialize(response.data);
  };

  static getQualifiedAccounts = async () => {
    const response = await HttpService.get(OrdersEndpoints.QUALIFIED_ACCOUNTS, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return response.data
      .map(AccountDropdown.deserialize)
      .sort((a, b) => (a.title || '').localeCompare(b.title));
  };

  static getPhysiciansForAccount = async (selectedAccount) => {
    const response = await HttpService.get(
      OrdersEndpoints.QUALIFIED_PHYSICIANS_FOR_ACCOUNT,
      {
        params: {
          selected_ghi: selectedAccount,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return response.data
      .map(PhysicianDropdown.deserialize)
      .sort((a, b) => (a.lastname || '').localeCompare(b.lastname));
  };

  static physicianSearch = async (selectedAccount, keyword) => {
    const response = await HttpService.get(
      OrdersEndpoints.QUALIFIED_PHYSICIANS_FOR_ACCOUNT,
      {
        params: { selected_ghi: selectedAccount, keyword },
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      },
    );

    return (response.data || []).map(PhysicianSearch.deserialize);
  };

  static finalizePrintedOrder = async (
    orderId,
    userCreatorId,
    productFamily,
  ) => {
    const {
      data: { order: orderData, redirect_to: redirectUrl },
    } = await HttpService.post(OrdersEndpoints.FINALIZE_PRINTED_ORDER, {
      order_id: orderId,
      product_family: productFamily,
      user_id: userCreatorId,
    }, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return {
      order: buildOrder(OrderSerializer.deserialize(orderData)),
      redirectUrl,
    };
  };

  static checkSignatureCompletion = async (envelopeId, productFamily) => {
    const {
      data: { signed },
    } = await HttpService.post(OrdersEndpoints.CHECK_SIGNATURE_COMPLETION, {
      envelope_id: envelopeId,
      product_family: productFamily,
    }, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...extHeader,
      },
    });

    return { signed };
  };
}
