import { constants } from '../../config/constants';

const { INCOMPLETE } = constants.printModal.infoCompleteness;

class BillingInfo {
  constructor(params) {
    this.cardIncluded = params.cardIncluded;
    this.type = params.type;
    this.company = params.company;
    this.dob = params.dob;
    this.groupNumber = params.groupNumber;
    this.insuredRelationship = params.insuredRelationship;
    this.subscriberFirstName = params.subscriberFirstName;
    this.subscriberLastName = params.subscriberLastName;
    this.policyNumber = params.policyNumber;
  }

  isComplete = () => !!this.type;

  formatForPrintModal = () => {
    const isCompleted = this.isComplete();

    return {
      title: 'BILLING INFORMATION',
      info: isCompleted ? this.type : INCOMPLETE,
      isCompleted,
    };
  }
}

export { BillingInfo };
