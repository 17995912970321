import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';

import styles from './guardant-reveal-ordering-collapsed.module.scss';

const IPropTypes = {
  surgicalResectionDate: PropTypes.string,
  orderFrequency: PropTypes.shape({
    code: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
  preferenceForBloodDraw: PropTypes.string,
  onToggle: PropTypes.func,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  surgicalResectionDate: null,
  orderFrequency: null,
  preferenceForBloodDraw: null,
  onToggle: () => { },
  highlightRequiredFields: false,
};

const GuardantRevealOrderingCollapsed = ({
  surgicalResectionDate,
  orderFrequency,
  preferenceForBloodDraw,
  onToggle,
  highlightRequiredFields,
}) => {
  const highlight = highlightRequiredFields && !orderFrequency.code;

  return (
    <div className={[styles.accordionSection, styles.collapsed].join(' ')} onClick={onToggle}>
      <div className={styles.sectionRow}>
        <CollapsedTab title="Date of surgical resection" value={surgicalResectionDate} truncate={false} />
        <CollapsedTab title="Order" highlightTitle={highlight} value={orderFrequency.name} truncate={false} />
        <CollapsedTab title="Blood draw" value={preferenceForBloodDraw} truncate={false} />
      </div>
    </div>
  );
};

GuardantRevealOrderingCollapsed.propTypes = IPropTypes;
GuardantRevealOrderingCollapsed.defaultProps = defaultProps;

export { GuardantRevealOrderingCollapsed };
