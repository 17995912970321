import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Cookies from 'js-cookie';
import { getAuthClient } from '../login/AuthClient';
import { isInternalUser } from '../../networking/controllers/helper';
import { config } from '../config/config';
import {
  API_GATEWAY_URL,
  USER_MGMT_V2_PUB_PATH,
} from '../config';
import { logger } from '../../util/logger';

const PatientSignUp = (props) => {
  const {
    authenticityToken,
    imgPath,
    welcomeCode,
  } = props;
  const [passwordErrors, setPasswordErrors] = useState('');
  const [dobErrors, setDobErrors] = useState('');
  const [emailErrors, setEmailErrors] = useState('');
  const inputRef = useRef(null);
  const lastName = useRef(null);
  const email = useRef(null);
  const firstPassword = useRef(null);
  const secondPassword = useRef(null);
  const formRef = useRef(null);
  const dob = useRef(null);
  const validate = (event, password1, password2, dateOfBirth) => {
    let passwordError = false;
    let dateOfBirthErr = false;
    let matchTest = true;
    const isPassword = /(?=[A-Za-z0-9]+)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,}).*$/g;
    if (!isPassword.test(password1)) {
      setPasswordErrors(
        'Password requirements were not met. Password requirements: at least 12 characters, a lowercase letter, an uppercase letter, a number',
      );
      matchTest = false;
      passwordError = true;
    }
    if (matchTest && password1 !== password2) {
      setPasswordErrors('Passwords do not match');
      passwordError = true;
    }
    if (dateOfBirth === '') {
      setDobErrors('Date of birth is incorrect');
      dateOfBirthErr = true;
    }
    return [passwordError, dateOfBirthErr];
  };

  const handleSubmit = async (event) => {
    const proceed = true;
    setDobErrors('');
    setPasswordErrors('');
    setEmailErrors('');
    const firstName = inputRef.current.value;
    const secondName = lastName.current.value;
    const useremail = email.current.value;
    const password1 = firstPassword.current.value;
    const password2 = secondPassword.current.value;
    const dateOfBirth = dob.current.value;
    const validateValue = validate(
      event,
      password1,
      password2,
      dateOfBirth,
      proceed,
    );
    const [passErr, doberr] = validateValue;
    if (!passErr && !doberr) {
      Cookies.set('isInternal', false);
      const formData = {
        'session[email]': useremail,
        'session[password]': password1,
        authenticity_token: authenticityToken,
        portal: 'physician',
        has_touch_id: '',
        has_secret: '',
      };
      sessionStorage.setItem('login-form-data', JSON.stringify(formData));
      const createPatientPayload = {
        dob: dateOfBirth,
        email: useremail,
        firstName,
        lastName: secondName,
        password: password1,
      };
      if (welcomeCode.trim().length > 0) {
        createPatientPayload.welcomeCode = welcomeCode;
      }
      const data = JSON.stringify(createPatientPayload);
      const extHeader = (isInternalUser === 'false' || !isInternalUser) && { 'x-portal-auth-type': 'v2ext' };
      const axiosConfig = {
        method: 'post',
        url: `${config()[API_GATEWAY_URL]}${config()[USER_MGMT_V2_PUB_PATH]}/patients`,
        headers: {
          Accept: '*',
          'Content-Type': 'application/json; charset=UTF-8',
          ...extHeader,
        },
        data,
      };
      const userdata = await axios(axiosConfig).catch((err) => {
        if (err.response.data.errors[0].code === 1061) {
          axios.post('/rest/patients/send_account_activation_email', { email: useremail }, { headers: { ...extHeader } }).catch((apierror) => {
            setEmailErrors(apierror.response.data.message);
          });
        } else {
          setEmailErrors(err.response.data.errors[0].message);
        }
      });
      // eslint-disable-next-line no-constant-condition
      if (userdata && (userdata.status === 200 || 201)) {
        await axios.post('/rest/patients/send_signup_success_email', { email: useremail, firstname: firstName, lastname: secondName }, { headers: { ...extHeader } });
        sessionStorage.setItem('userId', userdata.data.data.uuid);
        const authClient = getAuthClient();
        const username = useremail;
        const password = password1;
        authClient
          .signInWithCredentials({ username, password })
          .then((transaction) => {
            if (transaction.status === 'SUCCESS') {
              if (welcomeCode.trim().length === 0) {
                sessionStorage.setItem('patient-more-info', true);
              }
              authClient.token.getWithRedirect({
                sessionToken: transaction.sessionToken,
                responseType: 'token',
              });
            }
          })
          .catch(async (error) => {
            logger.log(error);
          });
      }
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="row content_ registry">
      <div className="registry-content col-xs-12">
        <h1>Create a myGuardant account</h1>
        <div className="notice-banner">
          <img alt="" src={imgPath} />
          Exclusively for United States residents
        </div>
        <form
          ref={formRef}
          className=""
          id="new_patient_user"
          acceptCharset="UTF-8"
          method="post"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          <input
            value="patient"
            type="hidden"
            name="patient_user[account_type]"
            id="patient_user_account_type"
          />
          <input type="hidden" name="welcome_code" id="welcome_code" value={welcomeCode} />
          <div className="form-group">
            <label htmlFor="patient_user_firstname">Patient&apos;s Full Name</label>
            <p className="help-block">
              (Note: Due to international privacy laws, this Patient Portal can
              only be used by US patients. If you are not a US patient, please
              contact your doctor for information regarding any Guardant test
              results.)
            </p>
            <p className="help-block">
              Please enter the patient&apos;s legal first and last name.
            </p>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <input
                  placeholder="First Name"
                  required="required"
                  ref={inputRef}
                  className="form-control"
                  type="text"
                  name="patient_user[firstname]"
                  id="patient_user_firstname"
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <input
                  placeholder="Last Name"
                  ref={lastName}
                  required="required"
                  className="form-control"
                  type="text"
                  name="patient_user[lastname]"
                  id="patient_user_lastname"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="patient_user_dob">Patient&apos;s Date of Birth</label>
            <div className="row">
              <div className="col-xs-12 col-md-12">
                {dobErrors !== '' && (
                  <div className="dob-errors">{dobErrors}</div>
                )}
                <input
                  id="dobpicker"
                  ref={dob}
                  data-template="MMM D YYYY"
                  data-format="DD-MM-YYYY"
                  type="date"
                  name="patient_user[dob]"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="patient_user_email">Set Username</label>
            <p className="help-block">
              Please enter your email address, which you’ll need to enter when
              you sign in to myGuardant. We will also use this email address to
              contact you about important updates.
            </p>
            <div className="row">
              <div className="col-xs-12">
                <input
                  placeholder="e.g. name@example.com"
                  ref={email}
                  required="required"
                  className="form-control"
                  type="email"
                  name="patient_user[email]"
                  id="patient_user_email"
                />
                {emailErrors !== '' && (
                  <div className="email-errors">{emailErrors}</div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="patient_user_password">Set Password</label>
            {passwordErrors !== '' && (
              <div className="password-errors">{passwordErrors}</div>
            )}
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <input
                  placeholder="Choose a password"
                  required="required"
                  id="first-password"
                  className="form-control"
                  type="password"
                  ref={firstPassword}
                  name="patient_user[password]"
                />
                <input
                  placeholder="Re-type password to confirm"
                  required="required"
                  id="second-password"
                  className="form-control"
                  type="password"
                  ref={secondPassword}
                  name="patient_user[password]"
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="registry-tooltip">
                  - at least 12 characters
                  <br />
                  - at least one UPPERCASE
                  <br />
                  - at least one lowercase
                  <br />
                  - at least one number (123)
                  <br />
                  - no parts of your username
                  <br />
                  - your password cannot be any of your last 4 passwords
                </div>
              </div>
            </div>
          </div>
          <div className="form-group checkbox">
            <label className="label-terms" htmlFor="accept_terms">
              <input
                type="checkbox"
                name="accept_terms"
                id="accept_terms"
                value="1"
                required="required"
              />
              By checking this box, I understand and agree that my personal and
              protected health information will be shared over this Portal as
              set forth in Guardant’s Terms and Conditions of Use. It is
              important for you to review and understand the Terms and
              Conditions of Use before checking this box. Please click on the
              “Terms and Conditions of Use” words at the end of the next
              sentence to access and read the Terms and Conditions of Use which
              you are agreeing to by checking the box. I attest that I am the
              individual stated above, or the personal representative of the
              individual stated above, and I agree to the
              <a target="_blank" href="/myguardant/terms_of_use">
                Terms and Conditions of Use
              </a>
              .
            </label>
          </div>
          <button
            className="registry-btn"
            type="submit"
          >
            CREATE YOUR ACCOUNT
          </button>
        </form>
      </div>
      <div className="col-xs-12">
        <div className="registry-headline">
          <div className="registry-text">Already have an account?</div>
          <div className="registry-links">
            <a className="registry-links" href="/myguardant/sign_in" title="">
              SIGN IN HERE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientSignUp;
PatientSignUp.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  imgPath: PropTypes.string.isRequired,
  welcomeCode: PropTypes.string,
};
PatientSignUp.defaultProps = {
  welcomeCode: '',
};
