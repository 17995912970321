import React from 'react';
import PropTypes from 'prop-types';

import styles from './patient-page-header.module.scss';
import { ActionBarContainer } from '../action-bar-container';
import { PatientNameContainer } from '../patient-name-container';

const IPropTypes = {
  rootUrl: PropTypes.string.isRequired,
};

const PatientPageHeader = ({ rootUrl }) => (
  <>
    <div className={styles.header}>
      <div className={styles.column}>
        <a className={styles.backButton} href={rootUrl}>
          <i className="fa fa-angle-left" />
          {' '}
          BACK
        </a>
      </div>
      <div className={styles.column}>
        <PatientNameContainer />
      </div>
      <div className={styles.column}>
        <ActionBarContainer />
      </div>
    </div>
  </>
);

PatientPageHeader.propTypes = IPropTypes;

export { PatientPageHeader };
