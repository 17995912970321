import { connect } from 'react-redux';

import { ClinicalHistoryEarlyStageCollapsed } from './clinical-history-early-stage-collapsed';

const mapStateToProps = store => ({
  clinicalHistoryEarlyStore: store.orders.activeOrder.earlyStageClinicalHistoryInfo,
});

const ConnectedClinicalHistoryEarlyStageCollapsed = connect(
  mapStateToProps,
)(ClinicalHistoryEarlyStageCollapsed);

export { ConnectedClinicalHistoryEarlyStageCollapsed as ClinicalHistoryEarlyStageCollapsed };
