import { createAction } from 'redux-actions';

export const GET_USER = '@usermanagment-workflow/GET_USER';
export const getUsers = createAction(GET_USER);

export const GET_SEARCH_USER = '@usermanagment-workflow/GET_SEARCH_USER';
export const getSearchUsers = createAction(GET_SEARCH_USER);

export const GET_USER_DONE = '@usermanagment-workflow/GET_USER_DONE';
export const getUsersDone = createAction(GET_USER_DONE);

export const USER_ERROR = '@usermanagment-workflow/USER_ERROR';
export const userError = createAction(USER_ERROR);

export const SAVE_USER = '@usermanagment-workflow/SAVE_USER';
export const saveUser = createAction(SAVE_USER);

export const SAVE_USER_DONE = '@usermanagment-workflow/SAVE_USER_DONE';
export const saveUserDone = createAction(SAVE_USER_DONE);

export const SAVE_USER_ERROR = '@usermanagment-workflow/SAVE_USER_ERROR';
export const saveUserError = createAction(SAVE_USER_ERROR);

export const UPDATE_USER = '@usermanagment-workflow/UPDATE_USER';
export const updatUser = createAction(UPDATE_USER);

export const UPDATE_USER_DONE = '@usermanagment-workflow/UPDATE_USER_DONE';
export const updatUserDone = createAction(UPDATE_USER_DONE);

export const UPDATE_USER_ERROR = '@usermanagment-workflow/UPDATE_USER_ERROR';
export const updatUserError = createAction(UPDATE_USER_ERROR);

export const DELETE_USER = '@usermanagment-workflow/DELETE_USER';
export const deleteUser = createAction(DELETE_USER);

export const DELETE_USER_DONE = '@usermanagment-workflow/DELETE_USER_DONE';
export const deleteUserDone = createAction(DELETE_USER_DONE);

export const DELETE_USER_ERROR = '@usermanagment-workflow/DELETE_USER_ERROR';
export const deleteUserError = createAction(DELETE_USER_ERROR);

export const USERS_PAGINATION_CHANGE = '@usermanagment-workflow/USERS_PAGINATION_CHANGE';
export const changePageUser = createAction(USERS_PAGINATION_CHANGE);

export const ON_CHANGE_USER = '@usermanagment-workflow/ON_CHANGE_USER';
export const onChangeUser = createAction(ON_CHANGE_USER);

export const defaultSite = createAction('');
