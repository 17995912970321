import React, { useEffect, useState } from 'react';
import { Toolbar, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import ApplicationMessage from '../common/components/elements/ApplicationMessage';
import ConfirmModalWindow from '../common/components/elements/ConfirmModalWindow';
import ModalPopup from '../common/components/elements/ModalPopup';
import Pagination from '../common/components/elements/Table/Pagination';
import DescriptionAlerts from '../common/components/elements/DescriptionAlerts';
import Controls from '../common/components/forms/controls';
import Table from '../common/Table';
import TableBody from './MatchGenTableBody';
import TableHeader from './MatchGenTableHeader';
import MatchGenForm from './MatchGenForm';
import GenomicCriteriaForm from './GenomicCriteriaForm';
import SearchSpaceForm from './SearchSpaceForm';

const useStyles = makeStyles(() => createStyles({
  root: {
    boxSizing: 'border-box',
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#FFFFFF',
  },
  searchInput: {
    width: '50%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#1A5EA7',
    boxShadow: 'none',
    '@media (max-width: 445px)': {
      width: '40%',
      height: '75%',
    },
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
}));
const headers = [
  { name: 'id', type: 'text' },
  { name: 'name', type: 'text' },
  { name: 'pharmaTrialsId', type: 'text' },
  { name: 'algorithmVersion', type: 'text' },
  { name: 'matchKeyPrefix', type: 'text' },
  { name: 'createdAt', type: 'text' },
  { name: 'endDate', type: 'text' },
  { name: 'lastRunTime', type: 'text' },
];
const MatchGenView = (props) => {
  const [openPopup, setOpenPopup] = useState([false, false, false]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false, title: '', subTitle: '', onConfirm: () => {},
  });
  const [formData, setFormData] = useState(null);
  const [title, setTitle] = useState('');
  const [GenomicTitle, setGenomicTitle] = useState('');
  const [SearchTitle, setSearchTitle] = useState('');
  const classes = useStyles();
  const [multiGenomic, setMultiGenomic] = useState(0);
  const [discriptionAlt, setDiscpAlt] = useState(false);
  const [buttonName, setbuttonName] = useState('Save');
  const [formReset, setForm] = useState(null);
  const [editMatch, setEditMatch] = useState(false);
  const [addGenomic, setAddGenomic] = useState(false);
  const {
    matchGen,
    actions: {
      getMatchGenData,
      addMatchGenData,
      addSearchSpaceData,
      addGenomicData,
      editMatchGenData,
      changePagination,
      onChange,
    },
  } = props;
  const matchData = matchGen.toJS().organizationList || {};
  const algorithmList = matchData.getAllMatchAlgorithms || {};
  const matchGenList = algorithmList.matchAlgorithms;
  const notify = matchGen.toJS().notify || {};
  const pagination = matchGen.toJS().pagination || {};
  const matchGenManagement = matchGen.toJS() || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (matchGenList == null || matchGenManagement.reload) {
      setConfirmDialog({
        isOpen: false, title: '', subTitle: '', onConfirm: () => {},
      });
      getMatchGenData(pagination);
      setAddGenomic(false);
      onChange({ key: 'reload', value: false });
    }
  }, [matchGenList || matchGenManagement.reload]);
  useEffect(() => {
    if (notify.type === 'error') {
      // eslint-disable-next-line consistent-return
      setOpenPopup((previous) => {
        if (previous !== undefined) {
          return [...previous];
        }
      });
    } else {
      setOpenPopup((previous) => {
        if (previous[0] === true) {
          formReset();
          setEditMatch(false);
          return !editMatch ? [false, true, false] : [false, false, false];
        }
        if (previous[1] === true) {
          formReset();
          return [false, false, true];
        }
        if (previous[2] === true) {
          setbuttonName('Save Another Genomic');
          return [false, false, true];
        }

        return [...previous];
      });
    }
  }, [notify.type, notify.message]);

  useEffect(() => {
    if (matchGenList && matchGenList.length === 0 && matchGenManagement.totalRecords !== 0) {
      getMatchGenData(pagination);
    }
  }, [pagination]);

  const addClickHandler = (e) => {
    e.preventDefault();
    setOpenPopup([true, false, false]);
    setFormData(null);
    setTitle('Add Match Algorithm');
  };
  const editClickHandler = (row) => {
    setOpenPopup([true, false, false]);
    setFormData(row);
    setTitle('Edit Match Algorithm');
  };
  const setNotify = (dat) => {
    onChange({ key: 'notify', value: { ...dat } });
  };

  const addGenomicHandler = (row) => {
    setMultiGenomic(row.id);
    setAddGenomic(true);
    setOpenPopup([false, false, true]);
    setGenomicTitle('Add Genomic Criteria');
  };
  const MatchGenSubmitHandler = (values, resetForm, edit) => {
    setForm(() => resetForm);
    if (!edit) {
      addMatchGenData(values);
      setFormData(null);
      setSearchTitle('Add Search Space Criteria');
    } else {
      editMatchGenData(values);
      setFormData(null);
      setEditMatch(true);
    }
  };

  const SearchSpaceFormSubmit = (values, resetForm) => {
    setForm(() => resetForm);
    addSearchSpaceData(values);
    setFormData(null);
    setGenomicTitle('Add Genomic Criteria');
  };
  const GenomicCriteriaFormSubmit = (values, resetForm) => {
    setForm(() => resetForm);
    addGenomicData(values);
    setFormData(null);
  };
  const handlePagination = (e, pagenum) => {
    getMatchGenData(pagenum);
    changePagination(pagenum);
  };
  const actions = [
    { action: 'Edit', handleAction: editClickHandler },
    { action: 'Add', handleAction: addGenomicHandler },
  ];

  return (
    <>
      <div id="matchgenContainer" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Controls.Button
            text="Add Match Algorithm"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={addClickHandler}
          />
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <Table>
              {!isMobile
              && <TableHeader headers={[...headers]} {...{ rows: matchGenList || [], actions }} />}
              <TableBody
                headers={headers}
                {...{
                  rows: matchGenList || [],
                  actions,
                  ...pagination,
                }}
              />
            </Table>
            <Pagination
              count={matchGenManagement.totalRecords}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNo}
              handleChangePage={(e, val) => handlePagination(e, { ...pagination, pageNo: val })}
              handleChangeRowsPerPage={e => handlePagination(e,
                { ...pagination, pageSize: e.target.value, pageNo: 0 })}
            />
          </Grid>
        </Grid>
        <ModalPopup title={`${title}`} openPopup={openPopup[0] || false} setOpenPopup={setOpenPopup}>
          <MatchGenForm
            formData={formData}
            edit={title === 'Edit Match Algorithm'}
            MatchGenSubmitHandler={MatchGenSubmitHandler}
          />
        </ModalPopup>
        <ModalPopup title={`${SearchTitle}`} openPopup={openPopup[1] || false} setOpenPopup={setOpenPopup}>
          <SearchSpaceForm
            matchAlgoID={matchGenManagement.matchAlgoID}
            formData={formData}
            edit={title === 'Edit Organization'}
            SearchSpaceFormSubmit={SearchSpaceFormSubmit}
          />
        </ModalPopup>
        <ModalPopup title={`${GenomicTitle}`} openPopup={openPopup[2] || false} setOpenPopup={setOpenPopup}>
          <GenomicCriteriaForm
            matchAlgoID={addGenomic ? multiGenomic : matchGenManagement.matchAlgoID}
            formData={formData}
            edit={title === 'Edit Organization'}
            GenomicCriteriaFormSubmit={GenomicCriteriaFormSubmit}
            setOpenPopup={setOpenPopup}
            buttonName={buttonName}
          />
        </ModalPopup>
        <ConfirmModalWindow confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        <ApplicationMessage notify={notify} setNotify={setNotify} />
        <DescriptionAlerts confirm={discriptionAlt} setConfirm={setDiscpAlt} />
      </div>
    </>
  );
};
export default MatchGenView;
MatchGenView.propTypes = {
  matchGen: PropTypes.object,
  actions: PropTypes.object,
};
MatchGenView.defaultProps = {
  matchGen: {},
  actions: {},
};
