class LoggedUser {
  constructor(params) {
    this.admin = params.admin;
    this.authenticityToken = params.authenticityToken;
    this.canDownloadReport = params.canDownloadReport;
    this.canReleasePatient = params.canReleasePatient;
    this.canSeeComments = params.canSeeComments;
    this.canShareReport = params.canShareReport;
    this.id = params.id;
    this.canReleaseReport = params.canReleaseReport;
    this.rootUrl = params.rootUrl;
    this.shareReportEnabled = params.shareReportEnabled;
    this.showDisclaimer = params.showDisclaimer;
  }
}

export { LoggedUser };
