import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import OrdersDashboard from './pages/OrdersDashboard';
import NewOrderRootContainer from '../addEditOrder/NewOrderRootContainer';

const Orders = props => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/new_orders" render={() => <OrdersDashboard {...props} />} />
      <Route exact path="/new_orders/new" render={() => <NewOrderRootContainer {...props} />} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);
export default Orders;
