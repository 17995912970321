import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const IPropTypes = {
  info: PropTypes.object.isRequired,
  showModalWindow: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};
const PendingSettings = (props) => {
  const {
    info: {
      firstname: firstName,
      lastname: lastName,
      dob_status: dobStatus,
      name_status: nameStatus,
      dob: dobDisplay,
      welcome_code: welcomeCode,
      bloodcolldate,
      physician_last_name: physicianLastName,
      additional_information_status: additionalInformationStatus,
    },
    showModalWindow,
    editable,
  } = props;
  const empty = !(!welcomeCode || !bloodcolldate || !physicianLastName);
  return (
    <>
      <div className="white-tab clickable__edit__name" onClick={() => (editable ? showModalWindow('name') : undefined)}>
        <div className="left">PATIENT&apos;S FULL NAME</div>
        <div className="mid-italic refresh__name">
          {`${firstName} ${lastName}`}
        </div>
        <div className="right">
          {
            dobStatus === 'PENDING' ? (
              <span className="text-pending">{nameStatus}</span>
            ) : (
              <span className="text-please-confirm">{nameStatus}</span>
            )
          }
          <span className="fa fa-chevron-right" />
        </div>
      </div>
      <div className="white-tab clickable__edit__dob" onClick={() => (editable ? showModalWindow('dob') : undefined)}>
        <div className="left">PATIENT&apos;S DATE OF BIRTH</div>
        <div className="mid-italic refresh__dob">{dobDisplay}</div>
        <div className="right">
          {
            dobStatus === 'PENDING' ? (
              <span className="text-pending">{dobStatus}</span>
            ) : (
              <span className="text-please-confirm">{dobStatus}</span>
            )
          }
          <span className="fa fa-chevron-right" />
        </div>
      </div>
      <div className="white-tab clickable__edit__additional-info" onClick={() => (editable ? showModalWindow('additionalInfo') : undefined)}>
        <div className="left">ADDITIONAL IDENTIFICATION</div>
        <div className="mid-italic refresh__additional-info">
          {
            welcomeCode && (
              <>
                <span className="mid-italic">{welcomeCode}</span>
                <span className="mid-italic">
                &nbsp;(activation code)
                  <br />
                  <br />
                </span>
              </>
            )
          }
          {
            bloodcolldate && (
              <>
                <span className="mid-italic">{moment(bloodcolldate).format('MMM DD,YYYY')}</span>
                <span className="mid-italic">
                &nbsp;(blood collection date)
                  <br />
                  <br />
                </span>
              </>
            )
          }
          {
            physicianLastName && (
              <>
                <span className="mid-italic">{physicianLastName}</span>
                <span className="mid-italic">
                &nbsp;(physician&apos;s last name)
                  <br />
                  <br />
                </span>
              </>
            )
          }
          {empty === true && <div className="mid-italic">empty</div>}
        </div>
        <div className="right">
          {
            additionalInformationStatus === 'PENDING'
              ? <span className="text-pending">{additionalInformationStatus}</span>
              : <span className="text-pending">{additionalInformationStatus}</span>
          }
          <span className="fa fa-chevron-right" />
        </div>
      </div>
    </>
  );
};

export default PendingSettings;
PendingSettings.propTypes = IPropTypes;
