import React from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'throttle-debounce';

const DEFAULT_MIN_CHARACTERS = 2;

const IPropTypes = {
  className: PropTypes.string,
  inputValue: PropTypes.string,
  // Allows spaces on the beginning and ending of the input.
  allowSpaces: PropTypes.bool,
  // NOTE: this function should return a list of objects.
  // These objects should have a `label` key with the text to show,
  // an optional `id` to use as key (the label will be used by default),
  // and a `value` key with the value that is returned when clicking an option.
  onOptionsFetch: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  minLength: PropTypes.number,
  onInputChange: PropTypes.func,
  onOptionSelect: PropTypes.func,
  name: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string,
  hideNoResultsOption: PropTypes.bool,
  highlightedBorder: PropTypes.bool,
};

const defaultProps = {
  className: '',
  allowSpaces: false,
  label: '',
  labelClassName: '',
  inputValue: '',
  noResultsMessage: 'No results found',
  hideNoResultsOption: false,
  minLength: DEFAULT_MIN_CHARACTERS,
  onInputChange: () => {},
  onOptionSelect: () => {},
  highlightedBorder: false,
};

class Typeahead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: null,
    };

    this.container = null;
    // Used to avoid race conditions on suggestion requests.
    this.optionFetchId = 0;
    this.inputText = '';

    this._setContainer = this._setContainer.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);
    this._fetchOptions = this._fetchOptions.bind(this);
    this._clearOptions = this._clearOptions.bind(this);
    this._updateText = this._updateText.bind(this);
    this._debounceSearch = debounce(300, this._queryForOptions.bind(this));
  }

  componentDidMount() {
    document.addEventListener('click', this._handleDocumentClick, false);
    document.addEventListener('touchend', this._handleDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleDocumentClick, false);
    document.removeEventListener('touchend', this._handleDocumentClick, false);
  }

  _setContainer(element) {
    this.container = element;
  }

  _handleDocumentClick(event) {
    if (this.container && !this.container.contains(event.target)) {
      if (this.state.options) {
        this._clearOptions();
      }
    }
  }

  _updateText(event) {
    const { target: { value } } = event;
    this.props.onInputChange(event);
    if (value) {
      this._fetchOptions(value);
    } else {
      this._clearOptions();
    }
  }

  _optionClickCallback(option) {
    return () => {
      this._clearOptions();
      this.props.onOptionSelect(option);
    };
  }

  _clearOptions() {
    // Ignore all pending requests
    this.optionFetchId += 1;
    this.setState({
      options: null,
    });
  }

  async _queryForOptions() {
    this.optionFetchId += 1;
    const requestId = this.optionFetchId;
    let options = this.props.onOptionsFetch(this.inputText);
    // If the return value of onOptionsFetch isn't a promise, then
    // create one that auto resolves to make it easier to process.
    if (!(options instanceof Promise)) {
      options = Promise.resolve(options);
    }

    const result = await options;

    if (requestId !== this.optionFetchId) {
      return;
    }

    this.setState({ options: result || [] });
  }

  _fetchOptions(text) {
    const preparedText = this.props.allowSpaces ? text : text.trim();
    if (preparedText.length < this.props.minLength) {
      return;
    }
    this.inputText = preparedText;
    this._debounceSearch();
  }

  render() {
    const propsToPass = { ...this.props };
    delete propsToPass.onOptionsFetch;
    delete propsToPass.onInputChange;
    delete propsToPass.onOptionSelect;
    delete propsToPass.labelClassName;
    delete propsToPass.inputValue;
    delete propsToPass.minLength;
    delete propsToPass.allowSpaces;
    delete propsToPass.label;
    delete propsToPass.noResultsMessage;
    delete propsToPass.hideNoResultsOption;
    delete propsToPass.highlightedBorder;
    return (
      <div
        className={['typeahead', this.props.className].join(' ')}
        ref={this._setContainer}
      >
        {
          !!this.props.label
          && (
          <label
            className={['typeahead__label', this.props.labelClassName].join(' ')}
            htmlFor={this.props.name}
          >
            {this.props.label}
          </label>
          )
        }
        <div className="typeahead__input-container">
          <div className="typeahead__search-icon-container">
            <i className="fa fa-search" />
          </div>
          <input
            {...propsToPass}
            className={['typeahead__input', this.props.highlightedBorder ? 'red-box' : ''].join(' ')}
            id={this.props.name}
            onChange={this._updateText}
            value={this.props.inputValue || ''}
          />
        </div>
        {
          !!this.state.options
          && (
          <div className="typeahead__option-container">
            {
              !!this.state.options.length
              && this.state.options.map(option => (
                <button
                  key={option.id || option.label}
                  type="button"
                  className="typeahead__option"
                  onClick={this._optionClickCallback(option)}
                >
                  {option.label}
                </button>
              ))
            }
            {
              !this.state.options.length
              && !this.props.hideNoResultsOption
              && (
              <div
                className="typeahead__option"
              >
                {this.props.noResultsMessage}
              </div>
              )
            }
          </div>
          )
        }
      </div>
    );
  }
}

Typeahead.propTypes = IPropTypes;
Typeahead.defaultProps = defaultProps;

export default Typeahead;
