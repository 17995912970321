import React from 'react';

const RevokeConfirmation = () => (
  <div className="share-created__revoke-confirmation share-revoke-display">
    <div className="share-created__confirmation">
      <div className="share-created__confirmation-text">Are you sure you want to revoke access?</div>
      <button type="button" className="share-created__confirmation-btn share-created__confirmation-btn-red">
        <div className="share-created__action-icon-red" />
        <div className="share-created__action-text-red">NO</div>
      </button>
      <button type="button" className="share-created__confirmation-btn share-created__confirmation-btn-green">
        <div className="share-created__action-icon-green" />
        <div className="share-created__action-text-green">YES</div>
      </button>
    </div>
  </div>
);
export default RevokeConfirmation;
