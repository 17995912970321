/* eslint-disable jsx-a11y/anchor-is-valid, no-prototype-builtins */
import React from 'react';
import PropTypes from 'prop-types';
import SignOut from '../login/SignOut';

const IPropTypes = {
  navData: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
};
const PhysicianSiteHeaderSignedIn = (props) => {
  const {
    fullName,
    email,
    lastLogin,
    PharmaPortal,
    ProviderPortal,
    termsPath,
    iosUser,
    editUserPath,
    canAccessPermissions,
    formAuthenticityToken,
    providerPortal,
    pharmaPortal,
    ...rest
  } = props.navData;
  const { viewAsUser: admin } = props;
  const path = window.location.search;
  const pathName = window.location.pathname;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const featureClaims = (userInfo
      && userInfo.accessInfo.authorities.admin
      && userInfo.accessInfo.authorities.featureClaims)
    || {};
  featureClaims.Matchgen = ['VIEW', 'CREATE', 'UPDATE', 'DELETE'];
  const enableAdmin = 'Enable Administration Tab';
  const enableOrder = 'Enable Order V2';
  const isAdminEnable = userInfo && userInfo.accessInfo.settings[enableAdmin].currentValue;
  //let newOrders = userInfo && userInfo.accessInfo.settings[enableOrder].currentValue;
  const isPharma = featureClaims.hasOwnProperty('Matchgen');
  /*
  if (userInfo && userInfo.accessInfo.authorities.admin) {
    newOrders = true;
  }
  */
  return (
    <div className="hidden-xs">
      <ul className="nav navbar-nav portal">
        {canAccessPermissions && (
        <li>
          <a
            href={rest.userRootUrl}
            className={`${
              pathName === rest.userRootUrl && 'active'
            }  main-link_ dashboard_button`}
          >
            {rest.studiesDashboardUser ? 'PATIENTS' : 'DASHBOARD'}
          </a>
        </li>
        )}
        {rest.studiesDashboardUser && (
        <li className="studies-tab">
          <a
            href={rest.studiesPath}
            className={`${
              pathName === rest.studiesPath && 'active'
            }  main-link_`}
          >
            STUDIES
          </a>
        </li>
        )}
        {rest.canAccessOrders && (
        <li>
          <a
            href='/new_orders'
            className={`${
              pathName === '/new_orders' && 'active'
            }  main-link_`}
          >
            ORDERS
          </a>
        </li>
        )}
        {canAccessPermissions && (
        <li>
          <a
            href={rest.sharesPath}
            className={`${
              pathName === rest.sharesPath && 'active'
            } main-link_`}
          >
            PERMISSIONS
          </a>
        </li>
        )}
        {userInfo && userInfo.accessInfo.authorities.admin && isPharma
          // <li className="dropdown">
          //   {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          //   <a
          //     href=""
          //     className={`${
          //       path === rest.administrationsPath && 'active'
          //     } main-link_`}
          //   >
          //     Pharma
          //   </a>
          //   <div className="dropdown-content">
          //     {featureClaims.hasOwnProperty('Matchgen') && (
          //     <a
          //       className={`${
          //         path === '?matchgen=true' && 'active'
          //       } main-link_`}
          //       href="/administrations?matchgen=true"
          //     >
          //       Matchgen
          //     </a>
          //     )}
          //   </div>
          // </li>
        }
        {(userInfo && userInfo.accessInfo.authorities.admin && isAdminEnable) && admin && (
        <li className="dropdown">
          <a
            className={`${
              pathName === rest.administrationsPath && 'active'
            } main-link_`}
          >
            ADMINISTRATION
          </a>
          <div className="dropdown-content">
            {featureClaims.hasOwnProperty('Account') && (
            <a
              className={`${
                path === '?account=true' && 'active'
              } main-link_`}
              href="/administrations?account=true"
            >
              Account
            </a>
            )}
            {featureClaims.hasOwnProperty('Site') && (
            <a
              className={`${
                path === '?site=true' && 'active'
              } main-link_`}
              href="/administrations?site=true"
            >
              Site
            </a>
            )}
            {featureClaims.hasOwnProperty('Role') && (
            <a
              className={`${
                path === '?roles=true' && 'active'
              } main-link_`}
              href="/administrations?roles=true"
            >
              Role
            </a>
            )}
            {featureClaims.hasOwnProperty('User') && (
            <a
              className={`${
                path === '?users=true' && 'active'
              } main-link_`}
              href="/administrations?users=true"
            >
              User
            </a>
            )}
            {featureClaims.hasOwnProperty('Role Assignment') && (
            <a
              className={`${
                path === '?roleassignment=true' && 'active'
              } main-link_`}
              href="/administrations?roleassignment=true"
            >
              Role Assignment
            </a>
            )}
            {featureClaims.hasOwnProperty('Site Assignment') && (
            <a
              className={`${
                path === '?siteassignment=true' && 'active'
              } main-link_`}
              href="/administrations?siteassignment=true"
            >
              Site Assignment
            </a>
            )}
            {featureClaims.hasOwnProperty('Project Assignment') && (
            <a
              className={`${
                path === '?projectassignment=true' && 'active'
              } main-link_`}
              href="/administrations?projectassignment=true"
            >
              Project Assignment
            </a>
            )}
            {featureClaims.hasOwnProperty('Patient Management') && (
            <a
              className={`${
                path === '?patients=true' && 'active'
              } main-link_`}
              href="/administrations?patients=true"
            >
              Patient Management
            </a>
            )}
          </div>
        </li>
        )}
      </ul>
      <div className="menu-user-container hidden-xs">
        <a
          aria-expanded="true"
          aria-haspopup="true"
          className="dropdown-toggle no-spinner"
          data-toggle="dropdown"
          href="#"
          role="button"
        >
          <p className="menu-user-name">
            {(fullName && fullName.length > 1 && fullName) || email}
          </p>
          <i className="fa fa-chevron-up" />
          <i className="fa fa-chevron-down" />
        </a>
        <ul className="dropdown-menu">
          <li className="no-hover">
            <div className="text bold">{email}</div>
          </li>
          <li className="no-hover">
            <div className="text">
              Last login:
              {lastLogin}
            </div>
          </li>
          <li className="li-divider no-hover" />
          <li>
            <a
              href={
                iosUser
                  ? 'gonative://auth/status?callback=%2ftouch_status_settings_initial_load'
                  : editUserPath
              }
            >
              Settings
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://docs.google.com/document/u/1/d/109CjD5xpcP3Wok3RWt9dZRpa63iVFlaeZhBZezWlVeE/pub"
              rel="noreferrer"
            >
              Help &amp; FAQ&apos;s
            </a>
          </li>
          <li>
            {termsPath && <a href={termsPath}>Terms and Conditions of Use</a>}
          </li>
          <li>
            {providerPortal && (
              <form
                action="/switch_portal"
                acceptCharset="UTF-8"
                method="post"
              >
                <input type="hidden" name="_method" value="put" />
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={formAuthenticityToken}
                />
                <input
                  type="hidden"
                  name="portal"
                  id="portal"
                  value="physician"
                />
                <a className="switch-portal-submit">Switch to Provider Portal</a>
              </form>
            )}
          </li>
          <li>
            {pharmaPortal && (
              <form
                action="/switch_portal"
                acceptCharset="UTF-8"
                method="post"
              >
                <input type="hidden" name="_method" value="put" />
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={formAuthenticityToken}
                />
                <input type="hidden" name="portal" id="portal" value="pharma" />
                <a className="switch-portal-submit">Switch to Pharma Portal</a>
              </form>
            )}
          </li>
          <SignOut authenticityToken={formAuthenticityToken} />
          <li className="li-divider no-hover" />
          <li className="no-hover">
            <div className="text">Call us at 1-855-698-8887</div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default PhysicianSiteHeaderSignedIn;
PhysicianSiteHeaderSignedIn.propTypes = IPropTypes;
