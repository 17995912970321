import React from 'react';
import PropTypes from 'prop-types';

import { ICDSearch } from '../icd-search';

const IPropTypes = {
  storeSelectedItem: PropTypes.func.isRequired,
  primaryCode: PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  highlightedBorder: PropTypes.bool,
};

const defaultProps = {
  highlightedBorder: false,
};

const PrimaryCode = ({ primaryCode, storeSelectedItem, highlightedBorder }) => {
  const onChange = (selectedCode) => {
    if (selectedCode) {
      storeSelectedItem({
        code: selectedCode.icdCode,
        description: selectedCode.description,
        limsICDCode: selectedCode.id,
      });
    } else {
      storeSelectedItem({ code: '', description: '' });
    }
  };

  const initialValue = (primaryCode && primaryCode.code) ? `${primaryCode.code} ${primaryCode.description}` : '';

  return (
    <ICDSearch
      selectedValue={initialValue}
      onChange={onChange}
      highlightedBorder={highlightedBorder}
      inline
    />
  );
};

PrimaryCode.propTypes = IPropTypes;
PrimaryCode.defaultProps = defaultProps;

export { PrimaryCode };
