import React from 'react';
import { Provider } from 'react-redux';

import { BannerMessage } from './banner-message';
import { store } from '../../../store';

const BannerMessageContainer = () => (
  <Provider store={store}>
    <BannerMessage />
  </Provider>
);

export { BannerMessageContainer };
