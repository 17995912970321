import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './list-picker.module.scss';
import { Button } from '../button';
import { FilterList } from './filter-list/filter-list';


const IPropTypes = {
  title: PropTypes.string,
  firstListTitle: PropTypes.string,
  secondListTitle: PropTypes.string,
  firstList: PropTypes.arrayOf(PropTypes.string).isRequired,
  secondList: PropTypes.arrayOf(PropTypes.string).isRequired,
  addItemCallback: PropTypes.func.isRequired,
  removeItemCallback: PropTypes.func.isRequired,
  filteredFirstList: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterCallback: PropTypes.func.isRequired,
  buttonEnable: PropTypes.bool.isRequired,
};

const defaultProps = {
  title: '',
  firstListTitle: '',
  secondListTitle: '',
};

const ListPicker = ({
  title,
  firstList,
  secondList,
  firstListTitle,
  secondListTitle,
  addItemCallback,
  removeItemCallback,
  filteredFirstList,
  filterCallback,
  buttonEnable,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const onSelect = (item) => {
    setSelectedItem(item);
  };

  const addItem = () => {
    if (selectedItem && firstList.includes(selectedItem)) {
      addItemCallback(selectedItem);
      setSelectedItem(null);
    }
  };

  const removeItem = () => {
    if (selectedItem && secondList.includes(selectedItem)) {
      removeItemCallback(selectedItem);
      setSelectedItem(null);
    }
  };

  const onInputChange = (e) => {
    const { target: { value } } = e;
    setInputValue(value);
    filterCallback(value);
  };

  return (
    <>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.listsContainer}>
        <div className={styles.firstListContainer}>
          {firstListTitle}
          <FilterList value={inputValue} onChange={onInputChange} />
          <ul className={styles.firstList}>
            {
              filteredFirstList.map(item => (
                <div
                  className={`${styles.listItem} ${item === selectedItem ? styles.selectedItem : ''}`}
                  onClick={() => onSelect(item)}
                  key={item}
                >
                  {item}
                </div>
              ))
            }
          </ul>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            onClick={addItem}
            className={styles.addButton}
            disabled={!filteredFirstList.length || buttonEnable}
          >
            add &gt;
          </Button>
          <Button
            onClick={removeItem}
            className={styles.removeButton}
            disabled={!secondList.length}
          >
            &lt; remove
          </Button>
        </div>
        <div className={styles.secondListContainer}>
          {secondListTitle}
          <ul className={styles.secondList}>
            {
              secondList.map(item => (
                <div
                  className={`${styles.listItem} ${item === selectedItem ? styles.selectedItem : ''}`}
                  onClick={() => onSelect(item)}
                  key={item}
                >
                  {item}
                </div>
              ))
            }
          </ul>
        </div>
      </div>
    </>
  );
};

ListPicker.propTypes = IPropTypes;
ListPicker.defaultProps = defaultProps;

export { ListPicker };
