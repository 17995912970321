import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './guardant-reveal-ordering-expanded.module.scss';
import baseEtrfStyles from '../../../../assets/stylesheets/etrf/base.module.scss';
import { DateInput } from '../../../generic/DateInput';
import { FormatHelper } from '../../helpers/format_helper';

const IPropTypes = {
  surgicalResectionDate: PropTypes.string,
  orderFrequency: PropTypes.shape({
    code: PropTypes.arrayOf(PropTypes.string),
  }),
  preferenceForBloodDraw: PropTypes.string,
  saveGuardantRevealOrderingInfo: PropTypes.func.isRequired,
  highlightRequiredFields: PropTypes.bool,
  testSelections: PropTypes.shape({
    preferenceForBloodDraw: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
    ),
    testTypes: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.arrayOf(PropTypes.string),
          code: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
    ),
  }),
};

const defaultProps = {
  surgicalResectionDate: null,
  orderFrequency: null,
  preferenceForBloodDraw: null,
  highlightRequiredFields: false,
  testSelections: null,
};

const GuardantRevealOrderingExpanded = ({
  surgicalResectionDate,
  orderFrequency,
  preferenceForBloodDraw,
  highlightRequiredFields,
  saveGuardantRevealOrderingInfo,
  testSelections,
}) => {
  const storeSurgicalResectionDate = useCallback((date) => {
    saveGuardantRevealOrderingInfo({
      surgicalResectionDate: date,
    }, true);
  }, []);

  const saveOrderFrequency = useCallback((e) => {
    const { value } = e.target;

    saveGuardantRevealOrderingInfo({ orderFrequency: value }, true);
  }, []);

  const savePreferenceForBloodDraw = useCallback((e) => {
    const { value } = e.target;

    saveGuardantRevealOrderingInfo({
      preferenceForBloodDraw: value,
    }, true);
  }, []);

  const highlightBoxes = highlightRequiredFields && !orderFrequency.code;

  if (!testSelections) {
    return null;
  }

  const preferenceForBloodDrawTitle = Object.keys(testSelections.preferenceForBloodDraw || [])[0];
  const bloodDrawOptions = testSelections
    .preferenceForBloodDraw[preferenceForBloodDrawTitle][0].text;

  return (
    <div className={baseEtrfStyles.accordionSection}>
      <div>
        <span className={baseEtrfStyles.requiredFieldsText}>* Required fields</span>
      </div>
      <div className={styles.dateContainer}>
        <label
          htmlFor="reveal-date-of-surgical-resection"
          className={baseEtrfStyles.fieldLabel}
        >
          Date of surgical resection
        </label>
        <DateInput
          className={styles.dateInput}
          id="reveal-date-of-surgical-resection"
          value={surgicalResectionDate || ''}
          maxDate={FormatHelper.getTodaysDate()}
          onInputChange={storeSurgicalResectionDate}
        />
      </div>
      <fieldset className={[styles.productTypeContainer, highlightBoxes ? styles.highlighted : ''].join(' ')}>
        <legend className={[styles.legend, highlightBoxes ? styles.highlightedText : ''].join(' ')}>Select one *</legend>
        <div className={styles.tests}>
          {
            Object.entries(testSelections.testTypes || {}).map(([key, value]) => {
              const radioValue = (((value || [])[0] || {}).code[0]);
              const texts = (((value || [])[0] || {}).text) || [];
              return (
                <div className={styles.productContainer} key={key}>
                  <input
                    id={key}
                    type="radio"
                    value={radioValue}
                    checked={(orderFrequency.code || [])[0] === radioValue}
                    onChange={saveOrderFrequency}
                  />
                  <div>
                    <label
                      htmlFor={key}
                      className={styles.productLabel}
                    >
                      <strong>
                        {key}
                      </strong>
                      <div>
                        {
                          !!texts.filter(Boolean).length && texts.map(text => (
                            <React.Fragment key={text}>
                              {text}
                              <br />
                            </React.Fragment>
                          ))
                        }
                      </div>
                    </label>
                  </div>
                </div>
              );
            })
          }
        </div>
      </fieldset>
      <div className={styles.bloodDrawContainer}>
        <div className={baseEtrfStyles.fieldLabel}>
          {preferenceForBloodDrawTitle}
        </div>
        <div className={styles.bloodDrawRadioContainer}>
          {
            bloodDrawOptions.map(text => (
              <div className={styles.bloodDrawRadio} key={text}>
                <input
                  id={text}
                  type="radio"
                  value={text}
                  checked={preferenceForBloodDraw === text}
                  onChange={savePreferenceForBloodDraw}
                />
                <label
                  htmlFor={text}
                  className={styles.drawPreferenceLabel}
                >
                  {text}
                </label>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

GuardantRevealOrderingExpanded.propTypes = IPropTypes;
GuardantRevealOrderingExpanded.defaultProps = defaultProps;

export { GuardantRevealOrderingExpanded };
