class FormatHelper {
  static capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  static getTodaysDate() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    today = `${yyyy}-${mm}-${dd}`;
    return today;
  }

  static toLocalDate(date) {
    const updatedDate = new Date(date);

    const options = {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', dateStyle: 'long',
    };
    const timeOptions = { timeStyle: 'short' };

    const finalDate = updatedDate.toLocaleDateString('en-US', options);
    const finalTime = updatedDate.toLocaleTimeString('en-US', timeOptions);

    return `${finalDate} ${finalTime}`;
  }
}

export { FormatHelper };
