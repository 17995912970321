import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: { main: '#1F5FAC' },
    success: { main: '#42A41C' },
    error: { main: '#d82a34' },
    warning: { main: '#d06800' },
    background: { default: '#8B8B98' },
  },
  typography: {
    fontFamily: ['Helvetica', 'sans-serif', 'Arial'].join(','),
    color: '#273644',
    h1: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: -1.5,
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
      letterSpacing: -0.5,
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '24px',
    },
    h6: {
      fontSize: 20,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 300,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.1,
      lineHeight: '24px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.25,
      opacity: 0.6,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 1,
      color: '#273644',
    },
  },
});
