class TrialSiteSerializer {
  static deserialize(data) {
    return {
      center: data.center,
      status: data.status,
      city: (data.address || {}).city,
      country: (data.address || {}).country,
      state: (data.address || {}).state,
    };
  }
}

export { TrialSiteSerializer };
