import { actionTypes } from '../../../actions/orders/dashboard';

const initialState = {
  ordersAmounts: null,
  orders: null,
};

const ordersDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ORDERS_AMOUNTS:
      return { ...state, ordersAmounts: action.ordersAmounts };
    case actionTypes.RECEIVE_ORDERS:
      return { ...state, orders: { ...state.orders, ...action.orders } };
    default:
      return state;
  }
};

export { ordersDashboardReducer };
