import { connect } from 'react-redux';
import { AddGuardantRevealOrderingActionTypes } from '../../../../store/actions/orders/active-order/guardant-reveal-ordering-info';
import { GuardantRevealOrderingExpanded } from './guardant-reveal-ordering-expanded';

const mapStateToProps = ({
  orders: { activeOrder: { guardantRevealOrderingInfo, formErrors }, staticData },
}) => ({
  surgicalResectionDate: guardantRevealOrderingInfo.surgicalResectionDate,
  orderFrequency: guardantRevealOrderingInfo.orderFrequency,
  preferenceForBloodDraw: guardantRevealOrderingInfo.preferenceForBloodDraw,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
  testSelections: staticData.testSelections,
});

const mapDispatchToProps = dispatch => ({
  saveGuardantRevealOrderingInfo: (info, hideBanner) => (
    dispatch(AddGuardantRevealOrderingActionTypes(info, hideBanner))
  ),
});

const ConnectedGuardantRevealOrderingExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(GuardantRevealOrderingExpanded);

export { ConnectedGuardantRevealOrderingExpanded as GuardantRevealOrderingExpanded };
