import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AddDiagnosisInfo } from '../../../../store/actions/orders/active-order/diagnosis_info';

import { CancerTypeSection } from './cancer-type-section';

const mapStateToProps = ({
  orders: {
    activeOrder: { diagnosisInfo, formErrors },
    staticData: { diagnoses },
  },
}) => ({
  disease: diagnosisInfo.disease,
  otherDisease: diagnosisInfo.otherDisease,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
  diagnoses,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveDiagnosisInfo: AddDiagnosisInfo,
}, dispatch);

const ConnectedCancerTypeSection = connect(
  mapStateToProps, mapDispatchToProps,
)(CancerTypeSection);

export { ConnectedCancerTypeSection as CancerTypeSection };
