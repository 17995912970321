import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { PrintModal } from '../print-modal';
import { logger } from '../../../util/logger';
import { ElectronicSignatureConfirmationContainer } from '../electronic-signature-confirmation-container';
import { Order } from '../../../models/order/order';
import { OrdersController } from '../../../networking/controllers/orders';
import { printPDFURL } from '../../../networking/print-helper';
import { getToken, isInternalUser } from '../../../networking/controllers/helper';

const IPropTypes = {
  toggleElectronicSignatureModal: PropTypes.func.isRequired,
  togglePrintModal: PropTypes.func.isRequired,
  updateModalInfo: PropTypes.func.isRequired,
  orderingUserEmail: PropTypes.string.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Order),
};

const defaultProps = {
  order: null,
};

const PrintCompletedOrderModalContainer = ({
  order,
  togglePrintModal,
  toggleElectronicSignatureModal,
  updateModalInfo,
  orderingUserEmail,
  updateLoadingCount,
}) => {
  if (!order) {
    return null;
  }

  const printAgainLink = printPDFURL(
    order.metadata.envelopeId, order.id, order.productFamily, true,
  );

  const onClickPrintAgain = useCallback(async () => {
    updateLoadingCount(1);
    const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
    try {
      axios.get(printAgainLink, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...extHeader,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);
          window.open(url, '_blank');
        });

      // Needed since order dashboard is not refreshed
      // when clicking Print now so latestprintDate is gonna be null yet.
      const { metadata } = order;
      if (!metadata.latestPrintDate) {
        metadata.latestPrintDate = new Date();
      }
      updateModalInfo({ printOrderText: 'PRINT AGAIN' });
    } catch (error) {
      logger.error(error);
    } finally {
      updateLoadingCount(-1);
    }
  }, [printAgainLink]);

  const finalizingOrderServerAction = () => (
    OrdersController.finalizePrintedOrder(order.id, order.orderingUser.id, order.productFamily)
  );

  const onClickFinalizeOrder = async () => {
    if (order.isEmbeddedSignature(orderingUserEmail)) {
      try {
        updateLoadingCount(1);

        const { redirectUrl } = await finalizingOrderServerAction();

        window.location.href = redirectUrl;
      } catch (error) {
        logger.error(error);
      } finally {
        updateLoadingCount(-1);
      }
    } else {
      toggleElectronicSignatureModal();
      togglePrintModal();
    }
  };

  return (
    <>
      <PrintModal
        onClickPrintOrder={onClickPrintAgain}
        onClickFinalizeOrder={onClickFinalizeOrder}
        disableFinalizeButton={
          !order.finalizationAvailable()
          || !order.canBeSigned(orderingUserEmail)
        }
        showNoPhysicianEmailDisclaimer={!order.finalizationAvailable()}
      />
      <ElectronicSignatureConfirmationContainer serverAction={finalizingOrderServerAction} />
    </>
  );
};

PrintCompletedOrderModalContainer.propTypes = IPropTypes;
PrintCompletedOrderModalContainer.defaultProps = defaultProps;

export { PrintCompletedOrderModalContainer };
