import React from 'react';
import PropTypes from 'prop-types';

import { isUsername, isValidUser } from '../util/validator';

const IPropTypes = {
  addBannerMessages: PropTypes.func.isRequired,
};

class AdminCreateGHAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      username: '',
      errorText: '',
    };
    this.createUser = this.createUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSuccessResponse = this.handleSuccessResponse.bind(this);
  }

  createUser() {
    const accountType = window.$('#_account_type').val();
    const { firstName, lastName, username } = this.state;

    if (!username) {
      this.setState({ errorText: 'Username is required.' });
      return;
    }
    this.setState({ errorText: '' });


    if ((username && !isUsername(username)) || !isValidUser(username)) {
      this.setState({ errorText: 'Please enter a valid email address.' });
      return;
    }
    this.setState({ errorText: '' });

    // eslint-disable-next-line max-len
    window.createUser(accountType, firstName, lastName, username, this.handleSuccessResponse);
  }

  handleSuccessResponse(bannerMessage) {
    this.props.addBannerMessages({ messages: [bannerMessage], status: 'success' });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.createUser();
  }

  update(field) {
    return (e) => {
      this.setState({ [field]: e.target.value });
    };
  }

  content() {
    return (
      <div className="content">
        <div className="email-errors" id="email-errors">{this.state.errorText}</div>
        <div id="select-account-type-label" className="grey-box__form__item-label share__email__address">Select Account Type*</div>
        <select id="_account_type" className="select-box__container create-gh-admin-select-box" required>
          <option className="select-dropdown__option" value="Standard GH Admin">Standard GH Admin</option>
          <option className="select-dropdown__option" value="Client Services GH Admin">Client Services GH Admin</option>
          <option className="select-dropdown__option" value="Super GH Admin">Super GH Admin</option>
          <option className="select-dropdown__option" value="Portal Dev GH Admin">Portal Dev GH Admin</option>
          <option className="select-dropdown__option" value="Sales Admin">Sales Admin</option>
        </select>
        <div className="grey-box__form__item">
          <div className="grey-box__form__item-inner grey-box__form__item-inner-left">
            <label className="grey-box__form__item-label share__email__address" htmlFor="__first_name_">
              First Name
              <input
                type="text"
                name="[first_name]]"
                id="__first_name_"
                className="grey-box__form__item grey-box__form__item-email change-admin-input"
                placeholder="e.g. Joe"
                onChange={this.update('firstName')}
              />
            </label>
          </div>
          <div className="grey-box__form__item-inner grey-box__form__item-inner-right">
            <label className="grey-box__form__item-label share__email__address" htmlFor="_:last_name">
              Last Name
              <input
                type="text"
                name="[last_name]]"
                id="_last_name"
                className="grey-box__form__item grey-box__form__item-email change-admin-input"
                placeholder="e.g. Smith"
                onChange={this.update('lastName')}
              />
            </label>
          </div>
        </div>
        <label
          id="create-gh-admin-username-label"
          className="grey-box__form__item-label share__email__address"
          htmlFor="_username"
        >
          Username*
          <input
            type="text"
            id="_username"
            className="grey-box__form__item grey-box__form__item-email change-admin-input"
            placeholder="user@example.com"
            required
            onChange={this.update('username')}
          />
        </label>

        <button
          type="button"
          className="btn__size-dynamic btn__color-blue"
          onClick={this.handleSubmit}
        >
          Create User
        </button>
      </div>
    );
  }

  handleCloseModal() {
    window.$(':text').val('');
    document.getElementById('_account_type').value = 'Standard GH Admin';
    window.$('.email-errors').html('');
    this.setState({
      firstName: '',
      lastName: '',
      username: '',
      errorText: '',
    });
  }

  render() {
    const content = this.content();
    return (
      <div className="modal__container-outer create-gh-admin">
        <div className="modal__container-inner align-top">
          <div className="modal__content">
            <div>
              <i className="fa fa-close modal-close" onClick={this.handleCloseModal} />
              <div className="header">
                Create GH Admin
              </div>
              <form id="content">
                {content}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminCreateGHAdmin.propTypes = IPropTypes;
export { AdminCreateGHAdmin };
