import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import HttpService from '../../service/interceptor';
import * as actionTypes from './actionTypes';

export const getSitesData = params => (dispatch) => {
  const query = `?${typeof params === 'string' ? params : new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());
  return HttpService.get(`/api/v1/sites${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getSites(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.siteError(err));
      dispatch(closeSpinner());
    });
};
export const addSitesData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.post('/api/v1/sites', { ...params })
    .then(({ data }) => {
      dispatch(actionTypes.saveSite(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.siteError(err));
      dispatch(closeSpinner());
    });
};
export const editSitesData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.put('/api/v1/sites', { ...params })
    .then(({ data }) => {
      dispatch(actionTypes.updatSite(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.siteError(err));
      dispatch(closeSpinner());
    });
};
export const deleteSitesData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.delete(`/api/v1/sites/${params.id}`)
    .then(({ data }) => {
      dispatch(actionTypes.deleteSite(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.siteError(err));
      dispatch(closeSpinner());
    });
};

export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePageSite(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeSite(params));
};
