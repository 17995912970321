import { ActivityLogsSerializer } from './activity-logs';

export class ReleaseToPatientSerializer {
  static deserialize = info => (
    info && {
      activityLogs: info.activity_logs.map(ActivityLogsSerializer.deserialize),
      message: info.message,
      patientEmail: info.patient_email,
      patientEmailReadOnly: info.patient_email_read_only,
      patientId: info.patient_id,
      requestId: info.request_id,
      patientName: info.patient_name,
      releasedReportsIds: info.released_reports_ids,
      reportToRelease: {
        latestReportDate: info.report_to_release.latest_report_date,
      },
    }
  );
}
