import React from 'react';
import PropTypes from 'prop-types';

import { LunarPatientReportSummary } from './lunar_patient_summary/LunarPatientReportSummary';
import { reportSummaryHelperFactory } from './lunar_patient_summary/ReportSummaryHelper';
import { LunarPatientDropdown } from './lunar_patient_download/LunarPatientDropdown';
import { LunarPatientHeader } from './lunar_header';
import { LunarPatientDownload } from './lunar_patient_download/LunarPatientDownload';
import { PatientTestsDropdownContainer } from '../patient-page/patient-tests-dropdown-container/patient-tests-dropdown-container';

import styles from './LunarPatientPageContainer.module.scss';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canDownloadReport: PropTypes.bool.isRequired,
  downloadReportEnabled: PropTypes.bool.isRequired,
  canShareReport: PropTypes.bool.isRequired,
  shareReportEnabled: PropTypes.bool.isRequired,
  canReleaseReport: PropTypes.bool.isRequired,
  releaseReportEnabled: PropTypes.bool.isRequired,
  testsDropdown: PropTypes.shape({
    selectedDropdownName: PropTypes.string.isRequired,
    errorHandlingSupport: PropTypes.bool.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })),
    options: PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      redirectTo: PropTypes.string.isRequired,
    })).isRequired,
  }),
};
const defaultProps = {
  testsDropdown: null,
};

const LunarPatientPageContainer = ({
  canDownloadReport, downloadReportEnabled, canShareReport, shareReportEnabled,
  canReleaseReport, releaseReportEnabled, authenticityToken, testsDropdown,
}) => (
  <div>
    <LunarPatientHeader
      canShareReport={canShareReport}
      shareReportEnabled={shareReportEnabled}
      canReleaseReport={canReleaseReport}
      releaseReportEnabled={releaseReportEnabled}
      authenticityToken={authenticityToken}
    />
    {testsDropdown ? (
      <PatientTestsDropdownContainer
        data={testsDropdown}
        canDownloadReport={canDownloadReport}
        downloadReportEnabled={downloadReportEnabled}
      />
    ) : (
      <div className={styles.reportsRow}>
        <LunarPatientDropdown
          patientLunarRequests={window.gon.patientLunarRequests}
          selectedRequestId={reportSummaryHelperFactory().requestId()}
        />
        <LunarPatientDownload
          canDownloadReport={canDownloadReport}
          downloadReportEnabled={downloadReportEnabled
            && !reportSummaryHelperFactory().isRequestInProgress()}
        />
      </div>
    )}
    <div className={styles.LunarPatientPageContainer}>
      <LunarPatientReportSummary />
    </div>
  </div>
);

LunarPatientPageContainer.propTypes = IPropTypes;
LunarPatientPageContainer.defaultProps = defaultProps;

export { LunarPatientPageContainer };
