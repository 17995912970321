import React from 'react';
import {
  FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';

const IPropTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  showHelperText: PropTypes.string,
  none: PropTypes.any,
};
const defaultProps = {
  name: '',
  label: '',
  value: '',
  error: null,
  showHelperText: undefined,
  none: null,
  onChange: () => {},
  options: [],
  defaultValue: '',
};
const Select = (props) => {
  const {
    name, label, value, error = null, onChange, options, defaultValue, showHelperText, ...other
  } = props;
  return (
    <FormControl fullWidth variant="outlined" {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        {...props}
        {...other}
      >
        {props.none && <MenuItem value="">None</MenuItem>}
        {options.map(item => (item.uuid ? (
          <MenuItem key={item.id} value={item.id || item.uuid}>
            {item.title || item.name}
          </MenuItem>
        ) : (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        )))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}

      {!error && showHelperText && <FormHelperText>{showHelperText}</FormHelperText>}
    </FormControl>
  );
};
export default Select;
Select.propTypes = IPropTypes;
Select.defaultProps = defaultProps;
