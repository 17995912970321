import React from 'react';
import PropTypes from 'prop-types';

import { PatientPageController } from '../../../networking/controllers/patient-page';
import { logger } from '../../../util/logger';
import styles from './PatientPageContainer.module.scss';
import { BiomarkersContainer } from '../biomarkers-container';
import { NavigationRow } from '../navigation-row';
import { ReferencesContainer } from '../references-container';
import { AlterationsContainer } from '../alterations-container';
import { PatientPageHeader } from '../patient-page-header';
import { PatientPageAlert } from '../patient-page-alert';
import { Alert } from '../alert/alert';
import { ImmunohistochemistryTable } from '../immunohistochemistry-table';
import { InterpretationAndMethodology } from '../interpretation-and-methodology';
import { Comment } from '../comment';
import { StatusMessageContainer } from '../status-message-container';
import { PatientSummaryContainer } from '../patient-summary-container';
import { PatientTestsDropdownContainer } from '../patient-tests-dropdown-container';
import { NotificationModal } from '../notification-modal';
import { ReferenceRange } from '../reference-range';
import { ParamsHelper } from '../../../helpers/params-helper';
import { GeneralMessage } from '../general-message';

const IPropTypes = {
  requestId: PropTypes.string.isRequired,
  patientRecordId: PropTypes.string.isRequired,
  savePatientPageData: PropTypes.func.isRequired,
  showDisclaimer: PropTypes.bool,
  type: PropTypes.string,
};

const defaultProps = {
  showDisclaimer: false,
  type: null,
};

class PatientPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showAlert: false,
      errorTitle: '',
      errorMessages: [],
      showError: false,
    };
  }

  async componentDidMount() {
    const {
      requestId,
      savePatientPageData,
      patientRecordId,
      type,
    } = this.props;
    window.showSpinner();

    const queryParams = ParamsHelper.parseQueryParams(window.location.search);
    const cacheReset = queryParams.cache_reset;
    try {
      const patientPageData = await PatientPageController.getPatientInformation(
        requestId,
        patientRecordId,
        type,
        cacheReset,
      );
      savePatientPageData(patientPageData);

      this.setState({
        showAlert: patientPageData.alert.showAlert,
      });
    } catch (error) {
      logger.warn(error);
    } finally {
      this.setState({ isLoading: false });
      window.hideSpinner();
    }
  }

  toggleErrorMessage = (title, messages) => {
    this.setState(prevState => ({
      showError: !prevState.showError,
      errorTitle: title,
      errorMessages: messages,
    }));
  }

  renderSalesUserAlert = () => (
    <Alert type="warning">
      Do not take screenshots of, pictures of, or print any element of this page.
    </Alert>
  );

  render() {
    const {
      isLoading,
      showAlert,
      showError,
      errorTitle,
      errorMessages,
    } = this.state;
    const { showDisclaimer } = this.props;
    const warningText = (
      <div>
        <p>
          {errorMessages[0]}
        </p>
        <p>
          {errorMessages[1]}
        </p>
      </div>
    );

    return (
      !isLoading
      && (
        <div className={styles.patientPageContainer}>
          { showDisclaimer && this.renderSalesUserAlert() }
          <PatientPageHeader />
          <PatientTestsDropdownContainer showErrorMessage={this.toggleErrorMessage} />
          { showError && (
            <NotificationModal
              errorTitle={errorTitle}
              content={warningText}
              onConfirmCallback={() => this.toggleErrorMessage('', '')}
              toggleConfirmationModal={() => this.toggleErrorMessage('', '')}
              centeredButtonText="Close"
            />
          )}
          { showAlert && <PatientPageAlert onClose={() => this.setState({ showAlert: false })} /> }
          <PatientSummaryContainer />
          <GeneralMessage />
          <ImmunohistochemistryTable />
          <ReferenceRange />
          <AlterationsContainer />
          <StatusMessageContainer />
          <NavigationRow />
          <BiomarkersContainer />
          <Comment />
          <InterpretationAndMethodology />
          <ReferencesContainer />
        </div>
      )
    );
  }
}

PatientPageContainer.propTypes = IPropTypes;
PatientPageContainer.defaultProps = defaultProps;

export { PatientPageContainer };
