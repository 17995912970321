import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styles from './modal.module.scss';

const IPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  className: PropTypes.string,
  startClose: PropTypes.bool,
  onClose: PropTypes.func,
};

const defaultProps = {
  className: '',
  startClose: false,
  onClose: () => {},
};

const FADE_SECONDS = 0.3;
const INTERVAL_TIME = 1000 / 60;

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: 0,
    };
    this.intervalId = null;

    this.clearInterval = this.clearInterval.bind(this);
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    const portalElement = document.createElement('div');
    document.body.appendChild(portalElement);
    this.setState({
      portalElement,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.portalElement && !prevState.portalElement) {
      this.startFadeIn();
    }
    if (!prevProps.startClose && this.props.startClose) {
      this.startFadeOut();
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
    this.clearInterval();
    if (this.state.portalElement) {
      this.state.portalElement.remove();
    }
  }

  clearInterval() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  startFadeIn() {
    this.clearInterval();
    this.intervalId = window.setInterval(() => {
      this.setState((prevState) => {
        const newOpacity = prevState.opacity + INTERVAL_TIME / (FADE_SECONDS * 1000);
        return {
          opacity: Math.min(newOpacity, 1),
        };
      }, () => {
        if (this.state.opacity === 1) {
          this.clearInterval();
        }
      });
    }, INTERVAL_TIME);
  }

  startFadeOut() {
    this.clearInterval();
    this.intervalId = window.setInterval(() => {
      this.setState((prevState) => {
        const newOpacity = prevState.opacity - INTERVAL_TIME / (FADE_SECONDS * 1000);
        return {
          opacity: Math.max(newOpacity, 0),
        };
      }, () => {
        if (this.state.opacity === 0) {
          this.clearInterval();
          this.props.onClose();
        }
      });
    }, INTERVAL_TIME);
  }

  render() {
    const toRender = (
      <div
        className={[styles.container, this.props.className].join(' ')}
        style={{ opacity: this.state.opacity }}
      >
        {this.props.children}
      </div>
    );
    if (this.state.portalElement) {
      return ReactDOM.createPortal(toRender, this.state.portalElement);
    }
    return null;
  }
}

Modal.propTypes = IPropTypes;
Modal.defaultProps = defaultProps;

export { Modal };
