import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import * as actionTypes from './actionsTypes';
import HttpService from '../../service/interceptor';

export const getAccountsData = (params, reload) => (dispatch) => {
  const query = `?${typeof params === 'string' ? params : new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());
  return HttpService.get(`/api/v1/accounts${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getAccounts({ ...data, reload }));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.accountsError(err));
      dispatch(closeSpinner());
    });
};
export const addAccountsData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.post('/api/v1/accounts', { ...params })
    .then(({ data }) => {
      dispatch(actionTypes.saveAccount(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.accountsError(err));
      dispatch(closeSpinner());
    });
};
export const deleteAccountsData = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.delete(`/api/v1/accounts/${params.accountNumber}`)
    .then(({ data }) => {
      dispatch(actionTypes.deleteAccount(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.accountsError(err));
      dispatch(closeSpinner());
    });
};

export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePageAccount(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangeAccount(params));
};
