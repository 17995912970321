import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  currentGhiList: PropTypes.string.isRequired,
  allGhiNumbers: PropTypes.object.isRequired,
};

class GhiListAndPhysicians extends React.Component {
  static parseGhiList(currentGhiList) {
    const split = currentGhiList.split('; ');

    const index = split.indexOf(' ');
    split.splice(index, 1);

    const unique = split.filter((elem, idx, self) => idx === self.indexOf(elem));
    return unique;
  }

  constructor(props) {
    super(props);
    this.state = {
      currentGhiList: GhiListAndPhysicians.parseGhiList(this.props.currentGhiList),
      physicianCheckboxes: {},
      selectAll: {},
    };

    this.checkboxesRefs = {};
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.generatePhysicianList = this.generatePhysicianList.bind(this);
  }

  generateGhiPhysicianList() {
    return this.state.currentGhiList.map(this.generatePhysicianList);
  }

  generatePhysicianList(ghi) {
    const ghiList = this.props.allGhiNumbers;

    const upperCaseGHI = ghi.toUpperCase();

    const physicianList = ghiList[upperCaseGHI];

    const physiciansDivs = [];

    if (physicianList === undefined) {
      return null;
    }

    physiciansDivs.push(
      <div key={upperCaseGHI} className="ghi-list__practice_name">
        {upperCaseGHI}
        {' '}
        -
        {' '}
        {physicianList[0].PRACTICENAME}
      </div>,
    );
    if (this.state.selectAll[upperCaseGHI] === undefined) {
      this.state.selectAll[upperCaseGHI] = true;
    }
    physiciansDivs.push(
      <div key={`${upperCaseGHI}-select-all`}>
        <input
          onChange={this.toggleCheckboxList.bind(this)}
          id={upperCaseGHI}
          type="checkbox"
          className="practice-manager__checkbox"
          checked={this.state.selectAll[upperCaseGHI]}
        />
        Select All
      </div>,
    );

    this.checkboxesRefs[upperCaseGHI] = [];
    physicianList.forEach((physician) => {
      if (this.state.physicianCheckboxes[physician.GHPHYCLIENTID] === undefined) {
        this.state.physicianCheckboxes[physician.GHPHYCLIENTID] = true;
      }

      const checkboxRef = React.createRef();
      this.checkboxesRefs[upperCaseGHI].push(checkboxRef);

      physiciansDivs.push(
        <div key={physician.GHPHYCLIENTID}>
          <input
            type="checkbox"
            className={`practice-manager__checkbox ${upperCaseGHI}`}
            onChange={this.handleCheckboxClick}
            id={physician.GHPHYCLIENTID}
            checked={this.state.physicianCheckboxes[physician.GHPHYCLIENTID]}
            ref={checkboxRef}
          />
          {physician.LASTNAME}
          ,
          {physician.FIRSTNAME}
        </div>,
      );
    });
    return physiciansDivs;
  }

  toggleCheckboxList(e) {
    e.stopPropagation();
    const senderId = e.target.id;

    this.setState((prevState) => {
      const newState = {
        selectAll: prevState.selectAll,
        physicianCheckboxes: { ...prevState.physicianCheckboxes },
      };
      const newCheckboxValue = !prevState.selectAll[senderId];
      newState.selectAll[senderId] = newCheckboxValue;

      const checkboxes = this.checkboxesRefs[senderId];
      if (!checkboxes) {
        return newState;
      }

      checkboxes.forEach((element) => {
        const physician = element.current.id;
        newState.physicianCheckboxes[physician] = newCheckboxValue;
      });
      return newState;
    });
  }

  handleCheckboxClick(e) {
    const elementId = e.target.id;

    this.setState((previousState) => {
      const list = { ...previousState.physicianCheckboxes };
      list[elementId] = !list[elementId];

      return { physicianCheckboxes: list };
    });
  }

  render() {
    const ghiPhysicianList = this.generateGhiPhysicianList();

    return (
      <div className="ghi-phyisicans-list">
        <div className="physician-description">ALL PHYSICIANS AT SELECTED PRACTICES:</div>
        <div className="physician-list-errors" />
        {ghiPhysicianList}
      </div>
    );
  }
}

GhiListAndPhysicians.propTypes = IPropTypes;

export default GhiListAndPhysicians;
