import { useEffect, useState } from 'react';
import { MutationsController } from '../../../networking/controllers/mutations';
import { logger } from '../../../util/logger';

export const useDetails = (requestId, saveGeneDetails) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const makeRequest = async () => {
    try {
      setLoading(true);
      const data = await MutationsController.getGeneDetails(requestId);
      saveGeneDetails(data);
    } catch (e) {
      logger.warn(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    makeRequest();
  }, []);

  return {
    loading,
    error,
  };
};
