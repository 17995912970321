import React from 'react';
import PropTypes from 'prop-types';

import { shareReport } from '../../../../../util/ajax';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
  shareReportEnabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

class ShareForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareForm: {
        firstName: '',
        lastName: '',
        email: '',
      },
    };
  }

  inputChangeHandler = field => (event) => {
    const updatedShareForm = { ...this.state.shareForm };
    updatedShareForm[field] = event.target.value;
    this.setState(() => ({ shareForm: updatedShareForm }));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { patient, authenticityToken, onSubmit } = this.props;

    // TODO: Add more cocmplex validation on email field before yielding the submission.
    // eslint-disable-next-line no-alert
    if (!window.confirm(`Are you sure you want to share ${patient.patientLastName} ${patient.patientFirstName}'s current and historical reports with this recipient?`)) {
      return;
    }

    window.showSpinner();
    await shareReport(authenticityToken, this.shareParams());
    window.hideSpinner();
    onSubmit();
  }

  shareParams() {
    const { patient } = this.props;
    const { shareForm } = this.state;

    return {
      patient_id: patient.id,
      patient_name: `${patient.patientLastName} ${patient.patientFirstName}`,
      request_id: patient.requestId,
      share_modal: true,
      accept_terms: true,
      sharees: {
        sharee_1: {
          first_name: shareForm.firstName,
          last_name: shareForm.lastName,
          email: shareForm.email,
          notification: false,
        },
      },
    };
  }

  render() {
    const {
      shareForm,
      errorText, // TODO: i.e. for email validations
    } = this.state;
    const { patient, shareReportEnabled } = this.props;

    const buttonClasses = ['btn__size-dynamic btn__color-blue'];
    if (!shareReportEnabled) {
      buttonClasses.push('disabled');
    }

    return (
      <div className="content">
        <p className="description">{`Invite an additional recipient to view ${patient.patientLastName} ${patient.patientFirstName}'s patient report.`}</p>
        <div className="email-errors" id="email-errors">{errorText}</div>
        <form className="shares__form-container" onSubmit={this.handleSubmit}>
          <div className="grey-box__form__item-container">
            <div className="grey-box__form__item">
              <div className="grey-box__form__item-inner grey-box__form__item-inner-left">
                <div className="ghi-errors" />
                <label className="grey-box__form__item-label share__first__name" htmlFor="__first_name_">First Name</label>
                <input type="text" name="[first_name]" id="_first_name" className="grey-box__form__item grey-box__form__item-name" placeholder="e.g. Joe" required minLength="3" value={shareForm.firstName} onChange={this.inputChangeHandler('firstName')} />
              </div>

              <div className="grey-box__form__item-inner grey-box__form__item-inner-right">
                <div className="ghi-errors" />
                <label className="grey-box__form__item-label share__last__name" htmlFor="__last_name_">Last Name</label>
                <input type="text" name="[last_name]]" id="_last_name" className="grey-box__form__item grey-box__form__item-name" placeholder="e.g. Smith" required minLength="3" value={shareForm.lastName} onChange={this.inputChangeHandler('lastName')} />
              </div>
            </div>

            <div className="grey-box__form__item">
              <div className="ghi-errors" />
              <label className="grey-box__form__item-label share__email__address" htmlFor="__email_">Email Address</label>
              <input type="email" name="[email_address]]" id="_email_address" className="grey-box__form__item grey-box__form__item-email" placeholder="e.g. physician@example.com" required value={shareForm.email} onChange={this.inputChangeHandler('email')} />
            </div>

            <div className="grey-box__form__item-inner grey-box__form__item">
              <div className="input-group shares__terms">
                <label className="label-terms" htmlFor="accept_terms">
                  <input type="checkbox" name="accept_terms" id="accept_terms" required="required" onChange={this.handleCheckmarkChange} />
                  I confirm the individual(s) to whom I am sending this invitation is a health
                  professional or workforce member at my organization authorized to access and
                  use health information in accordance with my organization policies, HIPAA
                  , federal, state, and local requirements, and the service
                  <a target="_blank" href="/terms_of_use">Terms and Conditions of Use</a>
                  . Your invitee will be required to set up a Provider Portal account and to
                  read and accept the Terms and Conditions of Use before access to the Provider
                  Portal will be provided to him/her.
                </label>
              </div>

              <button type="submit" className={buttonClasses.join(' ')} disabled={!shareReportEnabled}>Send Invitation</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

ShareForm.propTypes = IPropTypes;

export { ShareForm };
