class TrialSite {
  constructor(params) {
    this.center = params.center;
    this.city = params.city;
    this.country = params.country;
    this.state = params.state;

    this.region = this.state || this.country;
  }
}

export { TrialSite };
