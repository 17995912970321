import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';
import { DiagnosisConditionLateStageExpanded } from '../diagnosis-condition-late-stage-expanded';
import { DiagnosisConditionLateStageCollapsed } from '../diagnosis-condition-late-stage-collapsed';
import { useIcdCodesFiltering } from '../../../../hooks/use-icd-codes-filtering';
import { constants } from '../../../../config/constants';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
  icdCodes: PropTypes.arrayOf(PropTypes.string),
  saveDiagnosisInfo: PropTypes.func.isRequired,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
  icdCodes: [],
};

const DiagnosisConditionLateStageContainer = ({
  enabled, onToggle, icdCodes, saveDiagnosisInfo,
}) => {
  useIcdCodesFiltering(
    icdCodes,
    constants.etrfOrdering.productFamilies.g360,
    saveDiagnosisInfo,
  );

  return (
    <div>
      <GenericHeader
        enabled={enabled}
        handleContainerArrowClick={onToggle}
        label="Diagnosis"
      />
      {
        enabled
          ? <DiagnosisConditionLateStageExpanded />
          : <DiagnosisConditionLateStageCollapsed onToggle={onToggle} />
      }
    </div>
  );
};

DiagnosisConditionLateStageContainer.propTypes = IPropTypes;
DiagnosisConditionLateStageContainer.defaultProps = defaultProps;

export { DiagnosisConditionLateStageContainer };
