import React from 'react';
import PropTypes from 'prop-types';

import styles from './view-associated-button.module.scss';

const IPropTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  alignDirection: PropTypes.string,
};

const defaultProps = {
  text: 'View associated testing results',
  alignDirection: 'left',
};

const ViewAssociatedButton = ({ to, text, alignDirection }) => (
  <a className={styles.link} href={to}>
    {alignDirection === 'left' && <i className={`fa fa-arrow-circle-left ${styles.right}`} />}
    {text}
    {alignDirection === 'right' && <i className={`fa fa-arrow-circle-right ${styles.left}`} />}
  </a>
);

ViewAssociatedButton.propTypes = IPropTypes;
ViewAssociatedButton.defaultProps = defaultProps;

export { ViewAssociatedButton };
