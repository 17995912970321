/* eslint-disable */

// this function accepts a callback function as params.callback that will be called with the command results
// if a callback is not provided it returns a promise that will resolve with the command results
function addCommandCallback(command, params, persistCallback) {
  if (params && params.callback) {
    // execute command with provided callback function
    addCommand(command, params, persistCallback);
  } else {
    // create a temporary function and return a promise that executes command
    var tempFunctionName =
      "_gonative_temp_" + Math.random().toString(36).slice(2);
    if (!params) params = {};
    return new Promise(function (resolve, reject) {
      // declare a temporary function
      params.callbackFunction = window[tempFunctionName] = function (data) {
        resolve(data);
        delete window[tempFunctionName];
      };
      // execute command
      addCommand(command, params);
    });
  }
}

function addCallbackFunction(callbackFunction, persistCallback) {
  var callbackName;
  if (typeof callbackFunction === "string") {
    callbackName = callbackFunction;
  } else {
    callbackName = "_gonative_temp_" + Math.random().toString(36).slice(2);
    window[callbackName] = function (...args) {
      callbackFunction.apply(null, args);
      if (!persistCallback) {
        // if callback is used just once
        delete window[callbackName];
      }
    };
  }
  return callbackName;
}

function addCommand(command, params, persistCallback) {
  var commandObject = undefined;
  if (params) {
    commandObject = {};
    if (params.callback && typeof params.callback === "function") {
      params.callback = addCallbackFunction(params.callback, persistCallback);
    }
    if (
      params.callbackFunction &&
      typeof params.callbackFunction === "function"
    ) {
      params.callbackFunction = addCallbackFunction(
        params.callbackFunction,
        persistCallback
      );
    }
    if (params.statuscallback && typeof params.statuscallback === "function") {
      params.statuscallback = addCallbackFunction(
        params.statuscallback,
        persistCallback
      );
    }
    commandObject.gonativeCommand = command;
    commandObject.data = params;
  } else commandObject = command;

  const paramsQS = new URLSearchParams(commandObject.data);
  window.location.href = `${
    commandObject.gonativeCommand
  }?${paramsQS.toString()}`;
}

///////////////////////////////
////    General Commands   ////
///////////////////////////////

var gonative = {};

gonative.available = function () {
  return navigator.userAgent.includes("gonative");
};

///////////////////////////////
////         Auth          ////
///////////////////////////////

gonative.auth = {};

gonative.auth.status = function (params) {
  return addCommandCallback("gonative://auth/status", params);
};

gonative.auth.save = function (params) {
  return addCommandCallback("gonative://auth/save", params);
};

gonative.auth.get = function (params) {
  return addCommandCallback("gonative://auth/get", params);
};

export default gonative;
