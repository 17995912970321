import React from 'react';
import PropTypes from 'prop-types';

import { PatientSummary } from '../../../models/patient-summary';

import styles from './patient-summary-section.module.scss';

const IPropTypes = {
  data: PropTypes.instanceOf(PatientSummary).isRequired,
  isAdminLogged: PropTypes.bool.isRequired,
};

const PatientSummarySection = ({ data, isAdminLogged }) => (
  <div className={[data.type === 'Open' ? styles.expandedContainer : styles.modalContainer, isAdminLogged ? styles.adminContainer : ''].join(' ')}>
    <div className={styles.firstColumn}>
      <div className={styles.row}>
        <span className={styles.label}>MRN</span>
        <p className={styles.content}>{data.mrn || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>DOB</span>
        <p className={styles.content}>{data.dob || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>GENDER</span>
        <p className={styles.content}>{data.gender || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>DIAGNOSIS</span>
        <p className={styles.content}>{data.diagnosis || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>TEST NUMBER</span>
        <p className={styles.content}>{data.testNumber || '-'}</p>
      </div>
      {
        data.surgeryDate
        && (
          <div className={styles.row}>
            <span className={styles.label}>SURGERY DATE</span>
            <p className={styles.content}>{data.surgeryDate}</p>
          </div>
        )
      }
      <div className={styles.row}>
        <span className={styles.label}>PATIENT PH</span>
        <p className={[styles.content, styles.desktopOnly].join(' ')}>{data.patientPhoneNumber}</p>
        <p className={[styles.content, styles.mobileOnly].join(' ')}><a href={`tel:${data.patientPhoneNumber}`}>{data.patientPhoneNumber}</a></p>
      </div>
    </div>
    <div className={styles.secondColumn}>
      <div className={styles.row}>
        <span className={styles.label}>ACCESSION ID</span>
        <p className={styles.content}>{data.accessionId || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>{data.reportDateLabel}</span>
        <p className={styles.content}>{data.reportDate || '-'}</p>
      </div>
      {
        data.revisionDate
        && (
          <div className={styles.row}>
            <span className={styles.label}>{data.revisionDateLabel}</span>
            <p className={styles.content}>{data.revisionDate}</p>
          </div>
        )
      }
      <div className={styles.row}>
        <span className={styles.label}>RECEIPT DATE</span>
        <p className={styles.content}>{data.receiptDate || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>COLLECTION DATE</span>
        <p className={styles.content}>{data.collectionDate || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>SPECIMEN</span>
        <p className={styles.content}>{data.specimen || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>STATUS</span>
        <p className={styles.content}>{data.status || '-'}</p>
      </div>
      {
        data.type === 'Open' && data.baselineTimepoint
        && (
        <div className={styles.row}>
          <span className={styles.label}>BASELINE TIMEPOINT</span>
          <p className={styles.content}>{data.baselineTimepoint || '-'}</p>
        </div>
        )
      }
    </div>
    <div className={styles.thirdColumn}>
      <div className={styles.row}>
        <span className={styles.label}>PHYSICIAN</span>
        <p className={styles.content}>{data.physicianName || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>ACCOUNT</span>
        <p className={styles.content}>{data.practiceName || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>ADDRESS</span>
        <p className={styles.content}>{data.practiceFullAddress || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>PH</span>
        <p className={styles.content}>{data.practicePhoneNumber || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>FAX</span>
        <p className={styles.content}>{data.physicianFax || '-'}</p>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>ADDITIONAL RECIPIENT</span>
        <p className={styles.content}>{data.aditionalRecipient || '-'}</p>
      </div>
    </div>

    { data.showTestResult
      && (
        <div className={styles.testResultContainer}>
          <div className={styles.firstColumn}>
            <div className={styles.row}>
              <span className={styles.label}>TEST RESULTS</span>
              <hr />
              <p className={styles.content}>{(data.testResult && data.testResult.testName) || '-' }</p>
            </div>
          </div>
          <div className={styles.secondColumn}>
            <div className={styles.row}>
              <span className={styles.label}>STATUS</span>
              <hr />
              <p className={styles.content}>{(data.testResult && data.testResult.testResult) || '-'}</p>
            </div>
          </div>
        </div>
      )
    }
  </div>
);

PatientSummarySection.propTypes = IPropTypes;

export { PatientSummarySection };
