import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  callback: PropTypes.func,
};

const defaultProps = {
  callback: () => {},
};

class AdminManagePatientsSearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '', minChars: 1, users: [], selected: false, loading: false,
    };
    this.handleSelectUser = this.handleSelectUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  updateUsers(users) {
    this.setState({ users, loading: false });
  }

  needToLoad() {
    return (this.state.searchString.length > this.state.minChars);
  }

  resetUsers() {
    this.updateUsers([]);
  }

  triggerChange(e) {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    this.setState({
      searchString: e.target.value,
      loading: true,
      timer: setTimeout(this.handleChange, 500),
    });
  }

  handleChange() {
    const searchString = this.state.searchString.trim().toLowerCase();
    if (searchString && this.needToLoad() === true) {
      window.getManagePatientsDropdown({ searchString }, this.updateUsers.bind(this));
    } else if (!searchString) {
      this.resetUsers();
    }
  }

  handleSelectUser(user) {
    this.setState({
      searchString: user.name,
      selected: true,
    }, () => {
      this.props.callback(user, true);
    });
  }

  dropdown(users) {
    return users.map(user => (
      <div className="dropdown-row" key={user.id} onClick={() => this.handleSelectUser(user)}>
        <div className="fa fa-user dropdown-row-icon" />
        <div className="dropdown-row-text">
          <span className="dropdown-row-text__top-name">{user.name}</span>
          <span className="dropdown-row-text__top-status">{user.status === 'ACTIVATED' ? '' : (` (${user.status})`)}</span>
          <div className="dropdown-row-text__bottom">{user.email}</div>
        </div>
      </div>
    ));
  }

  filterUsers() {
    const { users } = this.state;
    const dropdown = this.dropdown(users);

    if (!this.state.loading && !this.state.selected && users.length > 0) {
      return (
        <div className="dropdown-anchor">
          <div className="dropdown-container">
            <div className="dropdown-rows">
              {dropdown}
            </div>
          </div>
        </div>
      );
    }
    return (<div className="dropdown-anchor" />);
  }

  render() {
    return (
      <div className="">
        <div className="search-container">
          <div className="search-icon fa fa-search" />
          <input className="search-input" type="text" value={this.state.searchString} onChange={this.triggerChange.bind(this)} placeholder="Search patient name, patient email..." />
        </div>
        {this.filterUsers()}
      </div>
    );
  }
}

AdminManagePatientsSearchPage.propTypes = IPropTypes;
AdminManagePatientsSearchPage.defaultProps = defaultProps;

export default AdminManagePatientsSearchPage;
