export const schema = {
  patient: {
    firstName: ['schema', 'order', 'patient', 'demographic', 'firstName'],
    lastName: ['schema', 'order', 'patient', 'demographic', 'lastName'],
    dob: ['schema', 'order', 'patient', 'demographic', 'dob'],
    phone: ['schema', 'order', 'patient', 'demographic', 'phone'],
    email: ['schema', 'order', 'patient', 'demographic', 'email'],
    address1: [
      'schema',
      'order',
      'patient',
      'demographic',
      'address',
      'address1',
    ],
    address2: [
      'schema',
      'order',
      'patient',
      'demographic',
      'address',
      'address2',
    ],
    city: ['schema', 'order', 'patient', 'demographic', 'address', 'city'],
    state: ['schema', 'order', 'patient', 'demographic', 'address', 'state'],
    country: ['schema', 'order', 'patient', 'demographic', 'address', 'country'],
    zipCode: [
      'schema',
      'order',
      'patient',
      'demographic',
      'address',
      'zipCode',
    ],
    hospitalizationStatus: [
      'schema',
      'order',
      'patient',
      'hospitalizationStatus',
    ],
    sex: ['schema', 'order', 'patient', 'demographic', 'sex'],
    type: ['schema', 'order', 'patient', 'type'],
    identifiers: ['schema', 'order', 'patient', 'identifiers'],
  },
  diagnosis: {
    disease: ['schema', 'order', 'diagnosis', 'disease'],
    primaryIcdCode: ['schema', 'order', 'diagnosis', 'primaryIcdCode'],
    secondaryIcdCodes: ['schema', 'order', 'diagnosis', 'secondaryIcdCodes'],
    stage: ['schema', 'order', 'diagnosis', 'stage'],
    dateOfOriginalDiagnosis: [
      'schema',
      'order',
      'diagnosis',
      'dateOfOriginalDiagnosis',
    ],
    Other: ['Other'],
    diagnosisOther: ['schema', 'order', 'diagnosis', 'diagnosisOther'],
    noAdjuvantTherapy: ['schema', 'order', 'diagnosis', 'noAdjuvantTherapy']
  },
  testSelection: {
    pathologyLabName: ['schema', 'order', 'specimens', 'tissueSpecimen', 'pathologyLabName'],
    collectionDate: ['schema', 'order', 'specimens', 'bloodSpecimen', 'collectionDate'],
    clinicianName: ['schema', 'order', 'specimens', 'bloodSpecimen', 'clinicianName'],
    phone: ['schema', 'order', 'specimens', 'tissueSpecimen', 'phone'],
    fax: ['schema', 'order', 'specimens', 'tissueSpecimen', 'fax'],
    tissueCollectionDate: ['schema', 'order', 'specimens', 'tissueSpecimen', 'tissueCollectionDate'],
    productFamily: ['schema', 'order', 'productFamily'],
    testOrdered: ['schema', 'order', 'testOrdered', 'name'],
    reorderType: ['schema', 'order', 'testOrdered', 'reorderType'],
    reorderTypeId: ['schema', 'order', 'testOrdered', 'reorderType', 'id'],
    interval: ['schema', 'order', 'testOrdered', 'reorderType', 'interval'],
    intervalId: ['schema', 'order', 'testOrdered', 'reorderType', 'interval', 'id'],
    intervalValue: ['schema', 'order', 'testOrdered', 'reorderType', 'interval', 'value'],
    preferenceForBloodDraw: ['schema', 'order', 'testOrdered', 'preferenceForBloodDraw'],
    followOnTestOrdered: ['schema', 'order', 'testOrdered', 'followOnTestOrdered'],
  },
  clinicalHistory: {
    specificTherapy: ['schema', 'order', 'advancedCancerStage', 'specificTherapy'],
    dateOfTherapyInitiation: ['schema', 'order', 'advancedCancerStage', 'dateOfTherapyInitiation'],
    advancedCancer: ['schema', 'order', 'advancedCancerStage', 'advancedCancer'],
    targetedTherapy: ['schema', 'order', 'advancedCancerStage', 'targetedTherapy'],
    chemotherapy: ['schema', 'order', 'advancedCancerStage', 'chemotherapy'],
    combinationTherapy: ['schema', 'order', 'advancedCancerStage', 'combinationTherapy'],
    immunotherapy: ['schema', 'order', 'advancedCancerStage', 'immunotherapy'],
    dateOfSurgicalResection: ['schema', 'order', 'testOrdered', 'dateOfSurgicalResection'],
    dateOfAdjuvantChemotherapy: ['schema', 'order', 'diagnosis', 'dateOfAdjuvantChemotherapy'],
    molecularMarker: ['schema', 'order', 'diagnosis', 'molecularMarker'],
    relevantClinicalHistory: ['schema', 'order', 'relevantClinicalHistory'],
  },
  physician: {
    authorizingProvider: ['schema', 'order', 'authorizingProvider'],
    client: ['schema', 'order', 'client'],
    firstName: ['schema', 'order', 'secondaryRecipient', 'firstName'],
    phone: ['schema', 'order', 'secondaryRecipient', 'phone'],
    fax: ['schema', 'order', 'secondaryRecipient', 'fax'],
  },
  billing: {
    insuredRelationship: ['schema', 'order', 'billing', 'primaryInsurance', 'insuredRelationship'],
    billingIndicator: ['schema', 'order', 'billing', 'primaryInsurance', 'billingIndicator'],
    company: ['schema', 'order', 'billing', 'primaryInsurance', 'company'],
    subscriberFirstName: ['schema', 'order', 'billing', 'primaryInsurance', 'subscriberFirstName'],
    subscriberLastName: ['schema', 'order', 'billing', 'primaryInsurance', 'subscriberLastName'],
    policyNumber: ['schema', 'order', 'billing', 'primaryInsurance', 'policyNumber'],
    groupNumber: ['schema', 'order', 'billing', 'primaryInsurance', 'groupNumber'],
    dob: ['schema', 'order', 'billing', 'primaryInsurance', 'dob'],
    cardIncluded: ['schema', 'order', 'billing', 'cardIncluded'],
  },
};
