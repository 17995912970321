
export const globalActionTypes = {
  UPDATE_INITIAL_PROPS: 'UPDATE_INITIAL_PROPS',
  ADD_BANNER_DATA: 'ADD_BANNER_DATA',
  RESET_BANNER: 'RESET_BANNER',
  INCREMENT_PROCESSES_LOADING: 'INCREMENT_PROCESSES_LOADING',
};

export const updateInitialProps = initialProps => ({
  type: globalActionTypes.UPDATE_INITIAL_PROPS,
  initialProps,
});

export const addBannerMessages = ({ messages, status }) => ({
  type: globalActionTypes.ADD_BANNER_DATA,
  messages,
  status,
});

export const resetBanner = () => ({
  type: globalActionTypes.RESET_BANNER,
});

export const updateLoadingCount = increment => (dispatch, getState) => {
  dispatch({
    type: globalActionTypes.INCREMENT_PROCESSES_LOADING,
    increment,
  });

  const { global: { processesLoading } } = getState();

  if (processesLoading) {
    window.showSpinner();
  } else {
    window.hideSpinner();
  }
};
