import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionTypes';

export const DEFAULT_SITES_STATE = fromJS({
  sitesList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },
  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
  isOpen: false,
});

const SitesReducer = handleActions(
  {
    [actionTypes.GET_SITE]: (state, action) => state
      .set('reload', false)
      .set('sitesList', action.payload.data.sites || action.payload.data)
      .set(
        'totalRecords',
        action.payload.data.totalRecords === 0 ? 0
          : action.payload.data.totalRecords || action.payload.data.length,
      )
      .setIn(
        ['pagination', 'pageNo'],
        action.payload.data.sites.length === 0 && state.getIn(['pagination', 'pageNo']) > 0
          ? state.getIn(['pagination', 'pageNo']) - 1
          : state.getIn(['pagination', 'pageNo']),
      ),
    [actionTypes.SAVE_SITE]: state => state
      .set('reload', true)
      .setIn(['notify'], { isOpen: true, message: 'Sites Added Successfully', type: 'success' })
      .set('openPopup', false),

    [actionTypes.UPDATE_SITE]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Sites Saved Successfully', type: 'success' }),

    [actionTypes.DELETE_SITE]: state => state
      .set('reload', true)
      .setIn(['notify'], fromJS({ isOpen: true, message: 'Sites Deleted Successfully', type: 'success' })),

    [actionTypes.SITE_ERROR]: (state, action) => state.setIn(['notify'], fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.SITE_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_SITE]: (state, action) => state
      .set(action.payload.key, action.payload.value),
  },
  DEFAULT_SITES_STATE,
);
export default SitesReducer;
