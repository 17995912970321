import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changePagination,
  onChange,
  getProjectsAssignmentList,
  editProjectAssignment,
  getProjectsList,
} from './actions/ProjectAssignmentActions';
import ProjectAssignmentView from './ProjectAssignmentView';

const mapStateToProps = state => ({
  projectAssignment: state.projectAssignment,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePagination,
      onChange,
      getProjectsAssignmentList,
      editProjectAssignment,
      getProjectsList,
    },
    dispatch,
  ),
});
// eslint-disable-next-line max-len
export const ConnectedSiteAssignmentView = connect(mapStateToProps, mapDispatchToProps)(ProjectAssignmentView);
export default ConnectedSiteAssignmentView;
