import { constants } from '../../../../config/constants';
import { OnlineOrderingFieldsLimits } from '../../../../config/input-character-limit';

const billingActionTypes = {
  ADD_BILLING_DATA: 'ADD_BILLING_DATA',
};

const saveBillingInfo = (info, hideBanner) => ({
  type: billingActionTypes.ADD_BILLING_DATA,
  info,
  hideBanner,
});

const saveSelfInsuredBillingInfo = () => async (dispatch, getState) => {
  const { orders: { activeOrder: { patientInfo } } } = getState();
  const {
    billingInsuredFirstNameMaxLength: firstNameLimit,
    billingInsuredLastNameMaxLength: lastNameLimit,
  } = OnlineOrderingFieldsLimits;

  dispatch({
    type: billingActionTypes.ADD_BILLING_DATA,
    info: {
      insuredRelationship: constants.billingInformation.selfInsuredValue,
      subscriberFirstName: patientInfo.patientFirstName.substring(0, firstNameLimit),
      subscriberLastName: patientInfo.patientLastName.substring(0, lastNameLimit),
      dob: patientInfo.patientBirthDate,
    },
    hideBanner: true,
  });
};

export { saveBillingInfo, saveSelfInsuredBillingInfo, billingActionTypes };
