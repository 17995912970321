import { DownloadReportsLinksSerializer } from './download-reports-links';
import { ReleaseToPatientSerializer } from './release-to-patient';
import { SharePatientSerializer } from './share-patient';

export class ActionBarSerializer {
  static deserialize = actionBar => (
    actionBar && {
      canOrderProgressionTest: actionBar.can_order_progression_test,
      downloadReportsLinks: DownloadReportsLinksSerializer.deserialize(
        actionBar.download_reports_links,
      ),
      releaseToPatient: ReleaseToPatientSerializer.deserialize(actionBar.release_to_patient),
      sharePatient: SharePatientSerializer.deserialize(actionBar.share_patient),
    }
  );
}
