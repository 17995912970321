import { connect } from 'react-redux';

import { NavigationRow } from './navigation-row';

const mapStateToProps = store => ({
  associatedTest: store.patientPage.multiProduct.info.associatedTest,
  collectionDate: (store.patientPage.multiProduct.info.patientSummary || {}).collectionDate,
});

const ConnectedNavigationRow = connect(
  mapStateToProps,
)(NavigationRow);

export { ConnectedNavigationRow as NavigationRow };
