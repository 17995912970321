class SecondaryRecipientInfo {
  constructor(params) {
    this.firstname = params.secondaryRecipientFirstname;
    this.lastname = params.secondaryRecipientLastname;
    this.phone = params.secondaryRecipientPhone;
    this.fax = params.secondaryRecipientFax;
  }
}

export { SecondaryRecipientInfo };
