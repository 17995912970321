import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Shares from './Shares';
import { loadSharesDataList } from './actions/SharesActions';
import {
  getPhysicianAdminList,
  getPhysiciansList,
  getSharesList,
  isSharesLoading,
} from './selectors';
import {
  loadPhysicianAdminList,
  loadPhysiciansList,
  downloadContractReport,
  eSignContractReport,
} from '../addEditOrder/actions/NewOrderAction';

const mapStateToProps = (state, ownProps) => {
  const { authenticityToken, accessClass, viewAsUser } = ownProps;
  return {
    shares: getSharesList(state),
    isLoading: isSharesLoading(state),
    authenticityToken,
    accessClass,
    physicians: getPhysiciansList(state),
    physicinAdmins: getPhysicianAdminList(state),
    viewAsUser,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loadSharesDataList,
      downloadContractReport,
      eSignContractReport,
      loadPhysicianAdminList,
      loadPhysiciansList,
    },
    dispatch,
  ),
});

const ConnectedSharesContainer = connect(mapStateToProps, mapDispatchToProps)(Shares);
export default ConnectedSharesContainer;
