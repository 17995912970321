import { connect } from 'react-redux';

import { BiomarkersContainer } from './biomarkers-container';

const mapStateToProps = store => ({
  data: store.patientPage.multiProduct.info.biomarkersTable,
  dictionary: (store.patientPage.multiProduct.info.dictionary || {}).biomarkers,
});

const ConnectedBiomarkersContainer = connect(
  mapStateToProps,
)(BiomarkersContainer);

export { ConnectedBiomarkersContainer as BiomarkersContainer };
