import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActionBarContainer } from './action-bar-container';
import { addBannerMessages } from '../../../store/actions/global';

const mapStateToProps = store => ({
  loggedUser: store.patientPage.multiProduct.info.loggedUser,
  viewedAsUser: store.patientPage.multiProduct.info.viewedAsUser,
  data: store.patientPage.multiProduct.info.actionBar,
});

const mapDispatchToProps = dispatch => bindActionCreators({ addBannerMessages }, dispatch);

const ConnectedActionBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActionBarContainer);

export { ConnectedActionBarContainer as ActionBarContainer };
