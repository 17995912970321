import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';
import { clinicalHistoryActionTypes } from '../../../actions/orders/active-order/clinical_history_info';

const clinicalHistoryInitialState = {
  clinicalHistoryCgpFeasible: null,
  clinicalHistoryTreatment: null,
  clinicalHistoryReport: null,
  clinicalHistoryCgpResult: null,
  clinicalHistoryCgpCompletion: null,
};

const clinicalHistoryInfo = (state = clinicalHistoryInitialState, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return { ...state, ...action.order.clinicalHistoryInfo };
    case clinicalHistoryActionTypes.ADD_CLINICAL_HISTORY_DATA:
      return { ...state, ...action.info };
    default:
      return state;
  }
};

export default clinicalHistoryInfo;
