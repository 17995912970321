import { showSpinner, closeSpinner } from '../../common/utils/spinner/actions';
import HttpService from '../../service/interceptor';
import * as actionTypes from './actionTypes';

export const getPatientsData = params => (dispatch) => {
  const query = `?${typeof params === 'string' ? params : new URLSearchParams(params).toString()}`;
  dispatch(showSpinner());
  return HttpService.get(`/api/v1/patients${query}`)
    .then(({ data }) => {
      dispatch(actionTypes.getPatients(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.patientsError(err));
      dispatch(closeSpinner());
    });
};


export const deletePatient = params => (dispatch) => {
  dispatch(showSpinner());
  return HttpService.delete(`/api/v1/patients/${params.id}`)
    .then(({ data }) => {
      dispatch(actionTypes.deletePatient(data));
      dispatch(closeSpinner());
    })
    .catch((err) => {
      dispatch(actionTypes.deletePatientError(err));
      dispatch(closeSpinner());
    });
};

export const changePagination = params => (dispatch) => {
  dispatch(actionTypes.changePagePatient(params));
};
export const onChange = params => (dispatch) => {
  dispatch(actionTypes.onChangePatients(params));
};
