import React, { useEffect, useState } from 'react';

function DisclaimerModal() {
  const acceptedDisclaimer = localStorage.getItem('accepted_disclaimer');
  const [show, setShow] = useState(true);
  const handleClick = () => {
    // eslint-disable-next-line no-use-before-define
    // window.acceptDisclaimer(HttpService, callBack);
    localStorage.setItem('accepted_disclaimer', 'true');
    setShow(false);
  };
  useEffect(() => {
    if (acceptedDisclaimer === 'true') {
      setShow(false);
    }
  }, [acceptedDisclaimer]);
  return (
    show && (
      <div className="modal__container-outer disclaimer__modal show_table">
        <div className="modal__container-inner">
          <div className="modal__content">
            <div className="disclaimer__form box-shadow">
              <div className="top">
                <div className="title">User Agreement</div>
              </div>
              <div className="content">
                <div className="bold">
                  By clicking on ‘I Agree’ below, I confirm that I have read,
                  understand, and agree to the following terms:
                </div>
                <br />
                <ol>
                  <li>I will only access the portal using company equipment</li>
                  <li>
                    I will
                    {' '}
                    <b>not</b>
                    {' '}
                    provide my password to any other person or
                    use any other person&apos;s username and password
                  </li>
                  <li>
                    I will
                    {' '}
                    <b>not</b>
                    {' '}
                    show or discuss test results outside of
                    the ordering physician&apos;s office
                  </li>
                  <li>
                    I will
                    {' '}
                    <b>not</b>
                    {' '}
                    attempt to capture computer images
                    (pictures, screenshots, etc.) of any elements of the portal
                  </li>
                  <li>
                    I will
                    {' '}
                    <b>not</b>
                    {' '}
                    attempt to print a report
                  </li>
                </ol>
              </div>
              <div className="divider-line" />
              <div className="bottom">
                <div
                  className="button btn__color-blue accept__disclaimer"
                  onClick={handleClick}
                >
                  I AGREE
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default DisclaimerModal;
