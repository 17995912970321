import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addGenericInfo, clearActiveOrder } from '../../store/actions/orders/active-order/generic-info';
import { receiveOrdersAmounts } from '../../store/actions/orders/dashboard';
import { clearRedirectAfterErrorRoute, clearError, receiveError } from '../../store/actions/errors';
import { updateModalInfo } from '../../store/actions/orders/active-order/print-modal';

import { OrdersDashboard } from './orders-dashboard';
import { withSignatureChecking } from '../../hocs';

const mapStateToProps = ({
  orders: { activeOrder, dashboard },
  errors: { messages },
  global: { initialProps: { userPermissions } },
}) => ({
  activeOrder,
  ordersAmounts: dashboard.ordersAmounts,
  errors: messages,
  onlyNewOrders: !userPermissions || userPermissions.onlyNewOrders,
  withIncompleteSignatureMessage: (
    !userPermissions || userPermissions.withIncompleteSignatureMessage
  ),
});

const clearsAfterRedirection = () => (dispatch) => {
  dispatch(updateModalInfo({ showModal: false }));
  dispatch(addGenericInfo({ redirectContinueLater: false }));
  dispatch(clearRedirectAfterErrorRoute());
};

const mapDispatchToProps = dispatch => bindActionCreators({
  saveOrdersAmounts: receiveOrdersAmounts,
  clearErrorMessage: clearError,
  clearsAfterRedirection,
  clearActiveOrder,
  receiveError,
}, dispatch);

const ConnectedOrdersDashboard = withSignatureChecking(
  connect(mapStateToProps, mapDispatchToProps)(OrdersDashboard),
);

export { ConnectedOrdersDashboard as OrdersDashboard };
