import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './LunarActionBar.module.scss';
import { LunarShareModal } from './lunar_share_modal';
import { ShareForm } from './lunar_share_modal/ShareForm';
import { LunarReleaseModal } from './lunar_release_modal';
import { ReleaseForm } from './lunar_release_modal/ReleaseForm';
import { reportSummaryHelperFactory } from '../../lunar_patient_summary/ReportSummaryHelper';


const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canShareReport: PropTypes.bool.isRequired,
  shareReportEnabled: PropTypes.bool.isRequired,
  canReleaseReport: PropTypes.bool.isRequired,
  releaseReportEnabled: PropTypes.bool.isRequired,
  showShareModal: PropTypes.bool.isRequired,
  showReleaseModal: PropTypes.bool.isRequired,
  toggleShareModal: PropTypes.func.isRequired,
  toggleShareMessage: PropTypes.func.isRequired,
  toggleReleaseModal: PropTypes.func.isRequired,
  toggleReleaseMessage: PropTypes.func.isRequired,
};


class LunarActionBar extends React.Component {
  _toggleShareModal = () => {
    const { toggleShareModal } = this.props;
    toggleShareModal();
  }

  _showShareMessage = () => {
    const { toggleShareMessage } = this.props;
    toggleShareMessage();
  }

  _onShareSubmit = () => {
    this._toggleShareModal();
    this._showShareMessage();
  }

  _toggleReleaseModal = () => {
    const { toggleReleaseModal } = this.props;
    toggleReleaseModal();
  }

  _showReleaseMessage = () => {
    const { toggleReleaseMessage } = this.props;
    toggleReleaseMessage();
  }

  _onReleaseSubmit = () => {
    this._toggleReleaseModal();
    this._showReleaseMessage();
  }

  render() {
    const {
      canShareReport, shareReportEnabled, canReleaseReport, releaseReportEnabled,
      showShareModal, showReleaseModal, authenticityToken,
    } = this.props;
    const { patientInformation, requestsToRelease } = window.gon;
    const isReqInProgress = reportSummaryHelperFactory().isRequestInProgress();
    const disabledClass = isReqInProgress ? 'disabled ' : '';
    return (
      <Fragment>
        <div className={styles.actionBar}>
          {
            canShareReport
            && (
              <button type="button" className={`${styles.actionBarButton} ${disabledClass}`} onClick={this._toggleShareModal}>
                <div className="fa fa-share-square-o">&nbsp;</div>
              </button>
            )
          }
          {
            canReleaseReport
            && (
              <button type="button" className={`${styles.actionBarButton} ${disabledClass}`} onClick={this._toggleReleaseModal}>
                <div className="fa fa-paper-plane">&nbsp;</div>
              </button>
            )
          }
        </div>
        {
          showShareModal
          && !isReqInProgress
          && (
            <LunarShareModal
              onClose={this._toggleShareModal}
              content={(
                <ShareForm
                  patient={patientInformation}
                  authenticityToken={authenticityToken}
                  shareReportEnabled={shareReportEnabled}
                  onSubmit={this._onShareSubmit}
                />
              )}
              patient={patientInformation}
            />
          )
        }
        {
          showReleaseModal
          && !isReqInProgress
          && (
            <LunarReleaseModal
              onClose={this._toggleReleaseModal}
              content={(
                <ReleaseForm
                  patient={patientInformation}
                  reportsToRelease={requestsToRelease}
                  authenticityToken={authenticityToken}
                  releaseReportEnabled={releaseReportEnabled}
                  onSubmit={this._onReleaseSubmit}
                />
              )}
            />
          )
        }
      </Fragment>
    );
  }
}

LunarActionBar.propTypes = IPropTypes;

export { LunarActionBar };
