import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { logger } from '../../util/logger';
import { constants } from '../../config/constants';
import { ParamsHelper } from '../../helpers/params-helper';
import { OrdersController } from '../../networking/controllers/orders';
import { orderStatuses } from '../../models/order/order';
import { useSignatureIncompleteAnnouncement } from '../../hooks/use-signature-incomplete-announcement';

import { Bubbles } from '../../components/generic';
import BannerMessage from '../../components/orders/trf_button/BannerMessage';
import { NewTestsButtonsContainer, OrdersListContainer } from '../../components/orders/dashboard';
import { PrintCompletedOrderModalContainer } from '../../components/orders/print-completed-order-modal-container';

import styles from './orders-dashboard.module.scss';

const IPropTypes = {
  clearActiveOrder: PropTypes.func.isRequired,
  saveOrdersAmounts: PropTypes.func.isRequired,
  activeOrder: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  clearsAfterRedirection: PropTypes.func.isRequired,
  receiveError: PropTypes.func.isRequired,
  onlyNewOrders: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  ordersAmounts: PropTypes.array,
  success: PropTypes.string,
  updateLoadingCount: PropTypes.func.isRequired,
  withIncompleteSignatureMessage: PropTypes.bool,
};

const defaultProps = {
  success: null,
  ordersAmounts: null,
  withIncompleteSignatureMessage: false,
};

const OrdersDashboard = ({
  activeOrder,
  ordersAmounts,
  onlyNewOrders,
  errors,
  saveOrdersAmounts,
  clearActiveOrder,
  clearErrorMessage,
  clearsAfterRedirection,
  receiveError,
  history,
  location,
  success,
  updateLoadingCount,
  withIncompleteSignatureMessage,
}) => {
  const [successMessage, setSuccessMessage] = useState(success);
  const [showErrorMessage, setShowErrorMessage] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [,
    signatureNotFinalizedMessage,
    closeSignatureNotFinalizedMessage,
    startSignatureNotFinalizedAutoDismiss,
  ] = useSignatureIncompleteAnnouncement(withIncompleteSignatureMessage);

  const queryParams = ParamsHelper.parseQueryParams(location.search);

  useEffect(() => {
    clearsAfterRedirection();
    if (queryParams.event) {
      history.replace('/orders');
    }

    return () => { clearErrorMessage(); };
  }, []);

  useEffect(() => {
    (async () => {
      if (onlyNewOrders) {
        setIsLoading(false);
        return;
      }

      updateLoadingCount(1);

      try {
        if (!ordersAmounts) {
          const data = await OrdersController.getOrdersAmounts();
          saveOrdersAmounts(data);
        }
      } catch (error) {
        logger.warn(error);
        receiveError([constants.etrfOrdering.errorEncounteredMessage]);
      } finally {
        updateLoadingCount(-1);
        setIsLoading(false);

        if (activeOrder.genericInfo.trfCompleted) {
          setSuccessMessage(activeOrder.genericInfo.successMessage);
          clearActiveOrder();
        }
      }
    })();

    return () => { clearErrorMessage(); };
  }, [ordersAmounts]);

  const toggleSuccessMessage = useCallback(() => {
    setSuccessMessage(null);
  }, []);

  const toggleErrorMessage = useCallback(() => {
    if (signatureNotFinalizedMessage) {
      closeSignatureNotFinalizedMessage();
    } else {
      setShowErrorMessage(false);
      clearErrorMessage();
    }
  }, [clearErrorMessage, signatureNotFinalizedMessage]);

  const pendingSignOrdersAmount = !!ordersAmounts
    && (ordersAmounts.find(({ status: st }) => st === orderStatuses.pendingSignature) || {}).count;

  const incompleteOrdersAmount = !!ordersAmounts
    && (ordersAmounts.find(({ status: st }) => st === orderStatuses.incomplete) || {}).count;
  const disableNewTest = incompleteOrdersAmount >= constants.etrfOrdering.maxPendingOrders;

  const successMessageBanner = !!successMessage && (
    <BannerMessage
      status="success"
      toggleBanner={toggleSuccessMessage}
      messages={[successMessage]}
    />
  );

  const errorMessageBanner = (
    signatureNotFinalizedMessage || (showErrorMessage && errors.length > 0)
  ) && (
    <BannerMessage
      status="error"
      toggleBanner={toggleErrorMessage}
      messages={[signatureNotFinalizedMessage] || errors}
    />
  );

  const status = queryParams.status
    || (pendingSignOrdersAmount > 0 ? orderStatuses.pendingSignature : orderStatuses.incomplete);

  return (
    !!ordersAmounts
      && (
        <div className={styles.container}>
          <PrintCompletedOrderModalContainer />
          {successMessageBanner}
          {errorMessageBanner}
          <NewTestsButtonsContainer disable={disableNewTest} />
          {
            !onlyNewOrders && (
              <>
                { !isLoading && ordersAmounts && (
                  <Bubbles
                    statuses={ordersAmounts}
                    selected={status}
                  />
                )}
                <div className={styles.section}>
                  <OrdersListContainer
                    status={status}
                    startMessageAutoDismiss={startSignatureNotFinalizedAutoDismiss}
                  />
                </div>
              </>
            )
          }
        </div>
      )
  );
};

OrdersDashboard.propTypes = IPropTypes;
OrdersDashboard.defaultProps = defaultProps;

export { OrdersDashboard };
