import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';
import { DiagnosisConditionEarlyStageCollapsed } from '../diagnosis-condition-early-stage-collapsed';
import { DiagnosisConditionEarlyStageExpanded } from '../diagnosis-condition-early-stage-expanded';
import { constants } from '../../../../config/constants';
import { useIcdCodesFiltering } from '../../../../hooks/use-icd-codes-filtering';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
  icdCodes: PropTypes.arrayOf(PropTypes.string),
  saveEarlyStageDiagnosisInfo: PropTypes.func.isRequired,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
  icdCodes: [],
};

const DiagnosisConditionEarlyStageContainer = ({
  enabled, onToggle, icdCodes, saveEarlyStageDiagnosisInfo,
}) => {
  useIcdCodesFiltering(
    icdCodes,
    constants.etrfOrdering.productFamilies.reveal,
    saveEarlyStageDiagnosisInfo,
  );

  return (
    <div>
      <GenericHeader
        enabled={enabled}
        handleContainerArrowClick={onToggle}
        label="Diagnosis"
      />
      {
        enabled
          ? <DiagnosisConditionEarlyStageExpanded />
          : <DiagnosisConditionEarlyStageCollapsed onToggle={onToggle} />
      }
    </div>
  );
};

DiagnosisConditionEarlyStageContainer.propTypes = IPropTypes;
DiagnosisConditionEarlyStageContainer.defaultProps = defaultProps;

export { DiagnosisConditionEarlyStageContainer };
