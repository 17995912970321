const getQuestionValue = (questions, questionNumber) => (
  (questions.find(q => q.questionNumber === questionNumber) || {}).answerValue
);

class LateStageClinicalHistorySerializer {
  static deserialize = (clinicalHistory) => {
    if (!clinicalHistory) {
      return {};
    }

    return {
      clinicalHistoryTreatment: getQuestionValue(clinicalHistory, 1),
      clinicalHistoryReport: getQuestionValue(clinicalHistory, 2),
      clinicalHistoryCgpFeasible: getQuestionValue(clinicalHistory, 3),
      clinicalHistoryCgpCompletion: getQuestionValue(clinicalHistory, 4),
      clinicalHistoryCgpResult: getQuestionValue(clinicalHistory, 5),
    };
  }

  static serialize = clinicalHistoryInfo => ([
    'clinicalHistoryTreatment',
    'clinicalHistoryReport',
    'clinicalHistoryCgpFeasible',
    'clinicalHistoryCgpCompletion',
    'clinicalHistoryCgpResult',
  ].map((key, index) => ({
    answerValue: clinicalHistoryInfo[key],
    questionNumber: index + 1,
  })));
}

export { LateStageClinicalHistorySerializer };
