import React from 'react';
import PropTypes from 'prop-types';
import { SlideDown } from 'react-slidedown';

import { PendingOrderItem } from '../pending-order-item';
import { CompletedOrderItem } from '../completed-order-item';
import { Order } from '../../../../models/order/order';

import styles from './dashboard-list.module.scss';

const IPropTypes = {
  showAllDashboardListItems: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.instanceOf(Order)).isRequired,
  initialDashboardListItems: PropTypes.number.isRequired,
  loadAdditionalDashboardListItems: PropTypes.func,
  handleCollapseClick: PropTypes.func,
  handleContainerArrowClick: PropTypes.func,
  toggled: PropTypes.bool.isRequired,
  disabledForSales: PropTypes.bool,
};

const defaultProps = {
  loadAdditionalDashboardListItems: () => {},
  handleCollapseClick: () => {},
  handleContainerArrowClick: () => {},
  disabledForSales: false,
};

class DashboardList extends React.Component {
  constructor(props) {
    super(props);

    this.checkLoadMore = this.checkLoadMore.bind(this);
    this.checkCollapse = this.checkCollapse.bind(this);
  }

  checkLoadMore() {
    const {
      showAllDashboardListItems,
      list,
      initialDashboardListItems,
      loadAdditionalDashboardListItems,
    } = this.props;
    const dashboardListItemsCount = showAllDashboardListItems
      ? list.length
      : initialDashboardListItems;
    return (
      <div>
        {list.length > dashboardListItemsCount ? (
          <div
            className="dashboard__load_more_collapse"
            onClick={loadAdditionalDashboardListItems}
          >
            LOAD MORE
          </div>
        ) : null}
      </div>
    );
  }

  checkCollapse() {
    const {
      showAllDashboardListItems,
      list,
      initialDashboardListItems,
      handleCollapseClick,
    } = this.props;

    if (showAllDashboardListItems && list.length > initialDashboardListItems) {
      return (
        <div
          className="dashboard__load_more_collapse"
          onClick={handleCollapseClick}
        >
          COLLAPSE
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      list,
      handleContainerArrowClick,
      initialDashboardListItems,
      showAllDashboardListItems,
      toggled,
      disabledForSales,
    } = this.props;

    const dashboardListItemsLimit = showAllDashboardListItems
      ? list.length
      : initialDashboardListItems;

    const dashboardListItems = list.slice(0, dashboardListItemsLimit).map((order) => {
      const RowComponent = order.isIncomplete()
        ? PendingOrderItem : CompletedOrderItem;

      return (
        <RowComponent
          key={order.id}
          order={order}
          disabled={disabledForSales}
        />
      );
    });

    const printedOrdersListStyle = ['requests-list', disabledForSales ? styles.disabled : ''].join(' ');

    return (
      <div>
        {
          toggled
            ? (
              <div className="requests-list-container expanded">
                <SlideDown>
                  <div className={printedOrdersListStyle}>
                    {dashboardListItems}
                  </div>
                  {this.checkLoadMore()}
                  {this.checkCollapse()}
                </SlideDown>
              </div>
            )
            : (
              <div className="requests-list requests-list--collapsed--reactified">
                <div className="request requests-list--collapsed__request">
                  <div className="request requests-list--collapsed__request">
                    <div
                      className="request requests-list--collapsed__request"
                      onClick={() => handleContainerArrowClick('down')}
                    >
                      Show Reports
                    </div>
                  </div>
                </div>
              </div>
            )
        }
      </div>
    );
  }
}

DashboardList.propTypes = IPropTypes;
DashboardList.defaultProps = defaultProps;

export { DashboardList };
