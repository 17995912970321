import {
  TOGGLE_SHARE_MODAL,
  TOGGLE_SHARE_MESSAGE,
  HIDE_FORM_VALIDATION_MESSAGE,
  SHOW_FORM_VALIDATION_MESSAGE,
} from '../../../../actions/patient-page/lunar/share_modal';

const initialState = {
  showShareModal: false,
  showShareMessage: false,
  showFormValidationMessage: false,
};

const shareModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SHARE_MODAL:
      return {
        ...state,
        showShareModal: !state.showShareModal,
      };
    case TOGGLE_SHARE_MESSAGE:
      return {
        ...state,
        showShareMessage: !state.showShareMessage,
      };
    case HIDE_FORM_VALIDATION_MESSAGE:
      return {
        ...state,
        showFormValidationMessage: false,
      };
    case SHOW_FORM_VALIDATION_MESSAGE:
      return {
        ...state,
        showFormValidationMessage: true,
      };
    default:
      return state;
  }
};

export { shareModalReducer };
