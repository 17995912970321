import React from 'react';
import PropTypes from 'prop-types';
import Search from '../../orders/Search';
import styles from './StudiesSearch.module.scss';

const IPropTypes = {
  searchResults: PropTypes.array.isRequired,
  studyId: PropTypes.string.isRequired,
};

const defaultProps = {};

const containsTerm = (searchTerms, lookedTerm) => searchTerms.some(
  ({ term }) => term && term.toLowerCase().indexOf(lookedTerm.toLowerCase()) >= 0,
);

// Regex Scenarios:
// 1. "ASD123, QWE456 (2001-01-01)*MRN: ZXC789 IDs: A24696 A05684" => "MRN: ZXC789 "
// 2. "ASD123, QWE456 (2001-01-01)*MRN: ZXC789"                    => "MRN: ZXC789"
// 3. "ASD123, QWE456 (2001-01-01)*MRN: ZXC789 asd"                => "MRN: ZXC789 asd"
// 4. "ASD123, QWE456 (2001-01-01)*OH: ZXC789 asd"                 => "OH: ZXC789 asd"
// 5. "ASD123, QWE456 (2001-01-01)MRN: ZXC789 IDs: A24696 A05684"  => null
const extractMRN = (label) => {
  const matchMrnWithUndefined = label.match(/\*(.*)(IDs:)|\*(.*)/);
  // 1. => ["*MRN: ZXC789 IDs:", "MRN: ZXC789 ", "IDs:", undefined]
  // 2. => ["*MRN: ZXC789", undefined, undefined, "MRN: ZXC789"]
  const matchMrn = matchMrnWithUndefined && matchMrnWithUndefined.filter(Boolean);
  // 1. => ["*MRN: ZXC789 IDs:", "MRN: ZXC789 ", "IDs:"]
  // 2. => ["*MRN: ZXC789", "MRN: ZXC789"]
  return matchMrn && matchMrn[1];
};

// Regex Scenarios:
// 1. "ASD123, QWE456 (2001-01-01)*MRN: ZXC789 IDs: A24696 A05684" => "ASD123, QWE456 (2001-01-01)"
// 2. "ASD123, QWE456 (2001-01-01)"                                => "ASD123, QWE456 (2001-01-01)"
const extractName = (label) => {
  const matchUntilAsteriskIfAny = label.match(/(.*)\*|(.*)/);
  return matchUntilAsteriskIfAny && matchUntilAsteriskIfAny[1];
};

const StudiesSearch = (props) => {
  const { searchResults, studyId } = props;
  const fetchSearch = async keyword => searchResults.reduce((itemsFound, entity) => {
    if (containsTerm(entity.search_terms, keyword)) {
      itemsFound.push({
        id: entity.value,
        title: extractName(entity.label),
        subtitle: extractMRN(entity.label),
      });
    }
    return itemsFound;
  }, []);
  // Receives same { id, title, subtitle } object
  const navigateToSubjectPage = (selectedItem) => {
    const subjectId = selectedItem.id;
    if (studyId && subjectId) {
      window.location = `/studies/${studyId}/subjects/${subjectId}`;
    }
  };
  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchInput}>
        <Search
          iconContainerStyles={styles.iconContainer}
          searchWrapperStyles={styles.searchWrapper}
          dropdownItemStyles={styles.dropdownItem}
          dropdownTitleStyles={styles.dropdownTitle}
          dropdownListStyles={styles.dropdownList}
          inputPlaceholder="Search by subject or A#"
          minLengthSearch={3}
          onOptionsFetch={e => fetchSearch(e, searchResults)}
          storeSelectedItem={navigateToSubjectPage}
          avoidCheckingInputMatches
        />
      </div>
    </div>
  );
};


StudiesSearch.propTypes = IPropTypes;
StudiesSearch.defaultProps = defaultProps;

export { StudiesSearch };
