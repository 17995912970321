import React from 'react';
import PropTypes from 'prop-types';

import { SecondaryCodes } from '../secondary-codes';

const IIcdCode = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

const IPropTypes = {
  storeSelectedItem: PropTypes.func.isRequired,
  codes: PropTypes.shape({
    selectedSecondaryCode: IIcdCode.isRequired,
    selectedTertiaryCode: IIcdCode.isRequired,
    selectedQuarternaryCode: IIcdCode.isRequired,
    selectedQuinaryCode: IIcdCode.isRequired,
    selectedSenaryCode: IIcdCode.isRequired,
  }).isRequired,
};


const SecondaryCodeContainer = ({ storeSelectedItem, codes }) => {
  const codesElements = Object.entries(codes).map(([key, code]) => (
    <SecondaryCodes
      title="ICD.10"
      secondaryCode={code}
      selectedCodes={codes}
      storeSelectedItem={storeSelectedItem(key.replace('selected', 'diagnosis'))}
      key={key}
    />
  ));

  return (
    <div className="diagnosis-container flex-container__etrf-row secondary-codes-conatiner">
      {codesElements}
    </div>
  );
};

SecondaryCodeContainer.propTypes = IPropTypes;

export { SecondaryCodeContainer };
