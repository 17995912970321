import React from 'react';
import PropTypes from 'prop-types';
import { SlideDown } from 'react-slidedown';

import { Request } from './request';
import { fetchInitialRequestData, fetchLoadMoreRequestData } from '../util/ajax';

const IPropTypes = {
  requestLimit: PropTypes.number.isRequired,
  requestIds: PropTypes.array.isRequired,
  physician: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    lastName: PropTypes.string.isRequired,
    practiceName: PropTypes.string.isRequired,
  }).isRequired,
  accessKlass: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  requestStatus: PropTypes.string.isRequired,
  onAffiliationRead: PropTypes.func.isRequired,
  tissueStandaloneRefactor: PropTypes.bool.isRequired,
};

class RequestList extends React.Component {
  static handleViewAllClick(e) {
    if (window.$(e.target).hasClass('disabled')) {
      e.preventDefault();
    }
  }

  static parsedRequestId(request) {
    return request.requestId.charAt(0).toLowerCase()
           + request.requestId.slice(1);
  }

  constructor(props) {
    super(props);

    const { requestLimit } = this.props;
    this.state = {
      collapsed: true,
      containerArrow: 'down',
      toggled: false,
      downloadTriggered: false,
      requestData: [],
      intialDataLoaded: false,
      loadMoreDataLoading: false,
      loadMorePreviouslyTriggered: false,
      loadMoreExhausted: false,
      loadMorePaginationSize: 5,
      currRequestIdx: requestLimit,
    };
    this.receiveLoadMore = this.receiveLoadMore.bind(this);
    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
    this.handleCollapseClick = this.handleCollapseClick.bind(this);
    this.handleContainerArrowClick = this.handleContainerArrowClick.bind(this);
    this.receiveInitialRequestData = this.receiveInitialRequestData.bind(this);
  }

  componentDidMount() {
    const { requestIds, requestLimit, tissueStandaloneRefactor } = this.props;

    const slicedRequestIds = requestIds.slice(0, requestLimit);

    const initialRequestIds = {};
    if (tissueStandaloneRefactor) {
      initialRequestIds.request_ids = slicedRequestIds.map(({ requestId }) => requestId).join(',');
    } else {
      slicedRequestIds.forEach(({ itemType, requestId }) => {
        if (!initialRequestIds[itemType]) initialRequestIds[itemType] = [];
        initialRequestIds[itemType].push(requestId);
      });
      Object.keys(initialRequestIds).forEach((key) => {
        initialRequestIds[key] = initialRequestIds[key].join(',');
      });
    }
    fetchInitialRequestData(initialRequestIds, this.receiveInitialRequestData);
  }

  componentDidUpdate(prevProps, prevState) {
    const { downloadTriggered } = this.state;

    if (downloadTriggered) {
      if (this.lastDownloaded(prevProps, prevState)) {
        this.props.onAffiliationRead();
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ downloadTriggered: false });
      }
    }
  }

  lastDownloaded = (props, state) => {
    const {
      loadMorePreviouslyTriggered,
      loadMoreExhausted,
      requestData,
    } = state;

    const {
      requestIds,
      requestLimit,
    } = props;

    return (requestIds.length === 1
      || (loadMorePreviouslyTriggered && loadMoreExhausted && requestData.length === 1)
      || (requestData.length === 1 && (requestIds.length <= requestLimit)));
  }

  receiveInitialRequestData(data) {
    this.setState({ requestData: data.requests, intialDataLoaded: true });
  }

  receiveLoadMore(data) {
    this.setState((prevState) => {
      const { requestData, currRequestIdx } = prevState;
      const { requestIds } = this.props;
      let existingState = requestData;
      existingState = existingState.concat(data.requests);

      return {
        requestData: existingState,
        loadMoreDataLoading: false,
        collapsed: false,
        loadMorePreviouslyTriggered: true,
        loadMoreExhausted: currRequestIdx >= requestIds.length,
      };
    });
  }

  handleMarkAsRead(reqId) {
    return () => {
      this.setState((prevState) => {
        const { requestData } = prevState;
        const newRequestData = requestData.filter(req => req.requestId !== reqId);
        return { requestData: newRequestData, downloadTriggered: true };
      });
    };
  }

  handleCollapseClick() {
    this.setState({ collapsed: true });
  }

  handleLoadMoreClick() {
    const { requestIds, tissueStandaloneRefactor } = this.props;
    const {
      collapsed,
      currRequestIdx,
      loadMoreExhausted,
      loadMorePaginationSize,
      loadMorePreviouslyTriggered,
    } = this.state;
    if (collapsed && loadMorePreviouslyTriggered) {
      this.setState({ collapsed: false });
      return;
    }
    if (loadMoreExhausted) return;

    const slicedRequestIds = requestIds.slice(
      currRequestIdx, currRequestIdx + loadMorePaginationSize,
    );

    const additionalRequestIds = {};

    if (tissueStandaloneRefactor) {
      additionalRequestIds.request_ids = slicedRequestIds.map(({ requestId }) => requestId).join(',');
    } else {
      slicedRequestIds.forEach(({ itemType, requestId }) => {
        if (!additionalRequestIds[itemType]) additionalRequestIds[itemType] = [];
        additionalRequestIds[itemType].push(requestId);
      });
      Object.keys(additionalRequestIds).forEach((key) => {
        additionalRequestIds[key] = additionalRequestIds[key].join(',');
      });
    }

    fetchLoadMoreRequestData(additionalRequestIds, this.receiveLoadMore);
    this.setState({
      loadMoreDataLoading: true,
      currRequestIdx: currRequestIdx + loadMorePaginationSize,
    });
  }

  handleContainerArrowClick() {
    const { containerArrow, toggled } = this.state;
    const newArrowDirection = containerArrow === 'down' ? 'right' : 'down';
    this.setState({ containerArrow: newArrowDirection, toggled: !toggled });
  }

  showHeaderLabel() {
    const { physician } = this.props;
    const { containerArrow } = this.state;
    const name = `${physician.lastName}, ${physician.firstName}`;
    const practiceName = `${physician.practiceName}`;

    return (
      <button
        type="button"
        onClick={this.handleContainerArrowClick}
        className="request-list-physician-button"
      >
        <div className={`fa fa-angle-${containerArrow}
                         expanded dashboard-angle-reactified`}
        />
        <div className="requests-list__header__label__name">
          {`${name} (${practiceName})`}
        </div>
      </button>
    );
  }

  showReports() {
    return (
      <div className="requests-list requests-list--collapsed--reactified">
        <div className="request requests-list--collapsed__request">
          <div className="request requests-list--collapsed__request">
            <button
              className="request requests-list--collapsed__request"
              onClick={this.handleContainerArrowClick}
              type="button"
            >
              Show Reports
            </button>
          </div>
        </div>
      </div>
    );
  }

  showLoadMoreCollapse() {
    const {
      requestIds,
      requestLimit,
      idx,
    } = this.props;
    const {
      loadMoreDataLoading,
      collapsed,
      loadMoreExhausted,
    } = this.state;

    if (requestIds.length > requestLimit) {
      if (loadMoreDataLoading) {
        return <div className="dashboard__load_more_collapse loading-spinner__btn" />;
      }
      if (collapsed) {
        return (
          <button
            type="button"
            className="dashboard__load_more_collapse"
            onClick={this.handleLoadMoreClick}
            id={`load-more-${idx}`}
          >
            LOAD MORE
          </button>
        );
      }
      if (loadMoreExhausted) {
        return (
          <button
            type="button"
            className="dashboard__load_more_collapse"
            onClick={this.handleCollapseClick}
            id={`load-more-${idx}`}
          >
            COLLAPSE
          </button>
        );
      }
      return (
        <div className="dashboard__load_more_container">
          <button
            type="button"
            className="dashboard__load_more_collapse-left"
            onClick={this.handleCollapseClick}
          >
            COLLAPSE
          </button>
          <button
            type="button"
            className="dashboard__load_more_collapse-right"
            onClick={this.handleLoadMoreClick}
            id={`load-more-${idx}`}
          >
            LOAD MORE
          </button>
        </div>
      );
    }
    return null;
  }

  viewAllReports() {
    const { accessKlass } = this.props;
    return (
      <div className="dashboard__tableview">
        <a
          className={`dashboard__tableview-link ${accessKlass}`}
          href="/requests"
          onClick={RequestList.handleViewAllClick}
        >
          <div className="fa fa-table dashboard-table-icon" />
          VIEW ALL REPORTS IN TABLE
        </a>
      </div>
    );
  }

  generateRequestList() {
    if (this.state.intialDataLoaded) {
      return (
        <div className="requests-list">
          { this.generateRequestsAboveLoadMore() }
          <SlideDown>
            { this.generateRequestsBelowLoadMore() }
          </SlideDown>
          { this.showLoadMoreCollapse() }
        </div>
      );
    }
    return (
      <div className="request-list-item">
        <div className="request">
          <div className="request__loading__block" />
        </div>
      </div>
    );
  }

  generateRequestsAboveLoadMore() {
    const {
      requestLimit,
      accessKlass,
      requestStatus,
      requestIds,
    } = this.props;
    const { requestData } = this.state;
    return (requestData.map((request, idx) => {
      if ((idx + 1) > requestLimit) return null;

      const requestId = RequestList.parsedRequestId(request);
      const { revisionIds = [] } = requestIds.find(
        patientRow => patientRow.requestId === request.requestId,
      ) || {};

      return (
        <Request
          key={requestId}
          request={request}
          accessKlass={accessKlass}
          requestStatus={requestStatus}
          revisionsMap={revisionIds}
          onRead={this.handleMarkAsRead(request.requestId)}
        />
      );
    }));
  }

  generateRequestsBelowLoadMore() {
    const { collapsed, requestData } = this.state;
    const {
      requestLimit,
      accessKlass,
      requestStatus,
      requestIds,
    } = this.props;
    if (collapsed) return null;

    return (requestData.map((request, idx) => {
      if ((idx + 1) > requestLimit) {
        const requestId = RequestList.parsedRequestId(request);

        const { revisionIds } = requestIds.find(
          patientRow => patientRow.requestId === request.requestId,
        );

        return (
          <Request
            key={requestId}
            request={request}
            accessKlass={accessKlass}
            requestStatus={requestStatus}
            revisionsMap={revisionIds}
            onRead={this.handleMarkAsRead(request.requestId)}
          />
        );
      }
      return null;
    }));
  }

  generateResult() {
    const { idx } = this.props;
    if (idx === 0) {
      return (
        <div className="h4 requests-list__header">
          <div className="requests-list__header__label">
            {this.showHeaderLabel()}
          </div>
          <div className="dashboard__tableview">
            {this.viewAllReports()}
          </div>
        </div>
      );
    }
    return (
      <div className="h4 requests-list__header">
        <div className="requests-list__header__label header__full">
          {this.showHeaderLabel()}
        </div>
      </div>
    );
  }

  render() {
    const { toggled } = this.state;
    return (
      <div className="requests-list-container expanded">
        <div className="h4 requests-list__header">{this.generateResult()}</div>
        { toggled
          ? <div>{this.showReports()}</div>
          : <div>{ this.generateRequestList() }</div>
        }
      </div>
    );
  }
}

RequestList.propTypes = IPropTypes;

export default RequestList;
