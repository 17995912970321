import React from 'react';
import PropTypes from 'prop-types';

const buttonStyle = {
  all: 'unset',
  display: 'flex',
  margin: '20px auto',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#1A5EA7',
};

const spanStyle = {
  fontWeight: 'bold',
};

function BiometryButton(props) {
  return (
    <button style={buttonStyle} type="button" onClick={props.onClick}>
      <BiometryIcon stroke="#1A5EA7" width="96" height="96" />
      <span style={spanStyle}>Login with biometrics</span>
    </button>
  );
}
BiometryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function BiometryIcon(props) {
  return (
    <svg stroke={props.stroke} width={props.width} height={props.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 3H5C3.89543 3 3 3.89543 3 5V7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 3H19C20.1046 3 21 3.89543 21 5V7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 8L16 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 8L8 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 16C9 16 10 17 12 17C14 17 15 16 15 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 8L12 13L11 13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 21H5C3.89543 21 3 20.1046 3 19V17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 21H19C20.1046 21 21 20.1046 21 19V17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
BiometryIcon.propTypes = {
  stroke: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export { BiometryButton };
