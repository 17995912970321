
class TestsDropdown {
  constructor(params) {
    this.downloadButtonName = params.downloadButtonName;
    this.selectedDropdownName = params.selectedDropdownName;
    this.errorHandlingSupport = params.errorHandlingSupport;
    this.links = params.links;
    this.options = params.options;
  }
}

export { TestsDropdown };
