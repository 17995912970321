import { LateStageDiagnosisInfo } from './late-stage-diagnosis-info';

class EarlyStageDiagnosisInfo extends LateStageDiagnosisInfo {
  constructor(params) {
    super(params);

    this.adjuvantChemotherapy = params.diagnosisAdjuvantChemotherapy;
  }
}

export { EarlyStageDiagnosisInfo };
