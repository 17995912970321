import React from 'react';
import { string } from 'prop-types';

import styles from './loading-dots.module.scss';

const LoadingDots = ({ className }) => (
  <div className={`${styles.spinner} ${className}`}>
    <div className={`${styles.dotColor} ${styles.bounce1}`} />
    <div className={`${styles.dotColor} ${styles.bounce2}`} />
    <div className={`${styles.dotColor} ${styles.bounce3}`} />
  </div>
);

LoadingDots.propTypes = {
  className: string,
};

LoadingDots.defaultProps = {
  className: '',
};

export { LoadingDots };
