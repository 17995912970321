import { connect } from 'react-redux';
import { CurrentTherapyInformationExpanded } from './current-therapy-information-expanded';

import { AddCurrentTherapyInfo } from '../../../../store/actions/orders/active-order/current-therapy-info';

const mapStateToProps = ({
  orders: { activeOrder: { currentTherapyInfo } },
}) => ({
  currentTherapyInfo,
});

const mapDispatchToProps = dispatch => ({
  saveCurrentTherapyInfo: (info, hideBanner) => dispatch(AddCurrentTherapyInfo(
    info, hideBanner,
  )),
});

const ConnectedCurrentTherapyInformationExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(CurrentTherapyInformationExpanded);

export { ConnectedCurrentTherapyInformationExpanded as CurrentTherapyInformationExpanded };
