import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Route, Redirect, Switch, BrowserRouter,
} from 'react-router-dom';

import { useDispatch } from 'react-redux';

import {
  OrdersDashboard,
  NewOrderContainer,
  EditOrderContainer,
  ProgressionOrderContainer,
} from '../../../pages';
import { updateInitialProps } from '../../../store/actions/global';
import { loadEmrData } from '../../../store/actions/orders/active-order/emr-data';
import { loadEmrAccounts, loadEmrProvider } from '../../../store/actions/orders/emr';

const IPropTypes = {
  viewAsUser: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  userPermissions: PropTypes.shape({
    canChangeOrders: PropTypes.bool.isRequired,
    canOnlyDemoOrders: PropTypes.bool.isRequired,
  }).isRequired,
  emrData: PropTypes.object,
};

const defaultProps = {
  emrData: null,
};

const EtrfOrders = ({
  viewAsUser, authenticityToken, userPermissions, emrData,
}) => {
  const dispatch = useDispatch();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    dispatch(updateInitialProps({ viewAsUser, authenticityToken, userPermissions }));
    if (emrData) {
      dispatch(loadEmrData(emrData.data));
      dispatch(loadEmrAccounts(emrData.accounts));
      dispatch(loadEmrProvider(emrData.provider));
    }
    setDisplay(true);
  }, []);

  return (
    display
    && (
      <BrowserRouter>
        <Switch>
          <Route exact path="/orders" component={OrdersDashboard} />
          <Route exact path="/orders/new/:productFamily" component={NewOrderContainer} />
          <Route exact path="/orders/:orderId" component={EditOrderContainer} />
          <Route exact path="/orders/requests/:requestId" component={ProgressionOrderContainer} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    ));
};

EtrfOrders.propTypes = IPropTypes;
EtrfOrders.defaultProps = defaultProps;

export { EtrfOrders };
