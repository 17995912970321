import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: '',
  label: '',
  labelClassName: '',
  error: null,
  inputRef: () => {},
  disabled: false,
};

const BasicInput = (props) => {
  const propsToPass = { ...props };
  delete propsToPass.className;
  delete propsToPass.id;
  delete propsToPass.ref;
  delete propsToPass.inputRef;
  delete propsToPass.labelClassName;
  return (
    <div className={props.className}>
      {
        !!props.label
        && (
        <label
          className={[
            'basic-input__label',
            props.labelClassName,
            props.disabled ? 'basic-input__label_disabled' : '',
          ].join(' ')}
          htmlFor={props.name}
        >
          {props.label}
        </label>
        )
      }
      <input
        className={[
          'basic-input__input',
          props.disabled ? 'inactive' : '',
        ].join(' ')}
        id={props.name}
        ref={props.inputRef}
        {...propsToPass}
      />
      {
        !!props.error
        && (
        <p className="basic-input__error">
          {props.error}
        </p>
        )
      }
    </div>
  );
};

BasicInput.propTypes = IPropTypes;
BasicInput.defaultProps = defaultProps;

export default BasicInput;
