class BiologicalGeneDetailSerializer {
  static deserialize(data) {
    return {
      alterationId: data.alteration_id,
      content: data.content,
      molecularFunction: data.molecular_function,
      incidence: data.incidence,
    };
  }
}

export { BiologicalGeneDetailSerializer };
