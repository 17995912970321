import { combineReducers } from 'redux';

import { activeOrderReducer } from './active-order';
import { ordersDashboardReducer } from './dashboard';
import { staticDataReducer } from './static-data';
import { emrReducer } from './emr';
import { electronicSignatureConfirmationReducer } from './electronic-signature-confirmation';

const ordersReducer = combineReducers({
  activeOrder: activeOrderReducer,
  dashboard: ordersDashboardReducer,
  staticData: staticDataReducer,
  emr: emrReducer,
  electronicSignatureConfirmation: electronicSignatureConfirmationReducer,
});

export { ordersReducer };
