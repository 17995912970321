import { Typography } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  getBillingInfoStatus, getClinicalHistory,
  getClinicalHistoryStatus,
  getDiagnosisStatus,
  getPatientInfoStatus,
  getPhysicianInfoStatus,
  getTestSelectionStatus
} from '../helpers';
import { getTests } from '../utils/helper';

const useStyles = makeStyles(theme => createStyles(
  {
    Complete: {
      color: theme.palette.success.main,
    },
    Incomplete: {
      color: theme.palette.warning.main,
    },
    Required: {
      color: theme.palette.error.main,
    },
  },
));

const TabDetails = (props) => {
  const {
    currentTab,
    index,
    tabStatus,
    contextualContent = '',
  } = props;
  const classes = useStyles();
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <div className={`iconCircle ${(tabStatus !== 'Requires Diagnosis' && tabStatus !== 'Test Selection Required') && tabStatus}`}>{index + 1}</div>
        <Typography
          component="span"
          variant="subtitle1"
          fontWeight="500"
          sx={{
            color: (tabStatus !== 'Not Started' && tabStatus !== 'current') && '#273644',
          }}
        >
          {currentTab.label}
        </Typography>
      </Box>
      <Typography
        component="p"
        variant="caption"
        sx={{
          my: '10px',
          lineHeight: '16px',
          minHeight: '16px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: (tabStatus !== 'Not Started' && tabStatus !== 'current') && '#273644',
        }}
      >
        {contextualContent}
      </Typography>
      <Typography component="div" variant="caption" className={classes[tabStatus]} sx={{ fontWeight: 800 }}>
        {tabStatus === 'Required' ? 'Required fields missing' : tabStatus}
      </Typography>
    </>
  );
};
TabDetails.propTypes = {
  currentTab: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  tabStatus: PropTypes.string.isRequired,
  contextualContent: PropTypes.string.isRequired,
};

const IPropTypes = {
  tab: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  panelDetails: PropTypes.object.isRequired,
  infoType: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  cancerTypeList: PropTypes.object.isRequired
};

const TabStatus = (props) => {
  const {
    tab: currentTab,
    order,
    panelDetails,
    infoType = '',
    errors,
    order: {
      patient,
      diagnosis,
      billing,
      productFamily = {},
      testOrdered = [],
      relevantClinicalHistory,
      authorizingProvider,
    },
    index,
    cancerTypeList
  } = props;
  const {disease} = diagnosis || {};
  const { firstName: patientFirstName = '', lastName: patientLastName = '' } = patient?.demographic || {};
  const testSelection = getTests([...testOrdered.name?.filter(x => x !== 'G360MR'),
    testOrdered.followOnTestOrdered]);
  const clinicalHistory = getClinicalHistory(relevantClinicalHistory, productFamily?.id);
  const { firstName: physicianFirstName = '', lastName: physicianLastName = '' } = authorizingProvider || {};
  const { billingIndicator = '' } = billing?.primaryInsurance || {};
  const patientStatus = getPatientInfoStatus(patient, panelDetails.tab, false, errors);
  const diagnosisStatus = getDiagnosisStatus(diagnosis, panelDetails.tab, false, cancerTypeList);
  const testSelectionStatus = getTestSelectionStatus(order, panelDetails.tab, false, cancerTypeList);
  const clinicalHistoryStatus = getClinicalHistoryStatus(order, panelDetails.tab, false, cancerTypeList);
  const physicianStatus = getPhysicianInfoStatus(order, panelDetails.tab, false);
  const billingStatus = getBillingInfoStatus(billing, infoType, panelDetails.tab, false);
  const trfDisplayName = cancerTypeList?.filter(i => i?.diagnosis?.toUpperCase() === disease?.toUpperCase())[0]?.trfDisplayName;
  return (
    <>
      {(() => {
        switch (currentTab.label) {
          case 'Patient Info':
            return (
              <TabDetails
                {...{ currentTab, index }}
                tabStatus={patientStatus}
                contextualContent={`${patientFirstName} ${patientLastName}`}
              />
            );
          case 'Diagnosis':
            return (
              <TabDetails
                {...{ currentTab, index }}
                tabStatus={diagnosisStatus}
                contextualContent={trfDisplayName}
              />
            );
          case 'Test Selection':
            return (
              <TabDetails
                {...{ currentTab, index }}
                tabStatus={testSelectionStatus}
                contextualContent={testSelection.split(',').length > 1 ? testSelection.split(',')[0].concat(', ...') : testSelection}
              />
            );
          case 'Clinical History':
            return (
              <TabDetails
                {...{ currentTab, index }}
                tabStatus={clinicalHistoryStatus}
                contextualContent={clinicalHistory}
              />
            );
          case 'Physician Info':
            return (
              <TabDetails
                {...{ currentTab, index }}
                tabStatus={physicianStatus}
                contextualContent={`${physicianFirstName} ${physicianLastName}`}
              />
            );
          case 'Billing Info':
            return (
              <TabDetails
                {...{ currentTab, index }}
                tabStatus={billingStatus}
                contextualContent={billingIndicator}
              />
            );
          default: return null;
        }
      })()}
    </>
  );
};

export default TabStatus;
TabStatus.propTypes = IPropTypes;
