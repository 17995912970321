import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BannerMessage } from './banner-message';
import { addBannerMessages, resetBanner } from '../../../../store/actions/global';

const mapStateToProps = ({
  global: { banner: { messages, status } },
}) => ({
  messages,
  status,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addBannerMessages,
  resetBanner,
}, dispatch);

const ConnectedBannerMessage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BannerMessage);

export { ConnectedBannerMessage as BannerMessage };
