import React from 'react';
import PropTypes from 'prop-types';

import dateFormat from 'dateformat';

const DAY_DIGITS = 2;
const MONTH_DIGITS = 2;
const YEAR_DIGITS = 4;
const MIN_ACCEPTED_DATE = '01/01/1900';
const MAX_ACCEPTED_DATE = '01/01/3000';
const REFS = ['month', 'day', 'year'];

const IPropTypes = {
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  highlightedBorder: PropTypes.bool,
};

const defaultProps = {
  value: '',
  className: '',
  maxDate: MAX_ACCEPTED_DATE,
  minDate: MIN_ACCEPTED_DATE,
  highlightedBorder: false,
};

class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validDate: true,
      day: '',
      month: '',
      year: '',
    };
    this.references = REFS.map(() => React.createRef());
    this._validateDate = this._validateDate.bind(this);
    this._checkDateDigits = this._checkDateDigits.bind(this);
    this._createAndCheckDate = this._createAndCheckDate.bind(this);
    this._deserializeDefaultDate = this._deserializeDefaultDate.bind(this);
    this._focusNextElement = this._focusNextElement.bind(this);
    this._getReference = this._getReference.bind(this);
    this.resetDate = this.resetDate.bind(this);
  }

  componentDidMount() {
    this._deserializeDefaultDate();
  }

  componentDidUpdate({ value: prevValue }) {
    const { value } = this.props;
    if (prevValue !== value) {
      if (value === null) {
        this.resetDate();
      } else {
        this._deserializeDefaultDate();
      }
    }
  }

  resetDate() {
    this.setState({ month: '', day: '', year: '' });
  }

  _getReference(dateElement) {
    const index = REFS.findIndex(e => e === dateElement);
    return this.references[index];
  }

  _focusNextElement(dateElement) {
    const indexTofocus = REFS.findIndex(e => e === dateElement) + 1;
    if (indexTofocus < this.references.length) {
      this.references[indexTofocus].current.focus();
    }
  }

  _deserializeDefaultDate() {
    const value = this.props.value || '';
    const fullDate = value.split('/');
    const splitted = fullDate || value.split('-');
    this.setState({
      month: splitted[0] || '',
      day: splitted[1] || '',
      year: splitted[2] || '',
    });
  }

  _checkDateDigits(event, dateElement, maxDigits) {
    const { value } = event.target;
    if (value.length === maxDigits) {
      this._focusNextElement(dateElement);
    }
    if (value.length <= maxDigits) {
      this.setState({
        [dateElement]: value,
      }, this._createAndCheckDate);
    }
  }

  _createAndCheckDate() {
    const { day, month, year } = this.state;
    const isCompletedDate = day && month && year;
    const unformattedDate = `${month}/${day}/${year}`;

    // validation is executed only if day, month, and year are not empty
    const valid = !isCompletedDate || this._validateDate(new Date(unformattedDate));

    this.props.onInputChange(isCompletedDate && valid ? dateFormat(unformattedDate, 'mm/dd/yyyy') : '');

    this.setState({ validDate: valid });
  }

  _validateDate(currentDate) {
    const { maxDate, minDate } = this.props;
    const lowerBound = new Date(minDate);
    const upperBound = new Date(maxDate);

    return !Number.isNaN(currentDate) && this._dateHasntAutoCorrected(currentDate)
      && currentDate <= upperBound && currentDate >= lowerBound;
  }

  /* This function was made for auto-correction purposes. When you create a new Date,
   if you enter a non-existing calendar number such as 50, it will return an invalid date.
   There is only one case which JS may 'auto-correct' and it is when you enter a calendar day but
   not valid for that month: eg. 30th february, it will auto correct to 1st march and it's NOT
   an invalid date. Also, if you enter a one digit year, it will autocorrect like: 2 -> 2002
   */
  _dateHasntAutoCorrected(currentDate) {
    const { month, year } = this.state;
    // This accepts months prepended with 0 (eg. 02).
    const normalizedMonth = parseInt(month, 10);
    // JS Date's month start in 0.
    const monthHasChanged = currentDate.getMonth() + 1 !== normalizedMonth;
    const yearHasChanged = currentDate.getFullYear() !== parseInt(year, 10);
    return !monthHasChanged && !yearHasChanged;
  }

  render() {
    const {
      className, value, highlightedBorder,
    } = this.props;
    const {
      validDate, day, month, year,
    } = this.state;

    const divClassNames = [
      className,
      !value ? 'inactive-section' : '',
      !validDate ? 'warning-content' : '',
      'date-container',
      highlightedBorder ? 'red-box' : '',
    ].join(' ');

    return (
      <div className={divClassNames}>
        <input
          ref={this._getReference('month')}
          className="date__single-field non-incremental-input"
          type="number"
          value={month}
          min="1"
          placeholder="MM"
          maxLength="2"
          onChange={e => this._checkDateDigits(e, 'month', MONTH_DIGITS)}
        />
        <p className="date__separator">
          /
        </p>
        <input
          ref={this._getReference('day')}
          className="date__single-field non-incremental-input"
          type="number"
          value={day}
          min="1"
          placeholder="DD"
          onChange={e => this._checkDateDigits(e, 'day', DAY_DIGITS)}
        />
        <p className="date__separator">
          /
        </p>
        <input
          ref={this._getReference('year')}
          className="date__double-field non-incremental-input"
          type="number"
          value={year}
          min="1900"
          placeholder="YYYY"
          onChange={e => this._checkDateDigits(e, 'year', YEAR_DIGITS)}
        />
      </div>
    );
  }
}

DateInput.propTypes = IPropTypes;
DateInput.defaultProps = defaultProps;

export { DateInput };
