import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { store } from '../../store';
import { PatientPageContainer } from './patient-page-container';

const IPropTypes = {
  requestId: PropTypes.string.isRequired,
  patientRecordId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

const defaultProps = {
  type: null,
};

const PatientPageRootContainer = props => (
  <Provider store={store}>
    <PatientPageContainer
      requestId={props.requestId}
      patientRecordId={props.patientRecordId}
      type={props.type}
    />
  </Provider>
);

PatientPageRootContainer.propTypes = IPropTypes;
PatientPageRootContainer.defaultProps = defaultProps;
export default PatientPageRootContainer;
