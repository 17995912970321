import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

export function useForm(initialFValues, validateOnChange, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };
  const resetForm = (resetValues) => {
    if (resetValues == null) {
      setValues(initialFValues);
    } else {
      Object.keys(resetValues).forEach((key) => {
        if (typeof resetValues[key] === 'undefined') {
          // eslint-disable-next-line no-param-reassign
          resetValues[key] = '';
        }
      });
      setValues({ ...initialFValues, ...resetValues });
    }
    setErrors({});
  };
  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      width: '90%',
      margin: theme.spacing(1),
    },
  },
}));

export const Form = (props) => {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <form className={classes.root} autoComplete="off" {...other}>
      {children}
    </form>
  );
};
Form.propTypes = {
  children: PropTypes.element.isRequired,
};
