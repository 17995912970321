import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './current-therapy-information-expanded.module.scss';
import baseEtrfStyles from '../../../../assets/stylesheets/etrf/base.module.scss';
import { FormatHelper } from '../../helpers/format_helper';
import { DateInput } from '../../../generic/DateInput';
import { OnlineOrderingFieldsLimits } from '../../../../config/input-character-limit';

const IPropTypes = {
  currentTherapyInfo: PropTypes.shape({
    advancedCancer: PropTypes.bool,
    targetedTherapy: PropTypes.bool,
    immunotherapy: PropTypes.bool,
    chemotherapy: PropTypes.bool,
    combinationTherapy: PropTypes.bool,
    specificTherapy: PropTypes.string,
    dateOfTherapyInitiation: PropTypes.string,
  }).isRequired,
  saveCurrentTherapyInfo: PropTypes.func.isRequired,
};

const CurrentTherapyInformationExpanded = ({
  currentTherapyInfo: {
    targetedTherapy,
    immunotherapy,
    chemotherapy,
    combinationTherapy,
    specificTherapy,
    dateOfTherapyInitiation,
  },
  saveCurrentTherapyInfo,
}) => {
  const onSpecificTherapyChange = useCallback((e) => {
    saveCurrentTherapyInfo({
      specificTherapy: e.target.value,
    }, true);
  }, []);

  const onDateChange = useCallback((newDate) => {
    saveCurrentTherapyInfo({
      dateOfTherapyInitiation: newDate,
    });
  }, []);

  const storeCheckboxValue = useCallback(key => (e) => {
    saveCurrentTherapyInfo({
      [key]: e.target.checked,
    }, true);
  }, []);

  return (
    <div className={baseEtrfStyles.accordionSection}>
      Currently on Therapy? If yes, please list below
      <div className={styles.checkboxList}>
        <div className={styles.checkboxContainer}>
          <input
            id="targeted-therapy-checkbox"
            type="checkbox"
            checked={targetedTherapy}
            onChange={useCallback(storeCheckboxValue('targetedTherapy'), [])}
          />
          <label className={styles.label} htmlFor="targeted-therapy-checkbox">
            Targeted Therapy
          </label>
        </div>
        <div className={styles.checkboxContainer}>
          <input
            id="immunotherapy-checkbox"
            type="checkbox"
            checked={immunotherapy}
            onChange={useCallback(storeCheckboxValue('immunotherapy'), [])}
          />
          <label className={styles.label} htmlFor="immunotherapy-checkbox">
            Imunotherapy
          </label>
        </div>
        <div className={styles.checkboxContainer}>
          <input
            id="chemotherapy-checkbox"
            type="checkbox"
            checked={chemotherapy}
            onChange={useCallback(storeCheckboxValue('chemotherapy'), [])}
          />
          <label className={styles.label} htmlFor="chemotherapy-checkbox">
            Chemotherapy
          </label>
        </div>
        <div className={styles.checkboxContainer}>
          <input
            id="combination-therapy-checkbox"
            type="checkbox"
            checked={combinationTherapy}
            onChange={useCallback(storeCheckboxValue('combinationTherapy'), [])}
          />
          <label className={styles.label} htmlFor="combination-therapy-checkbox">
            Combination Therapy
          </label>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.specificTherapyContainer}>
          <label className={baseEtrfStyles.fieldLabel} htmlFor="specific-therapy-input">
            Specific Therapy
          </label>
          <input
            id="specific-therapy-input"
            className={baseEtrfStyles.field}
            onChange={onSpecificTherapyChange}
            value={specificTherapy}
            maxLength={OnlineOrderingFieldsLimits.specialTherapyMaxLength}
            autoComplete="off"
          />
        </div>
        <div>
          <label className={baseEtrfStyles.fieldLabel} htmlFor="date-of-therapy-initiation-input">
            Date of therapy initiation
          </label>
          <DateInput
            className={styles.dateInput}
            value={dateOfTherapyInitiation}
            maxDate={FormatHelper.getTodaysDate()}
            onInputChange={onDateChange}
          />
        </div>
      </div>
    </div>
  );
};

CurrentTherapyInformationExpanded.propTypes = IPropTypes;

export { CurrentTherapyInformationExpanded };
