import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../generic/Modal';

import styles from './gene-details-modal.module.scss';
import { useDetails } from './hooks';
import { BiologicalGeneDetail } from '../../../models/biological-gene-detail';
import { ClinicalGeneDetail } from '../../../models/clinical-gene-detail';

const IPropTypes = {
  requestId: PropTypes.string.isRequired,
  alterationId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  gene: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  saveGeneDetails: PropTypes.func.isRequired,
  geneDetails: PropTypes.shape({
    biological: PropTypes.arrayOf(PropTypes.instanceOf(BiologicalGeneDetail)).isRequired,
    clinical: PropTypes.arrayOf(PropTypes.instanceOf(ClinicalGeneDetail)).isRequired,
  }),
};

const defaultProps = {
  onClose: () => {},
  geneDetails: null,
};

const TABS = {
  CLINICAL: 0,
  BIOLOGICAL: 1,
};

const renderClinicalTab = (clinicalData) => {
  if (!clinicalData) {
    return (
      <div>
        Biomarker not found
      </div>
    );
  }
  const {
    roleInDisease,
    drugSensitivity,
    drugResistance,
  } = clinicalData;
  if (!roleInDisease && !drugSensitivity && !drugResistance) {
    return (
      <div>
        No clinical relevance found.
      </div>
    );
  }
  return (
    <div>
      {
        !!roleInDisease
        && (
          <div>
            <h3 className={styles.sectionTitle}>Role in Disease</h3>
            <p className={styles.sectionContent}>{roleInDisease}</p>
          </div>
        )
      }
      {
        !!drugSensitivity
        && (
          <div>
            <h3 className={styles.sectionTitle}>Effect on Drug Sensitivity</h3>
            <p className={styles.sectionContent}>{drugSensitivity}</p>
          </div>
        )
      }
      {
        !!drugResistance
        && (
          <div>
            <h3 className={styles.sectionTitle}>Effect on Drug Resistance</h3>
            <p className={styles.sectionContent}>{drugResistance}</p>
          </div>
        )
      }
    </div>
  );
};

const renderBiologicalTab = (biologicalData) => {
  if (!biologicalData) {
    return (
      <div>
        Biomarker not found
      </div>
    );
  }
  const {
    content,
    molecularFunction,
    incidence,
  } = biologicalData;
  if (!content && !molecularFunction && !incidence) {
    return (
      <div>
        No clinical relevance found.
      </div>
    );
  }
  return (
    <div>
      {
        !!content
        && (
          <div>
            <h3 className={styles.sectionTitle}>Content</h3>
            <p className={styles.sectionContent}>{content}</p>
          </div>
        )
      }
      {
        !!molecularFunction
        && (
          <div>
            <h3 className={styles.sectionTitle}>Molecular Function</h3>
            <p className={styles.sectionContent}>{molecularFunction}</p>
          </div>
        )
      }
      {
        !!incidence
        && (
          <div>
            <h3 className={styles.sectionTitle}>Incidence</h3>
            <p className={styles.sectionContent}>{incidence}</p>
          </div>
        )
      }
    </div>
  );
};

const renderTabs = (displayedTab, alterationId, details) => {
  if (displayedTab === TABS.CLINICAL) {
    const clinicalData = details.clinical.find(elem => elem.alterationId === alterationId);
    return renderClinicalTab(clinicalData);
  }
  if (displayedTab === TABS.BIOLOGICAL) {
    const biologicalData = details.biological.find(elem => elem.alterationId === alterationId);
    return renderBiologicalTab(biologicalData);
  }
  return null;
};

const GeneDetailsModal = (props) => {
  const [startClose, setStartClose] = useState(false);
  const [displayedTab, setDisplayedTab] = useState(TABS.CLINICAL);
  const { loading, error } = useDetails(props.requestId, props.saveGeneDetails);

  return (
    <Modal startClose={startClose} onClose={props.onClose}>
      <div className={styles.container}>
        <button
          className={styles.closeButton}
          type="button"
          onClick={() => setStartClose(true)}
        >
          <i className="fa fa-close" />
        </button>
        <div className={styles.titleContainer}>
          <strong><em>{props.gene}</em></strong>
          {' '}
          {props.name}
        </div>
        <div>
          <button
            type="button"
            onClick={() => setDisplayedTab(TABS.CLINICAL)}
            className={[styles.tabButton, displayedTab === TABS.CLINICAL ? styles.active : ''].join(' ')}
          >
            Clinical Relevance
          </button>
          <button
            type="button"
            onClick={() => setDisplayedTab(TABS.BIOLOGICAL)}
            className={[styles.tabButton, displayedTab === TABS.BIOLOGICAL ? styles.active : ''].join(' ')}
          >
            Biological Significance
          </button>
        </div>
        <div className={styles.contentContainer}>
          {
            loading
            && (
              <div className={styles.spinnerContainer} />
            )
          }
          {
            !loading
            && !error
            && !!props.geneDetails
            && renderTabs(displayedTab, props.alterationId, props.geneDetails)
          }
        </div>
      </div>
    </Modal>
  );
};

GeneDetailsModal.propTypes = IPropTypes;
GeneDetailsModal.defaultProps = defaultProps;

export { GeneDetailsModal };
