import { deserializeDate, serializeDate } from './helpers';
import { DiagnosisSerializer } from './diagnosis';

class EarlyStageDiagnosisSerializer extends DiagnosisSerializer {
  static deserialize = diagnosis => ({
    ...super.deserialize(diagnosis),
    diagnosisAdjuvantChemotherapy: deserializeDate(diagnosis.dateOfAdjuvantChemotherapy),
  });

  static serialize = diagnosisInfo => ({
    ...super.serialize(diagnosisInfo),
    dateOfAdjuvantChemotherapy: serializeDate(diagnosisInfo.diagnosisAdjuvantChemotherapy),
  });
}

export { EarlyStageDiagnosisSerializer };
