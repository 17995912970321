import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';
import { StoreHelper } from '../../helpers/store_helper';
import { formatFirstLastName } from '../../util/order_util';

const IPropTypes = {
  patientStore: PropTypes.object.isRequired,
  onToggle: PropTypes.func,
  highlightRequiredFields: PropTypes.bool,
  showAllInformation: PropTypes.bool,
};

const defaultProps = {
  onToggle: () => {},
  highlightRequiredFields: false,
  showAllInformation: false,
};

const PatientInformationCollapsed = ({
  patientStore: {
    patientFirstName,
    patientLastName,
    patientType,
    patientStatus,
    patientBirthDate,
    patientSex,
    patientMedicalRecordNumber,
    patientEmail,
    patientPhone,
    patientCity,
    patientCountry,
    patientPostalCode,
    patientProvince,
    patientState,
    patientStreet,
    patientStreet2,
    patientZipCode,
  },
  onToggle, highlightRequiredFields, showAllInformation,
}) => {
  const patientFullName = formatFirstLastName(patientFirstName, patientLastName);
  const addressValue = StoreHelper.formatAddress(
    patientStreet, patientStreet2, patientCity, patientState,
    patientCountry === 'United States' ? patientCountry : patientProvince,
    patientCountry === 'United States' ? patientZipCode : patientPostalCode,
  );

  return (
    <div className="requests-list requests-list--collapsed--reactified">
      <div
        className="accordion-section collapsed flex-container__inner-container"
        type="button"
        onClick={onToggle}
      >
        <div className="flex-container__etrf-section">
          <div className="flex-row">
            <div className="patient-information-column-2-2">
              <div className="patient-two-item-row">
                <div className="patient-information-column-1-1__fixed">
                  <CollapsedTab title="Type" value={patientType} />
                </div>
                <div className="patient-information-column-1-1__double-column">
                  <CollapsedTab title="Status" value={patientStatus} highlightTitle={highlightRequiredFields && !patientStatus} />
                </div>
              </div>

              <div className="patient-two-item-row">
                <div className="patient-information-column-1-1__fixed">
                  <CollapsedTab className={`${showAllInformation ? '' : 'no-padding'}`} title="Name" value={patientFullName} highlightTitle={highlightRequiredFields && (!patientFirstName || !patientLastName)} />
                </div>
                <div className="patient-information-column-1-1__double-column flex-row multiple-items">
                  <CollapsedTab className="no-padding" title="DOB" value={StoreHelper.formatDate(patientBirthDate)} highlightTitle={highlightRequiredFields && !patientBirthDate} />
                  <CollapsedTab className="no-padding" title="Sex" value={patientSex} highlightTitle={highlightRequiredFields && !patientSex} />
                </div>
              </div>

              <div className="patient-information-column-1-2">
                <CollapsedTab
                  className={`${showAllInformation ? 'no-padding' : 'hide'}`}
                  title="Address"
                  value={addressValue}
                  extraLargeValue
                />
              </div>


            </div>
            <div className="patient-information-column-1-3">
              <CollapsedTab title="MRN" value={patientMedicalRecordNumber} />
              <CollapsedTab sectionClassname={patientEmail && 'lowercase'} className={`${showAllInformation ? '' : 'hide'}`} title="Email" value={patientEmail} />
              <CollapsedTab className={`${showAllInformation ? 'no-padding' : 'hide'}`} title="Phone" value={patientPhone} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PatientInformationCollapsed.propTypes = IPropTypes;
PatientInformationCollapsed.defaultProps = defaultProps;

export { PatientInformationCollapsed };
