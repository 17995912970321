// @ts-nocheck
import Immutable from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionsTypes';

export const DEFAULT_MATCHGEN_STATE = Immutable.fromJS({
  organizationList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },

  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
  matchAlgoID: null,
});

const MatchGenReducer = handleActions(
  {
    [actionTypes.GET_MATCHGEN]: (state, action) => state
      .set('reload', false)
      .set('organizationList', action.payload.data || action.payload.data.getAllMatchAlgorithms)
      .set(
        'totalRecords',
        action.payload.data.getAllMatchAlgorithms.totalRecords === 0
          ? 0
          : action.payload.data.getAllMatchAlgorithms.totalRecords || action.payload.data.length,
      )
      .setIn(
        ['pagination', 'pageNo'],
        action.payload.data.getAllMatchAlgorithms.matchAlgorithms.length === 0 && state.getIn(['pagination', 'pageNo']) > 0
          ? state.getIn(['pagination', 'pageNo']) - 1
          : state.getIn(['pagination', 'pageNo']),
      ),
    [actionTypes.SAVE_MATCHGEN]: (state, action) => state
      .set('matchAlgoID', action.payload.data.createMatchAlgorithm.id)
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Match Algorithm Added Successfully', type: 'success' }),

    [actionTypes.SAVE_SEARCH_SPACE]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Search Space Added Successfully', type: 'success' }),

    [actionTypes.SAVE_GENOMIC]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Genomic Criteria Added Successfully', type: 'success' }),

    [actionTypes.UPDATE_MATCHGEN]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Match Algorithm Saved Successfully', type: 'success' }),

    [actionTypes.MATCHGEN_ERROR]: (state, action) => state.setIn(['notify'], Immutable.fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.SEARCH_ERROR]: (state, action) => state.setIn(['notify'], Immutable.fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.GENOMIC_ERROR]: (state, action) => state.setIn(['notify'], Immutable.fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.UPDATE_MATCHGEN_ERROR]: (state, action) => state.setIn(['notify'], Immutable.fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.MATCH_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_ORGANIZATION]: (state, action) => state
      .set(action.payload.key, action.payload.value),
  },
  DEFAULT_MATCHGEN_STATE,
);
export default MatchGenReducer;
