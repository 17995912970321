import { connect } from 'react-redux';
import { AddPatientPageInfo } from '../../../store/actions/patient-page/multi-product';

import { PatientPageContainer } from './patient-page-container';

const mapDispatchToProps = dispatch => ({
  savePatientPageData: info => dispatch(AddPatientPageInfo(info)),
});

const mapStateToProps = store => ({
  showDisclaimer: ((((store.patientPage || {})
    .multiProduct || {})
    .info || {})
    .loggedUser || {})
    .showDisclaimer,
});

const ConnectedPatientPageContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(PatientPageContainer);

export { ConnectedPatientPageContainer as PatientPageContainer };
