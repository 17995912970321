import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { LunarActionBar } from './lunar_action_bar';
import BannerMessage from '../../orders/trf_button/BannerMessage';

import styles from './LunarPatientHeader.module.scss';

const IPropTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canShareReport: PropTypes.bool.isRequired,
  shareReportEnabled: PropTypes.bool.isRequired,
  canReleaseReport: PropTypes.bool.isRequired,
  releaseReportEnabled: PropTypes.bool.isRequired,
  showShareMessage: PropTypes.bool.isRequired,
  toggleShareMessage: PropTypes.func.isRequired,
  showReleaseMessage: PropTypes.bool.isRequired,
  toggleReleaseMessage: PropTypes.func.isRequired,
};

const LunarPatientHeader = ({
  canShareReport, shareReportEnabled, authenticityToken, showShareMessage, toggleShareMessage,
  canReleaseReport, releaseReportEnabled, showReleaseMessage, toggleReleaseMessage,
}) => {
  const patientData = window.gon.patientInformation || {};

  const successShareLink = <a href="/shares">Permissions</a>;
  const successShareMessage = [
    <span className={styles.flashMessage}>
      Sent invitation to view this patient. Remember, you can always revoke
      this invitation from the&nbsp;
      {successShareLink}
      &nbsp;page.
    </span>,
  ];

  return (
    <Fragment>
      {
        // TODO: move banners to const
        !!showShareMessage && (
          <BannerMessage
            status="success"
            toggleBanner={toggleShareMessage}
            messages={successShareMessage}
          />
        )
      }
      {
        !!showReleaseMessage && (
          <BannerMessage
            status="success"
            toggleBanner={toggleReleaseMessage}
            messages={['Report has been released to patient.']}
          />
        )
      }
      <div className={styles.container}>
        <div className={styles.header}>
          <a className={styles.backButton} href="/">
            <i className="fa fa-angle-left" />
            {' '}
            BACK
          </a>
          <div className={styles.title}>
            {patientData.name}
          </div>
          <LunarActionBar
            canShareReport={canShareReport}
            shareReportEnabled={shareReportEnabled}
            canReleaseReport={canReleaseReport}
            releaseReportEnabled={releaseReportEnabled}
            authenticityToken={authenticityToken}
          />
        </div>
      </div>
    </Fragment>
  );
};

LunarPatientHeader.propTypes = IPropTypes;

export { LunarPatientHeader };
