import React from 'react';
import PropTypes from 'prop-types';
import SignOut from '../login/SignOut';

const IPropTypes = {
  imgSrc: PropTypes.string.isRequired,
  lastLogin: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  dashboardPath: PropTypes.string.isRequired,
  rootPath: PropTypes.string.isRequired,
  studiesDashboardUser: PropTypes.bool.isRequired,
  studiesUrl: PropTypes.string.isRequired,
  userIdentity: PropTypes.string.isRequired,
  newSharePath: PropTypes.string.isRequired,
  ordersPath: PropTypes.string.isRequired,
  canAccessOrders: PropTypes.bool.isRequired,
  canAccessPermissions: PropTypes.bool.isRequired,
  sharesPath: PropTypes.string.isRequired,
  myguardantSettingsPath: PropTypes.string,
  myguardantTermsOfUsePath: PropTypes.string.isRequired,
  formAuthenticityToken: PropTypes.string.isRequired,
  iosUser: PropTypes.bool.isRequired,
};
const defaultProps = {
  lastLogin: '',
  myguardantSettingsPath: '',
};
const PhysicianSlideInMenu = (props) => {
  const {
    imgSrc,
    lastLogin,
    fullName,
    dashboardPath,
    rootPath,
    studiesDashboardUser,
    studiesUrl,
    userIdentity,
    newSharePath,
    ordersPath,
    canAccessOrders,
    iosUser,
    canAccessPermissions,
    sharesPath,
    myguardantSettingsPath,
    myguardantTermsOfUsePath,
    formAuthenticityToken,
  } = props;
  return (
    <>
      <div
        className="slide-in-menu__content affix-top"
        data-offset-top="0"
        data-spy="affix"
      >
        <img
          className="logo-mobile visible-xs"
          height="300px"
          alt="img"
          src={imgSrc}
        />
        {lastLogin && (
          <div className="menu-block">
            <div className="menu-block-item header bold">{fullName}</div>
            <div className="menu-block-item sub-description">
              Last login:
              {' '}
              {lastLogin}
            </div>
          </div>
        )}
        <div className="menu-block">
          <div className="menu-block-item">
            <a
              className={`${
                [dashboardPath, rootPath].includes(window.location.pathname)
                && 'active'
              }`}
              href={rootPath}
            >
              DASHBOARD
            </a>
          </div>
        </div>
        {studiesDashboardUser && !iosUser && (
          <div className="menu-block">
            <div className="menu-block-item">
              <a
                className={`${
                  studiesUrl === window.location.pathname && 'active'
                }`}
                href={studiesUrl}
              >
                STUDIES
              </a>
            </div>
          </div>
        )}
        {userIdentity === 'account_owner' && (
          <div className="menu-block">
            <div className="menu-block-item">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a
                className={`${
                  newSharePath === window.location.pathname && 'active'
                }`}
                href={newSharePath}
              />
              <i className="fa fa-unlock-alt">&nbsp;&nbsp;</i>
              <span> Grant Reports Access</span>
            </div>
          </div>
        )}
        {canAccessOrders && !iosUser && (
          <div className="menu-block">
            <div className="menu-block-item">
              <a
                className={`${
                  ordersPath === window.location.pathname && 'active'
                }`}
                href={ordersPath}
              >
                ORDERS
              </a>
            </div>
          </div>
        )}
        {canAccessPermissions && (
          <div className="menu-block">
            <div className="menu-block-item">
              <a
                className={`${
                  sharesPath === window.location.pathname && 'active'
                }`}
                href={sharesPath}
              >
                PERMISSIONS
              </a>
            </div>
          </div>
        )}
        <div className="menu-block">
          <div className="menu-block-item margin-bottom__25-px">
            <a
              className={`${
                myguardantSettingsPath === window.location.pathname && 'active'
              }`}
              href={myguardantSettingsPath}
            >
              Settings
            </a>
          </div>
          <div className="menu-block-item margin-bottom__25-px">
            <a
              target="_blank"
              href="https://docs.google.com/document/u/1/d/109CjD5xpcP3Wok3RWt9dZRpa63iVFlaeZhBZezWlVeE/pub"
              rel="noreferrer"
            >
              Help &amp; FAQ&apos;s
            </a>
          </div>
          <div className="menu-block-item margin-bottom__25-px">
            <a
              className={`${
                myguardantTermsOfUsePath === window.location.pathname
                  && 'active'
              }`}
              href={myguardantTermsOfUsePath}
            >
              Terms and Conditions of Use
            </a>
          </div>
          <div className="menu-block-item">
            <SignOut authenticityToken={formAuthenticityToken} />
          </div>
        </div>
        <div className="menu-block" />
      </div>
    </>
  );
};

export default PhysicianSlideInMenu;
PhysicianSlideInMenu.propTypes = IPropTypes;
PhysicianSlideInMenu.defaultProps = defaultProps;
