import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  showModal: PropTypes.bool,
};
const defaultProps = {
  showModal: false,
};

const CanContactModal = (props) => {
  const {
    showModal,
  } = props;
  return (
    <>
      {
        showModal && <div>Modal</div>
      }
    </>
  );
};
export default CanContactModal;
CanContactModal.propTypes = IPropTypes;
CanContactModal.defaultProps = defaultProps;
