/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Controls from '../common/components/forms/controls';
import { useForm, Form } from '../common/components/forms/useForm';
import Validation from '../common/utils/Validation';

const useStyles = makeStyles(theme => createStyles({
  actionButtons: {
    textAlign: 'right',
    margin: theme.spacing(2),
  },
  submitButton: {
    boxShadow: 'none',
    backgroundColor: '#1A5EA7',
    '&:hover': {
      backgroundColor: '#4572A7',
      boxShadow: 'none',
    },
  },
  resetButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  inputText: {
    width: '90%',
  },
}));
const initialFValues = {
  firstName: '',
  lastName: '',
  email: '',
};
const fieldsValidation = {
  roles: {
    error: '',
    validate: 'dropDown',
    required: true,
    maxLength: 256,
  },
};
const RoleAssignmentForm = (props) => {
  const [disabled, setDisabled] = useState(false);
  const {
    submitHandler, formData, edit, rolesList, HandleChange,
  } = props;
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    ['roles'].forEach((val) => {
      if (val in fieldValues) {
        const error = Validation(val, fieldValues[val], fieldsValidation) || '';
        temp[val] = error;
      }
    });
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
  };

  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      submitHandler(values, resetForm, edit);
    }
  };
  const inputChange = (e) => {
    const role = e.target.value.name;
    const adminRoles = ['Super GH Admin', 'Site Admin', 'Client Services GH Admin', 'Portal Dev GH Admin',
      'Standard GH Admin',
      'Sales GH Admin',
    ];

    if (!values.sites && !adminRoles.includes(role)) {
      HandleChange({
        key: 'notify',
        value: {
          isOpen: true,
          message: 'Site is not assigned to user to assign the role',
          type: 'warning',
        },
      });
    }
    if (role === 'Study Manager') {
      HandleChange({
        key: 'notify',
        value: {
          isOpen: true,
          message: 'Study Manager not assignable',
          type: 'error',
        },
      });
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    handleInputChange(e);
  };
  useEffect(() => {
    if (formData !== null) {
      setValues({
        ...formData,
      });
    } else {
      setValues({
        firstName: '',
        lastName: '',
      });
    }
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="FirstName"
            name="firstName"
            id="firstName"
            value={values.firstName}
            onChange={handleInputChange}
            error={errors.firstName && errors.firstName}
            disabled={edit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="LastName"
            name="lastName"
            id="lastName"
            value={values.lastName}
            onChange={handleInputChange}
            error={errors.lastName && errors.lastName}
            disabled={edit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Email"
            name="email"
            id="email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email && errors.email}
            disabled={edit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.AutoCompleteInput
            multiple={false}
            value={values.roles || []}
            label="Roles"
            name="roles"
            property="uuid"
            displaylabel="name"
            options={rolesList || []}
            onChange={inputChange}
            error={errors.roles && errors.roles.length > 0}
            helperText={errors.roles && errors.roles}
          />
        </Grid>
        <Grid item xs={12} className={classes.actionButtons}>
          <Controls.Button
            className={classes.submitButton}
            type="submit"
            text="Save"
            disabled={disabled}
          />
          <Controls.Button
            text="Reset"
            color="default"
            className={classes.resetButton}
            onClick={() => {
              resetForm(formData);
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
export default RoleAssignmentForm;
RoleAssignmentForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  formData: PropTypes.object,
  edit: PropTypes.bool,
  rolesList: PropTypes.array,
  HandleChange: PropTypes.func,
};
RoleAssignmentForm.defaultProps = {
  formData: null,
  rolesList: [],
  edit: false,
  HandleChange: () => {},
};
