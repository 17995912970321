/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Controls from '../common/components/forms/controls';
import { useForm, Form } from '../common/components/forms/useForm';
import Validation from '../common/utils/Validation';

const IPropTypes = {
  formData: PropTypes.object,
  submitHandler: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};
const defaultProps = {
  formData: null,
};
const initialFValues = {
  accountNumber: '',
};
const useStyles = makeStyles(theme => createStyles({
  actionButtons: {
    textAlign: 'right',
    margin: theme.spacing(2),
  },
  submitButton: {
    boxShadow: 'none',
    backgroundColor: '#1A5EA7',
    '&:hover': {
      backgroundColor: '#4572A7',
      boxShadow: 'none',
    },
  },
  resetButton: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  inputText: {
    width: '90%',
  },
}));
const fieldsValidation = {
  accountNumber: {
    error: '',
    validate: 'alphanumaric',
    minLength: 2,
    maxLength: 256,
  },
};
const AccountForm = (props) => {
  const { submitHandler, formData, edit } = props;
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    ['accountNumber'].forEach((val) => {
      if (val in fieldValues) {
        const error = Validation(val, fieldValues[val], fieldsValidation) || '';
        temp[val] = error;
      }
    });
    setErrors({
      ...temp,
    });

    if (fieldValues === values) return Object.values(temp).every(x => x === '');
  };
  const {
    values, setValues, errors, setErrors, handleInputChange, resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      submitHandler(values, resetForm, edit);
    }
  };

  useEffect(() => {
    if (formData !== null) {
      setValues({
        ...formData,
      });
    }
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Account Number"
            name="accountNumber"
            value={values.accountNumber}
            onChange={handleInputChange}
            error={errors.accountNumber}
          />
        </Grid>
        <Grid item xs={12} className={classes.actionButtons}>
          <Controls.Button
            className={classes.submitButton}
            type="submit"
            text="save"
          />
          <Controls.Button
            text="reset"
            color="default"
            className={classes.resetButton}
            onClick={() => {
              resetForm(formData);
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
export default AccountForm;
AccountForm.propTypes = IPropTypes;
AccountForm.defaultProps = defaultProps;
