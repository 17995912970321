import dateFormat from 'dateformat';
import { constants } from '../../config/constants';

import {
  AccountInfo, BillingInfo, BloodSpecimenInfo, PatientInfo,
  PhysicianInfo, SecondaryRecipientInfo, OrderingUser,
} from '.';

const { INCOMPLETE } = constants.printModal.infoCompleteness;
const { selfInsuredValue } = constants.billingInformation;

const orderStatuses = {
  incomplete: 'INCOMPLETE',
  printed: 'PRINTED',
  pendingSignature: 'PENDINGSIGNATURE',
  signed: 'SIGNED',
};

const formatStatusAndDateForDashboard = (status, date, defaultValue = status) => {
  if (date) {
    const formattedDate = dateFormat(date, 'mmm-d-yyyy');
    return `${status}: ${formattedDate.toUpperCase()}`;
  }
  return defaultValue;
};

class Order {
  constructor(params) {
    this.patientInfo = new PatientInfo(params.patientInfo);
    this.accountInfo = new AccountInfo(params.physicianInfo.client);
    this.physicianInfo = new PhysicianInfo(params.physicianInfo.physician);
    this.secondaryRecipientInfo = new SecondaryRecipientInfo(
      params.physicianInfo.secondaryRecipient,
    );
    this.billingInfo = new BillingInfo(params.billingInfo);

    this.bloodSpecimen = new BloodSpecimenInfo(params.specimen || {});
    this.comments = params.commentsText;

    this.id = params.orderId || params.genericInfo.activeOrderId;
    this.productFamily = params.genericInfo.productFamily;
    this.metadata = {
      status: params.status,
      printDate: params.printDate,
      finalizationDate: params.finalizationDate,
      signatureDate: params.signatureDate,
      updatedAt: params.genericInfo.updatedAt,
      latestPrintDate: params.latestPrintDate,
      envelopeId: params.envelopeId,
      esignature: params.esignature,
    };
    this.orderingUser = new OrderingUser(params.orderingUser || {});
  }

  buildHeaderForPrintedOrdersDashboard = () => `${this.physicianInfo.lastname || 'N/A'}, ${this.physicianInfo.firstname || 'N/A'} (${this.accountInfo.name || 'N/A'})`;

  formatStatus = () => {
    if (this.isIncomplete()) {
      return formatStatusAndDateForDashboard('Last Modified', this.metadata.updatedAt, 'N/A');
    }

    if (this.isPendingSignature()) {
      return formatStatusAndDateForDashboard('Pending E-Signature', this.metadata.finalizationDate);
    }

    if (orderStatuses.printed === this.metadata.status) {
      return formatStatusAndDateForDashboard('Printed', this.metadata.printDate);
    }

    if (orderStatuses.signed === this.metadata.status) {
      return formatStatusAndDateForDashboard('Submitted Electronically', this.metadata.signatureDate);
    }

    return '';
  };

  formatOrderingUserAndStatus = () => {
    const userFullname = this.orderingUser.fullName();

    return [
      userFullname && `Created by: ${userFullname}`,
      this.formatStatus(),
    ].filter(Boolean);
  };

  formatPrintDateForModal = () => {
    if (this.metadata.printDate) {
      return dateFormat(this.metadata.printDate, 'mmmm d, yyyy');
    }

    return this.metadata.printDate;
  };

  isPhysicianInfoComplete = () => this.physicianInfo.isComplete() && this.accountInfo.isComplete();

  formatPhysicianInfoForPrintModal = () => {
    const isCompleted = this.isPhysicianInfoComplete();

    return {
      title: 'ORDERING PHYSICIAN',
      info: isCompleted ? `${this.physicianInfo.formatFullName()} - ${this.accountInfo.name}` : INCOMPLETE,
      isCompleted,
    };
  }

  checkNeededSurgicalResectionDateMissing = () => false;

  canBeSigned = orderingUserEmail => (
    !this.metadata.status
      || [
        orderStatuses.incomplete,
        orderStatuses.printed,
      ].includes(this.metadata.status)
      || (
        this.isPendingSignature() && this.isEmbeddedSignature(orderingUserEmail)
      )
  );

  isIncomplete = () => this.metadata.status === orderStatuses.incomplete;

  isPendingSignature = () => this.metadata.status === orderStatuses.pendingSignature;

  finalizationAvailable = () => !!this.physicianInfo.email;

  isEmbeddedSignature = orderingUserEmail => (
    (orderingUserEmail || this.orderingUser.email) === this.physicianInfo.email
  );

  checkMatchingFields = () => this.billingInfo.insuredRelationship !== selfInsuredValue
    || this.patientInfo.birthDate === this.billingInfo.dob;

  isWronglyFinalized = () => (
    this.isPendingSignature() && !!this.metadata.esignature.embeddedUrl
  )
}

export { Order, orderStatuses };
