

import { connect } from 'react-redux';
import { AddGeneDetails } from '../../../store/actions/patient-page/multi-product';

import { GeneDetailsModal } from './gene-details-modal';

const mapStateToProps = store => ({
  geneDetails: store.patientPage.multiProduct.geneDetails,
});

const mapDispatchToProps = dispatch => ({
  saveGeneDetails: geneDetails => dispatch(AddGeneDetails(geneDetails)),
});

const ConnectedGeneDetailsModal = connect(
  mapStateToProps, mapDispatchToProps,
)(GeneDetailsModal);

export { ConnectedGeneDetailsModal as GeneDetailsModal };
