import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';
import { StoreHelper } from '../../helpers/store_helper';

const IIcdCode = PropTypes.shape({
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

const IPropTypes = {
  diagnosisStore: PropTypes.shape({
    diagnosisPrimaryCode: IIcdCode,
    diagnosisSecondaryCode: IIcdCode,
    diagnosisTertiaryCode: IIcdCode,
    diagnosisQuarternaryCode: IIcdCode,
    diagnosisQuinaryCode: IIcdCode,
    diagnosisSenaryCode: IIcdCode,
    disease: PropTypes.string,
    diagnosisStage: PropTypes.string,
    diagnosisOriginalDiagnosis: PropTypes.string,
    otherDisease: PropTypes.string,
  }).isRequired,
  onToggle: PropTypes.func,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  onToggle: () => {},
  highlightRequiredFields: false,
};

const DiagnosisConditionLateStageCollapsed = ({
  diagnosisStore, onToggle, highlightRequiredFields,
}) => {
  const {
    diagnosisPrimaryCode, diagnosisSecondaryCode, diagnosisTertiaryCode,
    diagnosisQuarternaryCode, diagnosisQuinaryCode, diagnosisSenaryCode,
    disease, otherDisease,
    diagnosisStage,
    diagnosisOriginalDiagnosis,
  } = diagnosisStore;

  const diagnosisMultipleCodes = [
    diagnosisSecondaryCode, diagnosisTertiaryCode, diagnosisQuarternaryCode,
    diagnosisQuinaryCode, diagnosisSenaryCode,
  ].map(code => code.code).filter(Boolean).join(', ');

  const cancerType = disease || (otherDisease && 'Other');

  return (
    <div className="requests-list requests-list--collapsed--reactified">
      <div
        className="accordion-section collapsed diagnosis-information-container"
        type="button"
        onClick={onToggle}
      >
        <div className="diagnosis-information-section">
          <div className="flex-row">
            <div className="diagnosis-information-column-2-2">
              <div className="diagnosis-two-item-row">
                <div className="diagnosis-information-column-1-1">
                  <CollapsedTab
                    title="Primary Code"
                    value={diagnosisPrimaryCode && diagnosisPrimaryCode.code}
                    highlightTitle={
                      highlightRequiredFields
                        && (!diagnosisPrimaryCode || !diagnosisPrimaryCode.code)
                    }
                  />
                </div>
                <div className="diagnosis-information-column-1-1">
                  <CollapsedTab className="diagnosis-information-section__double-column" title="Secondary Code(s)" value={diagnosisMultipleCodes} largeValue />
                  <CollapsedTab title="Diagnosis" value={StoreHelper.formatDate(diagnosisOriginalDiagnosis)} />
                </div>
              </div>
              <div className="diagnosis-information-column-1-2">
                <CollapsedTab title="Cancer Type" value={cancerType} highlightTitle={highlightRequiredFields && !cancerType} />
              </div>
            </div>
            <div className="patient-information-column-1-3">
              <CollapsedTab title="Stage" value={diagnosisStage} highlightTitle={highlightRequiredFields && !diagnosisStage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DiagnosisConditionLateStageCollapsed.propTypes = IPropTypes;
DiagnosisConditionLateStageCollapsed.defaultProps = defaultProps;

export { DiagnosisConditionLateStageCollapsed };
