const patientPageInfoActionTypes = {
  ADD_PATIENT_PAGE_INFO: 'ADD_PATIENT_PAGE_INFO',
  ADD_GENE_DETAILS: 'ADD_GENE_DETAILS',
};

const AddPatientPageInfo = info => ({
  type: patientPageInfoActionTypes.ADD_PATIENT_PAGE_INFO,
  info,
});

const AddGeneDetails = geneDetails => ({
  type: patientPageInfoActionTypes.ADD_GENE_DETAILS,
  geneDetails,
});

export { patientPageInfoActionTypes, AddPatientPageInfo, AddGeneDetails };
