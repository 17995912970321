import { connect } from 'react-redux';

import { Comment } from './comment';

const mapStateToProps = store => (
  {
    comment: store.patientPage.multiProduct.info.comment,
  }
);

const ConnectedComment = connect(mapStateToProps)(Comment);

export { ConnectedComment as Comment };
