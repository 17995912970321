class SecondaryRecipientSerializer {
  static deserialize = (secondaryRecipient) => {
    if (!secondaryRecipient) {
      return {};
    }

    return {
      secondaryRecipientFirstname: secondaryRecipient.firstName,
      secondaryRecipientLastname: secondaryRecipient.lastName,
      secondaryRecipientPhone: secondaryRecipient.phone,
      secondaryRecipientFax: secondaryRecipient.fax,
    };
  }

  static serialize = physicianInfo => ({
    firstName: physicianInfo.secondaryRecipientFirstname,
    lastName: physicianInfo.secondaryRecipientLastname,
    phone: physicianInfo.secondaryRecipientPhone,
    fax: physicianInfo.secondaryRecipientFax,
  })
}

export { SecondaryRecipientSerializer };
