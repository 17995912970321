class PhysicianDropdown {
  static deserialize = (data) => {
    if (!data) {
      return {};
    }

    const firstname = data.firstname || data.firstName;
    const lastname = data.lastname || data.lastName;

    return {
      id: String(data.id || ''),
      firstname,
      lastname,
      email: data.email,
      phone: data.physician_phone,
      npi: data.npi,
      title: `${lastname}, ${firstname}`,
    };
  };
}

export { PhysicianDropdown };
