import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as actionTypes from '../actions/actionTypes';

export const DEFAULT_PROJECT_ASSIGNMENT_STATE = fromJS({
  projectAssignmentList: null,
  projectsList: null,
  pagination: {
    pageSize: 5,
    pageNo: 0,
  },
  totalRecords: 0,
  notify: {
    isOpen: false,
    message: '',
    type: '',
  },
  openPopup: false,
  reload: false,
  isOpen: false,
});

const ProjectAssignmentReducer = handleActions(
  {
    [actionTypes.GET_PROJECT_ASSIGNMENT_LIST]: (state, action) => state
      .set('reload', false)
      .set('projectAssignmentList', action.payload.data.userProjects)
      .set('totalRecords', action.payload.data.totalRecords),
    [actionTypes.GET_ALL_PROJECTS]: (state, action) => state.set('projectsList', action.payload.data),
    [actionTypes.SAVE_PROJECT_ASSIGNMENT]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Project Added Successfully', type: 'success' }),

    [actionTypes.UPDATE_PROJECT_ASSIGNMENT]: state => state
      .set('reload', true)
      .set('openPopup', false)
      .setIn(['notify'], { isOpen: true, message: 'Project Added Successfully', type: 'success' }),

    [actionTypes.DELETE_PROJECT_ASSIGNMENT]: state => state
      .set('reload', true)
      .setIn(['notify'], fromJS({ isOpen: true, message: 'Project Deleted Successfully', type: 'success' })),

    [actionTypes.PROJECT_ASSIGNMENT_LIST_ERROR]: (state, action) => state.setIn(['notify'], fromJS({ isOpen: true, message: action.payload.message, type: 'error' })),
    [actionTypes.PROJECT_ASSIGN_PAGINATION_CHANGE]: (state, action) => state.set('pagination', action.payload),
    [actionTypes.ON_CHANGE_PROJECT_ASSIGNMENT]: (state, action) => state
      .set(action.payload.key, action.payload.value),
  },
  DEFAULT_PROJECT_ASSIGNMENT_STATE,
);
export default ProjectAssignmentReducer;
