import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateLoadingCount } from '../../../store/actions/global';
import { showErrorBanner } from '../../../store/actions/orders/active-order/form_errors';
import { addGenericInfo } from '../../../store/actions/orders/active-order/generic-info';
import { updateDashboard } from '../../../store/actions/orders/dashboard';

import { ElectronicSignatureConfirmationContainer } from './electronic-signature-confirmation-container';

const mapStateToProps = ({
  orders: { activeOrder, electronicSignatureConfirmation },
  global: { initialProps: { userPermissions } },
}) => ({
  order: activeOrder.printModal.order,
  emrUser: userPermissions.onlyNewOrders,
  showElectronicSignatureConfirmationOverlay: electronicSignatureConfirmation.showModal,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveGenericInfo: addGenericInfo,
  onError: showErrorBanner,
  updateDashboard,
  updateLoadingCount,
}, dispatch);

const ConnectedElectronicSignatureConfirmationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElectronicSignatureConfirmationContainer);

export {
  ConnectedElectronicSignatureConfirmationContainer as ElectronicSignatureConfirmationContainer,
};
