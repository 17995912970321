class ClinicalGeneDetail {
  constructor(params) {
    this.alterationId = params.alterationId;
    this.drugResistance = params.drugResistance;
    this.roleInDisease = params.roleInDisease;
    this.drugSensitivity = params.drugSensitivity;
  }
}

export { ClinicalGeneDetail };
