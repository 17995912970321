class ClinicalGeneDetailSerializer {
  static deserialize(data) {
    return {
      alterationId: data.alteration_id,
      drugResistance: data.drug_resistance,
      roleInDisease: data.role_in_disease,
      drugSensitivity: data.drug_sensitivity,
    };
  }
}

export { ClinicalGeneDetailSerializer };
