import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changePagination,
  onChange,
  getSiteUsersList,
  editSite,
  getSitesList,
} from './actions/SiteAssignmentActions';
import SiteAssignmentView from './SiteAssignmentView';

const mapStateToProps = state => ({
  siteAssignment: state.siteAssignment,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changePagination,
      onChange,
      getSiteUsersList,
      editSite,
      getSitesList,
    },
    dispatch,
  ),
});
// eslint-disable-next-line max-len
export const ConnectedSiteAssignmentView = connect(mapStateToProps, mapDispatchToProps)(SiteAssignmentView);
export default ConnectedSiteAssignmentView;
