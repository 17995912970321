import { DiseaseSerializer } from './disease';

class ReferenceRangeSerializer {
  static deserialize(data) {
    return data && {
      title: data.label,
      firstColumnName: data.indication,
      secondColumnName: data.expressionLevel,
      thirdColumnName: data.referenceRange,
      rows: (data.disease || []).map(DiseaseSerializer.deserialize),
    };
  }
}

export { ReferenceRangeSerializer };
