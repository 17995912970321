function formatDay(date) {
  let day = date.getUTCDate();
  day = day < 10 ? `0${day}` : day;
  return day;
}

function formatDate(date, format = 'MMM-DD-YYYY') {
  if (!date) return undefined;

  const monthName = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const month = date.getUTCMonth();
  const day = formatDay(date);
  const year = date.getUTCFullYear();
  switch (format) {
    case 'DD-MMM-YYYY':
      return `${day}-${monthName[month]}-${year}`;
    default:
      return `${monthName[month]}-${day}-${year}`;
  }
}

export { formatDate };
