import React from 'react';
import PropTypes from 'prop-types';

import GenericHeader from '../../GenericHeader';
import { PatientInformationExpanded } from '../patient-information-expanded';
import { PatientInformationCollapsed } from '../patient-information-collapsed';
import { constants } from '../../../../config/constants';

const IPropTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
  patientStatuses: PropTypes.arrayOf(PropTypes.string),
  patientTypes: PropTypes.arrayOf(PropTypes.string),
  hasPatientDemoFaceSheetFields: PropTypes.bool,
  currentPatientType: PropTypes.string,
};

const defaultProps = {
  enabled: false,
  onToggle: () => {},
  patientStatuses: null,
  patientTypes: null,
  hasPatientDemoFaceSheetFields: false,
  currentPatientType: null,
};

class PatientInformationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDemoFaceSheet: false,
      showEnterAllInfo: false,
      selections: {
        patientStatus: null,
        patientSex: null,
        patientType: null,
      },
      showSelectPatientType: true,
      faceSheet: false,
    };
    this.toggleSelected = this.toggleSelected.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onPatientTypeChange = this.onPatientTypeChange.bind(this);
    this.setProgressionPatientTypeDefault = this.setProgressionPatientTypeDefault.bind(this);
  }

  componentDidMount() {
    const { patientTypes, currentPatientType } = this.props;
    if (patientTypes) {
      this.onPatientTypeChange(
        patientTypes.findIndex(e => e === currentPatientType),
      );
    }
  }

  onPatientTypeChange(id) {
    const numericId = parseInt(id, 10);
    if (numericId === 1) {
      this.setState({
        showEnterAllInfo: true,
        showSelectPatientType: false,
        showDemoFaceSheet: false,
        faceSheet: false,
      });
    } else if (numericId === 0) {
      this.setState({
        showEnterAllInfo: false,
        showSelectPatientType: false,
        showDemoFaceSheet: true,
      });
    } else {
      this.setState({
        showEnterAllInfo: false,
        showSelectPatientType: true,
        showDemoFaceSheet: false,
      });
    }
  }

  setProgressionPatientTypeDefault() {
    this.setState({
      showEnterAllInfo: true,
      showSelectPatientType: false,
      showDemoFaceSheet: false,
    });
  }

  toggleSelected = (id, key) => {
    this.setState((prevState) => {
      const temp = JSON.parse(JSON.stringify(prevState[key]));
      temp[id].selected = !temp[id].selected;
      return {
        [key]: temp,
      };
    });
  }

  onFieldChange = (id, key) => {
    this.setState(prevState => ({
      selections: {
        ...prevState.selections,
        [key]: id,
      },
    }));

    if (key === 'patientType') {
      this.onPatientTypeChange(id);
    }
  }

  transformFieldForDropdowns(valueList, fieldName) {
    return valueList.map((status, index) => ({
      id: String(index),
      title: status,
      selected: this.state.selections[fieldName] === index,
      key: fieldName,
    }));
  }

  render() {
    return (
      <div>
        <GenericHeader
          enabled={this.props.enabled}
          handleContainerArrowClick={this.props.onToggle}
          label="Patient Information"
        />

        {
          this.props.enabled
            ? (
              <PatientInformationExpanded
                patientTypes={this.transformFieldForDropdowns(this.props.patientTypes, 'patientType')}
                toggleSelected={this.toggleSelected}
                onFieldChange={this.onFieldChange}
                showEnterAllInfo={this.state.showEnterAllInfo}
                showDemoFaceSheet={
                  this.props.hasPatientDemoFaceSheetFields && this.state.showDemoFaceSheet
                }
                patientStatuses={this.transformFieldForDropdowns(this.props.patientStatuses, 'patientStatus')}
                patientSexes={this.transformFieldForDropdowns(constants.patientSexes, 'patientSex')}
                showSelectPatientType={this.state.showSelectPatientType}
                setProgressionPatientTypeDefault={this.setProgressionPatientTypeDefault}
              />
            ) : (
              <PatientInformationCollapsed
                onToggle={this.props.onToggle}
                showAllInformation={this.state.showEnterAllInfo}
              />
            )
        }
      </div>
    );
  }
}

PatientInformationContainer.propTypes = IPropTypes;
PatientInformationContainer.defaultProps = defaultProps;

export { PatientInformationContainer };
