import React from 'react';
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import PropTypes from 'prop-types';

const IPropTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.string,
  fromControlClassName: PropTypes.string,
};
const defaultProps = {
  name: '',
  label: '',
  value: '',
  onChange: () => {},
  fromControlClassName: '',
};
const Checkbox = (props) => {
  const {
    name, label, value, onChange, fromControlClassName = '', ...other
  } = props;
  // eslint-disable-next-line no-shadow
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <FormControl className={fromControlClassName}>
      <FormControlLabel
        control={(
          <MuiCheckbox
            name={name}
            color="primary"
            checked={value}
            {...other}
            onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
          />
        )}
        label={label}
      />
    </FormControl>
  );
};
export default Checkbox;
Checkbox.propTypes = IPropTypes;
Checkbox.defaultProps = defaultProps;
