import { createAction } from 'redux-actions';

export const LOAD_PENDING_ORDERS = '@onlineOrderManagement-workflow/LOAD_PENDING_ORDERS';
export const loadPendingOrders = createAction(LOAD_PENDING_ORDERS);

export const LOAD_PENDING_SIGNATURE_ORDERS = '@onlineOrderManagement-workflow/LOAD_PENDING_SIGNATURE_ORDERS';
export const loadPendingSignatureOrders = createAction(LOAD_PENDING_SIGNATURE_ORDERS);

export const LOAD_COMPLETED_ORDERS = '@onlineOrderManagement-workflow/LOAD_COMPLETED_ORDERS';
export const loadCompletedOrders = createAction(LOAD_COMPLETED_ORDERS);

export const LOAD_PENDING_ORDERS_ERROR = '@onlineOrderManagement-workflow/LOAD_PENDING_ORDERS_ERROR';
export const loadPendingOrdersError = createAction(LOAD_PENDING_ORDERS_ERROR);

export const LOAD_COMPLETED_ORDERS_ERROR = '@onlineOrderManagement-workflow/LOAD_COMPLETED_ORDERS_ERROR';
export const loadCompletedOrdersError = createAction(LOAD_COMPLETED_ORDERS_ERROR);

export const LOAD_PENDING_SIGNATURE_ORDERS_ERROR = '@onlineOrderManagement-workflow/LOAD_PENDING_SIGNATURE_ORDERS_ERROR';
export const loadPendingSignatureOrdersError = createAction(LOAD_PENDING_SIGNATURE_ORDERS_ERROR);

export const CLOSE_BANNER_MESSAGE = '@onlineOrderManagement-workflow/CLOSE_BANNER_MESSAGE';
export const closeBannerMessage = createAction(CLOSE_BANNER_MESSAGE);

export const DOWNLOAD_ETRF_PDF_ERROR = '@onlineOrderManagement-workflow/DOWNLOAD_ETRF_PDF_ERROR';
export const downloadETRFPdfError = createAction(DOWNLOAD_ETRF_PDF_ERROR);

export const ORDERS_SEARCH_ERROR = '@onlineOrderManagement-workflow/ORDERS_SEARCH_ERROR';
export const ordersSearchError = createAction(ORDERS_SEARCH_ERROR);

export const ORDERS_SELECTED_TAB = '@onlineOrderManagement-workflow/ORDERS_SELECTED_TAB';
export const ordersSelectedTab = createAction(ORDERS_SELECTED_TAB);

export const EDIT_ORDER = '@onlineOrderManagement-workflow/EDIT_ORDER';
export const editOrder = createAction(EDIT_ORDER);

export const ORDERS_DASHBOARD_INITIAL_LOAD = '@onlineOrderManagement-workflow/ORDERS_DASHBOARD_INITIAL_LOAD';
export const ordersDashboardInitialLoad = createAction(ORDERS_DASHBOARD_INITIAL_LOAD);

export const ORDERS_DASHBOARD_ROLE_BASED_AUTH = '@onlineOrderManagement-workflow/ORDERS_DASHBOARD_ROLE_BASED_AUTH';
export const ordersDashboardRoleBasedAuth = createAction(ORDERS_DASHBOARD_ROLE_BASED_AUTH);
