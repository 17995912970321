import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './patient-page-alert.module.scss';
import { ReleaseModal } from '../release-modal/release-modal';
import { LearnMoreModal } from '../learn-more-modal/learn-more-modal';
import { ReleaseForm } from '../release-modal/release-form';
import { Alert } from '../../../models/alert';
import { Alert as AlertComponent } from '../alert/alert';
import { LoggedUser } from '../../../models/logged-user';
import { PatientPageController } from '../../../networking/controllers/patient-page';

const IPropTypes = {
  data: PropTypes.instanceOf(Alert),
  loggedUser: PropTypes.instanceOf(LoggedUser),
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  data: null,
  loggedUser: null,
};

class PatientPageAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReleaseModal: false,
      showLearnMoreModal: false,
    };
  }

  _onReleaseSubmit = () => {
    this._toggleReleaseModal();
    this._showReleaseMessage();
  }

  _toggleReleaseModal = () => {
    this.setState(prevState => ({
      showReleaseModal: !prevState.showReleaseModal,
    }));
  }

  _toggleLearnMoreModal = () => {
    this.setState(prevState => ({
      showLearnMoreModal: !prevState.showLearnMoreModal,
    }));
  }

  releaseReportString = () => {
    const { autoReleaseDate, autoReleaseDateTimeAgo } = this.props.data;

    const whenWillReportBeAutoReleased = new Date(autoReleaseDate) - new Date() > 86400
      ? `in ${autoReleaseDateTimeAgo}` : 'later today';

    return `Would you like to release this report to the patient?
              It will automatically be made available for download on the myGuardant
              patient portal ${whenWillReportBeAutoReleased}`;
  }

  async hideAlert() {
    const { data, loggedUser, onClose } = this.props;

    onClose();
    await PatientPageController.dismissAutoReleasePanel(data.releaseToPatient.requestId,
      loggedUser.authenticityToken);
  }

  render() {
    const { showReleaseModal, showLearnMoreModal } = this.state;
    const { data, loggedUser } = this.props;
    const {
      unreleasedReportsForPatient,
      autoReleaseDate,
      latestReleasedReportDateTimeAgo,
      releaseToPatient,
    } = data;

    const showReleaseToPatient = unreleasedReportsForPatient && autoReleaseDate >= new Date();

    return (
      <Fragment>
        <AlertComponent type="info" displayCloseIcon={!showReleaseToPatient} onClose={() => this.hideAlert()}>
          <div className={styles.text}>
            {
              showReleaseToPatient
                ? this.releaseReportString()
                : `This report was auto-released to the patient ${latestReleasedReportDateTimeAgo} ago.`
            }
          </div>
          <div className={styles.links}>
            <button className={styles.alertButton} type="button" onClick={this._toggleReleaseModal}>
              {
                showReleaseToPatient ? 'RELEASE REPORT TO PATIENT' : 'VIEW PATIENT RELEASE ACTIVITY LOG'
              }
            </button>
            <button className={styles.alertButton} type="button" onClick={this._toggleLearnMoreModal}> LEARN MORE </button>
          </div>
        </AlertComponent>
        {
          showReleaseModal
          && (
            <ReleaseModal
              onClose={this._toggleReleaseModal}
              content={(
                <ReleaseForm
                  requestId={releaseToPatient.requestId}
                  patient={releaseToPatient.patient}
                  reportsToRelease={releaseToPatient.reportToRelease}
                  authenticityToken={loggedUser.authenticityToken}
                  releaseReportEnabled={loggedUser.canReleaseReport}
                  onSubmit={this._onReleaseSubmit}
                />
              )}
            />
          )
        }
        {
          showLearnMoreModal
          && (
            <LearnMoreModal
              onClose={this._toggleLearnMoreModal}
            />
          )
        }
      </Fragment>
    );
  }
}

PatientPageAlert.propTypes = IPropTypes;
PatientPageAlert.defaultProps = defaultProps;

export { PatientPageAlert };
