import { updateBubblesAmounts } from '../../../../helpers/bubbles-helper';
import { orderStatuses } from '../../../../models/order/order';

const COMPLETE = 'COMPLETE';

export const actionTypes = {
  SET_ORDERS_AMOUNTS: 'SET_ORDERS_AMOUNTS',
  RECEIVE_ORDERS: 'RECEIVE_ORDERS',
};

export const receiveOrdersAmounts = (ordersAmounts) => {
  const amounts = [
    {
      displayName: 'To be completed',
      count: ordersAmounts[orderStatuses.incomplete],
      status: orderStatuses.incomplete,
    },
    {
      displayName: 'Pending e-signature',
      count: ordersAmounts[orderStatuses.pendingSignature],
      status: orderStatuses.pendingSignature,
    },
    {
      displayName: 'Completed',
      count: (ordersAmounts[orderStatuses.printed] || 0)
        + (ordersAmounts[orderStatuses.signed] || 0),
      status: COMPLETE,
    },
  ];

  return {
    type: actionTypes.SET_ORDERS_AMOUNTS,
    ordersAmounts: amounts,
  };
};

export const receiveOrders = orders => ({
  type: actionTypes.RECEIVE_ORDERS,
  orders,
});

const updateOrdersLists = (ordersList, orderId, currentStatus, newStatus) => {
  if (!currentStatus) {
    return receiveOrders({ [newStatus]: null });
  }

  const newOrders = ordersList[currentStatus].map((ordersByAffiliation) => {
    const orderPosition = ordersByAffiliation.findIndex(e => e.id === orderId);
    if (orderPosition > -1) {
      if (ordersByAffiliation.length === 1) {
        return null;
      }

      return [
        ...ordersByAffiliation.slice(0, orderPosition),
        ...ordersByAffiliation.slice(orderPosition + 1),
      ];
    }
    return [...ordersByAffiliation];
  }).filter(Boolean);

  const listsToUpdate = { [currentStatus]: newOrders };

  if (newStatus) {
    listsToUpdate[newStatus] = null;
  }

  return receiveOrders(listsToUpdate);
};

export const updateDashboard = (currentOrder, updatedOrder) => (dispatch, getState) => {
  const { orders: { dashboard: { orders, ordersAmounts } } } = getState();

  const parseOrderStatus = (orderStatus) => {
    if ([orderStatuses.printed, orderStatuses.signed].includes(orderStatus)) return COMPLETE;
    if (!orderStatus) return orderStatuses.incomplete;
    return orderStatus;
  };

  const currentStatus = currentOrder && parseOrderStatus(currentOrder.metadata.status);
  const updatedStatus = updatedOrder && parseOrderStatus(updatedOrder.metadata.status);

  if (orders) {
    dispatch(updateOrdersLists(
      orders,
      currentOrder && currentOrder.id,
      currentStatus,
      updatedStatus,
    ));
  }

  if (ordersAmounts) {
    dispatch({
      type: actionTypes.SET_ORDERS_AMOUNTS,
      ordersAmounts: updateBubblesAmounts(ordersAmounts, currentStatus, updatedStatus),
    });
  }
};
