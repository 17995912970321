/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Box,
  Button,
  Grid,
  Tabs,
  useMediaQuery,
  useTheme,
  Toolbar,
} from '@mui/material';
import PatientInfo from './sections/PatientInfo';
import Diagnosis from './sections/Diagnosis';
import { schema } from './schema';
import ValidationJson from './validate.json';
import ClinicalHistory360 from './sections/ClinicalHistory360';
import TestSelection from './sections/TestSelection';
import PhysicianInfo from './sections/PhysicianInfo';
import ReviewOrderPopUp from './sections/ReviewOrderPopUp';
import BillingInfo from './sections/BillingInfo';
import ApplicationMessage from '../admin/common/components/elements/ApplicationMessage';
import Validation, { isEmail } from '../admin/common/utils/Validation';
import Vector from '../../assets/images/icons/patient-page/Vector.svg';
import { Form } from '../admin/common/components/forms/useForm';
import AlertModal from './alert-modal/AlertModal';
import { discardOrder } from './actions/NewOrderAction';
import { StyledTab } from './common/components/StyledTab';
import TabStatus from './tabs/TabStatus';
import {allowReveal, allowG360, getChildDiseases, getDisease, getClinicalHistoryStatus} from './helpers';
import RchAlert from './utils/RchAlert';

const useStyles = makeStyles(theme => createStyles(
  {
    root: {
      marginTop: '1rem',
      backgroundColor: '#f9f9f9',
      width: '100%',
      '& .Mui-disabled': {
        color: 'black',
      },
    },
    header: {
      width: '100%',
    },
    foorter: {
      bottom: '0px',
      width: '100%',
      backgroundColor: '#ffffff',
      verticalAlign: 'middle',
      left: '0px',
      position: 'fixed',
      zIndex: 10,
      minHeight: '90px',
    },
    container: {
      marginBottom: '40px',
    },
    flexDisplay: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        placeContent: 'center',
        padding: '0 !important',
      },
    },
    reviewOrders: {
      display: 'inline',
      position: 'fixed',
      bottom: '100px',
      right: '30px',
    },
    fooContainer: {
      [theme.breakpoints.down('md')]: {
        padding: '0',
      },
    },
  },
));
const NewOrder = (props) => {
  const classes = useStyles();
  const {
    newOrder,
    roleBasedAuthorisation,
    history,
    viewAsUser:
    {
      email = '', firstname = '', lastname = '', id = '', phone = '',
    },
    actions: {
      openAlertWindow,
      onTabChange,
      updateCurrentData,
      saveCurrentData,
      onChange,
      updateDelegeeFlag,
    },
  } = props;

  const orderingUser = {
    portalUserId: id?.toString(),
    firstName: firstname,
    lastName: lastname,
    email,
    phone,
  };
  const panelDetails = newOrder && newOrder.toJS().panal;
  const orderManagement = newOrder && newOrder.toJS();
  const {
    schema: {
      order,
    },
    infoType,
    errors,
    cancerTypeList,
    clinicalHistory360,
    clinicalHistoryReveal
  } = orderManagement;
  const setNotify = (data) => {
    onChange({ name: ['notify'], value: { ...data } });
  };
  const openClinicalModal = (float) => {
    const {
      productFamily,
      relevantClinicalHistory,
    } = order;
    const status = getClinicalHistoryStatus(order, orderManagement?.panal?.tab, true, cancerTypeList);
    if (!float && status !== 'Not Started') {
      if ((productFamily?.id === 'REVEAL' && (!relevantClinicalHistory || (relevantClinicalHistory?.length !== clinicalHistoryReveal?.length)))
        || (productFamily?.id === 'G360' && (!relevantClinicalHistory || (relevantClinicalHistory?.length !== clinicalHistory360?.length)))) {
        props.actions.onChange({ name: ['showClinicalDialog'], value: true });
        props.actions.onChange({ name: ['isReviewPopUpOpen'], value: false });
        props.actions.onChange({ name: ['nextTabValue'], value: -1 });
        // eslint-disable-next-line no-param-reassign
        float = true;
      }
    }
    return float;
  };
  const handleSaveDiscard = (isSave) => {
    if (isSave === true) {
      const patientPhone = orderManagement?.schema?.order?.patient?.demographic?.phone;
      const patientEmail = orderManagement?.schema?.order?.patient?.demographic?.email;
      const patientZipCode = orderManagement?.schema?.order?.patient?.demographic?.address?.zipCode;
      const secondaryPhone = orderManagement?.schema?.order?.secondaryRecipient?.phone;
      const secondaryFax = orderManagement?.schema?.order?.secondaryRecipient?.fax;
      const tissuePhone = orderManagement?.schema?.order?.specimens?.tissueSpecimen?.phone;
      const tissueFax = orderManagement?.schema?.order?.specimens?.tissueSpecimen?.fax;
      /*
        ECS-8647: keep only valid items in secondaryIcdCodes array.
        Long term solution is to never let the data go wrong in the first place.
      */
      if(orderManagement.schema?.order?.diagnosis?.secondaryIcdCodes && orderManagement.schema.order.diagnosis.secondaryIcdCodes.length > 0){
        orderManagement.schema.order.diagnosis.secondaryIcdCodes = orderManagement.schema.order.diagnosis.secondaryIcdCodes.filter(i => i);
      }
      const data = {
        order: {
          ...orderManagement.schema.order,
          orderingUser,
          patient: {
            ...orderManagement?.schema?.order?.patient,
            demographic: {
              ...orderManagement?.schema?.order?.patient?.demographic,
              phone: patientPhone?.trim().length === 10 ? patientPhone : undefined,
              email: isEmail?.test(patientEmail) ? patientEmail : undefined,
              address: {
                ...orderManagement?.schema?.order?.patient?.demographic?.address,
                zipCode: patientZipCode?.trim().length === 5 ? patientZipCode : undefined,
              },
            },
          },
          diagnosis: {
            ...orderManagement?.schema?.order?.diagnosis,
            trfDisplayName: undefined,
          },
          secondaryRecipient: {
            ...orderManagement?.schema?.order?.secondaryRecipient,
            phone: secondaryPhone?.trim().length === 10 ? secondaryPhone : undefined,
            fax: secondaryFax?.trim().length === 10 ? secondaryFax : undefined,
          },
          specimens: orderManagement?.schema?.order?.specimens ? {
            ...orderManagement?.schema?.order?.specimens,
            tissueSpecimen: orderManagement?.schema?.order?.specimens?.tissueSpecimen ? {
              ...orderManagement?.schema?.order?.specimens?.tissueSpecimen,
              phone: tissuePhone?.trim().length === 10 ? tissuePhone : undefined,
              fax: tissueFax?.trim().length === 10 ? tissueFax : undefined,
            } : undefined,
          } : undefined,
        },
      };
      if (orderManagement.schema.order?.portalOrderId) {
        updateCurrentData(JSON.stringify(data), () => { window.location.pathname = '/new_orders'; });
      } else {
        saveCurrentData(JSON.stringify(data), () => { window.location.pathname = '/new_orders'; });
      }
    } else if (!isSave) {
      openAlertWindow(true);
    }
  };
  const validateStageType = (float) => {
    const stage = newOrder.getIn(schema.diagnosis.stage);
    const disease = getDisease(orderManagement.schema.order.diagnosis, orderManagement.cancerTypeList);
    const isReveal = allowReveal(stage, disease);
    const isG360 = allowG360(stage, disease);

    if (stage && disease) {
      if (!isReveal && !isG360) {
        if (!float) {
          props.actions.onChange({ name: ['showDailog'], value: true });
        }
        // eslint-disable-next-line no-param-reassign
        float = true;
      }
    }
    props.actions.onChange({ name: ['isReveal'], value: isReveal });
    props.actions.onChange({ name: ['isG360'], value: isG360 });
    return float;
  };
  const closeAndDiscardOrder = () => {
    const {
      portalOrderId = '',
    } = orderManagement.schema.order || {};

    if (portalOrderId) {
      const { productFamily, orderingUser: user } = orderManagement.schema.order || {};
      const productFamilyId = productFamily?.id && productFamily?.id;
      const params = {
        userId: user.portalUserId.toString(),
        physicianEmail: user.email,
        productFamilyId,
      };
      discardOrder(portalOrderId, params, history);
      openAlertWindow(false);
    } else {
      openAlertWindow(false);
      window.location.pathname = '/new_orders';
    }
  };
  const customValidation = (validSchema, identifier) => {
    const errors = {};
    const productFamily = newOrder.getIn(schema.testSelection.productFamily);
    let testOrdered = (newOrder.getIn(schema.testSelection.testOrdered) || []).filter(x => x !== 'REVEAL');
    testOrdered = testOrdered.toJS ? testOrdered.toJS() : testOrdered;
    const { noAdjuvantTherapy } = orderManagement?.schema?.order?.diagnosis || {};
    //const isAdjuvantTherapy = noAdjuvantTherapy === true || noAdjuvantTherapy === 'true';
    //const isnoAdjuvantTherapy = noAdjuvantTherapy === false || noAdjuvantTherapy === 'false';
    const { reorderType = {} } = order?.testOrdered || {};
    const { interval = {}, id: reorderTypeId = '' } = reorderType || {};
    const { id: intervalId = '', value: intervalValue = '' } = interval || {};

    const childDiseases = getChildDiseases(newOrder.getIn(schema.diagnosis.disease), orderManagement.cancerTypeList);

    if (identifier === 'clinicalHistory') {
      if (productFamily?.id === 'REVEAL') {
        errors.dateOfSurgicalResection = Validation('dateOfSurgicalResection', newOrder.getIn(schema.clinicalHistory.dateOfSurgicalResection), validSchema) ? 'Required' : '';
        //if (!(isAdjuvantTherapy || isnoAdjuvantTherapy)) {
        if (noAdjuvantTherapy === undefined || noAdjuvantTherapy === null) {
          errors.noAdjuvantTherapy = 'Required';
        }
        if (noAdjuvantTherapy === false) {
          errors.dateOfAdjuvantChemotherapy = Validation('dateOfAdjuvantChemotherapy', newOrder.getIn(schema.clinicalHistory.dateOfAdjuvantChemotherapy), validSchema) ? 'Required' : '';
        }
        if (childDiseases.length > 0) {
          const isLeaf = orderManagement.cancerTypeList
            .filter(i => i.diagnosis.toUpperCase() === newOrder.getIn(schema.diagnosis.disease).toUpperCase() && i.parentId).length === 1;
          if (!(isLeaf || newOrder.getIn(schema.clinicalHistory.molecularMarker))) {
            errors.subDiagnosis = 'Required';
          }
          if(isLeaf && newOrder.getIn(schema.diagnosis.disease).toUpperCase().indexOf("OTHER") === 0) {
            if(!newOrder.getIn(schema.diagnosis.diagnosisOther)) {
              errors.subDiagnosis = 'Required';
            }
          }
        }
      }
    } else {
      errors.productFamily = Validation('productFamily', productFamily, validSchema) || '';
      errors.testOrdered = Validation('testOrdered', testOrdered, validSchema) || '';
      if (productFamily?.id === 'G360') {
        errors.productFamily = Validation('productFamily', productFamily, validSchema) || '';
      }
    }
    if (productFamily?.id === 'REVEAL' && identifier === 'testSelection') {
      const preferenceForBloodDraw = (newOrder.getIn(schema.testSelection.preferenceForBloodDraw));
      errors.preferenceForBloodDraw = Validation('preferenceForBloodDraw', preferenceForBloodDraw, validSchema) || '';
      if (!reorderTypeId) {
        errors.reorderTypeId = 'Required';
      }
      if (reorderTypeId === 'S') {
        if (!intervalId) {
          errors.reorderTypeId = 'Required';
        }
        if (intervalId?.toUpperCase() === 'NMONTH') {
          errors.intervalValue = Validation('intervalValue', intervalValue, validSchema) || '';
        }
      }
    }
    Object.keys(errors).forEach((x) => {
      if (!errors[x] || errors[x]?.length === 0) {
        delete errors[x];
      }
    });
    return errors;
  };
  const handleSubmit = async () => {
    let errors = {};
    let float = false;

    const { identifier } = panelDetails.tabs[orderManagement.panal.tab];

    const validSchema = ValidationJson[identifier] || {};

    Object.keys(validSchema).forEach((key) => {
      if (schema[identifier][key]) {
        let value = newOrder.getIn(schema[identifier][key]);
        value = value?.toJS ? value.toJS() : value;
        let error = Validation(key, value, validSchema) || '';
        error = error.trim().length > 0 && validSchema[key].required ? 'Required' : '';
        if (error.trim().length > 0) {
          float = true;
          errors[key] = error;
        }
      }
    });
    if (identifier === 'testSelection' || identifier === 'clinicalHistory') {
      errors = customValidation(validSchema, identifier);
      float = Object.keys(errors).length > 0;
    }
    if (identifier === 'diagnosis') {
      const codes = [...(newOrder.getIn(schema.diagnosis.secondaryIcdCodes) || []), (newOrder.getIn(schema.diagnosis.primaryIcdCode) || {})].map(x => (x ? x.icdCode : '')).join();
      const disease = newOrder.getIn(schema.diagnosis.disease) || '';
      if (disease === 'Other' && !newOrder.getIn(schema.diagnosis?.diagnosisOther)) {
        let error = Validation('disease', undefined, validSchema) || '';
        error = error.trim().length > 0 && validSchema.disease.required ? 'Required' : '';
        if (error.trim().length > 0) {
          float = true;
          errors.disease = error;
        }
      }

      float = validateStageType(float);
      if (!float && disease !== 'Other') {
        await props.actions.ValidateIcdCodes(codes, disease).then((x) => {
          float = !x.data;
          if (!x.data) {
            props.actions.onChange({ name: ['icdMissMatch'], value: true });
            props.actions.onChange({ name: ['showDailog'], value: true });
          }
        });
      } else {
        props.actions.onChange({ name: ['icdMissMatch'], value: false });
      }
    }
    if (identifier === 'clinicalHistory') {
      float = openClinicalModal(float);
    }
    if (float) {
      onTabChange({ key: ['errors'], value: errors });
    } else {
      if (orderManagement.panal.tab < panelDetails.tabs.length - 1) {
        onTabChange({
          key: ['panal', 'tab'],
          value: Number(orderManagement.panal.tab) + 1,
        });

        const tabs = orderManagement.panal.tabs.map((t) => {
          if (t.identifier === identifier) {
            let optionLabel = '';

            try {
              if (Array.isArray(t.pathOps)) {
                t.pathOps.forEach((k) => {
                  optionLabel += ` ${newOrder.getIn(schema[t.identifier][k])}`;
                });
              } else {
                optionLabel += t.keyval ? newOrder.getIn(schema[t.identifier][t.pathOps])[t.key]
                  : newOrder.getIn(schema[t.identifier][t.pathOps]);
              }
            } catch (error) { optionLabel = ''; }
            return { ...t, optionLabel, content: ' Complete' };
          } if (panelDetails.tab + 1 === t.index) {
            return { ...t, content: t.content === ' Complete' ? ' Complete' : 'Current' };
          } return t;
        });
        onTabChange({
          key: ['panal', 'tabs'],
          value: tabs,
        });
      }
      if (panelDetails.tab === panelDetails.tabs.length - 1) {
        onChange({ name: ['isReviewOrderOpen'], value: true });
      }
    }
  };
  const openReviewModal = () => {
    const { identifier } = panelDetails.tabs[orderManagement.panal.tab];
    let float;
    if (identifier === 'clinicalHistory') {
      float = openClinicalModal(float);
      if (float) {
        onChange({ name: ['isReviewPopUpOpen'], value: true });
      }
    }
    if (!float) {
      onChange({ name: ['isReviewOrderOpen'], value: true });
    }
  };
  const renderTabs = () => {
    switch (panelDetails.tab) {
      case 0:
        return <PatientInfo {...props} />;
      case 1:
        return <Diagnosis {...props} />;
      case 2:
        return <TestSelection {...props} orderManagement={orderManagement} />;
      case 3:
        return <ClinicalHistory360 {...props} orderManagement={orderManagement} />;
      case 4:
        return <PhysicianInfo {...props} />;
      case 5:
        return <BillingInfo {...props} />;
      default:
        return '';
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const showTabs = [
    panelDetails.tab - 1,
    panelDetails.tab,
    panelDetails.tab + 1,
  ];
  const onTabChangeHandler = async (e, value) => {
    const { identifier } = panelDetails.tabs[orderManagement.panal.tab];
    const { primaryIcdCode } = orderManagement.schema.order.diagnosis || {};
    let float;
    if (value === 2) {
      float = validateStageType(float);
    }
    if (identifier === 'diagnosis' && value === 2) {
      const codes = [...(newOrder.getIn(schema.diagnosis.secondaryIcdCodes) || []), (newOrder.getIn(schema.diagnosis.primaryIcdCode) || {})].map(x => (x ? x.icdCode : '')).join();
      const disease = newOrder.getIn(schema.diagnosis.disease) || '';
      if (!float && disease !== 'Other' && primaryIcdCode !== undefined && primaryIcdCode !== null) {
        await props.actions.ValidateIcdCodes(codes, disease).then((x) => {
          float = !x.data;
          if (!x.data) {
            props.actions.onChange({ name: ['icdMissMatch'], value: true });
            props.actions.onChange({ name: ['showDailog'], value: true });
          }
        });
      } else {
        props.actions.onChange({ name: ['icdMissMatch'], value: false });
      }

      if (float) {
        onTabChange({ key: ['errors'], value: errors });
      } else if (orderManagement.panal.tab < panelDetails.tabs.length - 1) {
        onTabChange({
          key: ['panal', 'tab'],
          value: Number(orderManagement.panal.tab) + 1,
        });
      }
    } else if (identifier === 'clinicalHistory' && (value === 4 || value === 5) && openClinicalModal(undefined)) {
      onTabChange({ key: ['errors'], value: errors });
      if (value === 5) {
        props.actions.onChange({ name: ['nextTabValue'], value });
      }
    } else {
      onTabChange({
        key: ['panal', 'tab'],
        value,
      });
    }
  };

  const idenifyChange = () => {
    if (!orderManagement.formUpdated) {
      props.actions.onChange({ name: ['formUpdated'], value: true });
    }
  };
  useEffect(() => {
    validateStageType();
  }, [orderManagement.roOpenFromDashboard]);

  useEffect(() => {
    updateDelegeeFlag();
  }, []);

  const tabControl = (prevTab) => {
    let isTabDisabled = false;
    const { stage, disease, diagnosisOther } = orderManagement.schema.order.diagnosis || {};
    const { id: ProductFamilyId } = orderManagement.schema.order.productFamily || {};
    if (orderManagement.panal.tab === 2 || prevTab === 2) {
      let diseaseOther = 'exits';
      if (disease === 'Other' && !diagnosisOther) {
        diseaseOther = undefined;
      }
      isTabDisabled = (!(stage && disease && diseaseOther) && orderManagement.showDailog && !orderManagement.icdMissMatch);
    } else if (orderManagement.panal.tab === 3 || prevTab === 3) {
      isTabDisabled = !ProductFamilyId;
    }
    return isTabDisabled;
  };
  const getPreTab = (tabNum) => {
    while (tabControl(tabNum)) {
      // eslint-disable-next-line no-param-reassign
      tabNum -= 1;
    }
    return tabNum;
  };

  return (
    <Form id="newOrderContainer" className={classes.root} onChange={idenifyChange}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item xs={12} lg={10}>
          <div className="order-tabs">
            <Tabs
              value={panelDetails.tab}
              variant="fullWidth"
              onChange={onTabChangeHandler}
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
              className={classes.header}
            >
              {panelDetails.tabs.map((tab, index) => {
                const show = (isMobile && showTabs.includes(index)) || !isMobile;
                let isTabDisabled = false;
                const { id: ProductFamilyId } = orderManagement.schema.order.productFamily || {};
                const { name: testOrderedName } = orderManagement.schema.order.testOrdered || {};
                if (index === 2) {
                  const stage = newOrder.getIn(schema.diagnosis.stage);
                  const disease = getDisease({disease: newOrder.getIn(schema.diagnosis.disease), diagnosisOther: newOrder.getIn(schema.diagnosis.diagnosisOther)}, orderManagement.cancerTypeList);
                  /*
                  GH offers 2 broad categories of tests (aka product families); Reveal and G360.
                  Tests are available only for certain Diagnoses (combination of stage and cancer).
                  "Test Selection" tab is enabled iff
                  - diagnosis is valid
                  - GH test is available for the diagnosis
                   */
                  isTabDisabled = !(allowReveal(stage, disease) || allowG360(stage, disease));
                } else if (index === 3) {
                  isTabDisabled = !(ProductFamilyId && testOrderedName?.length !== 0);
                }
                return show ? (
                  <StyledTab
                    key={tab.label}
                    disabled={isTabDisabled}
                    label={(
                      <Box sx={{ width: '100%', textAlign: 'left', whiteSpace: 'nowrap' }}>
                        <TabStatus {...{
                          tab, order, index, panelDetails, infoType, errors, cancerTypeList
                        }}
                        />
                      </Box>
                    )}
                  />
                ) : null;
              })}
              )
            </Tabs>
            {renderTabs()}
          </div>
        </Grid>
        <div className={classes.reviewOrders}>
          <Button
            variant="outlined"
            startIcon={(
              <img
                alt="Guardant-Logo"
                height="20"
                width="20"
                src={Vector}
              />
            )}
            disabled={false}
            onClick={openReviewModal}
          >
            Review Order
          </Button>
        </div>
        <Grid item xs={12} className={classes.foorter}>
          <Grid
            container
            direction="row"
            spacing={isMobile ? 2 : 0}
            xs={12}
            className={classes.fooContainer}
          >
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => handleSaveDiscard(true)}
                  sx={{ mr: '10px' }}
                  disabled={!roleBasedAuthorisation.toJS().saveAndExit}
                >
                  Save and Exit
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleSaveDiscard(false)}
                  disabled={!roleBasedAuthorisation.toJS().discardAndExit}
                >
                  Discard and Exit
                </Button>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  style={{ marginRight: '12px' }}
                  disabled={orderManagement.panal.tab === 0}
                  onClick={() => orderManagement.panal.tab > 0
                    && onTabChange({
                      key: ['panal', 'tab'],
                      value: getPreTab(Number(orderManagement.panal.tab - 1)),
                    })
                  }
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={(orderManagement.panal.tab === 2
                    && orderManagement.isReveal === false
                    && orderManagement.isG360 === false)
                  }
                >
                  {orderManagement.panal.tab
                    === orderManagement.panal.tabs.length - 1
                    ? 'Finalize'
                    : 'Next'}
                </Button>
              </Box>
            </Toolbar>
          </Grid>
        </Grid>
      </Grid>
      <AlertModal discardOrder={closeAndDiscardOrder} />
      <ApplicationMessage notify={orderManagement.notify} setNotify={setNotify} />
      <ReviewOrderPopUp {...props} />
      <RchAlert {...props} />
    </Form>
  );
};

export default NewOrder;

NewOrder.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
  roleBasedAuthorisation: PropTypes.object.isRequired,
};
