const physicianActionTypes = {
  ADD_PHYSICIAN_DATA: 'ADD_PHYSICIAN_DATA',
};

const AddPhysicianInfo = (info, hideBanner) => ({
  type: physicianActionTypes.ADD_PHYSICIAN_DATA,
  info,
  hideBanner,
});

const resetOnlyPhysicianData = () => ({
  type: physicianActionTypes.ADD_PHYSICIAN_DATA,
  info: {
    physician: {
      lastname: '',
      firstname: '',
      email: '',
      phone: '',
      npi: '',
      orderingPhysician: '',
      id: '',
    },
  },
});

export { physicianActionTypes, AddPhysicianInfo, resetOnlyPhysicianData };
