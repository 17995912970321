import { useEffect } from 'react';
import { setupAuthInterceptor } from '../networking/interceptors/AuthInterceptor';

export const useAuthInterceptor = (authenticityToken) => {
  useEffect(() => {
    if (authenticityToken) {
      setupAuthInterceptor(authenticityToken);
    }
  }, [authenticityToken]);
};
