import React, { useState, useEffect } from 'react';
import {
  Toolbar,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import _ from 'loadsh';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ApplicationMessage from '../common/components/elements/ApplicationMessage';
import ConfirmModalWindow from '../common/components/elements/ConfirmModalWindow';
import ModalPopup from '../common/components/elements/ModalPopup';
import Pagination from '../common/components/elements/Table/Pagination';
import Controls from '../common/components/forms/controls';
import Table from '../common/Table';
import TableBody from '../common/TableBody';
import TableHeader from '../common/TableHeader';
import SitesForm from './SitesForm';

const useStyles = makeStyles(() => createStyles({
  root: {
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#ffffff',
  },
  searchInput: {
    width: '50%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#1A5EA7',
    boxShadow: 'none',
    '@media (max-width: 445px)': {
      width: '40%',
      height: '75%',
    },
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '20px',
    marginLeft: '10px',
  },
}));
const IPropTypes = {
  sites: PropTypes.object,
  accounts: PropTypes.object,
  roles: PropTypes.array.isRequired,
  actions: PropTypes.object,
};
const defaultProps = {
  sites: Immutable.fromJS({}),
  accounts: {},
  actions: null,
};
const SitesView = (props) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => {},
  });
  const [formData, setFormData] = useState(null);
  const [title, setTitle] = useState('');
  const classes = useStyles();
  const {
    sites,
    accounts,
    roles,
    actions: {
      addSitesData,
      changePagination,
      deleteSitesData,
      editSitesData,
      getSitesData,
      onChange,
      getAccountsData,
      accountOnChange,
    },
  } = props;
  const sitesList = sites.toJS().sitesList || [];
  const notify = sites.toJS().notify || {};
  const pagination = sites.toJS().pagination || 5;
  const sitesManagement = sites.toJS() || {};
  const accountManagement = (accounts && accounts.toJS()) || {};
  const headers = [
    { name: 'name', type: 'text' },
    { name: 'phone', type: 'text' },
    { name: 'country', type: 'text' },
    { name: 'accountNumber', type: 'text' },
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (sitesList.length === 0 || sitesManagement.reload) {
      setConfirmDialog({
        isOpen: false,
        title: '',
        subTitle: '',
        onConfirm: () => {},
      });
      getSitesData(pagination);
      onChange({ key: 'reload', value: false });
      // eslint-disable-next-line no-unused-expressions
      sitesManagement.reload && setFormData(null);
    }
  }, [sitesManagement.reload]);
  useEffect(() => {
    if (sitesManagement.isOpen) {
      setConfirmDialog({
        isOpen: false,
        title: '',
        subTitle: '',
        onConfirm: () => {},
      });
      onChange({ key: 'isOpen', value: false });
    }
    if (sitesList.length === 0 && sitesManagement.totalRecords !== 0) {
      getSitesData(pagination);
    }
  }, [sitesManagement.isOpen, pagination]);

  const setNotify = (data) => {
    onChange({ key: 'notify', value: { ...data } });
  };
  const addClickHandler = (e) => {
    e.preventDefault();
    getAccountsData({});
    onChange({ key: 'openPopup', value: true });
    setFormData(null);
    setTitle('Add Site');
  };
  const editClickHandler = (row) => {
    getAccountsData({});
    onChange({ key: 'openPopup', value: true });
    const {
      id, name, phone, accountNumber, country,
    } = row;
    setFormData({
      id,
      name,
      phone,
      accountNumber,
      country,
    });
    setTitle('Edit Site');
  };

  const deleteClickHandler = (row) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Are you sure to delete this record',
      subTitle: '',
      onConfirm: () => {
        deleteSitesData(row);
        accountOnChange({ key: 'reload', value: true });
        setConfirmDialog({
          isOpen: false,
          title: '',
          subTitle: '',
          onConfirm: () => {},
        });
      },
    });
  };

  const submitHandler = (values, resetForm, edit) => {
    accountOnChange({ key: 'reload', value: true });
    if (edit === true) editSitesData(values);
    else addSitesData(values);
  };
  const delayedQuery = _.debounce(q => getSitesData(q), 500);
  const handleSearch = (e) => {
    if (e.target.value !== '') {
      delayedQuery(
        `name:contains=${e.target.value}&${new URLSearchParams(
          pagination,
        ).toString()}`,
      );
    } else {
      delayedQuery(`${new URLSearchParams(pagination).toString()}`);
    }
  };
  const userPermission = {
    Edit: roles && roles.includes('UPDATE'),
    Delete: roles && roles.includes('DELETE'),
  };
  const actions = [
    { action: 'Edit', handleAction: editClickHandler },
    { action: 'Delete', handleAction: deleteClickHandler },
  ];
  const handlePagination = (e, pageNum) => {
    e.preventDefault();
    getSitesData(pageNum);
    changePagination(pageNum);
  };
  return (
    <React.Fragment>
      <div className={classes.title}>Sites</div>
      <div id="sitesContainer" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Controls.Input
            label="Search Sites"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text="Add Site"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={addClickHandler}
            disabled={!roles.includes('CREATE')}
          />
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <Table>
              {!isMobile && (
              // eslint-disable-next-line max-len
              <TableHeader
                headers={[...headers]}
                {...{ rows: sitesManagement.sitesList || [], actions }}
              />
              )}
              {/* eslint-disable-next-line max-len */}
              <TableBody
                headers={headers}
                {...{
                  rows: sitesManagement.sitesList || [],
                  actions,
                  userPermission,
                }}
              />
            </Table>
            <Pagination
              count={sitesManagement.totalRecords}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNo}
              handleChangePage={(e, val) => handlePagination(e, { ...pagination, pageNo: val })
            }
              handleChangeRowsPerPage={e => handlePagination(e, {
                ...pagination,
                pageSize: e.target.value,
                pageNo: 0,
              })
            }
            />
          </Grid>
        </Grid>
        <ModalPopup
          title={title}
          openPopup={sitesManagement.openPopup}
          setOpenPopup={val => onChange({ key: 'openPopup', value: val })}
        >
          <SitesForm
            formData={formData}
            submitHandler={submitHandler}
            edit={title === 'Edit Site'}
            getAccountsData={getAccountsData}
            accountOnChange={accountOnChange}
            accountList={accountManagement.accontsList}
          />
        </ModalPopup>
        <ConfirmModalWindow
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <ApplicationMessage notify={notify} setNotify={setNotify} />
      </div>
    </React.Fragment>
  );
};

export default SitesView;
SitesView.propTypes = IPropTypes;
SitesView.defaultProps = defaultProps;
