export const REQUESTS_COLUMNS = {
  PDF: 'pdf',
  REPORT_STATUS: 'reportStatus',
  PATIENT_NAME: 'patientName',
  DATE_OF_BIRTH: 'dateOfBirth',
  CANCER_TYPE: 'cancerType',
  REQUEST_ID: 'requestId',
  PARENT_REQUEST_ID: 'parentRequestId',
  SAMPLE_TYPE: 'sampleType',
  TEST_TYPE: 'testType',
  RECEIVED_DATE: 'receivedDate',
  PHYSICIAN_NAME: 'physicianName',
  PRACTICE_NAME: 'practiceName',
};

const sortReportStatus = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * (a.reportDate - b.reportDate)
));

const sortPatientName = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * a.patientName.localeCompare(b.patientName)
));

const sortDateOfBirth = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * (a.dateOfBirth - b.dateOfBirth)
));

const sortCancerType = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * a.cancerType.localeCompare(b.cancerType)
));

const sortRequestId = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * a.requestId.localeCompare(b.requestId)
));

const sortParentRequestId = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * a.parentRequestId.localeCompare(b.parentRequestId)
));

const sortSampleType = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * a.sampleType.localeCompare(b.sampleType)
));

const sortTestType = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * a.testType.localeCompare(b.testType)
));

const sortReceivedDate = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * (a.receivedDate - b.receivedDate)
));

const sortPhysicianName = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * a.physicianName.localeCompare(b.physicianName)
));

const sortPracticeName = (requests, isOrderAsc) => requests.sort((a, b) => (
  (isOrderAsc ? 1 : -1) * a.practiceName.localeCompare(b.practiceName)
));


export const sortRequests = (requests, column, isOrderAsc) => {
  switch (column) {
    case REQUESTS_COLUMNS.REPORT_STATUS:
      return sortReportStatus(requests, isOrderAsc);
    case REQUESTS_COLUMNS.PATIENT_NAME:
      return sortPatientName(requests, isOrderAsc);
    case REQUESTS_COLUMNS.DATE_OF_BIRTH:
      return sortDateOfBirth(requests, isOrderAsc);
    case REQUESTS_COLUMNS.CANCER_TYPE:
      return sortCancerType(requests, isOrderAsc);
    case REQUESTS_COLUMNS.REQUEST_ID:
      return sortRequestId(requests, isOrderAsc);
    case REQUESTS_COLUMNS.PARENT_REQUEST_ID:
      return sortParentRequestId(requests, isOrderAsc);
    case REQUESTS_COLUMNS.SAMPLE_TYPE:
      return sortSampleType(requests, isOrderAsc);
    case REQUESTS_COLUMNS.TEST_TYPE:
      return sortTestType(requests, isOrderAsc);
    case REQUESTS_COLUMNS.RECEIVED_DATE:
      return sortReceivedDate(requests, isOrderAsc);
    case REQUESTS_COLUMNS.PHYSICIAN_NAME:
      return sortPhysicianName(requests, isOrderAsc);
    case REQUESTS_COLUMNS.PRACTICE_NAME:
      return sortPracticeName(requests, isOrderAsc);
    default:
      return requests;
  }
};
