export const updateBubblesAmounts = (amounts, statusToSubtract, statusToAdd) => {
  const newAmounts = amounts.map(e => ({ ...e }));

  const alterAmount = (status, variant) => {
    newAmounts.find(e => e.status === status).count += variant;
  };

  if (statusToSubtract) {
    alterAmount(statusToSubtract, -1);
  }

  if (statusToAdd) {
    alterAmount(statusToAdd, 1);
  }

  return newAmounts;
};
