import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { discardOrder as discardOrderAction } from '../../../store/actions/orders/active-order/order';
import { toggleConfirmationModalAction } from '../../../store/actions/orders/active-order/confirmation_modal';
import { hideErrorBanner as hideErrorBannerAction } from '../../../store/actions/orders/active-order/form_errors';
import { updateLoadingCount } from '../../../store/actions/global';
import { DiscardOverlay } from './discard-overlay';

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleConfirmationModal: toggleConfirmationModalAction,
  hideErrorBanner: hideErrorBannerAction,
  discardOrder: discardOrderAction,
  updateLoadingCount,
}, dispatch);

const ConnectedDiscardOverlay = connect(
  null,
  mapDispatchToProps,
)(withRouter(DiscardOverlay));

export { ConnectedDiscardOverlay as DiscardOverlay };
