import { ReleaseToPatient } from './release-to-patient';

class Alert {
  constructor(params) {
    this.autoReleaseDate = new Date(params.autoReleaseDate);
    this.unreleasedReportsForPatient = params.unreleasedReportsForPatient;
    this.autoReleaseDateTimeAgo = params.autoReleaseDateTimeAgo;
    this.latestReleasedReportDateTimeAgo = params.latestReleasedReportDateTimeAgo;
    this.showAlert = params.showAlert;
    this.releaseToPatient = params.releaseToPatient
      && new ReleaseToPatient(params.releaseToPatient);
  }
}

export { Alert };
