import { constants } from '../../../../config/constants';

const TOGGLE_MODAL = 'TOGGLE_MODAL';
const UPDATE_MODAL_INFO = 'UPDATE_MODAL_INFO';

const {
  failureMessage,
  successMessageWithoutElectronicSignature,
  successMessageWithElectronicSignature,
  failureSubMessage,
  successSubMessageWithoutElectronicSignature,
  successSubMessageWithElectronicSignature,
  footerWarningWithElectronicSignature,
  footerWarningWithoutElectronicSignature,
} = constants.printModal;

const updateModalInfo = (info, hideBanner) => ({
  type: UPDATE_MODAL_INFO,
  info,
  hideBanner,
});

const updateSummaryModalInfo = ({ order }) => (dispatch, getState) => {
  const { global: { initialProps: { userPermissions: { eSignatureEnabled } } } } = getState();

  const summary = order.buildFormSummaryForPrintModal();
  const warningStatus = (summary || []).some(e => !e.isCompleted);

  const successMessage = eSignatureEnabled
    ? successMessageWithElectronicSignature
    : successMessageWithoutElectronicSignature[order.productFamily];

  const successSubMessage = eSignatureEnabled
    ? successSubMessageWithElectronicSignature
    : successSubMessageWithoutElectronicSignature;

  const footerMessage = eSignatureEnabled
    ? footerWarningWithElectronicSignature
    : footerWarningWithoutElectronicSignature;

  dispatch({
    type: UPDATE_MODAL_INFO,
    info: {
      alreadyPrinted: false,
      printOrderText: 'PRINT NOW',
      finalizeOrderText: 'Finalize order',
      warningStatus,
      informationMessage: warningStatus ? failureMessage : successMessage,
      informationSubMessage: warningStatus ? failureSubMessage : successSubMessage,
      footer: {
        message: footerMessage,
        isAWarning: true,
      },
      commentsText: '',
      summary,
      productFamily: order.productFamily,
      order,
    },
  });
};

const updateCompletedOrderModalInfo = ({ order, finalizeOrderText }) => {
  const printDate = order.formatPrintDateForModal();
  const { latestPrintDate } = order.metadata;

  return {
    type: UPDATE_MODAL_INFO,
    info: {
      alreadyPrinted: true,
      printOrderText: printDate || latestPrintDate ? 'PRINT AGAIN' : 'PRINT NOW',
      finalizeOrderText,
      informationMessage: 'This is a summary of a previous test requisition',
      informationSubMessage:
        'None of the information below can be modified, but you may print a new copy of the paper form.',
      footer: {
        message: printDate && `Note: this test requisition form was originally printed on ${printDate}.`,
        isAWarning: false,
      },
      commentsText: order.comments || '',
      specimenCollectionDate: order.bloodSpecimen.collectionDate || '',
      labClinicianText: order.bloodSpecimen.labClinicianName || '',
      summary: order.buildFormSummaryForPrintModal(),
      productFamily: order.productFamily,
      order,
    },
  };
};

const toggleModalAction = () => ({
  type: TOGGLE_MODAL,
});

export {
  TOGGLE_MODAL,
  UPDATE_MODAL_INFO,
  updateModalInfo,
  updateSummaryModalInfo,
  updateCompletedOrderModalInfo,
  toggleModalAction,
};
