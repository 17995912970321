import { styled, Tab } from '@mui/material';
import React from 'react';

export const StyledTab = styled(props => (
  <Tab
    disableRipple
    wrapper={false}
    wrapped={false}
    disabled={props.disabled}
    {...props}
  >
    {props.label}
  </Tab>
))(() => ({
  width: '240px',
  height: '120px',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  backgroundColor: '#ffffff',
  border: '2px solid #f9f9f9 !important',
  color: '#005DB2',
  padding: '10px 16px',
  flexDirection: 'row',
  textTransform: 'capitalize',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  '&.MuiTab-root': {
    '&:hover': {
      color: '#005DB2',
      backgroundColor: '#E4F3FF',
      borderColor: '#4091CB !important',
    },
  },
  '&p': {
    padding: '2%',
    border: '1px solid',
    borderRadius: '50%',
    height: '31px',
  },
  '&.Mui-selected': {
    color: '#005DB2',
    border: '2px solid #0085f9',
    backgroundColor: '#E4F3FF',
    borderColor: '#4091CB !important',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
  '&.Mui-disabled': {
    opacity: 1,
    backgroundColor: '#FFFFFF !important',
  },
}));
