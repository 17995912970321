class StoreHelper {
  static formatDate(data) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
      'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    let currentDate = null;
    if (data) {
      currentDate = data.split('/');
      return `${currentDate[1]}-${months[parseInt(currentDate[0], 10) - 1]}-${currentDate[2]}`;
    }
    return currentDate;
  }

  static formatPhysician(firstname, lastname) {
    if (!firstname && !lastname) return null;
    return `Dr. ${firstname} ${lastname}`;
  }

  static formatAddress(street, street2, city, state, country, zipcode) {
    if (!street && !street2 && !city && !state && !country && !zipcode) return null;
    return `${street || ''} ${street2 || ''} - ${city || ''}, ${state || ''} ${zipcode || ''} - ${country || ''}`;
  }

  static formatLastFirstName(lastname, firstname) {
    if (!lastname && !firstname) return null;
    return `${lastname}, ${firstname}`;
  }
}

export { StoreHelper };
