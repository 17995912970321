import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveBillingInfo } from '../../../../store/actions/orders/active-order/billing-info';
import { PrivateInsurance } from './private-insurance';

const mapDispatchToProps = dispatch => bindActionCreators({ saveBillingInfo }, dispatch);

const mapStateToProps = ({
  orders: { activeOrder: { billingInfo } },
}) => ({ billingInfo });

const ConnectedPrivateInsurance = connect(
  mapStateToProps, mapDispatchToProps,
)(PrivateInsurance);

export { ConnectedPrivateInsurance as PrivateInsurance };
