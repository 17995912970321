import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isInternalUser } from '../../../networking/controllers/helper';
import { config } from '../../config/config';
import { API_GATEWAY_URL, USER_MGMT_V2_PATH } from '../../config';

const IPropTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};
const defaultProps = {
  showModal: false,
};

const PasswordRestModal = (props) => {
  const { showModal, closeModal } = props;
  const [state, setState] = useState({
    currentPassword: '',
    newPassword: '',
    currentPassError: '',
    newPassError: '',
    displayMessage: false,
  });
  const validate = (fieldValue = state, submit = false) => {
    if (submit === true) {
      if (
        fieldValue.newPassword.length === 0
        || fieldValue.currentPassword.length === 0
      ) {
        setState(previous => ({
          ...previous,
          newPassError:
            fieldValue.newPassword.length === 0
              ? 'Please fill the new password'
              : previous.newPassError,
          currentPassError:
            fieldValue.currentPassword.length === 0
              ? 'Please fill the current password'
              : '',
        }));
      }
    }
    if (submit === false && fieldValue.newPassword.length === 0) {
      setState(previous => ({ ...previous, newPassError: '' }));
      return;
    }
    const isPassword = /(?=[A-Za-z0-9]+)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,}).*$/g;
    const samePass = 'New password can not be same as that of current password';
    if (submit === false && !isPassword.test(fieldValue.newPassword)) {
      setState(previous => ({
        ...previous,
        newPassError:
          fieldValue.newPassword !== state.currentPassword
            ? 'Your password do not meet requirements'
            : samePass,
      }));
    } else if (submit === false) {
      setState(previous => ({
        ...previous,
        newPassError:
          fieldValue.newPassword !== state.currentPassword ? '' : samePass,
      }));
    }
  };
  const submitHandler = async (fieldValues = state) => {
    validate(undefined);
    if (
      state.currentPassword.length !== 0
      && state.newPassError === ''
      && state.newPassword.length !== 0
    ) {
      window.onbeforeunload();
      const { accessToken } = JSON.parse(
        localStorage.getItem('okta-token-storage'),
      ).accessToken;
      const data = JSON.stringify({
        oldPassword: `${fieldValues.currentPassword}`,
        newPassword: `${fieldValues.newPassword}`,
      });
      const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
      const axiosConfig = {
        method: 'put',
        url: `${config()[API_GATEWAY_URL]}${config()[USER_MGMT_V2_PATH]}/users/me/change_password`,
        headers: {
          Accept: '*',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${accessToken}`,
          ...extHeader,
        },
        data,
      };
      await axios(axiosConfig)
        .then(() => {
          window.hideSpinner();
          setState(previous => ({
            ...previous,
            currentPassword: '',
            newPassword: '',
            displayMessage: true,
          }));
          closeModal('passwordReset');
          // eslint-disable-next-line no-alert
          alert('Password updated successfully!');
        })
        .catch((error) => {
          const errorMsg = error.response.data.errors[0].message;
          setState(previous => ({ ...previous, currentPassError: errorMsg }));
          window.hideSpinner();
        });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState(previous => ({
      ...previous,
      currentPassError:
          previous.currentPassword.length !== 0 ? '' : previous.currentPassError,
      [name]: value,
    }));
    validate({ [name]: value });
  };

  return (
    <>
      {showModal && (
        <div className="modal__container-outer modal__patient edit__password">
          <div className="modal__container-inner">
            <div className="modal__content">
              <div className="patient__settings-edit patient__settings-edit__password">
                <div className="settings-edit__container">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="myguardant-form">Reset your password</label>
                  <p className="help-block">
                    Use this form to choose a new password to use when you sign
                    in to myGuardant
                  </p>
                  <div className="content">
                    {state.currentPassError !== '' && (
                      <div className="password__error-msg">
                        {state.currentPassError}
                      </div>
                    )}

                    <div className="width__49percent__left">
                      <input
                        required
                        className="input__password1"
                        id="input__password1"
                        placeholder="Current Password"
                        type="password"
                        name="currentPassword"
                        value={state.currentPassword}
                        onChange={handleInputChange}
                      />
                      {state.newPassError !== '' && (
                        <div className="password__error-msg">
                          {state.newPassError}
                        </div>
                      )}
                      <input
                        required
                        className="input__password2"
                        id="input__password2"
                        placeholder="New Password"
                        type="password"
                        name="newPassword"
                        value={state.newPassword}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="width__49percent__right">
                      <div className="tooltip__inline-block">
                        <ul>
                          <li>at least 12 characters</li>
                          <li>at least one UPPERCASE</li>
                          <li>at least one lowercase</li>
                          <li>at least one number (123)</li>
                          <li>no parts of your username</li>
                          <li>your password cannot be any of your last 4 passwords</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-row">
                    <div
                      className="button button-clear-background close__edit__password"
                      onClick={() => {
                        closeModal('passwordReset');
                        setState(previous => ({
                          ...previous,
                          currentPassword: '',
                          newPassword: '',
                          currentPassError: '',
                          newPassError: '',
                        }));
                      }}
                    >
                      CANCEL
                    </div>
                    <div
                      className="button form-submit__btn save__edit__password"
                      onClick={() => {
                        submitHandler();
                      }}
                    >
                      RESET PASSWORD
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PasswordRestModal;
PasswordRestModal.propTypes = IPropTypes;
PasswordRestModal.defaultProps = defaultProps;
