import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { getUserInfo } from '../../helpers';
import * as actionTypes from '../actions/actionTypes';

export const DEFAULT_ORDER_DASHBOARD_STATE = fromJS({
  completedOrders: null,
  pendingOrders: null,
  pendingESignatureOrders: null,
  totalPendingOrders: null,
  totalCompletedOrders: null,
  totalESignPendingOrders: null,
  isPendingOrdersLoaded: false,
  isCompletedOrdersLoaded: false,
  isPendingSignOrdersLoaded: false,
  errors: false,
  errorMessage: [],
  dashboardInitialLoad: true,
  selectedTab: '2',
  roleBasedAuthorisation: {
    ordersTab: true,
    editOrderButton: true,
    ordersDashboardTable: {
      toBeCompleted: true,
      pendingESignature: true,
      completed: true,
    },
    searchBox: true,
    filterTable: true,
    reviewModelEditOptions: true,
    reviewModelPrintOption: true,
    reviewModelESignButton: true,
    saveAndExit: true,
    discardAndExit: true,
    finalizeOrder: true,
    patientNameClickable: true,
    download: true,
    dobFlag: true,
    isSuperGHAdmin: false,
  },
});

const getPendingSigOrdersCount = (arr = []) => arr.reduce((acc, curr) => (curr.name === 'PENDINGSIGNATURE' ? acc + curr.count : acc), 0);
const getPendingOrdersCount = (arr = []) => arr.reduce((acc, curr) => (curr.name === 'INCOMPLETE' ? acc + curr.count : acc), 0);
const getCompleteOrdersCount = (arr = []) => arr.reduce((acc, curr) => ((curr.name === 'PRINTED' || curr.name === 'SIGNED') ? acc + curr.count : acc), 0);
const getFlagStatusByRole = (state, ds) => {
  const userInfo = getUserInfo();
  const { authorities } = userInfo.accessInfo;
  const { roles } = authorities;
  let updatePermissions;
  if (roles['Super GH Admin'] || roles['Client Services GH Admin'] || roles['Standard GH Admin'] || roles['Portal Dev GH Admin']) {
    updatePermissions = fromJS({
      ordersTab: true,
      editOrderButton: true,
      ordersDashboardTable: {
        toBeCompleted: true,
        pendingESignature: true,
        completed: true,
      },
      searchBox: true,
      filterTable: true,
      reviewModelEditOptions: false,
      reviewModelPrintOption: true,
      reviewModelESignButton: false,
      saveAndExit: false,
      discardAndExit: false,
      finalizeOrder: false,
      patientNameClickable: true,
      download: true,
      dobFlag: true,
      isSuperGHAdmin: true,
    });
  } else if (roles['Sales GH Admin']) {
    updatePermissions = fromJS({
      ordersTab: true,
      editOrderButton: true,
      ordersDashboardTable: {
        toBeCompleted: true,
        pendingESignature: true,
        completed: true,
      },
      searchBox: false,
      filterTable: true,
      reviewModelEditOptions: false,
      reviewModelPrintOption: false,
      reviewModelESignButton: false,
      saveAndExit: false,
      discardAndExit: false,
      finalizeOrder: false,
      patientNameClickable: false,
      download: false,
      dobFlag: false,
      isSuperGHAdmin: false,
    });
  } else {
    updatePermissions = fromJS({
      ordersTab: true,
      editOrderButton: true,
      ordersDashboardTable: {
        toBeCompleted: true,
        pendingESignature: true,
        completed: true,
      },
      searchBox: true,
      filterTable: true,
      reviewModelEditOptions: true,
      reviewModelPrintOption: true,
      reviewModelESignButton: true,
      saveAndExit: true,
      discardAndExit: true,
      finalizeOrder: true,
      patientNameClickable: true,
      download: true,
      dobFlag: true,
      isSuperGHAdmin: false,
    });
  }
  return updatePermissions;
};

const OrdersDashboardReducer = handleActions(
  {
    [actionTypes.LOAD_COMPLETED_ORDERS]: (state, action) => state
      .set('completedOrders', action.payload.pageData.list)
      .set('totalCompletedOrders', getCompleteOrdersCount(action.payload.groupByData))
      .set('totalESignPendingOrders', getPendingSigOrdersCount(action.payload.groupByData))
      .set('totalPendingOrders', getPendingOrdersCount(action.payload.groupByData))
      .set('isCompletedOrdersLoaded', true),
    [actionTypes.LOAD_PENDING_ORDERS]: (state, action) => state
      .set('pendingOrders', action.payload.pageData.list)
      .set('totalCompletedOrders', getCompleteOrdersCount(action.payload.groupByData))
      .set('totalESignPendingOrders', getPendingSigOrdersCount(action.payload.groupByData))
      .set('totalPendingOrders', getPendingOrdersCount(action.payload.groupByData))
      .set('isPendingOrdersLoaded', true),
    [actionTypes.LOAD_PENDING_SIGNATURE_ORDERS]: (state, action) => state
      .set('pendingESignatureOrders', action.payload.pageData.list)
      .set('totalCompletedOrders', getCompleteOrdersCount(action.payload.groupByData))
      .set('totalESignPendingOrders', getPendingSigOrdersCount(action.payload.groupByData))
      .set('totalPendingOrders', getPendingOrdersCount(action.payload.groupByData))
      .set('isPendingSignOrdersLoaded', true),
    [actionTypes.ORDERS_SELECTED_TAB]: (state, action) => state.set('selectedTab', action.payload)
      .set('errorMessage', ['Failed to load Completed orders']),
    [actionTypes.ORDERS_DASHBOARD_INITIAL_LOAD]: (state, action) => state.set('dashboardInitialLoad', action.payload)
      .set('errorMessage', ['Failed to load Completed orders']),
    [actionTypes.EDIT_ORDER]: state => state.set('dashboardInitialLoad', true),
    [actionTypes.LOAD_COMPLETED_ORDERS_ERROR]: state => state.set('errors', true)
      .set('errorMessage', ['Failed to load Completed orders']),
    [actionTypes.LOAD_PENDING_ORDERS_ERROR]: state => state.set('errors', true)
      .set('errorMessage', ['Failed to load To Be Completed orders']),
    [actionTypes.LOAD_PENDING_SIGNATURE_ORDERS_ERROR]: state => state.set('errors', true)
      .set('errorMessage', ['Failed to load Pending E-Signature orders']),
    [actionTypes.ORDERS_SEARCH_ERROR]: state => state.set('errors', true)
      .set('errorMessage', ['Failed to load Search orders']),
    [actionTypes.CLOSE_BANNER_MESSAGE]: state => state.set('errors', false)
      .set('errorMessage', ''),
    [actionTypes.DOWNLOAD_ETRF_PDF_ERROR]: state => state.set('errors', true)
      .set('errorMessage', ['Failed to download ETRF order']),
    [actionTypes.ORDERS_DASHBOARD_ROLE_BASED_AUTH]: (state, action) => state
      .set('roleBasedAuthorisation', getFlagStatusByRole(state, action.payload)),
  }, DEFAULT_ORDER_DASHBOARD_STATE,
);

export default OrdersDashboardReducer;
