import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'loadsh';
import moment from 'moment';
import PropTypes from 'prop-types';
import Controls from '../../admin/common/components/forms/controls';
import { schema } from '../schema';
import ValidationJson from '../validate.json';
import Validation from '../../admin/common/utils/Validation';
import { filterICDcodes } from '../utils/helper';
import DiagnosisAutoComplete from '../common/components/DiagnosisAutoComplete';
import Alert from '../utils/Alert';
import AlertMessage from '../alert-modal/AlertMessage';
import {getRootDisease} from '../helpers';

const useStyles = makeStyles(() => ({
  root: {
  },
  indent: {
    marginLeft: '10px',
  },
  errorBox: {
    padding: '10px'
  },
  helperText: {
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    'overflow-y': 'clip'
  }
}));

function Diagnosis(props) {
  const classes = useStyles();
  const { newOrder } = props;
  const [showMessage, setShowMessage] = useState(false);
  const orderManagement = newOrder && newOrder.toJS();
  const closeMessage = () => {
    setShowMessage(false);
  };
  const onInputChange = (e, i) => {
    const { name, type } = e.target;
    let { value } = e.target;
    const { diagnosis } = ValidationJson;
    const { id: ProductFamilyId } = orderManagement.schema.order.productFamily || {};
    if (name === 'diagnosisOther') {
      if (value.indexOf(' ') === 0) {
          value = value.trim();
      }
      value = value.replace(/\s{2,}/g, ' ');
    }
    if (ProductFamilyId && (type === 'radio' || type === 'checkbox') && (name === 'stage' || name === 'disease')) {
      setShowMessage(true);
      props.actions.onChange({ name: schema.testSelection.productFamily, value: {} });
      props.actions.onChange({ name: schema.testSelection.followOnTestOrdered, value: undefined });
      props.actions.onChange({ name: schema.testSelection.testOrdered, value: [] });
    }
    if (name === 'stage' || name === 'disease') {
      props.actions.onChange({ name: schema.testSelection.reorderType, value: undefined });
      props.actions.onChange({ name: schema.testSelection.preferenceForBloodDraw, value: undefined });
      props.actions.onChange({ name: schema.diagnosis.noAdjuvantTherapy, value: undefined });
      props.actions.onChange({ name: schema.clinicalHistory.molecularMarker, value: undefined });
      props.actions.onChange({ name: schema.clinicalHistory.dateOfAdjuvantChemotherapy, value: undefined });
      props.actions.setError({ name: 'subDiagnosis', value: undefined });
      props.actions.setError({ name: 'diagnosisOther', value: undefined });
      props.actions.setError({ name: 'dateOfSurgicalResection', value: undefined });
      props.actions.setError({ name: 'noAdjuvantTherapy', value: undefined });
      props.actions.setError({ name: 'dateOfAdjuvantChemotherapy', value: undefined });
      props.actions.setError({ name: 'testOrdered', value: undefined });
      props.actions.setError({ name: 'reorderType', value: undefined });
      props.actions.setError({ name: 'reorderTypeId', value: undefined });
      props.actions.setError({ name: 'intervalValue', value: undefined });
      props.actions.setError({ name: 'preferenceForBloodDraw', value: undefined });
      if(name === 'disease'){
        props.actions.onChange({ name: schema.diagnosis.diagnosisOther, value: undefined });
      }
    }
    if (name === 'primaryIcdCode') {
      value = value && {
        description: value.description,
        icdCode: value.icdCode,
      };
    }
    if (ValidationJson.diagnosis[name] && Number(i)) {
      if (Number(i)) {
        diagnosis[name].index = i;
        diagnosis[name].property = 'icdCode';
      }
      let error = Validation(name, value, diagnosis) || '';
      error = error.trim().length > 0 ? 'Required' : '';
      props.actions.setError({
        name: name + (i.toString() || ''),
        value: error,
      });
    } else if (ValidationJson.diagnosis[name]) {
      let errortest = Validation(name, value, diagnosis) || '';
      if (name !== 'dateOfOriginalDiagnosis') {
        errortest = (errortest || '').trim().length > 0 ? 'Required' : '';
      }
      props.actions.setError({ name, value: errortest });
    }
    if (name === 'stage') {
      props.actions.onChange({ name: schema.diagnosis.stage, value: value });
      const rootDisease = getRootDisease(newOrder.getIn(schema.diagnosis.disease), orderManagement.cancerTypeList)?.diagnosis;
      if(rootDisease !== newOrder.getIn(schema.diagnosis.disease)){
        props.actions.onChange({ name: schema.diagnosis.disease, value: rootDisease });
        props.actions.onChange({ name: schema.diagnosis.diagnosisOther, value: undefined });
      }
    }

    if (name === 'secondaryIcdCodes') {
      const arr = newOrder.getIn(schema.diagnosis.secondaryIcdCodes)
        ? [...newOrder.getIn(schema.diagnosis.secondaryIcdCodes)]
        : [];
      arr[i] = value
        ? { description: value.description, icdCode: value.icdCode }
        : undefined;
      props.actions.onChange({ name: schema.diagnosis[name], value: arr });
    } else props.actions.onChange({ name: schema.diagnosis[name], value });
  };

  useEffect(() => {
    if (!orderManagement.cancerTypeList) {
      props.actions.getAllcancer();
    }
  }, [orderManagement.cancerTypeList]);
  const handleSearch = useCallback(
    _.debounce((name, value, key) => {
      if (value !== '' && value && value.trim().length > 1) {
        if (name === 'secondaryIcdCodes') {
          props.actions.getSecoundaryList(value.trim(), key);
        } else props.actions.getCancerList(value.trim());
      }
    }, 1000),
    [],
  );
  // eslint-disable-next-line arrow-spacing
  const createCancer = (diseases = []) => (
    <Grid item xs={12}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body1">
            {diseases[0].category.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column" className={classes.indent}>
            {diseases.map(cancer => (
              <FormControlLabel
                value={cancer.diagnosis}
                control={<Radio color="primary" inputProps={{ 'aria-label': cancer.trfDisplayName }} />}
                label={<Typography component="span" variant="body1">{cancer.trfDisplayName}</Typography>}
                key={cancer.diagnosis}
                sx={{mt: 0, mb: 0}}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>


    </Grid>
  );
  const primaryICDcode = newOrder.getIn(schema.diagnosis.primaryIcdCode, {}) || {};
  const selctedCodes = [...(newOrder.getIn(schema.diagnosis.secondaryIcdCodes) || []),
    primaryICDcode];
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={3}>
      <Grid item lg={8}>
        <Typography sx={{ my: '30px' }} component="h5" variant="h5">2. Diagnosis</Typography>
        <Grid container direction="row" rowSpacing={1}
          className={orderManagement.errors.stage && `error ${classes.errorBox}`}>
          <Grid item xs={10}>
            <Typography component="span" variant="body1"
              color={orderManagement.errors.stage && 'error'}>
              Cancer Stage &nbsp;
            </Typography>
            <Typography component="span" variant="caption"
              color={orderManagement.errors.stage && 'error'}>
              Required
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography component="div" variant="body2">
              Guardant tests are used for stage II, III & IV cancer
              diagnoses only
            </Typography>
            <FormControl
              error={orderManagement.errors.stage}
              helperText={orderManagement.errors.stage}
            >
              <RadioGroup
                row
                aria-labelledby="stage"
                name="stage"
                onChange={onInputChange}
                value={newOrder.getIn(schema.diagnosis.stage) || ''}
              >
                <FormControlLabel
                  value="Stage II"
                  control={<Radio color="primary" />}
                  label="II"
                  sx={{ mr: 5 }}
                />
                <FormControlLabel
                  value="Stage III"
                  control={<Radio color="primary" />}
                  label="III"
                  sx={{ mr: 5 }}
                />
                <FormControlLabel
                  value="Stage IV"
                  control={<Radio color="primary" />}
                  label="IV"
                  sx={{ mr: 5 }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ mt: 2 }}
          className={orderManagement.errors.primaryIcdCode && `error ${classes.errorBox}`}>
          <Grid item xs={10}>
            <Typography component="div" variant="body1"
              color={orderManagement.errors.primaryIcdCode && 'error'}
              sx={{ my: '10px' }}>
              ICD.10 Codes &nbsp;
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <DiagnosisAutoComplete
              label="Primary ICD.10 Code"
              options={filterICDcodes(orderManagement.cancerList,
                selctedCodes, selctedCodes.length)}
              name="primaryIcdCode"
              id="input"
              property={['icdCode', 'description']}
              showPropertyValue
              displaylabel={['icdCode', 'description']}
              manualFilter
              onChange={e => onInputChange(e, null)}
              error={orderManagement.errors.primaryIcdCode}
              value={`${primaryICDcode?.icdCode || ''} ${primaryICDcode?.description || ''}`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onTextChange={e => handleSearch(e.target.name, e.target.value)
              }
            />
            {orderManagement.errors.primaryIcdCode ? (
              <FormHelperText className="Mui-error">{`  ${orderManagement.errors.primaryIcdCode}`}</FormHelperText>
            ) : <FormHelperText className="uiFormHelperText-root">Required</FormHelperText>}
          </Grid>
        </Grid>
        <Grid container direction="row" sx={{ mt: 2 }} columnSpacing={1}>
          <Grid item xs={12} lg={3} sx={{ mb: 2 }}>
            <DiagnosisAutoComplete
              label="Secondary ICD.10 Code"
              options={filterICDcodes(orderManagement.secondaryCode0,
                selctedCodes, 0, primaryICDcode?.icdCode)}
              name="secondaryIcdCodes"
              property="icdCode"
              id="input"
              displaylabel={['icdCode', 'description']}
              showPropertyValue
              manualFilter
              onChange={e => onInputChange(e, '0')}
              error={orderManagement.errors.secondaryCode0}
              value={
                (newOrder.getIn(schema.diagnosis.secondaryIcdCodes, [])
                  && newOrder.getIn(schema.diagnosis.secondaryIcdCodes, [])[0]
                    ?.icdCode)
                || ''
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onTextChange={e => handleSearch(e.target.name, e.target.value, '0')
              }
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mb: 3 }}>
            <DiagnosisAutoComplete
              label="Secondary ICD.10 Code"
              options={filterICDcodes(orderManagement.secondaryCode1,
                selctedCodes, 1, primaryICDcode?.icdCode)}
              name="secondaryIcdCodes"
              property="icdCode"
              displaylabel={['icdCode', 'description']}
              showPropertyValue
              manualFilter
              id="input"
              onChange={e => onInputChange(e, 1)}
              error={orderManagement.errors.secondaryCode1}
              value={
                newOrder.getIn(schema.diagnosis.secondaryIcdCodes, [])
                && newOrder.getIn(schema.diagnosis.secondaryIcdCodes, [])[1]
                  ?.icdCode
              }
              InputProps={{
                id: 'input',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onTextChange={e => handleSearch(e.target.name, e.target.value, 1)
              }
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mb: 3 }}>
            <DiagnosisAutoComplete
              label="Secondary ICD.10 Code"
              options={filterICDcodes(orderManagement.secondaryCode2,
                selctedCodes, 2, primaryICDcode?.icdCode)}
              name="secondaryIcdCodes"
              property="icdCode"
              displaylabel={['icdCode', 'description']}
              onChange={e => onInputChange(e, 2)}
              id="input"
              error={orderManagement.errors.secondaryCode2}
              value={
                newOrder.getIn(schema.diagnosis.secondaryIcdCodes, [])
                && newOrder.getIn(schema.diagnosis.secondaryIcdCodes, [])[2]
                  ?.icdCode
              }
              showPropertyValue
              manualFilter
              InputProps={{
                id: 'input',
                startAdornment: (
                  <InputAdornment
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onTextChange={e => handleSearch(e.target.name, e.target.value, 2)
              }
            />
          </Grid>
          <Grid item xs={12} lg={3} sx={{ mb: 3 }}>
            <DiagnosisAutoComplete
              label="Secondary ICD.10 Code"
              options={filterICDcodes(orderManagement.secondaryCode3,
                selctedCodes, 3, primaryICDcode?.icdCode)}
              name="secondaryIcdCodes"
              property="icdCode"
              displaylabel={['icdCode', 'description']}
              onChange={e => onInputChange(e, 3)}
              id="input"
              error={orderManagement.errors.secondaryCode3}
              value={
                newOrder.getIn(schema.diagnosis.secondaryIcdCodes, [])
                && newOrder.getIn(schema.diagnosis.secondaryIcdCodes, [])[3]
                  ?.icdCode
              }
              showPropertyValue
              manualFilter
              InputProps={{
                id: 'input',
                startAdornment: (
                  <InputAdornment
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onTextChange={e => handleSearch(e.target.name, e.target.value, 3)
              }
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} lg={6} sx={{ mb: 2 }}>
            <Controls.DatePicker
              mask="__-__-____"
              label="Original Diagnosis Date"
              name="dateOfOriginalDiagnosis"
              type="date"
              id="input"
              fullWidth
              inputFormat="MM-DD-YYYY"
              maxDate={moment()}
              minDate={moment().year(1900).month(0).date(1)}
              value={newOrder.getIn(schema.diagnosis.dateOfOriginalDiagnosis) || null}
              error={orderManagement.errors.dateOfOriginalDiagnosis}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              // eslint-disable-next-line consistent-return
              onChange={e => {
                props.actions.setError({ name: 'dateOfOriginalDiagnosis', value: '' });
                props.actions.onChange({ name: schema.diagnosis.dateOfOriginalDiagnosis, value: undefined });
                if (!e) return;
                const val = moment(e);
                //invalid.isBefore(another), invalid.isAfter(another) return false
                const isFutureDate = val.isAfter(new Date(), 'day');
                const isPastDate = val.isBefore(moment().year(1900).month(0).date(1), 'day');
                if (!(isFutureDate || isPastDate)) {
                  onInputChange({
                    target: {
                      name: 'dateOfOriginalDiagnosis',
                      value: val.format('YYYY-MM-DD').toString(),
                    },
                  });
                } else {
                  props.actions.setError({ name: 'dateOfOriginalDiagnosis', value: 'Please select a date between 01-01-1900 to today' });
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>




      <Grid item lg={8}>
        <Grid container direction="column" rowSpacing={1}
          className={orderManagement.errors.disease && `error ${classes.errorBox}`}>
          <Grid item>
            <Typography component="span" variant="body1" color={orderManagement.errors.disease && 'error'}>
              Cancer Type &nbsp;
            </Typography>
            <Typography variant="caption" component="span" color={orderManagement.errors.disease && 'error'}>
              Required
            </Typography>
          </Grid>
          <Grid item>
            <FormControl error={orderManagement?.errors?.disease}>
              <RadioGroup
                name="disease"
                onChange={onInputChange}
                value={getRootDisease(newOrder.getIn(schema.diagnosis.disease), orderManagement.cancerTypeList)?.diagnosis}
              >
                <Grid container direction="row" columnSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" rowSpacing={2}>
                    {orderManagement.cancerTypeList
                      && Object.keys(_.groupBy(orderManagement.cancerTypeList, 'categoryOrder')).map(
                        (key, ind) => ind < 2 &&
                          createCancer(orderManagement.cancerTypeList.filter(i => !i.parentId && i.categoryOrder == key))
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" rowSpacing={2}>
                    {orderManagement.cancerTypeList
                      && Object.keys(_.groupBy(orderManagement.cancerTypeList, 'categoryOrder')).map(
                        (key, ind) => ind >= 2 &&
                          createCancer(orderManagement.cancerTypeList.filter(i => !i.parentId && i.categoryOrder == key))
                      )}
                    {getRootDisease(newOrder.getIn(schema.diagnosis.disease), orderManagement.cancerTypeList)?.diagnosis === 'Other' && (
                      <Controls.Input
                        name="diagnosisOther"
                        label="Other Disease"
                        value={newOrder.getIn(schema.diagnosis.diagnosisOther) || ''}
                        onChange={onInputChange}
                        inputProps={{ maxLength: 21 }}
                        error={orderManagement?.errors?.diagnosisOther}
                      />
                    )}
                    </Grid>
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Alert {...props} />
      <AlertMessage
        message="Changing diagnosis/stage may affect availability of tests. Please confirm test selection in next section."
        showMessage={showMessage}
        closeMessage={closeMessage}
      />
    </Grid>
  );
}
Diagnosis.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};
export default Diagnosis;
