import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  patientStore: PropTypes.shape({
    faceSheet: PropTypes.bool,
  }).isRequired,
  storeSelectedItem: PropTypes.func.isRequired,
};

const PatientInformationDemoFaceSheet = ({ patientStore, storeSelectedItem }) => (
  <div className="flex-container__etrf-section">
    <div className="text-body">
      <input
        type="checkbox"
        checked={patientStore.faceSheet}
        onChange={e => storeSelectedItem('faceSheet', e.target.checked)}
      />
      A copy of the patient&apos;s face sheet will be inserted in the G360 sample kit.
    </div>
  </div>
);

PatientInformationDemoFaceSheet.propTypes = IPropTypes;

export { PatientInformationDemoFaceSheet };
