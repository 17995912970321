import React from 'react';
import PropTypes from 'prop-types';

import { CollapsedTab } from '../../../generic/CollapsedTab';

import styles from './test-selection-information-collapsed.module.scss';

const IPropTypes = {
  testSelectionInfo: PropTypes.shape({
    testSelectionType: PropTypes.shape({
      text: PropTypes.string,
    }),
    treatmentResponse: PropTypes.shape({
      text: PropTypes.string,
    }),
    tissueSpecimenRetrieval: PropTypes.shape({
      pathologyLabName: PropTypes.string,
      pathologyLabPhone: PropTypes.string,
      pathologyLabFax: PropTypes.string,
      tissueCollectionDate: PropTypes.string,
    }),
    isTissueOption: PropTypes.bool,
  }).isRequired,
  onToggle: PropTypes.func,
  highlightRequiredFields: PropTypes.bool,
};

const defaultProps = {
  onToggle: () => { },
  highlightRequiredFields: false,
};

const TestSelectionInformationCollapsed = ({
  testSelectionInfo: {
    testSelectionType,
    treatmentResponse,
    tissueSpecimenRetrieval,
    isTissueOption,
  },
  onToggle,
  highlightRequiredFields,
}) => {
  const highlight = highlightRequiredFields && !testSelectionType.text && !treatmentResponse.text;

  const testSelectionValue = [testSelectionType.text, treatmentResponse.text].filter(Boolean).join(', ');

  return (
    <div className={[styles.accordionSection, styles.collapsed].join(' ')} onClick={onToggle}>
      <CollapsedTab title="Test Selection" highlightTitle={highlight} value={testSelectionValue} truncate={false} />
      {
        isTissueOption && (
          <div className={styles.sectionRow}>
            <CollapsedTab className={styles.collapsedTab} title="Pathology Lab Name" value={tissueSpecimenRetrieval.pathologyLabName} />
            <CollapsedTab className={styles.collapsedTab} title="Phone" value={tissueSpecimenRetrieval.pathologyLabPhone} />
            <CollapsedTab className={styles.collapsedTab} title="Fax" value={tissueSpecimenRetrieval.pathologyLabFax} />
            <CollapsedTab className={styles.collapsedTab} title="Tissue Collection Date" value={tissueSpecimenRetrieval.tissueCollectionDate} />
          </div>
        )
      }
    </div>
  );
};

TestSelectionInformationCollapsed.propTypes = IPropTypes;
TestSelectionInformationCollapsed.defaultProps = defaultProps;

export { TestSelectionInformationCollapsed };
