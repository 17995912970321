import { guardantRevealOrderingActionTypes } from '../../../actions/orders/active-order/guardant-reveal-ordering-info';
import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';

const guardantRevealOrderingInitialState = {
  surgicalResectionDate: null,
  orderFrequency: {
    code: null, // list of strings
    name: null,
  },
  preferenceForBloodDraw: null,
};

const guardantRevealOrderingInfo = (state = guardantRevealOrderingInitialState, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return { ...state, ...action.order.guardantRevealOrderingInfo };
    case guardantRevealOrderingActionTypes.ADD_GUARDANT_REVEAL_ORDERING_INFO:
      return { ...state, ...action.info };
    default:
      return state;
  }
};

export { guardantRevealOrderingInfo };
