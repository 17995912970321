import { patientPageInfoActionTypes } from '../../../actions/patient-page/multi-product';

const patientPageInfoInitialState = {
  info: {
    patientSummary: null,
    references: null,
  },
  geneDetails: null,
};

const patientPageInfo = (state = patientPageInfoInitialState, action) => {
  switch (action.type) {
    case patientPageInfoActionTypes.ADD_PATIENT_PAGE_INFO:
      return { ...state, info: { ...action.info } };
    case patientPageInfoActionTypes.ADD_GENE_DETAILS:
      return { ...state, geneDetails: { ...action.geneDetails } };
    default:
      return state;
  }
};

export { patientPageInfo };
