import { createAction } from 'redux-actions';

export const GET_ROLES = '@usermanagment-workflow/GET_ROLES';
export const getRoles = createAction(GET_ROLES);

export const GET_ROLES_DONE = '@usermanagment-workflow/GET_ROLES_DONE';
export const getRolesDone = createAction(GET_ROLES_DONE);

export const ROLES_ERROR = '@usermanagment-workflow/ROLES_ERROR';
export const rolesError = createAction(ROLES_ERROR);

export const SAVE_ROLES = '@usermanagment-workflow/SAVE_ROLES';
export const saveRoles = createAction(SAVE_ROLES);

export const SAVE_ROLES_DONE = '@usermanagment-workflow/SAVE_ROLES_DONE';
export const saveRolesDone = createAction(SAVE_ROLES_DONE);

export const SAVE_ROLES_ERROR = '@usermanagment-workflow/SAVE_ROLES_ERROR';
export const saveRolesError = createAction(SAVE_ROLES_ERROR);

export const UPDATE_ROLES = '@usermanagment-workflow/UPDATE_ROLES';
export const updatRoles = createAction(UPDATE_ROLES);

export const UPDATE_ROLES_DONE = '@usermanagment-workflow/UPDATE_ROLES_DONE';
export const updatRolesDone = createAction(UPDATE_ROLES_DONE);

export const UPDATE_ROLES_ERROR = '@usermanagment-workflow/UPDATE_ROLES_ERROR';
export const updatRolesError = createAction(UPDATE_ROLES_ERROR);

export const DELETE_ROLES = '@usermanagment-workflow/DELETE_ROLES';
export const deleteRoles = createAction(DELETE_ROLES);

export const DELETE_ROLES_DONE = '@usermanagment-workflow/DELETE_ROLES_DONE';
export const deleteRolesDone = createAction(DELETE_ROLES_DONE);

export const DELETE_ROLES_ERROR = '@usermanagment-workflow/DELETE_ROLES_ERROR';
export const deleteRolesError = createAction(DELETE_ROLES_ERROR);

export const ROLES_PAGINATION_CHANGE = '@usermanagment-workflow/ROLES_PAGINATION_CHANGE';
export const changePageRoles = createAction(ROLES_PAGINATION_CHANGE);

export const ON_CHANGE_ROLES = '@usermanagment-workflow/ON_CHANGE_ROLES';
export const onChangeRoles = createAction(ON_CHANGE_ROLES);


export const GET_CLAIMS = '@usermanagment-workflow/GET_CLAIMS';
export const getClaims = createAction(GET_CLAIMS);

export const defaultRole = createAction('');
