import React from 'react';
import PropTypes from 'prop-types';

import styles from './interpretation-and-methodology.module.scss';

const IPropTypes = {
  interpretationAndMethodology: PropTypes.shape({
    interpretationAndUse: PropTypes.string,
    limitations: PropTypes.string,
    methodology: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.string),
  }),
};

const defaultProps = {
  interpretationAndMethodology: null,
};

const InterpretationAndMethodology = props => !!props.interpretationAndMethodology && (
  <>
    {
      !!props.interpretationAndMethodology.interpretationAndUse
      && (
        <>
          <h3 className={styles.title}>
            Test Interpretation and Use
          </h3>
          <p>
            {props.interpretationAndMethodology.interpretationAndUse}
          </p>
        </>
      )
    }
    {
      !!props.interpretationAndMethodology.methodology
      && (
        <>
          <h3 className={styles.title}>
            Methodology
          </h3>
          <p>
            {props.interpretationAndMethodology.methodology}
          </p>
        </>
      )
    }
    {
      !!props.interpretationAndMethodology.limitations
      && (
        <>
          <h3 className={styles.title}>
            Limitations
          </h3>
          <p>
            {props.interpretationAndMethodology.limitations}
          </p>
        </>
      )
    }
    {
      !!props.interpretationAndMethodology.references
      && !!props.interpretationAndMethodology.references.length
      && (
        <>
          <h3 className={styles.title}>
            References
          </h3>
          <ol className={styles.referenceList}>
            {
              props.interpretationAndMethodology.references.map(reference => (
                <li key={reference}>{reference}</li>
              ))
            }
          </ol>
        </>
      )
    }
  </>
);

InterpretationAndMethodology.propTypes = IPropTypes;
InterpretationAndMethodology.defaultProps = defaultProps;

export { InterpretationAndMethodology };
