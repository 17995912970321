import React from 'react';
import PropTypes from 'prop-types';

const RequestTile = (props) => {
  const {
    selectedCategory, testAgain, category, count,
  } = props;
  const selected = category === selectedCategory ? 'request-counts-tile--is-selected' : 'unselected';
  let label;
  if (selected !== 'unselected') {
    if (category === 'downloaded') {
      label = <div className="request-counts-tile__label selected">Read</div>;
    } else {
      label = <div className="request-counts-tile__label selected">{category.toString().replace('_', ' ')}</div>;
    }
  }
  if (category === 'downloaded') {
    label = <div className="request-counts-tile__label">Read</div>;
  } else {
    label = <div className="request-counts-tile__label">{category.toString().replace('_', ' ')}</div>;
  }
  return (
    <React.Fragment>
      {
        (category !== testAgain) && (
          <div className={`request-counts-tile tile-${selected}`}>
            <div className={`request-counts-circles request-counts-tile--${category.toString().replace('_', '-')} ${selected}`}>
              <a href={`/dashboard?request_status=${category}`} className="request-counts-tile__link">
                {
                  selected !== 'unselected' ? <div className="request-counts-tile__count selected">{count}</div>
                    : <div className="request-counts-tile__count">{count}</div>
                }
              </a>
            </div>
            {label}
          </div>
        )
      }
    </React.Fragment>
  );
};
export default RequestTile;
RequestTile.propTypes = {
  category: PropTypes.string.isRequired,
  testAgain: PropTypes.bool.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};
