import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './alteration-row.module.scss';
import approvedIcon from '../../../assets/images/icons/patient-page/approved.svg';
import inOtherIcon from '../../../assets/images/icons/patient-page/in-other.svg';
import lackOfResponseIcon from '../../../assets/images/icons/patient-page/lack-of-response.svg';
import { GeneDetailsModal } from '../gene-details-modal';
import { ClinicalTrialsModal } from '../clinical-trials-modal/clinical-trials-modal';
import { ALTERATION_COLUMNS } from '../../../helpers/alterations-table';

const ITherapy = PropTypes.shape({
  drugName: PropTypes.string,
  urlSuffix: PropTypes.string,
  hasUrl: PropTypes.bool,
});

const IPropTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  bordered: PropTypes.bool,
  noTopBorder: PropTypes.bool,
  name: PropTypes.string.isRequired,
  gene: PropTypes.string.isRequired,
  description: PropTypes.string,
  synonym: PropTypes.string,
  richDescription: PropTypes.bool.isRequired,
  percentage: PropTypes.string,
  showPlasmaCopyNumber: PropTypes.bool.isRequired,
  plasmaCopyNumber: PropTypes.string,
  hideTherapies: PropTypes.bool.isRequired,
  synonymous: PropTypes.bool.isRequired,
  noTherapiesDetected: PropTypes.bool.isRequired,
  therapies: PropTypes.shape({
    approvedForThisDisease: PropTypes.arrayOf(ITherapy),
    approvedForOtherDiseases: PropTypes.arrayOf(ITherapy),
    lackOfResponse: PropTypes.arrayOf(ITherapy),
  }),
  unknownSignificance: PropTypes.bool.isRequired,
  showSuperscript: PropTypes.bool.isRequired,
  additionalTherapyInformation: PropTypes.bool.isRequired,
  clinicalTrialsText: PropTypes.string,
  requestId: PropTypes.string.isRequired,
  therapyUrl: PropTypes.string.isRequired,
  clinicalTrialsAvailable: PropTypes.bool.isRequired,
  clinicalTrials: PropTypes.array.isRequired,
  showFrequencyColumn: PropTypes.bool.isRequired,
  columnOrder: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ALTERATION_COLUMNS))).isRequired,
  shaded: PropTypes.bool.isRequired,
};

const defaultProps = {
  color: null,
  noTopBorder: false,
  bordered: false,
  description: null,
  synonym: null,
  percentage: null,
  plasmaCopyNumber: null,
  therapies: {},
  clinicalTrialsText: null,
};

const renderDescription = (gene, synonym, name, description, rich) => (
  <>
    <span className={rich ? styles.gene : styles.descriptionText}>
      {gene}
      {
        !!synonym
        && (
          <span className={styles.synonym}>
            {synonym}
          </span>
        )
      }
    </span>
    <span className={rich ? styles.highlightedDescription : styles.descriptionText}>
      {name}
    </span>
    {
      !!description
      && (
        <span className={rich ? styles.highlightedDescription : styles.descriptionText}>
          {description}
        </span>
      )
    }
  </>
);

const renderTherapyList = (therapyList, icon, textClassName, therapyUrl) => (
  <li className={styles.therapyListItem}>
    <img src={icon} alt="" className={styles.therapyIcon} />
    {
      therapyList.map((therapy, index) => (
        <div key={therapy.drugName}>
          {
            !!therapy.hasUrl
            && (
              <a
                href={`${therapyUrl}${therapy.urlSuffix}`}
                target="_blank"
                rel="noreferrer noopener"
                className={textClassName}
              >
                {therapy.drugName}
              </a>
            )
          }
          {
            !therapy.hasUrl
            && (
              <span className={textClassName}>
                {therapy.drugName}
              </span>
            )
          }
          { index < therapyList.length - 1 && ',' }
        </div>
      ))
    }
  </li>
);

const renderTherapies = (
  noTherapiesDetected,
  therapies,
  synonymous,
  unknownSignificance,
  showSuperscript,
  therapyUrl,
) => {
  if (noTherapiesDetected) {
    let text = 'None';
    if (synonymous) {
      text = 'None (Synonymous)';
    } else if (unknownSignificance) {
      text = 'None (VUS)';
    }
    let superscript = '';
    if ((synonymous || unknownSignificance) && showSuperscript) {
      superscript = <sup>§</sup>;
    }
    return (
      <div>
        {text}
        {superscript}
      </div>
    );
  }
  return (
    <ul className={styles.therapyList}>
      {
        !!therapies.approvedForThisDisease
        && !!therapies.approvedForThisDisease.length
        && renderTherapyList(
          therapies.approvedForThisDisease,
          approvedIcon,
          styles.approvedText,
          therapyUrl,
        )
      }
      {
        !!therapies.approvedForOtherDiseases
        && !!therapies.approvedForOtherDiseases.length
        && renderTherapyList(
          therapies.approvedForOtherDiseases,
          inOtherIcon,
          styles.otherDiseasesText,
          therapyUrl,
        )
      }
      {
        !!therapies.lackOfResponse
        && !!therapies.lackOfResponse.length
        && renderTherapyList(
          therapies.lackOfResponse,
          lackOfResponseIcon,
          styles.lackOfResponseText,
          therapyUrl,
        )
      }
    </ul>
  );
};

const renderClinicalTrials = (
  clinicalTrialsAvailable,
  clinicalTrialsText,
  synonymous,
  unknownSignificance,
  showSuperscript,
  showClinicalTrialsModal,
  setShowClinicalTrialsModal,
  clinicalModalProps,
) => {
  if (!clinicalTrialsAvailable) {
    let text = 'No';
    let superscript = '';
    if (synonymous) {
      text = 'No (Synonymous)';
    } else if (unknownSignificance) {
      text = 'No (VUS)';
    }
    if ((synonymous || unknownSignificance) && showSuperscript) {
      superscript = <sup>§</sup>;
    }
    return (
      <div className={styles.trial}>
        {text}
        {superscript}
      </div>
    );
  }
  return (
    <div>
      {
        !!clinicalTrialsText
        && (
          <button
            className={styles.positiveTrial}
            type="button"
            onClick={() => setShowClinicalTrialsModal(true)}
          >
            {clinicalTrialsText}
            {
              showClinicalTrialsModal
              && (
                <ClinicalTrialsModal
                  onClose={() => setShowClinicalTrialsModal(false)}
                  {...clinicalModalProps}
                />
              )
            }
          </button>
        )
      }
    </div>
  );
};

const renderFrequencyColumn = (
  showFrequencyColumn,
  showPlasmaCopyNumber,
  percentage,
  plasmaCopyNumber,
  shaded,
) => {
  if (!showFrequencyColumn) {
    return null;
  }
  return (
    <td className={[styles.percentageBlock, shaded ? styles.shaded : ''].join(' ')}>
      {
        !showPlasmaCopyNumber
        && (
          <div>
            {percentage}
          </div>
        )
      }
      {
        !!showPlasmaCopyNumber
        && !!plasmaCopyNumber
        && (
          <>
            <div>
              {percentage}
            </div>
            <div className={styles.plasmaCopyNumber}>
              {plasmaCopyNumber}
            </div>
          </>
        )
      }
    </td>
  );
};

const renderAlterationColumn = (richDescription, description, setShowGeneDetailsModal, shaded) => (
  <td className={[styles.descriptionBlock, shaded ? styles.shaded : ''].join(' ')}>
    {
      richDescription
        ? (
          <button
            type="button"
            onClick={() => setShowGeneDetailsModal(true)}
            className={styles.clickable}
          >
            {description}
          </button>
        )
        : (
          <div>
            {description}
          </div>
        )
    }
  </td>
);

const renderColorColumn = (colorBlockStyle, bordered) => (
  <td
    className={[styles.colorBlockItem, bordered ? styles.bordered : ''].join(' ')}
    style={colorBlockStyle}
  >
    <div />
  </td>
);

const AlterationRow = (props) => {
  const [showGeneDetailsModal, setShowGeneDetailsModal] = useState(false);
  const [showClinicalTrialsModal, setShowClinicalTrialsModal] = useState(false);
  const colorBlockStyle = {
    backgroundColor: props.color,
  };
  if (props.noTopBorder) {
    colorBlockStyle.borderTop = 'none';
  }
  const description = renderDescription(
    props.gene,
    props.synonym,
    props.name,
    props.description,
    props.richDescription,
  );

  const colorColumn = props.color ? renderColorColumn(colorBlockStyle, props.bordered) : null;
  const alterationColumn = renderAlterationColumn(
    props.richDescription,
    description,
    setShowGeneDetailsModal,
    props.shaded,
  );
  const frequencyColumn = renderFrequencyColumn(
    props.showFrequencyColumn,
    props.showPlasmaCopyNumber,
    props.percentage,
    props.plasmaCopyNumber,
    props.shaded,
  );
  const therapyColumn = (
    <td className={[styles.therapiesBlock, props.shaded ? styles.shaded : ''].join(' ')}>
      {
        !!props.hideTherapies
        && !!props.synonymous
        && (
          <>
            Synonymous Alteration
            {props.showSuperscript && <sup>§</sup>}
          </>
        )
      }
      {
        !props.hideTherapies
        && !props.additionalTherapyInformation
        && !!props.synonymous
        && (
          <>
            None (Synonymous)
            {props.showSuperscript && <sup>§</sup>}
          </>
        )
      }
      {
        !props.hideTherapies
        && props.additionalTherapyInformation
        && renderTherapies(
          props.noTherapiesDetected,
          props.therapies,
          props.synonymous,
          props.unknownSignificance,
          props.showSuperscript,
          props.therapyUrl,
        )
      }
    </td>
  );
  const clinicalColumn = (
    <td className={[styles.clinicalTrialsBlock, props.shaded ? styles.shaded : ''].join(' ')}>
      {
        !props.additionalTherapyInformation
        && props.synonymous
        && (
          <>
            No (Synonymous)
            {props.showSuperscript && <sup>§</sup>}
          </>
        )
      }
      {
        props.additionalTherapyInformation
        && renderClinicalTrials(
          props.clinicalTrialsAvailable,
          props.clinicalTrialsText,
          props.synonymous,
          props.unknownSignificance,
          props.showSuperscript,
          showClinicalTrialsModal,
          setShowClinicalTrialsModal,
          {
            geneName: props.gene,
            clinicalTrials: props.clinicalTrials,
          },
        )
      }
    </td>
  );

  const columns = {
    [ALTERATION_COLUMNS.COLOR]: colorColumn,
    [ALTERATION_COLUMNS.ALTERATIONS]: alterationColumn,
    [ALTERATION_COLUMNS.FREQUENCY]: frequencyColumn,
    [ALTERATION_COLUMNS.THERAPIES]: therapyColumn,
    [ALTERATION_COLUMNS.CLINICAL]: clinicalColumn,
  };

  return (
    <tr className={styles.row}>
      {
        showGeneDetailsModal
        && (
          <GeneDetailsModal
            requestId={props.requestId}
            alterationId={props.id}
            onClose={() => setShowGeneDetailsModal(false)}
            gene={props.gene}
            name={props.name}
          />
        )
      }
      {
        props.columnOrder.map(columnName => (
          <React.Fragment key={columnName}>
            {columns[columnName]}
          </React.Fragment>
        ))
      }
    </tr>
  );
};

AlterationRow.propTypes = IPropTypes;
AlterationRow.defaultProps = defaultProps;

export { AlterationRow };
