import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShareIndexCollaborator from './commons/components/ShareIndexCollaborator';
import AllPracticeShare from './commons/components/AllPracticeShare';
import ModalDialogBox from './commons/components/ModalDialogBox';

const StudyManagerView = (props) => {
  const {
    orderingPhysiciansOfStudyManager: studyMangerOrders,
    allPracticeShares,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const showModalWindow = () => {
    setShowModal(!showModal);
  };
  const closeWindow = () => {
    setShowModal(!showModal);
  };
  const orderingPhysiciansView = Object.keys(studyMangerOrders).map((key) => {
    const lastName = studyMangerOrders[key].lastname;
    const firstName = studyMangerOrders[key].firstname;
    const lastAndFirstName = (firstName && lastName) ? [firstName, lastName].join(', ') : studyMangerOrders[key].physician_username;
    return (
      <ShareIndexCollaborator
        key={studyMangerOrders[key].id}
        leftColName={`${lastAndFirstName}`}
        leftColEmail={studyMangerOrders[key].physician_username || ''}
        rightColName="YOU CAN VIEW"
        sharingTitle="YOU HAVE STUDY MANAGER ACCESS TO"
        shareDescription={`All ${studyMangerOrders[key].patient_ids.length} of their patient reports from ${studyMangerOrders[key].practice_name}`}
      />
    );
  });
  return (
    <React.Fragment>
      <div className="share-index__body">
        <div className="share-index__body__header">
          <div className="share-index__body__header-text">
            <div className="share-index__body__header-title">
              Ordering Physicians
              <i className="fa fa-info-circle shares_info_circle modal-info" onClick={showModalWindow} />
            </div>
          </div>
        </div>
        <div className="share-index__collaborators-container">
          {orderingPhysiciansView}
          <AllPracticeShare
            allPracticeShares={allPracticeShares}
            display="theirs"
          />
        </div>
      </div>
      <ModalDialogBox show={showModal} showModal={closeWindow} ModalContent="See whose reports you have access to" />
    </React.Fragment>
  );
};
export default StudyManagerView;
StudyManagerView.propTypes = {
  orderingPhysiciansOfStudyManager: PropTypes.object.isRequired,
  allPracticeShares: PropTypes.array.isRequired,
};
