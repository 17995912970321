const filterICDcodes = (list = [], ignoreval = [], cur, skip) => {
  const ignores = ignoreval.map((icd, i) => (i !== cur || skip?.length > 0) && icd?.icdCode);
  return list.filter(li => !ignores.includes(li.icdCode));
};

const disobj = {
  REVEAL: ['G360CDx', 'G360CDx', 'G360', 'TGPDL1', 'TG', 'G360MR'],
  G360CDx: ['REVEAL', 'G360'],
  G360: ['REVEAL', 'G360CDx', 'TGPDL1', 'TG'],
  TGPDL1: ['REVEAL', 'G360', 'TG'],
  TG: ['REVEAL', 'TGPDL1', 'G360'],
  G360MR: ['REVEAL'],
};
const descriptionObject = {
  REVEAL: 'Can\'t be ordered with current test selection(s)',
  'Liquid Profiling': "Can't be ordered with current test selection(s)",
  'Treatment Response Assessment':
    'Requires prior Guardant360 or Guardant360 CDx test',
  DEFAULT: 'Not available with current diagnosis selections',
  AlterMsg: 'Can\'t be ordered with Guardant Reveal',
};

const tooltipText = {
  G360CDx: 'Guardant360 CDx is an FDA approved comprehensive liquid biopsy for all advanced solid tumors. It includes both the FDA-approved report with 55 genes and the *professional services report with 74 genes and MSI status.',
  TGPDL1: 'Guardant360 TissueNext: 84-gene tissue panel, including TMB and MSI and PD-L1 IHC, if requested. If ordered with Guardant360 CDx, confirmation of medical necessity is required after Guardant360 CDx results are reported. This product is not reviewed or approved by the FDA.',
  TG: 'Guardant360 TissueNext: 84-gene tissue panel, including TMB and MSI and PD-L1 IHC, if requested. If ordered with Guardant360 CDx, confirmation of medical necessity is required after Guardant360 CDx results are reported. This product is not reviewed or approved by the FDA.',
  G360: 'Guardant360: Expanded liquid panel including TMB and additional genes Report includes TMB, NTRK2, NTRK3, CHEK2, FANCA, KEAP1, MSH2, MSH6, PALB2, PMS2, RAD51D. Cannot be ordered with Guardant360 TissueNext. This product is not reviewed or approved by the FDA.',
  G360MR: 'Guardant360 Response assesses treatment response. It utilizes ctDNA to evaluate response to therapy approximately 4-10 weeks after treatment initiation. Requires a baseline Guardant360 CDx or Guardant360 prior to treatment initiation. This product is not reviewed or approved by the FDA.',
  REVEAL: 'GuardantReveal: Guardant Reveal™ is a blood-only liquid biopsy test that detects circulating tumor DNA (ctDNA) for minimal residual disease (MRD) assessment in early-stage colorectal, breast, and lung cancers. In addition to detection of MRD, Guardant Reveal is also available to monitor recurrence in previously diagnosed patients who have completed curative intent treatment. Guardant Reveal does not provide comprehensive genotyping.',
};

/*
  Take a list of GH test codes and return comma + space (', ') separated string of their display (pretty) values.
  Example:
  [G360CDx, G360MR] returns Guardant360 CDx, Guardant360 Response
  Ignore test codes that have same display value as others in the input list.
  Example:
  [TG, G360MR, PDL1] returns Guardant360 TissueNext, Guardant360 Response
*/
const getTests = (codes = []) => {
  const names = {
    GUARDANT_REVEAL: 'Guardant Reveal',
    'LUNAR1-CRC-P': 'Guardant Reveal',
    'LUNAR1-CRC-S': 'Guardant Reveal',
    'LUNAR1-CRC-O': 'Guardant Reveal',
    G360CDx: 'Guardant360 CDx',
    G360: 'Guardant360',
    G360MR: 'Guardant360 Response',
    PDL1: 'PD-L1',
    TG: 'Guardant360 TissueNext',
  };
  if(codes.length === 0){
    return '';
  }
  const displayValues = [];
  codes.filter(code => names[code]).forEach(code => {
    if(!displayValues.includes(names[code])){
      displayValues.push(names[code]);
    }
  });
  return displayValues.join(', ');
};
export {
  tooltipText, descriptionObject, disobj, filterICDcodes, getTests,
};
