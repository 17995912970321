import dateFormat from 'dateformat';

import { constants } from '../../config/constants';

const { INCOMPLETE } = constants.printModal.infoCompleteness;
class PatientInfo {
  constructor(params) {
    this.status = params.patientStatus;
    this.faceSheet = params.faceSheet;
    this.lastName = params.patientLastName;
    this.firstName = params.patientFirstName;
    this.sex = params.patientSex;
    this.birthDate = params.patientBirthDate;
    this.medicalRecordNumber = params.patientMedicalRecordNumber;
    this.street = params.patientStreet;
    this.street2 = params.patientStreet2;
    this.city = params.patientCity;
    this.country = params.patientCountry;
    this.type = params.patientType;
    this.email = params.patientEmail;
    this.phone = params.patientPhone;
    this.state = params.patientState;
    this.province = params.patientProvince;
    this.postalCode = params.patientPostalCode;
    this.zipCode = params.patientZipCode;
  }

  formatFullName = () => `${this.lastName || 'N/A'}, ${this.firstName || 'N/A'}`;

  formatBirthDate = () => {
    if (this.birthDate === 'MMM/DD/YYYY') {
      return `DOB: ${this.birthDate}`;
    }
    if (this.birthDate) {
      const date = dateFormat(this.birthDate, 'mmm-d-yyyy');
      return `DOB: ${date}`;
    }
    return 'N/A';
  };

  isComplete = () => !!this.firstName && !!this.lastName && !!this.birthDate && !!this.sex;

  formatForPrintModal = () => {
    const isCompleted = this.isComplete();
    return {
      title: 'PATIENT INFORMATION',
      info: (
        isCompleted
          ? `${this.lastName}, ${this.firstName} - ${dateFormat(this.birthDate, 'mmm-d-yyyy')} - ${this.sex}`
          : INCOMPLETE
      ),
      isCompleted,
    };
  };

  checkRequiredFields = () => !!(this.status
    && this.lastName && this.firstName && this.birthDate && this.sex)
}

export { PatientInfo };
