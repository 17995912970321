import { diagnosisActionTypes } from '../../../actions/orders/active-order/diagnosis_info';
import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';

const initialIcdCode = { code: '', description: '' };

const diagnosisInitialState = {
  diagnosisStage: null,
  diagnosisOriginalDiagnosis: null,
  disease: null,
  otherDisease: null,
  diagnosisPrimaryCode: initialIcdCode,
  diagnosisSecondaryCode: initialIcdCode,
  diagnosisTertiaryCode: initialIcdCode,
  diagnosisQuarternaryCode: initialIcdCode,
  diagnosisQuinaryCode: initialIcdCode,
  diagnosisSenaryCode: initialIcdCode,
};

const diagnosisInfo = (state = diagnosisInitialState, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return Object.assign({}, state, action.order.diagnosisInfo);
    case diagnosisActionTypes.ADD_DIAGNOSIS_DATA:
      return { ...state, ...action.info };
    default:
      return state;
  }
};

export default diagnosisInfo;
