import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';
import { clinicalHistoryEarlyActionTypes } from '../../../actions/orders/active-order/early-stage-clinical-history-info';

const clinicalHistoryEarlyInitialState = {
  chemotherapy: null,
  evidenceOfRecurrence: null,
  mrdSurveillance: null,
};

const earlyStageClinicalHistoryInfo = (state = clinicalHistoryEarlyInitialState, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return { ...state, ...action.order.earlyStageClinicalHistoryInfo };
    case clinicalHistoryEarlyActionTypes.ADD_EARLY_STAGE_CLINICAL_HISTORY_INFO:
      return { ...state, ...action.info };
    default:
      return state;
  }
};

export default earlyStageClinicalHistoryInfo;
