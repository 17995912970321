import { isEqual } from 'lodash';

const guardantRevealOrderingActionTypes = {
  ADD_GUARDANT_REVEAL_ORDERING_INFO: 'ADD_GUARDANT_REVEAL_ORDERING_INFO',
};

const AddGuardantRevealOrderingActionTypes = (info, hideBanner) => (dispatch, getState) => {
  const finalInfo = { ...info };
  const { orders: { staticData: { testSelections: { testTypes } } } } = getState();

  if (info.orderFrequency !== undefined) {
    // info.orderFrequency may be null or an actual object

    finalInfo.orderFrequency = { code: null, name: null };

    if (info.orderFrequency) {
      // if info.orderFrequency is not null
      finalInfo.orderFrequency = {};

      const [testName, [testData]] = Object.entries(testTypes).find(
        ([, testInfo]) => testInfo[0].code.includes(info.orderFrequency),
      );

      finalInfo.orderFrequency = {
        code: [info.orderFrequency],
        name: testName,
        requiresSurgicalResectionDate: testData.requiresSurgicalResectionDate,
      };
    }
  }

  dispatch({
    type: guardantRevealOrderingActionTypes.ADD_GUARDANT_REVEAL_ORDERING_INFO,
    info: finalInfo,
    hideBanner,
  });
};

const receiveGuardantRevealOrderingInfo = (order, testTypes) => {
  const parsedOrder = { ...order };
  const { guardantRevealOrderingInfo } = parsedOrder;

  if (guardantRevealOrderingInfo) {
    if ((guardantRevealOrderingInfo.orderFrequency || {}).code) {
      const [testName, [testData]] = Object.entries(testTypes).find(
        ([, [testInfo]]) => isEqual(testInfo.code, guardantRevealOrderingInfo.orderFrequency.code),
      );

      guardantRevealOrderingInfo.orderFrequency = {
        code: guardantRevealOrderingInfo.orderFrequency.code,
        name: testName,
        requiresSurgicalResectionDate: testData.requiresSurgicalResectionDate,
      };
    }
  }

  return parsedOrder;
};

export {
  guardantRevealOrderingActionTypes,
  AddGuardantRevealOrderingActionTypes,
  receiveGuardantRevealOrderingInfo,
};
