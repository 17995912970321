import React from 'react';

import PropTypes from 'prop-types';
import { getAllProjectIds } from '../util/ajax';
import { isUsername } from '../util/validator';

const IPropTypes = {
  internalUsersSsoEnabled: PropTypes.bool.isRequired,
  studyManagerHasMultipleProjects: PropTypes.bool.isRequired,
};

class StudyManagerSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputVal: '',
      firstName: '',
      lastName: '',
      email: '',
      sendInvitationEmail: true,
      sendCourtesyEmail: true,
      errorText: '',
      selectedProjectIds: null,
      submitEnabled: true,
      displayDropdown: false,
      projectIds: null,
      allProjectIds: null,
      allProjects: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.updateAllProjectIds = this.updateAllProjectIds.bind(this);
    this.createStudyManager = this.createStudyManager.bind(this);
    this.handleCheckmarkChange = this.handleCheckmarkChange.bind(this);
    this.verifyAbsoluteMatch = this.verifyAbsoluteMatch.bind(this);
  }

  componentDidMount() {
    getAllProjectIds().then(this.updateAllProjectIds);
  }

  updateAllProjectIds(data) {
    const ids = data.map(p => p.id);
    this.setState({ projectIds: ids, allProjectIds: ids, allProjects: data });
  }

  update(field) {
    return (e) => {
      if (this.props.internalUsersSsoEnabled && field === 'email') {
        const currentEmail = e.target.value;
        if (currentEmail.match(/@guardanthealth.com$/)) {
          this.setState({ submitEnabled: false, errorText: 'Please enter an email address with different domain' });
        } else {
          this.setState({ submitEnabled: true, errorText: null });
        }
      }

      this.setState({ [field]: e.target.value });
    };
  }

  handleCheckmarkChange(field) {
    return (e) => {
      this.setState({ [field]: e.target.checked });
    };
  }

  verifyAbsoluteMatch() {
    const { selectedProjectIds, allProjectIds } = this.state;

    if (!selectedProjectIds) {
      return null;
    }
    const splittedProjectIds = selectedProjectIds.split('; ');
    if (splittedProjectIds.length === 0) {
      return '';
    }
    const matches = [];

    allProjectIds.forEach((projectId) => {
      const text = projectId.replace(/ +/g, ' ').toLowerCase();
      splittedProjectIds.filter(el => el.length > 0).forEach((pp) => {
        const val = pp.replace(/ +/g, ' ').toLowerCase();
        if (text === val) {
          matches.push(projectId);
        }
      });
    });

    if (!this.props.studyManagerHasMultipleProjects) {
      return matches[0] || '';
    }
    return matches;
  }

  createStudyManager() {
    const {
      firstName,
      lastName,
      email,
      sendInvitationEmail,
      sendCourtesyEmail,
      allProjects,
    } = this.state;

    const projectIds = this.verifyAbsoluteMatch();

    if (!firstName) {
      this.setState({ errorText: 'First name is required.' });
      return;
    }
    if (!lastName) {
      this.setState({ errorText: 'Last name is required.' });
      return;
    }
    if (!email) {
      this.setState({ errorText: 'Email address is required.' });
      return;
    }
    if (!isUsername(email)) {
      this.setState({ errorText: 'Please enter a valid email address.' });
      return;
    }
    if (!projectIds || projectIds.length === 0) {
      this.setState({ errorText: 'Please enter a valid Project ID.' });
      return;
    }
    this.setState({ errorText: null });
    const projects = allProjects.filter(project => projectIds.some(pId => pId === project.id));
    window.showSpinner();
    window.createStudyManager(
      firstName,
      lastName,
      email,
      sendInvitationEmail,
      sendCourtesyEmail,
      projectIds,
      projects,
    );
  }

  handleInputChange(e) {
    const { allProjectIds } = this.state;
    let currentProjectId = e.target.value;
    this.setState({ inputVal: currentProjectId });

    if (this.props.studyManagerHasMultipleProjects) {
      const splittedProjectIds = currentProjectId.split('; ');
      currentProjectId = splittedProjectIds[splittedProjectIds.length - 1];
    }

    const val = currentProjectId.replace(/ +/g, ' ').toLowerCase();

    if (val === '') {
      this.setState({ displayDropdown: false });
      return;
    }

    const filteredProjectIds = allProjectIds.filter((elem) => {
      const text = elem.replace(/\s+/g, ' ').toLowerCase();
      return text.indexOf(val) !== -1;
    });
    this.setState({ projectIds: filteredProjectIds, displayDropdown: true });
  }

  handleProjectIdClick(e) {
    e.stopPropagation();
    const { inputVal } = this.state;

    let newValue = e.target.innerText;
    if (this.props.studyManagerHasMultipleProjects) {
      const index = inputVal.lastIndexOf('; ');
      const trimmedCurrentValue = inputVal.substring(0, index !== -1 ? index + 2 : 0);
      newValue = trimmedCurrentValue.concat(e.target.innerText, '; ');
    }

    this.setState({ inputVal: newValue, selectedProjectIds: newValue, displayDropdown: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.createStudyManager();
  }

  handleCloseModal() {
    this.setState({
      inputVal: '',
      firstName: '',
      lastName: '',
      email: '',
      errorText: '',
      displayDropdown: false,
    });
  }

  content() {
    const {
      firstName,
      lastName,
      email,
      inputVal,
      errorText,
      sendInvitationEmail,
      submitEnabled,
      displayDropdown,
      projectIds,
    } = this.state;

    const splittedProjectIds = (inputVal || '').split(';');
    const lastProjectId = splittedProjectIds[splittedProjectIds.length - 1];

    let results = '';
    if (projectIds) {
      results = projectIds.map(result => (
        <button
          key={result}
          data-ghi-number={result}
          className="project-id"
          onClick={this.handleProjectIdClick.bind(this)}
          type="button"
        >
          {result}
        </button>
      ));
    }

    return (
      <div className="content">
        <div className="email-errors" id="email-errors">{errorText}</div>
        <form className="site__manager__form-container" action="">
          <div className="grey-box__form__item-container">
            <div className="grey-box__form__item">
              <div className="grey-box__form__item-inner grey-box__form__item-inner-left">
                <div className="ghi-errors" />
                <label className="grey-box__form__item-label share__first__name" htmlFor="__first_name_">First Name</label>
                <input type="text" name="[first_name]]" id="_first_name" className="grey-box__form__item grey-box__form__item-name" placeholder="e.g. Joe" required value={firstName} onChange={this.update('firstName')} />
              </div>

              <div className="grey-box__form__item-inner grey-box__form__item-inner-right">
                <div className="ghi-errors" />
                <label className="grey-box__form__item-label share__last__name" htmlFor="__last_name_">Last Name</label>
                <input type="text" name="[last_name]]" id="_last_name" className="grey-box__form__item grey-box__form__item-name" placeholder="e.g. Smith" required value={lastName} onChange={this.update('lastName')} />
              </div>
            </div>

            <div className="grey-box__form__item">
              <div className="ghi-errors" />
              <label className="grey-box__form__item-label share__email__address" htmlFor="__email_">Email Address</label>
              <input type="text" name="[email_address]]" id="_email_address" className="grey-box__form__item grey-box__form__item-email" placeholder="e.g. studymanager@example.com" required value={email} onChange={this.update('email')} />
            </div>

            <div className="grey-box__form__item-inner grey-box__form__item site-manager-ghi-list">
              <div className="ghi-errors" />
              <label className="grey-box__form__item-label ghi__list" htmlFor="__email_">Project Id</label>
              <input type="text" name="[project_id]]" id="_project_id" className="input__ghi-number" value={inputVal} placeholder="e.g. ABC_01" onChange={this.handleInputChange} onClick={this.handleInputChange} required />

              <div className="ghi-list__dropdown" style={{ display: displayDropdown ? 'block' : 'none' }}>
                <button type="button" className="ghi-list__input-value">
                  {lastProjectId}
                </button>
                <div className="ghi-list__dropdown-header">Project ID List</div>
                <div className="divider-line" />
                <div>
                  {results}
                </div>
              </div>

              <div className="buttons-row" />

              <button type="button" className="btn__size-dynamic btn__color-blue" disabled={!submitEnabled} onClick={this.handleSubmit}>Send Portal Study Manager Invitation</button>

              <div className="site-manager-checkbox__container">
                <input type="checkbox" name="invitation_email" id="_first_name" className="site-manager-checkbox" defaultChecked="true" value={sendInvitationEmail} onChange={this.handleCheckmarkChange('sendInvitationEmail')} />
                Send invitation email
                <br />

                <input type="checkbox" name="courtesy_email" id="_first_name" className="site-manager-checkbox" defaultChecked="true" value={sendInvitationEmail} onChange={this.handleCheckmarkChange('sendCourtesyEmail')} />
                Send courtesy emails to ordering physicians
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { projectIds } = this.state;
    if (!projectIds) {
      return null;
    }

    const content = this.content();

    return (
      <div className="modal__container-outer study-manager-signup">
        <div className="modal__container-inner">
          <div className="modal__content">
            <div className="sign_up__site-manager">
              <button className="modal-close" type="button" onClick={this.handleCloseModal}>
                <i className="fa fa-close" />
              </button>
              <div className="sign_up__site-manager__container">
                <div className="header">
                  Portal Study Manager Sign Up
                </div>
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StudyManagerSignup.propTypes = IPropTypes;
export default StudyManagerSignup;
