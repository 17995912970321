import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { OrdersContainer } from '../../components/orders/orders-container';
import { logger } from '../../util/logger';
import { OrdersController } from '../../networking/controllers/orders';
import { OrderSerializer } from '../../networking/serializers/order';
import { constants } from '../../config/constants';
import { orderStatuses } from '../../models/order/order';

const IPropTypes = {
  clearActiveOrder: PropTypes.func.isRequired,
  saveOrder: PropTypes.func.isRequired,
  receiveError: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
    isExact: PropTypes.boolean,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

class ProgressionOrderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: [],
    };
  }

  async componentDidMount() {
    window.showSpinner();

    const errors = [];
    try {
      const {
        [orderStatuses.incomplete]: count,
      } = await OrdersController.getOrdersAmounts(orderStatuses.incomplete);

      if (count >= constants.etrfOrdering.maxPendingOrders) {
        errors.push(constants.etrfOrdering.maxPendingOrdersError);
      } else {
        const response = await OrdersController.getProgressionOrder(
          this.props.match.params.requestId,
        );
        if (response.data.order === null) {
          this.props.clearActiveOrder();
        } else {
          const deseralizedOrder = OrderSerializer.deserialize(response.data.order);
          this.props.saveOrder(deseralizedOrder);
        }
        this.setState({ loading: false });
      }
    } catch (error) {
      logger.error(error);

      if (error.response.status === 400 || error.response.status === 403) {
        errors.push(error.response.data.error);
      } else {
        errors.push(constants.etrfOrdering.errorEncounteredMessage);
      }
    } finally {
      this.props.receiveError(errors);
      this.setState({ errors });
      window.hideSpinner();
    }
  }

  render() {
    const { loading, errors } = this.state;

    if (errors.length > 0) {
      return <Redirect to="/orders" />;
    }

    return !loading && <OrdersContainer />;
  }
}


ProgressionOrderContainer.propTypes = IPropTypes;

export { ProgressionOrderContainer };
