import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  reportDate: PropTypes.string.isRequired,
  releaseDateHour: PropTypes.string.isRequired,
  releaser: PropTypes.string.isRequired,
};

const ReleaseActivity = ({ reportDate, releaseDateHour, releaser }) => (
  <div>
    <p className="release-reports__activity-log-event">
      {`${releaser} `}
      <b className="release-reports__report-date">
        report from&nbsp;
        {reportDate}
      </b>
      &nbsp;to patient
    </p>
    <p className="release-reports__activity-log-timestamp">{releaseDateHour}</p>
  </div>
);

ReleaseActivity.propTypes = IPropTypes;

export default ReleaseActivity;
