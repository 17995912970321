import React from 'react';
import PropTypes from 'prop-types';

import baseEtrfStyles from '../../../../assets/stylesheets/etrf/base.module.scss';
import styles from './clinical-history-early-stage-expanded.module.scss';

const IPropTypes = {
  clinicalHistoryEarlyStore: PropTypes.object.isRequired,
  clinicalHistoryQuestions: PropTypes.object.isRequired,
  saveEarlyStageClinicalHistoryInfo: PropTypes.func.isRequired,
};

const ClinicalHistoryEarlyStageExpanded = ({
  clinicalHistoryEarlyStore,
  clinicalHistoryQuestions,
  saveEarlyStageClinicalHistoryInfo,
}) => {
  const updateInput = id => (event) => {
    const { value } = event.target;
    saveEarlyStageClinicalHistoryInfo({ [id]: value }, true);
  };

  return (
    <div className={[baseEtrfStyles.accordionSection, styles.container].join(' ')}>
      {
        Object.keys(clinicalHistoryQuestions).map((questionKey, idx) => (
          <div className={styles.row} key={questionKey}>
            <span className={styles.columnText}>
              { clinicalHistoryQuestions[questionKey].description }
            </span>
            {
              clinicalHistoryQuestions[questionKey].answers.map((answer) => {
                const id = `relevant-clinical-history-${idx}-${answer}`;
                return (
                  <span className={styles.columnCheckbox} key={answer}>
                    <input
                      id={id}
                      type="radio"
                      value={answer}
                      checked={clinicalHistoryEarlyStore[questionKey] === answer}
                      onChange={updateInput(questionKey)}
                    />
                    <label htmlFor={id}>{answer}</label>
                  </span>
                );
              })
            }
          </div>
        ))
      }
    </div>
  );
};

ClinicalHistoryEarlyStageExpanded.propTypes = IPropTypes;

export { ClinicalHistoryEarlyStageExpanded };
