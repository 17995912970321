import { BiomarkerSerializer } from './biomarker';

export class BiomarkersTableSerializer {
  static deserialize = data => (
    data && {
      biomarkers: data.rows.map(b => BiomarkerSerializer.deserialize(b)),
      footnote: data.footnote,
    }
  );
}
