import {
  FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { schema } from '../schema';
import ValidationJson from '../validate.json';
import Controls from '../../admin/common/components/forms/controls';
import Validation from '../../admin/common/utils/Validation';
import {getChildDiseases, getRootDisease} from "../helpers";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'row !important',
  },
  errorBox: {
    padding: '10px'
  },
  helperText: {
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    'overflow-y': 'clip'
  },
  hide: {
    display: 'none'
  }
}));
function ClinicalHistoryReveal(props) {
  const classes = useStyles();
  const { newOrder } = props;
  const orderManagement = newOrder && newOrder.toJS();
  const noAdjuvantTherapy = orderManagement?.schema?.order?.diagnosis?.noAdjuvantTherapy;
  const isAdjuvantTherapy = noAdjuvantTherapy === true || noAdjuvantTherapy === 'true';
  const isnoAdjuvantTherapy = noAdjuvantTherapy === false || noAdjuvantTherapy === 'false';

  const onInputChange = (e, questionObj) => {
    const { name, value } = e.target;
    if (questionObj && name === 'relevantClinicalHistory') {
      let relCliHistory = newOrder.getIn(schema.clinicalHistory.relevantClinicalHistory) || [];
      relCliHistory = [...relCliHistory.filter(x => x.questionNumber !== questionObj.order),
        { questionNumber: questionObj.order, answerValue: value }];
      props.actions.onChange({ name: schema.clinicalHistory[name], value: relCliHistory });
    } else {
      props.actions.onChange({ name: schema.clinicalHistory[name] || [''], value });
      props.actions.setError({ name, value: '' });
    }
    const { clinicalHistory } = ValidationJson;
    if (ValidationJson.clinicalHistory[name]) {
      let error = Validation(name, value, clinicalHistory) || '';
      error = ValidationJson.clinicalHistory[name].required && error.trim().length > 0 && (value || '').length === 0 ? 'Required' : error;
      props.actions.setError({ name, value: error });
    }
  };
  useEffect(() => {
    if (!orderManagement.clinicalHistoryReveal) {
      props.actions.getClinicalHistoryReveal();
    }
  }, [orderManagement.clinicalHistoryReveal]);

  const childDiseases = getChildDiseases(newOrder.getIn(schema.diagnosis.disease), orderManagement.cancerTypeList);
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item lg={8} justifyContent="center" alignItems="center">
        <Typography sx={{ my: '30px' }} component="h5" variant="h5">4. Clinical History</Typography>
        {childDiseases.length > 0
          && (
            <Grid item sx={{ mb: '30px' }} lg={5} xs={12}
                  className={(orderManagement?.errors?.diagnosisOther?.length > 0
                    || orderManagement?.errors?.subDiagnosis?.length > 0) ? `error ${classes.errorBox}` : ''}
            >
              <RadioGroup
                name="subDiagnosis"
                onChange={(e) => {
                  const { name, value } = e.target;
                  props.actions.onChange({ name: schema.clinicalHistory.molecularMarker, value: undefined });
                  props.actions.onChange({ name: schema.diagnosis.diagnosisOther, value: undefined });
                  props.actions.onChange({name: schema.diagnosis.disease,
                    value: getRootDisease(newOrder.getIn(schema.diagnosis.disease), orderManagement.cancerTypeList)?.diagnosis});
                  if (name === 'molecularMarker') {
                    props.actions.onChange({ name: schema.clinicalHistory.molecularMarker, value });
                  } else if (name === 'disease') {
                    props.actions.onChange({ name: schema.diagnosis.disease, value });
                  }
                  if (value.toUpperCase().indexOf("OTHER") !== 0) {
                    props.actions.setError({ name: 'diagnosisOther', value: '' });
                  }
                  props.actions.setError({ name: 'subDiagnosis', value: '' });
                }}
                value={newOrder.getIn(schema.clinicalHistory.molecularMarker) || newOrder.getIn(schema.diagnosis.disease)}
              >
                <Grid item lg={12} xs={12}>
                  <Typography component="span" variant="body1">Additional Diagnosis Information &nbsp;</Typography>
                  <Typography component="span" variant="caption" color={orderManagement.errors?.subDiagnosis && 'error'}>
                    Required
                  </Typography>
                </Grid>
                <Typography component="span" variant="caption" color={orderManagement.errors?.subDiagnosis && 'error'}>Please select a subtype</Typography>
                {childDiseases.map(val => (
                  <FormControlLabel
                    className={classes.checkMark}
                    value={val.diagnosis}
                    control={<Radio color="primary" />}
                    label={val.trfDisplayName}
                    sx={{ mt: 0, mb: 0 }}
                    name={val.type}
                  />
                ))}
                {newOrder.getIn(schema.diagnosis.disease).toUpperCase().indexOf('OTHER') === 0 && (
                  <Controls.Input
                    name="diagnosisOther"
                    sx={{ m: 1 }}
                    label="Please enter a subtype"
                    /*color={orderManagement.errors?.subDiagnosis && 'error'}*/
                    value={newOrder.getIn(schema.diagnosis.diagnosisOther) || ''}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.indexOf(' ') === 0) {
                        value = value.trim();
                      }
                      value = value.replace(/\s{2,}/g, ' ');
                      props.actions.onChange({ name: schema.diagnosis.diagnosisOther, value });
                      let error = Validation('diagnosisOther', value, ValidationJson?.diagnosis);
                      error = ValidationJson.diagnosis?.diagnosisOther?.required && error.trim().length > 0 && (value || '').length === 0 ? 'Required' : error;
                      props.actions.setError({ name: 'diagnosisOther', value: error });
                      props.actions.setError({ name: 'subDiagnosis', value: error });
                    }}
                    inputProps={{ maxLength: 21 }}
                    error={orderManagement?.errors?.diagnosisOther || orderManagement?.errors?.subDiagnosis}
                    /*className={(orderManagement?.errors?.diagnosisOther?.length > 0
                      || orderManagement?.errors?.subDiagnosis?.length > 0) ? 'error' : ''}*/
                  />
                )}
              </RadioGroup>
            </Grid>
          )
        }
        <Typography component="div" variant="body1" sx={{ mb: '10px' }}>Relevant Clinical History</Typography>
        <Grid container direction="row" spacing={2} xs={12}>
          {orderManagement?.clinicalHistoryReveal?.map(val => (
            <Grid item xs={12}>
              <Typography component="div" variant="body2">
                {val.description}
              </Typography>
              <FormControl>
                <RadioGroup
                  name="relevantClinicalHistory"
                  className={classes.root}
                  onChange={e => onInputChange(e, val)}
                  value={newOrder.getIn(schema.clinicalHistory.relevantClinicalHistory)?.find(x => x.questionNumber === val.order)?.answerValue || ''}
                >
                  <FormControlLabel
                    className={classes.checkMark}
                    value={val.answers[0]}
                    control={<Radio color="primary" />}
                    label={val.answers[0]}
                  />
                  <FormControlLabel
                    className={classes.checkMark}
                    value={val.answers[1]}
                    control={<Radio color="primary" />}
                    label={val.answers[1]}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          ))}
          <Grid lg={4} xs={6} sx={{ pl: '16px' }}>
            <Typography component="span" variant="caption"
                        color={orderManagement.errors.dateOfSurgicalResection && 'error'}
            >
              Required
            </Typography>
            <Controls.DatePicker
              sx={{ mt: '5px', minWidth: '22rem' }}
              mask="__-__-____"
              label="Date of Surgical Resection"
              name="dateOfSurgicalResection"
              type="date"
              fullWidth
              inputFormat="MM-DD-YYYY"
              maxDate={moment()}
              minDate={moment().year(1900).month(0).date(1)}
              value={newOrder.getIn(schema.clinicalHistory.dateOfSurgicalResection) || null}
              error={orderManagement.errors.dateOfSurgicalResection}
              FormHelperTextProps={orderManagement.errors.dateOfSurgicalResection === 'Required' ? { classes: { root: classes.hide } } : { classes: { root: classes.helperText } }}
              onChange={e => {
                const name = 'dateOfSurgicalResection';
                props.actions.setError({ name: name, value: '' });
                props.actions.onChange({ name: schema.clinicalHistory.dateOfSurgicalResection, value: undefined });
                if (!e) {
                  props.actions.setError({ name: name, value: 'Required' });
                  return;
                }
                const val = moment(e);
                if(!val.isValid()){
                  props.actions.setError({ name: name, value: 'Please select a valid date in MM-DD-YYYY format' });
                  return;
                }
                const isFutureDate = val.isAfter(new Date(), 'day');
                const isPastDate = val.isBefore(moment().year(1900).month(0).date(1), 'day');
                if (isFutureDate || isPastDate) {
                  props.actions.setError({ name: name, value: 'Please select a date between 01-01-1900 to today' });
                  return;
                }
                props.actions.onChange({ name: schema.clinicalHistory[name], value: val.format('YYYY-MM-DD').toString() });
              }}
            />
          </Grid>
          <Grid lg={1} xs={12}>
          </Grid>
          <Grid
            lg={7}
            xs={12}
            className={(orderManagement?.errors?.noAdjuvantTherapy?.length > 0
              || orderManagement?.errors?.dateOfAdjuvantChemotherapy?.length > 0) ? `error ${classes.errorBox}` : ''}
          >
            <Typography component="span" variant="caption"
                        color={(orderManagement?.errors?.noAdjuvantTherapy
                          || orderManagement?.errors?.dateOfAdjuvantChemotherapy) && 'error'}>
              Required
            </Typography>
            <RadioGroup
              name="noAdjuvantTherapy"
              xs={12}
              onChange={e => {
                props.actions.onChange({ name: schema.diagnosis.noAdjuvantTherapy, value: e.target.value === 'true' });
                if (e.target.value === 'true' || e.target.value === true) {
                  props.actions.onChange({ name: schema.clinicalHistory.dateOfAdjuvantChemotherapy, value: moment().format('YYYY-MM-DD').toString() });
                  setTimeout(() => props.actions.onChange({ name: schema.clinicalHistory.dateOfAdjuvantChemotherapy, value: null }), 10);
                  props.actions.setError({ name: 'dateOfAdjuvantChemotherapy', value: '' });
                }
                props.actions.setError({ name: 'noAdjuvantTherapy', value: '' });
              }}
              value={noAdjuvantTherapy === undefined ? noAdjuvantTherapy : `${noAdjuvantTherapy}`}
            >
              <FormControlLabel
                className={classes.checkMark}
                sx={{ minWidth: '80%' }}
                control={(
                  <Radio
                    checked={isnoAdjuvantTherapy}
                    color="primary"
                  />
                )}
                value={"false"}
                label={(
                <Grid xs={12} sx={{ display: 'flex'}}>
                  <Grid lg={8} xs={12}>
                    <Controls.DatePicker
                      mask="__-__-____"
                      name="dateOfAdjuvantChemotherapy"
                      type="date"
                      label="Date of Adjuvant Chemotherapy Completion"
                      sx={{ minWidth: '22rem' }}
                      disabled={noAdjuvantTherapy}
                      inputFormat="MM-DD-YYYY"
                      maxDate={moment()}
                      minDate={moment().year(1900).month(0).date(1)}
                      value={newOrder.getIn(schema.clinicalHistory.dateOfAdjuvantChemotherapy) || null}
                      error={orderManagement.errors.dateOfAdjuvantChemotherapy}
                      FormHelperTextProps={orderManagement.errors.dateOfAdjuvantChemotherapy === 'Required' ? { classes: { root: classes.hide } } : { classes: { root: classes.helperText } }}
                      onChange={e => {
                        const name = 'dateOfAdjuvantChemotherapy';
                        props.actions.setError({ name: name, value: '' });
                        props.actions.onChange({ name: schema.clinicalHistory[name], value: undefined });
                        if (!e) {
                          props.actions.setError({ name: name, value: 'Required' });
                          return;
                        }
                        const val = moment(e);
                        if(!val.isValid()){
                          props.actions.setError({ name: name, value: 'Please select a valid date in MM-DD-YYYY format' });
                          return;
                        }
                        const isFutureDate = val.isAfter(new Date(), 'day');
                        const isPastDate = val.isBefore(moment().year(1900).month(0).date(1), 'day');
                        if (isFutureDate || isPastDate) {
                          props.actions.setError({ name: name, value: 'Please select a date between 01-01-1900 to today' });
                          return;
                        }
                        props.actions.onChange({ name: schema.diagnosis.noAdjuvantTherapy, value: false });
                        props.actions.onChange({ name: schema.clinicalHistory[name], value: val.format('YYYY-MM-DD').toString() });
                        props.actions.setError({ name: 'noAdjuvantTherapy', value: '' });
                      }}
                    />
                  </Grid>
                  <Grid lg={4} xs={12} sx={{ mt: 2}}>
                    <Typography component="span" variant="body1" sx={{ ml: '5rem'}}>OR</Typography>
                  </Grid>
                </Grid>
              )}
              />
              <FormControlLabel
                className={classes.checkMark}
                control={(
                  <Radio
                    checked={isAdjuvantTherapy}
                    color="primary"
                  />
                )}
                label={<Typography variant="body2">Patient has not had adjuvant therapy or is currently on adjuvant therapy</Typography>}
                value={"true"}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ClinicalHistoryReveal;

ClinicalHistoryReveal.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};
