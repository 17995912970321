import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { DateInput } from '../../../generic/DateInput';
import { FormatHelper } from '../../helpers/format_helper';
import { OnlineOrderingFieldsLimits } from '../../../../config/input-character-limit';

const IPropTypes = {
  saveBillingInfo: PropTypes.func.isRequired,
  billingInfo: PropTypes.object.isRequired,
};

const PrivateInsurance = ({
  billingInfo: {
    company,
    policyNumber,
    dob,
    groupNumber,
  },
  billingInfo,
  saveBillingInfo,
}) => {
  const updateTextField = id => useCallback(
    ({ target: { value } }) => saveBillingInfo(
      { [id]: value },
      billingInfo[id] !== value,
    ),
    [billingInfo[id]],
  );

  const updateDateField = id => useCallback(
    value => saveBillingInfo(
      { [id]: value === '' ? null : value },
      billingInfo[id] !== value,
    ),
    [billingInfo[id]],
  );

  return (
    <div className="private-insurance-container ">
      <div className="flex-container__etrf-section">
        <div className="flex-container__etrf-row">
          <div className="private-insurance-column flex-3">
            <div className="action-label bold">Primary Insurance</div>
            <div className="content-space-breathable">
              <input
                placeholder="Insurance Company"
                type="text"
                value={company || ''}
                onChange={updateTextField('company')}
                maxLength={OnlineOrderingFieldsLimits.genericInputMaxLength}
              />
            </div>
          </div>
          <div className="private-insurance-column flex-2">
            <div className="action-label bold">Policy Number</div>
            <div className="content-space-breathable">
              <input
                placeholder="Insured Policy #"
                type="text"
                value={policyNumber || ''}
                onChange={updateTextField('policyNumber')}
                maxLength={OnlineOrderingFieldsLimits.genericInputMaxLength}
              />
            </div>
          </div>

          <div className="private-insurance-column flex-row flex-4">
            <div className="flex-container__etrf-column">
              <div className="action-label bold">Group Number</div>
              <div className="content-space-breathable">
                <input
                  placeholder="Insured Group #"
                  type="text"
                  value={groupNumber || ''}
                  onChange={updateTextField('groupNumber')}
                  maxLength={OnlineOrderingFieldsLimits.genericInputMaxLength}
                />
              </div>
            </div>

            <div className="private-insurance-column__no-margin">
              <div className="action-label bold">Insured DOB</div>
              <div className="content-space-breathable">
                <DateInput
                  value={dob}
                  maxDate={FormatHelper.getTodaysDate()}
                  onInputChange={updateDateField('dob')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PrivateInsurance.propTypes = IPropTypes;

export { PrivateInsurance };
