import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { OrdersController } from '../../networking/controllers/orders';
import { constants } from '../../config/constants';
import { OrdersContainer } from '../../components/orders/orders-container';
import { orderStatuses } from '../../models/order/order';

const IPropTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      productFamily: PropTypes.string,
    }),
  }).isRequired,
  receiveError: PropTypes.func.isRequired,
  fetchStaticData: PropTypes.func.isRequired,
  saveGenericInfo: PropTypes.func.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
  resetBloodCollectionInfo: PropTypes.func.isRequired,
  ordersAmounts: PropTypes.array,
};
const defaultProps = {
  ordersAmounts: [],
};

const NewOrderContainer = ({
  receiveError, fetchStaticData, saveGenericInfo, ordersAmounts,
  match: { params: { productFamily } }, updateLoadingCount, resetBloodCollectionInfo,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [newOrderNotAllowed, setNewOrderNotAllowed] = useState(false);

  useEffect(() => {
    (async () => {
      updateLoadingCount(1);
      try {
        let count = (ordersAmounts || {})[orderStatuses.incomplete];
        if (typeof count !== 'number') {
          const amounts = await OrdersController.getOrdersAmounts(orderStatuses.incomplete);
          ({ [orderStatuses.incomplete]: count } = amounts);
        }

        if (count >= constants.etrfOrdering.maxPendingOrders) {
          receiveError([constants.etrfOrdering.maxPendingOrdersError]);
          setNewOrderNotAllowed(true);
        } else {
          fetchStaticData(productFamily);
          saveGenericInfo({ productFamily });
          resetBloodCollectionInfo();
        }
      } catch (error) {
        receiveError([constants.etrfOrdering.errorEncounteredMessage]);
        setNewOrderNotAllowed(true);
      } finally {
        setIsLoading(false);
        updateLoadingCount(-1);
      }
    })();
  }, []);

  if (newOrderNotAllowed) {
    return <Redirect to="/orders" />;
  }

  return !isLoading && <OrdersContainer productFamily={productFamily} />;
};

NewOrderContainer.propTypes = IPropTypes;
NewOrderContainer.defaultProps = defaultProps;

export { NewOrderContainer };
