import { PatientSerializer } from './patient';
import { PhysicianSerializer } from './physician';
import { BillingSerializer } from './billing';
import { AccountSerializer } from './account';
import { SecondaryRecipientSerializer } from './secondary-recipient';
import { SpecimenSerializer } from './specimen';
import { RevealSerializer } from './reveal';
import { G360Serializer } from './g360';
import { constants } from '../../../config/constants';

const { productFamilies } = constants.etrfOrdering;

const productFamilySpecificDeserialization = (order, productFamily) => {
  switch (productFamily) {
    case productFamilies.reveal:
      return RevealSerializer.deserialize(order);
    default:
      return G360Serializer.deserialize(order);
  }
};

const productFamilySpecificSerialization = ({
  guardantRevealOrderingInfo, earlyStageDiagnosisInfo, earlyStageClinicalHistoryInfo,
  testSelectionInfo, diagnosisInfo, currentTherapyInfo, clinicalHistoryInfo,
  genericInfo: { productFamily },
}) => {
  switch (productFamily) {
    case productFamilies.reveal:
      return RevealSerializer.serialize({
        guardantRevealOrderingInfo, earlyStageDiagnosisInfo, earlyStageClinicalHistoryInfo,
      });
    default:
      return G360Serializer.serialize({
        testSelectionInfo, diagnosisInfo, currentTherapyInfo, clinicalHistoryInfo,
      });
  }
};

export class OrderSerializer {
  static deserialize = (order) => {
    const productFamily = (order.productFamily || {}).id;

    const deserializedOrder = {
      patientInfo: PatientSerializer.deserialize(order.patient),
      physicianInfo: {
        client: AccountSerializer.deserialize(order.client),
        physician: PhysicianSerializer.deserialize(order.authorizingProvider),
        secondaryRecipient: SecondaryRecipientSerializer.deserialize(order.secondaryRecipient),
      },
      billingInfo: BillingSerializer.deserialize(order.billing),
      genericInfo: {
        updatedAt: order.updatedAt && Date.parse(order.updatedAt),
        productFamily,
      },
      latestPrintDate: order.latestPrintDate && Date.parse(order.latestPrintDate),
      printDate: order.printDate && Date.parse(order.printDate),
      finalizationDate: order.orderDate && Date.parse(order.orderDate),
      signatureDate: order.signatureDate && Date.parse(order.signatureDate),
      specimen: SpecimenSerializer.deserialize(order.specimens),
      commentsText: order.comments,
      orderId: order.portalOrderId,
      status: order.status,
      orderingUser: order.orderingUser,
      esignature: order.esignature,
      ...productFamilySpecificDeserialization(order, productFamily),
    };

    const metadata = order.esignature || {};
    if (metadata.envelopeId) {
      deserializedOrder.envelopeId = metadata.envelopeId;
    }

    return deserializedOrder;
  };

  static serialize = (store) => {
    const {
      patientInfo,
      physicianInfo,
      billingInfo,
      testSelectionInfo,
      genericInfo: { productFamily },
    } = store;

    const { labClinicianText, specimenCollectionDate } = store.printModal || {};

    const serializedOrder = {
      patient: PatientSerializer.serialize(patientInfo),
      client: AccountSerializer.serialize(physicianInfo.client),
      secondaryRecipient: SecondaryRecipientSerializer.serialize(physicianInfo.secondaryRecipient),
      billing: BillingSerializer.serialize(billingInfo),
      specimens: SpecimenSerializer.serialize(
        { labClinicianText, specimenCollectionDate },
        testSelectionInfo.isTissueOption && testSelectionInfo.tissueSpecimenRetrieval,
      ),
      productFamily: { id: productFamily },
      ...productFamilySpecificSerialization(store),
    };

    const authorizingProvider = PhysicianSerializer.serialize(physicianInfo.physician);
    if (authorizingProvider) {
      serializedOrder.authorizingProvider = authorizingProvider;
    }

    return serializedOrder;
  };
}
