import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './download-button.module.scss';
import { RequestDownloadMenu } from '../../request-download-menu';
import { downloadPDFInNewTab } from '../../../helpers/download-pdf';
import { PatientPageController } from '../../../networking/controllers/patient-page';

const IPropTypes = {
  canDownloadReport: PropTypes.bool.isRequired,
  downloadButtonName: PropTypes.string,
  errorHandlingSupport: PropTypes.bool.isRequired,
  downloadLinks: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })).isRequired,
  showErrorMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  downloadButtonName: null,
};

const DownloadButton = (props) => {
  const [showLinksDropdown, setShowLinksDropdown] = useState(false);
  const {
    downloadLinks,
    downloadButtonName,
    canDownloadReport,
    errorHandlingSupport,
  } = props;

  const handleButtonClick = useCallback(async () => {
    const buttonLink = downloadLinks[0].link;
    window.showSpinner();
    try {
      if (errorHandlingSupport) {
        const response = await PatientPageController.downloadReport(buttonLink);
        downloadPDFInNewTab(response);
      } else {
        window.open(buttonLink, '_blank', 'noopener noreferrer');
      }
      window.hideSpinner();
    } catch (error) {
      window.hideSpinner();
      const { title, errorMessages } = error.response.data;
      props.showErrorMessage(title, errorMessages);
    }
  }, [downloadLinks, errorHandlingSupport]);

  if (downloadLinks.length === 1) {
    return (
      <button
        type="button"
        className={canDownloadReport ? styles.downloadButton : styles.disabledButton}
        onClick={handleButtonClick}
        disabled={!canDownloadReport}
      >
        <span className={styles.downloadText}>
          { downloadButtonName || 'Download Report' }
        </span>
        <i className="fa fa-download" />
      </button>
    );
  }

  return (
    <div className={styles.buttonDropdownContainer}>
      <button
        className={styles.downloadButton}
        disabled={!canDownloadReport}
        type="button"
        onClick={() => setShowLinksDropdown(!showLinksDropdown)}
      >
        <span className={styles.downloadText}>
          { downloadButtonName || 'Download Report' }
        </span>
        <i className="fa fa-download" />
      </button>
      {
        showLinksDropdown
        && (
          <RequestDownloadMenu
            menuKlass={styles.downloadMenu}
            withTitle={false}
            accessKlass={styles.downloadMenuLink}
            urls={downloadLinks}
            canDownloadReport
          />
        )
      }
    </div>
  );
};

DownloadButton.propTypes = IPropTypes;
DownloadButton.defaultProps = defaultProps;

export { DownloadButton };
