const getQuestionValue = (questions, questionNumber) => {
  const value = questions.find(q => q.order === questionNumber);

  if (value) {
    return {
      answers: value.answers,
      description: value.description,
    };
  }

  return {};
};

class RelevantClinicalHistoryOptionsSerializer {
  static deserialize = (clinicalHistory) => {
    if (!clinicalHistory) {
      return {};
    }

    return {
      chemotherapy: getQuestionValue(clinicalHistory, 1),
      evidenceOfRecurrence: getQuestionValue(clinicalHistory, 2),
      mrdSurveillance: getQuestionValue(clinicalHistory, 3),
    };
  }
}

export { RelevantClinicalHistoryOptionsSerializer };
