import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import React from 'react';
import DateAdapter from '@mui/lab/AdapterMoment';
import Input from './Input';

function DatePicker(props) {
  const {
    // eslint-disable-next-line react/prop-types
    name, label, value, error = null, onChange, inputFormat, ...other
  } = props;
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DesktopDatePicker
        label={label}
        name={name}
        id="input"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        inputFormat={inputFormat}
        value={value}
        {...other}
        onChange={onChange}
        formatDate={date => moment(date).format(inputFormat || 'MM/DD/YYYY')}
        renderInput={params => (
          <Input
            {...params}
            error={error}
            {...other}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
