const productFamiliesForOrdering = {
  g360: 'G360',
  reveal: 'REVEAL',
};

const selfInsuredValue = 'Patient (self)';

export const constants = {
  onlineOrderSections: {
    PHYSICIAN_INFORMATION: 'physicianInformation',
    PATIENT_INFORMATION: 'patientInformation',
    TEST_SELECTION_INFORMATION: 'testSelectionInformation',
    EARLY_DIAGNOSIS_CONDITION_INFORMATION: 'earlyDiagnosisConditionInformation',
    DIAGNOSIS_CONDITION_INFORMATION: 'diagnosisConditionInformation',
    CURRENT_THERAPY_INFORMATION: 'currentTherapyInformation',
    CLINICAL_HISTORY_INFORMATION: 'clinicalHistoryInformation',
    EARLY_CLINICAL_HISTORY_INFORMATION: 'earlyClinicalHistoryInformation',
    BILLING_INFORMATION: 'billingInformation',
    GUARDANT_REVEAL_ORDERING: 'guardantRevealOrdering',
  },

  billingInformation: {
    institution: [],
    selfPay: [],
    medicaid: ['Subscriber ID'],
    medicare: ['Member ID'],
    primaryInsurance: ['Insured', 'Primary Insurance', 'Policy Number', 'Group Number'],
    primaryInsuranceExternal: ['Insured', 'Insured Name', 'Primary Insurance', 'Policy Number', 'Group Number', 'Insured DOB'],
    selfInsuredValue,
    billingTypes: [
      {
        id: '1',
        title: 'Private Insurance',
        value: 'Private Insurance',
        key: 'type',
      },
      {
        id: '2',
        title: 'Medicare - Part B',
        value: 'Medicare - Part B',
        key: 'type',
      },
      {
        id: '3',
        title: 'Medicaid',
        value: 'Medicaid',
        key: 'type',
      },
      {
        id: '4',
        title: 'Institution (e.g., hospital)',
        value: 'Institution',
        key: 'type',
      },
      {
        id: '5',
        title: 'Self Pay',
        value: 'Self Pay',
        key: 'type',
      },
    ],
    insuredTypes: [
      {
        id: '1',
        title: selfInsuredValue,
        key: 'insuredRelationship',
      },
      {
        id: '2',
        title: 'Spouse',
        key: 'insuredRelationship',
      },
      {
        id: '3',
        title: 'Child',
        key: 'insuredRelationship',
      },
      {
        id: '4',
        title: 'Other',
        key: 'insuredRelationship',
      },
    ],
  },
  auditLogActions: {
    newTestRequisition: {
      [productFamiliesForOrdering.g360]: 'etrf_start_new_test_requisition_G360',
      [productFamiliesForOrdering.reveal]: 'etrf_start_new_test_requisition_REVEAL',
    },
    printForm: 'etrf_summary_page',
    progression_test_button: 'etrf_order_progression_test',
    viewPrintedOrder: 'etrf_view_printed_order',
  },
  etrfOrdering: {
    maxPendingOrders: 10,
    maxPendingOrdersReachedMessage: 'You have reached the maximum number of ‘Orders To Be Completed’. Please complete or discard at least one to start a new order.',
    maxPendingOrdersError: 'Please complete the unsubmitted orders prior to starting a new order.',
    errorEncounteredMessage: 'Error encountered. Please try again.',
    requiredFiledsMissingError: 'You cannot print this form because required fields are missing',
    testSelectionTypes: {
      liquidProfiling: 'Liquid Profiling',
      liquidToTissueProfiling: 'Liquid to Tissue Profiling',
      treatmentResponse: 'Treatment Response',
    },
    productFamilies: productFamiliesForOrdering,
  },
  printModal: {
    successMessageWithoutElectronicSignature: {
      [productFamiliesForOrdering.g360]: 'Congratulations, you are ready to print a Guardant360 CDx test requisition form.',
      [productFamiliesForOrdering.reveal]: 'Congratulations, you are ready to print a Guardant Reveal test requisition form.',
    },
    successSubMessageWithoutElectronicSignature: 'Please review the information below, and if available, please enter specimen details,\n and any additional order information.',
    successMessageWithElectronicSignature: 'You are ready to finalize a Guardant test requisition form.',
    successSubMessageWithElectronicSignature: 'Please review the information below and enter specimen details if available.',
    modalTextContentWithElectronicSignature: {
      reminders: {
        title: 'REMINDERS',
        signature: {
          title: 'SIGNATURE',
          content: 'Authorizing provider must either provide a digital signature (FINALIZE ORDER) or sign printed form (PRINT NOW).',
        },
        documents: {
          title: 'DOCUMENTS',
          preamble: 'Please send the following with blood sample:',
          content: [
            'Second page of Test Requisition Form *signed by patient*',
            'Patient face sheet',
            'Insurance card (front and back)',
            'Relevant test results (Pathology/cytology report, IHC, FISH)',
          ],
        },
      },
    },
    modalTextContentWithoutElectronicSignature: {
      reminders: {
        title: 'REMINDERS',
        signature: {
          title: 'SIGNATURES',
          content: 'A medical professional and the patient must sign the printed form.',
        },
        documents: {
          title: 'DOCUMENTS',
          preamble: 'Please include a copy of the following documentation:',
          content: [
            'patient face sheet',
            'patient/insured insurance card (front and back)',
            'if available, pathology/cytology report, IHC, FISH or other test results',
          ],
        },
      },
    },
    failureMessage: 'Missing or incomplete information may impact the processing and/or analysis \n of your patient’s sample',
    failureSubMessage: 'Please complete all highlighted sections on the printed form.',
    footerWarningWithoutElectronicSignature: 'WARNING: once printed, the information provided can no longer be edited using this tool.',
    footerWarningWithElectronicSignature: 'WARNING: Once finalized or printed, no further changes can be made in this tool.',
    infoCompleteness: {
      INCOMPLETE: 'INCOMPLETE',
      COMPLETE: 'COMPLETE',
    },
  },
  testTypes: {
    LDT: 'Guardant 360',
    CDX: 'Guardant360 CDx',
    CDX_EU: 'Guardant360 CDx EU',
    MR: 'Guardant360 Response',
    TISSUE: 'Guardant Tissue',
    LUNAR_PREFIX: 'LUNAR',
  },
  productIds: {
    LDT: 'G360',
    CDX: 'G360CDx',
    MR: 'G360MR',
    TISSUE: 'TG',
    LUNAR_PREFIX: 'LUNAR',
  },
  patientSexes: [
    'Male',
    'Female',
  ],
};
