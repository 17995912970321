import { connect } from 'react-redux';

import { showErrorBanner } from '../../../../store/actions/orders/active-order/form_errors';
import { ICDSearch } from './icd-search';

const mapStateToProps = ({ orders: { activeOrder: { genericInfo: { productFamily } } } }) => ({
  productFamily,
});

const mapDispatchToProps = dispatch => ({
  showErrorBanner: () => dispatch(showErrorBanner()),
});

const ConnectedICDSearch = connect(mapStateToProps, mapDispatchToProps)(ICDSearch);

export { ConnectedICDSearch as ICDSearch };
