
import { createAction } from 'redux-actions';

export const GET_ROLE_ASSIGNMENT = '@usermanagment-workflow/GET_ROLE_ASSIGNMENT';
export const getroleAssignment = createAction(GET_ROLE_ASSIGNMENT);

export const GET_ADMIN_ROLES = '@usermanagment-workflow/GET_ADMIN_ROLES';
export const getAdminRoles = createAction(GET_ADMIN_ROLES);

export const GET_ROLE_ASSIGNMENT_DONE = '@usermanagment-workflow/GET_ROLE_ASSIGNMENT_DONE';
export const getroleAssignmentDone = createAction(GET_ROLE_ASSIGNMENT_DONE);

export const ROLE_ASSIGNMENT_ERROR = '@usermanagment-workflow/ROLE_ASSIGNMENT_ERROR';
export const roleAssignmentError = createAction(ROLE_ASSIGNMENT_ERROR);

export const SAVE_ROLE_ASSIGNMENT = '@usermanagment-workflow/SAVE_ROLE_ASSIGNMENT';
export const saveRoleAssignment = createAction(SAVE_ROLE_ASSIGNMENT);

export const SAVE_ROLE_ASSIGNMENT_DONE = '@usermanagment-workflow/SAVE_ROLE_ASSIGNMENT_DONE';
export const saveRoleAssignmentDone = createAction(SAVE_ROLE_ASSIGNMENT_DONE);

export const SAVE_ROLE_ASSIGNMENT_ERROR = '@usermanagment-workflow/SAVE_ROLE_ASSIGNMENT_ERROR';
export const saveRoleAssignmentError = createAction(SAVE_ROLE_ASSIGNMENT_ERROR);

export const UPDATE_ROLE_ASSIGNMENT = '@usermanagment-workflow/UPDATE_ROLE_ASSIGNMENT';
export const updatRoleAssignment = createAction(UPDATE_ROLE_ASSIGNMENT);

export const UPDATE_ROLE_ASSIGNMENT_DONE = '@usermanagment-workflow/UPDATE_ROLE_ASSIGNMENT_DONE';
export const updatRoleAssignmentDone = createAction(UPDATE_ROLE_ASSIGNMENT_DONE);

export const UPDATE_ROLE_ASSIGNMENT_ERROR = '@usermanagment-workflow/UPDATE_ROLE_ASSIGNMENT_ERROR';
export const updatRoleAssignmentError = createAction(UPDATE_ROLE_ASSIGNMENT_ERROR);

export const DELETE_ROLE_ASSIGNMENT = '@usermanagment-workflow/DELETE_ROLE_ASSIGNMENT';
export const deleteRoleAssignment = createAction(DELETE_ROLE_ASSIGNMENT);

export const DELETE_ROLE_ASSIGNMENT_DONE = '@usermanagment-workflow/DELETE_ROLE_ASSIGNMENT_DONE';
export const deleteRoleAssignmentDone = createAction(DELETE_ROLE_ASSIGNMENT_DONE);

export const DELETE_ROLE_ASSIGNMENT_ERROR = '@usermanagment-workflow/DELETE_ROLE_ASSIGNMENT_ERROR';
export const deleteRoleAssignmentError = createAction(DELETE_ROLE_ASSIGNMENT_ERROR);

export const ROLE_ASSIGN_PAGINATION_CHANGE = '@usermanagment-workflow/ROLE_ASSIGN_PAGINATION_CHANGE';
export const changePageRoleAssignment = createAction(ROLE_ASSIGN_PAGINATION_CHANGE);

export const ON_CHANGE_ROLE_ASSIGNMENT = '@usermanagment-workflow/ON_CHANGE_ROLE_ASSIGNMENT';
export const onChangeRoleAssignment = createAction(ON_CHANGE_ROLE_ASSIGNMENT);

export const defaultRoleAssignment = createAction('');
