const clinicalHistoryEarlyActionTypes = {
  ADD_EARLY_STAGE_CLINICAL_HISTORY_INFO: 'ADD_EARLY_STAGE_CLINICAL_HISTORY_INFO',
};

const AddEarlyStageClinicalHistoryInfo = (info, hideBanner) => ({
  type: clinicalHistoryEarlyActionTypes.ADD_EARLY_STAGE_CLINICAL_HISTORY_INFO,
  info,
  hideBanner,
});

export { AddEarlyStageClinicalHistoryInfo, clinicalHistoryEarlyActionTypes };
