import { Alteration } from './alteration';

class AlterationsTable {
  constructor(params) {
    this.hasInfoModal = params.hasInfoModal;
    this.hasTherapyLegend = params.hasTherapyLegend;
    this.showInProgressMessage = params.showInProgressMessage;
    this.showCancelledMessage = params.showCancelledMessage;
    this.showContent = params.showContent;
    this.showFootnote = params.showFootnote;
    this.hideTherapies = params.hideTherapies;
    this.requestId = params.requestId;
    this.alterations = params.alterations.map(alteration => new Alteration(alteration));
    this.colorTable = params.colorTable;
    this.showSuperscript = params.showSuperscript;
    this.therapyUrl = params.therapyUrl;
    this.showFrequencyColumn = params.showFrequencyColumn;
  }
}

export { AlterationsTable };
