import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { logger } from '../../../../util/logger';
import { OrdersController } from '../../../../networking/controllers/orders';

import { DashboardContainer } from '../dashboard-container';

import styles from './orders-list-container.module.scss';

const IPropTypes = {
  orders: PropTypes.object,
  fetchConfiguration: PropTypes.func.isRequired,
  receiveOrders: PropTypes.func.isRequired,
  updateLoadingCount: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  startMessageAutoDismiss: PropTypes.func.isRequired,
};

const defaultProps = {
  orders: [],
};

const OrdersListContainer = ({
  orders, fetchConfiguration, status, receiveOrders, updateLoadingCount,
  startMessageAutoDismiss,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchConfiguration();
  }, []);

  useEffect(() => {
    if (!orders || !orders[status]) {
      (async () => {
        updateLoadingCount(1);
        setLoading(true);
        try {
          const affiliationGroupedOrders = await OrdersController.getOrdersByStatus(status);

          receiveOrders({ [status]: affiliationGroupedOrders });
        } catch (error) {
          logger.error(error);
        } finally {
          setLoading(false);
          updateLoadingCount(-1);
          startMessageAutoDismiss();
        }
      })();
    }
  }, [status]);

  if (loading || !orders) {
    return null;
  }

  return (
    <div className={styles.container}>
      {
        orders[status] && orders[status].length
          ? <DashboardContainer dashboardData={orders[status]} />
          : (
            <div className={styles.noOrders}>
              NO PREVIOUS TEST REQUISITIONS
            </div>
          )
      }
    </div>
  );
};

OrdersListContainer.propTypes = IPropTypes;
OrdersListContainer.defaultProps = defaultProps;

export { OrdersListContainer };
