/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SiteManager from '../SiteManager';
import ShareModel from '../../shares/ShareModel';

const IPropTypes = {
  current_user_delegator_download_report: PropTypes.object.isRequired,
  user_identity: PropTypes.string.isRequired,
  can_grant_access: PropTypes.bool.isRequired,
  can_download_report: PropTypes.bool.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  patientName: PropTypes.string,
  patientId: PropTypes.string,
  number: PropTypes.number,
};
const defaultProps = {
  patientName: '',
  patientId: '',
  number: null,
};
const DashboardSearch = (props) => {
  const {
    current_user_delegator_download_report: currentUserDownloadReport,
    user_identity: userIdentity,
    can_grant_access: canGrantAccess,
    can_download_report: canDownloadReport,
    patientName,
    patientId,
    number,
    authenticityToken,
  } = props;
  const [showShareModal, setShowShareModal] = useState(false);
  const [state, setState] = useState({
    show: false,
    searchText: '',
    modal: false,
  });
  const closeShareWindow = () => {
    setShowShareModal(!showShareModal);
  };
  const HandleModal = (modal) => {
    setState({ ...state, modal });
  };
  const showShareModalWindow = () => {
    setShowShareModal(!showShareModal);
  };
  const triggerChange = (e) => {
    const searchText = e.target.value;
    const matcher = new RegExp(`^${window.$.ui.autocomplete.escapeRegex(searchText)}`, 'i');
    const list = window.gon.dashboardSearchResults
      && window.gon.dashboardSearchResults.filter(x => x.searchTerms
        .some(y => matcher.test(y.term)));
    setState({ ...state, list, searchText });
  };

  const HandleLiClick = (item) => {
    window.location.assign(
      `http://${window.location.host}/patients/${item.value}`,
    );
  };
  const filterList = () => (
    state.list
      && state.list.map((x, i) => {
        const label = x.label.split('*');
        const splitNameDobLabel = label[0].split('~');
        return (
          <li
            className="ui-menu-item show-spinner"
            id={`ui-id-${i}`}
            tabIndex="-1"
            key={x.label}
            role="presentation"
            onMouseEnter={() => {
              setState({ ...state, searchText: x.label });
            }}
            onClick={() => HandleLiClick(x)}
          >
            <div className="name_dob">
              <div className="search__name">
                {splitNameDobLabel[0]}
              </div>
              <div className="search__dob">{splitNameDobLabel[1]}</div>
            </div>
            <div className="id_div">
              <div className="search__ids">
                {label[1]}
                {' '}
              </div>
            </div>
          </li>
        );
      })
  );
  return (
    <div className="dashboard_search_and_buttons">
      <div className="dashboard_search_and_buttons_left">
        <div className="search">
          <div className="fa fa-search search__icon" />
          <input
            className="search__input web-search"
            placeholder="Quick jump to patient (Name, DOB, ID)"
            onChange={triggerChange}
            type="search"
            value={state.searchText}
            onClick={() => setTimeout(() => {
              setState({ ...state, show: true });
            }, 1000)}
            onBlur={() => setTimeout(() => {
              setState({ ...state, show: false });
            }, 3000)
            }
          />
          <ul
            className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content"
            style={{ top: '50px' }}
          >
            {state.show && filterList()}
          </ul>
        </div>
      </div>
      {(currentUserDownloadReport) && (
        <div className="dashboard_search_and_buttons_right">
          {(userIdentity === 'site_manager' && canGrantAccess) && (
            <a
              className="site-manager-grant-access-link page-button-grey"
              modal-toggle="edit__site-manager-shares"
              onClick={() => HandleModal(true)}
            >
              <div className="fa fa-unlock-alt">&nbsp;</div>
              GRANT ACCESS
            </a>
          )}
          {
            (userIdentity !== 'site_manager' && canGrantAccess) && (
              <a
                className="site-manager-grant-access-link page-button-grey"
                modal-toggle="edit__site-manager-shares"
                onClick={showShareModalWindow}
              >
                <div className="fa fa-unlock-alt">&nbsp;</div>
                GRANT ACCESS
              </a>
            )
          }
          <div className="dropdown export-report-data-dropdown page-dropdown-toggle-grey">
            {canDownloadReport && (
              <a
                className="dropdown-toggle no-spinner"
                data-toggle="dropdown"
                href="#"
              >
                <div className="fa fa-download export-results">&nbsp;</div>
                EXPORT RESULTS
              </a>
            )}
            <ul className="dropdown-menu no-top-dropdown export-dropdown">
              <li>
                <a
                  className="button button__white download__excel"
                  target="_blank"
                  href="/patient_summary.csv"
                >
                  Download Excel
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
      {state.modal && <SiteManager {...props} HandleMadal={HandleModal} />}
      <ShareModel
        show={showShareModal}
        closeShareModal={closeShareWindow}
        patientName={patientName}
        patientId={patientId}
        number={number}
        authenticityToken={authenticityToken}
      />
    </div>
  );
};

export default DashboardSearch;
DashboardSearch.propTypes = IPropTypes;
DashboardSearch.defaultProps = defaultProps;
