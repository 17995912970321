class AccountDropdown {
  static deserialize = data => ({
    id: String(data.id),
    account: data.account_number,
    title: data.practice_name,
    phone: data.client_phone,
    fax: data.client_fax,
    address1: data.address1,
    address2: data.address2,
    country: data.country,
    state: data.state,
    city: data.city,
    zipCode: data.zip_code,
  })
}

export { AccountDropdown };
