import React, { useEffect, useState } from 'react';
import {
  Toolbar, InputAdornment, useMediaQuery, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import _ from 'loadsh';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ApplicationMessage from '../common/components/elements/ApplicationMessage';
import ConfirmModalWindow from '../common/components/elements/ConfirmModalWindow';
import ModalPopup from '../common/components/elements/ModalPopup';
import Pagination from '../common/components/elements/Table/Pagination';
import DescriptionAlerts from '../common/components/elements/DescriptionAlerts';
import Controls from '../common/components/forms/controls';
import Table from '../common/Table';
import TableBody from '../common/TableBody';
import TableHeader from '../common/TableHeader';
import AccountForm from './AccountForm';

const IPropTypes = {
  account: PropTypes.object,
  actions: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};
const defaultProps = {
  account: Immutable.fromJS({}),
};

const useStyles = makeStyles(() => createStyles({
  root: {
    boxSizing: 'border-box',
    textAlign: 'right',
    marginTop: '2rem',
    backgroundColor: '#FFFFFF',
  },
  searchInput: {
    width: '50%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
    backgroundColor: '#1A5EA7',
    boxShadow: 'none',
  },
  toolbar: {
    paddingTop: '2rem',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '20px',
    marginLeft: '10px',
  },
}));
const AccountView = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false, title: '', subTitle: '', onConfirm: () => {},
  });
  const [formData, setFormData] = useState(null);
  const [title, setTitle] = useState('');
  const classes = useStyles();
  const [disableAlias, setDisableAlias] = useState(false);
  const [discriptionAlt, setDiscpAlt] = useState(false);
  const headers = [
    { name: 'accountNumber', type: 'text' },
  ];
  const {
    account,
    roles,
    actions: {
      getAccountsData,
      deleteAccountsData,
      addAccountsData,
      onChange,
      changePagination,
    },
  } = props;
  const accontsList = account.toJS().accontsList || [];
  const notify = account.toJS().notify || {};
  const pagination = account.toJS().pagination || 5;
  const accontsManagement = account.toJS() || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (accontsList === null || (accontsManagement && accontsManagement.reload)) {
      setConfirmDialog({
        isOpen: false, title: '', subTitle: '', onConfirm: () => {},
      });
      getAccountsData(pagination);
      onChange({ key: 'reload', value: false });
    }
  }, [accontsList || (accontsManagement && accontsManagement.reload)]);
  useEffect(() => {
    if (accontsManagement.isOpen) {
      setConfirmDialog({
        isOpen: false,
        title: '',
        subTitle: '',
        onConfirm: () => {},
      });
      onChange({ key: 'isOpen', value: false });
    }
    if (accontsList.length === 0) {
      getAccountsData(pagination);
    }
  }, [accontsManagement.isOpen, pagination]);

  const addClickHandler = (e) => {
    e.preventDefault();
    setDisableAlias(false);
    setOpenPopup(true);
    setFormData(null);
    setTitle('Add Account');
  };
  const deleteClickHandler = (row) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Are you sure to delete this record',
      subTitle: '',
      onConfirm: () => {
        deleteAccountsData(row);
      },
    });
  };
  const setNotify = (dat) => {
    onChange({ key: 'notify', value: { ...dat } });
  };

  const submitHandler = (values, resetForm) => {
    addAccountsData(values);
    resetForm();
    setFormData(null);
    setOpenPopup(false);
  };
  const handlePagination = (e, pageNum) => {
    getAccountsData(pageNum);
    changePagination(pageNum);
  };
  const delayedQuery = _.debounce(q => getAccountsData(q), 500);

  const handleSearch = (e) => {
    if (e.target.value !== '') {
      delayedQuery(`name:contains=${e.target.value}&${new URLSearchParams(pagination).toString()}`);
    } else {
      delayedQuery(`${new URLSearchParams(pagination).toString()}`);
    }
  };
  const userPermission = {
    Delete: roles && roles.includes('DELETE'),
  };
  const actions = [
    { action: 'Delete', handleAction: deleteClickHandler },
  ];

  return (
    <React.Fragment>
      <div className={classes.title}>Accounts</div>
      <div id="organizationContainer" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Controls.Input
            label="Search Account"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text="Create Account"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={addClickHandler}
          />
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <Table>
              {!isMobile && (
              <TableHeader
                headers={[...headers]}
                {...{ rows: accontsList || [], actions }}
              />
              )}
              <TableBody
                headers={headers}
                {...{
                  rows: accontsList || [], actions, ...pagination, userPermission,
                }}
              />
            </Table>
            <Pagination
              count={accontsManagement.totalRecords}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNo}
              handleChangePage={(e, val) => handlePagination(e, { ...pagination, pageNo: val })}
              handleChangeRowsPerPage={e => handlePagination(e,
                { ...pagination, pageSize: e.target.value, pageNo: 0 })}
            />
          </Grid>
        </Grid>
        <ModalPopup title={title} openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <AccountForm
            disableAlias={disableAlias}
            formData={formData}
            edit={title === 'Edit Account'}
            submitHandler={submitHandler}
          />
        </ModalPopup>
        <ConfirmModalWindow confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        <ApplicationMessage notify={notify} setNotify={setNotify} />
        <DescriptionAlerts confirm={discriptionAlt} setConfirm={setDiscpAlt} />
      </div>
    </React.Fragment>
  );
};
export default AccountView;
AccountView.propTypes = IPropTypes;
AccountView.defaultProps = defaultProps;
