import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HttpService from '../../networking/services/HttpService';

function CanContactModal({ class_name: className }) {
  const [state, setState] = useState({ showLess: false, show: true });
  const HandleLiClick = (status) => {
    window.canContact({ status }, HttpService);
    setState({ showLess: false, show: false });
  };
  return (
    state.show && (
      <div
        className={`modal__container-outer consent-to-contact__modal ${className}`}
      >
        <div className="modal__container-inner">
          <div className="modal__content">
            <div className="consent-to-contact__form box-shadow">
              <div className="top">
                <div className="title">
                  May we contact you about research studies, new trials, and
                  other opportunities?
                  {!state.showLess ? (
                    <div
                      className="text-link clickable"
                      onClick={() => setState({ ...state, showLess: true })}
                    >
                      Learn more
                    </div>
                  ) : (
                    <div
                      className="text-link clickable "
                      onClick={() => setState({ ...state, showLess: false })}
                    >
                      Show less
                    </div>
                  )}
                </div>
                <div className="close">
                  <i
                    className="fa fa-close clickable"
                    onClick={() => HandleLiClick('hide_for_session')}
                  />
                </div>
              </div>
              {state.showLess && (
                <div className="collapsable-text hide">
                  Medicine is in constant evolution. Occasionally we find
                  information in our patients’ tests that may be helpful for
                  cancer researchers, or useful for getting a patient onto a new
                  therapy or into a trial. Clicking “I Agree” here will give us
                  permission to contact you about research studies, new trials,
                  or with other questions. Guardant Health will
                  {' '}
                  <u>never</u>
                  {' '}
                  sell or disclose your contact information.
                </div>
              )}
              <div className="divider-line" />
              <div className="bottom">
                <div className="title">
                  Yes, Guardant Health may contact me about research studies,
                  other opportunities, or with questions.
                </div>
                <div className="bottom__button-row">
                  <div
                    className="button form-submit__btn send__consent"
                    onClick={() => HandleLiClick('agree')}
                  >
                    I AGREE
                  </div>
                  <div
                    className="button button-clear-background close__consent"
                    onClick={() => HandleLiClick('do_not_show')}
                  >
                    Don&apos;t ask me again
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CanContactModal;
CanContactModal.propTypes = {
  class_name: PropTypes.string.isRequired,
};
