import { createAction } from 'redux-actions';

export const LOAD_SHARES_DATA = '@shares/LOAD_SHARES_DATA';
export const loadSharesData = createAction(LOAD_SHARES_DATA);

export const LOAD_SHARES_ERROR = '@shares/LOAD_SHARES_ERROR';
export const loadSharesError = createAction(LOAD_SHARES_ERROR);

export const LOAD_SHARES_DONE = '@shares/LOAD_SHARES_DONE';
export const loadSharesDone = createAction(LOAD_SHARES_DONE);

export const DOWNLOAD_REPORT = '@shares/DOWNLOAD_REPORT';
export const downloadReport = createAction(DOWNLOAD_REPORT);

export const DOWNLOAD_REPORT_ERROR = '@shares/DOWNLOAD_REPORT_ERROR';
export const downloadReportError = createAction(DOWNLOAD_REPORT_ERROR);

export const DOWNLOAD_REPORT_DONE = '@shares/DOWNLOAD_REPORT_DONE';
export const downloadReportDone = createAction(DOWNLOAD_REPORT_DONE);

export const ESIGN_REPORT = '@shares/ESIGN_REPORT';
export const eSignReport = createAction(ESIGN_REPORT);

export const ESIGN_REPORT_ERROR = '@shares/ESIGN_REPORT_ERROR';
export const eSignReportError = createAction(ESIGN_REPORT_ERROR);

export const ESIGN_REPORT_DONE = '@shares/ESIGN_REPORT_DONE';
export const eSignReportDone = createAction(ESIGN_REPORT_DONE);

export const LOAD_PHYSICIANS = '@shares/LOAD_PHYSICIANS';
export const loadPhysicians = createAction(LOAD_PHYSICIANS);

export const LOAD_PHYSICIANS_ERROR = '@shares/LOAD_PHYSICIANS_ERROR';
export const loadPhysiciansError = createAction(LOAD_PHYSICIANS_ERROR);

export const LOAD_PHYSICIANS_DONE = '@shares/LOAD_PHYSICIANS_DONE';
export const loadPhysiciansDone = createAction(LOAD_PHYSICIANS_DONE);

export const LOAD_PHYSICIAN_ADMINS = '@shares/LOAD_PHYSICIAN_ADMINS';
export const loadPhysicianAdmins = createAction(LOAD_PHYSICIAN_ADMINS);

export const LOAD_PHYSICIAN_ADMIN_ERROR = '@shares/LOAD_PHYSICIAN_ADMIN_ERROR';
export const loadPhysicianAdminError = createAction(LOAD_PHYSICIAN_ADMIN_ERROR);

export const LOAD_PHYSICIAN_ADMIN_DONE = '@shares/LOAD_PHYSICIAN_ADMIN_DONE';
export const loadPhysicianAdminDone = createAction(LOAD_PHYSICIAN_ADMIN_DONE);
