const genericActionTypes = {
  CLEAR_ACTIVE_ORDER: 'CLEAR_ACTIVE_ORDER',
  ADD_GENERIC_INFO: 'ADD_GENERIC_INFO',
};

const clearActiveOrder = () => ({
  type: genericActionTypes.CLEAR_ACTIVE_ORDER,
});

const addGenericInfo = info => ({
  type: genericActionTypes.ADD_GENERIC_INFO,
  info,
});

export {
  addGenericInfo, clearActiveOrder, genericActionTypes,
};
