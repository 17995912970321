import {
  TOGGLE_CONFIRMATION_MODAL,
  HIDE_FORM_VALIDATION_MESSAGE,
  SHOW_FORM_VALIDATION_MESSAGE,
} from '../../../actions/orders/active-order/confirmation_modal';

const initialState = {
  showConfirmationModal: false,
  showFormValidationMessage: false,
  warnings: [],
};

const confirmationModal = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationModal: !state.showConfirmationModal,
      };
    case HIDE_FORM_VALIDATION_MESSAGE:
      return {
        ...state,
        showFormValidationMessage: false,
      };
    case SHOW_FORM_VALIDATION_MESSAGE:
      return {
        ...state,
        showFormValidationMessage: true,
        warnings: action.warnings,
      };
    default:
      return state;
  }
};

export default confirmationModal;
