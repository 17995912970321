const getQuestionValue = (questions, questionNumber) => (
  (questions.find(q => q.questionNumber === questionNumber) || {}).answerValue
);

class EarlyStageClinicalHistorySerializer {
  static deserialize = (clinicalHistory) => {
    if (!clinicalHistory) {
      return {};
    }

    return {
      chemotherapy: getQuestionValue(clinicalHistory, 1),
      evidenceOfRecurrence: getQuestionValue(clinicalHistory, 2),
      mrdSurveillance: getQuestionValue(clinicalHistory, 3),
    };
  }

  static serialize = clinicalHistoryInfo => ([
    'chemotherapy',
    'evidenceOfRecurrence',
    'mrdSurveillance',
  ].map((key, index) => ({
    answerValue: clinicalHistoryInfo[key],
    questionNumber: index + 1,
  })));
}

export { EarlyStageClinicalHistorySerializer };
