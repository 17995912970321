import React from 'react';
import PropTypes from 'prop-types';

import { getGhPhyClientByGhiPracticeList } from '../util/ajax';

const IPropTypes = {
  selectedIds: PropTypes.array.isRequired,
  onCheckboxClick: PropTypes.func,
};

const defaultProps = {
  onCheckboxClick: () => {},
};

class GhiPracticeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ghiList: null,
    };
    this.updateGhiPracticeList = this.updateGhiPracticeList.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
  }

  componentDidMount() {
    getGhPhyClientByGhiPracticeList(this.props.selectedIds, this.updateGhiPracticeList);
  }

  updateGhiPracticeList(data) {
    this.setState({ ghiList: data });
  }

  generatePhysicianList() {
    const physiciansDivs = [];

    const keys = Object.keys(this.state.ghiList);

    for (let i = 0; i < keys.length; i += 1) {
      const currentKey = parseInt(keys[i], 10);

      const accountNumber = this.state.ghiList[`${currentKey}`][0].account_number;
      const practiceName = this.state.ghiList[`${currentKey}`][0].practice_name;
      physiciansDivs.push(
        <div key={`${currentKey}-${accountNumber}-${practiceName}`}>
          <div className="ghi-list__practice_name">
            {accountNumber}
            {' '}
            -
            {' '}
            {practiceName}
          </div>
        </div>,
      );
      for (let j = 0; j < this.state.ghiList[`${currentKey}`].length; j += 1) {
        const current = this.state.ghiList[`${currentKey}`][j];
        physiciansDivs.push(
          <div key={`${current.id}-${current.lastname}-${current.firstname}`}>
            <input
              type="checkbox"
              id={current.id}
              value={current.id}
              className="practice-manager__checkbox"
              onChange={this.handleCheckboxClick.bind(this)}
            />
            <span className="physician-admin-phyclient">
              {current.lastname}
              ,
              {' '}
              {current.firstname}
            </span>

          </div>,
        );
      }
    }

    return physiciansDivs;
  }

  handleCheckboxClick(e) {
    const { target: { id } } = e;
    this.props.onCheckboxClick(id);
  }

  render() {
    if (!this.state.ghiList) {
      return (<div className="modal-loading-spinner" />);
    }

    const ghiPhysicianList = this.generatePhysicianList();

    return (
      <div className="ghi-phyisicans-list">
        <div className="physician-description">ALL PHYSICIANS AT SELECTED PRACTICES:</div>
        <div className="physician-list-errors" />
        {ghiPhysicianList}
      </div>
    );
  }
}

GhiPracticeList.propTypes = IPropTypes;
GhiPracticeList.defaultProps = defaultProps;

export default GhiPracticeList;
