
import { constants } from '../../config/constants';

const { INCOMPLETE } = constants.printModal.infoCompleteness;

class RevealOrderingInfo {
  constructor(params) {
    this.orderFrequency = params.orderFrequency || {};
    this.preferenceForBloodDraw = params.preferenceForBloodDraw;
    this.surgicalResectionDate = params.surgicalResectionDate;
  }

  isComplete = () => !!this.orderFrequency.code;

  formatForPrintModal = () => {
    const isCompleted = this.isComplete();

    return {
      title: 'REVEAL ORDERING',
      info: isCompleted ? this.orderFrequency.name : INCOMPLETE,
      isCompleted,
    };
  }

  checkRequiredFields = () => !!this.orderFrequency.code;

  checkNeededSurgicalResectionDateMissing = () => !!(
    this.orderFrequency.requiresSurgicalResectionDate
      && !this.surgicalResectionDate
  );
}

export { RevealOrderingInfo };
