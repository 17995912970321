import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  Box, Divider, IconButton, Typography,
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { closeAlertWindow } from './actions';

const IPropTypes = {
  showAlertModal: PropTypes.bool.isRequired,
  discardOrder: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};
const AlertModal = ({ showAlertModal, discardOrder, actions: { closeAlert } }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={showAlertModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" />
        <DialogContent sx={{ padding: '80px 80px', textAlign: 'center' }}>
          <DialogContentText>
            <IconButton size="large">
              <ReportProblemIcon sx={{ height: '80px', width: '80px' }} color="error" />
            </IconButton>
            <Typography component="h3" variant="h3">Discard this Order?</Typography>
            <br />
            <Typography component="div" variant="body1">Are you sure you want to discard this order?</Typography>
            <br />
            <Typography>This can not be undone.</Typography>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box sx={{
            display: 'flex', justifyContent: 'space-around', width: '100%', padding: '10px 0',
          }}
          >
            <Button variant="outlined" onClick={discardOrder}>
              Yes, Discard Order
            </Button>
            <Button variant="contained" color="primary" onClick={() => closeAlert(false)}>
              No Don’t Discard Order
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const mapStateToProps = state => ({
  showAlertModal: state.newOrder.get('showSaveAndDiscardModal'),
}
);
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    closeAlert: closeAlertWindow,
  }, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
AlertModal.propTypes = IPropTypes;
