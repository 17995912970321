import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShareIndexCollaborator from './commons/components/ShareIndexCollaborator';
import AllPracticeShare from './commons/components/AllPracticeShare';
import {
  getPracticeList,
  getLastAndFirstName,
  emailWithParenthesis,
} from './helpers';
import ModalDialogBox from './commons/components/ModalDialogBox';
import ShareModel from './ShareModel';

const SharedAll = (props) => {
  const {
    siteManagersOfOrderingPhysician,
    studyManagersOfOrderingPhysician,
    physicianAdminsOfOrderingPhysician,
    yoursEmpty,
    allPracticeShares,
    accessClass,
    authenticityToken,
    patientName,
    patientId,
    number,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const showModalWindow = () => {
    setShowModal(!showModal);
  };
  const closeWindow = () => {
    setShowModal(!showModal);
  };
  const closeShareWindow = () => {
    setShowShareModal(!showShareModal);
  };
  const showShareModalWindow = () => {
    setShowShareModal(!showShareModal);
  };
  let siteManagersOrders = null;
  let studyMangersOrders = null;
  let physicianAdminsOrders = null;
  if (siteManagersOfOrderingPhysician.length > 0) {
    siteManagersOrders = siteManagersOfOrderingPhysician.map((info) => {
      const { firstname, lastname, email } = info[0];
      const lastAndFirstName = (firstname && lastname) ? [lastname, firstname].join(', ') : email;
      return (
        <ShareIndexCollaborator
          key={info[0].id}
          leftColName={lastAndFirstName}
          leftColEmail={`(${email})`}
          rightColName="THEY CAN VIEW AND GRANT ACCESS"
          sharingTitle="THIS SITE MANAGER CAN VIEW AND GRANT ACCESS TO"
          shareDescription={`All ${info[2].report_count} of your patient reports from ${info[2].practice_name}`}
          accessClass={accessClass}
          authenticityToken={authenticityToken}
        />
      );
    });
  }
  if (studyManagersOfOrderingPhysician.length > 0) {
    studyMangersOrders = studyManagersOfOrderingPhysician.map((permittedUser) => {
      const practiceList = getPracticeList(permittedUser);
      return practiceList.map(practice => (
        <ShareIndexCollaborator
          key={practice.id}
          leftColName={getLastAndFirstName(permittedUser)}
          leftColEmail={emailWithParenthesis(permittedUser)}
          rightColName="THEY CAN VIEW"
          sharingTitle="THIS STUDY MANAGER CAN VIEW"
          shareDescription={`All ${practice.count} of your patient reports from ${practice.practiceName}`}
          accessClass={accessClass}
          authenticityToken={authenticityToken}
        />
      ));
    });
  }
  if (physicianAdminsOfOrderingPhysician.length > 0) {
    physicianAdminsOrders = physicianAdminsOfOrderingPhysician.map((permittedUser) => {
      const practiceList = getPracticeList(permittedUser);
      const { shares, sharee, sharer } = permittedUser;
      return practiceList.map(practice => (
        <ShareIndexCollaborator
          key={practice.id}
          leftColName={getLastAndFirstName(permittedUser)}
          leftColEmail={emailWithParenthesis(permittedUser)}
          rightColName="THEY CAN VIEW AND GRANT ACCESS"
          shareDescription={`All ${practice.count} of your patient reports from ${practice.practiceName}`}
          share={shares[0]}
          sharee={sharee[0]}
          sharer={sharer[0]}
          accessClass={accessClass}
          authenticityToken={authenticityToken}
        />
      ));
    });
  }
  return (
    <React.Fragment>
      <div className="share-index__body">
        <div className="share-index__body__header">
          <div className="share-index__body__header-text margin-right__40px">
            <div className="share-index__body__header-title">
              Users with Access to Your Reports
              <i className="fa fa-info-circle shares_info_circle modal-info" onClick={showModalWindow} />
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="icon-container no-spinner modal-toggle" href="#">
            <i className="button__left-logo button__icon-dashboard-add fa fa-plus" onClick={showShareModalWindow} />
          </a>
        </div>
        <div className="share-index__collaborators-container">
          {siteManagersOrders}
          {studyMangersOrders}
          {physicianAdminsOrders}
          {
          yoursEmpty === false && (
            <AllPracticeShare
              allPracticeShares={allPracticeShares}
              accessClass={accessClass}
              authenticityToken={authenticityToken}
              display="yours"
            />
          )
         }
        </div>
      </div>
      <ModalDialogBox show={showModal} showModal={closeWindow} ModalContent="Manage who has access to Guardant360 reports you have ordered." />
      <ShareModel
        show={showShareModal}
        closeShareModal={closeShareWindow}
        patientName={patientName}
        patientId={patientId}
        number={number}
        authenticityToken={authenticityToken}
      />
    </React.Fragment>
  );
};
export default SharedAll;
SharedAll.propTypes = {
  siteManagersOfOrderingPhysician: PropTypes.array.isRequired,
  studyManagersOfOrderingPhysician: PropTypes.array.isRequired,
  physicianAdminsOfOrderingPhysician: PropTypes.array.isRequired,
  yoursEmpty: PropTypes.bool.isRequired,
  allPracticeShares: PropTypes.array.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  accessClass: PropTypes.string.isRequired,
  patientName: PropTypes.string,
  patientId: PropTypes.string,
  number: PropTypes.number,
};
SharedAll.defaultProps = {
  patientName: '',
  patientId: '',
  number: null,
};
