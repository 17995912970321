import { connect } from 'react-redux';

import { PhysicianInformationCollapsed } from './physician-information-collapsed';

const mapStateToProps = ({ orders: { activeOrder: { physicianInfo, formErrors } } }) => ({
  physicianStore: physicianInfo,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const ConnectedPhysicianInformationCollapsed = connect(
  mapStateToProps,
)(PhysicianInformationCollapsed);

export { ConnectedPhysicianInformationCollapsed as PhysicianInformationCollapsed };
