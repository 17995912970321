import React from 'react';
import PropTypes from 'prop-types';

import { ICDSearch } from '../icd-search';
import OutsideAlerter from '../../OutsideAlerter';
import { KeyCodes } from '../../../../constants/key_codes';

import styles from './secondary-codes.module.scss';

const IIcdCode = PropTypes.shape({
  code: PropTypes.string.isRequired,
});

const IPropTypes = {
  storeSelectedItem: PropTypes.func.isRequired,
  secondaryCode: PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  selectedCodes: PropTypes.shape({
    selectedSecondaryCode: IIcdCode.isRequired,
    selectedTertiaryCode: IIcdCode.isRequired,
    selectedQuarternaryCode: IIcdCode.isRequired,
    selectedQuinaryCode: IIcdCode.isRequired,
    selectedSenaryCode: IIcdCode.isRequired,
  }).isRequired,
};
class SecondaryCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
    };
    this.toggleShowSearch = this.toggleShowSearch.bind(this);
    this.toggleKeySearch = this.toggleKeySearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.close = this.close.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  toggleShowSearch(secondaryCode) {
    this.setState(prevState => ({
      showSearch: !prevState.showSearch,
    }));

    if (secondaryCode) {
      this.props.storeSelectedItem({
        code: secondaryCode.icdCode,
        description: secondaryCode.description,
        limsICDCode: secondaryCode.id,
      });
    } else if (secondaryCode === '') {
      this.props.storeSelectedItem({ code: '', description: '' });
    }
  }

  toggleKeySearch() {
    if (!this.state.showSearch) {
      document.addEventListener('keydown', this.handleKeyPress);
    }
  }

  handleKeyPress(event) {
    if (event.keyCode === KeyCodes.ENTER) {
      this.setState(prevState => ({
        showSearch: !prevState.showSearch,
      }));
      event.preventDefault();
    }
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  close() {
    this.setState({ showSearch: false });
  }

  render() {
    const { showSearch } = this.state;
    const { secondaryCode, selectedCodes } = this.props;
    const abbreviatedValue = secondaryCode && (secondaryCode.code || '');

    return (
      <>
        <div
          className={`diagnosis-button diagnosis-button__multiple ${(secondaryCode && secondaryCode.code) || showSearch ? '' : 'inactive'}`}
          onFocus={this.toggleKeySearch}
          onClick={() => this.toggleShowSearch()}
        >
          {abbreviatedValue || 'ICD.10'}
        </div>

        {
          showSearch
            ? (
              <div className="diagnosis-search-container">
                <OutsideAlerter onOutsideClick={this.toggleShowSearch}>
                  <ICDSearch
                    toggleShowSearch={this.toggleShowSearch}
                    selectedCodes={selectedCodes}
                    defaultValue={secondaryCode.code ? `${secondaryCode.code} ${secondaryCode.description}` : ''}
                    searchWrapperStyles={styles.searchWrapper}
                  />
                </OutsideAlerter>
              </div>
            )
            : null
        }
      </>
    );
  }
}
SecondaryCodes.propTypes = IPropTypes;

export { SecondaryCodes };
