import { connect } from 'react-redux';

import { PatientPageHeader } from './patient-page-header';

const mapStateToProps = store => ({
  rootUrl: store.patientPage.multiProduct.info.loggedUser.rootUrl,
});

const ConnectedPatientPageHeader = connect(
  mapStateToProps,
)(PatientPageHeader);

export { ConnectedPatientPageHeader as PatientPageHeader };
