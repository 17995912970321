import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SharedAll from './SharedAll';
import PhysicianSharesView from './PhysicianSharesView';
import SiteManagerView from './SiteManagerView';
import PhysicianAdminView from './PhysicianAdminView';
import StudyManagerView from './StudyManagerView';
import SinglePatientView from './SinglePatientView';
import SiteManagerShares from './SitemanagerShares';
import Spinner from '../admin/common/utils/spinner/Spinner';
import PhysicianView from './PhysicianView';
import PhysicianAdminListView from './PhysicianAdminListView';

const Shares = (props) => {
  const {
    shares: {
      all_patient_shares: allPatientShares = [],
      all_practice_shares: allPracticeShares = [],
      all_site_manager_shares: allSiteManagerShares,
      ordering_physicians_of_physician_admin: orderingPhysiciansOfPhysicianAdmin = [],
      ordering_physicians_of_site_manager: orderingPhysiciansOfSiteManager = [],
      ordering_physicians_of_study_manager: orderingPhysiciansOfStudyManager = [],
      physician_admin: isPhysicianAdmin,
      physician_admins_of_ordering_physician: physicianAdminsOfOrderingPhysician = [],
      practice_names: practiceNames = [],
      site_manager: isSiteManager,
      site_managers_of_ordering_physician: siteManagersOfOrderingPhysician = [],
      study_manager: isStudyManager,
      study_manager_stringified_project_list: studyManagerStringifiedProjectList = [],
      study_managers_of_ordering_physician: studyManagersOfOrderingPhysician = [],
      user_identity: userIdentity,
      phy_clients: physicianClients = [],
      patient_id: patientId = '',
      patient_name: patientName = '',
      number,
    },
    actions: {
      loadSharesDataList,
      downloadContractReport,
      eSignContractReport,
      loadPhysiciansList,
      loadPhysicianAdminList,
    },
    authenticityToken,
    accessClass,
    isLoading,
    physicians,
    physicinAdmins,
    viewAsUser,
  } = props;
  const {email = ''} = viewAsUser || {};
  useEffect(() => {
    loadSharesDataList();
    loadPhysiciansList(email);
    loadPhysicianAdminList(email);
  }, []);
  let description;
  if (userIdentity === 'site_manager') {
    description = `You are a Portal Site Manager for ${practiceNames.join(', ')}`;
  } else if (isStudyManager) {
    description = `You are a Portal Study Manager for ${studyManagerStringifiedProjectList}`;
  } else if (isPhysicianAdmin) {
    description = 'You may view and download Guardant360 reports';
  } else if (userIdentity === 'account_owner') {
    description = 'You are an ordering physician and an owner of Guardant360 reports';
  } else if (userIdentity === 'account_admin') {
    description = 'You may view and download Guardant360 reports';
  } else {
    description = 'You are a guest user';
  }
  let yoursEmpty = true;
  let theirsEmpty = true;
  let singlePatientEmpty = true;
  allPracticeShares.forEach((item) => {
    // eslint-disable-next-line no-unused-vars
    const [_, collabShares] = item;
    const { yours, theirs } = collabShares;
    if (yours !== undefined) {
      yoursEmpty = false;
    }
    if (theirs !== undefined) {
      theirsEmpty = false;
    }
  });
  if (allPatientShares.length > 0) {
    singlePatientEmpty = false;
  }
  const isSharedWithAll = !yoursEmpty || siteManagersOfOrderingPhysician.length > 0
    || studyManagersOfOrderingPhysician.length > 0 || physicianAdminsOfOrderingPhysician.length > 0;
  // eslint-disable-next-line max-len
  const isPhysicianWithOutReports = userIdentity === 'account_owner' && yoursEmpty && siteManagersOfOrderingPhysician.length === 0;
  // eslint-disable-next-line max-len
  const isSiteManagerWithTheirsEmpty = userIdentity === 'site_manager' || (!theirsEmpty && !isStudyManager);
  const isPhysicianUsersExists = physicians?.userList?.filter(it => it?.role === 'physician')?.length > 0;
  const isPhysicianAdminUsersExists = physicinAdmins?.userList?.filter(it => it?.role === 'physicianAdmin')?.length > 0;
  return (
    <React.Fragment>
      {
        isLoading
        && (
        <div className="share-index__container">
          <div className="share-index">
            <div className="share-index__header">
              <h1 className="share-index__header__page-title">Manage Permissions</h1>
              <h6 className="share-index__description">{description}</h6>
            </div>
            {
              isSharedWithAll && (
                <SharedAll
                  siteManagersOfOrderingPhysician={siteManagersOfOrderingPhysician}
                  studyManagersOfOrderingPhysician={studyManagersOfOrderingPhysician}
                  physicianAdminsOfOrderingPhysician={physicianAdminsOfOrderingPhysician}
                  yoursEmpty={yoursEmpty}
                  allPracticeShares={allPracticeShares}
                  authenticityToken={authenticityToken}
                  accessClass={accessClass}
                  patientName={patientName}
                  patientId={patientId}
                  number={number}
                />
              )
            }
            {
              isPhysicianWithOutReports && (
                <PhysicianSharesView
                  authenticityToken={authenticityToken}
                  accessClass={accessClass}
                  patientName={patientName}
                  patientId={patientId}
                  number={number}
                />
              )
            }
            {
              isSiteManagerWithTheirsEmpty && (
                <SiteManagerView
                  orderingPhysiciansOfSiteManager={orderingPhysiciansOfSiteManager}
                  allPracticeShares={allPracticeShares}
                  authenticityToken={authenticityToken}
                  accessClass={accessClass}
                />
              )
            }
            {
              isPhysicianAdmin && (
                <PhysicianAdminView
                  orderingPhysiciansOfPhysicianAdmin={orderingPhysiciansOfPhysicianAdmin}
                  allPracticeShares={allPracticeShares}
                  authenticityToken={authenticityToken}
                  accessClass={accessClass}
                />
              )
            }
            {
              !singlePatientEmpty && (
                <SinglePatientView
                  allPatientShares={allPatientShares}
                  authenticityToken={authenticityToken}
                  accessClass={accessClass}
                />
              )
            }
            {
              isStudyManager && (
                <StudyManagerView
                  orderingPhysiciansOfStudyManager={orderingPhysiciansOfStudyManager}
                  allPracticeShares={allPracticeShares}
                  authenticityToken={authenticityToken}
                  accessClass={accessClass}
                />
              )
            }
            {
              isSiteManager && (
                <SiteManagerShares
                  allSiteManagerShares={allSiteManagerShares}
                  authenticityToken={authenticityToken}
                  accessClass={accessClass}
                  physicianClients={physicianClients}
                />
              )
            }
            {
              isPhysicianUsersExists && (
                <PhysicianAdminListView
                  physicians={physicians}
                  downloadContractReport={downloadContractReport}
                  eSignContractReport={eSignContractReport}
                  viewAsUser={viewAsUser}
                />
              )
            }
            {
              isPhysicianAdminUsersExists && (
                <PhysicianView
                  physicinAdmins={physicinAdmins}
                  downloadContractReport={downloadContractReport}
                  eSignContractReport={eSignContractReport}
                  viewAsUser={viewAsUser}
                />
              )
            }
          </div>
        </div>
        )
      }
      <Spinner />
    </React.Fragment>
  );
};
export default Shares;
Shares.propTypes = {
  actions: PropTypes.object.isRequired,
  shares: PropTypes.object,
  authenticityToken: PropTypes.string.isRequired,
  accessClass: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
Shares.defaultProps
