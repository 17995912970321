/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PatientsShare from './PatientsShare';
import ModalDialogBox from './commons/components/ModalDialogBox';

const SinglePatientView = (props) => {
  const {
    allPatientShares,
    accessClass,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const showModalWindow = () => {
    setShowModal(!showModal);
  };
  const closeWindow = () => {
    setShowModal(!showModal);
  };
  const handlePermissionClick = (event) => {
    event.stopPropagation();
    const target = window.$(event.target).closest('.share-index__collaborator__top');
    window.$(target).find('.share-index__collaborator__left-col__email').toggle();
    window.$(target).find('.share-index__count-of-shares').toggle();
    window.$(target).next().slideToggle();
  };
  return (
    <React.Fragment>
      <div className="share-index__body">
        <div className="share-index__body__header">
          <div className="share-index__body__header-text">
            <div className="share-index__body__header-title">
              Users with Shared Patients
              <i className="fa fa-info-circle shares_info_circle modal-info" onClick={showModalWindow} />
            </div>
          </div>
        </div>
        <div className="share-index__collaborators-container">
          {
          allPatientShares.map((item) => {
            const [collab, collabShares] = item;
            let rightCol;
            let num;
            if (collabShares.yours) {
              num = 0;
              collabShares.yours.forEach((share) => {
                num = !share.revoked_at ? num + 1 : num;
              });
              if (num === 0) {
                rightCol = 'YOU ARE NO LONGER SHARING PATIENTS';
              } else {
                rightCol = `YOU ARE SHARING ${num} ${num > 1 ? 'PATIENTS' : 'PATIENT'}`;
              }
            }
            if (collabShares.theirs) {
              num = 0;
              collabShares.theirs.forEach((share) => {
                num = !share.revoked_at ? num + 1 : num;
              });
              if (num === 0) {
                rightCol = 'YOU ARE NO LONGER SHARING PATIENTS';
              } else {
                rightCol = `YOU ARE SHARING ${num} ${num > 1 ? 'PATIENTS' : 'PATIENT'}`;
              }
            }
            const lastName = collab.lastname == null
              ? collabShares.users[collab.id].lastname : collab.lastname;
            const firstName = collab.firstname == null
              ? collabShares.users[collab.id].firstname : collab.firstname;
            return (
              <div className="share-index__collaborator" key={collab.id}>
                <div className="share-index__collaborator__top" onClick={handlePermissionClick}>
                  <div className="share-index__collaborator__top-inner">
                    <div className="share-index__collaborator__left-col">
                      <div className="share-index__collaborator__left-col__name">{`${lastName}, ${firstName}`}</div>
                      <div className="share-index__collaborator__left-col__email">{`(${collab.email ? collab.email : ''})`}</div>
                    </div>
                    <div className="share-index__collaborator__right-col">
                      <div className="share-index__count-of-shares">
                        {rightCol}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="share-index__collaborator__bottom">
                  {
                    collabShares.yours && (
                    <>
                      <div className="share-index__you-sharing__title">
                        YOU ARE SHARING
                        {
                          collabShares.yours.map((share, index) => (
                            <PatientsShare
                              key={share.id + index}
                              share={share}
                              sharedWithYou={false}
                              accessClass={accessClass}
                              sharees={collabShares.sharees}
                              sharers={collabShares.sharers}
                              physicians={collabShares.physicians}
                              patients={collabShares.patients}
                            />
                          ))
                        }
                      </div>
                    </>
                    )
                  }
                  {
                    collabShares.theirs && (
                      <>
                        <div className="share-index__spacing" />
                        <div className="share-index__you-sharing__title">
                          YOU ARE SHARING
                          {
                            collabShares.theirs.map((share, index) => (
                              <PatientsShare
                                key={share.id + index}
                                share={share}
                                sharedWithYou
                                accessClass={accessClass}
                                sharees={collabShares.sharees}
                                sharers={collabShares.sharers}
                                physicians={collabShares.physicians}
                                patients={collabShares.patients}
                              />
                            ))
                          }
                        </div>
                      </>
                    )
                  }
                </div>
                <div className="share-index__spacing" />
              </div>
            );
          })
        }
        </div>
      </div>
      <ModalDialogBox show={showModal} showModal={closeWindow} ModalContent="You have either shared patients or have received shared patient invitations from these users. To share a patient report, open a patient report from the Dashboard and click Share This Report." />

    </React.Fragment>
  );
};
export default SinglePatientView;
SinglePatientView.propTypes = {
  allPatientShares: PropTypes.array.isRequired,
  accessClass: PropTypes.string,
};
SinglePatientView.defaultProps = {
  accessClass: '',
};
