// NOTE: this is a very intricate way of determining if a string is an email.
// The linter doesn't like the fact that the regular expression has control characters in them,
// but in order to avoid potential regressions, I've decided to ignore this rule here and
// avoid refactors that might break something.
// eslint-disable-next-line no-control-regex
import { config } from '../components/config/config';
import { INTERNAL_EMAIL_DOMAINS } from '../components/config';

// eslint-disable-next-line no-control-regex
const USERNAME_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
export const isUsername = text => USERNAME_REGEX.test(text);

const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// TODO: determine why we have two different regular expressions to check if a string is an email.
// Namely validEmail and isUsername.
export const validEmail = email => EMAIL_REGEX.test(email);

export const isValidUser = (user) => {
  const domains = config()[INTERNAL_EMAIL_DOMAINS].split(',');
  const userDomain = user.split('@')[1];
  return domains.includes(userDomain);
};
