/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

function PopDropdown(props) {
  const { items, handleMenuClick } = props;
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <>
          <Menu {...bindMenu(popupState)}>
            <MenuItem>
              Download:
              <p style={{ float: 'right', marginLeft: 'auto' }}>
                <i className="fa fa-download" />
              </p>
            </MenuItem>
            {items.map(option => (
              <MenuItem key={option.display_name} style={{ fontSize: 'small' }} onClick={handleMenuClick}>
                {option.display_name}
              </MenuItem>
            ))}
          </Menu>
          <Button className="row-download-btn" {...bindTrigger(popupState)}>
            <i className="fa fa-download" />
          </Button>
        </>
      )}
    </PopupState>
  );
}
export default memo(PopDropdown);
