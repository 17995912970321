import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isInternalUser } from '../networking/controllers/helper';
import { logger } from '../util/logger';

const IPropTypes = {
  authenticity_token: PropTypes.string.isRequired,
};

class SearchPhysicians extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '', limit: 30, minChars: 1, users: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  updateUsers(users) {
    this.setState({ users });
  }

  triggerChange(e) {
    const { timer } = this.state;
    if (timer) {
      clearTimeout(timer);
    }
    this.setState({
      searchString: e.target.value,
      timer: setTimeout(this.handleChange, 500),
    });
  }

  resetUsers() {
    this.updateUsers([]);
  }

  needToLoad() {
    const { searchString, minChars } = this.state;
    return (searchString.length > minChars);
  }

  reachedLimit() {
    const { users, limit } = this.state;
    return (users.length > limit);
  }

  handleChange() {
    const { limit, searchString } = this.state;
    const trimmedSearchString = searchString.trim().toLowerCase();
    if (this.needToLoad() === true) {
      window.getSearchPhysiciansDropdown(
        { keyword: trimmedSearchString, limit },
        this.updateUsers.bind(this),
      );
    } else {
      this.resetUsers();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  submitHandler(event) {
    const userId = event.target.user_id.value;
    const userSfdfcId = event.target.user_sfdc_id.value;
    const userNameAndEmail = event.target.user_name_and_email.value;
    const contactType = event.target.contact_type.value;
    event.preventDefault();
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken;
    const extHeader = isInternalUser === 'false' && { 'x-portal-auth-type': 'v2ext' };
    const headers = { Authorization: `Bearer ${accessToken}`, ...extHeader };
    const data = {
      contact_type: contactType,
      user_id: userId,
      user_sfdc_id: userSfdfcId,
      user_name_and_email: userNameAndEmail,
      utf8: '✓',
    };
    const searchParams = new URLSearchParams(data);
    axios.put(`rest/admin_view_as?${searchParams}`, {}, { headers })
      .then(res => window.location.assign(res.data.redirect_path))
      .catch(error => logger.error(error));
  }

  dropdown(users) {
    return users.map(user => (
      <form key={user.id} action="/admin_view_as" method="POST">
        <input type="hidden" name="_method" value="PUT" />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="auth_token" value={JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken} />
        <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
        <input type="hidden" name="user_id" id="user_id" value={user.id} />
        <input type="hidden" name="user_name_and_email" id="user_name_and_email" value={user.name} />
        <input type="hidden" name="contact_type" id="contact_type" value={user.portal_account_type} />
        <input type="hidden" name="user_sfdc_id" id="user_sfdc_id" value={user.sfdc_id} />
        <button type="submit" className="portal-user__row">
          <col-xs-1 class="user-icon-row">
            <i className="fa fa-user">&nbsp;</i>
          </col-xs-1>
          <col-xs-11 class="user-row-info">
            <div className="portal-user__row-name">
              <span>{user.name}</span>
              <span className="portal-user__row-status">{user.status}</span>
            </div>
            <div className="portal-user__row-email">
              {user.portal_account_type}
              {' '}
              {user.second_type ? `& ${user.second_type},` : ','}
              {' '}
              {user.status ? (user.practice_name || []).join(', ') : user.email_or_sfdc_id}
            </div>
          </col-xs-11>
        </button>
      </form>
    ));
  }

  filterUsers() {
    const { users } = this.state;
    window.$('.user-list__container').show();

    if (this.reachedLimit() === true) {
      const dropdown = this.dropdown(users);

      return (
        <div className="user-list__container">
          <div className="row_header">
            USER RESULTS
          </div>
          <div className="dropdown-rows">{dropdown}</div>
          <div className="row_footer">
            Too many records to display (
            {users.length}
            )
          </div>
        </div>
      );
    }
    if (users.length > 0) {
      const dropdown = this.dropdown(users);
      return (
        <div className="user-list__container">
          <div className="row_header">
            USER RESULTS
          </div>
          <div className="dropdown-rows">{dropdown}</div>
        </div>
      );
    }
    if (this.needToLoad() === true) {
      return (
        <div className="user-list__container">
          <div className="row_footer"> NO RESULTS</div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className="fa fa-search admin-search-icon" />
        <input className="view-as__search-bar" placeholder="Search for provider" onChange={this.triggerChange.bind(this)} type="text" />
        <div className="list-button">
          <i className="fa fa-times">&nbsp;&nbsp;&nbsp;</i>
        </div>
        <div className="users-list__dropdown">
          {this.filterUsers()}
        </div>
      </div>
    );
  }
}

SearchPhysicians.propTypes = IPropTypes;

export default SearchPhysicians;
