import React from 'react';
import PropTypes from 'prop-types';

import AdminManagePatientsSearchPage from './AdminManagePatientsSearchPage';
import AdminManagePatientsDetailPage from './AdminManagePatientsDetailPage';

const IPropTypes = {
  users: PropTypes.array,
  authenticity_token: PropTypes.string.isRequired,
};

const defaultProps = {
  users: null,
};

class AdminManagePatients extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 1, selectedUser: {}, selected: false };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect = (user, selected) => {
    this.setState({
      selectedUser: user,
      selected,
    }, () => {
      this.content();
    });
  }

  switchPage(n) {
    this.setState({ page: n, selected: false });
  }

  content() {
    if (this.state.page === 1) {
      return (
        <div className="content">
          <p>SEARCH FOR MYGUARDANT PATIENT</p>
          <AdminManagePatientsSearchPage
            users={this.props.users}
            callback={this.onSelect}
          />
          <button
            type="button"
            disabled={!this.state.selected}
            className="btn__size-dynamic btn__color-blue"
            onClick={this.switchPage.bind(this, 2)}
          >
            NEXT
          </button>
        </div>
      );
    }
    return (
      <div className="content">
        <div className="page-button-grey" onClick={this.switchPage.bind(this, 1)}>
          <i className="fa fa-angle-left" />
          <span> RESET PATIENT SELECTION</span>
        </div>
        <AdminManagePatientsDetailPage
          selectedUser={this.state.selectedUser}
          authenticity_token={this.props.authenticity_token}
        />
      </div>
    );
  }

  render() {
    const content = this.content();
    return (
      <div className="modal__container-outer manage__portal-patients">
        <div className="modal__container-inner align-top">
          <div className="modal__content">
            <div>
              <i className="fa fa-close modal-close" />
              <div className="header">
                Manage Patients
              </div>
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminManagePatients.propTypes = IPropTypes;
AdminManagePatients.defaultProps = defaultProps;

export default AdminManagePatients;
