import { connect } from 'react-redux';
import { CurrentTherapyInformationCollapsed } from './current-therapy-information-collapsed';

const mapStateToProps = ({ orders: { activeOrder: { currentTherapyInfo } } }) => ({
  currentTherapyInfoStore: currentTherapyInfo,
});

const ConnectedCurrentTherapyInformationCollapsed = connect(
  mapStateToProps,
)(CurrentTherapyInformationCollapsed);

export { ConnectedCurrentTherapyInformationCollapsed as CurrentTherapyInformationCollapsed };
