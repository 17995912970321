import React from 'react';

import styles from './LunarPatientReportSummary.module.scss';
import { reportSummaryHelperFactory, formatDate } from './ReportSummaryHelper';

const LunarPatientReportSummary = () => {
  const reportSummaryHelper = reportSummaryHelperFactory();
  let bloodCollectionDate = reportSummaryHelper.bloodCollectionDate();
  if (bloodCollectionDate) { bloodCollectionDate = bloodCollectionDate.toUpperCase(); }

  const data = {
    MRN: reportSummaryHelper.mrn(),
    DOB: reportSummaryHelper.dateOfBirth(),
    GENDER: reportSummaryHelper.gender(),
    DIAGNOSIS: reportSummaryHelper.diagnosis(),
    TEST_NUMBER: reportSummaryHelper.testNumber(),
    REPORT_STATUS: reportSummaryHelper.reportStatus(),
    ACCESSION_ID: reportSummaryHelper.requestId(),
    REPORT_DATE: formatDate(reportSummaryHelper.finalReportDate()),
    RECEIPT_DATE: formatDate(reportSummaryHelper.receivedDate()),
    COLLECTION_DATE: bloodCollectionDate,
    SPECIMEN: reportSummaryHelper.specimen(),
    SURGERY_DATE: reportSummaryHelper.surgeryDate(),
    PHYSICIAN: reportSummaryHelper.physicianName(),
    ACCOUNT: reportSummaryHelper.practiceName(),
    ADDRESS: reportSummaryHelper.formattedAddress(),
    PH: reportSummaryHelper.phoneNumber(),
    FAX: reportSummaryHelper.fax(),
    SECONDARY_PHYSICIAN: reportSummaryHelper.secondaryPhysicianName(),
    TEST_RESULTS: reportSummaryHelper.testResultName(),
    DETECTION_STATUS: reportSummaryHelper.testResultValue(),
  };

  const contentEntry = (labelText, value) => (
    <div className={styles.row}>
      <div className={styles.contentabel}>
        {labelText}
      </div>
      <div className={styles.contentData}>
        {value || 'N/A'}
      </div>
    </div>
  );

  const resultsSection = () => (
    <div className={styles.results}>
      <div className="col-md-3 patient-report-summary__first_col">
        <div className={styles.row}>
          <div className={styles.contentabel}>
            TEST RESULTS
          </div>
          <hr />
          <div className={styles.contentData}>
            {data.TEST_RESULTS || 'N/A'}
          </div>
        </div>
      </div>
      <div className="col-md-3 patient-report-summary__middle_col">
        <div className={styles.row}>
          <div className={styles.contentabel}>
            STATUS
          </div>
          <hr />
          <div className={styles.contentData}>
            {data.DETECTION_STATUS || 'N/A'}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className={styles.patientReportSummary}>
        <div className="container">
          <div className="col-md-12">
            <div className="col-md-3 patient-report-summary__first_col">
              {contentEntry('MRN', data.MRN)}
              {contentEntry('DOB', data.DOB)}
              {contentEntry('GENDER', data.GENDER)}
              {contentEntry('DIAGNOSIS', data.DIAGNOSIS)}
              {contentEntry('TEST NUMBER', data.TEST_NUMBER)}
              {contentEntry('STATUS', data.REPORT_STATUS)}
            </div>
            <div className="patient-summary-col-divider" />
            <div className="col-md-3 patient-report-summary__middle_col">
              {contentEntry('ACCESSION ID', data.ACCESSION_ID)}
              {contentEntry('REPORT DATE', data.REPORT_DATE)}
              {contentEntry('RECEIPT DATE', data.RECEIPT_DATE)}
              {contentEntry('COLLECTION DATE', data.COLLECTION_DATE)}
              {contentEntry('SPECIMEN', data.SPECIMEN)}
              {contentEntry('SURGERY DATE', data.SURGERY_DATE)}
            </div>
            <div className="patient-summary-col-divider" />
            <div className="col-md-5 patient-report-summary__last_col">
              {contentEntry('PHYSICIAN', data.PHYSICIAN)}
              {contentEntry('ACCOUNT', data.ACCOUNT)}
              {contentEntry('ADDRESS', data.ADDRESS)}
              {contentEntry('PH', data.PH)}
              {contentEntry('FAX', data.FAX)}
              {contentEntry('ADDITIONAL RECIPIENT', data.SECONDARY_PHYSICIAN || '-')}
            </div>
          </div>
          {resultsSection()}
        </div>
      </div>
    </div>
  );
};

export { LunarPatientReportSummary };
