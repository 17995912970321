import { requestsActionTypes } from '../../actions/requests';

const requestsInitialState = {
  requests: [],
  rootUrl: null,
};

const requestsReducer = (state = requestsInitialState, action) => {
  switch (action.type) {
    case requestsActionTypes.ADD_REQUESTS:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export { requestsReducer };
