import React from 'react';
import { Provider } from 'react-redux';

import { store } from '../../../store';
import { RequestsContainer } from '../requests-container';

const RequestsPageContainer = () => (
  <Provider store={store}>
    <RequestsContainer />
  </Provider>
);

export default RequestsPageContainer;
