/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const useStyles = makeStyles(() => createStyles({
  borderRight: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  label: {
    fontSize: '0.8rem',
  },
}));

const IPropTypes = {
  claimsList: PropTypes.object,
  formData: PropTypes.object,
  resetToggel: PropTypes.bool.isRequired,
  setResetToggel: PropTypes.func.isRequired,
  fetchTableValue: PropTypes.func.isRequired,
};
const defaultProps = {
  claimsList: {},
  formData: null,
};

const ClaimsTableBody = (props) => {
  const {
    claimsList, formData, resetToggel, setResetToggel, fetchTableValue,
  } = props;
  const claimData = claimsList;
  const claimStatekeys = claimData ? Object.keys(claimData) : [];
  const claimState = {
    VIEW: false,
    CREATE: false,
    UPDATE: false,
    DELETE: false,
  };
  const formDataValue = {};
  if (formData) {
    const { featureClaims } = formData;
    const ClaimsKeys = Object.keys(featureClaims || []);
    ClaimsKeys.forEach((claim) => {
      const featureKeys = Object.keys(featureClaims[claim]);
      featureKeys.forEach((feature) => {
        if (formDataValue[claim] == null) {
          formDataValue[claim] = {};
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const val of featureClaims[claim][feature]) {
          if (formDataValue[claim][feature] == null) {
            formDataValue[claim][feature] = {};
          }
          formDataValue[claim][feature][val] = true;
        }
        ['VIEW', 'CREATE', 'UPDATE', 'DELETE'].forEach((val) => {
          if (!(val in formDataValue[claim][feature])) {
            formDataValue[claim][feature][val] = false;
          }
        });
      });
    });
  }
  claimStatekeys.forEach((val) => {
    const featuresData = Object.keys(claimData[val]);
    featuresData.forEach((featureval) => {
      claimData[val][featureval] = formData
        ? formDataValue[val]
          ? formDataValue[val][featureval]
            ? formDataValue[val][featureval]
            : claimState
          : claimState
        : claimState;
    });
  });
  const [state, setState] = useState(claimData);
  const handleChange = (e, item, module) => {
    setState((previous) => {
      const updatedState = {
        ...previous,
        [item]:
          {
            ...previous[item],
            [module]: {
              ...previous[item][module],
              [e.target.name]: e.target.checked,
            },
          },
      };
      return updatedState;
    });
  };

  useEffect(() => {
    const claimsData = {};
    const stateKeys = Object.keys(state);
    stateKeys.forEach((item) => {
      const featureKeys = Object.keys(state[item]);
      featureKeys.forEach((feature) => {
        const featurData = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const key in state[item][feature]) {
          if (state[item][feature][key] === true) {
            if (claimsData[item] == null) {
              claimsData[item] = {};
            }
            featurData.push(key);
            claimsData[item][feature] = featurData;
          }
        }
      });
    });
    fetchTableValue(claimsData);
  }, [state]);
  useEffect(() => {
    if (resetToggel) {
      claimStatekeys.forEach((val) => {
        const featuresData = Object.keys(claimData[val]);
        featuresData.forEach((featureval) => {
          claimData[val][featureval] = claimState;
        });
      });
      setState(claimData);
      setResetToggel(false);
    }
  }, [resetToggel]);
  const features = Object.keys(claimsList);
  const classes = useStyles();
  return (
    <TableBody>
      {features
        && features.map((item) => {
          let count = 0;
          const featureClaims = Object.keys(claimsList[item]);
          const moduleLength = Object.keys(claimsList[item]).length;
          return featureClaims.map((module) => {
            count += 1;
            return (
              <TableRow key={module}>
                {count === 1 && (
                  <TableCell className={classes.borderRight} rowSpan={moduleLength}>
                    {item}
                  </TableCell>
                )}
                {<TableCell className={classes.borderRight}>{module}</TableCell>}
                {
                  <TableCell className={classes.borderRight}>
                    <FormGroup row>
                      <FormControlLabel
                        classes={{
                          label: classes.label,
                        }}
                        control={(
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={state[item][module].VIEW}
                            onChange={(e) => {
                              handleChange(e, item, module);
                            }}
                            name="VIEW"
                          />
)}
                        label="VIEW"
                      />
                      {module !== 'Role Assignment' && (
                        <FormControlLabel
                          classes={{
                            label: classes.label,
                          }}
                          control={(
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={state[item][module].CREATE}
                              onChange={(e) => {
                                handleChange(e, item, module);
                              }}
                              name="CREATE"
                            />
)}
                          label="CREATE"
                        />
                      )}
                      <FormControlLabel
                        classes={{
                          label: classes.label,
                        }}
                        control={(
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={state[item][module].UPDATE}
                            onChange={(e) => {
                              handleChange(e, item, module);
                            }}
                            name="UPDATE"
                          />
)}
                        label="UPDATE"
                      />
                      {module !== 'Role Assignment' && (
                        <FormControlLabel
                          classes={{
                            label: classes.label,
                          }}
                          control={(
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={state[item][module].DELETE}
                              onChange={(e) => {
                                handleChange(e, item, module);
                              }}
                              name="DELETE"
                            />
)}
                          label="DELETE"
                        />
                      )}
                    </FormGroup>
                  </TableCell>
                }
              </TableRow>
            );
          });
        })}
    </TableBody>
  );
};
export default ClaimsTableBody;
ClaimsTableBody.propTypes = IPropTypes;
ClaimsTableBody.defaultProps = defaultProps;
