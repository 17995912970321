import * as actionTypes from './actionTypes';
import HttpService from '../../service/axiosInterceptor';
import HttpEtrfService from '../../service/etrfInterceptor';

export const loadPendingOrders = (params, userId, sortVal = {}, data = {}, email = '') => (dispatch) => {
  window.showSpinner();
  HttpService.post(`/orders/filter?userId=${userId}&physicianEmail=${encodeURIComponent(email)}&status=INCOMPLETE&offset=${params.offset + 1}&limit=${params.limit}&sortBy=${sortVal.sortBy}&orderBy=${sortVal.orderBy}&groupBy=true`, data)
    .then(({ data: response }) => {
      /*
      Transform Reveal-Single-Cancer data in incomplete order forms to Reveal-Multi-Cancer data.
      This transformation can be removed after there is no incomplete Reveal-Single-Cancer.
      Following old data need transformation
      1) testOrdered.name: LUNAR1-CRC-P, LUNAR1-CRC-S, LUNAR1-CRC-O
      2) diagnosis.stage
      3) diagnosis.disease: 'Other Diagnosis' => 'Other'
      4) set noAdjuvantTherapy to false if dateOfAdjuvantChemotherapy contains value
      */
      response?.pageData?.list?.forEach(i => {
        if(i.productFamily?.id === 'REVEAL'){
          switch(i.testOrdered?.name[0]){
            case 'LUNAR1-CRC-P':
              i.testOrdered.name[0] = 'GUARDANT_REVEAL';
              i.testOrdered.reorderType = {id: "P"};
              break;
            case 'LUNAR1-CRC-S':
              i.testOrdered.name[0] = 'GUARDANT_REVEAL';
              i.testOrdered.reorderType = {id: "S"};
              break;
            case 'LUNAR1-CRC-O':
              i.testOrdered.name[0] = 'GUARDANT_REVEAL';
              i.testOrdered.reorderType = {id: "O"};
              break;
          }
          if(i.diagnosis.stage){
            i.diagnosis.stage = i.diagnosis.stage.replace(/(Stage III?).*/i, "$1");
          }
          if(i.diagnosis.disease?.toUpperCase() === 'OTHER DIAGNOSIS'){
            i.diagnosis.disease = 'Other';
          }
          if(i.diagnosis.dateOfAdjuvantChemotherapy){
            i.diagnosis.noAdjuvantTherapy = false;
          }
        }
        if(i?.testOrdered?.name?.length === 0) {
          i.productFamily = {};
        }
      });
      dispatch(actionTypes.loadPendingOrders(response));
      window.hideSpinner();
    })
    .catch((err) => {
      dispatch(actionTypes.loadPendingOrdersError(err));
      window.hideSpinner();
    });
};

export const loadCompletedOrders = (params, userId, sortVal = {}, data = {}, email = '') => (dispatch) => {
  window.showSpinner();
  HttpService.post(`/orders/filter?userId=${userId}&physicianEmail=${encodeURIComponent(email)}&status=COMPLETE,PRINTED,SIGNED&offset=${params.offset + 1}&limit=${params.limit}&sortBy=${sortVal.sortBy}&orderBy=${sortVal.orderBy}&groupBy=true`, data)
    .then(({ data: response }) => {
      dispatch(actionTypes.loadCompletedOrders(response));
      window.hideSpinner();
    })
    .catch((err) => {
      dispatch(actionTypes.loadCompletedOrdersError(err));
      window.hideSpinner();
    });
};

export const loadESignPendingOrders = (params, userId, sortVal = {}, data = {}, email = '') => (dispatch) => {
  window.showSpinner();
  HttpService.post(`/orders/filter?userId=${userId}&physicianEmail=${encodeURIComponent(email)}&status=PENDINGSIGNATURE&offset=${params.offset + 1}&limit=${params.limit}&sortBy=${sortVal.sortBy}&orderBy=${sortVal.orderBy}&groupBy=true`, data)
    .then(({ data: response }) => {
      dispatch(actionTypes.loadPendingSignatureOrders(response));
      window.hideSpinner();
    })
    .catch((err) => {
      dispatch(actionTypes.loadPendingSignatureOrdersError(err));
      window.hideSpinner();
    });
};

export const getFilterData = (value, userId, callback, email = '') => {
  HttpService.post(`orders/groupby?userId=${userId}&physicianEmail=${encodeURIComponent(email)}&searchKey=${value}`)
    .then(({ data }) => {
      callback(data);
    })
    .catch((error) => {
      actionTypes.ordersSearchError(error);
    });
};

export const downloadEtrfPdf = orderId => (dispatch) => {
  window.showSpinner();
  HttpEtrfService.get(`/etrfs/${orderId}`, { responseType: 'blob' })
    .then((response) => {
      const windowUrl = window.URL || window.webkitURL;
      const href = windowUrl.createObjectURL(response.data);
      const a = document.createElement('a');
      a.setAttribute('download', `Guardant360-${orderId}-TestRequisition`);
      a.setAttribute('href', href);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.hideSpinner();
    })
    .catch((err) => {
      dispatch(actionTypes.downloadETRFPdfError(err));
      window.hideSpinner();
    });
};
export const editPatientOrder = params => (dispatch) => {
  dispatch(actionTypes.editOrder(params));
};

export const completedPatchRequest = (envelopId, productFamilyId, callback) => {
  HttpEtrfService.patch(`/etrfs/${envelopId}?productFamilyId=${productFamilyId}`)
    .then(() => {
      callback('success');
    })
    .catch(() => callback('fail'));
};

export const ordersDashboardRoleBasedAuth = params => (dispatch) => {
  dispatch(actionTypes.ordersDashboardRoleBasedAuth(params));
};
