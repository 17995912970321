import { RECEIVE_ORDER } from '../../../actions/orders/active-order/order';
import { billingActionTypes } from '../../../actions/orders/active-order/billing-info';

const billingInitialState = {
  cardIncluded: false,
  type: null,
  company: null,
  subscriberFirstName: null,
  subscriberLastName: null,
  groupNumber: null,
  dob: null,
  insuredRelationship: null,
  policyNumber: null,
};

const billingInfo = (state = billingInitialState, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return action.order.billingInfo;
    case billingActionTypes.ADD_BILLING_DATA:
      return { ...state, ...action.info };
    default:
      return state;
  }
};

export { billingInfo };
