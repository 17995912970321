import React from 'react';
import { Study } from '../Study';
import styles from './StudiesCarousel.module.scss';
import rightCarouselArrow from '../../../assets/images/icons/studies/rightCarouselArrow.svg';
import leftCarouselArrow from '../../../assets/images/icons/studies/leftCarouselArrow.svg';
import { getStudiesList } from '../../../util/ajax';
import StudiesSearch from '../StudiesSearch';

class StudiesCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.leftArrowButton = React.createRef();
    this.rightArrowButton = React.createRef();
    this.carouselScrollableArea = React.createRef();
    this.scrollButtonDisplay = this.scrollButtonDisplay.bind(this);
    this.scroll = this.scroll.bind(this);
    this.scrollLeftHandler = this.scrollLeftHandler.bind(this);
    this.scrollRightHandler = this.scrollRightHandler.bind(this);
    this.studiesList = this.studiesList.bind(this);
    this.state = {
      showLeftArrow: false,
      showRightArrow: true,
      carouselItems: [],
      dashboardSearchResults: [],
      studyId: '',
    };
  }

  componentDidMount() {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const studyId = searchParams.get('study_id');
    const url = studyId ? `/rest/studies?study_id=${studyId}` : '/rest/studies';
    getStudiesList(url, this.studiesList);
    this.carouselScrollableArea.current.addEventListener('scroll', this.scrollButtonDisplay);
    this.scrollButtonDisplay();
    this.scrollToSelected();
  }

  componentWillUnmount() {
    this.carouselScrollableArea.current.removeEventListener('scroll', this.scrollButtonDisplay);
  }

  studiesList(data) {
    this.setState({
      carouselItems: data.iuo_studies,
      dashboardSearchResults: data.selectedStudy.dashboardSearchResults,
      studyId: data.selectedStudy.study_id,
    });
  }

  scrollButtonDisplay() {
    const el = this.carouselScrollableArea.current;
    const remainingWidth = el.scrollWidth - el.clientWidth;
    const scrollPosition = el.scrollLeft;
    this.setState({ showLeftArrow: scrollPosition !== 0 });
    this.setState({ showRightArrow: scrollPosition < remainingWidth });
  }

  scroll(scrollIncrement) {
    const el = this.carouselScrollableArea.current;
    el.scrollBy(scrollIncrement, 0);
    this.scrollButtonDisplay();
  }

  carouselItemWidth() {
    const el = this.carouselScrollableArea.current;
    return el.scrollWidth / el.children.length;
  }

  scrollLeftHandler() {
    this.scroll(-this.carouselItemWidth());
  }

  scrollRightHandler() {
    this.scroll(this.carouselItemWidth());
  }

  scrollToSelected() {
    const itemList = this.state.carouselItems;
    let indexOfSelected = 0;
    for (let i = 0; i < itemList.length; i += 1) {
      if (itemList[i].isSelected) {
        indexOfSelected = i;
        break;
      }
    }
    const scrollIncrement = indexOfSelected * this.carouselItemWidth();
    this.scroll(scrollIncrement);
  }

  render() {
    return (
      <>
        <div className={styles.carouselContainer}>
          <div className={styles.leftArrow} id="carouselArrowLeft" onClick={this.scrollLeftHandler}>
            { this.state.showLeftArrow && (
            <img src={leftCarouselArrow} ref={this.leftArrowButton} alt="carousel left navigation arrow" />
            )}
          </div>
          <div className={styles.container} ref={this.carouselScrollableArea} id="studyCarouselScrollable">
            {this.state.carouselItems.map(study => <Study key={study.id} {...study} />)}
          </div>
          <div className={styles.rightArrow} id="carouselArrowRight" onClick={this.scrollRightHandler}>
            { this.state.showRightArrow && (
            <img src={rightCarouselArrow} ref={this.rightArrowButton} alt="carousel right navigation arrow" />
            )}
          </div>
        </div>
        <StudiesSearch
          searchResults={this.state.dashboardSearchResults}
          studyId={this.state.studyId}
        />
      </>
    );
  }
}
export { StudiesCarousel };
