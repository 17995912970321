
import { connect } from 'react-redux';

import { AlterationsTable } from './alterations-table';

const mapStateToProps = (store) => {
  const { alterationsTable } = store.patientPage.multiProduct.info;
  const alterationsDictionary = (store.patientPage.multiProduct.info.dictionary || {}).alterations;
  return {
    alterationHeaderText: alterationsDictionary && alterationsDictionary.alterationHeader,
    frequencyHeaderText: alterationsDictionary && alterationsDictionary.frequencyHeader,
    therapiesHeaderText: alterationsDictionary && alterationsDictionary.therapiesHeader,
    clinicalHeaderText: alterationsDictionary && alterationsDictionary.clinicalHeader,
    noAlterationsText: alterationsDictionary && alterationsDictionary.noAlterationsText,
    hideTherapies: alterationsTable.hideTherapies,
    requestId: alterationsTable.requestId,
    showSuperscript: alterationsTable.showSuperscript,
    alterationData: alterationsTable.alterations,
    therapyUrl: alterationsTable.therapyUrl,
    showFrequencyColumn: alterationsTable.showFrequencyColumn,
  };
};

const ConnectedAlterationsTable = connect(
  mapStateToProps, null,
)(AlterationsTable);

export { ConnectedAlterationsTable as AlterationsTable };
