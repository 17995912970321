import { BiomarkersTableSerializer } from './biomarkers-table';
import { DictionarySerializer } from './dictionary';
import { PatientSummarySerializer } from './patient-summary';
import { ReferenceMenuSerializer } from './reference-menu';
import { AlterationsTableSerializer } from './alterations-table';
import { AssociatedTestSerializer } from './associated-test';
import { ActionBarSerializer } from './action-bar';
import { LoggedUserSerializer } from './logged-user';
import { ImmunohistochemistryTableSerializer } from './immunohistochemistry-table';
import { AlertSerializer } from './alert';
import { InterpretationAndMethodologySerializer } from './interpretation-and-methodology';
import { TestsDropdownSerializer } from './tests-dropdown';
import { ReferenceRangeSerializer } from './reference-range';

export class PatientPageSerializer {
  static deserialize = info => (
    {
      loggedUser: LoggedUserSerializer.deserialize(info.logged_user),
      viewedAsUser: LoggedUserSerializer.deserialize(info.viewed_as_user),
      actionBar: ActionBarSerializer.deserialize(info.action_bar),
      alterationsTable: AlterationsTableSerializer.deserialize(info.alterations_table),
      biomarkersTable: BiomarkersTableSerializer.deserialize(info.biomarkers_table),
      dictionary: DictionarySerializer.deserialize(info.dictionary),
      patientSummary: PatientSummarySerializer.deserialize(info.patient_summary),
      references: ReferenceMenuSerializer.deserialize(info.reference_menu),
      associatedTest: AssociatedTestSerializer.deserialize(info.associated_testing_results),
      immunohistochemistryTable: ImmunohistochemistryTableSerializer.deserialize(
        info.immunohistochemistry_table,
      ),
      interpretationAndMethodology: InterpretationAndMethodologySerializer.deserialize(
        info.interpretation_and_methodology,
      ),
      comment: info.comment,
      alert: AlertSerializer.deserialize(info.alert),
      primaryStatusMessages: info.primary_status_messages,
      testsDropdown: TestsDropdownSerializer.deserialize(info.tests_dropdown),
      referenceRange: ReferenceRangeSerializer.deserialize(info.reference_range),
      generalMessage: info.general_message,
    }
  )
}
