import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AddEarlyStageDiagnosisInfo } from '../../../../store/actions/orders/active-order/early-stage-diagnosis-info';

import { DiagnosisConditionEarlyStageContainer } from './diagnosis-condition-early-stage-container';

const mapStateToProps = ({ orders: { activeOrder: { earlyStageDiagnosisInfo } } }) => ({
  icdCodes: [
    earlyStageDiagnosisInfo.diagnosisPrimaryCode.code,
    earlyStageDiagnosisInfo.diagnosisSecondaryCode.code,
    earlyStageDiagnosisInfo.diagnosisTertiaryCode.code,
    earlyStageDiagnosisInfo.diagnosisQuarternaryCode.code,
    earlyStageDiagnosisInfo.diagnosisQuinaryCode.code,
    earlyStageDiagnosisInfo.diagnosisSenaryCode.code,
  ].filter(Boolean),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveEarlyStageDiagnosisInfo: AddEarlyStageDiagnosisInfo,
}, dispatch);

const ConnectedDiagnosisConditionEarlyStageContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(DiagnosisConditionEarlyStageContainer);

export { ConnectedDiagnosisConditionEarlyStageContainer as DiagnosisConditionEarlyStageContainer };
