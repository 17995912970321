class TherapySerializer {
  static deserialize(data) {
    return {
      drugName: data.drugName,
      hasUrl: data.hasUrl,
      urlSuffix: data.urlSuffix,
    };
  }
}

export { TherapySerializer };
