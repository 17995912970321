import {
  Box, Button, Grid, Modal, Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { schema } from '../schema';
import { isEmail } from '../../admin/common/utils/Validation';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  overflow: 'auto',
  height: '20%',
  bgcolor: '#FFFFFF',
  p: 4,
  textAlign: 'center',
};
const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '10px',
    fontSize: '20px',
    cursor: 'pointer',
  },
}));
function Dialog(props) {
  const {
    newOrder,
  } = props;
  const orderManagement = newOrder && newOrder.toJS();

  const detPhysician = newOrder.getIn(schema.physician.authorizingProvider) || {};
  const {
    viewAsUser:
    {
      email = '', firstname = '', lastname = '', id = '', phone = '',
    },
  } = props;
  const { delegee } = orderManagement;

  const orderingUser = {
    portalUserId: id?.toString(),
    firstName: firstname,
    lastName: lastname,
    email,
    phone,
  };
  const continueSteps = async () => {
    const patientPhone = orderManagement?.schema?.order?.patient?.demographic?.phone;
    const patientEmail = orderManagement?.schema?.order?.patient?.demographic?.email;
    const patientZipCode = orderManagement?.schema?.order?.patient?.demographic?.address?.zipCode;
    const secondaryPhone = orderManagement?.schema?.order?.secondaryRecipient?.phone;
    const secondaryFax = orderManagement?.schema?.order?.secondaryRecipient?.fax;
    const tissuePhone = orderManagement?.schema?.order?.specimens?.tissueSpecimen?.phone;
    const tissueFax = orderManagement?.schema?.order?.specimens?.tissueSpecimen?.fax;
    /*
      ECS-8647: keep only valid items in secondaryIcdCodes array.
      Long term solution is to never let the data go wrong in the first place.
    */
    if(orderManagement.schema?.order?.diagnosis?.secondaryIcdCodes && orderManagement.schema.order.diagnosis.secondaryIcdCodes.length > 0){
      orderManagement.schema.order.diagnosis.secondaryIcdCodes = orderManagement.schema.order.diagnosis.secondaryIcdCodes.filter(i => i);
    }
    const data = {
      order: {
        ...orderManagement.schema.order,
        orderingUser,
        patient: {
          ...orderManagement?.schema?.order?.patient,
          demographic: {
            ...orderManagement?.schema?.order?.patient?.demographic,
            phone: patientPhone?.trim().length === 10 ? patientPhone : undefined,
            email: isEmail?.test(patientEmail) ? patientEmail : undefined,
            address: {
              ...orderManagement?.schema?.order?.patient?.demographic?.address,
              zipCode: patientZipCode?.trim().length === 5 ? patientZipCode : undefined,
            },
          },
        },
        diagnosis: {
          ...orderManagement?.schema?.order?.diagnosis,
          trfDisplayName: undefined,
        },
        secondaryRecipient: {
          ...orderManagement?.schema?.order?.secondaryRecipient,
          phone: secondaryPhone?.trim().length === 10 ? secondaryPhone : undefined,
          fax: secondaryFax?.trim().length === 10 ? secondaryFax : undefined,
        },
        specimens: orderManagement?.schema?.order?.specimens ? {
          ...orderManagement?.schema?.order?.specimens,
          tissueSpecimen: orderManagement?.schema?.order?.specimens?.tissueSpecimen ? {
            ...orderManagement?.schema?.order?.specimens?.tissueSpecimen,
            phone: tissuePhone?.trim().length === 10 ? tissuePhone : undefined,
            fax: tissueFax?.trim().length === 10 ? tissueFax : undefined,
          } : undefined,
        } : undefined,
      },
    };
    await props.actions.completeSaveData(data, orderManagement.embaded).then((saveData) => {
      props.actions.eSignAndSubmit({ data: saveData.data, embaded: orderManagement.embaded, delegee, signeeEmail: props.viewAsUser?.email })
        .then((esign) => {
          const {
            portalOrderId,
            productFamily,
          } = saveData.data;
          const findata = {
            order: {
              portalOrderId,
              orderingUser,
              productFamily,
              esignature: esign.data,
            },
          };
          props.actions.updateSignature(esign.data.envelopeId).then(() => {
            props.actions.finalizeOrder(findata).then(() => {
              window.location.href = '/new_orders';
            });
          });
        });
    }).catch(() => props.actions.closeOederSpinner());

    props.actions.onTabChange({
      key: ['showEmbadedDailog'],
      value: false,
    });
  };
  const classes = useStyles();
  return (
    <Modal
      open={orderManagement.showEmbadedDailog}
    >
      <Box sx={style}>
        <CloseIcon
          className={classes.closeButton}
          onClick={() => props.actions.onTabChange({
            key: ['showEmbadedDailog'],
            value: false,
          })}
        />
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ my: '20px', margin: 'auto', textTransform: 'uppercase' }}> Guardant - Request for e-signature </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ my: '10px', width: '80%', margin: 'auto' }}>
              An email notification will be sent to Dr.
              {` ${detPhysician.firstName}  ${detPhysician.lastName} `}
              for their electronic signature.
              This order will not be submitted until the electronic signature is received.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.actions.onTabChange({
                key: ['showEmbadedDailog'],
                value: false,
              })}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={continueSteps}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
Dialog.propTypes = {
  newOrder: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
};
export default Dialog;
