import React from 'react';
import ProtoTypes from 'prop-types';

const ShareModelWindow = (props) => {
  const {
    patientId,
    patientName,
    number,
  } = props;
  const header = patientId ? `Share ${patientName}'s report` : 'Grant access to all reports';
  const description = patientId ? `Invite an additional recipient to view ${patientName}'s patient report.` : `Grant access to all ${number} reports you ordered, as well as any new reports you order in the future.`;
  return (
    <div className="modal__container-outer.shares__modal">
      <div className="modal__container-inner.shares__modal">
        <div className="modal__content">
          <div className="shares__modal__content">
            <i className="fa fa-close.modal-close" />
            <div className="header">{header}</div>
            <div className="content">
              <p className="description">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShareModelWindow;
ShareModelWindow.propTypes = {
  patientId: ProtoTypes.number.isRequired,
  patientName: ProtoTypes.string.isRequired,
  number: ProtoTypes.number.isRequired,
};
