import React, { memo } from 'react';
import {
  Box, Button, Popover, Typography,
  Autocomplete,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PopupState, { bindMenu, bindPopover, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';
import Controls from './components/forms/controls';

const useStyles = makeStyles(() => createStyles({
  Container: {
    border: '1px solid #cccccc',
    borderRadius: '5px',
    padding: '7px',
    display: 'flex',
    width: '100%',
  },
  button: {
    color: 'white',
    backgroundColor: ' #8B939C',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textAlign: 'center',
    padding: '8px 4px',
    fontSize: '12px',
    marginRight: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100% !important',
    maxWidth: '100%',
  },
  buttonMultiple: {
    color: 'white',
    backgroundColor: ' #8B939C',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textAlign: 'center',
    padding: '8px 4px',
    fontSize: '12px',
    marginRight: '5px',
    width: 'auto',
    display: 'flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#8B939C',
    },
  },
  marginLess: {
    width: '400px',
  },
}));
const IPropTypes = {
  codes: PropTypes.array.isRequired,
};
const list = [{ title: 'Test' }];

const CodePopOver = (props) => {
  const { codes } = props;
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Container}>
        {codes.map(code => (
          <PopupState key={code} variant="popover" popupId="demo-popup-popover">
            {popupState => (
              <>
                <Button
                  variant="contained"
                  className={codes.length > 1 ? classes.buttonMultiple : classes.button}
                  {...bindTrigger(popupState)}
                >
                  <Typography noWrap>{code}</Typography>
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2}>
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      className={classes.marginLess}
                      options={list.map(option => option.title)}
                      renderInput={params => (
                        <Controls.Input
                          {...bindMenu(popupState)}
                          {...params}
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                    />
                  </Box>
                </Popover>
              </>
            )}
          </PopupState>
        ))}
      </Box>
    </>
  );
};

export default memo(CodePopOver);
CodePopOver.propTypes = IPropTypes;
