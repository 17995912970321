import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { OrdersContainer } from './orders-container';

import {
  hideErrorBanner, showErrorBanner, hideMissingRequiredFields,
} from '../../../store/actions/orders/active-order/form_errors';
import { updateLoadingCount } from '../../../store/actions/global';

const mapStateToProps = ({
  global: { initialProps: { userPermissions: { canOnlyDemoOrders, emrUser } } },
  orders: { activeOrder, staticData, emr },
}, { productFamily }) => ({
  showErrorBanner: activeOrder.formErrors.showErrorBanner,
  errorBannerMsg: activeOrder.formErrors.errorBannerMsg,
  showConfirmationModal: activeOrder.confirmationModal.showConfirmationModal,
  showMissingRequiredFields: activeOrder.formErrors.showMissingRequiredFields,
  configuration: (staticData.configuration || {})[productFamily],
  canOnlyDemoOrders,
  productFamily,
  emrUser,
  emrAccounts: emr.accounts,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onGhiAccountsError: showErrorBanner,
  hideErrorBanner,
  hideMissingRequiredFields,
  updateLoadingCount,
}, dispatch);

const ConnectedOrdersContainer = connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);

export { ConnectedOrdersContainer as OrdersContainer };
