import React from 'react';

import Typeahead from '../../../generic/Typeahead';
import { OrdersController } from '../../../../networking/controllers/orders';

const getDiagnosis = keywords => OrdersController.diagnosisSearch(keywords);

const DiagnosisTypeahead = props => (
  <Typeahead
    {...props}
    placeholder="Select One..."
    onOptionsFetch={getDiagnosis}
    autoComplete="off"
    hideNoResultsOption
  />
);

export { DiagnosisTypeahead };
