import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {
  Snackbar,
  Box, Button, Toolbar, Typography,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(theme => ({
  root: {
    top: theme.spacing(8),
    paddingLeft: '1.5rem',
    marginTop: '3%',
  },
  content: {
    width: 'max-content',
  },
}));

const IPropTypes = {
  currentUser: PropTypes.string.isRequired,
  totalPendingRecords: PropTypes.number.isRequired,
  roleBasedAuthorisation: PropTypes.object.isRequired,
};

const DashboardHeader = (props) => {
  const [confirm, setConfirm] = useState(false);
  const {
    currentUser = 'Jane',
    totalPendingRecords,
    roleBasedAuthorisation,
  } = props;

  useEffect(() => {
    if (totalPendingRecords >= 10) {
      setConfirm(true);
    }
  }, [totalPendingRecords]);

  const classes = useStyles();
  return (
    <>
      <Snackbar
        className={classes.root}
        open={confirm}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert className={classes.content} severity="error">
          You have reached the maximum number of &quot;Orders To Be Completed.&quot;
          Please complete or discard at least one to start a new order
        </Alert>
      </Snackbar>

      <Box>
        <Toolbar>
          <Typography component="h2" variant="h4" sx={{ flexGrow: 1 }}>
            {currentUser ? <span>Hello,</span> : <span>Hello</span>}
            {' '}
            {currentUser}
          </Typography>
          <Link to={totalPendingRecords >= 10 ? '/new_orders' : '/new_orders/new'}>
            <Button
              disabled={totalPendingRecords >= 10 || !roleBasedAuthorisation.toJS().editOrderButton}
              variant={totalPendingRecords >= 10 ? 'outlined' : 'contained'}
              size="large"
              startIcon={<AddIcon disabled={totalPendingRecords >= 10 || !roleBasedAuthorisation.toJS().editOrderButton} />}
            >
              New Order
            </Button>
          </Link>
        </Toolbar>
      </Box>
    </>
  );
};
export default DashboardHeader;
DashboardHeader.propTypes = IPropTypes;
