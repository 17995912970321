import React from 'react';
import PropTypes from 'prop-types';

import styles from './molecular-response-summary-table.module.scss';
import arrowDownGreen from '../../../assets/images/arrows/arrowDownGreen.svg';
import arrowUpRed from '../../../assets/images/arrows/arrowUpRed.svg';
import enyaOrange from '../../../assets/images/arrows/enyaOrange.svg';

const IPropTypes = {
  mrSummary: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitles: PropTypes.shape({
      interpretation: PropTypes.string.isRequired,
      dnaChange: PropTypes.string.isRequired,
    }),
    references: PropTypes.shape({
      increasing: PropTypes.string.isRequired,
      decreasing: PropTypes.string.isRequired,
      notEvaluable: PropTypes.string.isRequired,
    }),
    categoricalCall: PropTypes.oneOf(['DECREASING', 'INCREASING', 'NOT EVALUABLE', 'NO CHANGE']),
    changePercentage: PropTypes.string.isRequired,
    annotation: PropTypes.string,
  }).isRequired,
};

const textColor = (categoricalCall) => {
  let color = 'black';
  if (categoricalCall.toUpperCase() === 'DECREASING') {
    color = 'green';
  } else if (categoricalCall.toUpperCase() === 'INCREASING') {
    color = 'red';
  }
  return color;
};

const arrowImage = (categoricalCall, style) => {
  switch (categoricalCall.toUpperCase().trim()) {
    case 'DECREASING':
      return <img key={`decr_${style}`} src={arrowDownGreen} className={style} alt="" />;
    case 'INCREASING':
      return <img key={`incr_${style}`} src={arrowUpRed} className={style} alt="" />;
    case 'NOTEVALUABLE':
      return <img key={`notev_${style}`} src={enyaOrange} className={style} alt="" />;
    default:
      return null;
  }
};

const MolecularResponseSummaryTable = ({ mrSummary }) => (
  <div className={styles.container}>
    <div className={styles.titleRow}>
      <h3 className={styles.title}>
        {mrSummary.title}
      </h3>
      <span className={styles.legend}>
        <span className={styles.legendTitle}>KEY</span>
        { Object.entries(mrSummary.references).map(([key, value]) => (
          <span key={key + value} className={styles.legendItem}>
            {arrowImage(key, styles.arrowImageLegend)}
            {value}
          </span>
        ))}
      </span>
    </div>
    <table className={styles.table}>
      <thead>
        <tr className={styles.tableTitle}>
          <th>{mrSummary.subtitles.interpretation}</th>
          <th>{mrSummary.subtitles.dnaChange}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span className={`${styles.content} ${styles[textColor(mrSummary.categoricalCall)]}`}>
              {mrSummary.categoricalCall}
            </span>
            {
            !!mrSummary.annotation
              && (<sup className={styles.tableContentSuperscript}>&#8224;</sup>)
            }
          </td>
          <td>
            <span>
              {arrowImage(mrSummary.categoricalCall, styles.arrowImageContent)}
            </span>
            <span className={`${styles.content} ${styles[textColor(mrSummary.categoricalCall)]}`}>
              {mrSummary.changePercentage}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    {
    !!mrSummary.annotation
      && (
        <div className={styles.footnote}>
          <sup className={styles.footnoteSuperscript}>&#8224;</sup>
          {mrSummary.annotation}
        </div>
      )
    }
  </div>
);

MolecularResponseSummaryTable.propTypes = IPropTypes;

export default MolecularResponseSummaryTable;
