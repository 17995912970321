import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { PrintCompletedOrderModalContainer } from './print-completed-order-modal-container';
import { toggleElectronicSignatureModal } from '../../../store/actions/orders/electronic-signature-confirmation';
import { toggleModalAction, updateModalInfo } from '../../../store/actions/orders/active-order/print-modal';
import { updateLoadingCount } from '../../../store/actions/global';

const mapStateToProps = ({
  orders: {
    activeOrder: {
      printModal: { order },
    },
  },
  global: {
    initialProps: {
      viewAsUser,
    },
  },
}) => ({
  order,
  orderingUserEmail: viewAsUser.email,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleElectronicSignatureModal,
  togglePrintModal: toggleModalAction,
  updateModalInfo,
  updateLoadingCount,
}, dispatch);

const ConnectedPrintCompletedOrderModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrintCompletedOrderModalContainer);

export { ConnectedPrintCompletedOrderModalContainer as PrintCompletedOrderModalContainer };
