import React from 'react';
import PropTypes from 'prop-types';

import { ViewAssociatedButton } from '../view-associated-button';

import styles from './navigation-row.module.scss';

import { AssociatedTest } from '../../../models/associated-test';

const IPropTypes = {
  associatedTest: PropTypes.instanceOf(AssociatedTest),
};

const defaultProps = {
  associatedTest: null,
};

const NavigationRow = (props) => {
  if (!props.associatedTest) return null;

  const { name, path } = props.associatedTest;

  return (
    <div className={styles.container}>
      <div className={styles.buttonSection}>
        <ViewAssociatedButton
          to={path}
          text={`View ${name} Testing Results`}
          alignDirection={name === 'blood' ? 'left' : 'right'}
        />
      </div>
    </div>
  );
};

NavigationRow.propTypes = IPropTypes;
NavigationRow.defaultProps = defaultProps;

export { NavigationRow };
