import { connect } from 'react-redux';
import { AddClinicalHistoryInfo } from '../../../../store/actions/orders/active-order/clinical_history_info';
import { ClinicalHistoryExpanded } from './ClinicalHistoryExpanded';

const mapStateToProps = store => ({
  clinicalHistoryStore: store.orders.activeOrder.clinicalHistoryInfo,
});

const mapDispatchToProps = dispatch => ({
  saveClinicalHistoryInfo: (info, hideBanner) => dispatch(AddClinicalHistoryInfo(info, hideBanner)),
});

const ConnectedClinicalHistoryExpanded = connect(
  mapStateToProps, mapDispatchToProps,
)(ClinicalHistoryExpanded);

export { ConnectedClinicalHistoryExpanded as ClinicalHistoryExpanded };
