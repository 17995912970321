/* eslint-disable no-undef */
import React, { useEffect } from 'react';

const NeedFooter = () => {
  useEffect(() => {
    $('.open-contact-us').click(() => {
      $('button.feedback-form__btn').click();
    });
  });
  return (
    <div className="php_landing__page__text help-footer">
      <div className="php_landing__page__text-desc">Need help?&nbsp;</div>
      <div className="php_landing__page__text-link bold open-contact-us no-spinner">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="open-contact-us no-spinner" href="#">
          Contact Us
        </a>
      </div>
    </div>
  );
};
export default NeedFooter;
