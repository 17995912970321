import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceRange as ReferenceRangeModel } from '../../../models/reference-range';

import styles from './reference-range.module.scss';

const IPropTypes = {
  referenceRangeData: PropTypes.instanceOf(ReferenceRangeModel),
};

const defaultProps = {
  referenceRangeData: null,
};

const diseaseValues = (expressionValues, name) => (
  expressionValues.map((exprValue, idx) => {
    if (exprValue.context) {
      return (
        <tr key={name}>
          <td>{name}</td>
          <td colSpan={2} className={styles.contextRow}>
            {exprValue.context}
          </td>
        </tr>
      );
    }

    return (
      <tr className={styles.diseaseRow} key={`${name} ${exprValue.expressionLevel}`}>
        {!idx && <td rowSpan={expressionValues.length}>{name}</td>}
        <td className={styles.disease}>{exprValue.expressionLevel}</td>
        <td className={styles.disease}>{exprValue.referenceRange}</td>
      </tr>
    );
  })
);

const ReferenceRange = ({ referenceRangeData }) => {
  if (!referenceRangeData) {
    return null;
  }

  const {
    columnNames,
    title,
    rows,
  } = referenceRangeData;
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <table className={styles.mainTable}>
        <thead>
          <tr>
            {columnNames.map(name => <th key={name}>{name}</th>)}
          </tr>
        </thead>
        <tbody>
          {
            rows.map(row => diseaseValues(row.values, row.name))
          }
        </tbody>
      </table>
    </div>
  );
};

ReferenceRange.propTypes = IPropTypes;
ReferenceRange.defaultProps = defaultProps;

export { ReferenceRange };
