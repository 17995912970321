import React from 'react';
import PropTypes from 'prop-types';

import styles from './Study.module.scss';
import subjectIcon from '../../../assets/images/icons/studies/subject-icon-opened.svg';


const IPropTypes = {
  id: PropTypes.string.isRequired,
  patient_count: PropTypes.number.isRequired,
  project_study_id: PropTypes.string,
  is_selected: PropTypes.bool,
};

const defaultProps = {
  is_selected: false,
  project_study_id: null,
};

const Study = ({
  is_selected: isSelected, id, project_study_id: projectStudyId, patient_count: patientCount,
}) => {
  const selectedCss = isSelected ? styles.requestCountsTileIsSelected : styles.unselected;
  const tileSelectedCss = isSelected ? styles.tileRequestCountsTileIsSelected : null;
  return (
    <a href={`/studies?study_id=${id}`}>
      <div className={[styles.studyPlate, isSelected && styles.active].join(' ')}>
        <div className={[styles.bubbleContainer, isSelected && styles.bubbleSelected].join(' ')}>
          <div className={[styles.requestCountsTile, tileSelectedCss].join(' ')}>
            <div className={[styles.requestCountsCircles, styles.requestCountsTile, selectedCss].join(' ')}>
              <div className={[styles.requestCountsTileCount, (isSelected && styles.selected)].join(' ')}>{patientCount}</div>
            </div>
          </div>
        </div>
        <div className={[styles.textContainer, isSelected ? '' : styles.inactive].join(' ')}>
          <div className={styles.informationContainer}>
            <span>
              {projectStudyId}
            </span>
          </div>
          <div className={[styles.title, isSelected ? '' : styles.inactive].join(' ')}>
            <img src={subjectIcon} className={[styles.subjectIcon, isSelected ? '' : styles.inactive].join(' ')} alt="" />
            SUBJECTS
          </div>
        </div>
      </div>
    </a>
  );
};

Study.propTypes = IPropTypes;
Study.defaultProps = defaultProps;

export { Study };
