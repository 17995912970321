const diagnosisEarlyActionTypes = {
  ADD_EARLY_STAGE_DIAGNOSIS_DATA: 'ADD_EARLY_STAGE_DIAGNOSIS_DATA',
};

const AddEarlyStageDiagnosisInfo = (info, hideBanner) => ({
  type: diagnosisEarlyActionTypes.ADD_EARLY_STAGE_DIAGNOSIS_DATA,
  info,
  hideBanner,
});

export {
  AddEarlyStageDiagnosisInfo, diagnosisEarlyActionTypes,
};
