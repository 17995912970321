import React from 'react';
import PropTypes from 'prop-types';

const IPropTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
};

const LunarShareModal = ({ onClose, content, patient }) => (
  <div className="modal__container-outer shares__modal show_table">
    <div className="modal__container-inner shares__modal">
      <div className="modal__content">
        <div className="shares__modal__content">
          <button className="modal-close" type="button" onClick={onClose}>
            <i className="fa fa-close" />
          </button>
          <div className="shares__form-container">
            <div className="header">
              {`SHARE ${patient.patientLastName} ${patient.patientFirstName}'S REPORT`}
            </div>
            {content}
          </div>
        </div>
      </div>
    </div>
  </div>
);

LunarShareModal.propTypes = IPropTypes;

export { LunarShareModal };
