import { connect } from 'react-redux';

import { ReferenceRange } from './reference-range';

const mapStateToProps = store => (
  {
    referenceRangeData: store.patientPage.multiProduct.info.referenceRange,
  }
);

const ConnectedReferenceRange = connect(
  mapStateToProps,
)(ReferenceRange);

export { ConnectedReferenceRange as ReferenceRange };
