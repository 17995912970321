import React from 'react';
import PropTypes from 'prop-types';

import styles from './request-more-actions.module.scss';

const IPropTypes = {
  requestStatus: PropTypes.string.isRequired,
  handleDashboardMoreAction: PropTypes.func.isRequired,
  revisionsMap: PropTypes.array.isRequired,
  requestId: PropTypes.string.isRequired,
  accessKlass: PropTypes.string.isRequired,
};

const RequestMoreActions = React.forwardRef((props, ref) => {
  const {
    requestId, accessKlass, requestStatus,
    handleDashboardMoreAction, revisionsMap,
  } = props;

  const text = requestStatus === 'new' ? 'Read' : 'New';
  const newOrReadItem = (
    <button
      className="react-dashboard-action"
      onClick={handleDashboardMoreAction}
      data-js-revisionids={revisionsMap.join(',')}
      type="button"
    >
      {text}
    </button>
  );

  return (
    <ul
      ref={ref}
      id={`more-actions-menu-${requestId}`}
      className={`dropdown-menu popup-menu reactify-popup-desktop-download ${styles.container}`}
    >
      <li>
        <button type="button" className="no-spinner popup-menu-header" onClick={handleDashboardMoreAction}>
          Move To:
          <div className={`fa fa-ellipsis-v request-elliptical-dropdown ${accessKlass}`} />
        </button>
      </li>
      <li>
        { newOrReadItem }
      </li>
    </ul>
  );
});

RequestMoreActions.propTypes = IPropTypes;

export { RequestMoreActions };
