import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleRevoke from './commons/components/ToggleRevoke';
import { getStatusMessage } from './helpers';

const SiteMangerShare = (props) => {
  const {
    share,
    accessClass,
    sharedWithYou,
    sharees,
    sharers,
    physicians,
  } = props;
  const {
    physician_id: physicianId,
    revoked_at: revokedAt,
  } = share;
  const [showStatus, setShowStatus] = useState(true);
  const showShareStatus = (status) => {
    if (status === true) {
      setShowStatus(true);
    } else {
      setShowStatus(false);
    }
  };
  const firstName = physicians[physicianId].physician_first_name || '';
  const lastName = physicians[physicianId].physician_last_name || '';
  const practiceName = physicians[physicianId].practice_name || '';

  const shareClass = revokedAt && 'share-created--revoked';
  return (
    <div className={`share-created ${shareClass}`}>
      <div className={`share-created__patient ${shareClass}`}>
        <div className="share-created__text">
          <div className="share-created__text-icon-report" />
          <div className="share-created__text-description">
            {`${firstName}, ${lastName} (${practiceName})`}
          </div>
        </div>
        {showStatus
        && (
        <div className="share-created__status">
          {getStatusMessage(share)}
        </div>
        ) }
        <ToggleRevoke
          share={share}
          showShareStatus={showShareStatus}
          accessClass={accessClass}
          sharedWithYou={sharedWithYou}
          sharees={sharees}
          sharers={sharers}
        />
      </div>
    </div>
  );
};
export default SiteMangerShare;
SiteMangerShare.propTypes = {
  share: PropTypes.object.isRequired,
  accessClass: PropTypes.string,
  sharees: PropTypes.object.isRequired,
  sharers: PropTypes.object.isRequired,
  physicians: PropTypes.object.isRequired,
  sharedWithYou: PropTypes.bool,
};
SiteMangerShare.defaultProps = {
  accessClass: '',
  sharedWithYou: false,
};
