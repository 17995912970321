import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AddPhysicianInfo } from '../../../../store/actions/orders/active-order/physician_info';

import { OrderingPhysicianSelection } from './ordering-physician-selection';

const mapStateToProps = ({
  orders: { activeOrder: { physicianInfo, formErrors } },
  global: {
    initialProps: { userPermissions },
  },
}) => ({
  physician: physicianInfo.physician,
  withAutoSuggest: userPermissions.emrUser,
  clientAccount: physicianInfo.client.clientAccount,
  highlightRequiredFields: formErrors.showMissingRequiredFields,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  savePhysicianInfo: AddPhysicianInfo,
}, dispatch);

const ConnectedOrderingPhysicianSelection = connect(
  mapStateToProps, mapDispatchToProps,
)(OrderingPhysicianSelection);

export { ConnectedOrderingPhysicianSelection as OrderingPhysicianSelection };
