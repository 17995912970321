import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const PatientForgotPassword = (props) => {
  const {
    authenticityToken,
  } = props;
  const inputRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [isGuardantUser, setIsGuardantUser] = useState('');
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const fetchUsers = async () => {
    const email = inputRef.current.value;
    if (email !== '') {
      const response = await axios.post('/user/usernames', { current_email: email });
      setIsGuardantUser(response.data);
    }
  };
  return (
    <div className="row content_ registry">
      <div className="registry-content col-xs-12">
        <h1>Forgot your password?</h1>
        <form className="" id="new_password_reset" action="/password_resets" acceptCharset="UTF-8" method="post">
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />
          <input
            value="patient"
            type="hidden"
            name="password_reset[account_type]"
            id="password_reset_account_type"
          />
          <input
            type="hidden"
            name="patient_reset"
            id="patient_reset"
            value="true"
          />
          <div className="form-group">
            <label htmlFor="password_reset_email">Email Address</label>
            <p className="help-block">
              Please enter the email address you used to create your account.
              <br />
              We will send this email address instructions on how to reset the password.
            </p>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <input
                  type="email"
                  placeholder="e.g. name@example.com"
                  required="required"
                  ref={inputRef}
                  className="form-control"
                  name="password_reset[email]"
                  id="password_reset_email"
                  onBlur={() => fetchUsers()}
                />
              </div>
            </div>
          </div>
          <button className="registry-btn" type="submit">Send password reset email</button>
        </form>
      </div>
      <div className="col-xs-12">
        <div className="registry-headline">
          <div className="registry-text">Remembered your password?</div>
          <div className="registry-links">
            <a className="registry-links" href="/myguardant/sign_in" title="">SIGN IN HERE</a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientForgotPassword;
PatientForgotPassword.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
};
